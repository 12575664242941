import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Params, Router } from '@angular/router';
//////////////
// Interfaces
/////////////
import { Course } from 'src/app/interfaces/course';
import { Groups } from 'src/app/interfaces/groups';
import { Paginator } from 'src/app/interfaces/paginator';
import { Playlist } from 'src/app/interfaces/playlist';
import { Video } from 'src/app/interfaces/video/video';
////////////
// Services
////////////
import { AuthService } from 'src/app/services/auth.service';
import { LangService } from 'src/app/services/lang.service';
import { UsersService } from 'src/app/services/users.service';
import { VideoService } from 'src/app/services/video.service';
/////////////
// Components
////////////
import { CreateGroupModalComponent } from 'src/app/modals/group/create-modal/create-modal.component';
import { CreatePlayListModalComponent } from 'src/app/modals/playlist/create-modal/create-modal.component';
import { VideoUploadComponent } from 'src/app/modals/video-edit/video-upload/video-upload.component';
import { InitService } from 'src/app/services/init.service';
import { Organization } from 'src/app/interfaces/organization';
import { OrganizationUtilitiesService } from 'src/app/utilities/organization-utilities.service';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  paginator: Paginator = {
    pages: 1,
    current_page: 1,
    per_page: 8,
    total: 0,
  };
  order = 'RELEVANCE';
  loading = false;
  loadingDelete = false;
  listFilter = '';
  username: any;
  display_name: any;
  language = 'en';
  avatarImg: undefined | string =
    'assets/img/avatars/profile-avatar-purple.svg';
  user: any;

  coursesCompleted: Array<Course> = [];
  coursesEnrolled: Array<Course> = [];
  coursesOwn: Array<Course> = [];
  playListEnrolled: Array<Playlist> = [];
  myPlayList: Array<Playlist> = [];
  groupsEnrolled: Array<Groups> = [];
  myGroups: Array<Groups> = [];
  videosPublished: Array<Video> = [];
  videosUnprocessed: Array<Video> = [];
  videosUnpublished: Array<Video> = [];
  favoritesVideos: Array<Video> = [];
  public currentOrganization: Organization | null = null;
  public listOrders: any = [];
  public organization_id: any = [];
  public loadingMore: boolean = false;
  public searchTextOwn: string = '';

  constructor(
    public auth: AuthService,
    private lang: LangService,
    private videoService: VideoService,
    private userService: UsersService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ngbModal: NgbModal,
    private initService: InitService,
    public orgUtils: OrganizationUtilitiesService,
    private adminService: AdminService
  ) {
    this.auth.user.subscribe((user) => {
      this.username = user?.username;
      this.display_name = user?.display_name;
      this.user = user;
      this.avatarImg =
        this.user?.avatarPathUrlMedium ||
        'assets/img/avatars/profile-avatar-purple.svg';
    });
  }

  ngOnInit() {
    this.currentOrganization = this.initService.getCurrentOrg();
    console.log('este es ', this.currentOrganization);
    this.activatedRoute.queryParams.subscribe((res: Params) => {
      // console.log(this.listFilter);
      if (!res.section) {
        this.getCourses();
        return;
      }
      switch (res.section.toString()) {
        case '': {
          this.getCourses();
          break;
        }
        case 'settings': {
          this.getSetting();
          //  this.listFilter = res.section.toString();
          break;
        }
        default: {
          this.listFilter = res.section.toString();
          this.refreshList();
        }
      }
    });
  }

  createPlayList() {
    let modalRef = this.ngbModal.open(CreatePlayListModalComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
      centered: true,
    });
  }
  createGroup() {
    let modalRef = this.ngbModal.open(CreateGroupModalComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
      centered: true,
    });
  }
  uploadVideo() {
    // TODO add a callback function to video upload component to be called after the video is published
    // TODO pass a callback to video upload component to refresh the video list after the video is published
    let modalRef = this.ngbModal.open(VideoUploadComponent, {
      windowClass: 'default-modal',
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.selfModal = modalRef;
  }
  updateQueryParams() {
    const queryParams: Params = { section: this.listFilter };

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  getSetting() {
    this.listFilter = 'settings';
    this.updateQueryParams();
  }
  getCourses() {
    this.listFilter = 'courses';
    this.updateQueryParams();
  }
  getPlayList() {
    this.listFilter = 'playlists';
    this.updateQueryParams();
  }

  getGroups() {
    this.listFilter = 'groups';
    this.updateQueryParams();
  }
  getOrders() {
    this.listFilter = 'orders';
    this.getList();
  }

  getVideo() {
    this.listFilter = 'videos';
    this.updateQueryParams();
  }

  changeOrder() {
    this.refreshList();
  }

  loadMore() {
    if (!this.loading) {
      if (this.paginator.current_page < this.paginator.pages) {
        this.paginator.current_page++;
        this.getList();
      }
    }
  }

  refreshList() {
    this.paginator.current_page = 1;
    this.coursesCompleted = [];
    this.coursesEnrolled = [];
    this.coursesOwn = [];
    this.playListEnrolled = [];
    this.myPlayList = [];
    this.groupsEnrolled = [];
    this.myGroups = [];
    this.videosPublished = [];
    this.videosUnprocessed = [];
    this.videosUnpublished = [];
    this.favoritesVideos = [];
    this.getList();
  }

  getList(isLoadMore: boolean = false) {
    if (!this.username) {
      return;
    }
    this.loading = true;
    switch (this.listFilter) {
      case 'groups':
        this.userService.getGroups(this.username).then((res) => {
          this.groupsEnrolled = [...this.groupsEnrolled, ...res.data.enrolled];
          this.myGroups = [...this.myGroups, ...res.data.myGroups];
          this.loading = false;
        });
        break;
      case 'playlists':
        this.userService.getPlayList(this.username).then((res) => {
          this.playListEnrolled = [
            ...this.playListEnrolled,
            ...res.data.enrolled,
          ];
          this.myPlayList = [...this.myPlayList, ...res.data.myPlaylists];
          this.loading = false;
        });
        break;
      case 'courses':
        this.userService
          .getEnrolled(99, this.paginator.current_page, this.order, null)
          .then((res) => {
            this.order = '';
            this.paginator = res.paginator;

            this.coursesEnrolled = [...this.coursesEnrolled, ...res.data];
            this.loading = false;
          });
        this.userService.getCourses(this.username).then((res) => {
          this.coursesCompleted = [
            ...this.coursesCompleted,
            ...res.data.completed,
          ];
          this.coursesOwn = [...this.coursesOwn, ...res.data.own];
        });
        break;
      case 'orders':
        if (this.searchTextOwn) {
          this.paginator.current_page = 1;
        }
        if (isLoadMore) {
          this.loadingMore = true;
        } else {
          this.loading = true;
        }
        this.adminService
          .getOrders(
            this.searchTextOwn,
            this.organization_id,
            this.paginator.per_page,
            this.paginator.current_page
          )
          .then((resp: any) => {
            if (isLoadMore) {
              this.loadingMore = false;
            } else {
              this.loading = false;
              this.listOrders = [];
            }
            let copy = resp?.data?.data.map((data: any) => data);
            this.paginator = resp.data.paginator;
            this.listOrders = [...this.listOrders, ...copy];
          });
        break;
      case 'videos':
        this.userService.getMyVideos(this.username).then((res) => {
          this.videosPublished = [
            ...this.videosPublished,
            ...res.data.published,
          ];
          this.videosUnprocessed = [
            ...this.videosUnprocessed,
            ...res.data.unprocessed,
          ];
          this.videosUnpublished = [
            ...this.videosUnpublished,
            ...res.data.unpublished,
          ];
          this.loading = false;
          this.loadingDelete = false;
        });
        this.videoService.getFavorites(this.username).then((res) => {
          this.favoritesVideos = res.data.favorites.videos;
          this.loading = false;
          this.loadingDelete = false;
        });
        break;

      default:
        break;
    }
  }
  public refreshDelete() {
    this.loadingDelete = true;
    this.videosPublished = [];
    this.videosUnprocessed = [];
    this.videosUnpublished = [];
    this.favoritesVideos = [];
    this.getList();
  }
}
