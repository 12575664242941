<div class="table-responsive-sm mb-4">
  <table class="table table-hover" #container>
    <thead>
      <tr class="" [ngClass]="{ 'custom-border': true }">
        <th class="text-center" scope="col"></th>

        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "organization:settings:discounts:table:title:1" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "organization:settings:discounts:table:title:2" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "organization:settings:discounts:table:title:3" | translate }}
          </span>
        </th>

        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "organization:settings:discounts:table:title:5" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "organization:settings:discounts:table:title:6" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "organization:settings:discounts:table:title:7" | translate }}
          </span>
        </th>
        <th class="text-center" scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container>
        <tr
          [ngClass]="{ 'custom-border': true }"
          *ngFor="let item of listDiscounts; let i = index"
        >
          <td class="text-center align-middle">{{ " " }}</td>

          <td class="title-course align-middle fw-bold">
            {{ item?.code | excerpt : 25 }}
          </td>
          <td class="align-middle">
            {{ item?.type == "fixed" ? "€" + item.amount : item.amount + "%" }}
          </td>
          <td class="align-middle">
            {{ item?.collection?.name || "" | excerpt : 50 }}
          </td>
          <td class="align-middle">
            {{ item?.valid_from.slice(0, 10)  | date : "dd LLL YYYY" }}
          </td>
          <td *ngIf="item?.valid_until" class="align-middle">
            {{ item?.valid_until.slice(0, 10)  | date : "dd LLL YYYY" }}
          </td>
          <td *ngIf="!item?.valid_until" class="align-middle">
            {{ "admin:users:modal:discountProfile:check:2" | translate }}
          </td>
          <td class="align-middle">
            {{
              item.total_available == null
                ? item?.used
                : item?.used + "/" + item?.total_available
            }}
          </td>

          <td colspan="3" class="align-middle text-center">
            <div
              ngbDropdown
              class="d-inline-block float_right"
              placement="bottom-right"
            >
              <button
                type="button"
                class="btn btn-outline-primary"
                id="dropdownBasic1"
                ngbDropdownToggle
              >
                <i class="fa fa-ellipsis-h"></i>
              </button>
              <div
                ngbDropdownMenu
                aria-labelledby="dropdownBasic1"
                class="mt-1"
              >
                <button (click)="openDetailsDiscount(item)" ngbDropdownItem>
                  {{
                    "organization:settings:discounts:table:dropdown:item:1"
                      | translate
                  }}
                </button>
                <button (click)="openEditDiscount(item)" ngbDropdownItem>
                  {{
                    "organization:settings:discounts:table:dropdown:item:3"
                      | translate
                  }}
                </button>
                <button (click)="openDeleteDiscount(item,remove)" ngbDropdownItem>
                  {{
                    "organization:settings:discounts:table:dropdown:item:2"
                      | translate
                  }}
                </button>
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>

<ng-template #remove let-modal>
  <div class="modal-close" (click)="modal.dismiss()">
    <i class="fas fa-times me-2"></i><span>{{ "btn:close" | translate }}</span>
  </div>
  <div class="modal-header">
    <h4 class="modal-title">
      {{ "admin:users:modal:discountRemove" | translate }}
    </h4>
  </div>
  <div class="modal-body">
    <p>{{ "course:delete:modal:description:1b" | translate }} <strong>{{deleteDiscount?.code | excerpt : 25}}</strong>>? {{"course:delete:modal:description:1a" | translate }}?</p>
    <ul>
      <li>
        <p>{{ "discounts:delete:modal:description:2" | translate }}</p>
      </li>
    </ul>
    <p>{{ "discounts:delete:modal:description:5" | translate }}</p>
   
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-secondary"
      (click)="modal.dismiss()"
    >
      {{ "discount:delete:modal:cancel" | translate }}
    </button>
    <button class="btn btn-primary" (click)="modal.close()">
      {{ "discount:delete:modal:delete" | translate }}
    </button>
  </div>
</ng-template>