
<div class="select-users">
    <div class="box-input">
        <app-users-selected
        [usersSelected]="usersSelected"
      ></app-users-selected>

        <input
            id="selectMembersInpt"
            class="input-box"
            type="text"
            (keyup)="onSearch()"
            (focus)="newSearch()"
            [(ngModel)]="searchTxt" 
            placeholder="{{placeholder}}"
            *ngIf="maxUsers != null && maxUsers > usersSelected.length"
            auto-focus
            autofocus />
    </div>

    <div class="list-search" *ngIf="usersSearch&&usersSearch.length&&!loadingUsers || ifIsValidToInvite">
        <div *ngFor="let user of usersSearch" (click)="onAdd(user)" style="display: flex;">
            <img src="{{user.avatarPathUrlSmall || user.avatar || 'assets/img/avatars/profile-avatar-purple.svg'}}" />
            <span style="float:left;">{{user.display_name}} (<strong>{{user.email}}</strong>)</span>            
        </div>
       
    </div>

    <div class="list-search" *ngIf="!loadingUsers&&usersSelected&&usersSelected.length==0&&usersSearch&&usersSearch.length==0">
   
        <div>
            <img src="assets/img/avatars/profile-avatar-gray.svg" />
            This user does not exist or is not a member of this {{type}}.
        </div>
    </div>

    <div class="list-search" *ngIf="loadingUsers">
        <div>
            Loading..
        </div>
    </div>
</div>
