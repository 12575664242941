<h3 class="h6 mt-1">{{ 'video:edit:modal:tab:advanced_settings' | translate }}</h3>
<p class="fs-14 text-muted">{{ 'video:edit:modal:tab:advanced_settings:description' | translate }}</p>

<div class="">
  <div class="tab-content" id="nav-tabContent">
    <div class="tab-pane fade show active" id="nav-language-en" role="tabpanel" aria-labelledby="nav-home-tab">
      <label for="title-course-used" class="form-label w-100 fw-bold">{{ 'video:edit:modal:add_watermark:title' | translate }}</label>

      <div class="btn-group" role="group">
        <input type="radio" [(ngModel)]="videoEditService?.source.enable_watermark" (ngModelChange)="touched()" [value]="true" class="btn-check" name="enable_watermark" id="enable_watermark_watermarker_yes">
        <label class="btn btn-outline-primary" for="enable_watermark_watermarker_yes">{{ "video:edit:enable_watermark:yes" |
          translate }}</label>
        <input type="radio"[(ngModel)]="videoEditService?.source.enable_watermark" (ngModelChange)="touched()" [value]="false" class="btn-check" name="enable_watermark" id="enable_watermark_watermarker_no">
        <label class="btn btn-outline-primary" for="enable_watermark_watermarker_no">{{ "video:edit:enable_watermark:no" |
          translate }}</label>
      </div>
    </div>
  </div>
</div>

<hr [ngClass]="{'my-5': !videoEditService.isModal}">

<div class="pure-control-group">
  <label class="common-form-title mr-2" style="display: inline-block;margin-right: 10px" for="video-vixy-id">Vixy ID</label>
  <input [(ngModel)]="video.source.external_id" class="form-control" style="display: inline-block;width: 200px;" id="video-vixy-id" name="video-vixy-id" type="text" disabled=""
    value="">
</div>
