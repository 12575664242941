import { User } from 'src/app/interfaces/user';
import { Component, OnInit } from '@angular/core';
import { Organization } from 'src/app/interfaces/organization';
import { InitService } from 'src/app/services/init.service';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Router } from '@angular/router';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-home-reviews',
  templateUrl: './home-reviews.component.html',
  styleUrls: ['./home-reviews.component.scss'],
})
export class HomeReviewsComponent implements OnInit {
  public currentOrganization: any = '';
  public currentLang: string = '';
  public borderColors: any = [
    '2px solid rgba(0, 158, 224, 0.24)',
    '2px solid rgba(255, 204, 0, 0.24)',
    '2px solid rgba(65, 166, 42, 0.24)',
    '2px solid rgba(255, 204, 0, 0.24)',
    '2px solid rgba(65, 166, 42, 0.24)',
    '2px solid rgba(0, 158, 224, 0.24)',
  ];
  public infoCardsReviewsEn: any = [];
  public infoCardsReviewsNl: any = [];
  public sectionReviews: any = [];
  constructor(
    private initService: InitService,
    private orgsService: OrganizationsService,
    private dashboardServ: DashboardService,
    private router: Router,
    private lang: LangService
  ) {
    this.lang.language.subscribe((lang) => {
      this.currentLang = lang || 'en';
    });
  }

  ngOnInit(): void {
    this.currentOrganization = this.initService.getCurrentOrg();
    //this.getReviewsHome();
    this.getNewSections();
  }
  public getNewSections() {
    this.dashboardServ.getNewSections('reviews').then((data) => {
      if (data) {
        let dataContent: any = [];
        data[0].contents.forEach((cont) => {
          dataContent.push(cont.content);
        });

        this.infoCardsReviewsEn = dataContent.filter(
          (item: any) => item?.collection?.language == 'en'
        );

        this.infoCardsReviewsNl = dataContent.filter(
          (item: any) => item?.collection?.language == 'nl'
        );

        console.log('reviews filtered en', dataContent);
        console.log('reviews filtered', this.infoCardsReviewsNl);
      } else {
        this.infoCardsReviewsEn = [];
        this.infoCardsReviewsNl = [];
      }

      //  console.log( this.sectionReviews);
    });
  }
  public getReviewsHome() {
    this.orgsService
      .getReviewsHome(this.currentOrganization.id)
      .then((resp) => {
        if (resp.result == 'success') {
          this.infoCardsReviewsEn = resp.data.filter((item: any) => {
            console.log(item);
            if (
              item &&
              item.description != null &&
              item.description != '' &&
              item.username != null &&
              item.username != '' &&
              item.job_title != null &&
              item.job_title != ''
            ) {
              return true;
            } else {
              return false;
            }
          });
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public goToCourse(course: any) {
    this.router.navigate(['/courses', course.collection_id]);
  }
}
