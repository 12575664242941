import { Component, Input, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { GroupsService } from 'src/app/services/groups.service';
import { ToastService } from 'src/app/services/toast.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PlaylistsService } from 'src/app/services/playlists.service';
@Component({
  selector: 'app-create-playlist-modal',
  templateUrl: './create-modal.component.html',
  styleUrls: ['./create-modal.component.scss'],
})
export class CreatePlayListModalComponent implements OnInit {
  faTimes = faTimes;
  @Input() selfModal: NgbModalRef | null = null;
  @Input() title: String = '';
  @Input() group: any = {};
  @Input() forGroup: boolean = false;
  loadSpin: boolean = false;
  groupName: string = '';
  faCircleNorch: any = faCircleNotch;
  loading = false;
  playlist = {
    type: 'playlist',
    name: '',
    description: '',
    open: true,
  };

  constructor(
    private groupsService: GroupsService,
    private toastService: ToastService,
    private router: Router,
    private translate: TranslateService,
    private playlistService: PlaylistsService,
  ) {}

  ngOnInit(): void {
    this.groupName = this.group?.name;
    this.sendData();
  }

  sendData() {
    this.playlistService.setNameGroup(this.groupName);
  }
  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
  create() {
    this.loading = true;
    this.loadSpin = true;
    if (!this.forGroup) {
      this.groupsService
        .save(this.playlist)
        .then((res) => {
          this.toastService.showSuccess(
            this.translate.instant('playlist:create:success')
          );
          let id = res.data.collection.id;
          this.loading = false;
          this.loadSpin = false;

          this.closeModal();
          this.router.navigate(['/playlists', 'edit', id]);
        })
        .catch((err) => {
          if (err.error?.message) {
            this.toastService.showError(err.error?.message?.friendly);
            if (err.error?.message?.data?.errors) {
              const errors = err.error.message?.data?.errors;
              errors.forEach((e: any) => {
                this.toastService.showError(e.message);
              });
            }
          } else {
            this.toastService.showError(
              this.translate.instant('playlist:create:error')
            );
          }
          console.log(err);
          this.loading = false;
          this.loadSpin = false;
        });
    } else {
      this.groupsService
        .save(this.playlist)
        .then((res) => {
          let id = res.data.collection.id;
          this.saveFromGroup(id);
        })
        .catch((err) => {
          if (err.error?.message) {
            this.toastService.showError(err.error?.message?.friendly);
            if (err.error?.message?.data?.errors) {
              const errors = err.error.message?.data?.errors;
              errors.forEach((e: any) => {
                this.toastService.showError(e.message);
              });
            }
          } else {
            this.toastService.showError(
              this.translate.instant('playlist:create:error')
            );
          }
          console.log(err);
          this.loading = false;
          this.loadSpin = false;
        });
    }
  }

  public saveFromGroup(playlistId: any) {
    this.groupsService
      .saveFromGroup(playlistId, this.playlist, this.group.id)
      .then((res) => {
        this.toastService.showSuccess(
          this.translate.instant('playlist:create:success')
        );
        let id = res?.data?.collection?.id;
        this.loading = false;
        this.loadSpin = false;

        this.closeModal();
        this.router.navigate(['/playlists', 'edit', playlistId], {
          queryParams: { groupId: id },
        });
      })
      .catch((err) => {
        if (err.error?.message) {
          this.toastService.showError(err.error?.message?.friendly);
          if (err.error?.message?.data?.errors) {
            const errors = err.error.message?.data?.errors;
            errors.forEach((e: any) => {
              this.toastService.showError(e.message);
            });
          }
        } else {
          this.toastService.showError(
            this.translate.instant('playlist:create:error')
          );
        }
        console.log(err);
        this.loading = false;
        this.loadSpin = false;
      });
  }
}
