import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-desactivate-members',
  templateUrl: './desactivate-members.component.html',
  styleUrls: ['./desactivate-members.component.scss'],
})
export class DesactivateMembersComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() members: any | null = [];
  @Input() organizationId: any;
  @Input() numbAccounts: any;
  public users: any;
  @Output() refreshDelete = new EventEmitter<any>();
  public faCircleNorch: any = faCircleNotch;

  public faTimes = faTimes;
  public loadSpin: boolean = false;
  constructor(
    private organizationService: OrganizationsService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.users = Object.values(this.members);
  }
  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  public desactivateMembers() {
    let arrayKey: any = [];
    for (let k in this.members) {
      arrayKey.push(+k);
    }
    this.loadSpin = true;
    this.organizationService
      .desactivateMembers(this.organizationId, arrayKey)
      .then((data: any) => {
        if (data.result == 'success') {
          this.toastService.showSuccess('Disabled users');
          this.loadSpin = false;
          this.refreshDelete.emit();
          this.closeModal();
        } else {
          this.closeModal();
          this.toastService.showError('Error deleting');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
