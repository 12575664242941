import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MainComponent } from '../main/main.component';

@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.scss'],
})
export class TermsConditionComponent implements OnInit {
  faTimes = faTimes;
  @Input() description: string = '';
  @Input() info: any = {};
  @Input() selfModal: NgbModalRef | null = null;
  @Input() title: String = '';
  @Input() normal: boolean = false;
  @Output('close') close = new EventEmitter();
  loadSpin: boolean = false;
  faCircleNorch: any = faCircleNotch;
  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
    if (!this.normal) {
      this.openModalRegister();
    }
  }
  public openModalRegister() {
    let modalRef = this.modalService.open(MainComponent, {
      windowClass: 'login-modal',
    });
    modalRef.componentInstance.state = 'register';
    modalRef.componentInstance.info = this.info;
    modalRef.componentInstance.selfModal = modalRef;
    this.close.emit(this.info)
  }
}
