import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-custom-confirm',
  templateUrl: './custom-confirm.component.html',
  styleUrls: ['./custom-confirm.component.scss'],
})
export class CustomConfirmComponent implements OnInit {
  @Input() title: any = '';
  @Input() showCancel: boolean = true;
  @Input() description: any = '';
  @Input() buttonText: any = 'organizations:disable:modal:description:button';
  @Input() selfModal: NgbModalRef | null = null;
  @Output() refresh = new EventEmitter<any>();

  public faCircleNorch: any = faCircleNotch;

  public faTimes = faTimes;
  public loadSpin: boolean = false;

  constructor() {}

  ngOnInit(): void {}
  public disabledPlatform() {
    this.refresh.emit();
    this.closeModal();
  }

  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
}
