<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title">
    {{ "organization:members:action:resend_invitation" | translate }}
  </h4>
</div>
<div class="modal-body flex-column">
  <div class="form-group">
    <label class="modal-label">{{ "User" | translate }}</label>
    <input
      [(ngModel)]="user"
      class="input-box w-100"
      id="clip-title"
      maxlength="80"
      name="name"
      required=""
      type="text"
    />
  </div>
  <div class="form-group">
    <label for="clip-title" class="modal-label mt-3">{{
      "Email" | translate
    }}</label>
    <input
      [(ngModel)]="email"
      class="input-box w-100"
      id="clip-title"
      maxlength="80"
      name="name"
      required=""
      type="email"
    />
  </div>
  <div class="form-group">
    <label for="clip-title" class="modal-label mt-3">{{
      "collection:members:add:members:message:label" | translate
    }}</label>
    <textarea
      [(ngModel)]="invitie"
      class="input-box w-100"
      id="clip-title"
   
      rows="8"
      name="name"
      required=""
      type="text"
    ></textarea>
  </div>
</div>
<div class="row justify-content-end modal-footer">
  <div class="row justify-content-end">
    <div class="col-auto px-0">
      <button
        (click)="ResendInvitation()"
        type="button"
        role="tab"
        class="btn btn-primary text-nowrap"
        [disabled]="!email || !user || !invitie || loadSpin"
      >
        <fa-icon
          class="mr-2"
          [icon]="faCircleNorch"
          *ngIf="loadSpin"
          [spin]="true"
        ></fa-icon>
        {{ "organization:members:action:resend_invitation" | translate }}
      </button>
    </div>
  </div>
</div>
