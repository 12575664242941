<div *ngIf="loading" class="row min-vh-100 m-auto">
  <div class="my-auto">
    <app-loading-icon></app-loading-icon>
  </div>
</div>



<div *ngIf="!loading">
  <header>
    <ng-container *ngIf="!org.visible; else public">
      <div class="header-wrapper container-fluid bg-light-blue pt-5">
        <div class="container" *ngIf="featured?.contents">
          <ng-container *ngIf="lastactivecourse.length > 0">
            <app-continue-watching
              *ngIf="!loading"
              [course]="lastactivecourse[0]"
            ></app-continue-watching>
          </ng-container>
          <div class="row mb-5">
            <app-carousel
              [items]="featured!.contents"
              [cols]="2"
              [featured]="true"
              [title]="organizationWelcomeText()"
            ></app-carousel>
          </div>
        </div>
        <div class="inclined-bottom bg-white"></div>
      </div>
    </ng-container>
    <ng-template #public>
      <div
        *ngIf="!isAuthenticated() && !loading && lastactivecourse.length <= 0"
        class="header-develop"
      >
        <app-personal-development
          (scroll)="scrollTo()"
        ></app-personal-development>
      </div>
      <div
        *ngIf="!loading && lastactivecourse.length > 0"
        class="header-develop d-flex align-items-center justify-content-center"
        [ngClass]="{ 'container-develop': lastactivecourse.length > 2 }"
      >
        <div class="row">
          <app-continue-course-home
            [courses]="lastactivecourse"
          ></app-continue-course-home>
        </div>
      </div>
      <div
        *ngIf="isAuthenticated() && lastactivecourse.length <= 0 && !loading"
        class="header-develop d-flex align-items-center justify-content-center"
      >
        <div class="row">
          <app-course-preview-home
            [arrayReviews]="arrayReviews"
          ></app-course-preview-home>
        </div>
      </div>
      <div class="header-wrapper container-fluid bg-light-blue pt-5">
        <div class="container" *ngIf="featured?.contents">
          <div class="row mb-5">
            <app-carousel
              [items]="featured!.contents"
              [cols]="3"
              [title]="'home:title:new:courses' | translate"
            ></app-carousel>
          </div>
          <div class="row justify-content-center my-5">
            <div class="col-md-2 col-5">
              <button
                [routerLink]="['/courses']"
                class="btn btn-outline-courses"
              >
                {{ "home:title:all:courses" | translate }}
                <img
                  src="/assets/img/home/arrow-right.png"
                  alt=""
                  class="img-arrow"
                />
              </button>
            </div>
          </div>
        </div>
        <!-- <div class="inclined-bottom bg-white"></div> -->
      </div>
    </ng-template>
  </header>

  <div class="container mt-5 reviews-container">
    <ng-container *ngIf="!org.visible">
      <div class="row mb-5" *ngFor="let section of sections">
        <app-carousel
          [items]="section.contents"
          [cols]="3"
          [title]="section.title"
        ></app-carousel>
      </div>
    </ng-container>
    <app-home-reviews *ngIf="org.visible"></app-home-reviews>
  </div>
</div>

<app-questionaire-notification
  (showNotification)="getInfoNotification()"
  (openSurvey)="acceptAndOpenQuestionnaire()"
  (hideDefinitely)="hideDefinitelyNotification()"
  [quest]="questionnaires[this.lastPosition]"
  *ngIf="showNotification"
></app-questionaire-notification>

 
<app-maintenance-notification
  [data]="maintenance"
  [lang]="currentLang"
  (showNotification)="acceptMaintenance()"
  (openSurvey)="acceptMaintenance()"
  (hideDefinitely)="hideDefinitelyNotificationMaintenance()"
  *ngIf="showNotificationMantenance&&maintenance"
></app-maintenance-notification>

<app-widget-notification
  *ngIf="showWidget"
  (showQuestionaire)="showQuestionnaire()"
></app-widget-notification>

<app-widget-maintenance-notification
  *ngIf="showWidgetMantenance"
  (showMaintenance)="showMaintenance()"
></app-widget-maintenance-notification>