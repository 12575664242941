import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { PlaylistsService } from 'src/app/services/playlists.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-change-owner',
  templateUrl: './change-owner.component.html',
  styleUrls: ['./change-owner.component.scss'],
})
export class ChangeOwnerComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() user: any = {};
  @Input() collection: any = {};
  @Output() refresh = new EventEmitter<any>();
  public selectUsers: any = [];
  public loading: boolean = false;
  public faCircleNorch: any = faCircleNotch;
  public faTimes = faTimes;

  constructor(
    private playlistService: PlaylistsService,
    private toastService: ToastService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {}
  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  public change() {
    this.loading = true;
    this.playlistService
      .changeOwner(this.collection, this.selectUsers[0].id)
      .then((result) => {
        this.loading = false;
        this.toastService.showSuccess(
          this.translate.instant('playlist:change:ownership:success')
        );
        this.refresh.emit();
        this.closeModal();
      })
      .catch((err: any) => {
        this.loading = false;
        this.closeModal();
        console.log(err);
      });
  }
  public getUsersFromSubTeam(event: any) {
    this.selectUsers[event.index].id = event.user?.id;
  }
  usersSelected(data: any) {
    this.selectUsers = data;
  }
}
