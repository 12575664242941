<div (click)="goToPlaylist(activity?.id)" class="thumbnail-item-content clearfix">
    <div class="pull-left the-thumbnail" [ngStyle]="{'background' : 'url('+imageUrl+')'}">

    </div>
    <div class="thumbnail-item-content-text">
        <div class="the-min-height-trick">
            <h2 class="the-item-title">{{activity.name}}
                <div class="the-status-button status-button {{ activity.status }}">{{ 'playlist:library:status:' + activity.status | translate }}</div>


            </h2>
      
            <div class="the-item-text" [innerHtml]="activity.description|htmlToPlainText|excerpt:200"></div>

            
        </div>
    </div>
    <div class="user-name-with-avatar">
        <app-user-icon class="small-avatar-group" [user]="activity.user"></app-user-icon>
        <div class="the-user-name"><span class="by-whom">{{'author:by' | translate}} </span> <span class="the-author ">{{activity.user.display_name}}</span></div>
        <div class="the-video-count ng-binding">
            <fa-icon class="icon" [icon]="faPlayCircle" [styles]="{ 'color': '#636e72'}"></fa-icon>
            {{ activity.video_count }} {{ (activity.video_count == 1 ? "playlist:list:count:label:singular" : "playlist:list:count:label:plural") | translate }}
        </div>
    </div>  
    

</div>
<div class="comments">
    <app-comments-container type="activity_group" [activity]="activities" placeholder="{{ 'comments:placeholder' | translate }}" [title]=false ></app-comments-container>
</div>