import { Injectable } from '@angular/core';
import { Organization } from '../interfaces/organization';
import { InitService } from '../services/init.service';
import { OrganizationsService } from '../services/organizations.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationUtilitiesService {

  constructor(
    private initService: InitService,
    private orgsService: OrganizationsService
  ) { }

  isPublic(org: Organization | null = null): boolean {
    if (!org) {
      org = this.initService.getCurrentOrg();
    }
    return org?.visible!;
  }
}
