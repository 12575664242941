import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  constructor(private api: ApiService) {}

  createTeam(params: any): Promise<any> {


    const randomNumber = Math.floor(Math.random() * 10000000000000);
    const today = new Date();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    const year = today.getFullYear();
    const formattedStartDate = `${month}/${day}/${year}`;
    const title = `${day}/${month}/${year}`;

    const today1 = new Date();
    today1.setFullYear(today1.getFullYear() + 100);
    const month1 = (today1.getMonth() + 1).toString().padStart(2, '0');
    const day1 = today1.getDate().toString().padStart(2, '0');
    const year1 = today1.getFullYear();
    const formattedEndDate = `${month1}/${day1}/${year1}`;

    let tab = {
      id:'1044-'+randomNumber,
      dates: {
        start: formattedStartDate,
        end: formattedEndDate
      },
      message: '',
      title: title,
      users: [],
      users_id:[]
    };



    const defaultParams = {
      hypothetical: false,
      language: 'en',
      open: true,
      public: 'true',
      type: 'dh_team',
      tabs:[tab]
    };

    params = { ...defaultParams, ...params };
    return this.api.postJson('/collection', params).then((res) => {
      if (res.result == 'error') {
        throw Error(res.message.friendly);
      }
      return res;
    });
  }

  public async deleteTeam(teamId: number) {
    return this.api.delete('/collection/' + teamId);
  }

  public async removeUserDH(userid: number) {
    return this.api.delete('/dh_profile/' + userid).catch((err) => {
      return err.message.friendly;
    });
  }

  public async createProfile(team: any, profile: any) {
    //elimino los promedios ya que no quiero que se guarden en el json.
    if (team.tabs){
      team.tabs.forEach((tab:any) => {
        tab.average=[];
      });
    }
    let params = {
      id: team.id,
      sendInvites: true,
      tabs: team.tabs || [],
    };
    
    params.tabs.push(profile);
    console.log('params',params);
     
    const today = new Date();
    today.setDate(today.getDate() - 1);

    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    const year = today.getFullYear();

    const formattedEndDate = `${month}/${day}/${year}`;
    //al ante ultimo tabs le pongo la fecha de cierre al dia de hoy menos 1
    //siempre y cuando no sea el primer tab creado
    if (params.tabs.length>1){
      params.tabs[params.tabs.length-2].dates.end=formattedEndDate;
    }

    return this.api.put('/collection/' + team.id, params).catch((err) => {
      return err.message.friendly;
    });
  }

  public async editTeamSettings(team: any, teamName: string,excludeOwner:boolean=true) {
    let params = {
      exclude_owner: excludeOwner,
      id: team.id,
      name: teamName,
    };
    return this.api.put('/collection/' + team.id, params).catch((err) => {
      return err.message.friendly;
    });
  }

}
