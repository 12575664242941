<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">{{ "groups:profile:add:courses" | translate }}</h4>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="row tabs-row">
      <div class="col text-center">
        <app-movie-tabs-wrapper [main]="true">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active py-3"
              id="activity-tab"
              (click)="getTab('allCourse')"
              type="button"
              role="tab"
            >
              {{ "course:libray:all" | translate }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link py-3"
              id="conversation-tab"
              (click)="getTab('myCourse')"
              type="button"
              role="tab"
            >
              {{ "course:libray:my" | translate }}
            </button>
          </li>
        </app-movie-tabs-wrapper>
      </div>
    </div>
  </div>

  <div class="tab-content" id="myTabContent">
    <div
      class="tab-pane fade show active"
      id="all"
      role="tabpanel"
      aria-labelledby="profile-tab"
    >
      <div class="container py-4">
        <app-search-input
          [inputPlaceholder]="'Search courses'"
          [actualSearch]="this.searchText"
          [loading]="loading"
          [searchText]="'search:button'"
          (searchEvent)="searchAll($event)"
          *ngIf="actualTab == 'allCourse'"
        ></app-search-input>

        <app-search-input
          [inputPlaceholder]="'Search courses'"
          [actualSearch]="this.searchTextOwn"
          [loading]="loading"
          [searchText]="'search:button'"
          (searchEvent)="searchOwn($event)"
          *ngIf="actualTab == 'myCourse'"
        ></app-search-input>

        <div *ngIf="loading" class="row max-heigth">
          <div class="row loadingCenter">
            <app-loading-icon></app-loading-icon>
          </div>
        </div>

        <div
          *ngIf="!loading"
          class="row justify-content-center max-heigth list-overflow"
        >
          <div *ngIf="actualTab == 'allCourse'">
            <div
              *ngFor="let course of courses"
              class="row justify-content-center"
            >
              <div class="col-xl-12 p-0" style="margin-right: 4px">
                <app-course-list-card
                  (selectItem)="selectItem($event)"
                  [itemSelect]="itemSelect"
                  [course]="course"
                ></app-course-list-card>
              </div>
            </div>
            <div *ngIf="loadingMore" class="row py-1">
              <app-loading-icon></app-loading-icon>
            </div>
            <div class="row justify-content-center">
              <div class="col-auto" *ngIf="this.paginator.current_page < this.paginator.pages" >
                <button
                  [disabled]="loadingMore"
                  class="btn btn-secondary py-1"
                  (click)="nextPage()"
                  type="button"
                >
                  {{ "Load more" | translate }}
                </button>
              </div>
            </div>
          </div>

          <div *ngIf="actualTab == 'myCourse'">
            <div
              *ngFor="let courseOwn of coursesOwn"
              class="row justify-content-center"
            >
              <div class="col-xl-12 p-0">
                <app-course-list-card
                  (selectItem)="selectItem($event)"
                  [itemSelect]="itemSelect"
                  [course]="courseOwn"
                ></app-course-list-card>
              </div>
            </div>
            <div class="text-empty-content" *ngIf="coursesOwn.length == 0">
              {{ "courses:list:empty" | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group clearfix">
    <button
      (click)="sendData()"
      [disabled]="itemSelect == ''"
      type="button"
      role="tab"
      class="btn btn-primary btn-create modal-btn-width float-right ms-2 fs-15"
    >
      <fa-icon
        class="ms-2"
        [icon]="faCircleNorch"
        *ngIf="addLoading"
        [spin]="true"
      ></fa-icon>
      {{ "groups:profile:add:courses" | translate }}
    </button>
  </div>
</div>
