<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">{{ "user:profile:playlists:new" | translate }}</h4>
</div>
<div class="modal-body">
  <div class="form-group">
    <label for="clip-title" class="form-label">{{ "name" | translate }}</label>
    <input
      [(ngModel)]="playlist.name"
      class="input-box w-100"
      id="clip-title"
      maxlength="80"
      name="name"
      placeholder="{{ 'playlist:edit:name:placeholder' | translate }}"
      required=""
      type="text"
    />
  </div>

  <div class="form-group">
    <label class="mt-3 form-label" for="clip-title">{{
      "playlist:edit:settings:label" | translate
    }}</label>
    <label class="fancy-radio-checkbox form-label">
      <input
        [(ngModel)]="playlist.open"
        class="fancy-input"
        id="open"
        name="privacyOpen"
        type="radio"
        [value]="true"
      />
      <div class="fancy-graphic"></div>
      {{ "playlist:edit:settings:open" | translate }}
    </label>
    <div class="small-form-text">
      {{ "playlist:edit:settings:open:description" | translate }}
    </div>
    <label class="fancy-radio-checkbox form-label">
      <input
        [(ngModel)]="playlist.open"
        class="fancy-input"
        id="closed"
        name="privacyOpen"
        type="radio"
        value="false"
      />
      <div class="fancy-graphic"></div>
      {{ "playlist:edit:settings:closed" | translate }}
    </label>
    <div class="small-form-text">
      {{ "playlist:edit:settings:closed:description" | translate }}
    </div>
  </div>
  <div *ngIf="forGroup" class="row">
    <p >
      {{ "playlists:create:note:1" | translate }}
      <span class="fw-bold">
        {{ groupName }}
      </span>
      {{ "playlists:create:note:2" | translate }}
    </p>
  </div>
  <div class="form-group clearfix">
    <button
      [disabled]="playlist.name.length < 4"
      (click)="create()"
      type="button"
      role="tab"
      class="btn btn-primary col-auto float-right ms-2 fs-15"
    >
      <fa-icon
        class="mr-2"
        [icon]="faCircleNorch"
        *ngIf="loadSpin"
        [spin]="true"
      ></fa-icon>
      {{ "playlist:create:button" | translate }}
    </button>
  </div>
</div>
