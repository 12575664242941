import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Chapter } from '../interfaces/chapter';
import { Course } from '../interfaces/course';
import { Lesson } from '../interfaces/lesson';
import { Paragraph } from '../interfaces/paragraph';
import { CourseUtilitiesService } from '../utilities/course-utilities.service';
import { CoursesService } from './courses.service';
import { CourseReviewModalComponent } from 'src/app/modals/courses/review-modal/course-review-modal.component'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class CourseViewerService {
  private course$: BehaviorSubject<Course | null> = new BehaviorSubject<Course|null>(null);
  private current$: BehaviorSubject<Lesson | Chapter | Paragraph | undefined> = new BehaviorSubject<Lesson | Chapter | Paragraph | undefined>(undefined);
  private updatingLesson$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private current:{
    type: 'chapter'|'paragraph'|'text'|'video'|'microclip',
    id: number
  } | undefined;

  constructor(
    private router: Router,
    private courseUtils: CourseUtilitiesService,
    private coursesService: CoursesService,
    private ngbModal:NgbModal,
    private auth: AuthService
  ) { }

  setCourseId( id: number ) {
    this.coursesService.getObservableCollection(id).subscribe( c => {
      this.course$.next(c);

      if (this.current) {
        if (this.current?.type == 'text' || this.current?.type == 'video' || this.current?.type == 'microclip') {
          this.current$.next(this.courseUtils.getLesson(c!, this.current?.id));
        } else if (this.current?.type == 'chapter') {
          this.current$.next(this.courseUtils.getChapter(c!, this.current.id));
        } else if (this.current?.type == 'paragraph') {
          this.current$.next(this.courseUtils.getParagraph(c!, this.current.id));
        } else {
          this.current$.next(undefined);
        }
      }
    });
  }

  setCurrentView( type: 'chapter'|'paragraph'|'text'|'video'|'microclip', id: number) {
    this.current = {
      type: type,
      id: id
    }

    if (this.course$.value) {
      if (this.current?.type == 'text' || this.current?.type == 'video' || this.current?.type == 'microclip') {
        this.current$.next(this.courseUtils.getLesson(this.course$.value, this.current?.id));
      } else if (this.current?.type == 'chapter') {
        this.current$.next(this.courseUtils.getChapter(this.course$.value, this.current.id));
      } else if (this.current?.type == 'paragraph') {
        this.current$.next(this.courseUtils.getParagraph(this.course$.value, this.current.id));
      } else {
        this.current$.next(undefined);
      }
    }
  }

  getCourseObservable(): Observable<Course|null> | undefined {
    return this.course$;
  }

  getCurrent(): Observable<Lesson | Chapter | Paragraph | undefined> {
    return this.current$.asObservable();
  }

  getUpdtingObservable(): Observable<boolean> {
    return this.updatingLesson$.asObservable();
  }

  getCurrentType(): string {
    return this.current?.type!;
  }

  getNext(): Lesson | Chapter | Paragraph | undefined {
    // course already loaded
    let course = this.course$.value
    if (course) {
      if (this.current?.type == 'chapter') {
        let chapter = course?.chapters.find( chapter => {
          return chapter.id == this.current?.id;
        });
console.log('aca tengo el chapter primero ', chapter);

        if (chapter?.lessons.length) {
          return chapter?.lessons[0];
        } else if (chapter?.paragraphs.length) {
          return chapter?.paragraphs[0]
        // empty chapter
        } else {
          // go to next chapter
          let ci = course.chapters.findIndex( c => c.id == chapter?.id );
          // is not the last chapter
          if ((ci || ci == 0) && course.chapters.length && ci < (course.chapters.length - 1) ) {
            // go to next chapter view
            
            return course.chapters[ci + 1];
          }
        }
      } else if (this.current?.type == 'paragraph') {
        let paragraph: Paragraph | undefined;

        if (course?.chapters) {
          for (let chapter of course?.chapters) {
            paragraph = chapter.paragraphs.find( paragraph => {
              return paragraph.id == this.current?.id;
            })
            if (paragraph) {
              break
            }
          }
        }

        if (paragraph?.lessons.length) {
          return paragraph?.lessons[0];
        }
      } else if (this.current?.type == 'text' || this.current?.type == 'video' || this.current?.type == 'microclip') {
        // get lesson and chapter
        let lesson = this.courseUtils.getAllLessons(course).find( lesson => lesson.id == this.current?.id );
        let chapter = course.chapters.find( chapter => chapter.id == lesson?.chapter_id)

        // lessons belongs to a paragraph
        if (lesson?.paragraph_id) {
          let paragraph = chapter?.paragraphs.find( paragraph => paragraph.id == lesson?.paragraph_id);
          let i = paragraph?.lessons.findIndex( lesson => lesson.id == this.current?.id )

          // is not the last lessons of the paragraph
          if ((i || i == 0) && paragraph?.lessons.length && i < (paragraph?.lessons.length - 1) ) {
            // go to previous lesson view
            return paragraph?.lessons[i + 1];
          // is the last lessons of the paragraph
          } else {
            // isnt the last paragraph of the chapter
            let pi = chapter?.paragraphs.findIndex( p => p.id == paragraph?.id )
            if ((pi || pi == 0) && chapter?.paragraphs.length && pi < (chapter.paragraphs.length - 1) ) {
              // go to next paragraph view
              return chapter.paragraphs[pi + 1];
            // is the last paragraph of the chapter
            } else {
              // go to next chapter view
              let ci = course.chapters.findIndex( c => c.id == chapter?.id );
              // is not the last chapter
              if ((ci || ci == 0) && course.chapters.length && ci < (course.chapters.length - 1) ) {
                // go to next chapter view
                return course.chapters[ci + 1];
              }
            }
          }
        // lesson don't belogs to a paragraph
        } else {
          // search in chapter
          let i = chapter?.lessons.findIndex( lesson => lesson.id == this.current?.id )

          // lesson is not the last of the chapter
          if ((i || i == 0) && chapter?.lessons.length && i < (chapter.lessons.length - 1) ) {
            // go to next lesson
            return chapter?.lessons[i + 1];
          // lesson is the last of the chapter
          } else {
            // chapter has paragraphs
            if (chapter?.paragraphs.length) {
              // go to first paragraph
              return chapter.paragraphs[0];
            }

            let ci = course.chapters.findIndex( c => c.id == chapter?.id );
            // is not the last chapter
            if ((ci || ci == 0) && course.chapters.length && ci < (course.chapters.length - 1) ) {
              // go to next chapter view
              return course.chapters[ci + 1];
            }
          }
        }
      }
    }
    return undefined
  }

  goNext(): void {
    let next = this.getNext();
    if (next) {
      this.goTo(next);
    }else {
      let course = this.course$.value;
      this.router.navigate(['/courses',course!.id])
      setTimeout(() => {
        window.location.reload();
      }, 300);
    }

    // TODO error: no next lesson found
  }

  getPrev(): Chapter | Paragraph | Lesson | undefined {
    let course = this.course$.value;

    // course already loaded
    if (course) {
      // current is text lesson
      if (this.current?.type == 'text' || this.current?.type == 'video' || this.current?.type == 'microclip') {
        let lesson = this.courseUtils.getAllLessons(course).find( lesson => lesson.id == this.current?.id );
        let chapter = course.chapters.find( chapter => chapter.id == lesson?.chapter_id)

        // lessons belongs to a paragraph
        if (lesson?.paragraph_id) {
          let paragraph = chapter?.paragraphs.find( paragraph => paragraph.id == lesson?.paragraph_id);
          let i = paragraph?.lessons.findIndex( lesson => lesson.id == this.current?.id )

          // is not the first lessons of the paragraph
          if (i && i > 0) {
            // go to previous lesson view
            return paragraph?.lessons[i - 1];
          // is the first lessons of the paragraph
          } else {
            // go to paragraph view
            return paragraph;
          }
        // lesson don't belogs to a paragraph
        } else {
          // search in chapter
          let i = chapter?.lessons.findIndex( lesson => lesson.id == this.current?.id )

          // lesson is not the first of the chapter
          if (i && i > 0) {
            // go to previous lesson
            return chapter?.lessons[i - 1];
          // lesson is the first of the chapter
          } else {
            // go to chapter view
            return chapter;
          }
        }
      // current is a paragraph view
      } else if (this.current?.type == 'paragraph') {
        // get paragraph and chapter
        let i, paragraph: Paragraph|undefined, chapter: Chapter|undefined;
        for (const c of course.chapters) {
          paragraph = c.paragraphs.find( p => p.id == this.current?.id);
          i = c.paragraphs.findIndex( p => p.id == this.current?.id);
          if (paragraph) {
            chapter = c;
            break;
          }
        }

        // isn't the first paragraph of the chapter
        if (i && i > 0) {
          let prevParagraph = chapter?.paragraphs[i - 1];
          // previous chapter has lessons
          if (prevParagraph?.lessons.length) {
            // go to last lesson of previous chapter
            return prevParagraph?.lessons[prevParagraph?.lessons.length - 1];
          } else {
            // go to paragraph view
            return prevParagraph;
          }
        // is the first paragrap of the chapter
        } else {
          // chapter has lessons
          if (chapter?.lessons.length) {
            // go to last lesson of chapter
            return chapter?.lessons[chapter.lessons.length - 1];
          } else {
            // go to chapter view
            return chapter
          }
        }

      // current is a chapter
      } else if (this.current?.type == 'chapter') {
        let chapter = course.chapters.find( c => c.id == this.current?.id );
        let i = course.chapters.findIndex( c => c.id == this.current?.id );

        // chapter is not the first
        if (i && i > 0) {
          let prevChapter = course.chapters[i - 1];
          // previous chapter has paragraphs
          if (prevChapter.paragraphs.length) {
            // last paragraph of previous chapter has lessons
            let lastParagraph = prevChapter.paragraphs[prevChapter.paragraphs.length - 1];
            if (lastParagraph.lessons.length) {
              // go to last lesson of paragraph
              return lastParagraph.lessons[lastParagraph.lessons.length - 1];
            // no lessons
            } else {
              // go to paragraph view
              return lastParagraph;
            }
          // previous chapter dosn't has paragraphs and has lessons
          } else if (prevChapter.lessons.length) {
            // go to last lesson of previous chapter
            return prevChapter?.lessons[prevChapter.lessons.length - 1];
          // no paragraphs and no lessons
          } else {
            // go to chapter view
            return prevChapter;
          }
        }
      }
    }
    return undefined;
  }

  goPrev(): void {
    let prev = this.getPrev();
    if (prev) {
      this.goTo(prev);
    }

    // TODO error: no previous lesson or chapter or paragraph
  }

  getEntityType( entity: Chapter | Lesson | Paragraph ): string | undefined {
    // is chapter
    if (entity && 'Collection_Entity' in entity) {
      return 'chapter';
    // is paragraph
    } else if (entity && 'lessons' in entity && 'position' in entity) {
      return 'paragraph';
    // is lesson
    } else if (entity && !('lessons' in entity) && 'position' in entity) {
      return 'lesson';
    } else {
      // TODO error: entity is not chapter or lesson or paragraph
    }
    return undefined;
  }
  private cantGoToChapter =  new EventEmitter<void>(); // Crea un Subject para controlar que vaya al chapter 
 
  triggerFunction() {
    if (this.callbackFunction) {
      this.callbackFunction(); // Llama a la función de callback
    }
  }
  private callbackFunction: (() => void) | null = null;
   // Método para establecer la función de callback
   setCallback(callback: () => void) {
    this.callbackFunction = callback;
  }
  goTo( entity: Chapter | Lesson | Paragraph ): void {
    // is chapter
    if (this.getEntityType(entity) == 'chapter') {
      if (entity?.access) {
        this.router.navigate([
          'course-viewer',
          this.course$.value?.id,
          'chapter',
          entity.id
        ]);
      }else{
        this.triggerFunction()
      }
    // is paragraph
    } else if (this.getEntityType(entity) == 'paragraph') {
      this.router.navigate([
        'course-viewer',
        this.course$.value?.id,
        'paragraph',
        entity.id
      ]);
    // is lesson
    } else if (this.getEntityType(entity) == 'lesson') {
      this.router.navigate([
        'course-viewer',
        this.course$.value?.id,
        (<Lesson>entity).type == 'text' ? 'text-lesson' : (<Lesson>entity).video_post_id ? 'video-lesson' : 'microclip-lesson',
        entity.id
      ]);
    }

    // TODO error: entity is not chapter or lesson or paragraph
  }

  completeLesson(lesson: Lesson): Promise<Lesson> {
    console.log(this.course$.value)
    this.updatingLesson$.next(true);
    return this.coursesService.completeLesson(this.course$.value!, <Lesson>this.current).then( res => {
      if (res.result == "success") {
        this.updateLesson(lesson, res.data);
        this.updatingLesson$.next(false);
      }
      return res;
    })
  }

  uncompleteLesson(lesson: Lesson): Promise<Lesson> {
    this.updatingLesson$.next(true);
    return this.coursesService.uncompleteLesson(this.course$.value!, <Lesson>this.current).then( res => {
      if (res.result == "success") {
        this.updateLesson(lesson, res.data);
        this.updatingLesson$.next(false);
      }
      return res;
    })
  }

  updateLesson(lesson: Lesson, lesson_user: any): void {

    let course = this.course$.value;
    let valuePrev=this.course$.value?.course_progress;
    course?.chapters.forEach(c => {
      c.lessons.forEach(l => {
        if (l.id == lesson.id) {
          l.completed = lesson_user.completed;
          if (l.lesson_users![0]) {
            l.lesson_users![0] = {...l.lesson_users![0], ...lesson_user}
          }
        }
      });

      c.paragraphs.forEach(p => {
        p.lessons.forEach(l => {
          if (l.id == lesson.id) {
            l.completed = lesson_user.completed;
            if (l.lesson_users![0]) {
              l.lesson_users![0] = {...l.lesson_users![0], ...lesson_user}
            }
          }
        });
      });
    });

    this.courseUtils.calculateProgress(course!);
    if ( course&&valuePrev !< 50 && course.course_progress >= 50 ){
      this.coursesService.getReviews(this.course$?.value?.id as number).then( (rev: any) => {
        let reviews = rev.data.reviews
        let userReview = reviews.find( (r: any) => {
          return r.user_id == this.auth.getMe()?.id;
        });
        if (!userReview) {
          this.reviewModalsShow();
        }
      })
    }
    this.course$.next(course);
    this.current$.next(lesson)
  }

  reviewModalsShow() {
    let modalRef = this.ngbModal.open(CourseReviewModalComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
      centered: true
    });
    modalRef.componentInstance.course = this.course$.value;
  }
  private actionSubject = new Subject<void>();
  action$ = this.actionSubject.asObservable();

  closeMenu() {
    this.actionSubject.next(); // Emitir el evento
  }
}
