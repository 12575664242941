<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header px-1">
  <h5 class="modal-title">
    {{ "collection:requests:title" | translate }}
  </h5>
</div>
<div class="modal-body">
  <div class="row justify-content-center mt-4">
    <div class="col-md-10" *ngIf="pendingRequests.length">
      <div class="row mb-3 py-2" *ngFor="let request of pendingRequests; let i = index">
        <div class="col d-flex">
          <app-user-icon [user]="request.user"></app-user-icon>
          <h6 class="mt-2 ms-2">
            {{ request.user.display_name }}
          </h6>
        </div>
        <div class="col-auto">
          <div
            class="btn-group"
            role="group"
            aria-label="Basic outlined example"
          >
            <button
              type="button"
              class="btn btn-outline-success"
              [disabled]="loadingConfirmRequest"
              (click)="confirmRequest(request.id, i)"
            >
              <i
                *ngIf="request.isLoading"
                class="fas fa-circle-notch fa-spin"
              ></i>
              <i *ngIf="!request.isLoading" class="fas fa-check"></i>
            </button>
            <button
              type="button"
              class="btn btn-outline-danger"
              [disabled]="loadingConfirmRequest"
              (click)="denyRequest(request.id, i)"
            >
              <i
                *ngIf="request.isLoading"
                class="fas fa-circle-notch fa-spin"
              ></i>
              <i *ngIf="!request.isLoading" class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-auto mt-5"
      *ngIf="!pendingRequests.length && !loadingRequests"
    >
      {{ "collection:requests:empty" | translate }}
    </div>
    <app-loading-icon *ngIf="loadingRequests"></app-loading-icon>
  </div>
</div>
<div class="modal-footer">
  <div class="row justify-content-end mt-3">
    <!-- <div class="col-auto px-0">
      <button
        (click)="closeModal()"
        type="button"
        role="tab"
        class="btn btn-outline-primary"
      >
        {{ "btn:close" | translate }}
      </button>
    </div> -->
  </div>
</div>
