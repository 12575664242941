import { AdminService } from './../../../services/admin.service';
import { Component, Input, OnInit } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { InitService } from 'src/app/services/init.service';

@Component({
  selector: 'app-create-new-platform',
  templateUrl: './create-new-platform.component.html',
  styleUrls: ['./create-new-platform.component.scss'],
})
export class CreateNewPlatformComponent implements OnInit {
  faTimes = faTimes;
  @Input() selfModal: NgbModalRef | null = null;
  @Input() title: String = '';
  public idOrganization: number = 0;
  public slugOrganization: string = '';
  loadSpin: boolean = false;
  faCircleNorch: any = faCircleNotch;

  platform = {
    name: '',
    slug: '',
    description: '',
    language: 'en',
    visible: false,
    dh_organization: true,
    show_social_login: false,
    show_okta_login: false,
    show_grip_login: false,
    show_terms_conditions: true,
    show_security_policy: true,
  };
  constructor(
    private adminService: AdminService,
    private toastService: ToastService,
    private translate: TranslateService,
    private initService: InitService
  ) {}

  ngOnInit(): void {}
  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
  
  sanitizeUrlText(inputText: string): string {
    // Reemplaza todos los caracteres no válidos, puntos y barras con guiones ("-")
    const sanitizedText = inputText
      .trim() // Elimina espacios en blanco al principio y al final
      .toLowerCase() // Convierte a minúsculas (opcional, pero es una buena práctica para URLs)
      .replace(/[^\w\d-]+/g, '-') // Reemplaza caracteres no válidos, puntos y barras con guiones
      .replace(/-+/g, '-') // Reemplaza múltiples guiones consecutivos con uno solo
      .replace(/^-+|-+$/g, ''); // Elimina guiones al principio y al final
  
    return sanitizedText;
  }


  createPlatform() {
    this.loadSpin = true;
    this.platform.slug = this.platform.name.split(' ').join('-');
    this.closeModal();
    this.platform.slug=this.sanitizeUrlText(this.platform.slug);

    this.adminService
      .createPlatform(this.platform)
      .then((resp) => {
        this.toastService.showSuccess(
          this.translate.instant('organization:create:success')
        );
        console.log(resp);
        this.idOrganization = resp.data.id;
        this.slugOrganization = resp.data.slug;
        this.loadSpin = false;
        this.closeModal();

        this.switchOrganization();
      })
      .catch((err) => {
        console.log(err);
        this.loadSpin = false;
        this.toastService.showError(
          this.translate.instant('organization:create:error')
        );
      });
  }

  switchOrganization(): void {
    const currentOrg = this.initService.getCurrentOrg();
    if (currentOrg && currentOrg.slug != this.platform.slug) {
      let aDomain = window.location.host.split('.');

      aDomain[0] = this.slugOrganization;
      window.location.href =
        window.location.protocol +
        '//' +
        aDomain.join('.') +
        '/organization/' +
        this.idOrganization +
        '/settings';
    } else {
      window.location.reload();
    }
  }
}
