import { Injectable } from '@angular/core';
import { DashboardSection } from '../interfaces/dashboard-section';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private api: ApiService
  ) {
  }

  getNewSections(type: string | undefined = undefined): Promise<Array<DashboardSection>> {
    let url = '/dashboard/newsections?limit=999999&published=true';
    if (type) {
      url += '&type=' + type;
    }

    return new Promise( (resolve, reject) => {
      this.api.get(url).then( data => {
        if (data.result == 'success') {
          resolve(data.data);
        }
      }).catch( err => reject(err) );
    });
  }

  getPreviewLessons(search: string | undefined = undefined, limit = 10, offset = 0) {
    let url = '/dashboard/preview_lessons';

    url += '?limit=' + limit;
    url += '&offset=' + offset;

    if (search) {
      url += '&q=' + search;
    }

    return this.api.get(url).then( (res) => {
      return res.data;
    });
  }


  getReviews(search: string | undefined = undefined, limit = 10, offset = 0) {
    let url = '/dashboard/reviews';

    url += '?limit=' + limit;
    url += '&offset=' + offset;

    if (search) {
      url += '&q=' + search;
    }

    return this.api.get(url).then( (res) => {
      return res.data;
    });
  }

}
