import { Component, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InvoiceDetails } from 'src/app/interfaces/order';
import { CountryService } from 'src/app/services/country.service';
import { OrdersService } from 'src/app/services/orders.service';
import { ToastService } from 'src/app/services/toast.service';
import { CheckoutQuestionnaireService } from '../checkout-questionnaire.service';


@Component({
  selector: 'app-method-tab',
  templateUrl: './method-tab.component.html',
  styleUrls: ['./method-tab.component.scss']
})
export class MethodTabComponent implements OnInit {
  countries: Array<any> = [];
  paymentMethods: Array<string> = [];
  vatInfo: any | undefined;

  constructor(
    public checkout: CheckoutQuestionnaireService,
    private ordersService: OrdersService,
    private countriesService: CountryService,
  ) { }

  ngOnInit(): void {
    this.paymentMethods = this.ordersService.getPaymentMethods();
    this.countries = this.countriesService.getCountryList();
    console.log('this.checkout',this.checkout);
  }

  asInvoiceDetails(val: any): InvoiceDetails {
    return val;
  }
}
