<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 *ngIf="!pending" class="modal-title">
    {{
      "organization:members:selected_users_remove:title"
        | translate : { count: numbAccounts }
    }}
  </h4>
  <h4 *ngIf="pending" class="modal-title">
    {{
      "organization:members:selected_users_pending"
        | translate 
    }}
  </h4>
</div>
<div class="modal-body flex-column mx-2">
  <div *ngIf="!pending" class="row">
    <p class="description">
      {{
        "organization:members:selected_users_remove:text"
          | translate : { count: numbAccounts }
      }}
    </p>
  </div>
  <div class="users">
    <div class="row mt-2" *ngFor="let user of users">
      <div class="col-1">
        <td class="align-middle">
          <app-user-icon size="medium" [user]="user"></app-user-icon>
        </td>
      </div>
      <div class="col ms-3">
        <p class="mb-0">{{ user?.name }}</p>
        <p class="mb-0" style="color: #969696">{{ user?.email }}</p>
      </div>
    </div>
  </div>
</div>
<div class="row justify-content-end modal-footer">
  <!-- <hr class="common-separator my-0" /> -->
  <div class="row justify-content-end text-center">
    <div class="col-auto">
      <button
        type="button"
        [disabled]="loading"
        class="btn btn-outline-primary ms-2 fs-15 text-nowrap"
        (click)="closeModal()"
      >
        {{ "Cancel" | translate }}
      </button>
    </div>
    <div class="col-auto">
      <button
        (click)="removeMembers()"
        type="button"
        [disabled]="loading"
        role="tab"
        class="btn btn-primary text-nowrap"
      >
        <fa-icon
          class="mr-2"
          [icon]="faCircleNorch"
          *ngIf="loading"
          [spin]="true"
        ></fa-icon>
        {{ "Yes, remove" | translate }}
      </button>
    </div>
  </div>
</div>
