import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DurationPipe } from './duration.pipe';
import { TimePipe } from './time.pipe';
import { htmlToPlainTextPipe } from './htmlToPlainText.pipe';
import { excerptPipe } from './excerpt.pipe';
import { orderByPipe } from './orderBy.pipe';
import { ArraySortPipe } from './sort.pipe';
import { FilterPipe } from './filter.pipe';
import { timeAgo } from './timeAgo.pipe';

@NgModule({
  declarations: [
    DurationPipe,
    TimePipe,
    htmlToPlainTextPipe,
    excerptPipe,
    orderByPipe,
    ArraySortPipe,
    FilterPipe,
    timeAgo
  ],
  imports: [CommonModule],
  exports: [
    DurationPipe,
    TimePipe,
    htmlToPlainTextPipe,
    excerptPipe,
    orderByPipe,
    ArraySortPipe,
    FilterPipe,
    timeAgo
  ],
})
export class PipesModule {}
