<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">
    {{ "admin:users:btn:edit" | translate }}
  </h4>
</div>
<div class="modal-body p-4">
  <div class="row" *ngIf="loading; else content">
    <app-loading-icon></app-loading-icon>
  </div>
  <ng-template #content>
    <div class="row mb-2">
      <div class="col-auto">
        <app-user-icon size="medium" [user]="data?.user"></app-user-icon>
      </div>
      <div class="col-8">
        <div class="row">
          <div class="d-flex justify-content-between">
            <p class="mb-0 title-user">{{ data?.user?.display_name }}</p>
          </div>
          <p class="mb-0" style="color: #302e2e">@{{ data?.user?.username }}</p>
        </div>
      </div>
      <div class="col-auto">
        <p (click)="openViewOrder(data)" class="fw-bold custom-color">
          {{ "admin:users:modal:edit:btn:view_order" | translate }}
        </p>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-3">
        <div class="title-desc">
          {{ "admin:users:modal:edit_courses:label:1" | translate }}
        </div>
        <p class="subtitle fw-bold">{{ data?.name }}</p>
      </div>
      <div class="col-3">
        <div class="title-desc">
          {{ "admin:users:modal:edit_courses:label:2" | translate }}
        </div>
        <p class="subtitle fw-bold">
          {{ data?.created_at | date : "dd LLL YYYY" }}
        </p>
      </div>
      <div class="col-3">
        <div class="title-desc">
          {{ "admin:users:modal:edit_courses:label:3" | translate }}
        </div>
        <p class="subtitle fw-bold">
          {{ data?.created_at | date : "dd LLL YYYY" }}
        </p>
        <p
          [ngClass]="{
            'custom-color': data?.payment_status == 'paid',
            'custom-color2': data?.payment_status == 'pending'
          }"
          class="subtitle fw-bold"
        >
          {{ data?.payment_status | titlecase }}
        </p>
      </div>
      <div class="col-3">
        <div class="title-desc">
          {{ "admin:users:modal:edit_courses:label:4" | translate }}
        </div>
        <p class="subtitle fw-bold">
          {{ data?.platforms }}
        </p>
      </div>
    </div>
  </ng-template>

  <hr class="common-separator" />
  <div class="form-group">
    <div class="row justify-content-end mx-1">
      <button
        (click)="closeModal()"
        type="button"
        role="tab"
        class="btn btn-primary col-auto float-right"
      >
        {{ "Cancel" | translate }}
      </button>
    </div>
  </div>
</div>
