import { UsersAdminDetailComponent } from './../../modals/admin/users/users-admin-detail/users-admin-detail.component';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-table-admin-users',
  templateUrl: './table-admin-users.component.html',
  styleUrls: ['./table-admin-users.component.scss'],
})
export class TableAdminUsersComponent implements OnInit {
  @Output() scrolled = new EventEmitter<null>();
  @Input() listUsers: any[] = [];
  @Output() refreshTable = new EventEmitter<any>();

  @ViewChild('container') container!: ElementRef;
  constructor(
    private organizationService: OrganizationsService,
    private toastService: ToastService,
    private ngbModal: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {}
  @HostListener('window:scroll', ['$event'])
  onScroll(e: any) {
    let bottomPosition =
      this.container.nativeElement.getBoundingClientRect().top +
      this.container.nativeElement.offsetHeight;
    if (bottomPosition < window.innerHeight) {
      this.scrolled.emit();
    }
  }
  public openDetail(userName: any) {
    let modalRef = this.ngbModal.open(UsersAdminDetailComponent, {
      windowClass: 'default-modal video-modal',
      size: '900',
      centered: true,
    });
    modalRef.componentInstance.userName = userName;
    modalRef.componentInstance.refresh.subscribe((data: any) => {
      this.refreshTable.emit();
    });
    modalRef.componentInstance.openAgain.subscribe((data: any) => {
      this.openDetail(userName);
    });
  }
  mapOrg(org: []) {
    return org.map((obj: any) => obj.name).join(', ');
  }
}
