<div class="container">
  <div class="continue-watching row">
    <div
      *ngIf="image"
      class="the-image col-md-3 col-sm-12 px-0"
      [ngStyle]="{ 'background-image': 'url(' + image + ')'  }"
    >
    <!-- <img src="../../../assets/img/groups/icons/list.png" alt=""> -->
      <!-- <div class="item-type ng-binding">{{course.type}}</div> -->
    </div>
    <div class="col-md-6 col-sm-12 col-7">
      <div class="row">
        <div *ngIf="courseProgress() < 100">
          <div class="col-auto continue-watching-contents">
            <h3 *ngIf="!isFirstLesson()" class="h3-title">
              {{ "continueWatching:title" | translate }}:
            </h3>
            <h3 *ngIf="isFirstLesson()" class="h3-title">
              {{ "course:start:title" | translate }}
            </h3>
            <h3 class="chapter-intro-title">{{ lesson?.name }}</h3>
            <!-- <h2 class="ng-binding progress-bar-text">{{ "continueWatching:title" | translate }}: {{ lesson.name }}</h2> -->
            <span class="chapter-title" *ngIf="chapter?.name"
              >{{ "courseviewer:chapter" | translate }}:
              {{ chapter.name }}</span
            >
          </div>
        </div>

        <div class="flex-center completed-text ps-4" *ngIf="courseProgress() >= 100">
          {{ "course:completed:title" | translate }}
        </div>
      </div>
    </div>
    <div class="col-5 col-md flex-center mb-md-0">
      <button
        *ngIf="courseProgress() < 100 && isFirstLesson()"
        class="btn btn-sm button--confirm-light continue-learning-button"
        (click)="goToChapter(course?.id, lesson?.chapter_id)"
      >
        {{ "course:start:btn" | translate }}
      </button>
      <button
        *ngIf="courseProgress() < 100 && !isFirstLesson()"
        (click)="goToChapter(course?.id, lesson?.chapter_id)"
        class="btn btn-sm button--confirm-light continue-learning-button"
      >
        {{ "continueWatching:button:continue" | translate }}
      </button>
      <span
        *ngIf="courseProgress() >= 100"
        class="fa fa-check-circle pull-right check-circle"
      ></span>
    </div>
  </div>
</div>
