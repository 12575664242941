import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { User } from '../interfaces/user';
import { AuthService } from '../services/auth.service';
import { InitService } from '../services/init.service';

@Injectable({
  providedIn: 'root'
})
export class CollectionUtilitiesService {

  constructor(private initService: InitService, private auth: AuthService) {}

  isOpen(course: any): boolean {
    return course.open;
  }

  userIsEnrolled(course: any): boolean {
    return course.status === 'enrolled';
  }

  userWasEnrolled(course: any): boolean {
    return course.status === 'renew_subscription';
  }

  userSubscriptionExpired(course: any): boolean {
    return (
      moment(new Date(course.finish_subscription)).isBefore(moment()) == true
    );
  }

  userIsOwner(course: any | null, user: User | undefined = undefined) {
    if (!user) {
      user = this.auth.getMe()!;
    }
    return (
      user && course && course.user && course.user.username === user.username
    );
  }

  userCanEdit(course: any, user: User | undefined = undefined) {
    if (!user) {
      user = this.auth.getMe()!;
    }

    if (
      this.auth.hasAccess('edit_all_collections') ||
      this.userIsOwner(course, user)
    ) {
      return true;
    }

    return false;
  }

  userIsInvited(course: any): boolean {
    return (
      course.status === 'accept_invitation' || course.status === 'accept_gift'
    );
  }
}
