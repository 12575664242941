import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/services/admin.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.scss'],
})
export class DeleteUserComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Output() refresh = new EventEmitter<any>();
  @Input() userId: any = 0;
  @Input() member: any;

  public faCircleNorch: any = faCircleNotch;

  public faTimes = faTimes;
  public loadSpin: boolean = false;

  constructor(
    private toastService: ToastService,
    private adminService: AdminService
  ) {}

  ngOnInit(): void {}

  public deleteUser() {
    this.loadSpin = true;
    this.adminService
      .deleteUser(this.userId)
      .then((resp) => {
        if (resp.result == 'success') {
          this.toastService.showSuccess('The user was successfully removed.');
          this.loadSpin = false;
          this.refresh.emit();
          this.closeModal();
        } else {
          this.loadSpin = false;
          this.toastService.showError('Error');
        }
      })
      .catch((error) => {
        this.loadSpin = false;
        this.toastService.showError(error);
      });
  }

  public closeModal() {
    if (this.loadSpin) return;
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
}
