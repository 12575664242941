import { PlaylistsService } from 'src/app/services/playlists.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { VideoService } from 'src/app/services/video.service';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { CollectionService } from 'src/app/services/collection.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { Video } from 'src/app/interfaces/video/video';

@Component({
  selector: 'app-add-video-group-modal',
  templateUrl: './add-video.component.html',
  styleUrls: ['./add-video.component.scss'],
})
export class AddVideoComponent implements OnInit {
  @Output() sendIdVideo: EventEmitter<any> = new EventEmitter();
  @Output() refresh: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() success: EventEmitter<any> = new EventEmitter();

  faTimes = faTimes;
  @Input() selfModal: NgbModalRef | null = null;
  @Input() title: string = '';
  @Input() group: any;
  @Input() entities: any;
  @Input() playlist: any;
  @Input() course: boolean = false;
  @Input() addOnPlaylist: boolean = false;
  @Input() addOnCourse: boolean = false;
  @Input() addOnLessons: boolean = false;
  @Input() addOnGroup: boolean = false;

  videos: any;
  videosOwn: any;
  faCircleNorch = faCircleNotch;
  loading = true;
  addLoading = false;
  actualTab = 'allVideo';
  username: any;
  itemSelect = '';
  public itemsSelected: any = [];
  searchText = '';
  searchTextOwn = '';
  groupName = '';

  constructor(
    private videoService: VideoService,
    private collectionService: CollectionService,
    public auth: AuthService,
    private toastService: ToastService,
    private translate: TranslateService,
    private playlistService: PlaylistsService
  ) {
    this.auth.user.subscribe((user) => {
      this.username = user?.username;
    });
  }

  ngOnInit(): void {
    this.getVideoAll();
    this.getVideoOwn();
    //
  }
  getVideoAll() {
    let excluded = this.group?.id || undefined,
      owner = '';
    this.loading = true;
    this.videoService
      .searchAllVideos(50, 1, this.searchText, owner, false, excluded)
      .then((res) => {
        this.videos = res.data.videos;
        if (this.entities) {
          this.videos.forEach((obj1: any) => {
            const matchingObj = this.entities.find(
              (obj2: any) => obj2.object.source_id === obj1.source_id
            );
            if (matchingObj) {
              obj1.appearances = matchingObj.appearances;
            }
          });
        }
        console.log('videos resultante', this.videos);

        this.loading = false;
      });
  }
  getVideoOwn() {
    let excluded = this.group?.id || undefined;
    this.videoService
      .getAllVideo(20, 1, this.searchText, this.username, false, excluded)
      .then((res) => {
        this.videosOwn = res.data.videos;
      });
  }

  getTab(tab: string) {
    this.actualTab = tab;
  }
  closeModal() {
    if (this.selfModal) {
      this.selfModal?.close();
    } else {
      $('ngb-modal-window').click();
    }
    this.close.emit();
  }
  selectItem(value: string) {
    const index = this.itemsSelected.findIndex(
      (element: any) => element.entity_id === value
    );
    let myVideo = this.videos.find((video: any) => video.id === value);
    let name = '';
    let description = '';
    if (myVideo) name = myVideo.info[0].name;
    description = myVideo.info[0].description;

    if (index === -1) {
      this.itemsSelected.push({
        entity_id: value,
        type: 'video',
        name: '',
        description: '',
      });
    } else {
      this.itemsSelected.splice(index, 1);
    }

    console.log(this.itemsSelected);

    this.itemSelect = value;
  }

  sendData() {
    this.addLoading = true;
    if (this.addOnPlaylist) {
      this.playlistService
        .postVideoEntity(this.group.id, this.itemsSelected)
        .then((resp) => {
          this.toastService.showSuccess(
            this.translate.instant('collection:add:video:success')
          );
          this.addLoading = false;
          console.log(this.itemSelect);

          // let videoEntityId = resp.data?.collection?.entities.filter(
          //   (item: any) => this.itemSelect == item.entity_id
          // );

          // let videoEntity = this.videos.filter((item: any) => {
          //   return item.id == this.itemSelect;
          // });

          // videoEntity[0].id = videoEntityId[0].id; //

          //////////////////
          /////////////////
          //////////////////
          let videoEntityId = resp.data?.collection?.entities.filter(
            //obtengo los id de los videos
            (item: any) =>
              this.itemsSelected.some(
                (selectedItem: any) => selectedItem.entity_id === item.entity_id
              )
          );

          let videoEntity = this.videos.filter((item: any) => {
            // obtengo los videos seleccionados de la lista de videos completa
            return this.itemsSelected.some(
              (selectedItem: any) => selectedItem.entity_id === item.id
            );
          });

          videoEntity.forEach((idItem: any) => {
            // remplazo los id de cada video si coinciden con el id
            const matchingEntity = videoEntityId.find(
              (entity: any) => entity.entity_id === idItem.id
            );
            if (matchingEntity) {
              idItem.id = matchingEntity.id;
            }
          });
          this.refresh.emit(videoEntity);
          this.closeModal();
        })
        .catch((err: any) => {
          console.log(err);
          this.toastService.showError('Error adding video');
        });
    } else if (this.addOnLessons) {
      console.log('this.itemSelect', this.itemSelect);
      this.toastService.showSuccess(this.translate.instant('Video added'));
      this.addLoading = false;
      this.sendIdVideo.emit(this.itemsSelected);
      this.closeModal();
    } else if (this.addOnCourse) {
      console.log('this.itemSelect', this.itemSelect);
      this.toastService.showSuccess(this.translate.instant('Video added'));
      this.addLoading = false;
      this.sendIdVideo.emit(this.itemSelect);
      this.closeModal();
    } else if (this.addOnGroup) {
      this.collectionService
        .setCollection('video', this.itemSelect, this.group.id)
        .then((res) => {
          this.addLoading = false;
          this.toastService.showSuccess(
            this.translate.instant('group:add:video:success')
          );
          this.closeModal();
        })
        .catch((err: any) => {
          console.log(err);
          this.toastService.showError('Error');
        });
    } else {
      this.success.emit(this.findSelectedVideo());
      this.closeModal();
    }
  }

  searchAll(text: string) {
    this.searchText = text;
    this.getVideoAll();
  }
  searchOwn(text: string) {
    this.searchTextOwn = text;
    this.getVideoOwn();
  }

  findSelectedVideo() {
    let video = this.videos.find((v: Video) => v.id == +this.itemSelect);
    if (!video) {
      video = this.videosOwn.find((v: Video) => v.id == +this.itemSelect);
    }
    return video;
  }
}
