import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.scss'],
})
export class IntroductionComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;

  text = this.translate.instant('dh_profile:introduction:body:short');
  textoCompleto = this.translate.instant('dh_profile:introduction:body');

  showLongText = false;
  constructor(private translate: TranslateService) {}

  ngOnInit(): void {}
  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  showMore() {
    this.showLongText = !this.showLongText;
  }
}
