<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title">{{ "Details" | translate }}</h4>
</div>
<div class="modal-body flex-column">
  <div *ngIf="loading; else content" class="row justify-content-center">
    <div class="col">
      <app-loading-icon></app-loading-icon>
    </div>
  </div>
  <ng-template #content>
    <div class="row mb-4">
      <div class="col-auto">
        <app-user-icon size="medium" [user]="avatar"></app-user-icon>
      </div>
      <div class="col-11">
        <div class="row">
          <div class="d-flex justify-content-between">
            <p class="mb-0 title-user">{{ data?.display_name }}</p>
            <span
              class="me-3"
              [ngClass]="{ active: !status, 'text-danger': status }"
              >{{ !status ? "Active" : "Deactivated" }}</span
            >
          </div>
          <p class="mb-0" style="color: #302e2e">@{{ data?.username }}</p>
        </div>
        <div class="row mt-4">
          <div class="col-6">
            <div class="title-desc">
              {{ "admin:users:info:title:1" | translate }}
            </div>
            <p class="subtitle fw-bold">{{ data?.email }}</p>
          </div>
          <div class="col-6">
            <div class="title-desc">
              {{ "admin:users:info:title:2" | translate }}
            </div>
            <p class="subtitle fw-bold">{{ data?.organizations[0]?.name }}</p>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="col-4 d-flex justify-content-between">
            <a class="btn btn-link" [href]="'mailto:' + data?.email" target="_blank" >{{
              "admin:users:btn:email" | translate
            }}</a>
            <button (click)="openEdit(data)" class="btn btn-outline-primary">
              {{ "admin:users:btn:edit" | translate }}
            </button>
          </div>
          <div class="col-auto me-3">
            <div
              ngbDropdown
              class="d-inline-block float_right"
              placement="bottom-right"
            >
              <button
                type="button"
                class="btn btn-outline-primary"
                id="dropdownBasic1"
                ngbDropdownToggle
              >
                <i class="fa fa-ellipsis-h"></i>
              </button>
              <div
                ngbDropdownMenu
                aria-labelledby="dropdownBasic1"
                class="mt-1"
              >
                <button
                  (click)="
                    deactivateMember(data?.id, data?.account_deactivated)
                  "
                  [ngClass]="{
                    active: data?.account_deactivated,
                    'text-danger': !data?.account_deactivated
                  }"
                  ngbDropdownItem
                >
                  {{ data?.account_deactivated ? "Activate" : "Deactivate" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center my-5">
      <div class="col-auto">
        <app-movie-tabs-wrapper [main]="true">
          <li class="nav-item">
            <button
              (click)="getTab('order')"
              class="nav-link active py-3"
              data-bs-toggle="tab"
              type="button"
              role="tab"
            >
              {{ "admin:users:info:tab:1" | translate }}
            </button>
          </li>
          <li class="nav-item">
            <button
              (click)="getTab('platforms')"
              class="nav-link py-3"
              data-bs-toggle="tab"
              type="button"
              role="tab"
            >
              {{ "admin:users:info:tab:2" | translate }}
            </button>
          </li>
          <li class="nav-item">
            <button
              (click)="getTab('courses')"
              class="nav-link py-3"
              data-bs-toggle="tab"
              type="button"
              role="tab"
            >
              {{ "admin:users:info:tab:3" | translate }}
            </button>
          </li>
        </app-movie-tabs-wrapper>
      </div>
    </div>
    <div *ngIf="currentTab == 'order'" class="row">
      <div class="table-responsive-sm mb-4">
        <table class="table table-hover" #container>
          <thead>
            <tr class="" [ngClass]="{ 'custom-border': true }">
              <th scope="col" class="my-3 align-middle px-4">
                <span>
                  {{ "admin:users:info:table:orders:th:1" | translate }}
                </span>
              </th>

              <th scope="col" class="my-3 align-middle px-1">
                <span>
                  {{ "admin:order:datails:th:2" | translate }}
                </span>
              </th>

              <th scope="col" class="my-3 align-middle">
                <span>
                  {{ "admin:users:info:table:orders:th:2" | translate }}
                </span>
              </th>
              <th scope="col" class="my-3 align-middle">
                <span>
                  {{ "Info" | translate }}
                </span>
              </th>
              <th scope="col" class="my-3 align-middle">
                <span>
                  {{ "admin:users:info:table:orders:th:4" | translate }}
                </span>
              </th>
              <th scope="col" class="my-3 align-middle">
                <span>
                  {{ "admin:users:info:table:orders:th:5" | translate }}
                </span>
              </th>
              <th class="text-center" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              [ngClass]="{ 'custom-border': true }"
              *ngFor="let item of listOrders; let i = index"
            >
              <td
                (click)="openDetailOrder(item.id)"
                class="align-middle id-order px-4"
              >
                #{{ item?.id }}
              </td>
              <td class="title-course align-middle">
                {{ item?.created_at | date : "dd LLL YYYY" }}
              </td>
              <td class="title-course align-middle">
                {{ item?.product_name.includes('teamroleprofile:checkout:product:name')?((item?.product_name|translate)| excerpt : 45):(item?.product_name | excerpt : 45) }}
              </td>
              <td class="title-course align-middle">
                {{ item?.entries[0].subscription_days }}
              </td>
   
              <td
                [ngClass]="{
                  'custom-color': item?.payment_status == 'paid',
                  'custom-color2': item?.payment_status == 'pending'
                }"
                class="align-middle"
              >
                {{ item?.payment_status | titlecase }}
              </td>
              <td class="align-middle fw-bold">
                €{{ item?.total | number : "1.2-2" }}
              </td>
              <td colspan="3" class="align-middle text-center">
                <div
                  ngbDropdown
                  class="d-inline-block float_right"
                  placement="bottom-right"
                >
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    id="dropdownBasic1"
                    ngbDropdownToggle
                  >
                    <i class="fa fa-ellipsis-h"></i>
                  </button>
                  <div
                    ngbDropdownMenu
                    aria-labelledby="dropdownBasic1"
                    class="mt-1"
                  >
                    <button (click)="openDetailOrder(item?.id)" ngbDropdownItem>
                      {{
                        "organization:settings:users:table:dropdown:item:1"
                          | translate
                      }}
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row justify-content-center">
          <app-empty-content
            class="py-1"
            [primaryText]="'No orders.' | translate"
            *ngIf="!listOrders?.length && !loading"
          ></app-empty-content>
        </div>
      </div>
    </div>
    <div *ngIf="currentTab == 'platforms'" class="row">
      <div class="table-responsive-sm mb-4">
        <table class="table table-hover">
          <thead>
            <tr class="" [ngClass]="{ 'custom-border': true }">
              <th scope="col" class="my-3 align-middle px-4">
                <span>
                  {{ "admin:users:info:table:platforms:th:1" | translate }}
                </span>
              </th>
              <th scope="col" class="my-3 align-middle">
                <span>
                  {{ "admin:users:info:table:platforms:th:2" | translate }}
                </span>
              </th>

              <th scope="col" class="my-3 align-middle">
                <span>
                  {{ "admin:users:info:table:platforms:th:4" | translate }}
                </span>
              </th>
              <th scope="col" class="my-3 align-middle">
                <span>
                  {{ "admin:users:info:table:platforms:th:5" | translate }}
                </span>
              </th>

              <th class="text-center" scope="col"></th>
            </tr>
          </thead>
          <tbody class="body-platforms">
            <tr
              [ngClass]="{ 'custom-border': true }"
              *ngFor="let item of platforms; let i = index"
            >
              <td class="title-course align-middle text-nowrap">
                {{ item?.name | excerpt : 35 }}
              </td>
              <td class="align-middle item-table">
                {{ item?.created_at | date : "dd LLL YYYY" }}
              </td>
              <td class="align-middle item-table">
                {{ item?.visible ? "Active" : "Inactive" }}
              </td>
              <td class="align-middle item-table">
                {{ item?.Organization_Member?.type | titlecase }}
              </td>

              <td class="align-middle text-center">
                <div
                  ngbDropdown
                  class="d-inline-block float_right"
                  placement="bottom-right"
                >
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    id="dropdownBasic1"
                    ngbDropdownToggle
                  >
                    <i class="fa fa-ellipsis-h"></i>
                  </button>
                  <div
                    ngbDropdownMenu
                    aria-labelledby="dropdownBasic1"
                    class="mt-1"
                  >
                    <button
                      (click)="openRemoveFromPlatform(item)"
                      ngbDropdownItem
                    >
                      {{
                        "admin:users:info:table:platforms:dropdown:1"
                          | translate
                      }}
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row justify-content-center">
          <app-empty-content
            class="py-1"
            [primaryText]="'No platforms.' | translate"
            *ngIf="!platforms?.length && !loading"
          ></app-empty-content>
        </div>
      </div>
    </div>
    <div *ngIf="currentTab == 'courses'" class="row">
      <div class="table-responsive-sm mb-4">
        <table class="table table-hover">
          <thead>
            <tr class="" [ngClass]="{ 'custom-border': true }">
              <th scope="col" class="my-3 align-middle px-4" style="width: 40%;">
                <span>
                  {{ "admin:users:info:table:courses:th:1" | translate }}
                </span>
              </th>
              <th scope="col" class="my-3 align-middle px-1">
                <span>
                  {{ "Invited" | translate }}
                </span>
              </th>
              <th scope="col" class="my-3 align-middle">
                <span>
                  {{ "admin:users:info:table:courses:th:2" | translate }}
                </span>
              </th>
              <th scope="col" class="my-3 align-middle">
                <span>
                  {{ "Sub lenght" | translate }}
                </span>
              </th>
              <th scope="col" class="my-3 align-middle">
                <span>
                  {{ "admin:users:info:table:courses:th:3" | translate }}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              [ngClass]="{ 'custom-border': true }"
              *ngFor="let item of coursesList; let i = index"
            >
              <td class="title-course align-middle text-nowrap id-order"
              (click)="openCourse(item.course_id)">
              {{ (item.name|translate) | excerpt : 45 }}
                
               <div *ngIf="item.competency" class="row">
                <p class="compentency-table m-0 mt-1">
                  {{ item.competencies.length }}
                  {{
                    (item.competencies.length > 1
                      ? "focus:competencies:sub:text:s"
                      : "focus:competencies:sub:text"
                    ) | translate
                  }}
                  x
                  {{
                    item.compentencyPrice
                      | currency
                        : "EUR"
                        : "symbol"
                        : (item.compentencyPrice % 1 === 0 ? "1.0-2" : "1.2-2")
                  }}
                  =
                  {{
                    item.competencies.length * compentencyPrice
                      | currency
                        : "EUR"
                        : "symbol"
                        : ((item.competencies.length * compentencyPrice) % 1 ===
                          0
                            ? "1.0-2"
                            : "1.2-2")
                  }}
                </p>

                <p class="mb-0 compentency-table">
                  <ng-container
                    *ngFor="let competence of item.competencies; let last = last"
                  >
                    {{
                      "dh_profile:competences:level:" + 3 + ":" + competence
                        | translate
                    }}<span *ngIf="!last">, </span>
                  </ng-container>
                </p>




              </div>
              </td>
              <td class="align-middle item-table">
                {{ item.invited?(item.invited| date : "dd LLL YYYY"):'' }}
              </td>
              
              <td class="align-middle item-table">
                 {{ item?.start_date | date : "dd LLL YYYY" || "-" }}
              </td>
              <td class="align-middle item-table">
                {{ item?.subscription_days }}
             </td>
              <td class="align-middle item-table">
                {{
                  (item?.end_date | date : "dd LLL YYYY") || "-"
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row justify-content-center">
          <app-empty-content
            class="py-1"
            [primaryText]="'No courses.' | translate"
            *ngIf="!courses?.length && !loading"
          ></app-empty-content>
        </div>
      </div>
    </div>
  </ng-template>
</div>
