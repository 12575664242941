import { HowItworksComponent } from './../../modals/home/how-itworks/how-itworks.component';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VideoUtilitiesService } from 'src/app/utilities/video-utilities.service';

@Component({
  selector: 'app-personal-development',
  templateUrl: './personal-development.component.html',
  styleUrls: ['./personal-development.component.scss'],
})
export class PersonalDevelopmentComponent implements OnInit {
  @Output() scroll = new EventEmitter<string>();

  public infoCardsDevelopment: any = [
    {
      icon: '/assets/img/home/hat-icon.png',
      title: 'home:usp:leadership:title',
      description: 'home:usp:leadership',
      border: '2px solid rgba(255, 204, 0, 0.24)',
    },
    {
      icon: '/assets/img/home/grafic-icon.png',
      title: 'home:usp:teamwork:title',
      description: 'home:usp:teamwork',
      border: '2px solid rgba(65, 166, 42, 0.24)',
    },
    {
      icon: '/assets/img/home/people-icon.png',
      title: 'home:usp:youthcare:title',
      description: 'home:usp:youthcare',
      border: '2px solid rgba(0, 158, 224, 0.24)',
    },
  ];
  constructor(
    private modalService: NgbModal,
    private videoUtils: VideoUtilitiesService
  ) {}

  ngOnInit(): void {}
  openVideoModal() {
    let idVideo = '0_8t8c0m41';
    if (this.videoUtils.currentLang == 'nl') {
      idVideo = '0_8t8c0m41';
    } else {
      idVideo = '0_59pzi8ai';
    }
    let modalRef = this.modalService.open(HowItworksComponent, {
      windowClass: 'default-modal video-modal',
      size: 'lg',
    });
    modalRef.componentInstance.videoId = idVideo;
    modalRef.componentInstance.selfModal = modalRef;
  }
  public gotToReviews() {
    this.scroll.emit();
  }
}
