<div class="table-responsive-sm mb-4">
  <table class="table table-hover" #container>
    <thead>
      <tr class="" [ngClass]="{ 'custom-border': true }">
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "admin:users:info:table:orders:profile:th:1" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "admin:users:info:table:orders:profile:th:2" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "admin:users:info:table:orders:profile:th:3" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle text-nowrap">
          <span>
            {{ "admin:users:info:table:orders:profile:th:4" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle text-nowrap">
          <span>
            {{ "admin:users:info:table:orders:profile:th:5" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle text-nowrap">
          <span>
            {{ "admin:users:info:table:orders:profile:th:6" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "admin:users:info:table:orders:profile:th:7" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "admin:users:info:table:orders:profile:th:8" | translate }}
          </span>
        </th>
        <th class="text-center" scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container>
        <tr
          [ngClass]="{ 'custom-border': true }"
          *ngFor="let item of listOrders; let i = index"
        >
          <td class="align-middle text-nowrap">
            {{ item?.completed_at | date : "dd LLL YYYY" }}
          </td>
          <td class="align-middle title-course">
            <div class="d-flex">
              <a class="hidden-xs d-inline-block my-1 col-auto" [href]="getLink(item)">
                {{ (item?.product_name |translate) || "" }}
              </a>
            </div>

          
          </td>
          <td class="align-middle">
            {{ getLenght(item) }}
          </td>
          <td class="align-middle">
            {{ (getStart(item)) | date : "dd LLL YYYY" }}
          </td>
          <td class="align-middle">
            {{ getFinishDate(item) | date : "dd LLL YYYY" }}
          </td>
          <td class="align-middle title-email ">
            {{ item?.email || "" }}
          </td>
          <td class="align-middle fw-bold">
            €{{ item?.payment_paid| number : "1.2-2" || "" }}
          </td>

          <td class="align-middle">
            <div class="d-flex justify-content-center align-items-center">
              <a class="hidden-xs d-inline-block my-1 col-auto" [href]="getPdf(item)">
                <i class="far fa-file-pdf fa-lg"></i>
              </a>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
