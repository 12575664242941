<div class="dropdown d-inline-block me-2">
  <button
    class="btn btn-link btn-lan-color"
    type="button"
    d="dropdownUserLanguage"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    {{ language.toUpperCase() }}
    <i class="fa fa-angle-down angle-down-color"></i>
  </button>
  <ul  class="dropdown-menu text-center" aria-labelledby="dropdownUserLanguage">
    <li>
      <a
        [routerLink]="[]"
        class="dropdown-item"
        [ngClass]="{ 'fw-600': language.toLowerCase() == 'nl' }"
        href="#"
        (click)="changeLanguage('NL')"
        >{{ "navigation:language:nl" | translate }}</a
      >
    </li>
    <li>
      <a
        [routerLink]="[]"
        class="dropdown-item"
        [ngClass]="{ 'fw-600': language.toLowerCase() == 'en' }"
        (click)="changeLanguage('EN')"
        >{{ "navigation:language:en" | translate }}</a
      >
    </li>
  </ul>
</div>
<ng-container *ngIf="!user && !isAuthenticated(); else loged">
  <button *ngIf="showItems" type="button" class="btn login-button me-1" (click)="login()">
    {{ "login:menu:title" | translate }}
  </button>
  <button *ngIf="showItems" type="button" class="btn register-button" (click)="register()">
    {{ "register:menu:title" | translate }}
  </button>
</ng-container>
<ng-template #loged>
  <div *ngIf="showInterrogant" class="dropdown d-inline-block" >
    <button
      class="btn btn-outline-primary btn-round me-2 d-none d-md-inline"
      data-bs-toggle="dropdown"
      aria-expanded="true"
    >
      <i class="fas fa-question"></i>
    </button>
    <ul
      class="dropdown-menu dropdown-menu-movie dropdown-bubble admin-dropdown down-menu"
    >
      <li>
        <div class="tooltip2">
          <div class="row text-start">
            <h5>{{ "Need help ?" | translate }}</h5>
          </div>
          <div class="row text-start">
            <p class="mb-0 col-auto text-wrap">
              {{
                "If you want to take the tour again, click below. For other questions please email us at support@movielearning.com"
                  | translate
              }}
            </p>
          </div>
          <div class="row justify-content-center mt-3">
              <button
                type="button"
                class="btn btn-primary col-auto"
                (click)="openTour()"
              >
                {{ "Take the tour" | translate }}
              </button>
            </div> 
        </div>
      </li>
    </ul>
  </div>

  <button
  *ngIf="isOrganizationAdmin()||!currentOrg?.visible"
    [routerLink]="['search']"
    class="btn btn-secondary btn-round me-2 search-icon"
  >
    <img src="assets/img/nav-bar/ico-search.svg" width="17" />
  </button>

  <div class="dropdown d-inline-block" *ngIf="isOrganizationAdmin()">
    <button
      class="btn btn-outline-primary btn-round me-2 d-none d-md-inline"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <fa-icon [icon]="faBuilding"></fa-icon>
    </button>
    <ul
      class="dropdown-menu dropdown-menu-movie dropdown-bubble admin-dropdown down-menu"
    >
      <li *ngIf="isSuperUser() && currentOrg?.show_dashboard_widgets">
        <a
          [routerLink]="['organization', currentOrg?.id, 'overview']"
          class="dropdown-item"
          >{{ "organization:overview:menu" | translate }}</a
        >
      </li>
      <li>
        <a
          [routerLink]="['organization', currentOrg?.id, 'homepage']"
          class="dropdown-item"
          >{{ "organization:dashboard:menu" | translate }}</a
        >
      </li>
      <li *ngIf="isSuperUser()">
        <a
          [routerLink]="['organization', currentOrg?.id, 'videos']"
          class="dropdown-item"
          >{{ "organization:videos:menu" | translate }}</a
        >
      </li>
      <li *ngIf="!orgUtils.isPublic()">
        <a
          [routerLink]="['organization', currentOrg?.id, 'playlists']"
          class="dropdown-item"
          >{{ "organization:playlists:menu" | translate }}</a
        >
      </li>
      <li *ngIf="isSuperUser()">
        <a
          [routerLink]="['organization', currentOrg?.id, 'courses']"
          class="dropdown-item"
          >{{ "organization:courses:menu" | translate }}</a
        >
      </li>
      <li>
        <a
          [routerLink]="['organization', currentOrg?.id, 'members']"
          class="dropdown-item"
          >{{ "organization:members:menu" | translate }}</a
        >
      </li>
      <li *ngIf="isSuperUser()">
        <a
          [routerLink]="['organization', currentOrg?.id, 'settings']"
          class="dropdown-item"
          >{{ "organization:settings:menu" | translate }}</a
        >
      </li>
    </ul>
  </div>

  <div
    *ngIf="!orgUtils.isPublic() || isOrganizationAdmin()"
    class="dropdown d-inline-block"
  >
    <button
      class="btn btn-outline-primary btn-round me-2 d-none d-md-inline"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <fa-icon [icon]="faPlus"></fa-icon>
    </button>
    <ul
      class="dropdown-menu dropdown-menu-movie dropdown-bubble create-dropdown"
    >
      <li>
        <button class="dropdown-item" (click)="uploadVideo()">
          {{ "navbar:upload_video" | translate }}
        </button>
      </li>
      <li>
        <button class="dropdown-item" (click)="createPlayList()">
          {{ "navbar:create_playlist" | translate }}
        </button>
      </li>
      <li *ngIf="isSuperUser()">
        <button class="dropdown-item" (click)="createCourse()">
          {{ "navbar:create_course" | translate }}
        </button>
      </li>
      <li>
        <button class="dropdown-item" (click)="createGroup()">
          {{ "groups:button:create" | translate }}
        </button>
      </li>
    </ul>
  </div>

  <div class="dropdown d-inline-block me-2">
    <button
      class="btn btn-outline-primary btn-round btn-user-dropdown"
      *ngIf="user?.avatarPathUrlSmall; else userIcon"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <img [src]="user?.avatarPathUrlSmall" />
    </button>
    <ng-template #userIcon>
      <button
        class="btn btn-outline-primary btn-round"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <fa-icon [icon]="faUser"></fa-icon>
      </button>
    </ng-template>
    <ul class="dropdown-menu dropdown-bubble">
      <li>
        <button
          [routerLink]="['/profile']"
          [routerLinkActive]="['active']"
          class="dropdown-item fw-600"
        >
          {{ "organization:my_profile" | translate }}
        </button>
      </li>
      <li>
        <button
          [routerLink]="['/profile']"
          [queryParams]="{ section: 'settings' }"
          class="dropdown-item fw-600"
        >
          {{ "organization:account_settings" | translate }}
        </button>
      </li>
      <li *ngIf="isOrganizationAdmin()">
        <button class="dropdown-item fw-600">Organization Settings</button>
      </li>
      <li *ngIf="isSuperUser()"><hr class="dropdown-divider m-0" /></li>
      <li *ngIf="isSuperUser()">
        <a class="d-flex org-line py-3" [routerLink]="['/admin']">
          <!-- <app-organization-logo
            [organization]="{ logo_id: '', name: 'M' }"
          ></app-organization-logo> -->
          <div
            class="flex-grow-1 d-flex align-items-center mx-2 fw-600 fs-14 custom-color"
          >
            {{ "MovieLearning Admin" }}
          </div>
        </a>
      </li>
      <li *ngIf="isSuperUser()"><hr class="dropdown-divider m-0" /></li>

      <!-- <li>
        <h6
          class="dropdown-header fw-lighter text-muted fw-500 switch-platform"
        >
          <small>{{ "organization:switchplatform" | translate }}</small>
        </h6>
      </li>
      <li>
        <div class="choose-orgs-container">
          <a
            class="d-flex ps-2 org-line"
            (click)="switchOrganization(organization)"
            *ngFor="let organization of organizations"
          >
            <app-organization-logo
              [organization]="organization"
            ></app-organization-logo>
            <div
              class="flex-grow-1 d-flex align-items-center mx-2 text-dark fw-600 fs-14"
            >
              {{ organization.name }}
            </div>
          </a>
        </div>
      </li> -->
      <!-- <li><hr class="dropdown-divider m-0"></li> -->
      <li>
        <a
          [routerLink]="[]"
          class="dropdown-item text-danger fw-600 logout-border"
          (click)="logout()"
          >{{ "navbar:logout" | translate }}</a
        >
      </li>
    </ul>
  </div>
</ng-template>
