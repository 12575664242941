import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { VideoService } from 'src/app/services/video.service';

@Component({
  selector: 'app-imdb-search',
  templateUrl: './imdb-search.component.html',
  styleUrls: ['./imdb-search.component.scss']
})
export class ImdbSearchComponent implements OnInit {
  @Input('type') type = 'movie';
  @Output('notFound') notFound = new EventEmitter<any>();
  @Output('selected') selected = new EventEmitter<any>();

  search = '';
  searchChanged: Subject<string> = new Subject<string>();
  searching = 0;
  showResults = false;
  results: Array<any> = [];

  constructor(
    private videoService: VideoService
  ) {
    this.searchChanged.pipe(
      debounceTime(500),
      distinctUntilChanged()
    )
    .subscribe( s => {
      if (s.trim()=='') {return}
      this.search = s;
      this.searching += 1;
      let type = 'movie';
      if (this.type == 'tvshow') {
        type = 'series'
      }
      this.videoService.IMDBSearch(this.search, type).then( movies => {
        this.searching -= 1;
        this.results = movies;
      })
      this.showResults = true;
    });
  }

  ngOnInit(): void {
  }

  changed(text: string) {
    this.searchChanged.next(text);
  }

  notFoundClick() {
    this.notFound.emit(this.search);
  }

  selectMovie(movie: any) {
    this.showResults = false;
    this.results = [];
    this.selected.emit(movie);
  }
}
