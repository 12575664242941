<div class="row justify-content-center primary-title-circle">
  <div class="col-auto">
    <h2>{{ titleCircle }}</h2>
  </div>
</div>
<div [ngClass]="getLevel()" class="ciclegraph mx-0">
  <div
    [ngStyle]="{ background: getColorLevel(), borderColor: getColorLevel() }"
    class="circle"
    (click)="openModalCircle(dataCircleParse.fieldsdata[5], '6.')"
  >
    <h5>6</h5>
    <div class="text-wrapper-6 d-none d-md-block text-nowrap">
      <h4 class="title-circle">
        {{ dataCircleParse?.fieldsdata[5]?.fieldTitle || " " }}
      </h4>
      <p class="subtitle-circle">
        {{ "dh_profile:competences:multi:6" | translate }}
      </p>
    </div>
  </div>
  <div
    [ngStyle]="{ background: getColorLevel(), borderColor: getColorLevel() }"
    class="circle"
    (click)="openModalCircle(dataCircleParse.fieldsdata[6], '7.')"
  >
    <h5>7</h5>
    <div class="text-wrapper-7 text-nowrap d-none d-md-block">
      <h4 class="title-circle">
        {{ dataCircleParse?.fieldsdata[6]?.fieldTitle || " " }}
      </h4>
      <p class="subtitle-circle">
        {{ "dh_profile:competences:multi:7" | translate }}
      </p>
    </div>
  </div>
  <div
    [ngStyle]="{ background: getColorLevel(), borderColor: getColorLevel() }"
    class="circle"
    (click)="openModalCircle(dataCircleParse.fieldsdata[7], '8.')"
  >
    <h5>8</h5>
    <div class="text-wrapper-8 text-nowrap d-none d-md-block">
      <h4 class="title-circle">
        {{ dataCircleParse?.fieldsdata[7]?.fieldTitle || " " }}
      </h4>
      <p class="subtitle-circle">
        {{ "dh_profile:competences:multi:8" | translate }}
      </p>
    </div>
  </div>
  <div
    [ngStyle]="{ background: getColorLevel(), borderColor: getColorLevel() }"
    class="circle"
    (click)="openModalCircle(dataCircleParse.fieldsdata[8], '9.')"
  >
    <h5>9</h5>
    <div class="text-wrapper-9 text-nowrap d-none d-md-block">
      <h4 class="title-circle">
        {{ dataCircleParse?.fieldsdata[8]?.fieldTitle || " " }}
      </h4>
      <p class="subtitle-circle">
        {{ "dh_profile:competences:multi:9" | translate }}
      </p>
    </div>
  </div>
  <div
    [ngStyle]="{ background: getColorLevel(), borderColor: getColorLevel() }"
    class="circle"
    (click)="openModalCircle(dataCircleParse.fieldsdata[9], '10.')"
  >
    <h5>10</h5>
    <div class="text-wrapper-10 text-nowrap d-none d-md-block">
      <h4 class="title-circle">
        {{ dataCircleParse?.fieldsdata[9]?.fieldTitle || " " }}
      </h4>
      <p class="subtitle-circle">
        {{ "dh_profile:competences:multi:10" | translate }}
      </p>
    </div>
  </div>
  <div
    [ngStyle]="{ background: getColorLevel(), borderColor: getColorLevel() }"
    class="circle"
    (click)="openModalCircle(dataCircleParse.fieldsdata[10], '11.')"
  >
    <h5>11</h5>
    <div class="text-wrapper-11 text-nowrap d-none d-md-block">
      <h4 class="title-circle">
        {{ dataCircleParse?.fieldsdata[10]?.fieldTitle || " " }}
      </h4>
      <p class="subtitle-circle">
        {{ "dh_profile:competences:multi:11" | translate }}
      </p>
    </div>
  </div>
  <div
    [ngStyle]="{ background: getColorLevel(), borderColor: getColorLevel() }"
    class="circle"
    (click)="openModalCircle(dataCircleParse.fieldsdata[11], '12.')"
  >
    <h5>12</h5>
    <div class="text-wrapper-12 text-nowrap d-none d-md-block">
      <h4 class="title-circle">
        {{ dataCircleParse?.fieldsdata[11]?.fieldTitle || " " }}
      </h4>
      <p class="subtitle-circle">
        {{ "dh_profile:competences:multi:12" | translate }}
      </p>
    </div>
  </div>
  <div
    [ngStyle]="{ background: getColorLevel(), borderColor: getColorLevel() }"
    class="circle"
    (click)="openModalCircle(dataCircleParse.fieldsdata[0], '1.')"
  >
    <h5>1</h5>
    <div class="text-wrapper-1 text-nowrap d-none d-md-block">
      <h4 class="title-circle">
        {{ dataCircleParse?.fieldsdata[0]?.fieldTitle || " " }}
      </h4>
      <p class="subtitle-circle">
        {{ "dh_profile:competences:multi:1" | translate }}
      </p>
    </div>
  </div>
  <div
    [ngStyle]="{ background: getColorLevel(), borderColor: getColorLevel() }"
    class="circle"
    (click)="openModalCircle(dataCircleParse.fieldsdata[1], '2.')"
  >
    <h5>2</h5>
    <div class="text-wrapper-2 text-nowrap d-none d-md-block">
      <h4 class="title-circle">
        {{ dataCircleParse?.fieldsdata[1]?.fieldTitle || " " }}
      </h4>
      <p class="subtitle-circle">
        {{ "dh_profile:competences:multi:2" | translate }}
      </p>
    </div>
  </div>
  <div
    [ngStyle]="{ background: getColorLevel(), borderColor: getColorLevel() }"
    class="circle"
    (click)="openModalCircle(dataCircleParse.fieldsdata[2], '3.')"
  >
    <h5>3</h5>
    <div class="text-wrapper-3 text-nowrap d-none d-md-block">
      <h4 class="title-circle">
        {{ dataCircleParse?.fieldsdata[2]?.fieldTitle || " " }}
      </h4>
      <p class="subtitle-circle">
        {{ "dh_profile:competences:multi:3" | translate }}
      </p>
    </div>
  </div>
  <div
    [ngStyle]="{ background: getColorLevel(), borderColor: getColorLevel() }"
    class="circle"
    (click)="openModalCircle(dataCircleParse.fieldsdata[3], '4.')"
  >
    <h5>4</h5>
    <div class="text-wrapper-4 text-nowrap d-none d-md-block">
      <h4 class="title-circle">
        {{ dataCircleParse?.fieldsdata[3]?.fieldTitle || " " }}
      </h4>
      <p class="subtitle-circle">
        {{ "dh_profile:competences:multi:4" | translate }}
      </p>
    </div>
  </div>
  <div
    [ngStyle]="{ background: getColorLevel(), borderColor: getColorLevel() }"
    class="circle"
    (click)="openModalCircle(dataCircleParse.fieldsdata[4], '5.')"
  >
    <h5>5</h5>
    <div class="text-wrapper-5 text-nowrap d-none d-md-block">
      <h4 class="title-circle">
        {{ dataCircleParse?.fieldsdata[4]?.fieldTitle || " " }}
      </h4>
      <p class="subtitle-circle">
        {{ "dh_profile:competences:multi:5" | translate }}
      </p>
    </div>
  </div>

  <div class="card-info d-flex justify-content-center align-items-center">
    <div
      class="short-text"
      (click)="
        openModalCircle({ fieldTitle: titleCircle, fieldInfo: generalInfo })
      "
      [innerHtml]="generalInfo"
    ></div>
  </div>
</div>
<div class="row justify-content-center d-block d-md-none">
  <div class="col-auto text-center">
    <p>{{ "Click on each phase to see more information" | translate }}</p>
  </div>
</div>
