import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-resend-invitation',
  templateUrl: './resend-invitation.component.html',
  styleUrls: ['./resend-invitation.component.scss'],
})
export class ResendInvitationComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() user:String='';
  @Input() organizationId:number=0;
  @Input() invitedId:number=0;
  @Input() email:String='';
  @Input() invitie:String='';
  @Output() refresh = new EventEmitter<any>();
  public faCircleNorch: any = faCircleNotch;

  public faTimes = faTimes;
  public loadSpin: boolean = false;

  constructor(  
    private organizationService: OrganizationsService,
    private toastService: ToastService,
    ) {}

  ngOnInit(): void {}

  esEmailValido(email: any):boolean {
    let mailValido = false;
      'use strict';

      var EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if (email.match(EMAIL_REGEX)){
        mailValido = true;
      }
    return mailValido;
  }

  public ResendInvitation() {
    if (!this.esEmailValido(this.email)){
      this.toastService.showError('The Email address field must be an email');
      return;
    }
    this.loadSpin=true;
    this.organizationService
      .resendInvitation(this.organizationId,this.invitedId,
          this.email,this.invitie,this.user)
      .then((resp) => {
        if (resp.result == 'success') {
          this.toastService.showSuccess(
            'Invitation for '+this.email+' is succesfully sent'
          );
          this.refresh.emit();
          this.closeModal();
          this.loadSpin=false;

          
        } else {
          this.loadSpin=false;
          this.toastService.showError('Error');
        }
      })
      .catch((error) => {
        this.loadSpin=false;
        this.toastService.showError(error);
      });


    
  }

  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
}
