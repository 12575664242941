<h3 class="h6 mb-1">{{ 'video:edit:modal:tab:double_healix:title' | translate }}</h3>
<p class="fs-14 text-muted">{{ 'video:edit:modal:tab:double_healix:description' | translate }}</p>
<div class="card p-3 mb-2" *ngFor="let context of contexts; let i = index">
  <div class="row">
    <div class="col-md-2 col-6">
      <label class="form-label fs-14" [for]="'context_' + i + '_phase'" translate="video:edit:modal:phase:label"></label>
      <select class="form-select form-select-sm" [name]="'context_' + i + '_phase'" [(ngModel)]="context.phase_id" (ngModelChange)="getTags(i)">
        <option value="" selected></option>
        <option *ngFor="let i of phases" [value]="i">{{ i }}</option>
      </select>
    </div>

    <div class="col-md-2 col-6">
      <label class="form-label fs-14" [for]="'context_' + i + '_level'" translate="video:edit:modal:level:label"></label>
      <select class="form-select form-select-sm" [name]="'context_' + i + '_level'" [(ngModel)]="context.level_id" (ngModelChange)="getTags(i)">
        <option value="" selected></option>
        <option *ngFor="let i of levels" [value]="i">{{ i - 1 }}</option>
      </select>
    </div>

    <div class="col-md-3 col-12">
      <label class="form-label fs-14" [for]="'context_' + i + '_special'" translate="video:edit:modal:special:label"></label>
      <select class="form-select form-select-sm" [name]="'context_' + i + '_level'" [(ngModel)]="context.special" (ngModelChange)="touched()">
        <option value=""></option>
        <option value="phase" translate="video:edit:modal:special:phase"></option>
        <option value="state" translate="video:edit:modal:special:state"></option>
        <option value="trait" translate="video:edit:modal:special:trait"></option>
        <option value="paradox" translate="video:edit:modal:special:paradox"></option>
        <option value="polarity" translate="video:edit:modal:special:polarity"></option>
        <option [disabled]="context.phase_id != 1 && context.phase_id != 4 && context.phase_id != 7 && context.phase_id != 10" value="mentor_cross" translate="video:edit:modal:special:mentor_cross"></option>
        <option [disabled]="context.phase_id != 2 && context.phase_id != 5 && context.phase_id != 8 && context.phase_id != 11" value="power_cross" translate="video:edit:modal:special:power_cross"></option>
        <option [disabled]="context.phase_id != 3 && context.phase_id != 6 && context.phase_id != 9 && context.phase_id != 12" value="substance_cross" translate="video:edit:modal:special:substance_cross"></option>
        <option [disabled]="context.phase_id != 1 && context.phase_id != 5 && context.phase_id != 9"  value="water_triangle" translate="video:edit:modal:special:water_triangle"></option>
        <option [disabled]="context.phase_id != 2 && context.phase_id != 6 && context.phase_id != 10" value="fire_triangle" translate="video:edit:modal:special:fire_triangle"></option>
        <option [disabled]="context.phase_id != 3 && context.phase_id != 7 && context.phase_id != 11" value="earth_triangle" translate="video:edit:modal:special:earth_triangle"></option>
        <option [disabled]="context.phase_id != 4 && context.phase_id != 8 && context.phase_id != 12" value="air_triangle" translate="video:edit:modal:special:air_triangle"></option>
        <option value="sequence" translate="video:edit:modal:special:sequence"></option>
      </select>
    </div>

    <div class="col-md-2 col-6" *ngIf="context.special === 'sequence'">
      <label class="form-label fs-14" [for]="'context_' + i + '_extra_startphase'">Start</label>
      <select class="form-select form-select-sm" [name]="'context_' + i + '_extra_startphase'" [(ngModel)]="context.special_extra!.startPhase" (ngModelChange)="touched()">
        <option value="" selected></option>
        <option *ngFor="let i of phases" [value]="i">{{ i }}</option>
      </select>
    </div>

    <div class="col-md-2 col-6" *ngIf="context.special === 'sequence'">
      <label class="form-label fs-14" [for]="'context_' + i + '_extra_endphase'">End</label>
      <select class="form-select form-select-sm" [name]="'context_' + i + '_extra_endphase'" [(ngModel)]="context.special_extra!.endPhase" (ngModelChange)="touched()">
        <option value="" selected></option>
        <option *ngFor="let i of phases" [value]="i">{{ i }}</option>
      </select>
    </div>

    <div class="col-md-1 col-12 text-end ms-auto">
      <button class="btn btn-outline-danger" (click)="removeContext(i)">
        <i class="fa fa-trash"></i>
      </button>
    </div>
  </div>
  <div class="row my-3" *ngIf="apiTags[i]">
    <div class="col-12">
      <h4 class="h6 fs-14 fw-bold">{{ "video:edit:modal:add_dh_tag:label" | translate }}</h4>
      <div class="pill" *ngFor="let tag of apiTags[i]" [ngClass]="{'selected': tag.selected}" (click)="toggleTag(tag)">
        <span *ngIf="tag.info.description" placement="bottom" container="body" [ngbTooltip]="tag.info.description"><i class="fa fa-info-circle me-1 text-primary" aria-hidden="true"></i></span>
        {{ tag.info.name }}
      </div>
    </div>
  </div>
</div>

<button class="btn btn-primary" (click)="addContext()">
  <i class="fa fa-plus me-2"></i>
  <span *ngIf="contexts.length == 0">{{ "video:edit:modal:add_conbination:button" | translate }}</span>
  <span *ngIf="contexts.length > 0">Add another one</span>
</button>

<hr [ngClass]="{'my-5': !videoEditService.isModal}">

<div class="">
  <h4 class="h6 mb-1 fw-bold fs-14">{{ "video:edit:modal:tab:double_healix:analysis:title" | translate }}</h4>
  <p class="fs-14 text-muted">{{ "video:edit:modal:tab:double_healix:analysis:description" | translate }}</p>
  <textarea id="dh-analysis" class="form-control" [(ngModel)]="video!.double_helix_analysis" (ngModelChange)="touched()"></textarea>
</div>
