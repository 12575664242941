import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InvitedRegisterComponent } from 'src/app/modals/invited-register/invited-register.component';
import { LangService } from 'src/app/services/lang.service';
import { ToastService } from 'src/app/services/toast.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {
  invitation: any|undefined;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private us: UsersService,
    private ls: LangService,
    private ms: NgbModal,
    private toastr: ToastService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe( (params: any) => {
      if (params['hash']) {
        this.us.getInvite(params['hash']).then( (invitation) => {
          this.invitation = invitation;
          this.ls.setLanguage(invitation.language)
          this.invitation.hash = params['hash'];
          this.openRegisterModal(this.invitation)
        }).catch( (err: any) => {
          if (err.error.message.type=='InvitationAlreadyUsed'){
            this.router.navigate(['/login'],  { queryParams: { errormsg: err.error.message.type }} );
          }else{
            this.toastr.showError(err.error.message.friendly);
            setTimeout(() => {
              this.router.navigate(['/login'],  { queryParams: { errormsg: err.error.message.friendly }} );
            }, 3000);
          }
          
        })
      }
    });
  }

  openRegisterModal(invitation: any) {
    let modalRef = this.ms.open(InvitedRegisterComponent, {
      windowClass: 'login-modal',
      backdropClass: 'modal-backdrop-transparent',
      backdrop: 'static',
      keyboard: false
    });
    modalRef.componentInstance.invitation = invitation;
    modalRef.componentInstance.selfModal = modalRef;
    modalRef.componentInstance.success.subscribe(() => {
      
      if (this.invitation.section=='dhprofile' || this.invitation.section=='dhprofilelevel3' ) {
        this.router.navigate(['dh/profile']);
        return;
      }

      if (this.invitation.collection && this.invitation.collection.id && this.invitation.collection.type) {
        switch (this.invitation.collection.type) {
          case 'playlist':
            this.router.navigate(['playlist', this.invitation.collection.id]);
            break;
          case 'group':
            this.router.navigate(['groups', this.invitation.collection.id]);
            break;
          case 'course':
            this.router.navigate(['courses', this.invitation.collection.id]);
            break;
          case 'dh_team':
            this.router.navigate(['dh/profile']);
            break;
          case 'dh_profile':
            this.router.navigate(['dh/profile']);
            break;
        }
      } else {
        this.router.navigate(['']);
      }
    });
  }
}
