import { EnrolCourseModalComponent } from './../../../modals/courses/enrol-course-modal/enrol-course-modal.component';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';

import { CourseUtilitiesService } from 'src/app/utilities/course-utilities.service';
import { AuthService } from 'src/app/services/auth.service';
import { Chapter } from 'src/app/interfaces/chapter';
import { Course } from 'src/app/interfaces/course';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseViewerService } from 'src/app/services/course-viewer.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-curriculum-card',
  templateUrl: './curriculum-card.component.html',
  styleUrls: ['./curriculum-card.component.scss'],
})
export class CurriculumCard implements OnInit, OnDestroy {
  @Input() chapter: Chapter | undefined;
  @Input() course: Course | undefined;
  @Input() type: string = 'curriculum';
  public courseAux: any;
  showMore = false;
  public isCollapsed = true;

  chapterIndex = -1;
  chapterProgress: any = 0;
  sub: any | undefined;
  language = 'en';

  constructor(
    private courseUtilitiesService: CourseUtilitiesService,
    public auth: AuthService,
    private router: Router,
    private ngbModal: NgbModal,
    private courseViewer: CourseViewerService,
    private cd: ChangeDetectorRef,
    private lang: LangService,
  ) {
    this.lang.language.subscribe((lang) => {
      this.language = lang || 'en';
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.courseAux = this.course;
  }
  ngOnInit(): void {
    this.chapterProgress = this.courseUtilitiesService.chapterProgress(
      this.chapter
    );

    this.course?.chapters.forEach((chapter: Chapter, i: number) => {
      if (chapter.id == this.chapter?.id) {
        this.chapterIndex = i;
      }
    });

    this.sub = this.courseViewer.getCourseObservable()!.subscribe((course) => {
      this.course = course!;
      course?.chapters.forEach((chapter) => {
        if (this.chapter!.id == chapter.id) {
          this.chapter = chapter;
          this.chapterProgress = this.courseUtilitiesService.chapterProgress(
            this.chapter
          );
        }
      });
    });
  }

  userIsEnrolled(): boolean {
    if (this.courseAux) {
      return this.courseUtilitiesService.userIsEnrolled(this.courseAux!);
    }
    return false;
  }

  goToChapter() {
    this.closeMenu();
    if (this.userIsEnrolled() && this.chapter?.access ) {
      this.router.navigate(
        ['course-viewer', this.courseAux?.id, 'chapter', this.chapter?.id],
        { queryParams: { number: this.chapterIndex } }    
      );
    } else {
      this.enrolCourseModal();
    }
  }

  closeMenu() {
    this.courseViewer.closeMenu(); // Llama al método del servicio
  }
  enrolCourseModal() {
    let modalRef = this.ngbModal.open(EnrolCourseModalComponent, {
      windowClass: 'default-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.course = this.courseAux;
    modalRef.componentInstance.chapter = this.chapter;
    modalRef.componentInstance.chapterNumber = this.chapterIndex;
  }
}
