<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title">
    {{ "courses:modal:title:pricing" | translate }}: {{ course?.name }}
  </h4>
</div>
<div class="modal-body flex-column">
  <p class="text-muted mb-0">{{ "courses:modal:title:pricing" | translate }}</p>
  <p class="text-muted">
    {{ "courses:modal:details:subtitle:pricing" | translate }}
  </p>
  <div class="row d-flex my-3 fw-bold">
    <div class="col-3">
      {{ "courses:modal:details:days:0:pricing" | translate }}
    </div>
    <div class="col">
      {{ "courses:modal:details:days:1:pricing" | translate }}
    </div>
    <div class="col">
      {{ "courses:modal:details:days:2:pricing" | translate }}
    </div>
    <div class="col">
      {{ "courses:modal:details:days:3:pricing" | translate }}
    </div>
    <div class="col">
      {{ "courses:modal:details:days:4:pricing" | translate }}
    </div>
  </div>

  <div
    *ngFor="let platform of organizations; let i = index"
    [ngClass]="{ 'pricing-body ': i % 2 == 0 }"
    class="row py-4"
  >
    <div class="col-12 row mb-2">
      <div class="col-3">
        <label for="" class="fw-bold">{{ platform?.name }}</label>
      </div>
      <div class="col-9 row">
        <div class="col-3">
          <input
            class="input-box w-100"
            placeholder="$0.00"
            type="number"
            min="0"
            max="999999"
            (keypress)="evitarGuion($event)"
            [(ngModel)]="platform.pricing?.days30.price"
          />
        </div>
        <div class="col-3">
          <input
            class="input-box w-100"
            placeholder="$0.00"
            type="number"
            min="0"
            max="999999"
            (keypress)="evitarGuion($event)"
            [(ngModel)]="platform.pricing?.days90.price"
          />
        </div>
        <div class="col-3">
          <input
            class="input-box w-100"
            maxlength="80"
            placeholder="$0.00"
            [(ngModel)]="platform.pricing?.days180.price"
            type="number"
            min="0"
            max="999999"
            (keypress)="evitarGuion($event)"
          />
        </div>
        <div class="col-3">
          <input
            class="input-box w-100"
            maxlength="80"
            placeholder="$0.00"
            [(ngModel)]="platform.pricing?.days365.price"
            type="number"
            min="0"
            max="999999"
            (keypress)="evitarGuion($event)"
          />
        </div>
      </div>
    </div>
    <div class="col-12 row mb-2">
      <div class="col-3"><label for="">Prolong pricing</label></div>
      <div class="col-9 row">
        <div class="col-3">
          <input
            class="input-box w-100"
            maxlength="80"
            placeholder="$0.00"
            type="number"
            min="0"
            max="999999"
            (keypress)="evitarGuion($event)"
            [(ngModel)]="platform.prolong_price?.days30.price"
          />
        </div>
        <div class="col-3">
          <input
            class="input-box w-100"
            maxlength="80"
            placeholder="$0.00"
            [(ngModel)]="platform.prolong_price?.days90.price"
            type="number"
            min="0"
            max="999999"
            (keypress)="evitarGuion($event)"
          />
        </div>
        <div class="col-3">
          <input
            class="input-box w-100"
            maxlength="80"
            placeholder="$0.00"
            [(ngModel)]="platform.prolong_price?.days180.price"
            type="number"
            min="0"
            max="999999"
            (keypress)="evitarGuion($event)"
          />
        </div>
        <div class="col-3">
          <input
            class="input-box w-100"
            maxlength="80"
            placeholder="$0.00"
            [(ngModel)]="platform.prolong_price?.days365.price"
            type="number"
            min="0"
            max="999999"
            (keypress)="evitarGuion($event)"
          />
        </div>
      </div>
    </div>
    <div class="col-12 row">
      <div class="col-3"><label for="">Renew Pricing</label></div>
      <div class="col-9 row">
        <div class="col-3">
          <input
            class="input-box w-100"
            maxlength="80"
            placeholder="$0.00"
            [(ngModel)]="platform.renew_price?.days30.price"
            type="number"
            min="0"
            max="999999"
            (keypress)="evitarGuion($event)"
          />
        </div>
        <div class="col-3">
          <input
            class="input-box w-100"
            maxlength="80"
            placeholder="$0.00"
            [(ngModel)]="platform.renew_price?.days90.price"
            type="number"
            min="0"
            max="999999"
            (keypress)="evitarGuion($event)"
          />
        </div>
        <div class="col-3">
          <input
            class="input-box w-100"
            maxlength="80"
            placeholder="$0.00"
            [(ngModel)]="platform.renew_price?.days180.price"
            type="number"
            min="0"
            max="999999"
            (keypress)="evitarGuion($event)"
          />
        </div>
        <div class="col-3">
          <input
            class="input-box w-100"
            maxlength="80"
            placeholder="$0.00"
            [(ngModel)]="platform.renew_price?.days365.price"
            type="number"
            min="0"
            max="999999"
            (keypress)="evitarGuion($event,platform.renew_price?.days365.price)"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="form-group mt-3">
    <label class="modal-label" for="clip-title">{{
      "course:edit:settings:label" | translate
    }}</label>
    <div class="row d-flex mt-2">
      <div class="col-auto">
        <label class="fancy-radio-checkbox modal-label">
          <input
            [(ngModel)]="infoColletion.open"
            class="fancy-input"
            id="open"
            name="privacyOpen"
            type="radio"
            [value]="true"
          />
          <div class="fancy-graphic"></div>
          {{ "course:edit:settings:open" | translate }}
        </label>
        <div class="small-form-text">
          {{ "course:edit:settings:open:description" | translate }}
        </div>
      </div>
      <div class="col-auto">
        <label class="fancy-radio-checkbox modal-label">
          <input
            [(ngModel)]="infoColletion.open"
            class="fancy-input"
            id="closed"
            name="privacyClose"
            type="radio"
            [value]="false"
          />
          <div class="fancy-graphic"></div>
          {{ "course:edit:settings:closed" | translate }}
        </label>
        <div class="small-form-text">
          {{ "course:edit:settings:closed:description" | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row justify-content-end modal-footer">
  <hr class="common-separator my-1" />
  <div class="row justify-content-end text-end">
    <div class="col-auto">
      <button
        (click)="sendInfoPricing()"
        type="button"
        role="tab"
        class="btn btn-primary"
      >
        <fa-icon
          class="mr-2"
          [icon]="faCircleNorch"
          *ngIf="loadSpin"
          [spin]="true"
        ></fa-icon>
        {{ "save:label" | translate }}
      </button>
    </div>
  </div>
</div>
