<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 *ngIf="addOnGroup" class="modal-title">
    {{
      "videos:library:modal:group:title" | translate : { groupName: group.name }
    }}
  </h4>
  <h4 *ngIf="addOnPlaylist" class="modal-title">
    {{
      "playlist:modal:add:video:to:playlist:modal:header"|translate: { playlist: playlist.name }
    }}
  </h4>
  <h4 *ngIf="!addOnGroup && !addOnPlaylist">Add video</h4>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="row tabs-row">
      <div class="col text-center">
        <app-movie-tabs-wrapper [main]="true">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active py-3"
              id="activity-tab"
              (click)="getTab('allVideo')"
              type="button"
              role="tab"
            >
              {{ "video:libray:all" | translate }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link py-3"
              id="conversation-tab"
              (click)="getTab('myVideo')"
              type="button"
              role="tab"
            >
              {{ "video:libray:my" | translate }}
            </button>
          </li>
        </app-movie-tabs-wrapper>
      </div>
    </div>
  </div>

  <div class="tab-content" id="myTabContent">
    <div
      class="tab-pane fade show active"
      id="all"
      role="tabpanel"
      aria-labelledby="profile-tab"
    >
      <div class="container py-4">
        <app-search-input
          [inputPlaceholder]="'video:add:modal:search:placeholder' | translate"
          [actualSearch]="this.searchText"
          [loading]="loading"
          [searchText]="'search:button' | translate"
          (searchEvent)="searchAll($event)"
          *ngIf="actualTab == 'allVideo'"
        ></app-search-input>

        <app-search-input
          [inputPlaceholder]="'video:add:modal:search:placeholder' | translate"
          [actualSearch]="this.searchTextOwn"
          [loading]="loading"
          [searchText]="'Search'"
          (searchEvent)="searchOwn($event)"
          *ngIf="actualTab == 'myVideo'"
        ></app-search-input>

        <div *ngIf="loading" class="row max-heigth">
          <div class="row loadingCenter">
            <app-loading-icon></app-loading-icon>
          </div>
        </div>

        <div
          *ngIf="!loading"
          class="row justify-content-center max-heigth list-overflow"
        >
          <div *ngIf="actualTab == 'allVideo'">
            <div
              *ngFor="let video of videos"
              class="row justify-content-center"
            >
              <div class="col-xl-12 p-0" style="margin-right: 4px">
                <app-video-list-card
                  (selectItem)="selectItem($event)"
                  [itemSelect]="itemSelect"
                  [video]="video"
                ></app-video-list-card>
              </div>
            </div>
            <div class="text-empty-content" *ngIf="videos.length == 0">
              {{ "video:add:modal:list:empty" | translate }}
            </div>
          </div>

          <div *ngIf="actualTab == 'myVideo'">
            <div
              *ngFor="let videoOwn of videosOwn"
              class="row justify-content-center"
            >
              <div class="col-xl-12 p-0">
                <app-video-list-card
                  (selectItem)="selectItem($event)"
                  [itemSelect]="itemSelect"
                  [video]="videoOwn"
                ></app-video-list-card>
              </div>
            </div>
            <div class="text-empty-content" *ngIf="videosOwn.length == 0">
              {{ "video:add:modal:list:empty" | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group clearfix">
    <button
      (click)="sendData()"
      [disabled]="itemSelect == ''"
      type="button"
      role="tab"
      class="btn btn-primary btn-create float-right ms-2 fs-15 col-auto"
    >
      <fa-icon
        class="ms-2"
        [icon]="faCircleNorch"
        *ngIf="addLoading"
        [spin]="true"
      ></fa-icon>
      {{ "organization:video:add:button" | translate }}
    </button>
  </div>
</div>
