import { Component, Input, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { faClock  } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-course-list-card',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.scss']
})
export class CourseListCardComponent implements OnInit {
 
	@Input() course: any;
	@Input() itemSelect: any;
  
  @Output() selectItem = new EventEmitter<string>();


  loading = false;
  title = '';
  description = '';
  language: String = 'en';
  faClock = faClock;

  constructor() {  }

  ngOnInit(): void {
  
    
  }
  select() {
    console.log(this.course.id)
    this.selectItem.emit(this.course.id);
  }

}
