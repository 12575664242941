<div class="container">
    <div class="row">
        <div class="col-xs-8 col-xs-offset-2 col-md-4 col-md-offset-4" style="box-sizing: border-box;">
            <a class="button button--confirm-light enroll-button button-send-as-gift ml-0" ng-if="hasPrices()"
                ng-click="sendAsGift()">
                {{ "send:as:gift" | translate }}
            </a>
        </div>
    </div>
</div>
<h1 class="mt-4 text-center"><em>{{ 'course:subscription:pick:title' | translate }}</em></h1>
<p class="pragraph mt-2">{{ 'course:subscription:pick:subtitle' | translate }}</p>
<div class="row justify-content-center">

    <div *ngFor="let subscription of course?.subscription_price| sort:'price'; index as index "
        class="card shadow pe-pointer subscription-card text-center">

        {{ (subscription.subscription_days / 30).toFixed(0) }}
        {{ 'courses:profile:view:subscription:months:' + (((subscription.subscription_days / 30).toFixed(0) == '1')?
        'singular' : 'plural') | translate }} {{ "access" | translate }}

        <h1 class="price-text"><span class="striked-text" style="opacity: 0.6; margin-right: 5px;"
                *ngIf="course.is_discounted && index == 0">{{'€'+course.striked_price
                }}</span>{{'€'+subscription.price}}
        </h1>

        <p class="text-center vat-included" *ngIf="subscription.price">{{ 'vat:included' | translate }}</p>

        <p class="description" style="margin-bottom: 10px;">
            {{ 'course:subscription:access:days' | translate:{days: subscription.subscription_days} }}
        </p>

        <a class="button button--confirm-light enroll-button radiusBiggerBtn light-movie-button"
            style="margin-top: 20px;">
            {{ 'course:subscription:buy:button' | translate }}
        </a>
    </div>

</div>