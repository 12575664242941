import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from 'src/app/services/lang.service';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-invite-manually',
  templateUrl: './invite-manually.component.html',
  styleUrls: ['./invite-manually.component.scss'],
})
export class InviteManuallyComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() organizationId: any;
  @Output() refresh = new EventEmitter<any>();
  public faCircleNorch: any = faCircleNotch;
  public loading: boolean = false;
  public selectUsers: any = [];
  public textName: any = '';
  public language: any = 'en';
  public loadSpin: boolean = false;

  public textMessage: string = '';
  public faTimes = faTimes;
  constructor(
    private organizationService: OrganizationsService,
    private toastService: ToastService,
    private translate: TranslateService,
    private lang: LangService,
  ) {}
  ngOnInit(): void {
    this.lang.language.subscribe( lang => {
      this.language = lang || 'en';
      this.changeOrder();
    })
  }

  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  changeOrder() {
    if (this.language == 'en') {
      this.textMessage = `Welcome to MovieLearning!
      MovieLearning is the online video platform to learn about leadership.
      MovieLearning supports all recent versions of Chrome, Safari, Firefox and Edge.
      You can also access MovieLearning on your smartphone.   
      If you've got a question feel free to contact us at support@movielearning.com`;
    }
    if (this.language == 'nl') {
      this.textMessage = `Welkom op MovieLearning!
      MovieLearning is het video leerplatform om te leren over leiderschap.      
      MovieLearning werkt in alle actuele internetbrowsers van Chrome, Safari, Firefox en Edge.
      MovieLearning werkt ook op smartphones.      
      Mocht je vragen hebben dan staan we voor je klaar via support@movielearning.com`;
    }
  }

  sendInvitation() {
    let allUserInvited: any;
    this.loadSpin = true;
    allUserInvited = this.selectUsers.map((user: any) => {
      return {
        display_name: user.display_name,
        email: user.email,
      };
    });
    this.organizationService
      .inviteManually(
        this.organizationId,
        allUserInvited,
        this.textMessage,
        this.language
      )
      .then((data) => {
        this.selectUsers = [];
        this.toastService.showSuccess(
          this.translate.instant('collection:members:add:members:success')
        );
        this.loadSpin = false;
        this.closeModal();
        this.refresh.emit();
      })
      .catch((err) => {
        console.log(err);
        this.toastService.showError(
          this.translate.instant('organization:members:send_invitation:error')
        );
      });
  }

  usersSelected(data: any) {
    this.selectUsers = data;
  }
}
