import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { OrganizationNotPublicGuard } from 'src/app/guards/organization-not-public.guard';
import { Organization } from 'src/app/interfaces/organization';
import { AuthService } from 'src/app/services/auth.service';
import { InitService } from 'src/app/services/init.service';
import { LangService } from 'src/app/services/lang.service';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { OrganizationUtilitiesService } from 'src/app/utilities/organization-utilities.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  public currentLang: string = 'en';
  @Input() inSidebar: boolean = false;
  @Output() toggle = new EventEmitter<string>();
  hasDHProfileAccess = false;
  @Input() organizations: Array<Organization> = [];
  public env = environment?.URL_PLATFORM;
  constructor(
    public orgUtils: OrganizationUtilitiesService,
    public auth: AuthService,
    private initService: InitService,
    private router: Router,
    private lang: LangService
  ) {
    this.auth.user.subscribe((user) => {
      this.hasDHProfileAccess = user?.dh_profile_invited! || user?.dh_profile!;
    });
    this.lang.language.subscribe((lang) => {
      this.currentLang = lang || 'en';
    });
    // if (!this.isAuthenticated() && this.router.url === '/') {
    //   window.location.href = environment.URL_PLATFORM;
    // }
  }

  ngOnInit(): void {
    //  this.checkDHProfileAccess().then( res => this.hasDHProfileAccess = res );
  }

  isDHProfileAdmin(): boolean {
    return (
      (this.auth.isAuthenticated() &&
        this.auth.hasAnyRole(['trainer', 'super_user']) ||
        this.auth.hasOrganizationRole('moderator')) ||
      this.auth.hasAnyRole(['team_trainer'])
    );
  }

  isActive = function (viewLocation: string) {
    return viewLocation === window.location.pathname;
  };

  isAuthenticated() {
    return this.auth.isAuthenticated();
  }

  async checkDHProfileAccess(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.auth.isAuthenticated()) {
        this.auth.user.subscribe((user) => {
          resolve(user?.dh_profile_invited! || user?.dh_profile!);
        });
      }
      reject(false);
    });
  }
  toggleState(): void {
    this.toggle.emit();
  }

  switchOrganization(organization: Organization): void {
    const currentOrg = this.initService.getCurrentOrg();
    if (currentOrg && currentOrg.slug != organization.slug) {
      let aDomain = window.location.host.split('.');
      aDomain[0] = organization.slug;
      window.location.href =
        window.location.protocol + '//' + aDomain.join('.');
    } else {
      window.location.reload();
    }
  }
}
