import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-remove-members',
  templateUrl: './remove-members.component.html',
  styleUrls: ['./remove-members.component.scss'],
})
export class RemoveMembersComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() members: any | null = {};
  @Input() pending: boolean | null = false;
  @Input() organizationId: any;
  @Input() numbAccounts: any;
  public users: any;
  @Output() refreshDelete = new EventEmitter<any>();
  public faCircleNorch: any = faCircleNotch;
 
  public faTimes = faTimes;
  public loadSpin: boolean = false;
  public loading: boolean = false;
  constructor(
    private organizationService: OrganizationsService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.users = Object.values(this.members);
  }
  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  public removeMembers() {
    let arrayKey = [];
    for (let k in this.members) {
      arrayKey.push(+k);
    }
    if (this.pending) {
      this.loading=true;
      this.organizationService
      .deletePending(this.organizationId, arrayKey)
      .then((data: any) => {
        if (data.result == 'success') {
          this.loading=false;
          this.toastService.showSuccess('Removed pending users');
          this.refreshDelete.emit();
          this.closeModal();
        } else {
          this.loading=false;
          this.closeModal();
          this.toastService.showError('Error');
        }
      })
      .catch((err) => {
        this.loading=false;
        console.log(err);
      });
    } else {
      this.loading=true;
      this.organizationService
        .removeMembers(this.organizationId, arrayKey)
        .then((data: any) => {
          this.loading=false;
          if (data.result == 'success') {
            this.toastService.showSuccess('Removed users');
            this.refreshDelete.emit();
            this.closeModal();
          } else {
            this.closeModal();
            this.toastService.showError('Error');
          }
        })
        .catch((err) => {
          this.loading=false;
          console.log(err);
        });
    }
  }
}
