<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header px-1">
  <h5 class="modal-title">
    {{ "bulk:invite:title" | translate }}
  </h5>
</div>
<div class="modal-body">
  <h3 class="modal-sub-title mb-3">
    {{ "bulk:invite:download:1" | translate }}
    <a
      href="../../../../assets/img/bulk_example.csv"
      target="_blank"
      download="bulk_example.csv"
      class="ng-binding"
      >{{ "bulk:invite:download:2" | translate }}</a
    >
    {{ "bulk:invite:download:3" | translate }}
  </h3>

  <ngx-file-drop
    #fileDrop
    dropZoneLabel="Drop files here"
    accept=".csv"
    (onFileDrop)="dropped($event)"
  >
    <ng-template
      ngx-file-drop-content-tmp
      let-openFileSelector="openFileSelector"
    >
      <div class="text-center p-3 pt-0">
        <div class="btn btn-primary mb-3" (click)="openFileSelector($event)">
          Browse your computer
        </div>
        <h6>Drag and drop your Csv here</h6>
        <p class="mb-1 fs-14">Supported file types: CSV.</p>
        <p class="mb-1 fs-14">
          {{ fileName }}
        </p>
      </div>
    </ng-template>
  </ngx-file-drop>

  <div class="row">
    <div class="col-3">
      <label for="clip-title" class="d-block mt-3 form-label">{{
        "Language" | translate
      }}</label>

      <select
        class="form-select"
        aria-label="Language"
        (change)="changeOrder()"
        [(ngModel)]="language"
      >
        <option selected value="en">
          {{ "English" | translate }}
        </option>
        <option value="nl">
          {{ "Dutch" | translate }}
        </option>
      </select>
    </div>
  </div>

  <div class="row mt-3">
    <div class="form-group">
      <label class="form-label" for="textarea">{{
        "collection:members:add:members:message:label" | translate
      }}</label>
      <textarea
        [(ngModel)]="infoUser.message"
        class="form-control short-form-description"
        id="textarea"
        rows="4"
      ></textarea>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="form-group">
      <div class="row">
        <label for="clip-title" class="d-block form-label">{{
          "Include pending invitations" | translate
        }}</label>
        <div class="d-flex">
          <div class="col-md-10" style="margin: 0; padding: 0">
            <p class="short-form-description">
              Yes: If an e-mail in this bulk already appears in the pending list
              it will receive a new invitation.<br />
              No: Email addresses already in the pending list will be skipped.
            </p>
          </div>
          <div class="col-md-2" style="margin: 0; padding: 0">
            <label class="fancy-radio-checkbox modal-label">
              <input
                class="fancy-input"
                [(ngModel)]="infoUser.include_pending"
                id="open"
                name="open"
                type="radio"
                [value]="true"
              />
              <div class="fancy-graphic"></div>
              {{ "Yes" | translate }}
            </label>
            <label class="fancy-radio-checkbox modal-label">
              <input
                class="fancy-input"
                [(ngModel)]="infoUser.include_pending"
                id="open"
                name="open"
                type="radio"
                [value]="false"
              />
              <div class="fancy-graphic"></div>
              {{ "No" | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="row justify-content-end mt-3">
    <div class="col-auto px-0">
      <button
        (click)="sendInvitation()"
        [disabled]="infoUser.file == '' || loadSpin"
        type="button"
        role="tab"
        class="btn btn-success"
      >
        <fa-icon
          class="mr-2"
          [icon]="faCircleNorch"
          *ngIf="loadSpin"
          [spin]="true"
        ></fa-icon>
        {{ "Send multiple invitations" | translate }}
      </button>
    </div>
  </div>
</div>
