<div class="row justify-content-center" style="margin-top: -25px">
  <div class="col-auto">
    <app-organization-logo
      [organization]="organization"
    ></app-organization-logo>
  </div>
</div>
<div class="container px-5 py-3">
  <app-login
    [messageRegister]="showMessage"
    [openNormalLogin]="true"
    [state]="state"
    [info]="info"
    [organization]="organization"
    [reloadregister]="true"
    (close)="closeModal()"
    (success)="loggedIn()"
    [message]="message"
  ></app-login>
</div>
