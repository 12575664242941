import { Injectable } from '@angular/core';
import { DhProfile } from '../interfaces/dh-profile';
import { Questionnaire } from '../interfaces/questionnaire';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DhprofileService {

  private dataSubject = new BehaviorSubject<string>('');

  setData(data: string) {
    this.dataSubject.next(data);
  }

  getData(): Observable<string> {
    return this.dataSubject.asObservable();
  }
  constructor(private api: ApiService) {}

  getList(id = null): Promise<Array<DhProfile>> {
    return this.api
      .get('/dh_profile' + (id ? '/' + id : ''))
      .then((response) => {
        if (response.result == 'success') {
          return response.data;
        }
      });
  }

  getQuestionnairesList(
    id: string | null = null
  ): Promise<Array<Questionnaire>> {
    return this.api.get('/questionnaire' + (id ? '/' + id : ''));
  }

  ////////////////
  ///Questionnaire
  ////////////////

  public async getQuestionnaire(id: number) {
    let url = `/questionnaire/${id}`;
    try {
      const questionnaire = await this.api.get(url);
      return questionnaire;
    } catch (error) {
      return error;
    }
  }

  public async createQuestionnaire(data: any) {
    let url = `/questionnaire/create`;
  
    try {
      const questionnaire = await this.api.postJSON(url,{type:'quest_level_3'});
      return questionnaire;
    } catch (error) {
      return error;
    }
  }

  public async sendQuestion(id: string, data: any) {
    try { 
        console.log('data',data)
         const question = await this.api.put(`/questionnaire/${id}`, data);
         return question;
    } catch (error) {
      return error;
    }
  }

  public async sendInfoLastForm(user: string, data: any) {
    try {
      const formQuestionnaire = await this.api.put(`/user/${user}`, {
        dh_profile: JSON.stringify(data),
      });
      return formQuestionnaire;
    } catch (error) {
      return error;
    }
  }

  async getUser() {
    try {
      return await this.api.get(`/user`);
    } catch (error) {
      console.log(error);
    }
  }

  complete(id: number, infoQuestionnaire: any) {
    let json_data = [];

    for (let i = 0; i < infoQuestionnaire.items.length; i++) {
      json_data.push({
        id: i + 1,
        question_id: infoQuestionnaire.items[i].id,
        answer: infoQuestionnaire.items[i].response,
        competence_id: infoQuestionnaire.items[i].competence_id,
      });
    }

    let data = {
      data: { json_data: json_data },
      questionnaireId: id,
    };

    return this.api.postJSON('/dh_profile', data);
  }

  public async deleteProfile(profileId: number) {
    let url = `/dh_profile/remove/${profileId}`;
    return this.api.delete(url);
  }

  public async deleteAllProfile(userId: any) {
    let url = `/dh_profile/${userId}`;
    return this.api.delete(url);
  }

  public async getInfoReport(id: string) {
    let url = `/dh_teams/${id}/report`;
    try {
      const reportInfo = await this.api.get(url);
      return reportInfo;
    } catch (error) {
      return error;
    }
  }

  public async getInfoProfile(id: string) {
    let url = `/dh_teams/id/${id}`;
    try {
      const profileInfo = await this.api.get(url);
      return profileInfo;
    } catch (error) {
      return error;
    }
  }

  public sendInvitations(emails: Array<string>, message: string,language='nl',level=4) {
    let params = {
      message: message,
      users: emails,
      language:language,
      level:level,
      isDHTeam: true,
    };

    return this.api.postJSON('/dh_profile/invite', params);
  }
  public resendInvitation(email: string,level:any=4) {
    let params = {
      message: '',
      level:level,
      users: [email],
    };

    return this.api.postJSON('/dh_profile/invite', params);
  }
}
