import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { CollectionService } from 'src/app/services/collection.service';
import { AuthService } from 'src/app/services/auth.service';
import { InitService } from 'src/app/services/init.service';
import { Playlist } from 'src/app/interfaces/playlist';

@Component({
  selector: 'app-add-playlist-group-modal',
  templateUrl: './add-playlist.component.html',
  styleUrls: ['./add-playlist.component.scss']
})

export class AddPlayListComponent implements OnInit {
  @Output() success: EventEmitter<any> = new EventEmitter();

  faTimes = faTimes;
  @Input() selfModal: NgbModalRef | null = null;
  @Input() title:String='';
  @Input() group:any;
  inputValue=false;
  playlists: Array<any> = [];
  playlistsOwn: Array<any> = [];
  faCircleNorch = faCircleNotch;
  loading= true;
  addLoading=false;
  actualTab='allPlayList';
  username: any;
  itemSelect='';
  searchText=''
  searchTextOwn=''


  constructor(
    private collectionService: CollectionService,
    public auth: AuthService,
    private init: InitService
  ) {
    this.auth.user.subscribe((user) => {
      this.username = user?.username;
    });
  }

  ngOnInit(): void {
    this.getPlayListAll();
    this.getPlayListOwn();
  } 

  getPlayListAll(){
    let id_organization = this.group?.organizations[0].id || this.init.getCurrentOrg()?.id,
      isOpen = 'true',
      excluded = this.group?.id || undefined,
      owner = '';

    this.loading = true;
    this.collectionService.getCollection(20,1,'playlist',id_organization,this.searchText,isOpen,excluded,owner,'true').then(res => {
      this.playlists = res.data.collections;
      this.loading = false;
    });

  }
  getPlayListOwn(){
    let id_organization = this.group?.organizations[0].id || this.init.getCurrentOrg()?.id,
      isOpen = 'true',
      excluded = this.group?.id || undefined;

    this.collectionService.getCollection(20,1,'playlist',id_organization,this.searchTextOwn,isOpen,excluded,this.username,null).then(res => {
      this.playlistsOwn = res.data.collections;
    });
  }

  getTab(tab: string) {
    this.actualTab = tab;
  }

  closeModal(){
    if(this.selfModal) this.selfModal?.close();
    else $("ngb-modal-window").click();
  }

  selectItem(value:string){
    this.itemSelect = value;
  }

  sendData(){
    this.addLoading = true;
    if (this.group) {
      this.collectionService.setCollection('playlist',this.itemSelect,this.group.id).then(res => {
        this.addLoading=false;
        this.closeModal();
      });
    } else {
      this.success.emit(this.findSelectedPlaylist());
      this.closeModal()
    }
  }

  searchAll(text:string){
    this.searchText=text;
    this.getPlayListAll();
  }
  searchOwn(text:string){
    this.searchTextOwn=text;
    this.getPlayListOwn();
  }

  findSelectedPlaylist() {
    let playlist = this.playlists.find( (p: Playlist) => p.id == +this.itemSelect );
    if (!playlist) {
      playlist = this.playlistsOwn.find( (p: Playlist) => p.id == +this.itemSelect );
    }
    return playlist;
  }
}
