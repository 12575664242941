<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">{{ "collection:profile:button:ownership" | translate }}</h4>
</div>
<div class="modal-body">
  <div class="col-12">
    <p>{{ "users:change:owner:modal:description" | translate }} {{type| translate}}</p>
  </div>

  <div class="form-group">
    <app-search-owner
      [collection]="collection"
     
      id="search"
      (searchEvent)="usersSelected($event)"
      type="{{type}}"
      placeholder="{{ 'search:by:email' | translate }}"
    ></app-search-owner>
  </div>
   
  <div class="d-flex justify-content-end mt-2">
    <a class="btn btn-link font-weight-Light mx-2" (click)="closeModal()">
      <span>{{
        "users:deactivate:account:modal:description:button:cancel" | translate
      }}</span>
    </a>
   
    <button
      class="btn btn-primary"
      (click)="changeOwner()"
      [disabled]="loading||!userSelect||userSelect<1"
    >
      <span>{{ "collection:profile:button:ownership" | translate }}</span>
      <fa-icon
        *ngIf="loading"
        class="ms-2"
        [icon]="faCircleNotch"
        [spin]="true"
      ></fa-icon>
    </button>
  </div>
</div>
