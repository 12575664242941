import { Component, Input, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { faClock,faStar  } from '@fortawesome/free-regular-svg-icons';
import { VideoUtilitiesService } from 'src/app/utilities/video-utilities.service';

@Component({
  selector: 'app-video-list-card',
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.scss']
})
export class VideoListCardComponent implements OnInit {
 
	@Input() video: any;
	@Input() itemSelect: any;
  @Input() featured: boolean = false;
  
  @Output() selectItem = new EventEmitter<string>();


  loading = false;
  title = '';
  description = '';
  language: String = 'en';
  faClock = faClock;
  faStar = faStar;

    
  constructor(private videoUtils: VideoUtilitiesService) {  }

  ngOnInit(): void {
    if (this.video) {
      let info = this.videoUtils.getInfoByLang(this.video)
      this.title = info?info.name:'';
      this.video.selected = false;
      this.description = info?info.description:'';
    }
  }

  select() {
    this.video.selected = !this.video.selected 
    this.selectItem.emit(this.video.id);
  }

}
