<div class="comments-block">
  <app-loading-icon *ngIf="loading"></app-loading-icon>
  <ng-container *ngIf="!loading">
    <div class="row" *ngIf="title">
      <h3 class="h5 mb-3">
        {{ comments.length }}
        <span *ngIf="comments.length == 1">{{
          "play_video:comments:singular:label" | translate
        }}</span>
        <span *ngIf="comments.length != 1">{{
          "play_video:comments:plural:label" | translate
        }}</span>
      </h3>
    </div>

    <app-comment
      *ngFor="let comment of comments | orderBy: 'id'; let i = index"
      [comment]="comment"
      [type]="type"
      [videoId]="video?.id"
      (refresh)="refreshDelete(i)"
      (refreshEdit)="refreshEdit($event, i)"
    ></app-comment>

    <div class="row mb-2 gx-2">
      <div class="col-auto">
        <app-user-icon [user]="me" [size]="'medium'"></app-user-icon>
      </div>
      <div class="col">
        <textarea
          [(ngModel)]="newComment"
          name=""
          id=""
          class="form-control"
          placeholder="{{ placeholder }}"
        ></textarea>
        <button
          *ngIf="newComment"
          type="button"
          class="btn btn-primary mt-2"
          (click)="addComment()"
          [class.disabled]="addingComment"
        >
          {{ "play_video:comments:add_comment" | translate }}
          <fa-icon
            class="ms-2"
            [icon]="faCircleNorch"
            *ngIf="addingComment"
            [spin]="true"
          ></fa-icon>
        </button>
      </div>
    </div>
  </ng-container>
</div>
