<div class="row mb-2 gx-2">
  <div class="col-auto">
    <app-user-icon
      [user]="comment ? comment.user : null"
      [size]="'medium'"
    ></app-user-icon>
  </div>
  <div class="col">
    <div class="comment-box rounded-15 p-3 bg-gray">
      <div *ngIf="editingCommentary" class="row mb-2 gx-2">
        <textarea
          class="input-box form-control"
          name="edit"
          id=""
          cols="30"
          rows="2"
          [(ngModel)]="commentaryEdited"
        ></textarea>

        <div class="row justify-content-end gap-4 pt-2">
          <div class="col-auto">
            <button (click)="cancel()" class="btn btn-outlet">
              {{ "Cancel" | translate }}
            </button>
          </div>
          <div class="col-1">
            <button
              (click)="editCommentary(comment)"
              class="btn btn-primary text-nowrap"
            >
              {{ "Edit" | translate }}
              <fa-icon
                class="mr-2"
                [icon]="faCircleNorch"
                *ngIf="loadingSpin"
                [spin]="true"
              ></fa-icon>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="!editingCommentary" class="row mb-2 gx-2">
        <div class="col">
          <h5 class="h6 mb-0 fs-14 text-primary">
            {{ comment?.user?.display_name }}
          </h5>
        </div>
        <div class="col-auto fs-14 text-muted d-none d-sm-block">
          {{ comment?.created_at | amTimeAgo }}
        </div>
        <div class="col-auto" *ngIf="userCanEdit()">
          <div
            ngbDropdown
            class="d-inline-block float_right"
            placement="bottom-right"
          >
            <button
              type="button"
              class="btn btn-outline-primary"
              id="dropdownBasic1"
              ngbDropdownToggle
            >
              <i class="fa fa-ellipsis-h"></i>
              <fa-icon
                *ngIf="loadingSpin"
                class="ms-2"
                [icon]="faCircleNorch"
                [spin]="true"
              ></fa-icon>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="mt-1">
              <button  ngbDropdownItem (click)="openEdit()">
                {{ "comments:edit" | translate }}
              </button>
              <button
                disabled
                (click)="deleteCommentaryConversation(comment)"
                class="text-danger"
                ngbDropdownItem
              >
                {{ "comments:delete" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <p *ngIf="!editingCommentary" class="mb-0 fs-14">
        {{ comment?.comment }}
      </p>
    </div>
  </div>
</div>
