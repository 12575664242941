import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-leave-video',
  templateUrl: './leave-video.component.html',
  styleUrls: ['./leave-video.component.scss'],
})
export class LeaveVideoComponent implements OnInit {
  @Input() selfModalNotification: NgbModalRef | null = null;
  @Output() closeUpublished: EventEmitter<any> = new EventEmitter();
  public faCircleNorch: any = faCircleNotch;

  public faTimes = faTimes;
  public loadSpin: boolean = false;

  constructor() {}

  ngOnInit(): void {}
  public closeModal() {
    if (this.selfModalNotification) this.selfModalNotification?.close();
    else $('ngb-modal-window').click();
  }

  public closeModalUnpublished() {
   this.selfModalNotification?.close();
    this.closeUpublished.emit();
  }
}
