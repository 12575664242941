<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">
    {{ (typeConversation == "file" ? "Files" : title) | translate }}
  </h4>
</div>
<div class="modal-body">
  <ng-container *ngIf="loading; else bodyModal">
    <app-loading-icon></app-loading-icon>
  </ng-container>
  <ng-template #bodyModal>
    <div class="row p-3 conversation">
      <div class="avatar">
        <app-user-icon
          size="medium"
          containerClass="resize-avatar-group"
          [user]="conversationData?.user"
        ></app-user-icon>
      </div>
      <div class="data col">
        <div class="title">{{ conversationData?.title }}</div>
        <div class="the-creator mb-3">
          <span
            class="by-whom ng-binding"
            style="color: #999999; font-weight: 500; font-size: 14px"
            >{{ "author:by" | translate }}
          </span>
          <span
            class="the-author ng-binding"
            style="color: #a251bd; font-weight: 500; font-size: 14px"
            >{{ conversationData?.user.display_name }}</span
          >
          <span style="color: #999999; font-weight: 500; font-size: 14px">
            ·
          </span>
          <span
            class="the-time"
            style="color: #999999; font-weight: 500; font-size: 14px"
            >{{ conversationData?.created_at | amTimeAgo }}</span
          >
        </div>
        <div
          class="description p-margin"
          [innerHtml]="conversationData?.description"
        ></div>
        <div
          *ngIf="conversationData?.mimetype == 'audio/mpeg'"
          class="row preview-audio my-1 justify-content-center"
        >
          <audio class="col-12" controls>
            <source
              [src]="
                conversationData?.pathUrl || conversationData?.file.pathUrl
              "
              type="audio/ogg"
            />
            Your browser does not support the audio element.
          </audio>
        </div>
        <div
          class="file-download-name-size"
          *ngIf="
            conversationData?.mimetype &&
            conversationData?.mimetype != 'audio/mpeg'
          "
        >
          <img
            class="user-avatar rounded-list-icon file-list-icon"
            [src]="getMimeTypeImg(conversationData?.mimetype)"
          />

          <div class="d-block file-download-title">
            <h4 class="item-title">
              {{ conversationData?.originalName || conversationData?.title }}
            </h4>
            <p>{{ conversationData?.sizeFriendly }}</p>
          </div>

          <div class="file-item-controls">
            <a
              href="{{ conversationData?.pathUrl }}"
              target="_blank"
              class="button button-download"
              >{{ "files:download:button" | translate }}</a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="activity-list-item block margin-bottom">
      <app-group-activity-item-comment
        [type]="typeConversation"
        [activities]="conversationData"
        (refreshConversation)="getCountComment($event)"
      ></app-group-activity-item-comment>
      <!-- <app-file-card
        type="activity"
        [activities]="conversationData"
      ></app-file-card> -->
      <!-- *ngIf="activity.type_activity == 'upload_file'" -->
    </div>
  </ng-template>
</div>
