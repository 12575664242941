import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements OnInit {
  @ViewChild('searchInput') searchInput: ElementRef | undefined;
  @Input() inputPlaceholder: any;
  @Input() searchText: any;
  @Input() loading: boolean = false;
  @Input() disabled: boolean = false;
  @Input() actualSearch: string = '';
  @Output() searchEvent = new EventEmitter<string>();
  faCircleNorch = faCircleNotch;

  searchTxt = '';

  constructor(private translate: TranslateService) {}
  ngOnChanges(changes: SimpleChanges): void {
    // this.translate.instant(this.inputPlaceholder);
  }
  ngAfterViewInit(): void {
    this.searchInput!.nativeElement.focus();
  }
  ngOnInit(): void {
    this.searchTxt = this.actualSearch;
  }
  search() {
    this.searchEvent.emit(this.searchTxt);
  }
}
