<div class="icon rounded-circle" [ngClass]="class">
  <img
    [ngStyle]="{
      'background-color': background == 'black' ? '#fff' : background
    }"
    class="rounded-circle"
    [src]="src"
    onError="assets/img/avatars/profile-avatar-purple.svg"
    
  />
</div>
