<div class="row text-center">
  <h1>{{ "home:title:reviews" | translate }}</h1>
  <p class="subtitle-review py-2">
    {{ "home:subtitle:reviews" | translate }}
  </p>
</div>
<div id="reviews" class="row justify-content-center pb-5">
  <app-empty-content
    *ngIf="currentLang == 'en' && !infoCardsReviewsEn?.length"
    [primaryText]="'home:not:more:reviews' | translate"
  ></app-empty-content>
  <app-empty-content
    *ngIf="currentLang == 'nl' && !infoCardsReviewsNl?.length"
    [primaryText]="'home:not:more:reviews' | translate"
  ></app-empty-content>
  <div class="mt-3 card-columns px-5 px-md-1">
    <div
      *ngFor="
        let item of currentLang == 'en'
          ? infoCardsReviewsEn
          : infoCardsReviewsNl;
        let i = index
      "
      [style.border]="borderColors[i]"
      class="card mx-2 my-3 my-md-2 py-1 px-3"
    >
      <div class="card-body">
        <h3 *ngIf="item.collection?.name" class="card-title fw-normal">
          {{
            item.collection.name > 24
              ? (item.collection.name | slice : 0 : 24) + "..."
              : item.collection.name
          }}
        </h3>
        <p class="card-text">
          {{ item?.description }}
        </p>
        <div class="pt-2 d-flex gap-2">
          <app-user-icon
            *ngIf="item?.user.avatarPathUrlMedium"
            class="col-auto pr-2"
            container-class="avatar"
            [user]="item?.user"
            size="medium"
          ></app-user-icon>
          <div class="user-info col">
            <h5>
              <span class="user-name">{{ item?.username }}</span>
            </h5>
            <p class="user-description">{{ item?.job_title }}</p>
          </div>
        </div>
        <div class="row justify-content-start">
          <div class="col-auto">
            <a class="link" (click)="goToCourse(item)">
              {{ "continueWatching:completed:button" | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
