<div class="row justify-content-center" style="margin-top: -25px;">
  <div class="col-auto">
      <app-organization-logo [organization]="organization"></app-organization-logo>
  </div>
</div>
<div class="container px-5 py-3">
  <h3 class="text-center fw-bold h4">
    {{ "sign_up:title:label" | translate }}
  </h3>
  <div class="row text-center pb-3">
    <p>{{ "login:sign_up:invite:description" | translate: {organization: organization?.name, user: invitation.user.display_name} }}</p>
  </div>
  <form [formGroup]="registerForm">
    <div class="mb-3">
      <label for="register-username" class="form-label fw-500 fs-15">{{
        "sign_up:username:label" | translate
      }}</label>

      <input
        type="username"
        class="form-control"
        id="register-username"
        formControlName="username"
        placeholder="{{ 'sign_up:username:field:label' | translate }}"
      />
    </div>
    <div class="mb-3">
      <label for="register-password" class="form-label fw-500 fs-15">{{
        "login:password:label" | translate
      }}</label>

      <input
        type="password"
        class="form-control"
        id="register-password"
        formControlName="password"
        placeholder="{{ 'sign_up:password:field:label' | translate }}"
      />

      <div
        *ngIf="registerForm.controls['password'].value"
        class="p-2 px-1 px-md-4 text-muted"
      >
        <div class="check-meter mb-1">
          <fa-icon
            [icon]="faCheckCircle"
            [ngClass]="
              registerForm.controls['password'].hasError('minlength')
                ? 'text-muted'
                : 'text-success'
            "
          ></fa-icon>
          {{ "password:requirements:length" | translate }}
        </div>
        <div class="check-meter mb-1">
          <fa-icon
            [icon]="faCheckCircle"
            [ngClass]="
              registerForm.controls['password'].hasError('lowerCount')
                ? 'text-muted'
                : 'text-success'
            "
          ></fa-icon>
          {{ "password:requirements:letter:l" | translate }}
        </div>
        <div class="check-meter mb-1">
          <fa-icon
            [icon]="faCheckCircle"
            [ngClass]="
              registerForm.controls['password'].hasError('upperCount')
                ? 'text-muted'
                : 'text-success'
            "
          ></fa-icon>
          {{ "password:requirements:letter:u" | translate }}.
        </div>
        <div class="check-meter mb-1">
          <fa-icon
            [icon]="faCheckCircle"
            [ngClass]="
              registerForm.controls['password'].hasError('numberCount')
                ? 'text-muted'
                : 'text-success'
            "
          ></fa-icon>
          {{ "password:requirements:number" | translate }}
        </div>
        <div class="check-meter mb-1">
          <fa-icon
            [icon]="faCheckCircle"
            [ngClass]="
              registerForm.controls['password'].hasError('specialCount')
                ? 'text-muted'
                : 'text-success'
            "
          ></fa-icon>
          {{ "password:requirements:special" | translate }}
        </div>
      </div>
    </div>

    <div class="form-check" *ngIf="currentOrg?.show_terms_conditions">
      <input
        id="check1"
        type="checkbox"
        class="form-check-input"
        formControlName="terms_conditions"
      />
      <label for="check1" class="form-check-label fw-500 fs-14">
        {{ "login:sign_up:terms:1" | translate }}
        <span class="text-primary pe-pointer" (click)="openModalTerms()">
          {{ "login:sign_up:terms:2" | translate }}
        </span>
      </label>
    </div>

    <div class="mb-4 form-check" *ngIf="currentOrg?.show_security_policy">
      <input
        id="check2"
        type="checkbox"
        class="form-check-input"
        formControlName="security_policy"
      />
      <label for="check2" class="form-check-label fw-500 fs-14">
        {{ "login:sign_up:terms:1" | translate }}
        <span class="text-primary pe-pointer" (click)="openModalPrivacy()">
          {{ "login:sign_up:privacy:2" | translate }}
        </span>
      </label>
    </div>

    <div class="d-grid gap-2">
      <button
        class="btn btn-primary fs-14 py-2"
        href="javascript:void(0);"
        (click)="register()"
        [class.disabled]="loading"
      >
        {{ "sign_up:sign_up_and_enter_button:label" | translate
        }}<fa-icon
          class="ms-2"
          [icon]="faCircleNorch"
          *ngIf="loading"
          [spin]="true"
        ></fa-icon>
      </button>
    </div>
  </form>
</div>
