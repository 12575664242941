import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TeamService } from 'src/app/services/team.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-team-settings',
  templateUrl: './team-settings.component.html',
  styleUrls: ['./team-settings.component.scss'],
})
export class TeamSettingsComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() team: any;
  @Output() refresh: EventEmitter<any> = new EventEmitter();
  public teamName: string = '';
  public excludeOwner: boolean = false;
  public loading: boolean = false;
  constructor(
    private toastService: ToastService,
    private teamService: TeamService
  ) {}

  ngOnInit(): void {
    this.teamName = this.team?.name;
    this.excludeOwner = this.team?.exclude_owner;
  }
  ngOnChanges(changes: SimpleChanges): void {}
  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
  public editTeamSettings() {
    this.loading = true;
    this.teamService.editTeamSettings(this.team, this.teamName, this.excludeOwner).then((resp) => {
      this.loading = false;
      if (resp.result == 'success') {
        this.toastService.showSuccess('Team settings saved');
        this.closeModal()
        this.refresh.emit();
      } else {
        this.toastService.showError('Error saving settings');
      }
    });
  }

  setShowTeamOwner(value: any) {
    this.excludeOwner = value;
    // console.log('Upload:',value);Comment
  }

}
