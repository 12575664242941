<div  class="text-empty-content-valign">
    <div >activity group component</div>
</div>
<div class="activity-list col-xs-12 col-md-8 col-lg-8 col-sm-12 no-padding-765" style="order: 1;">
    <div *ngFor="let activity of activities">
        <app-group-activity-item [activity]="activity"></app-group-activity-item>
    </div>
</div>
<!-- <div class="activity-list col-xs-12 col-md-8 col-lg-8 col-sm-12 no-padding-765" style="order: 1;">
    <empty-content
           block-style="true"
           class="activity-profile-empty-content"
           ng-show="showEmptyContent"
           primary-text="'groups:profile:tab:activity:empty' | translate"
           secondary-text="'groups:profile:tab:activity:empty:subtitle' | translate"
       ></empty-content>

   <activity-item
       ng-repeat="activity in activities"
       activity="activity"
       group="group"
       focus="activityIdFocus == activity.id"
   ></activity-item>
</div> -->