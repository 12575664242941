import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-voting-stars',
  templateUrl: './voting-stars.component.html',
  styleUrls: ['./voting-stars.component.scss']
})
export class VotingStarsComponent implements OnInit {
  @Output() rate = new EventEmitter<number>();
  activeStar: number | undefined;

  voting = false;

  constructor() { }

  ngOnInit(): void {
  }

  setActiveStar( star: number ) {
    if (!this.voting) {
      this.activeStar = star
    }
  }

  vote( star: number ) {
    this.voting = true;
    this.rate.emit(star);
  }
}
