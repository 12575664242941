import { Component, ElementRef, OnInit, AfterContentInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { updateLocale } from 'moment';
import { User } from 'src/app/interfaces/user';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-movie-tabs-wrapper',
  templateUrl: './movie-tabs-wrapper.component.html',
  styleUrls: ['./movie-tabs-wrapper.component.scss']
})
export class MovieTabsWrapperComponent implements OnInit, AfterContentInit, OnChanges {
  @Input() main = false;
  @Input() update = false;
  tabs: Array<HTMLElement> = [];
  indicator: any;
  load = true;

  constructor (
    private elem: ElementRef,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.tabs.forEach( (item: HTMLElement, index:any) => {
        if ( item.firstElementChild?.classList.contains('active') ) {
          this.checkSwitcher(item);
        }
      });
    }, 300);
  }

  ngAfterContentInit(): void {
    this.initTabs()
    this.auth.user.subscribe( () => {
      setTimeout( () => {
        this.tabs.forEach( (item: HTMLElement, index:any) => {
          if ( item.firstElementChild?.classList.contains('active') ) {
            this.checkSwitcher(item);
          }
        });
      }, 800)
    });
  }

  public initTabs(){
    this.tabs = this.elem.nativeElement.querySelectorAll('.nav-item');
    this.indicator = this.elem.nativeElement.querySelector('.switch-nav-indicator');
    this.tabs.forEach( (item: HTMLElement, index:any) => {
      item.addEventListener('click', (e: MouseEvent) => {
        if (!(e.currentTarget as HTMLElement).firstElementChild?.classList.contains('disabled')) {
          this.checkSwitcher(e.currentTarget);
          this.toggleActiveClass(e.currentTarget as HTMLElement);
        }
      });
      if ( item.firstElementChild?.classList.contains('active') ) {
        this.checkSwitcher(item);
      }
    });
  }
  checkSwitcher(el: any) {
    // /* New swiper! */
    this.tabs.forEach( (item: any) => {
      item.removeAttribute('style');
    });
    if(this.load){
      this.load=false;
      return;
    }
    this.indicator.style.width = el.offsetWidth + "px";
    this.indicator.style.left = el.offsetLeft + "px";
    this.indicator.style.top = el.offsetTop + 46 + "px"; // 46px its the height of element
  }

  toggleActiveClass(el: HTMLElement) {
    this.tabs.forEach( (item: HTMLElement, index:any) => {
      if (item == el) {
        item.firstElementChild?.classList.add('active');
      } else {
        item.firstElementChild?.classList.remove('active');
      }
    });
  }
}
