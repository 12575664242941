<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title">
    {{
      activate
        ? ("welcome:back:modal:title" | translate)
        : ("users:deactivate:account:modal:title" | translate)
    }}
  </h4>
</div>
<div class="modal-body flex-column">
  <div class="row">
    <p class="description">
      {{
        activate
          ? ("users:activate:account:modal:description" | translate)
          : ("users:deactivate:account:modal:description:superuser" | translate)
      }}
    </p>
  </div>
</div>
<div class="row justify-content-end modal-footer">
  <hr class="common-separator my-0" />
  <div class="row justify-content-end text-center">
    <div class="col-2">
      <button
        type="button"
        class="btn btn-outline-primary ms-2 fs-15 text-nowrap"
        (click)="closeModal()"
      >
        {{ "Cancel" | translate }}
      </button>
    </div>
    <div class="col-auto">
      <button
        (click)="deactiveAccount()"
        type="button"
        role="tab"
        class="btn btn-danger text-nowrap"
        [disabled]="loadSpin"
      >
        <fa-icon
          class="mr-2"
          [icon]="faCircleNorch"
          *ngIf="loadSpin"
          [spin]="true"
        ></fa-icon>
        {{
          activate
            ? ("Yes, reactivate this account" | translate)
            : ("Deactivate this account" | translate)
        }}
      </button>
    </div>
  </div>
</div>
