<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">
    {{ "admin:users:btn:edit" | translate }}
  </h4>
</div>
<div class="modal-body p-4">
  <div class="row mb-2">
    <div class="col-auto">
      <app-user-icon size="medium" [user]="avatar"></app-user-icon>
    </div>
    <div class="col-9">
      <div class="row">
        <div class="d-flex justify-content-between">
          <p class="mb-0 title-user">{{ user?.display_name }}</p>
        </div>
        <p class="mb-0" style="color: #302e2e">@{{ user?.username }}</p>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-6">
      <div class="title-desc">
        {{ "admin:users:info:title:1" | translate }}
      </div>
      <p class="subtitle fw-bold">{{ data?.name }}</p>
    </div>
    <div class="col-6">
      <div class="title-desc">
        {{ "admin:users:info:title:2" | translate }}
      </div>
      <p class="subtitle fw-bold">
        {{ data?.created_at | date : "dd LLL YYYY" }}
      </p>
    </div>
  </div>
  <div class="form-group mt-4">
    <label class="modal-label col-12 my-3" for="clip-title ">{{
      "admin:users:modal:edit_platforms:label:7" | translate
    }}</label>
    <button
      (click)="remove()"
      type="button"
      role="tab"
      class="btn btn-danger col-auto"
    >
      {{ "Remove" | translate }}
    </button>
  </div>
  <hr class="common-separator" />
  <div class="form-group">
    <div class="row justify-content-end mx-1">
      <button
        (click)="closeModal()"
        [disabled]="loading"
        type="button"
        role="tab"
        class="btn btn-primary col-auto float-right"
      >
        {{ "Cancel" | translate }}
        <fa-icon [icon]="faCircleNorch" *ngIf="loading" [spin]="true"></fa-icon>
      </button>
    </div>
  </div>
</div>
