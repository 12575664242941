<div class="container-fluid">
  <app-toasts-container></app-toasts-container>
  <div class="container">
    <div class="row text-center pt-3">
      <h1 *ngIf="!deleteFromMembers">{{ "delete_account:start:title" | translate }}</h1>
      <h1 *ngIf="deleteFromMembers">{{ "delete_account:admin:start:title" | translate:{username: username} }}</h1>
      <div class="col-12 my-3">
        <p *ngIf="!deleteFromMembers">{{ "users:remove:account:modal:description:1" | translate }}</p>
        <p *ngIf="deleteFromMembers">{{ "users:remove:account:modal:description:1:superuser" | translate }}</p>
      </div>
      <div class="col-12 my-3">
        <p *ngIf="!deleteFromMembers">{{ "users:remove:account:modal:description:2" | translate }}</p>
        <p *ngIf="deleteFromMembers">{{ "users:remove:account:modal:description:2:superuser" | translate }}</p>
      </div>
      <div class="col-12 my-3">
        <p *ngIf="!deleteFromMembers">{{ "users:remove:account:modal:description:3" | translate }}</p>
        <p *ngIf="deleteFromMembers">{{ "users:remove:account:modal:description:3:superuser" | translate }}</p>
      </div>
      <div class="col-12 my-3 text-danger">
        <p *ngIf="!deleteFromMembers">{{ "users:remove:account:modal:description:4" | translate }}</p>
        <p *ngIf="deleteFromMembers">{{ "users:remove:account:modal:description:4:superuser" | translate }}</p>
      </div>
    </div>

    <div class="container mt-5">
      <app-loading-icon *ngIf="loading; else content"> </app-loading-icon>
    </div>

    <ng-template #content>
      <app-empty-content *ngIf="(!courses&&!videos&&!playlists&&!files)"
      [primaryText]="'user:profile:delete:empty' | translate"></app-empty-content>


      <div class="row text-center" *ngIf="courses?.length">
        <h2 *ngIf="!deleteFromMembers">{{ "delete_account:title:courses" | translate }}</h2>
        <h2 *ngIf="deleteFromMembers">{{ "delete_account:admin:title:courses" | translate }}{{ username }}</h2>
        <div class="col">
          <app-empty-content *ngIf="!loading&&courses?.length==0"
            [primaryText]="'user:profile:videos:empty' | translate"></app-empty-content>
          <div class="row file my-5" *ngFor="let course of courses">
            <div class="card">
              <div class="flex-row d-flex p-4">
                <img class="card-img-left col-3" [src]="course?.thumbnail_url" (error)="onImgError($event)" />
                <div class="card-body text-start pt-0 col-7">
                  <h4 class="card-title h5 h4-sm">{{ course.name }}</h4>
                  <div class="card-text from-description m-0" [innerHTML]="course.description"> </div>
                </div>
                <div class="card-body text-start pt-0 col-2">
                  {{'collection:profile:button:ownership'|translate}}
                </div>
              </div>
              <hr class="col-12 my-2 separator" />
              <div class="flex-row  text-start record-items px-4" *ngIf="course?.organizations">
                <h5 class="title-record">{{'delete_account:admin:title:platfom' |translate}}</h5>
                <p *ngFor="let item of course?.organizations">
                  {{ item.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="row text-center" *ngIf="videos?.length">
        <h2 *ngIf="!deleteFromMembers">{{ "delete_account:title:videos" | translate }}</h2>
        <h2 *ngIf="deleteFromMembers">{{ "delete_account:admin:title:videos" | translate }}{{ username }}</h2>
        <div class="col">
          <app-empty-content *ngIf="!loading&&videos?.length==0"
            [primaryText]="'user:profile:videos:empty' | translate"></app-empty-content>
          <div class="row file my-5" *ngFor="let video of videos">
            <div class="card">
              <div class="flex-row d-flex p-4">
                <img class="card-img-left col-3" [src]="video?.thumbnail_url" (error)="onImgError($event)" />
                <div class="card-body text-start pt-0  col-7">
                  <h4 class="card-title h5 h4-sm">{{ video.title }}</h4>
                  <p class="card-text from-description m-0">{{ video.from }}</p>
                  <p class="card-text">{{ video.description }}</p>
                </div>
                <!-- <div class="card-body text-end p-0 col-2">
                 <button  (click)="changeOwner(video,'video')" class="btn btn-primary">Assign new owner</button> 
                </div> -->
              </div>
              <hr class="col-12 my-2 separator" />
              <div class="flex-row  text-start record-items px-4" *ngIf="video?.organizations">
                <h5 class="title-record">{{'delete_account:admin:title:platfom' |translate}}</h5>
                <p *ngFor="let item of video?.organizations">
                  {{ item.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row text-center justify-content-center" *ngIf="playlists?.length">
        <h2 *ngIf="!deleteFromMembers">{{ "delete_account:title:playlists " | translate }}</h2>
        <h2 *ngIf="deleteFromMembers">{{ "delete_account:admin:title:playlists" | translate }}{{ username }}</h2>
        <div class="col">
          <app-loading-icon *ngIf="loading"> </app-loading-icon>
          <app-empty-content *ngIf="!loading&&playlists?.length==0"
            [primaryText]="'There are no files here yet.' | translate"></app-empty-content>
          <div class="row file my-5" *ngFor="let playlist of playlists">
            <div class="card">
              <div class="flex-row d-flex p-4">
                <div class="col-2 img-container my-auto">
                  <img class="card-img-left col-3" [src]="playlist.cover_image" (error)="onImgError($event)" />
                </div>
                <div class="card-body text-start pt-0 col-7">
                  <h4 class="card-title h5 h4-sm">{{ playlist.name }}</h4>
                  <div class="card-text" [innerHTML]="playlist.description"></div>
                </div>
                <div class="card-body text-end p-0 col-2">
                  <button  (click)="changeOwner(playlist,'playlist')" class="btn btn-primary">{{'collection:profile:button:ownership'|translate}}</button> 
                 </div>
              </div>


              <hr class="col-12 my-2 separator" />
              <div class="flex-row  text-start record-items px-4" *ngIf="playlist?.organizations">
                <h5 class="title-record">{{'delete_account:admin:title:platfom' |translate}}</h5>
                <p *ngFor="let item of playlist?.organizations">
                  {{ item.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row text-center justify-content-center" *ngIf="groups?.length">
        <h2 *ngIf="!deleteFromMembers">{{ "delete_account:title:groups" | translate }}</h2>
        <h2 *ngIf="deleteFromMembers">{{ "delete_account:admin:title:groups" | translate }}{{ username }}</h2>
        <div class="col">
          <app-loading-icon *ngIf="loading"> </app-loading-icon>
          <app-empty-content *ngIf="!loading&&groups?.length==0"
            [primaryText]="'There are no files here yet.' | translate"></app-empty-content>
          <div class="row file my-5" *ngFor="let group of groups">
            <div class="card">
              <div class="flex-row d-flex p-4">
                <div class="col-2 img-container my-auto">
                  <img class="card-img-left col-3" [src]="group.cover_image" (error)="onImgError($event)" />
                </div>
                <div class="card-body text-start pt-0">
                  <h4 class="card-title h5 h4-sm">{{ group.name }}</h4>
                  <div class="card-text" [innerHTML]="group.description"></div>
                </div>
                <div class="card-body text-end p-0 col-2">
                  <button  (click)="changeOwner(group,'group')" class="btn btn-primary">{{'collection:profile:button:ownership'|translate}}</button> 
                 </div>
              </div>

              <hr class="col-12 my-2 separator" />
              <div class="flex-row  text-start record-items px-4" *ngIf="group?.organizations">
                <h5 class="title-record">{{'delete_account:admin:title:platfom' |translate}}</h5>
                <p *ngFor="let item of group?.organizations">
                  {{ item.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="row text-center justify-content-center" *ngIf="files?.length">
        <h2 *ngIf="!deleteFromMembers">{{ "delete_account:title:files" | translate }}</h2>
        <h2 *ngIf="deleteFromMembers">{{ "delete_account:admin:title:files" | translate }}{{ username }}</h2>
        <div class="col">
          <app-loading-icon *ngIf="loading"> </app-loading-icon>
          <app-empty-content *ngIf="!loading&&videos?.length==0"
            [primaryText]="'There are no files here yet.' | translate"></app-empty-content>
          <div class="row file my-5" *ngFor="let file of files">
            <div class="card">
              <div class="flex-row d-flex p-4">
                <div class="col-2 img-container my-auto">
                  <img class="card-img-left img-file" src="assets/img/files/3.png" />
                </div>
                <div class="card-body text-start pt-0">
                  <h4 class="card-title h5 h4-sm">{{ file.title }}</h4>
                  <div class="card-text" [innerHTML]="file.description"></div>
                </div>
              </div>
              <hr class="col-12 my-2 separator" />
              <div class="flex-row text-start record-items px-4" *ngIf="file?.groups">
                <h5 class="title-record">In Groups</h5>
                <p *ngFor="let item of file?.groups">
                  {{ item.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row text-center justify-content-center mt-5">

      </div>
    </ng-template>
  </div>
</div>

<div class="footer-delete">
  <div class="container">

    <div class="row justify-content-end gap-4">
      <div class="col-3 text-end">
        <button class="btn btn-outline-primary" (click)="cancel()">
          {{ "Cancel" | translate }}
        </button>
      </div>
      <div class="col-4 text-end">
        <button (click)="deleteAccount()" class="btn btn-danger text-nowrap"
        [disabled]="loading">
          <span *ngIf="!deleteFromMembers">{{ "delete_account:button:confirm" | translate }}</span>
          <span *ngIf="deleteFromMembers">{{ "delete_account:admin:button:confirm" | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</div>