import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faCheckCircle, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Organization } from 'src/app/interfaces/organization';
import { AuthService } from 'src/app/services/auth.service';
import { InitService } from 'src/app/services/init.service';
import { LangService } from 'src/app/services/lang.service';
import { ToastService } from 'src/app/services/toast.service';
import { lowerCount, numberCount, specialCount, upperCount } from '../login/login/login.component';
import { TermsConditionComponent } from '../login/terms-condition/terms-condition.component';

@Component({
  selector: 'app-invited-register',
  templateUrl: './invited-register.component.html',
  styleUrls: ['./invited-register.component.scss']
})
export class InvitedRegisterComponent implements OnInit {
  @Output('close') close = new EventEmitter();
  @Output('success') success = new EventEmitter();
  @Input() invitation: any | undefined;
  @Input() selfModal: NgbModalRef | undefined;

  organization: Organization|null = null;
  registerForm: UntypedFormGroup;

  loading = false;

  currentLang = 'en';
  currentOrg: Organization | undefined;

  faCircleNorch = faCircleNotch;
  faCheckCircle = faCheckCircle;

  constructor(
    private initService: InitService,
    private fb: UntypedFormBuilder,
    private toastr: ToastService,
    private ms: NgbModal,
    private translate: TranslateService,
    private auth: AuthService,
    private lang: LangService,
  ) {
    this.currentOrg = this.initService.getCurrentOrg()!;

    this.lang.language.subscribe((lang) => {
      this.currentLang = lang || 'en';
    });

    this.registerForm = this.fb.group({
      username: ['', [Validators.required]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          lowerCount(),
          upperCount(),
          numberCount(),
          specialCount(),
        ],
      ],
    });

    if (this.currentOrg.show_terms_conditions) {
      this.registerForm.addControl('terms_conditions', this.fb.control(false, [Validators.requiredTrue]))
    }

    if (this.currentOrg.show_security_policy) {
      this.registerForm.addControl('security_policy', this.fb.control(false, [Validators.requiredTrue]))
    }
  }

  ngOnInit(): void {
    this.organization = this.initService.getCurrentOrg();
    this.registerForm.patchValue({username: this.invitation.display_name});
  }

  closeModal() {
    this.selfModal?.close();
  }

  register() {
    if (this.registerForm.valid) {
      this.loading = true;
      let email = this.invitation.email;
      let password = this.registerForm.get('password')!.value;
      this.auth
        .registerInvited(email, this.registerForm.get('username')!.value, password, this.invitation.hash)
        .then((res) => {
          this.toastr.showSuccess(
            this.translate.instant('register:success')
          );
          this.auth
            .logIn(email, password)
            .then((res) => {
              this.loading = false;
              this.success.emit();
              this.selfModal?.close();
            })
            .catch((err) => {
              if (err.error.message?.friendly) {
                this.toastr.showError(err.error.message.friendly);
              } else {
                this.toastr.showError('Login error');
              }
              this.loading = false;
            });
        })
        .catch((err) => {
          if (err.error.message?.friendly) {
            this.toastr.showError(err.error.message.friendly);
          } else {
            this.toastr.showError(
              this.translate.instant('sign_up:error')
            );
          }
          this.loading = false;
        });
    } else {
      if (
        this.registerForm.controls['username'].errors ||
        this.registerForm.controls['password'].errors
      ) {
        for (const i in this.registerForm.controls) {
          this.registerForm.controls[i].markAsTouched();
        }

        if (this.registerForm.controls['email'].hasError('required')) {
          this.toastr.showError(
            this.translate.instant('sign_up:email:required')
          );
          return;
        }

        if (this.registerForm.controls['email'].hasError('pattern')) {
          this.toastr.showError(
            this.translate.instant('sign_up:email:email')
          );
          return;
        }

        if (this.registerForm.controls['username'].hasError('required')) {
          this.toastr.showError(
            this.translate.instant('sign_up:username:required')
          );
          return;
        }

        if (this.registerForm.controls['password'].hasError('required')) {
          this.toastr.showError(
            this.translate.instant('sign_up:password:required')
          );
          return;
        }
      } else if (
        this.registerForm.controls['terms_conditions'].hasError('required')
      ) {
        this.toastr.showError(
          this.translate.instant('sign_up:terms:required')
        );
      } else if (
        this.registerForm.controls['security_policy'].hasError('required')
      ) {
        this.toastr.showError(
          this.translate.instant('sign_up:privacy:required')
        );
      }
    }
  }

  public openModalTerms() {
    this.close.emit();
    let modalRef = this.ms.open(TermsConditionComponent, {
      windowClass: 'default-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.title = this.translate.instant('login:modal:terms:title');

    let text = this.currentOrg?.show_terms_conditions.texts.en;
    if (this.currentLang == 'nl') {
      text = this.currentOrg?.show_terms_conditions.texts.nl;
    }

    modalRef.componentInstance.description = typeof text == 'object' ? text.long_description : text;
  }

  public openModalPrivacy() {
    this.close.emit();
    let modalRef = this.ms.open(TermsConditionComponent, {
      windowClass: 'default-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.title = this.translate.instant('login:modal:privacy:title');

    let text = this.currentOrg?.show_security_policy.texts.en;
    if (this.currentLang == 'nl') {
      text = this.currentOrg?.show_security_policy.texts.nl;
    }

    modalRef.componentInstance.description = typeof text == 'object' ? text.long_description : text;
  }
}
