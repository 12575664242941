import { ToastService } from '../../../services/toast.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { InitService } from 'src/app/services/init.service';

///////////
//icons
///////////
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
/////////////
//Interfaces
/////////////
import { TableHeader } from 'src/app/interfaces/headers';
///////////
//Services
///////////
import { DhprofileService } from 'src/app/services/dhprofile.service';

@Component({
  selector: 'app-dh-invite',
  templateUrl: './dh-invite.component.html',
  styleUrls: ['./dh-invite.component.scss'],
})
export class DhInviteComponent implements OnInit {
  ///////////
  //Atributes
  ///////////
  faTimes = faTimes;
  @Input() selfModal: NgbModalRef | null = null;
  @Input() team: any;
  @Input() level: any=null;
  @Input() title: string='dh_teams:profile:invite:user'; //titulo por defecto
  public faCircleNorch = faCircleNotch;
  public usersInvited: any = [];
  public actualTab = 'inviteNewUser';
  public loading: boolean = false;
  public selectUsers: any = [];
  public textName: any = '';
  public loadSpin: boolean = false;
  public textMessage: string = '';
  public language='nl';
  public headers: TableHeader[] = [
    { name: 'Icon', show: false, nickname: 'icon', source: 'user', id: 'id' },
    {
      name: 'Name',
      show: true,
      nickname: 'name',
      source: 'user.display_name',
      id: 'id',
    },
    // {
    //   name: 'Email',
    //   show: true,
    //   nickname: 'email',
    //   source: 'user.email',
    //   id: 'id',
    // },
    {
      name: 'Status',
      show: true,
      nickname: 'status',
      source: 'status',
      id: 'id',
    },
  ];
  public users: any = [];

  constructor(
    private toastService: ToastService,
    private translate: TranslateService,
    private initService: InitService,
    private dhs: DhprofileService
  ) {}

  ngOnInit(): void {
    let currentOrganization = this.initService.getCurrentOrg();
    this.language=currentOrganization?currentOrganization.language:'nl';
     console.log('level',this.level)
  }

  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  usersSelected(data: any) {
    this.selectUsers = data;
    // this.textName =  this.selectUsers[this.selectUsers.length-1].display_name;
  }

  sendInvitation() {
    this.loadSpin = true;

    let allUserInvited: Array<string> = this.selectUsers.map( (user: any) => {
      return {
        display_name: user.display_name,
        email: user.email,
      };
    });

    this.dhs.sendInvitations(allUserInvited, this.textMessage,this.language,this.level)
      .then((data) => {
        this.selectUsers = [];
        this.toastService.showSuccess(
          this.translate.instant('collection:members:add:members:success')
        );
        this.loadSpin = false;
        this.closeModal();
      })
      .catch((err) => {
        console.log(err);
        this.toastService.showError(
          this.translate.instant('organization:members:send_invitation:error')
        );
      });
  }
}
