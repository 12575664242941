<div *ngIf="closeBoton" class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title">{{ "Order " | translate }} #{{ orderId }}</h4>
</div>
<div class="modal-body flex-column">
  <div *ngIf="loading; else content" class="row justify-content-center">
    <div class="col">
      <app-loading-icon></app-loading-icon>
    </div>
  </div>
  <ng-template #content>
    <div class="row mb-4">
      <div class="col-12 d-flex">
        <div class="col-auto">
          <td class="align-middle">
            <app-user-icon size="medium" [user]="data?.user"></app-user-icon>
          </td>
        </div>
        <div class="col ms-3">
          <p class="mb-0 title-user">{{ data?.user?.display_name }}</p>
          <p class="mb-0" style="color: #302e2e">{{ data?.user?.email }}</p>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-between">
      <div class="col-4">
        <div class="title-desc">
          {{ "movielearning:admin:orders:profile:info:1" | translate }}
        </div>
        <p class="subtitle fw-bold">
          {{ (data?.created_at | amLocale: language | amDateFormat:'MMMM D YYYY') | titlecase }}
        </p>
      </div>
      <div class="col-4">
        <div class="title-desc">
          {{ "movielearning:admin:orders:profile:info:2" | translate }}
        </div>
         
          



        <p class="subtitle fw-bold">
          {{( data?.product_access_until | amLocale: language | amDateFormat:'MMMM D YYYY') | titlecase }}
        </p>
      </div>
      <div class="col-4">
        <!-- <div class="title-desc">
          {{ "movielearning:admin:orders:profile:info:3" | translate }}
        </div>
        <p class="subtitle fw-bold">
          {{ data?.platforms }}
        </p> -->
      </div>
    </div>
    <div class="row d-flex justify-content-between">
      <div class="col-4">
        <div class="title-desc">
          {{ "movielearning:admin:orders:profile:info:4" | translate }}
        </div>
        <p
          [ngClass]="{
            'custom-color': data.payment_status == 'paid',
            'custom-color2': data.payment_status == 'pending'
          }"
          class="subtitle fw-bold"
        >
          {{ data.payment_status | titlecase }}
        </p>
      </div>
      <div class="col-4">
        <div class="title-desc">
          {{ "movielearning:admin:orders:profile:info:5" | translate }}
        </div>
        <p class="subtitle fw-bold">
          {{data.payment_method=='invite'?'Invite':data.method | titlecase }}
        </p>
      </div>
      <div class="col-4">
        <div class="title-desc">
          {{ "movielearning:admin:orders:profile:info:8" | translate }}
        </div>
        <p class="subtitle fw-bold">
          {{ data?.invoice_details[0]?.firstname }}
        </p>
      </div>
    </div>
    <div class="row d-flex justify-content-start">
      <div class="col-4">
        <div class="title-desc">
          {{ "movielearning:admin:orders:profile:info:7" | translate }}
        </div>
        <p class="subtitle fw-bold">
          {{ "User" | titlecase }}
        </p>
      </div>
      <div class="col-4">
        <div class="title-desc">
          {{ "my_profile:settings:account:country" | translate }}
        </div>
        <p class="subtitle fw-bold">
          {{ data?.invoice_details[0]?.country_code }}
        </p>
      </div>
    </div>
    <div class="row order-detail mx-1">
      <div class="title px-0">
        {{ "admin:users:info:table:orders:dropdown:1" | translate }}
      </div>
      <div class="row px-0">
        <div class="col-auto">
          <img
            *ngIf="data?.collections[0]?.type!='quest_level_4'"
            [src]="data?.collections[0]?.coverPathUrlSmall"
            class="img-detail"
            alt=""
          />
          <img
          *ngIf="data?.collections[0]?.type=='quest_level_4'"
          src="assets/img/dh-profile/img-card3.png"
          class="img-detail"
          alt=""
        />
        </div>
        <div class="col-auto">
          <div class="subtitle-order pt-0" *ngIf="competencies?.length == 0||!competencies;">
            {{ data?.collections[0]?.name |translate }} 
          </div>



          <div class="row" *ngIf="competencies?.length > 0;">
            <div class="col-auto">
              <h6>{{ "focus:competencies:text" | translate }}</h6>
              <p class="fw-bold">
                {{ competencies.length }}
                {{
                  (competencies.length > 1
                    ? "focus:competencies:sub:text:s"
                    : "focus:competencies:sub:text"
                  ) | translate
                }}
                x
                {{
                  compentencyPrice
                    | currency
                      : "EUR"
                      : "symbol"
                      : (compentencyPrice % 1 === 0 ? "1.0-2" : "1.2-2")
                }}
                =
                {{
                  competencies.length * compentencyPrice
                    | currency
                      : "EUR"
                      : "symbol"
                      : ((competencies.length * compentencyPrice) % 1 ===
                        0
                          ? "1.0-2"
                          : "1.2-2")
                }}
              </p>
            </div>

            <!-- <div class="col text-end">
              <h6>{{ order!.total_taxed! | currency:'EUR':'symbol':'1.0-2' }}</h6>
            </div> -->
          </div>

          <p class="mb-0 fw-normal">
            <ng-container
              *ngFor="let competence of competencies; let last = last"
            >
              {{
                "dh_profile:competences:level:" + 3 + ":" + competence
                  | translate
              }}<span *ngIf="!last">, </span>
            </ng-container>
          </p>
        





          <div *ngIf="data?.collections[0]?.type!='quest_level_4'" class="desc-detail">
            {{
              "movielearning:admin:orders:profile:details:access"
                | translate : { days: data?.entries[0].subscription_days }
            }} 
            <!-- -
            {{ " Stars on · Ends on" }} -->
          </div>
        </div>
        <div class="col text-end d-flex justify-content-end">
          <p class="price">€{{ data.order_price==0?data.total_taxed:data.order_price }}</p>
        </div>
        <hr class="common-separator" />
        <div class="d-flex justify-content-end">
          <div class="col-5">
            <div *ngIf="data.coupon" class="row justify-content-between">
              <div class="col-auto">
                {{ "discount:choose:coupon:applied" | translate }}
              </div>
              <div class="col-auto price">{{ data.coupon.code }}</div>
            </div>
            <div *ngIf="data.discount" class="row justify-content-between my-3">
              <div class="col-auto">
                {{
                  "organization:settings:discounts:table:title:2" | translate
                }}
              </div>
              <div class="col-auto price">€{{ data.discount }}</div>
            </div>
            <div class="row justify-content-between my-3">
              <div class="col-auto">
                {{
                  "movielearning:admin:orders:profile:details:list:1"
                    | translate
                }}
              </div>
              <div class="col-auto price">
                €{{ data.total | number : "1.0-2" }}
              </div>
            </div>
            <div class="row justify-content-between my-3">
              <div class="col-auto">
                {{
                  "movielearning:admin:orders:profile:details:list:3"
                    | translate
                      : {
                          tax: data.order_tax * 100 || 0
                        }
                }}
              </div>
              <div class="col-auto price">
                €{{ data.tax | number : "1.0-2" }}
              </div>
            </div>
            <div class="row justify-content-between">
              <div class="col-auto">
                {{
                  "movielearning:admin:orders:profile:details:list:4"
                    | translate
                }}
              </div>
              <div class="col-auto price">
                €{{ data.total_taxed | number : "1.0-2" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
