import { throwError } from 'rxjs';
import { Injectable } from '@angular/core';

import { Organization } from '../interfaces/organization';

import { ApiService } from './api.service';
import { InitService } from './init.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  private userOrgs: Array<Organization> = [];
  public organizationId: string | null = '';

  constructor(private api: ApiService, private initService: InitService) {}

  getCurrent(): Organization | null {
    return this.initService.getCurrentOrg();
  }

  getUserOrganizationsMenu(): Promise<Array<Organization>> {
    if (this.userOrgs.length > 1) {
      return new Promise((resolve, reject) => {
        resolve(this.userOrgs);
      });
    }

    return new Promise((resolve, reject) => {
      this.api
        .get('/organization/menu')
        .then((data) => {
          if (data.result == 'success') {
            this.userOrgs = data.data;
            resolve(data.data);
          }
        })
        .catch((err) => reject(err));
    });
  }

  getOrganization(organizationId: any): Promise<any> {
    try {
      const resp = this.api.get(`/organization/${organizationId}`);
      return resp;
    } catch (error: any) {
      return error;
    }
  }

  getOrganizationCourse(username: any): Promise<any> {
    try {
      const resp = this.api.get(`/organization/username/${username}`);
      return resp;
    } catch (error: any) {
      return error;
    }
  }

  addMember(id: number) {
    return this.api
      .patch(
        '/organization/' +
          this.initService.getCurrentOrg()?.id +
          '/member/' +
          id +
          '/accepted'
      )
      .then((res) => {
        return res.data;
      });
  }

  async getMembersStats(organizationId: any) {
    try {
      const resp = await this.api.get(
        `/organization/${organizationId}/member/stats`
      );
      return resp;
    } catch (error: any) {
      return error;
    }
  }

  async getPendingInvitation(
    limit: number | null = 20,
    page: number | null = 1,
    search: string | null,
    organizationId: any
  ) {
    try {
      let query = `?limit=${limit}&page=${page}&q=${search || ''}`;
      const resp = await this.api.get(
        `/organization/${organizationId}/member/invitations` + query
      );
      return resp;
    } catch (error: any) {
      return error;
    }
  }

  async getFailedInvitation(organizationId: any) {
    try {
      let query = '?failed=1';
      const resp = await this.api.get(
        `/organization/${organizationId}/member/invitations` + query
      );
      return resp;
    } catch (error: any) {
      return error;
    }
  }

  async getMembersOrganization(
    limit: number | null = 20,
    page: number | null = 1,
    search: string | null,
    organizationId: string | null,
    status: string = 'all',
    type: string = ''
  ) {
    try {
      let url = '/organization/' + organizationId + '/member/all';
      if (status == 'active') {
        url = '/organization/' + organizationId + '/member/active';
      } else if (status == 'deactivated') {
        url = '/organization/' + organizationId + '/member/deactivated';
      }

      let query = `?limit=${limit}&page=${page}&q=${search || ''}&status=${
        status || ''
      }&type=${type || ''}`;
      const members = await this.api.get(url + query);
      return members;
    } catch (error) {
      return error;
    }
  }

  async deleteVideoOrganization(organizationId: any, data: any) {
    try {
      const deleteVideos = await this.api.patch(
        '/organization/' + organizationId + '/video',
        { video_ids: [data] }
      );
      return deleteVideos;
    } catch (error) {
      return error;
    }
  }

  async deleteMembers(organizationId: number, bulkusers: any, password: any) {
    try {
      let url = `/organization/${organizationId}/bulk_delete`;
      const deleteMembers = await this.api.postJson(url, {
        bulkusers: bulkusers,
        currentUserPassword: password,
      });
      return deleteMembers;
    } catch (error) {
      return error;
    }
  }

  async deleteInvitation(organizationId: number, origin: any, origin_id: any) {
    try {
      let url = `/organization/${organizationId}/member/invitations`;
      const deleteMembers = await this.api.deleteJSON(url, {
        origin: origin,
        origin_id: origin_id,
      });
      return deleteMembers;
    } catch (error) {
      return error;
    }
  }

  async resendInvitation(
    organizationId: number,
    invitedId: number,
    email: String,
    message: String,
    name: String
  ) {
    try {
      let url = `/organization/${organizationId}/member/invitations/resend/invitees/${invitedId}`;
      const resend = await this.api.postJson(url, {
        email: email,
        message: message,
        name: name,
      });
      return resend;
    } catch (error) {
      return error;
    }
  }

  async desactivateMembers(organizationId: number, bulkusers: number[]) {
    try {
      const desactivateMembers = await this.api.postJson(
        `/organization/${organizationId}/member/bulk/delete`,
        {
          bulkusers,
          deactivate: true,
        }
      );
      return desactivateMembers;
    } catch (error) {
      return error;
    }
  }

  async removeMembers(organizationId: number, bulkusers: any) {
    try {
      const removeMembers = await this.api.postJson(
        `/organization/${organizationId}/member/bulk/delete`,
        {
          bulkusers,
          deactivate: false,
        }
      );
      return removeMembers;
    } catch (error) {
      return error;
    }
  }
  async deletePending(organizationId: number, membersId: any) {
    try {
      let members = membersId;
      let url = `/organization/${organizationId}/member/multipleinvitations`;
      if (membersId.length == 1) {
        members = membersId[0];
        url = `/organization/${organizationId}/member/invitations`;
      }
      const removeMembers = await this.api.deleteJSON(url, {
        origin: 'invitees',
        origin_id: members,
      });
      return removeMembers;
    } catch (error) {
      return error;
    }
  }

  async inviteManually(
    organizationId: number,
    users: any,
    message: string = '',
    language: string = 'en'
  ) {
    try {
      const inviteManually = await this.api.postJson(
        `/organization/${organizationId}/member/invitations/sendmultiple`,
        {
          language,
          message,
          users,
        }
      );
      return inviteManually;
    } catch (error) {
      return error;
    }
  }

  async createAccount(organizationId: number, infoUser: any) {
    try {
      const inviteManually = await this.api.postJson(
        `/organization/${organizationId}/member/user`,
        infoUser
      );
      return inviteManually;
    } catch (error) {
      return error;
    }
  }

  async inviteCsv(organizationId: number, infoUser: any) {
    try {
      const inviteManually = await this.api.postJson(
        `/user/invite/csv`,
        infoUser
      );
      return inviteManually;
    } catch (error) {
      return error;
    }
  }
  async getcourseOrganization(
    limit: number | null = 999999999,
    page: number | null = 1,
    type: any = 'course'
  ) {
    try {
      let query = `?type=${
        type || ''
      }&public=true&limit=${limit}&page=${page}&order=RELEVANCE&includeStats=true`;
      const resp = await this.api.get(`/collection/get` + query);
      return resp;
    } catch (error: any) {
      return error;
    }
  }
  async getPlaylistsOrganization(
    limit: number | null = 999999999,
    page: number | null = 1,
    type: any = 'playlist'
  ) {
    try {
      let query = `?type=${
        type || ''
      }&public=true&limit=${limit}&page=${page}&order=RELEVANCE`;
      const resp = await this.api.get(`/collection` + query);
      return resp;
    } catch (error: any) {
      return error;
    }
  }

  update(organizationId: number, data: any) {
    return this.api.put('/organization/' + organizationId, data).then((res) => {
      return res.data;
    });
  }

  saveHomepageContent(params: any) {
    let id = params.id;
    delete params.id;
    return this.api
      .postJson('/dashboard/sections/' + id + '/content', params)
      .then((res) => {
        // TODO
        // if (response.data.type === 'course' && response.data.content) {
        //     Courses.addThumbnail(response.data.content, 'list');
        // }

        return res.data;
      });
  }

  deleteHomepageContent(params: any) {
    return this.api
      .delete(
        '/dashboard/sections/' + params.id + '/content/' + params.content_id
      )
      .then((res) => {
        return res;
      });
  }

  deleteHomepageSection(params: any) {
    return this.api
      .delete('/dashboard/sections/' + params.id)
      .then((res: any) => {
        return res;
      });
  }

  saveHomepageSectionsOrder(params: any) {
    return this.api.patch('/dashboard/sections', params).catch((err) => {
      debugger;
    });
  }

  saveHomepageSectionContentsOrder(params: any) {
    return this.api
      .patch('/dashboard/sections/' + params.id + '/content', params.contents)
      .catch((err) => {
        debugger;
      });
  }

  createSection(params: any) {
    return this.api.postJson('/dashboard/sections', params).then((res: any) => {
      return res.data;
    });
  }

  updateSection(params: any) {
    const id = params.id;
    delete params.id;

    return this.api
      .put('/dashboard/sections/' + id, params)
      .then((res: any) => {
        return res.data;
      });
  }

  public async removeFromPlatform(organizationId: string, memberId: string) {
    try {
      let url = `/organization/${organizationId}/member/${memberId}`;
      const deleteMember = await this.api.delete(url);
      return deleteMember;
    } catch (error) {
      return error;
    }
  }

  public async makeModerator(
    organizationId: string,
    memberId: string,
    type: string
  ) {
    try {
      let url = `/organization/${organizationId}/member/${memberId}`;
      const deleteMember = await this.api.put(url, { type });
      return deleteMember;
    } catch (error) {
      return error;
    }
  }
  public async saveChangesSettings(
    organizationId: string | null,
    data: string
  ) {
    try {
      let url = `/organization/${organizationId}`;
      const settings = await this.api.put(url, data);
      return settings;
    } catch (error) {
      return error;
    }
  }
  public async resetPassword(email: string) {
    try {
      let url = `/user/password_reset/request`;
      const resetPassword = await this.api.postJSON(url, { email });
      return resetPassword;
    } catch (error) {
      return error;
    }
  }

  async deactivateOrActivateOneMember(memberId: any, activate: boolean) {
    try {
      let url: string = '';
      if (activate) {
        url = `/user/${memberId}/activate`;
      } else {
        url = `/user/${memberId}/deactivate`;
      }
      const deactivate = await this.api.patch(url);
      return deactivate;
    } catch (error) {
      return error;
    }
  }

  async getOrganizationSettings() {
    try {
      const resp = await this.api.get(`/organization/terms-conditions-by-id`);
      return resp;
    } catch (error: any) {
      return error;
    }
  }

  async uploadFile(file: File): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.api.postFile('/file', formData).then((res) => {
      return res.data;
    });
  }
  saveImg(organizationId: string | null, imgId: any) {
    return this.api
      .patch('/organization/' + organizationId + '/logo', {
        logo_id: imgId,
      })
      .then((res: any) => {
        return res.data;
      });
  }
  orderCourses(organizationId: string | null, collections: any) {
    return this.api
      .patch('/organization/' + organizationId + '/collection/order', {
        collections,
      })
      .then((res: any) => {
        return res.data;
      });
  }
  async getSectionAnalytics(
    analyticsId: string,
    organizationId: string | null = ''
  ) {
    try {
      const sessions = await this.api.get(
        `/dashboard/section_analytics/${analyticsId}/sessions/${organizationId}`
      );
      return sessions;
    } catch (error: any) {
      return error;
    }
  }
  async getActiveUsers(
    analyticsId: string,
    organizationId: string | null = ''
  ) {
    try {
      const sessions = await this.api.get(
        `/dashboard/users_activity/${organizationId}/${analyticsId}`
      );
      return sessions;
    } catch (error: any) {
      return error;
    }
  }
  async getTotalLearning(
    analyticsId: string,
    organizationId: string | null = ''
  ) {
    try {
      const learning = await this.api.get(
        `/dashboard/time_stats/${analyticsId}/${organizationId}`
      );
      return learning;
    } catch (error: any) {
      return error;
    }
  }
  async getMostPopular(organizationId: string | null = '', typeDate = 'all') {
    try {
      const learning = await this.api.get(
        `/dashboard/most_popular/${organizationId}/${typeDate}/time`
      );
      return learning;
    } catch (error: any) {
      return error;
    }
  }
  async getReviewsHome(organizationId: string | null = '') {
    try {
      const reviews = await this.api.get(
        `/organization/${organizationId}/reviews`
      );
      return reviews;
    } catch (error: any) {
      return error;
    }
  }
  async editMember(organizationId: number, memberId: number, data: any) {
    try {
      const edited = await this.api.put(
        `/organization/${organizationId}/member/user/${memberId}`,
        data
      );
      return edited;
    } catch (error) {
      return error;
    }
  }

  async downloadMembers(organizationId: string | null = '') {
    try {
      const members = await this.api.get(
        '/organization/' + organizationId + '/member/csv?format=csv',
        true
      );
      return members;
    } catch (error: any) {
      return error;
    }
  }
}
