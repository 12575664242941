<div class="modal-close"  (click)="closeModal()">
    <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon><span >{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
    <h4 class="modal-title">{{"video:upload:title" | translate }}{{(fileName ? ': ' + fileName : '') | excerpt: 55}}</h4>
</div>
<div class="modal-body pe-0 pb-0">
    <div class="text-center">
        <app-movie-tabs-wrapper [main]="true">
            <li class="nav-item" role="presentation">
                <button class="nav-link active py-3" data-bs-toggle="tab" data-bs-target="#uploader"
                    type="button" role="tab" (click)="changeTab(0)" [ngClass]="{'disabled': !tabs[0].enabled}">
                    Upload
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link py-3" data-bs-toggle="tab" data-bs-target="#clip-details"
                    type="button" role="tab" (click)="changeTab(1)" [ngClass]="{'disabled': !tabs[1].enabled}">
                    {{ 'video:edit:modal:tab:clip_details' | translate }}
                    <i class="fa fa-circle fa-xs text-danger" *ngIf="tabs[1].touched"></i>
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link py-3" data-bs-toggle="tab" data-bs-target="#dh-info"
                    type="button" role="tab" (click)="changeTab(2)" [ngClass]="{'disabled': !tabs[2].enabled}">{{'video:edit:modal:tab:double_healix' | translate }}
                    <i class="fa fa-circle fa-xs text-danger" *ngIf="tabs[2].touched"></i></button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link py-3" data-bs-toggle="tab" data-bs-target="#tags-info"
                    type="button" role="tab" (click)="changeTab(3)" [ngClass]="{'disabled': !tabs[3].enabled}">{{'video:edit:modal:tab:tags' | translate }}
                    <i class="fa fa-circle fa-xs text-danger" *ngIf="tabs[3].touched"></i></button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link py-3" data-bs-toggle="tab" data-bs-target="#category"
                    type="button" role="tab" (click)="changeTab(4)" [ngClass]="{'disabled': !tabs[4].enabled}">{{ 'video:edit:modal:tab:category' | translate }}
                    <i class="fa fa-circle fa-xs text-danger" *ngIf="tabs[4].touched"></i></button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link py-3" data-bs-toggle="tab" data-bs-target="#advanced-setting"
                    type="button" role="tab" (click)="changeTab(5)" [ngClass]="{'disabled': !tabs[5].enabled}">{{ 'video:edit:modal:tab:advanced_settings' | translate }}
                    <i class="fa fa-circle fa-xs text-danger" *ngIf="tabs[5].touched"></i></button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link py-3" data-bs-toggle="tab" data-bs-target="#shared-public"
                    type="button" role="tab" (click)="changeTab(6)" [ngClass]="{'disabled': !tabs[6].enabled}">{{ 'video:edit:modal:tab:share_settings' | translate }}
                    <i class="fa fa-circle fa-xs text-danger" *ngIf="tabs[6].touched"></i></button>
            </li>

        </app-movie-tabs-wrapper>
    </div>

    <div class="tab-content mt-3 pe-3 pb-3">
      <div class="tab-pane fade active show pt-2" id="uploader" role="tabpanel">
          <div class="row justify-content-center">
              <div class="col-auto">
                  <ngx-file-drop #fileDrop dropZoneLabel="Drop files here" [accept]="acceptFileUpload" (onFileDrop)="fileDropped($event)">
                      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                          <div class="text-center p-3 pt-0">
                              <div class="btn btn-primary mb-3" (click)="openFileSelector($event)">Browse your computer</div>
                              <h6>Or drag and drop your video here</h6>
                              <p class="mb-1 fs-14">Supported file types: m4v, mp4, mov, avi, mkv, wmv.</p>
                              <p class="mb-0 fs-14">Max upload size: {{isAdmin() ? '10GB' : '300MB'}}.</p>
                          </div>
                      </ng-template>
                  </ngx-file-drop>
              </div>
          </div>
      </div>

      <div class="tab-pane fade pt-2" id="clip-details" role="tabpanel">
        <app-details-form #detailsForm (touch)="touched(1, $event)" *ngIf="editService.video" [video]="editService.video"></app-details-form>
      </div>

      <div class="tab-pane fade pt-2" id="dh-info" role="tabpanel">
        <app-dh-form #DHForm (touch)="touched(2, $event)" *ngIf="editService.video" [video]="editService.video"></app-dh-form>
      </div>

      <div class="tab-pane fade pt-2" id="tags-info" role="tabpanel">
        <app-tags-form #tagsForm (touch)="touched(3, $event)" *ngIf="editService.video" [video]="editService.video"></app-tags-form>
      </div>

      <div class="tab-pane fade pt-2" id="category" role="tabpanel">
        <app-category-form #categoryForm (touch)="touched(4, $event)" *ngIf="editService.video" [video]="editService.video"></app-category-form>
      </div>

      <div class="tab-pane fade pt-2" id="advanced-setting" role="tabpanel">
        <app-advanced-setting-form #advancedForm (touch)="touched(5, $event)" *ngIf="editService.video" [video]="editService.video"></app-advanced-setting-form>
      </div>

      <div class="tab-pane fade pt-2" id="shared-public" role="tabpanel">
        <app-shared-public-form #shareForm (touch)="touched(6, $event)" *ngIf="editService.video" [video]="editService.video" [modalref]="selfModal!"></app-shared-public-form>
      </div>
    </div>
</div>
<div class="modal-footer">
    <div class="container">
        <div class="row justify-content-end">
            <div class="col-4" *ngIf="currentTab > 1">
                <button class="btn btn-link" (click)="prevTab()"><i class="icon fa fa-angle-left"></i> {{ 'video:edit:modal:prev_step' | translate }}</button>
            </div>
            <div class="col-4 text-center pt-1">
                <app-progress-bar *ngIf="uploading" [progress]="uploadingProgress" [showLabelText]="false" [labelPosition]="'right'"></app-progress-bar>
                <p *ngIf="uploaded" class="text-success mb-0">Uploaded <i class="fas fa-check"></i></p>
            </div>
            <div class="col-4 text-end">
              <button class="btn btn-link" (click)="nextTab()" *ngIf="!areTouched() && currentTab < tabs.length - 1">{{ 'video:edit:modal:next_step' | translate }} <i class="icon fa fa-angle-right"></i></button>
              <button class="btn btn-success" (click)="saveAndNextTab()" *ngIf="areTouched()" [ngClass]="{ disabled: saving }">{{ 'video:edit:modal:save' | translate }} <fa-icon class="ms-2" [icon]="faCircleNorch" *ngIf="saving" [spin]="true"></fa-icon></button>
              <ng-container *ngIf="currentTab >= tabs.length - 1">
                <button *ngIf="!editService.video!.published" class="btn btn-success ms-2" (click)="publish()" [ngClass]="{ disabled: publishing }">{{ 'publish:label' | translate }} <fa-icon class="ms-2" [icon]="faCircleNorch" *ngIf="publishing" [spin]="true"></fa-icon></button>
              </ng-container>
            </div>
        </div>
    </div>
</div>
