import { AfterViewInit, ElementRef,ViewChild,ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Video } from 'src/app/interfaces/video/video';
import { ApiService } from 'src/app/services/api.service';
import { ToastService } from 'src/app/services/toast.service';
import { VideoEditService } from '../video-edit.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-details-form',
  templateUrl: './details-form.component.html',
  styleUrls: ['./details-form.component.scss']
})
export class DetailsFormComponent implements OnInit, OnChanges , AfterViewInit{
  @ViewChild('myTextareaEn') descriptionEn: ElementRef | undefined;
  @ViewChild('myTextareaNl') descriptionNl: ElementRef | undefined;

  @Output('touch') touch: EventEmitter<boolean> = new EventEmitter();
  @Input('video') video: Video|undefined;
  @Input('isNew') isNew: boolean=true;
  public currentLang: string = 'en';
  details: any = {};

  istouched: any = {
    details: false,
    source: false
  }

  pLanguages = ['none', 'en', 'nl', 'fr', 'de', 'es'];
  languages = ['af', 'sq', 'ar', 'hy', 'bn', 'bi', 'bs', 'bg', 'ca', 'zh', 'da', 'et', 'tl', 'fi', 'ka', 'el', 'kl', 'ht', 'he', 'hi', 'hu', 'ga', 'it', 'id', 'ja', 'kk', 'ko', 'ku', 'hr', 'la', 'lv', 'lt', 'lb', 'mk', 'ms', 'ne', 'no', 'uk', 'fa', 'pl', 'pt', 'ro', 'ru', 'sr', 'sl', 'sk', 'th', 'cs', 'tr', 'vi', 'sv'];

  IMDBNotFound = false;
  loadingImdb = false;

  constructor(
    private lang: LangService,
    private cd: ChangeDetectorRef,
    private api: ApiService,
    private toast: ToastService,
    public videoEditService: VideoEditService
  ) { 
   
  }
  ngAfterViewInit(): void {
  
  }
  autoGrowTextAreaEn() {
    if (this.isNew) {return}
    const textarea = this.descriptionEn?.nativeElement;
    textarea.style.overflow = 'hidden';
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
    //console.log('video',this.video)
  }
  autoGrowTextAreaNl() {
    if (this.isNew) {return}

    const textarea = this.descriptionNl?.nativeElement;
    textarea.style.overflow = 'hidden';
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
   // console.log('textareaNL',textarea)

  }
  public changeLang(){
    this.lang.language.subscribe((lang) => {
      let buttonLang: any  = ''
      
      this.currentLang = lang || 'en';
      if (!this.details[this.currentLang]) {
        this.details[this.currentLang] = {}; // crea un nuevo objeto si no existe
      }
      if (!this.details[this.currentLang].name) {
        
        if (this.details['nl'].name == '' && this.details['en'].name == '') {
          
          if (this.currentLang == 'en') {
            buttonLang = document.getElementById('nav-en-tab'); 
            }else {
              buttonLang = document.getElementById('nav-nl-tab');
          }
  
          buttonLang?.click();

        }else{
          buttonLang = this.currentLang == 'en'? document.getElementById('nav-nl-tab') : document.getElementById('nav-en-tab'); 
          buttonLang?.click();
        }
      }else{
        buttonLang = this.currentLang == 'en'? document.getElementById('nav-en-tab') : document.getElementById('nav-nl-tab'); 
        buttonLang?.click();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('video')) {
      let languages = this.video?.info;

        languages?.forEach((trans) => {
          if (!this.details[trans?.language]) {
            this.details[trans?.language] = {}; // crea un nuevo objeto si no existe
          }
          this.details[trans?.language].name = trans?.name;
          this.details[trans?.language].description = trans?.description;
          this.details[trans?.language].video_post_translation_id = trans?.video_post_translation_id;
        });
     
        
    }
  }

  ngOnInit(): void {
    this.details = {
      en: {
        name: '',
        description: ''
      },
      nl: {
        name: '',
        description: ''
      }
    };

    setTimeout(() => {
      let languages = this.video?.info;
      languages!.forEach( (trans, key) => {
        this.details[trans.language].name = trans.name;
        this.details[trans.language].description = trans.description;
        this.details[trans.language].video_post_translation_id = trans.video_post_translation_id
      });
      this.changeLang()
    }, 1);
  }

  notFound(title: string) {
    this.IMDBNotFound = true;
    this.videoEditService.source.origin.title = title;
    this.cd.detectChanges();
  }

  removeVideoSourceOrigin() {
    this.touched('source');
    this.IMDBNotFound = false;
    if (this.videoEditService.source && this.videoEditService.source.origin) {
        let id = this.videoEditService.source.origin.id;
        let created_at = this.videoEditService.source.origin.created_at;
        let updated_at = this.videoEditService.source.origin.updated_at;
        Object.keys(this.videoEditService.source.origin).forEach( (key: string) => {
            this.videoEditService.source.origin[key] = null;
        });
        this.videoEditService.source.origin.id = id;
        this.videoEditService.source.origin.created_at = created_at;
        this.videoEditService.source.origin.updated_at = updated_at;
    }
  }

  movieSelected(movie: any) {
    this.loadingImdb = true;
    this.api.get('/origin/imdb/' + movie.imdbid).then( (response) => {
      if (response.result === 'success' && response.data.record) {
        if (!this.videoEditService.source.origin) {
          this.videoEditService.source.origin = {};
        }

        this.videoEditService.source.origin = { ...this.videoEditService.source.origin, ...response.data.record };
        this.touched('source')
      } else {
        this.toast.showError("Something went wrong grabbing movie details.");
      }
      this.loadingImdb = false;
    });
  };

  touched(what: string) {
    this.istouched[what] = true;
    this.touch.emit(true);
  }

  save() {
    let all: Array<Promise<any>> = []
    if (this.istouched['details']) {
      all.push(this.saveDetails());
    }
    if (this.istouched['source']) {
      all.push(this.saveSource());
    }
    return Promise.all(all).then( (res) => {
      this.istouched['source'] = false;
      this.istouched['details'] = false;
      this.touch.emit(false);
    })
  }

  saveDetails() {
    let infoToDelete: {info: Array<any>} = {
      info: []
    };
    if (this.details.en &&
      !this.details.en.name &&
      !this.details.en.description &&
      this.details.en.video_post_translation_id
    ) {
      infoToDelete.info.push({ 'video_post_translation_id': this.details.en.video_post_translation_id });
    }

    if (this.details.nl &&
      !this.details.nl.name &&
      !this.details.nl.description &&
      this.details.nl.video_post_translation_id
    ) {
      infoToDelete.info.push({ 'video_post_translation_id': this.details.nl.video_post_translation_id });
    }

    return this.api.put('/video/' + this.video?.id , {
      published: this.video?.published,
      info: Object.fromEntries( Object.entries(this.details).filter( ([key, val]: any) => val.name && val.description ) ),
      //contexts: $scope.tag.contexts,
      delete: infoToDelete
    }).then( (res: any): void => {
      if (res.result === 'success') {
        this.touch.emit(false);
        if (res.data instanceof Array) {
          res.data.forEach( (value: any) => {
            if (this.details[value.language]) {
              this.details[value.language].video_post_translation_id = value.video_post_translation_id;
            }
          });
        }
      } else {
        alert('something went wrong updating clip details');
      }
    });
  }

  saveSource() {
    return this.api.put('/video/' + this.video?.id + '/source', this.videoEditService.source)
      .then( (response: any) => {
        if (response.result == 'success' && response.result.origin_id) {
          this.videoEditService.source.origin.id = response.result.origin_id;
        }
      })
      .catch(function(response) {
        alert('something went wrong updating clip source');
      });
  }
}
