import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Video } from 'src/app/interfaces/video/video';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';
import { VideoService } from 'src/app/services/video.service';

@Injectable({
  providedIn: 'root'
})
export class VideoEditService {
  private video$: BehaviorSubject<Video | null> = new BehaviorSubject<Video|null>(null);

  isModal = false;

  video: Video | undefined;
  tags = [];
  categories = [];

  source: any;

  dhtags: Array<any> = [];
  dhtagsBeforeChanges: Array<any> = [];

  userTags: Array<any> = [];
  userTagsBeforeChanges: Array<any> = [];

  videoCategories: Array<any> = [];
  videoCategoriesBeforeChanges: Array<any> = [];

  private group_id: number | null = null;

  constructor(
    private videoService: VideoService,
    private api: ApiService,
    private auth: AuthService,
    private router: Router,
    private toastr: ToastService,
    private translate: TranslateService,
  ) {
    this.video$.subscribe( video => {
      if (video) {
        this.video = video;

        let source: any = {
          spoken_language: 'none',
          subtitle_language: 'none',
          explicit_content: false,
          origin_type: 'film',
          origin: {
            title: null,
            description: null,
            copyright: null
          },
          enable_watermark: this.video.source?.enable_watermark
        };

        if (this.video.source) {
          this.source =  JSON.parse(JSON.stringify(this.video.source));
          for (const prop in source) {
            if (Object.prototype.hasOwnProperty.call(source, prop)) {
              if (!this.source[prop]) {
                this.source[prop] = source[prop];
              }
            }
          }
        } else {
          this.source = source;
        }



        let promises = [];

        promises.push(this.api.get('/video/' + video.id + '/analysis/tags').then( (response) => {
          this.tags = [];

          if (response && response.result === 'success') {
            if (response.data.video.analysis.tags) {
              this.tags = response.data.video.analysis.tags;
            }
          }

          return this.tags;
        }));

        promises.push(this.api.get('/tag/category').then( (response) => {
          this.categories = [];

          if (response && response.result === 'success') {
              if (response.data.categories) {
                  this.categories = response.data.categories;
              }
          }

          return this.categories;
        }));

        Promise.all(promises).then( res => {
          this.dhtags = this.tags.filter( (tag: any) => {
            return (tag.tag_type === 'doublehealix' && tag.in === null && tag.out === null);
          });
          this.dhtagsBeforeChanges = JSON.parse(JSON.stringify(this.dhtags));

          this.userTags = JSON.parse(JSON.stringify(this.tags));
          this.userTags.forEach( (tag: any) => {
            tag.selected = true;
          });
          this.userTagsBeforeChanges = JSON.parse(JSON.stringify(this.userTags));

          this.videoCategories = this.tags.filter( (tag: any) => tag.tag_type == 'category');
          this.videoCategoriesBeforeChanges = JSON.parse(JSON.stringify( this.videoCategories ));

          this.categories.forEach( (category: any) => {
            category.selected = this.videoCategories.filter( (cat: any) => cat.id == category.tag_id).length > 0;
            category.selectedBeforeUpdate = category.selected;

            let videoCategory: any = this.videoCategories.find( (cat: any) => cat.id == category.tag_id);
            category.analysis_record_id = (videoCategory ? videoCategory.analysis_record_id : undefined);

            category.subcategories.forEach( (sub: any) => {
              sub.selected = this.videoCategories.filter( (cat: any) => cat.id == sub.tag_id).length > 0;
              sub.selectedBeforeUpdate = sub.selected;

              let videoCategory: any = this.videoCategories.find( (cat: any) => cat.id == sub.tag_id);
              sub.analysis_record_id = (videoCategory ? videoCategory.analysis_record_id : undefined);
            });
          });

        });
      }
    })
  }

  setVideoId( id: number ) {
    this.videoService.getById(id).then( res => {
      // check user edit permissions
      if (!this.auth.hasRole('super_user') && !this.auth.isOwner(res.data.video)) {
        this.router.navigate(['/video', res.data.video.id]);
        this.toastr.showError(this.translate.instant('groups:status:permission_needed'));
      }

      this.video$.next(res.data.video);
    });
  }

  setVideo( video: Video ) {
    this.video$.next(video);
  }

  getObservable(): Observable<Video|null> {
    return this.video$.asObservable();
  }

  ngOnDestroy() {
    this.video$.unsubscribe();
  }

  setGroup(group_id: number | null = null) {
    this.group_id = group_id
  }

  getGroup() {
    return this.group_id;
  }
}
