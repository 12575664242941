<div *ngIf="items?.length" class="row" #container>
  <div
    *ngFor="let item of items; let i = index"
    class="col-md-{{ colSizeMd }} col-lg-{{ colSize }} mb-3 px-2"
  >
    <app-course-card
      *ngIf="item.type == 'course'"
      [course]="item"
      [isFromGroup]="isFromGroup"
      (refreshDelete)="deleteItem($event, 'course')"
    ></app-course-card>
    <app-playlist-card
      *ngIf="item.type == 'playlist' || type == 'playlist'"
      [playlist]="item"
      [isFromGroup]="isFromGroup"
      (refreshDelete)="deleteItem($event, 'playlist')"
    ></app-playlist-card>
    <app-video-card
      (refreshDelete)="getRefresh()"
      *ngIf="type == 'video'"
      [video]="item"
      [isFromGroup]="isFromGroup"
      [group]="group"
      [clickItem]="clickItem"
      (refreshDeleteFromGroup)="deleteItem($event, 'video')"
    ></app-video-card>
    <app-group-card
      *ngIf="item.type == 'group'"
      [groups]="item"
    ></app-group-card>
    <app-admin-platform-card *ngIf="item.type == 'platform'" [platform]="item">
    </app-admin-platform-card>
  </div>
</div>
