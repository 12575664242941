import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private api: ApiService,
    private authService:AuthService,
  ) { }

  getByEmail(email: string) {
    return this.api.get('/user/bymail/' + email).then( res => {
      return res.data;
    })
  }

  getByUsername(username: string) {
    return this.api.get('/user/' + username).then( res => {
      return res.data;
    })
  }

  async getCourses(userName: string) {
    try {
      let query = `?username=${userName}`;
      const courses = await this.api.get('/user/' + userName + '/courses' + query);
      return courses;
    } catch (error) {
      return error;
    }
  }

  async searchUsers(filter_by_organization:boolean,search: string) {
    try {
      let query = `?filter_by_organization=${filter_by_organization}&search=${search}&limit=30`;
      const users = await this.api.get('/user/list' + query);
      return users;
    } catch (error) {
      return error;
    }
  }

  async getPlayList(userName: string) {
    try {
      let query = `?username=${userName}`;
      const playList = await this.api.get('/user/' + userName + '/playlists' + query);
      return playList;
    } catch (error) {
      return error;
    }
  }

  async getMyVideos(userName: string) {
    try {
      let query = `?username=${userName}`;
      const videos = await this.api.get('/user/' + userName + '/myvideos' + query);
      return videos;
    } catch (error) {
      return error;
    }
  }

  async getGroups(userName: string) {
    try {
      let query = `?username=${userName}`;
      const groups = await this.api.get('/user/' + userName + '/groups' + query);
      console.log('getGroups');
      console.log(groups);
      return groups;
    } catch (error) {
      return error;
    }
  }

  async getEnrolled(
    limit: number | null = 50,
    page: number | null = 1,
    order: string | null,
    search: string | null
  ) {
    order = 'DESC';

    try {
      let query = `?type=course&limit=${limit}&page=${page}&search=${search}&order=${order}&owner=&section=joined&joined=true`;
      const courses = await this.api.get('/collection/get/enrolled' + query);
      return courses;
    } catch (error) {
      return error;
    }
  }


  async getAccepted(
    search: string | null,
    collection:number
  ) {

    try {
      let query = `?filter_owner=true&id=${collection}&search=${search}`;
      const courses = await this.api.get('/collection/'+collection+'/users/accepted' + query);
      return courses;
    } catch (error) {
      return error;
    }
  }


  async patchImage(userName: string, image: any){
    try{
      let query = `/user/${userName}/avatar`;
      const patch_img = await this.api.patch(query, {avatar: image}).then((res)=>{
          this.authService.loadMe();
          return res;
        }
      );
      return patch_img;
    }catch(error){
      return error;
    }
  }
  async deleteImage(userName: string){
    try{
      let query = `/user/${userName}/avatar`;
      const delete_image = await this.api.delete(query).then((res)=>{
        this.authService.loadMe();
        return res;
      });
      return delete_image;
    }catch(error){
      return error;
    }
  }
  async patchInfoAccount(userName:string, object: any){
    try{
      let query = `/user/${userName}/account`;
      const patch_country = await this.api.patch(query, object).then((res)=>{
          this.authService.loadMe();
          return res;
        }
      );
      return patch_country;
    }catch(error){
      return error;
    }
  }
  async patchDeactivateAccount(id:string){
    try{
      let query = `/user/${id}/deactivate`;
      const patch_deactivated = await this.api.patch(query).then((res)=>{
          this.authService.loadMe();
          return res;
        }
      );
      return patch_deactivated;
    }catch(error){
      return error;
    }
  }
  async patchNotificationsConf(userName:string, object: any){
    try{
      let query = `/user/${userName}/notifications`;
      const patch_notifications = this.api.patch(query, object).then((res)=>{
          this.authService.loadMe();
          return res;
        }
      );
      return patch_notifications;
    }catch(error){
      return error;
    }
  }
  async patchEmail(object: any){
    try{
      let query = `/user/auth/change-email`;
      const patch_email = await this.api.patch(query, object).then((res)=>{
          this.authService.loadMe();
          return res;
        }
      );
      return patch_email;
    }catch(error){
      return error;
    }
  }
  async patchPassword(userName:string, object: any){
    try{
      let query = `/user/${userName}/password`;
      const patch_country = await this.api.patch(query, object).then((res)=>{
          this.authService.loadMe();
          return res;
        }
      );
      return patch_country;
    }catch(error){
      return error;
    }
  }
  async putProfileAccount(userName: string, object:any){
    try{
      let query = `/user/${userName}`;
      const put_info = await this.api.put(query, object).then((res)=>{
        this.authService.loadMe();
        return res;
      });
      return put_info;
    }catch(error){
      return error;
    }
  }

  async setAcceptedPrivacyPolicy(userName: string){
    try{
      let query = `/user/${userName}`;
      const put_info = await this.api.put(query, {'accepted_privacy_policy':true}).then((res)=>{
        this.authService.loadMe();
        return res;
      });
      return put_info;
    }catch(error){
      return error;
    }
  }
  /////////////////
  // Delete Account
  /////////////////
  async getContentProfile(userId: number) {
    try {
      let url = `/user/${userId}/content`;
      const content = await this.api.get(url);
      return content;
    } catch (error) {
      return error;
    }
  }

  getProfile() {
    return this.authService.loadMe();
  }

  async deleteAccountProfile(userId:string, password: any){
    try{
      let url = `/user/${userId}/remove`;
      const accountDeleted = await this.api.patch(url, {password: password});
      return accountDeleted;
    }catch(error){
      return error;
    }
  }

  getInvite( hash: string ): Promise<any> {
    return this.api.get('/user/invite/' + hash).then( (res: any) => {
      return res.data.invitation;
    });
  }

  async inviteToOrganizations(userName: string, organization_id:any,message:string) {
    try{
      let url = '/user/'+userName+'/organizations';
      const resend = await this.api.patch(url, {
        organizations_id:organization_id,
        message:message
      });
      return resend;
    }catch(error){
      return error;
    }
  }

  async resendEmailConfirm(email: string) {
    try{
      let url = `/user/sendactivationemail`;
      const resend = await this.api.patch(url, {});
      return resend;
    }catch(error){
      return error;
    }
  }

  activateEmail(token: string) {
    return this.api.patch('/user/activateemail', {token: token}).then( res => {
      return res.data;
    })
  }

  cancelEmail(token: string) {
    return this.api.patch('/user/cancel_email', {token: token}).then( res => {
      return res.data;
    })
  }
}
