import { Router } from '@angular/router';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { Organization } from 'src/app/interfaces/organization';
import { InitService } from 'src/app/services/init.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-course-preview-home',
  templateUrl: './course-preview-home.component.html',
  styleUrls: ['./course-preview-home.component.scss'],
})
export class CoursePreviewHomeComponent implements OnInit {
  @Input() arrayReviews: any[] = [];
  faClock = faClock;
  public currentOrganization: Organization | null = null;

  constructor(
    private router: Router,
    public auth: AuthService,
    private orgsService: OrganizationsService,
    private adminService: AdminService,
    private initService: InitService,
    private gtmService: GoogleTagManagerService
  ) {
    this.currentOrganization = this.initService.getCurrentOrg();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  ngOnInit(): void {}

  public goTopreview(courseId: number, lessonId: number, course: any) {
    if (this.currentOrganization?.slug == 'courses') {
      const gtmTag = {
        event: 'watch_preview',
        preview: {},
      };
      console.log('Sending GTM', gtmTag);

      this.gtmService.pushTag(gtmTag);
    }
    let track = {
      email: this.auth.getMe()?.email,
      description: 'pendiente',
      element_id: lessonId,
      collection_id: courseId,
      organization_id: this.orgsService.getCurrent()?.id,
    };
    let self = this;
    this.adminService
      .setTrackPreviewLesson(track)
      .then((resp: any) => {
        self.router.navigate([
          '/course-viewer',
          courseId,
          'text-lesson',
          lessonId,
        ]);
      })
      .catch((err) => {
        self.router.navigate([
          '/course-viewer',
          courseId,
          'text-lesson',
          lessonId,
        ]);
      });
  }
  public goToCourse(courseId: number, lessonId: number) {
    this.router.navigate(['/courses', courseId]);
  }
}
