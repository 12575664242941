<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">{{'users:deactivate:account:modal:title' | translate}}</h4>
    </div>
    <div class="modal-body">
        <p class="description description--large">{{ 'users:goodbye:modal:description' | translate:{email: user.email} }}</p>
        <div class="d-flex justify-content-end">
            <a class="btn btn-primary" (click)="goodbye()">
                <span>{{ 'users:goodbye:modal:description:button' | translate }}</span>
            </a>
        </div>
    </div>
</div>

