import { Component, Input, OnInit } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { PlaylistsService } from 'src/app/services/playlists.service';
import { ToastService } from 'src/app/services/toast.service';
import { GoToDuplicateComponent } from '../go-to-duplicate/go-to-duplicate.component';

@Component({
  selector: 'app-duplicate',
  templateUrl: './duplicate.component.html',
  styleUrls: ['./duplicate.component.scss'],
})
export class DuplicateComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  public org: any = [];
  @Input() user: any = {};
  @Input() playlistId: any = ' ';

  public loading: boolean = false;
  public faCircleNorch: any = faCircleNotch;
  public faTimes = faTimes;
  constructor(
    private playlistService: PlaylistsService,
    private toastService: ToastService,
    private translate: TranslateService,
    private ngbModal: NgbModal
  ) {}

  ngOnInit(): void {
    this.getOrg();
  }
  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
  public getOrg() {
    this.loading = true;

    this.playlistService
      .getOrg(this.user)
      .then((result) => {
        this.org = [...result.data.organizationsRegistered];
        this.org.forEach((org: any) => {
          org.checked = false;
        });
        this.loading = false;
      })
      .catch((err: any) => {
        this.loading = false;
        console.log(err);
      });
  }

  public sendOrg() {
    let organizations = this.org
      .filter((o: any) => o.checked)
      .map((o: any) => o.id);
    let goToOrg = this.org.filter((o: any) => o.checked);

    if (organizations.length == 0) {
      this.toastService.showError(
        this.translate.instant(
          'collection:modal:duplicate:playlist:organizations:error'
        )
      );

      return;
    }

    this.playlistService
      .sendDuplicate(this.playlistId, organizations)
      .then((result) => {
        this.loading = false;
        this.closeModal();
        this.toastService.showSuccess(
          this.translate.instant('collection:modal:duplicate:playlist:success')
        );
          this.goToDuplicate(goToOrg);       
      })
      .catch((err: any) => {
        this.loading = false;
        console.log(err);
      });
  }

  public goToDuplicate(orgSelected: any) {
    let modalRef = this.ngbModal.open(GoToDuplicateComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.organizations = orgSelected;
    modalRef.componentInstance.playlistId = this.playlistId;
  }
}
