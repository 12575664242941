import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  transform(duration: number, secondNumber: number = 0): unknown {
    if (!duration) {
      return '';
    }

    var durationText = '';

    // Hours
    var hours = moment.duration(duration).hours();

    // Minutes
    var minutes = moment.duration(duration).minutes();

    // Seconds
    var seconds = moment.duration(duration).seconds();

    if (seconds > 0 && hours <= 0) {
      minutes += 1;
    }
    if (hours > 0) {
      durationText = hours + ' h ';
    }
    if (minutes > 0) {
      durationText += minutes + ' min ';
    }

    return durationText;
  }
}
