import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as vector from 'highcharts/modules/vector.src';

import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { CreateCourseModalComponent } from './courses/create-modal/create-modal.component';
import { AcceptGiftModalComponent } from './courses/accept-gift-modal/accept-gift-modal.component';
import { CreatePlayListModalComponent } from './playlist/create-modal/create-modal.component';
import { CreateGroupModalComponent } from './group/create-modal/create-modal.component';
import { AddCourseComponent } from './group/add-course/add-course.component';
import { AddVideoComponent } from './group/add-video/add-video.component';
import { AddPlayListComponent } from './group/add-playlist/add-playlist.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { DeactivateAccountComponent } from './profile/deactivate-account/deactivate-account.component';
import { DeactivateAcceptComponent } from './notify/deactivate-accept/deactivate-accept.component';
import { NotificationSettingsComponent } from './group/notification-settings/notification-settings.component';
import { CreateConversationComponent } from './group/create-conversation/create-conversation.component';
import { UploadFileModalComponent } from './group/upload-file/uploadFile-modal.component';
import { VideoEditModule } from './video-edit/video-edit.module';
import { CourseReviewModalComponent } from './courses/review-modal/course-review-modal.component';
import { InviteNewMembersComponent } from './group/invite-new-members/invite-new-members.component';
import { UsersInvitedComponent } from './group/invite-new-members/users-invited/users-invited.component';
import { WelcomeUserComponent } from './welcome-user/welcome-user.component';
import { EnrolCourseModalComponent } from './courses/enrol-course-modal/enrol-course-modal.component';
import { CreateReviewModalComponent } from './courses/create-review-modal/create-review-modal.component';
import { CheckoutModule } from './checkout/checkout.module';
import { CheckoutQuestionnaireModule } from './checkout-questionnaire/checkout-questionnaire.module';
import { LoginModule } from './login/login.module';
import { PlLoginModule } from './login-pl-notlogged/pllogin.module';
import { EditVideoPlaylistComponent } from './playlist/edit-video-playlist/edit-video-playlist.component';
import { DeleteAccountModalComponent } from './profile/delete-account-modal/delete-account-modal.component';
import { ChangeOwnerModalComponent } from './change-owner/change-owner-modal.component';
import { SearchOwnerComponent } from './change-owner/search-owner/search-owner.component';
import { UsersSelectedComponent } from './change-owner/users-selected/users-selected.component';
import { LeaveCommentaryModalComponent } from './group/leave-commentary-modal/leave-commentary-modal.component';
import { PipesModule } from '../pipes/pipes.module';
import { MomentModule } from 'ngx-moment';
import { ResetProgressComponent } from './courses/reset-progress/reset-progress.component';
import { LeaveCourseComponent } from './courses/leave-course/leave-course.component';
import { DeleteReviewComponent } from './courses/delete-review/delete-review.component';
import { ConfirmationDeleteComponent } from './organizations/confirmationDelete/confirmation-delete/confirmation-delete.component';
import { TeamInviteComponent } from './dh/team-invite/team-invite.component';
import { DhInviteComponent } from './dh/dh-invite/dh-invite.component';
import { TeamCreateProfileComponent } from './dh/team-create-profile/team-create-profile.component';
import { TeamSettingsComponent } from './dh/team-settings/team-settings.component';
import { DeleteVideoComponent } from './playlist/delete-video/delete-video.component';
import { InvitedRegisterComponent } from './invited-register/invited-register.component';
import { DeleteMembersComponent } from './organizations/delete-members/delete-members.component';
import { DesactivateMembersComponent } from './organizations/desactivate-members/desactivate-members.component';
import { RemoveMembersComponent } from './organizations/remove-members/remove-members.component';
import { InviteManuallyComponent } from './organizations/invite-manually/invite-manually.component';
import { CreateAccountComponent } from './organizations/create-account/create-account.component';
import { InviteWithCsvComponent } from './organizations/invite-with-csv/invite-with-csv.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { EnrolledStatsComponent } from './organizations/courses/enrolled-stats/enrolled-stats.component';
import { DeactivateOneMemberComponent } from './organizations/deactivate-one-member/deactivate-one-member.component';
import { HowItworksComponent } from './home/how-itworks/how-itworks.component';
import { AddReviewComponent } from './group/add-review/add-review.component';
import { AddPreviewLessonComponent } from './group/add-preview-lesson/add-preview-lesson.component';
import { CircleModalComponent } from './courses/circle-modal/circle-modal.component';
import { CreateNewPlatformComponent } from './admin/create-new-platform/create-new-platform.component';
import { DisabledOrDeleteComponent } from './admin/disabled-or-delete/disabled-or-delete.component';
import { DeleteCourseComponent } from './admin/course/delete-course/delete-course.component';
import { VideoDuplicateComponent } from './video-duplicate/video-duplicate.component';
import { ShareCourseComponent } from './courses/share-course/share-course.component';
import { PricingComponent } from './admin/course/pricing/pricing.component';
import { ViewMembersComponent } from './admin/course/view-members/view-members.component';
import { InviteMembersComponent } from './courses/invite-members/invite-members.component';
import { CustomConfirmComponent } from './custom-confirm/custom-confirm.component';
import { DeleteInvitationComponent } from './delete-invitation/delete-invitation.component';
import { ResendInvitationComponent } from './resend-invitation/resend-invitation.component';
import { EditMemberComponent } from './organizations/edit-member/edit-member.component';
import { ChartModalComponent } from './dh/chart-modal/chart-modal.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { OrderDetailComponent } from './admin/order/order-detail/order-detail.component';
import { UsersAdminDetailComponent } from './admin/users/users-admin-detail/users-admin-detail.component';
import { EditUserComponent } from './admin/users/edit-user/edit-user.component';
import { DeleteUserComponent } from './admin/users/delete-user/delete-user.component';
import { RemoveFromPlatformComponent } from './admin/users/remove-from-platform/remove-from-platform.component';
import { DiscountDetailComponent } from './admin/discount/discount-detail/discount-detail.component';
import { CreateDiscountComponent } from './admin/discount/create-discount/create-discount.component';
import { EditDiscountComponent } from './admin/discount/edit-discount/edit-discount.component';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { GoBackPlaylistComponent } from './playlist/go-back-playlist/go-back-playlist.component';
import { ZoomImageComponent } from './courses/zoom-image/zoom-image.component';
import { RequestMembersComponent } from './playlist/request-members/request-members.component';
import { MakeMemberModalComponent } from './playlist/make-member-modal/make-member-modal.component';
import { IntroductionComponent } from './dh/introduction/introduction.component';
import { AddUserComponent } from './playlist/add-user/add-user.component';
import { DuplicateComponent } from './playlist/duplicate/duplicate.component';
import { GoToDuplicateComponent } from './playlist/go-to-duplicate/go-to-duplicate.component';
import { DigitalOceanMessageComponent } from './digital-ocean-message/digital-ocean-message.component';
import { ChangeOwnerComponent } from './playlist/change-owner/change-owner.component';
import { CourseDetailComponent } from './admin/users/course-detail/course-detail.component';
import { NewPrivacyComponent } from './new-privacy/new-privacy.component';
import { OnboardingModalComponent } from './dh/onboarding-modal/onboarding-modal.component';
import { ShareCoursePlatformComponent } from './admin/share-course-platform/share-course-platform.component';
import { DevelopCompetenciesComponent } from './dh/develop-competencies/develop-competencies.component';

@NgModule({
  declarations: [
    CreateCourseModalComponent,
    CreatePlayListModalComponent,
    CreateGroupModalComponent,
    AcceptGiftModalComponent,
    AddCourseComponent,
    AddVideoComponent,
    AddPlayListComponent,
    DeactivateAccountComponent,
    DeactivateAcceptComponent,
    NotificationSettingsComponent,
    CreateConversationComponent,
    UploadFileModalComponent,
    CourseReviewModalComponent,
    InviteNewMembersComponent,
    UsersInvitedComponent,
    WelcomeUserComponent,
    EnrolCourseModalComponent,
    CreateReviewModalComponent,
    EditVideoPlaylistComponent,
    DeleteAccountModalComponent,
    ChangeOwnerModalComponent,
    SearchOwnerComponent,
    UsersSelectedComponent,
    LeaveCommentaryModalComponent,
    ResetProgressComponent,
    LeaveCourseComponent,
    DeleteReviewComponent,
    ConfirmationDeleteComponent,
    TeamInviteComponent,
    DhInviteComponent,
    TeamCreateProfileComponent,
    TeamSettingsComponent,
    DeleteVideoComponent,
    InvitedRegisterComponent,
    DeleteMembersComponent,
    DesactivateMembersComponent,
    RemoveMembersComponent,
    InviteManuallyComponent,
    CreateAccountComponent,
    InviteWithCsvComponent,
    ResetPasswordComponent,
    EnrolledStatsComponent,
    DeactivateOneMemberComponent,
    HowItworksComponent,
    AddPreviewLessonComponent,
    AddReviewComponent,
    CircleModalComponent,
    CreateNewPlatformComponent,
    DisabledOrDeleteComponent,
    DeleteCourseComponent,
    VideoDuplicateComponent,
    ShareCourseComponent,
    PricingComponent,
    ViewMembersComponent,
    InviteMembersComponent,
    CustomConfirmComponent,
    DeleteInvitationComponent,
    ResendInvitationComponent,
    EditMemberComponent,
    ChartModalComponent,
    OrderDetailComponent,
    UsersAdminDetailComponent,
    EditUserComponent,
    DeleteUserComponent,
    RemoveFromPlatformComponent,
    DiscountDetailComponent,
    CreateDiscountComponent,
    EditDiscountComponent,
    DeleteModalComponent,
    GoBackPlaylistComponent,
    ZoomImageComponent,
    RequestMembersComponent,
    MakeMemberModalComponent,
    IntroductionComponent,
    AddUserComponent,
    DuplicateComponent,
    GoToDuplicateComponent,
    DigitalOceanMessageComponent,
    ChangeOwnerComponent,
    CourseDetailComponent,
    NewPrivacyComponent,
    OnboardingModalComponent,
    ShareCoursePlatformComponent,
    DevelopCompetenciesComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    VideoEditModule,
    NgbModule,
    LoginModule,
    PlLoginModule,
    CheckoutModule,
    CheckoutQuestionnaireModule,
    PipesModule,
    MomentModule,
    NgxFileDropModule,
    ChartModule,
    HighchartsChartModule,
  ],
  exports: [
    CreateCourseModalComponent,
    CreatePlayListModalComponent,
    CreateGroupModalComponent,
    AcceptGiftModalComponent,
    AddCourseComponent,
    AddVideoComponent,
    AddPlayListComponent,
    UploadFileModalComponent,
    VideoEditModule,
    CourseReviewModalComponent,
    HowItworksComponent,
    AddPreviewLessonComponent,
    AddReviewComponent,
    DeleteModalComponent,
    ChangeOwnerComponent,
    NewPrivacyComponent
  ],
})
export class ModalsModule {}
