import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CoursesService } from 'src/app/services/courses.service';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faAlignJustify, faGlobe, faPlay } from '@fortawesome/free-solid-svg-icons';
// import { Course } from 'src/app/interfaces/course';


@Component({
  selector: 'app-summary-icons',
  templateUrl: './summary-icons.component.html',
  styleUrls: ['./summary-icons.component.scss']
})
export class SummaryIconsComponent implements OnInit {

  @Input('valor') course: any = null;
  // hFlag header is true -> show faGlob && faClock only 
  @Input('header') hFlag: boolean = false;

  // course: Course | any = null;
  faClock = faClock;
  faPlay = faPlay;
  faText = faAlignJustify
  faGlobe = faGlobe;


  constructor(private coursesService: CoursesService) {}

  ngOnInit(): void {
 
  }
  
  // This function receives miliseconds and convert it in a friendly time in hours and minutes
  msToTime(ms: any) {
    let minutes: number = Math.ceil(Number(ms / (1000 * 60)));
    let hours = (minutes / 60);
    if (minutes < 60) 
      return minutes + " min";
    else {
      let roundHours = Math.floor(hours);
      minutes = Math.ceil((hours - roundHours) * 60);
      if(minutes == 0) 
        return  roundHours + " h";
      else 
        return roundHours + " h " + minutes + " min"; 
    }
  }
  
  getEstimatedLessonsTime(){
 
    let qa_duration: number = Number ((this.course.qa_duration?(this.course.qa_duration):0) * 60000);
    let time_lessons: number = Number(this.course.summaryLessonsTime?this.course.summaryLessonsTime.estimated_time_lessons:0);
    let milliseconds: number = Number(qa_duration + time_lessons);
    // let duration: number = Math.ceil( milliseconds / 60000) || 0;
    return this.msToTime(milliseconds); 
  }

  getEstimatedTimeVideoLessons(){
    let duration = this.msToTime(this.course.summaryLessonsTime?this.course.summaryLessonsTime.estimated_time_video_lessons:1);
    return duration;
  }

  getEstimatedTimeTextLessons(){
    let duration = this.msToTime(this.course.summaryLessonsTime?this.course.summaryLessonsTime.estimated_time_text_lessons:1);
    return duration;
  }
  getEstimatedTimeQA(){
    let duration = this.msToTime((this.course.qa_duration?(this.course.qa_duration):0) * 60000);
    return duration;
  }


}
