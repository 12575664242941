import { GroupsService } from 'src/app/services/groups.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-leave-commentary-modal',
  templateUrl: './leave-commentary-modal.component.html',
  styleUrls: ['./leave-commentary-modal.component.scss'],
})
export class LeaveCommentaryModalComponent implements OnInit {
  @Input() conversationId: any;
  @Input() typeConversation: string = '';
  @Input() selfModal: NgbModalRef | null = null;
  @Output() countComment: EventEmitter<any> = new EventEmitter();

  public conversationData: any;
  public title: string = 'Group Conversation';
  faTimes = faTimes;
  public activities: any;
  public loading: boolean = false;

  constructor(private groupsService: GroupsService) {}

  ngOnInit(): void {
    if (this.typeConversation == 'file') {
      this.getConversationFile();
    }
    if (this.typeConversation == 'commentaryConversation') {
      this.getConversation();
    }
  }
  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  public getConversation() {
    this.loading = true;
    this.groupsService.getConversation(this.conversationId).then((resp) => {
      this.loading = false;
      this.conversationData = resp.data;
    });
  }

  public getConversationFile() {
    this.loading = true;
    this.groupsService.getConversationFile(this.conversationId).then((resp) => {
      this.loading = false;
      this.conversationData = resp.data;
    });
  }

  public getMimeTypeImg(type: any): string {
    if (type == 'application/pdf') {
      return 'assets/img/files/2.png';
    }
    if (type == 'image/png' || type == 'image/jpeg' || type == 'image/jpg') {
      return 'assets/img/files/3.png';
    }
    if (type == 'application/msword') {
      return 'assets/img/files/4.png';
    }
    return 'assets/img/files/1.png';
  }

  public getUser() {
    if (this.conversationData) {
      let user = this.conversationData.user;

      if (this.conversationData.type_activity === 'remove_user') {
        user = this.conversationData.entity;
      }
      return user;
    }
  }
  public getCountComment($event: any ){
    
    this.countComment.emit($event)

  }
}
