import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Groups } from 'src/app/interfaces/groups';
import { Router} from '@angular/router';


@Component({
  selector: 'app-group-card',
  templateUrl: './group-card.component.html',
  styleUrls: ['./group-card.component.scss']
})
export class GroupCardComponent implements OnChanges {
  @Input() groups: Groups | null = null;
  avatarImg:String = '';
  groupImg: undefined | string = '';
  faPlay = faUser;

  constructor(
    private router: Router,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.groupImg = 'assets/img/groups/icons/list.png';

    if (!this.groups?.user?.avatarPathUrlSmall) {
      this.avatarImg = 'assets/img/avatars/profile-avatar-purple.svg';
    } else {
      this.avatarImg = this.groups?.user?.avatarPathUrlSmall;
    }

    if (this.groups?.coverPathUrlMedium) {
      this.groupImg = this.groups?.coverPathUrlMedium;
    }
  }

  go() {
    console.log('click');
    console.log(this.groups);
  }
}

