<div class="container">
  <div class="col-12 col-lg-12 mb-3">
    <!-- <div class="row">
            <div class="col-12 d-flex justify-content-start align-content-center">
                <h2 class="float-left">
                    {{course?.summaryReviews.reviews_average}} 
                </h2> 
                <h5 class="mx-2">
                    <small>
                        <app-review-stars *ngIf="course?.summaryReviews.reviews_average" [rating]="round((course?.summaryReviews.reviews_average || 0) * 10) / 10"></app-review-stars>
                    </small>
                </h5>                                                                                                                                                            
            </div>
            <span class="col-12" style="font-family: Open Sans; font-weight: 600;" class="ng-binding">{{ 'courses:modal:reviews:view:number' | translate}}: {{course?.summaryReviews.reviews_count}}</span>
        </div> -->
    <div *ngIf="course.reviews.length > 0" class="pull-left">
      <div
        *ngFor="let review of course?.reviews; index as index"
        style="
          display: grid;
          margin-bottom: 0px;
          width: 100% !important;
          border-bottom: 1px solid #eaeaea;
        "
      >
        <div class="row justify-content-between">
          <div class="col">
            <h6 class="mb-0">
              <small>
                <app-review-stars
                  *ngIf="review"
                  [showNumber]="false"
                  [rating]="round((review.review || 0) * 10) / 10"
                ></app-review-stars>
              </small>
            </h6>
          </div>
          <div class="col-2 text-end mt-1 pe-0">
            <div *ngIf="userCanEdit(review)" ngbDropdown class="d-inline-block float_right" placement="bottom-right">
              <button
                type="button"
                class="btn btn-outline-primary"
                id="dropdownBasic1"
                ngbDropdownToggle
              >
                <i class="fa fa-ellipsis-h"></i>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button
                  (click)="editReview(review?.id, review)"
                  ngbDropdownItem
                >
                  Edit
                </button>
                <button
                  (click)="deleteReviewModal(review?.id, index)"
                  class="text-danger"
                  ngbDropdownItem
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="mx-0 px-10">
          <div class="pull-left mb-0">
            <h3 style="font-size: 18px; font-weight: 800">
              {{ review.title }}
            </h3>
            <p class="the-user-name">
              {{ review.username || review.user?.display_name }}
              {{ review.job_title ? "| " + review.job_title : "" }}
            </p>
          </div>
          <p class="description-review">{{ review.description }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
