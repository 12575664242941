<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title">
    {{ "collection:share:other:organizations:modal:title" | translate }}
  </h4>
</div>
<div class="modal-body flex-column">
  <div class="row">
    <p class="description">
      {{ "collection:share:other:organizations:modal:text" | translate : {organizationNames : names, organizationText: 'organisations'} }}
    </p>
  </div>
</div>
<div class="modal-footer">
  <div class="d-flex justify-content-end">
    <button (click)="duplicate()" type="button" role="tab" class="btn btn-primary ms-2 col-auto">
      {{ "collection:share:other:organizations:modal:button:duplicate" | translate }}
    </button>
    <button (click)="addUser()" type="button" role="tab" class="btn btn-primary ms-2 col-auto">
      {{ "collection:share:other:organizations:modal:button:make" | translate }}
    </button>
  </div>
</div>
