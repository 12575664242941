<div *ngIf="items?.length" class="pr-0 mr-0" #container>
  <div *ngFor="let item of items" class="row justify-content-center">
    <div class="col-xl-12">
      <app-file-card
        [isFromGroup]="isFromGroup"
        [file]="item"
        *ngIf="type == 'files'"
        (refreshDelete)="deleteItem($event, 'file')"
      ></app-file-card>
      <app-conversation-card
        [tab]="tab"
        (refreshConversations)="refreshListConversation($event)"
        [conversation]="item.object"
        *ngIf="type == 'conversations'"
      ></app-conversation-card>
      <app-group-activity-item
        [activity]="item"
        [tab]="tab"
        *ngIf="type == 'activity'"
        (refreshConversation)="refreshListConversation($event)"
      ></app-group-activity-item>
      <div class="row playlist-container" *ngIf="type == 'playlist-profile'">
        <h3 *ngIf="item?.name">{{ item?.name }}</h3>
        <div
          class="description col-11 p-margin"
          [innerHTML]="item?.description"
          *ngIf="item?.description"
        ></div>
        <app-group-activity-item-video
          type="video"
          [fromPlaylistProfile]="fromPlaylistProfile"
          [activity]="item?.object"
          [playlistId]="playlistId"
          *ngIf="type == 'playlist-profile'"
        ></app-group-activity-item-video>
        <div
          class="description col-11 p-margin"
          [innerHTML]="item?.description_alt"
          *ngIf="item?.description_alt"
        ></div>
      </div>
      <app-curriculum-card
        [type]="type"
        [course]="course"
        [chapter]="item"
        *ngIf="type == 'curriculum' || type == 'course_menu'"
      ></app-curriculum-card>
    </div>
  </div>
</div>
