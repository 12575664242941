<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">
    {{ "accept:gift:string:modal:title" | translate }}
  </h4>
</div>
<div class="modal-body">
  <div class="form-group">
    <div
      for="clip-title"
      [innerHtml]="
        'accept:gift:string1:modal'
          | translate
            : {
                time1: subDays,
                time2: initTime,
                time3: endTime,
                user: 'Alejandro'
              }
      "
    ></div>
  </div>
</div>

<div class="modal-footer">
  <div class="form-group clearfix">
    <button
      (click)="acceptInvitation()"
      type="button"
      role="tab"
      class="btn btn-primary btn-create float-right ms-2 fs-15"
    >
      <fa-icon
        class="mr-2"
        [icon]="faCircleNorch"
        *ngIf="loadSpin"
        [spin]="true"
      ></fa-icon>
      {{ "accept:gift:string:modal:button:do" | translate }}
    </button>

    <button
      (click)="closeModal()"
      type="button"
      role="tab"
      class="btn btn-primary btn-create float-right ms-2 fs-15"
    >
      <fa-icon
        class="mr-2"
        [icon]="faCircleNorch"
        *ngIf="loadSpin"
        [spin]="true"
      ></fa-icon>
      {{ "accept:gift:string:modal:button:dont" | translate }}
    </button>
  </div>
</div>
