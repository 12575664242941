import {
  Component,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Course } from 'src/app/interfaces/course';
import { Subscription } from 'src/app/interfaces/subscription';
import { AuthService } from 'src/app/services/auth.service';
import { CountryService } from 'src/app/services/country.service';
import { CoursesService } from 'src/app/services/courses.service';
import { OrdersService } from 'src/app/services/orders.service';
import { ToastService } from 'src/app/services/toast.service';
import { CourseUtilitiesService } from 'src/app/utilities/course-utilities.service';
import { CheckoutService } from '../checkout.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Router } from '@angular/router';
import { Organization } from 'src/app/interfaces/organization';
import { InitService } from 'src/app/services/init.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnChanges {
  @Input() selfModal: NgbModalRef | undefined;
  @Input() course: Course | undefined;
  @Input() initialDays: number | undefined;
  @Input() modalInfo: any;
  @Input() isGift = false;

  tabs = [
    {
      id: 'plan',
      touched: false,
      enabled: true,
    },
    {
      id: 'method',
      touched: false,
      enabled: false,
    },
    {
      id: 'confirm',
      touched: false,
      enabled: false,
    },
  ];

  currentTab = 0;

  subscriptions: Array<Subscription> = [];

  orderDetailsDiffer: KeyValueDiffer<any, any> | undefined;
  original_price: number | undefined;
  loadedOrderData = false;
  defaultVat = 0.21;
  currentCountry = 'NL';
  paying = false;
  finish_subscription: any;
  public currentOrganization: Organization | null = null;

  constructor(
    public checkout: CheckoutService,
    private coursesService: CoursesService,
    private courseUtils: CourseUtilitiesService,
    private ordersService: OrdersService,
    private countryService: CountryService,
    private kvDiffers: KeyValueDiffers,
    public auth: AuthService,
    private orderService: OrdersService,
    private toastr: ToastService,
    private translate: TranslateService,
    private gtmService: GoogleTagManagerService,
    private router: Router,
    private initService: InitService
  ) {
    if (!this.auth.isAuthenticated()) {
      for (let i = 0; i < this.tabs.length; i++) {
        this.tabs[i].enabled = false;
      }
      this.tabs.unshift({
        id: 'login',
        touched: false,
        enabled: true,
      });
    }
    this.auth.user.subscribe((user: any) => {
      this.currentCountry = user.country_code;
      if (!this.currentCountry) {
        this.currentCountry = this.countryService.getDefaultCountryCode();
      }
    });
  }

  ngOnInit(): void {
    this.finish_subscription = this.course!.finish_subscription;
    //console.log('this.course',this.course)
    let data_user=this.course!.users

    
    //si ya tiene competences comprada, entonces la fecah de finish va a null
    if (data_user&&data_user.length&&data_user[0].competency_data){
      this.finish_subscription =null
      //console.log('hay una competencies comprada')
    }
    
    this.subscriptions = this.courseUtils?.getSubscriptionOptions(this.course!);
    console.log(this.subscriptions);
    
    this.original_price = this.checkout.subscription?.price;
    this.currentOrganization = this.initService.getCurrentOrg();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.currentTab);
    if (this.modalInfo) {
      this.onSubscriptionChange(this.modalInfo);
    }

    if (this.course) {
      this.checkout.course = this.course;

      this.checkout.order = {
        method: this.ordersService.getPaymentMethods()[0],
        entries: [
          {
            collection_id: this.course.id,
            subscription_days: 0,
          },
        ],
        invoice_details: {
          firstname: '',
          lastname: '',
          is_company: false,
          country_code: this.countryService.getDefaultCountryCode(),
          is_eu_country: false,
          company_vat: '',
          coc: '',
          company_name: '',
          company_address: '',
          is_company_vat_valid: false,
        },
        tax_percentage: 0,
        in_total: 0,
        tax: 0,
      };

      if (this.isGift) {
        this.tabs.splice(this.auth.isAuthenticated() ? 1 : 2, 0, {
          id: 'gift',
          touched: false,
          enabled: false,
        });
        this.checkout.isGift = true;
        this.checkout.order!.message = '';
        this.checkout.order!.email = '';
      } else {
        this.checkout.isGift = false;
      }

      this.orderDetailsDiffer = this.kvDiffers
        .find(this.checkout.order!.invoice_details)
        .create();
    }
  }

  canGoNext(): boolean {
    if (this.currentTab == this.tabs.length - 2) {
      return true;
    }

    if (this.isGift && this.currentTab == this.tabs.length - 3) {
      return true;
    }

    return this.tabs[this.currentTab + 1]!.enabled;
  }

  nextTab() {
    let next = this.currentTab + 1;
    let nextTab = this.tabs[next];

    if (this.tabs[this.currentTab].id == 'gift') {
      this.checkout.validateGift(true).then((res) => {
        // debugger
        // if (res) {
        this.tabs[this.currentTab + 1].enabled = true;
        setTimeout(() => {
          if (nextTab) {
            let node = document.querySelector(
              'button[data-bs-target="#' + nextTab.id + '"]'
            );
            (node as HTMLElement).click();
            this.currentTab = next;
          }
        }, 1);
        // } else {
        //   this.toastr.showError('User email not found');
        // }
      });
    } else if (this.tabs[this.currentTab].id == 'method') {
      if (!this.checkout.validateInvoice(true)) {
        return;
      }
      this.tabs[this.currentTab + 1].enabled = true;
      setTimeout(() => {
        if (nextTab) {
          let node = document.querySelector(
            'button[data-bs-target="#' + nextTab.id + '"]'
          );
          (node as HTMLElement).click();
          this.currentTab = next;
        }
      }, 1);
    } else {
      if (nextTab) {
        let node = document.querySelector(
          'button[data-bs-target="#' + nextTab.id + '"]'
        );
        (node as HTMLElement).click();
        this.currentTab = next;
      }
    }
  }

  prevTab() {
    let prev = this.currentTab - 1;
    let prevTab = this.tabs[prev];
    if (prevTab) {
      let node = document.querySelector(
        'button[data-bs-target="#' + prevTab.id + '"]'
      );
      (node as HTMLElement).click();
      this.currentTab = prev;
    }
  }

  changeTab(num: number): void {
    let eventName: string =
      this.tabs[num].id == 'plan'
        ? 'checkout_duration_step1'
        : this.tabs[num].id == 'method'
        ? 'checkout_payment_step2'
        : 'checkout_details_step3';
    console.log(this.checkout);
    const gtmTag = {
      event: eventName,
      steps_checkout: this.tabs[num].id,
      ecommerce: {
        currency: 'USD',
        value: this.checkout?.order?.in_total,
        tax: this.checkout?.order?.tax_percentage,
        shipping: 0,
        affiliation: 'Movielearning',
        transaction_id: this.checkout?.subscription?.collection_id,
        payment_type: 'Mollie',
        shipping_tier: '',
        coupon: '',
        items: [
          {
            item_name: this.checkout?.course?.name,
            item_id: this.checkout?.subscription?.collection_id,
            price: this.checkout?.subscription?.originalPrice,
            item_brand: '',
            item_category: '',
            item_category2: '',
            item_category3: '',
            item_category4: '',
            item_variant: '',
            quantity: '1',
          },
        ],
      },
    };
    if (this.currentOrganization?.slug == 'courses') {
      console.log('Sending GTM', this.checkout);
      this.gtmService.pushTag(gtmTag);
    }

    if (this.tabs[num]?.enabled) {
      this.currentTab = num;
    }
  }

  closeModal() {
    this.router.navigate([], {
      queryParams: { checkout: 'false' },
      queryParamsHandling: 'merge',
    });
    this.checkout.discount = null;
    // this.checkout.subscription!.price = this.original_price!;
    this.checkout.calculateTotalAmount();
    this.selfModal?.close();
  }

  onSubscriptionChange(s: Subscription): void {
    if (s) {
      this.initialDays = s.subscription_days;
      this.checkout.subscription = s;

      if (this.checkout.order) {
        this.checkout.order!.entries[0].subscription_days = s.subscription_days;
      }

      if (this.isGift) {
        this.tabs.find((t) => t.id == 'gift')!.enabled = true;
      } else {
        this.tabs.find((t) => t.id == 'method')!.enabled = true;
      }

      if (this.auth.getMe()) {
        this.loadOrderData();
      }
    }
  }

  loadOrderData(): void {
    if (this.loadedOrderData) {
      return;
    }
    let params = {
      'filter[user_id]': this.auth.getMe()!.id,
      limit: 1,
    };

    this.orderService.query(params).then((res) => {
      // First order
      let order = res.data.data[0];
      if (order) {
        if (order.payment_method || order.method) {
          this.checkout.order!.method = order.payment_method || order.method;
        }

        let invoice_details = order.invoice_details[0];

        if (invoice_details) {
          this.checkout.order!.invoice_details = {
            firstname: invoice_details.firstname,
            lastname: invoice_details.lastname,
            is_company: invoice_details.is_company,
            country_code: invoice_details.country_code,
            is_eu_country: invoice_details.is_eu_country,
            company_vat: invoice_details.company_vat,
            coc: invoice_details.coc,
            company_name: invoice_details.company_name,
            company_address: invoice_details.company_address,
          };

          if (!this.checkout.order!.invoice_details.country_code) {
            this.checkout.order!.invoice_details.country_code =
              this.countryService.getDefaultCountryCode();
          }
          this.checkout.onCountryCodeChange();

          this.loadedOrderData = true;
        }
      } else {
        //not order complet to get country code and tax values
        // console.log('currentCountry',this.currentCountry)
        this.checkout.order!.invoice_details = {
          firstname: '',
          lastname: '',
          is_company: false,
          country_code: this.currentCountry, //this.countryService.getDefaultCountryCode(),
          is_eu_country: true,
          company_vat: '',
          coc: '',
          company_name: '',
          company_address: '',
        };
        this.checkout.onCountryCodeChange();
      }
    });
  }

  pay() {
    if (!this.checkout.order!.method) {
      this.toastr.showError(
        this.translate.instant('form:error:required', {
          field: this.translate.instant('course:buy:payment:form:label:method'),
        })
      );
      this.changeTab(1);
      return;
    }

    if (this.isGift && !this.checkout.validateGift(true)) {
      this.changeTab(this.tabs.findIndex((t) => t.id == 'gift'));
      return;
    }

    if (!this.checkout.validateInvoice(true)) {
      this.changeTab(this.tabs.findIndex((t) => t.id == 'method'));
      return;
    }

    //console.log("subscription_Type!!", $scope.subscription.subscription_type)
    // Show loading
    this.paying = true;
    // let in_total=this.getNetValue();
    // let tax=this.getTaxValue();
    // this.checkout.order!.tax=tax;
    // this.checkout.order!.in_total=in_total;
    if (this.checkout.discount) {
      this.checkout.order!.discount_net = this.getNetValue();
      this.checkout.order!.discount_tax = this.getTaxValue();
    }

    // console.log('TESTING',this.checkout);

    this.checkout.pay()?.finally(() => (this.paying = false));
  }
  getPriceWithDiscount(value: number) {
    if (!this.checkout.discount) return value;
    else if (this.checkout.discount.type === 'percentage')
      return value * (1 - this.checkout.discount.amount / 100);
    return Math.max(0, value - this.checkout.discount.amount);
  }
  getTotalValue() {
    return this.getPriceWithDiscount(
      this.checkout.order!.tax + this.checkout.order!.in_total
    );
  }

  getTaxValue() {
    // return this.getTotalValue()*this.checkout.order!.tax_percentage;
    return this.getTotalValue() - this.getNetValue();
  }
  getNetValue() {
    //return (this.getTotalValue()-this.getTotalValue()*this.checkout.order!.tax_percentage)
    return this.getTotalValue() / (1 + this.checkout.order!.tax_percentage);
  }

  logedIn() {
    this.auth.user.subscribe((user) => {
      this.coursesService
        .getCourseSummary(this.checkout.course?.id)
        .then((c) => {
          this.checkout.course = c.data;
          this.tabs.shift();
          this.tabs[0].enabled = true;
          if (this.checkout.subscription) {
            this.tabs[1].enabled = true;
            if (this.isGift) {
              this.tabs[2].enabled = true;
            }
          }
          this.loadOrderData();
          this.changeTab(0);
        });
    });
  }
}
