import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  HttpClient,
  HttpBackend,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgSelect2Module } from 'ng-select2';
import { LayoutModule } from './layout/layout.module';
import { InitService } from './services/init.service';
import { PipesModule } from './pipes/pipes.module';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MomentModule } from 'ngx-moment';
import { SharedModule } from './shared/shared.module';
// import { ProfileComponent } from './pages/profile/profile.component'
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';

import { OrganizationChooseComponent } from './modals/organization-choose/organization-choose.component';
import { HomeComponent } from './pages/home/home.component';

import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { SimpleVideoComponent } from './modals/simple-video/simple-video.component';
import { ModalsModule } from './modals/modals.module';
import { CommonModule } from '@angular/common';
import { ProfileModule } from './pages/profile/profile.module';
import { AuthInterceptor } from './interceptors/auth';
import { AuthService } from './services/auth.service';
import { InviteComponent } from './pages/invite/invite.component';
import { LoginComponent } from './pages/login/login.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';
import { NgxScrollspyModule } from '@uniprank/ngx-scrollspy';
import { DndModule } from 'ngx-drag-drop';
import { AcceptInvitationComponent } from './pages/accept-invitation/accept-invitation.component';
import { ConfirmEmailComponent } from './pages/confirm-email/confirm-email.component';
import { CancelEmailComponent } from './pages/cancel-email/cancel-email.component';
import { CollectionsRedirectComponent } from './pages/collections-redirect/collections-redirect.component';
import { SurfComponent } from './pages/surf/surf.component';
import { JoyrideModule } from 'ngx-joyride';
import { GoogleTagManagerService } from "angular-google-tag-manager";
export function HttpLoaderFactory(
  httpBackend: HttpBackend
): TranslateHttpLoader {
  return new TranslateHttpLoader(new HttpClient(httpBackend));
}

function initServiceFactory(service: InitService, auth: AuthService) {
  if (auth.isAuthenticated()) {
    return () => service.loadCurrentOrg();
  }
  return () => service.loadCurrentOrgData();
}

@NgModule({
  declarations: [
    AppComponent,
    OrganizationChooseComponent,
    HomeComponent,
    SimpleVideoComponent,
    InviteComponent,
    LoginComponent,
    ResetPasswordComponent,
    AcceptInvitationComponent,
    ConfirmEmailComponent,
    CancelEmailComponent,
    CollectionsRedirectComponent,
    SurfComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgSelect2Module,
    HttpClientModule,
    NgbModule,
    MomentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
      defaultLanguage: 'nl',
    }),
    LayoutModule,
    ProfileModule,
    PipesModule,
    SharedModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    ModalsModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsId),
    NgxGoogleAnalyticsRouterModule,
    NgxScrollspyModule.forRoot({ lookAhead: true }),
    DndModule,
    JoyrideModule.forRoot()
  ],

  providers: [
    InitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initServiceFactory,
      deps: [InitService, AuthService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    CookieService,
    { provide: "googleTagManagerId", useValue: "GTM-W73F6NF" },
    GoogleTagManagerService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
