<div
  class="d-flex justify-content-between px-3"
  [ngClass]="{ 'justify-content-md-center': items.length <= cols }"
>
  <fa-icon
    [icon]="faLeft"
    (click)="leftEnabled ? carousel.prev() : ''"
    size="2x"
    [ngClass]="{ 'd-block d-none': items.length <= cols }"
    [styles]="{
      stroke: '#a251bd',
      color: '#a251bd',
      cursor: leftEnabled ? 'pointer' : 'inherit',
      opacity: leftEnabled ? '1' : '.5'
    }"
  ></fa-icon>

  <h2 class="h4 mx-auto zindex">{{ title }}</h2>

  <fa-icon
    [icon]="faRight"
    (click)="rightEnabled ? carousel.next() : ''"
    size="2x"
    [ngClass]="{ 'd-block d-none': items.length <= cols }"
    [styles]="{
      stroke: '#a251bd',
      color: '#a251bd',
      cursor: rightEnabled ? 'pointer' : 'inherit',
      opacity: rightEnabled ? '1' : '.5'
    }"
  ></fa-icon>
</div>

<!-- <div *ngIf="items.length > 3" class="blur-left"></div> -->
<owl-carousel-o
  [options]="owlOptions"
  #carousel
  (translated)="translated($event)"
  [ngClass]="{ featured: featured }"
  class="blur"
>
  <ng-template carouselSlide *ngFor="let item of items; let i = index">
    <div class="p-2 pb-4" style="height: 100%">
      <app-course-card
        *ngIf="item.type == 'course'"
        [course]="item.content"
        [featured]="featured"
      ></app-course-card>
      <app-playlist-card
        *ngIf="item.type == 'playlist'"
        [playlist]="item.content"
        [featured]="featured"
      ></app-playlist-card>
      <app-video-card
        *ngIf="item.type == 'video'"
        [video]="item.content"
        [featured]="featured"
      ></app-video-card>
      <app-group-card
        *ngIf="item.type == 'group'"
        [groups]="item.content"
      ></app-group-card>
    </div>
  </ng-template>
</owl-carousel-o>
<!-- <div *ngIf="items.length > 3" class="blur-right"></div> -->
