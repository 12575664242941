import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

@Component({
  selector: 'app-maintenance-notification',
  templateUrl: './maintenance-notification.component.html',
  styleUrls: ['./maintenance-notification.component.scss'],
})
export class MaintenanceNotificationComponent implements OnInit {
  @Output() showNotification: EventEmitter<any> = new EventEmitter();
  @Output() openSurvey: EventEmitter<any> = new EventEmitter();
  @Output() hideDefinitely: EventEmitter<any> = new EventEmitter();

  @Input() data: any = null;
  @Input() lang: any =null;

  title='';
  msg=''
  constructor() {}

  ngOnInit(): void {
    console.log('data',this.data)
    console.log('this.lang',this.lang)
    
    if (this.lang=='en'){
      this.msg=this.data.content_en
      this.title=this.data.title_en
    }else{
      this.msg=this.data.content_nl
      this.title=this.data.title_nl
    }
  }

  hide() {
    this.showNotification.emit();
  }

  acceptAndOpenQuestionnaire() {
    this.openSurvey.emit();
  }
  
  hideDefinitelyNotification() {
    this.hideDefinitely.emit();
  }
}
