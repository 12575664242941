import { TableHeader } from './../interfaces/headers';
import { Injectable } from '@angular/core';
import { documentProvider } from 'ngx-owl-carousel-o/lib/services/document-ref.service';
import { User } from '../interfaces/user';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class GroupsService {
  user: User | null = null;

  constructor(private api: ApiService, private auth: AuthService) {
    this.auth.user.subscribe((user) => {
      this.user = user;
    });
  }

  async patchEnrollGroup(id: string) {
    try {
      const patch_enroll = await this.api.patch(
        `/collection/${id}/users/enroll`
      );

      return patch_enroll;
    } catch (error) {
      return error;
    }
  }
  async patchUnenrollGroup(id: string) {
    try {
      const patch_unenroll = await this.api.patch(
        `/collection/${id}/users/unenroll`
      );
      return patch_unenroll;
    } catch (error) {
      return error;
    }
  }

  async getPublic(
    limit: number | null = 50,
    page: number | null = 1,
    order: string | null,
    search: string | null
  ) {
    if (!order) {
      order = 'RELEVANCE';
    }
    try {
      let query = `?type=group&owner=&limit=${limit}&page=${page}&search=${
        search || ''
      }&order=${order}&section=all&public=true`;
      const groups = await this.api.get('/collection/' + query);
      return groups;
    } catch (error) {
      return error;
    }
  }

  async getNotifyConfig(id: string) {
    try {
      const group = await this.api.get('/collection/' + id + '/notifications');
      return group;
    } catch (error) {
      return error;
    }
  }
  async putNotifyConfig(id: string, data: any) {
    try {
      const group = await this.api.put(
        '/collection/' + id + '/notifications',
        data
      );
      return group;
    } catch (error) {
      return error;
    }
  }

  async getProfile(id: string) {
    return this.api.get('/collection/' + id).then((res) => {
      // TODO return only the group
      return res;
    });
  }

  async getVideos(
    id: string,
    limit: number | null = 16,
    page: number | null = 1,
    search: string | null
  ) {
    try {
      let query = `?limit=${limit}&page=${page}&search=${search || ''}`;
      const group = await this.api.get(
        '/collection/' + id + '/entity/type/video' + query
      );
      return group;
    } catch (error) {
      return error;
    }
  }

  async getCourses(
    id: string,
    limit: number | null = 12,
    page: number | null = 1,
    search: string | null
  ) {
    try {
      let query = `?limit=${limit}&page=${page}&search=${
        search || ''
      }&public=true`;
      const course = await this.api.get(
        '/collection/' + id + '/entity/type/course' + query
      );
      return course;
    } catch (error) {
      return error;
    }
  }

  async getMembers(
    id: string,
    limit: number | null = 12,
    page: number | null = 1
  ) {
    try {
      let query = `?id=${id}&limit=${limit}&page=${page}`;
      const members = await this.api.get(
        `/collection/${id}/users/accepted/${query}`
      );
      return members;
    } catch (error) {
      return error;
    }
  }
  async getUsersInvited(id: string) {
    try {
      const usersInvited = await this.api.get(
        `/collection/${id}/users/invited`
      );
      return usersInvited;
    } catch (error) {
      return error;
    }
  }
  async getUsersAccepted(id: string) {
    try {
      const usersInvited = await this.api.get(
        `/collection/${id}/users/accepted`
      );
      return usersInvited;
    } catch (error) {
      return error;
    }
  }
  async getPlaylist(
    id: string,
    limit: number | null = 12,
    page: number | null = 1,
    search: string | null
  ) {
    try {
      let query = `?limit=${limit}&page=${page}&search=${
        search || ''
      }&public=true`;
      const playlist = await this.api.get(
        '/collection/' + id + '/entity/type/playlist' + query
      );
      return playlist;
    } catch (error) {
      return error;
    }
  }

  async getActivity(
    id: String,
    limit: number | null = 30,
    page: number | null = 1
  ) {
    try {
      let query = `?limit=${limit}&page=${page}`;
      const activity = await this.api.get(
        '/collection/' + id + '/activity' + query
      );
      return activity;
    } catch (error) {
      return error;
    }
  }
  async getFile(id: String, limit: number | null = 3, page: number | null = 1) {
    try {
      let query = `?limit=${limit}&page=${page}`;
      const activity = await this.api.get(
        '/collection/' + id + '/entity/type/file' + query
      );
      return activity;
    } catch (error) {
      return error;
    }
  }

  ///////////////////
  // Conversations
  //////////////////
  async getConversations(
    id: string,
    limit: number | null = 3,
    page: number | null = 1
  ) {
    try {
      let query = `?limit=${limit}&page=${page}`;
      const activity = await this.api.get(
        '/collection/' + id + '/entity/type/conversation' + query
      );
      return activity;
    } catch (error) {
      return error;
    }
  }
  async getConversation(conversationId: string) {
    try {
      const conversation = await this.api.get(
        `/conversation/${conversationId}`
      );
      return conversation;
    } catch (error) {
      return error;
    }
  }

  async postConversations(conversation: any) {
    try {
      const post_conversation = await this.api.post(
        '/conversation',
        conversation
      );
      return post_conversation;
    } catch (error) {
      return error;
    }
  }

  public async deleteConversation(conversationId: number) {
    try {
      let url = `/conversation/${conversationId}`;
      const deleteConversation = await this.api.delete(url);
      return deleteConversation;
    } catch (error) {
      return error;
    }
  }

  async editConversation(conversationId: number, conversationData: any) {
    try {
      const groupEdited = await this.api.put(
        `/conversation/${conversationId}`,
        conversationData
      );
      return groupEdited;
    } catch (error) {
      return error;
    }
  }

  async getConversationFile(conversationId: string) {
    try {
      const conversation = await this.api.get(`/file/${conversationId}`);
      return conversation;
    } catch (error) {
      return error;
    }
  }
  async postConversationComment(id: number, comment: any): Promise<any> {
    return this.api.post(`/conversation/${id}/comment`, { comment });
  }

  async postConversationCommentFile(id: number, comment: any): Promise<any> {
    return this.api.post(`/file/${id}/comment`, { comment });
  }

  public async deleteCommentaryConversation(
    conversationId: number,
    commentId: number
  ) {
    try {
      let url = `/conversation/${conversationId}/comment/${commentId}`;
      const deleteConversationComment = await this.api.delete(url);
      return deleteConversationComment;
    } catch (error) {
      return error;
    }
  }

  public async deleteCommentaryConversationFile(
    conversationId: number,
    commentId: number
  ) {
    try {
      let url = `/file/${conversationId}/comment/${commentId}`;
      const deleteConversationComment = await this.api.delete(url);
      return deleteConversationComment;
    } catch (error) {
      return error;
    }
  }

  public async editCommentaryConversation(
    conversationId: number,
    commentaryId: number,
    commentaryData: any
  ) {
    try {
      const commentaryEdited = await this.api.put(
        `/conversation/${conversationId}/comment/${commentaryId}`,
        { comment: commentaryData }
      );
      return commentaryEdited;
    } catch (error) {
      return error;
    }
  }

  public async editCommentaryConversationFile(
    conversationId: number,
    commentaryId: number,
    commentaryData: any
  ) {
    try {
      const commentaryEdited = await this.api.put(
        `/file/${conversationId}/comment/${commentaryId}`,
        { comment: commentaryData }
      );
      return commentaryEdited;
    } catch (error) {
      return error;
    }
  }
  /////////////////////

  async postEntity(id: any, entity: any) {
    try {
      let url = `/collection/${id}/entity`;
      const post_entity = await this.api.postJSON(url, entity);
      return post_entity;
    } catch (error) {
      return error;
    }
  }
  async getJoined(
    limit: number | null = 50,
    page: number | null = 1,
    order: string | null,
    search: string | null
  ) {
    order = 'DESC';
    try {
      let query = `?type=group&limit=${limit}&page=${page}&search=${
        search || ''
      }&order=${order}&owner=&section=joined&joined=true`;
      const playlists = await this.api.get('/collection/enrolled' + query);
      return playlists;
    } catch (error) {
      return error;
    }
  }

  async getMyGroups(
    limit: number | null = 50,
    page: number | null = 1,
    order: string | null,
    search: string | null
  ) {
    order = 'DESC';
    try {
      let query = `?type=group&limit=${limit}&page=${page}&search=${
        search || ''
      }&order=${order}&owner=${this.user?.username}&section=mine`;
      const playlists = await this.api.get('/collection' + query);
      return playlists;
    } catch (error) {
      return error;
    }
  }
  async save(params: any): Promise<any> {
    return await this.api.postJson('/collection', params);
  }
  
  async saveFromGroup(playlistId: string, params: any, groupId:any): Promise<any> {
    let data = [
      {
        entity_id: playlistId,
        show_on_platform: false,
        type: 'playlist',
      },
    ];
    console.log('data', params);

    return await this.api.postJson(`/collection/${groupId}/entity`, {
      entities: data,
    });
  }

  async postActivityComment(
    groupId: number,
    activityId: number,
    params: any
  ): Promise<any> {
    return this.api.post(
      '/collection/' + groupId + '/activity/' + activityId + '/comment',
      params
    );
  }

  async sendInvitations(
    groupId: number,
    usersInvited: any,
    isDHTeam = false,
    language = 'nl'
  ): Promise<any> {
    return this.api.postJson(`/collection/${groupId}/invite`, {
      message: usersInvited.message,
      users: usersInvited,
      isDHTeam: isDHTeam,
      language: language 

    });
  }

  async addFileToGroup(id_file: string, group: string): Promise<any> {
    let collectionArray = [
      {
        entity_id: id_file,
        type: 'file',
      },
    ];

    return this.api
      .postJson('/collection/' + group + '/entity', {
        entities: collectionArray,
      })
      .then((res) => {
        if (res.result == 'success') {
          return res.data;
        }
      });
  }

  async uploadFile(
    file: File,
    title: string,
    description: string
  ): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('title', title);
    formData.append('description', description);
    return this.api.postFile('/file', formData).then((res) => {
      // let id_file = res.data.id;
      return res.data;
    });
  }

  getFileSoported(type: string) {
    const mimeTypes = {
      images: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
      imagesaudio: [
        'image/png',
        'image/jpg',
        'image/jpeg',
        'image/gif',
        'audio/mpeg',
        'audio/mp3',
        'audio/x-wav',
        'audio/wav',
        'audio/avi',
        '.mp3',
      ],
      documents: [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
        'text/plain',
        'application/vnd.ms-excel',
        'application/vnd.ms-excel.sheet.binary.macroenabled.12',
        'application/vnd.ms-excel.sheet.macroenabled.12',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/x-iwork-keynote-sffkey',
        'application/x-iwork-pages-sffpages',
        'application/x-iwork-numbers-sffnumbers',
        'application/pgp-keys',
        'application/octet-stream',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      ],
      pdf: ['application/pdf'],
      files: [
        'application/zip',
        'application/x-rar-compressed',
        'application/x-rar',
        'application/x-download',
        '.mp3',
      ],
    };

    let images = mimeTypes['images'];
    let files = mimeTypes['files'];
    let pdf = mimeTypes['pdf'];
    let documents = mimeTypes['documents'];
    let imagesaudio = mimeTypes['imagesaudio'];

    let valueArray: string[] = [];
    switch (type) {
      case 'images':
        valueArray = images;
        break;
      case 'files':
        valueArray = files;
        break;
      case 'pdf':
        valueArray = pdf;
        break;
      case 'documents':
        valueArray = documents;
        break;
      case 'imagesaudio':
        valueArray = imagesaudio;
        break;
      case 'all':
        valueArray = [...images, ...files, ...pdf, ...documents];
        break;
    }

    return valueArray.join(',');
  }
  private getValue(item: any, source: string) {
    let splitSource = source.split('.');
    let value = item;
    for (let index = 0; index < splitSource.length; index++) {
      if (!value[splitSource[index]]) {
        return 'no exist';
      }
      value = value[splitSource[index]];
    }

    return value;
  }

  public mapUsers(data: any, headers: TableHeader[]) {
    return data.map((item: any) => {
      let resp: any = {};
      headers.forEach((element) => {
        resp[element.nickname!] = this.getValue(item, element.source!);
      });
      return {
        id: item.id,
        type: item.type,
        ...resp,
      };
    });
  }

  public async deleteUserIvited(groupId: string, id: number) {
    try {
      let url = `/collection/${groupId}/enrolled/${id}`;
      const deleteUser = await this.api.delete(url);
      return deleteUser;
    } catch (error) {
      return error;
    }
  }

  ///////////////
  // Edit Groups
  ///////////////
  async uploadImgGroup(groupId: number, file: File): Promise<any> {
    let formData = new FormData();
    formData.append('file', file);
    return this.api.put(`/collection/${groupId}`, formData);
  }
  patchEditGroup(id: number, dataEdit: {}) {
    return this.api.put(`/collection/${id}`, dataEdit).then((res) => {
      return res;
    });
  }

  public async deleteGroup(grooupId: number) {
    try {
      let url = `/collection/${grooupId}`;
      const deleteGroup = await this.api.delete(url);
      return deleteGroup;
    } catch (error) {
      return error;
    }
  }

  // https://api.move-e-learning.com/collection/536/users/accepted?filter_owner=true&id=536&search=a
  async searchMembersOfGroup(groupId: number, search: string) {
    try {
      let query = `?filter_owner=true&id=${groupId}&search=${search}`;
      const users = await this.api.get(
        `/collection/${groupId}/users/accepted${query}`
      );
      return users;
    } catch (error) {
      return error;
    }
  }

  async makeSomeoneModerator(groupId: number, user_id: number) {
    try {
      const someoneModerator = await this.api.patch(
        `/collection/${groupId}/users/owner`,
        { user_id }
      );
      return someoneModerator;
    } catch (error) {
      return error;
    }
  }

  public getPendingRequests(id: number) {
    return this.api
      .get('/collection/' + id + '/users/pending')
      .then((res: any) => {
        return res.data.enrolled_users;
      });
  }

  public confirmAccessRequest(playlistId: number, requestId: number) {
    return this.api
      .patch('/collection/' + playlistId + '/enrolled/' + requestId + '/accept')
      .then((res: any) => {
        return res.data.message;
      });
  }

  public denyAccessRequest(playlistId: number, requestId: number) {
    return this.api
      .patch('/collection/' + playlistId + '/enrolled/' + requestId + '/deny')
      .then((res: any) => {
        return res.data.message;
      });
  }
}
