import { Component, ElementRef, HostListener, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  @Input() featured: boolean = false;
  @Input() items: Array<any> = [];
  @Input() cols = 2;
  @Input() title = '';

  faLeft = faChevronLeft;
  faRight = faChevronRight;

  leftEnabled = false;
  rightEnabled = true;

  owlOptions: OwlOptions = {
    // loop: true,
    stagePadding: 30,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    // autoWidth: true,
    responsive: {
        0: {
            items: 1,
        },
        752: {
            items: this.cols,
        }
    },
    nav: false,
  };

  public windowWidth: any;

  constructor() { }

  @HostListener('window:resize', ['$event'])
  resizeWindow() {
    this.windowWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.windowWidth = window.innerWidth;

    this.owlOptions.responsive = {
      0: {
          items: 1,
      },
      752: {
          items: this.cols,
      }
    };

    this.checkArrows(0);
  }

  translated(e: SlidesOutputData) {
    let pos = e.startPosition || 0
    this.checkArrows(pos);
  }

  checkArrows(position: number) {
    let cols = this.cols;
    if ( this.windowWidth < 752 ) {
      cols = 1;
    }

    if ( typeof position != 'undefined' && position >= 1 ) {
      this.leftEnabled = true;
    } else {
      this.leftEnabled = false;
    }

    if ( typeof position != 'undefined' && position + cols >= this.items.length ) {
      this.rightEnabled = false;
    } else {
      this.rightEnabled = true;
    }
  }

}
