import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { environment } from 'src/environments/environment';
import { Video } from 'src/app/interfaces/video/video';

declare var kWidget: any;

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit {
  @Output() watched: EventEmitter<Video> = new EventEmitter();
  @Input() video: Video | undefined;

  loading = true;

  kdp: any | undefined;
  alreadyWatched = false;

  constructor() {}

  ngOnInit(): void {
    let flashvars: any = { autoPlay: false };

    if (this.video?.source?.enable_watermark) {
      flashvars = {
        autoPlay: false,
        watermark: {
          plugin: true,
          img: '/assets/img/logos/mllogo-md.png',
          href: '',
          cssClass: this.video.source.watermark_position
            ? this.video.source.watermark_position.replace(
                /-([a-z])/g,
                function (g) {
                  return g[1].toUpperCase();
                }
              )
            : 'topRight',
        },
      };
    }

    let that = this;
    let interval = setInterval(() => {
      if (kWidget) {
        kWidget.embed({
          targetId: 'kaltura_player_' + this.video?.source?.external_id,
          wid: '_' + environment.vixyPId,
          uiconf_id: environment.vixyPlayerId,
          flashvars: flashvars,
          cache_st: this.video?.source?.external_id,
          entry_id: this.video?.source?.external_id,
          readyCallback: function readyCallback(playerID: any) {
            that.kdp = document.getElementById(playerID);

            that.kdp.kBind('monitorEvent', () => {
              that.updateTime(
                that.kdp.evaluate('{video.player.currentTime}'),
                that.kdp.evaluate('{duration}')
              );
            });
          },
        });
        clearInterval(interval);
      }
    }, 500);

    this.loading = false;
  }

  updateTime(currentTime: any, duration: any) {
    currentTime = (currentTime * 1000).toFixed(0);
    duration = (duration * 1000).toFixed(0);

    if (duration) {
      var percentage = Math.trunc((currentTime * 100) / duration);

      if (percentage >= 96 && !this.alreadyWatched) {
        this.watched.emit(this.video);
        this.alreadyWatched = true;
      }
    }
  }
}
