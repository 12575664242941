import { Injectable } from '@angular/core';
import { User } from '../interfaces/user';
import { Video } from '../interfaces/video/video';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class VideoService {
  user: User | null = null;
  private data = new Subject<string>();
  data$ = this.data;
  constructor(private api: ApiService, private auth: AuthService) {
    this.auth.user.subscribe((user) => {
      this.user = user;
    });
  }

  sendData() {
    this.data.next();
  }

  async getPublic(
    limit: number | null = 16,
    page: number | null = 1,
    order: string | null,
    search: string | null,
    owner: string | null,
    published: string | null = null
  ) {
    if (!order) {
      order = 'RELEVANCE';
    }

    try {
      let query = `?limit=${limit}&page=${page}&search=${search || ''}&owner=${
        owner || ''
      }`;
      if (published) {
        query += '&published=false';
      }

      const playlists = await this.api.get('/video' + query);
      return playlists;
    } catch (error) {
      return error;
    }
  }
  async getFavorites(username: string | null) {
    try {
      let query = `?username=${username || ''}`;

      const playlists = await this.api.get(
        '/user/' + username + '/favorites' + query
      );
      return playlists;
    } catch (error) {
      return error;
    }
  }

  async getEnrolled(
    limit: number | null = 50,
    page: number | null = 1,
    order: string | null,
    search: string | null
  ) {
    order = 'DESC';
    try {
      let query = `?type=playlist&limit=${limit}&page=${page}&search=${
        search || ''
      }&order=${order}&owner=&section=joined&joined=true`;
      const playlists = await this.api.get('/collection/enrolled' + query);
      return playlists;
    } catch (error) {
      return error;
    }
  }

  async getOwn(
    limit: number | null = 50,
    page: number | null = 1,
    order: string | null,
    search: string | null
  ) {
    order = 'DESC';
    try {
      let query = `?type=playlist&limit=${limit}&page=${page}&search=${
        search || ''
      }&order=${order}&owner=${this.user?.username}&section=joined&joined=true`;
      const playlists = await this.api.get('/collection' + query);
      return playlists;
    } catch (error) {
      return error;
    }
  }

  async getById(id: number | string): Promise<{ data: { video: Video } }> {
    return this.api.get('/video/' + id);
  }

  async getComments(id: number): Promise<any> {
    return this.api.get('/video/' + id + '/comments');
  }

  async postComment(id: number, params: any): Promise<any> {
    return this.api.post('/video/' + id + '/comment', params);
  }

  async getTags(id: number): Promise<any> {
    return this.api.get('/video/' + id + '/analysis/tags');
  }

  async getRelatedVideo(id: number): Promise<any> {
    return this.api.get('/video/' + id + '/related/videos/by/context');
  }

  async getAllVideo(
    limit: number | null = 20,
    page: number | null = 1,
    search: string | null,
    owner: string | null,
    all_available_videos: boolean = false,
    excluded_from_group: string
  ) {
    try {
      //https://api.move-e-learning.com/video?limit=20&page=1&all_available_videos=false&excluded_from_group=319
      let query = `?limit=${limit}&page=${page}&search=${search || ''}&owner=${
        owner || ''
      }&all_available_videos=${all_available_videos}&excluded_from_group=${
        excluded_from_group || ''
      }`;
      const videos = await this.api.get('/video' + query);
      return videos;
    } catch (error) {
      return error;
    }
  }

  async searchAllVideos(
    limit: number | null = 20,
    page: number | null = 1,
    search: string | null,
    owner: string | null,
    all_available_videos: boolean = false,
    excluded_from_group: string
  ) {
    try {
      //https://api.move-e-learning.com/video?limit=20&page=1&all_available_videos=false&excluded_from_group=319
      let query = `?limit=${limit}&page=${page}&search=${search || ''}&owner=${
        owner || ''
      }`;
      const videos = await this.api.get('/video' + query);
      return videos;
    } catch (error) {
      return error;
    }
  }


  // create video post
  async post(): Promise<Video> {
    return this.api.post('/video', {}).then((res) => {
      if (res.result == 'success') {
        return res.data.videopost;
      }
    });
  }

  async upload(file: File, videoPostId: number): Promise<Video> {
    let formData = new FormData();
    formData.append('file', file);
    return this.api
      .postFile('/video/' + videoPostId + '/upload', formData)
      .then((res) => {
        if (res.result == 'success') {
          return res.data.video;
        }
      });
  }

  async sendGift(id_video: number, params: any): Promise<any> {
    return this.api.postJson('/video/' + id_video + '/gift', params);
  }

  async IMDBSearch(query: string, type: string): Promise<any> {
    return this.api
      .get('/origin/imdb?q=' + query + '&type=' + type)
      .then((res) => {
        if ((res.result = 'success')) {
          return res.data.records;
        }
      });
  }

  async markView(videoId: any) {
    let url = `/video/${videoId}/view`;
    // moment.locale('en');
    let last_view = moment().calendar();
    try {
      const markView = await this.api.post(url, last_view);
      return markView;
    } catch (error) {
      return error;
    }
  }
  async addFavorite(videoId: any) {
    let url = `/video/${videoId}/star`;
    try {
      const addFavorite = await this.api.post(url, {});
      return addFavorite;
    } catch (error) {
      return error;
    }
  }
  async removeFavorite(videoId: any) {
    let url = `/video/${videoId}/star`;
    try {
      const addFavorite = await this.api.delete(url);
      return addFavorite;
    } catch (error) {
      return error;
    }
  }
  async deleteVideo(videoId: any) {
    let url = `/video/${videoId}`;
    try {
      const deleted = await this.api.delete(url);
      return deleted;
    } catch (error) {
      return error;
    }
  }

  async getVideosOrganization(
    search: string | null,
    order: string,
    limit: number | null = 18,
    page: number | null = 1,
    published: string = '',
    typeOrder: string = 'desc'
  ) {
    try {
      let query = `?limit=${limit}&page=${page}&search=${search || ''}&order=${
        order || ''
      }&order_type=${typeOrder}&published=${published || ''}`;
      const videos = await this.api.get('/video' + query);
      return videos;
    } catch (error) {
      return error;
    }
  }

  rate(id: number, value: number) {
    return this.api
      .postJson('/video/' + id + '/rating', { stars: value })
      .then((res) => {
        return res.data;
      });
  }
  public duplicate(videoId: number, params: any) {
    return this.api.postJson('/video/' + videoId + '/duplicate', params);
  }

  public async editCommentaryConversation(
    conversationId: string,
    commentaryId: number,
    commentaryData: any
  ) {
    try {
      const commentaryEdited = await this.api.put(
        `/video/${conversationId}/comment/${commentaryId}`,
        { comment: commentaryData }
      );
      return commentaryEdited;
    } catch (error) {
      return error;
    }
  }
}
