import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CoursesService } from 'src/app/services/courses.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-confirmation-delete',
  templateUrl: './confirmation-delete.component.html',
  styleUrls: ['./confirmation-delete.component.scss'],
})
export class ConfirmationDeleteComponent implements OnInit {
  @Input() organizationId: any = '';
  @Input() videoId: any = '';
  @Input() selfModal: NgbModalRef | null = null;
  @Output() refreshDelete = new EventEmitter<any>();

  public faCircleNorch: any = faCircleNotch;

  public faTimes = faTimes;
  public loadSpin: boolean = false;

  constructor() {}

  ngOnInit(): void {}
  public deleteVideoEmit() {
    this.refreshDelete.emit();
    this.closeModal();
  }
  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
}
