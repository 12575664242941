import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResetPasswordComponent as ResetPasswordModalComponent} from 'src/app/modals/reset-password/reset-password.component';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  hash: string | undefined;

  constructor(
    private route: ActivatedRoute,
    private ms: NgbModal
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe( (params: any) => {
      this.hash = params['hash'];
      this.openRegisterModal();
    });
  }

  openRegisterModal() {
    let modalRef = this.ms.open(ResetPasswordModalComponent, {
      windowClass: 'login-modal',
      backdropClass: 'modal-backdrop-transparent'
    });
    modalRef.componentInstance.hash = this.hash;
    modalRef.componentInstance.selfModal = modalRef;
  }
}
