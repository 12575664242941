import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Organization } from '../interfaces/organization';

@Injectable({
  providedIn: 'root'
})
export class InitService {
  public currentOrg: Organization | null = null;

  constructor(
    private httpClient: HttpClient,
    private title: Title,
  ) {}

  // get the current organization by the slug
  public getCurrentOrg(): Organization | null {
    return this.currentOrg;
  }

  async loadCurrentOrg(): Promise<Organization | null> {
    const subdomain = window.location.href.split('/')[2].split('.')[0];

    let apiUrl = environment.apiUrl;

    return new Promise( resolve => {
      this.httpClient.get( apiUrl + '/organization/slug/' + subdomain )
        .subscribe( (res: any ) => {
          this.currentOrg = res.data;
          if (this.currentOrg?.id != 1) {
            this.title.setTitle(this.currentOrg?.name!);
          }
          resolve(this.currentOrg);
        }, (error: any) => {
         
          if (error.status != 422||(error.error&&error.error.message.friendly=="Organization not founded")) {
            window.location.href = window.location.href.replace(subdomain, 'courses') + '?organizationError=' + subdomain;
          }
        })
    });
  }

  async loadCurrentOrgData(): Promise<Organization | null> {
    const subdomain = window.location.href.split('/')[2].split('.')[0];

    let apiUrl = environment.apiUrl;

    return new Promise( resolve => {
      this.httpClient.get( apiUrl + '/organization/data_from_slug/' + subdomain )
        .subscribe( (res: any ) => {
          this.currentOrg = res.data;
          resolve(this.currentOrg);
        }, (error: any) => {
          window.location.href = window.location.href.replace(subdomain, 'courses') + '?organizationError=' + subdomain;
        })
    });
  }
}
