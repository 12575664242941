<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">{{'conversations:create:title' | translate}}</h4>
</div>
<div class="modal-body">
  <form
    #fCreateConversation="ngForm"
    (submit)="create(fCreateConversation)"
    novalidate
  >
    <div class="form-group mb-3">
      <label for="clip-title">{{
        "conversations:form:title:label" | translate
      }}</label>
      <input
        name="title"
        type="text"
        class="input-box w-100"
        placeholder="{{ 'conversations:form:title:placeholder' | translate }}"
        [(ngModel)]="conversation.title"
        autofocus
        required
      />
    </div>
    <div class="form-group">
      <label for="clip-title">{{
        "conversations:form:description:label" | translate
      }}</label>
      <quill-editor
        id="txtContent"
        name="description"
        [styles]="boxStyle"
        class="input-box w-100 p-0"
        [(ngModel)]="conversation.description"
        [modules]="modulesQuill"
        placeholder="{{
          'conversations:form:description:placeholder' | translate
        }}"
        autofocus
      ></quill-editor>
    </div>
    <button
      type="submit"
      class="btn btn-primary footer"
      [disabled]="conversation?.title == '' || conversation?.description == ''"
    >
      <span>{{ "conversations:form:create:label" | translate }}</span>
      <fa-icon
        *ngIf="saveLoading"
        class="ms-2"
        [icon]="faCircleNotch"
        [spin]="true"
      ></fa-icon>
    </button>
  </form>
</div>
