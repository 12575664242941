<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">
    {{ "admin:users:modal:edit_account" | translate }}
  </h4>
</div>
<div class="modal-body p-4">
  <div class="form-group">
    <label class="modal-label" for="clip-title">{{
      "organization:members:create_account:user" | translate
    }}</label>
    <input
      [(ngModel)]="info.name"
      class="input-box w-100"
      id="clip-title"
      maxlength="80"
      name="name"
      placeholder="{{ '' | translate }}"
      required=""
      type="text"
    />
    <label class="modal-label" for="clip-title">{{
      "admin:users:info:title:1" | translate
    }}</label>
    <input
      [(ngModel)]="info.email"
      class="input-box w-100"
      id="clip-title"
      maxlength="80"
      name="name"
      placeholder="{{ '' | translate }}"
      required=""
      type="text"
    />
    <label class="modal-label" for="clip-title">{{
      "admin:users:modal:edit_account:label:3" | translate
    }}</label>
    <input
      [(ngModel)]="info.password"
      class="input-box w-100"
      id="clip-title"
      maxlength="80"
      name="name"
      placeholder="{{ '******' | translate }}"
      required=""
      type="text"
    />
    <label class="modal-label" for="clip-title">{{
      "admin:users:modal:edit_account:label:4" | translate
    }}</label>
    <input
      [(ngModel)]="info.passwordRepeat"
      class="input-box w-100"
      id="clip-title"
      maxlength="80"
      name="name"
      placeholder="{{ '******' | translate }}"
      required=""
      type="text"
    />
  </div>
  <div class="form-group">
    <label class="modal-label" for="clip-title mt-3">{{
      "organization:members:create_account:user_settings:label" | translate
    }}</label>
    <div class="small-form-text px-0">
      {{
        "organization:members:edit_account:user_settings:subtitle:label"
          | translate
      }}
    </div>
    <label class="fancy-radio-checkbox modal-label">
      <input
        [disabled]="true"
        [(ngModel)]="radio"
        class="fancy-input"
        id="open"
        name="privacyOpen"
        type="radio"
        [value]="true"
      />
      <div class="fancy-graphic"></div>
      {{ "organization:members:create_account:radio:normal_user" | translate }}
    </label>

    <label class="fancy-radio-checkbox modal-label">
      <input
        [disabled]="true"
        class="fancy-input"
        id="closed"
        name="privacyOpen"
        type="radio"
        value="false"
      />
      <div class="fancy-graphic"></div>
      {{ "collection:share:other:organizations:modal:moderator" | translate }}
    </label>
    <label class="fancy-radio-checkbox modal-label">
      <input
        [disabled]="true"
        class="fancy-input"
        id="closed"
        name="privacyOpen"
        type="radio"
        value="false"
      />
      <div class="fancy-graphic"></div>
      {{ "organization:members:create_account:radio:superadmin" | translate }}
    </label>
  </div>
  <hr class="common-separator" />
  <div class="form-group">
    <div class="row justify-content-end mx-1">
      <button
        (click)="editMember()"
        type="button"
        role="tab"
        class="btn btn-primary btn-create modal-btn-width float-right ms-2"
      >
        {{ "admin:users:modal:edit_account:btn:save" | translate }}
        <fa-icon [icon]="faCircleNorch" *ngIf="loading" [spin]="true"></fa-icon>
      </button>
    </div>
  </div>
</div>
