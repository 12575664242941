import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { CollectionService } from 'src/app/services/collection.service';
import { AuthService } from 'src/app/services/auth.service';
import { InitService } from 'src/app/services/init.service';
import { Course } from 'src/app/interfaces/course';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Lesson } from 'src/app/interfaces/lesson';

@Component({
  selector: 'app-add-preview-lesson',
  templateUrl: './add-preview-lesson.component.html',
  styleUrls: ['./add-preview-lesson.component.scss']
})

export class AddPreviewLessonComponent implements OnInit {
  @Output() success: EventEmitter<any> = new EventEmitter();
  @Input() onLesson:boolean = false;

  faTimes = faTimes;
  @Input() selfModal: NgbModalRef | null = null;
  @Input() title:String='';
  lessons: Array<Lesson> = [];
  faCircleNorch = faCircleNotch;
  loading= true;
  addLoading=false;
  actualTab='allCourse';
  username: any;
  itemSelect='';
  searchText=''
  searchTextOwn=''

  constructor(
    public auth: AuthService,
    public ds: DashboardService,
  ) { }

  ngOnInit(): void {
    this.getPreviewLessons();
  }

  getPreviewLessons() {
    this.ds.getPreviewLessons(this.searchText).then( (res) => {
      this.lessons = res;
      this.loading = false;
    });
  }

  closeModal() {
    if(this.selfModal) this.selfModal?.close();
    else $("ngb-modal-window").click();
  }

  selectItem( value: number ) {
    this.itemSelect = value + '';
  }

  sendData(){
    this.addLoading = true;
    this.success.emit(this.findSelected());
    this.closeModal()
  }

  search(text:string){
    this.searchText = text;
    this.getPreviewLessons();
  }

  findSelected() {
    let lesson = this.lessons.find( (l: Lesson) => l.id == +this.itemSelect );
    return lesson;
  }
}
