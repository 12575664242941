import { Component, Input, OnInit } from '@angular/core';
import { InitService } from 'src/app/services/init.service';
import { GroupsService } from 'src/app/services/groups.service';
import { LangService } from 'src/app/services/lang.service';
import { faClock  } from '@fortawesome/free-regular-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-group-activity-item-course',
  templateUrl: './group-activity-item-course.component.html',
  styleUrls: ['./group-activity-item-course.component.scss']
})
export class GroupActivityItemCourseComponent implements OnInit {
 
	@Input() activities: any;
  comments: any;
	activity: any;

  
  loading = false;
  title = '';
  description = '';
  language: String = 'en';
  faClock = faClock;

  constructor(private router: Router) {  }

  ngOnInit(): void {
    this.activity=this.activities.entity;
    this.comments=this.activities.comment
  }
 
  goToCourse(courseId: any) {
    // this.router.navigate(['/courses', courseId]);
    window.open(this.router.createUrlTree(['/courses', courseId]).toString(), '_blank');
  }
}
