import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ElementRef,
  HostListener,
} from '@angular/core';
import { Router } from '@angular/router';
import { faTimes, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { AdminService } from 'src/app/services/admin.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-create-discount',
  templateUrl: './create-discount.component.html',
  styleUrls: ['./create-discount.component.scss'],
})
export class CreateDiscountComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() discount: any = null;
  @Output() refresh = new EventEmitter<any>();

  public clearCourse: boolean = false;
  faTimes = faTimes;
  date1: any = '';
  date2: any = '';
  title: string= 'admin:users:modal:discountProfile';
  btnAccept: string='admin:users:modal:discountProfile:btn:save';
  infoDiscount: any = null;
  courseSelected: any = '';
  quantity: number = 1;
  loadSpin: boolean = false;
  firstCheck: boolean = false;
  secondCheck: boolean = true;
  faCircleNorch: any = faCircleNotch;
  timesError=false
  codesDiscounts: any = [];
  codes: any = '';
  constructor(
    private router: Router,
    private adminService: AdminService,
    private toastService: ToastService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    console.log('this.discount',this.discount)
    if (this.discount.id){
      this.title='admin:users:modal:discountEdit'
      this.btnAccept='admin:users:modal:discountEdit:btn:save'
      this.courseSelected=this.discount.collection
    }

    this.infoDiscount = {
      amount: this.discount?.amount,
      code: this.discount?.code,
      collection_id: this.discount.collection_id,
      total_available: this.discount.total_available,
      type: this.discount.type?this.discount.type:'percentage',
      valid_from: this.discount?.valid_from?this.discount?.valid_from.slice(0, 10):'',
      valid_until: this.discount?.valid_until?this.discount?.valid_until.slice(0, 10):'',
    };
  }
  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  goToCourse(courseId: any) {
    this.closeModal();
    this.router.navigate(['/courses', courseId]);
  }


  isValidDate(dateStr: string): boolean {
    const date = moment(dateStr, 'DD-MM-YYYY', true);

    // Verifica si la fecha es válida
    return date.isValid();
  }


  public updateDiscount() {
    
    
    if (this.firstCheck) {
      this.infoDiscount.total_available = null;
    }
    if (this.secondCheck) {
      this.infoDiscount.valid_until = null;
    }
    if (this.isValidDate(this.infoDiscount?.valid_from)){
      this.toastService.showError(
        this.translate.instant('Invalid date Discount begins')
      );
      return;
    }
    if (!this.firstCheck) {
      if (Number(this.infoDiscount.total_available)<=0) {
        this.toastService.showError(
          this.translate.instant('Invalid data fields')
        );
        this.timesError=true
        return;
      }
    }
    if (isNaN(Number(this.infoDiscount.total_available))) {
      this.toastService.showError(
        this.translate.instant('Invalid data fields')
      );
      this.timesError=true
      return;
    }
    // this.infoDiscount.valid_from = moment(this.infoDiscount?.valid_from).format(
    //   'YYYY-MM-DD'
    // );
    //this.infoDiscount.valid_from = this.infoDiscount.valid_from.toString();
    // if (this.infoDiscount.valid_until) {

    //   if (this.isValidDate(this.infoDiscount?.valid_until)){
    //     this.toastService.showError(
    //       this.translate.instant('Invalid date Discount expires')
    //     );
    //     return;
    //   }
      
    //   this.infoDiscount.valid_until = moment(
    //     this.infoDiscount?.valid_until
    //   ).format('YYYY-MM-DD');
    // }
    this.loadSpin = true;
    console.log('this.infoDiscount',this.infoDiscount)

    if (this.codes.length) {
      this.infoDiscount.code = this.codes;
    }
    this.infoDiscount.collection_id = parseInt(this.courseSelected?.id, 10);
    this.infoDiscount.amount = parseInt(this.infoDiscount.amount ) ;
    if (!this.discount.id){ //crear
      this.adminService
        .createDiscount(this.infoDiscount)
        .then((result) => {
          this.loadSpin = false;
          this.toastService.showSuccess(
            this.translate.instant('discount:create:success')
          );
          this.closeModal();
          this.refresh.emit();
        })
        .catch((err) => {
          this.loadSpin = false;
        // this.closeModal();
          let errorData=err.error.message.data[0].message;
          if ( errorData=='code must be unique'){
            this.toastService.showError(
              this.translate.instant('Discount Code must be unique')
            );
          }else{
            this.toastService.showError(
              this.translate.instant('discount:create:error')
            );
          }
          
        });
    }else{
      //editar
      this.adminService
      .updateDiscount(this.infoDiscount, this.discount.id)
      .then((result) => {
        this.loadSpin = false;
        this.closeModal();
        this.refresh.emit();
      })
      .catch((err) => {
        this.loadSpin = false;
        // this.closeModal();
          let errorData=err.error.message.data[0].message;
          if ( errorData=='code must be unique'){
            this.toastService.showError(
              this.translate.instant('Discount Code must be unique')
            );
          }else{
            this.toastService.showError(
              this.translate.instant('discount:create:error')
            );
          }
      });
    }
  }
  public unselect() {
    this.courseSelected = '';
  }
  public changeInfinite() {
    !this.firstCheck;
  }
  public changeExpires() {
    !this.secondCheck;
  }
  public generateCodes() {
    this.codes = '';
    this.reset();
    for (let index = 0; index < this.quantity; index++) {
      this.codesDiscounts.push(
        `${this.infoDiscount.code.toUpperCase()}-${this.generateRandomLetter().toUpperCase()}${Math.floor(
          Math.random() * 10
        )}`
      );
    }
    this.codes = this.codesDiscounts.join(', ');
  }

  generateRandomLetter() {
    const alphabet = 'abcdefghijklmnopqrstuvwxyz';

    return `${alphabet[Math.floor(Math.random() * alphabet.length)]}${
      alphabet[Math.floor(Math.random() * alphabet.length)]
    }${alphabet[Math.floor(Math.random() * alphabet.length)]}`;
  }
  public reset() {
    this.codesDiscounts = [];
  }
  getCourse(course: any) {
    this.courseSelected = course;
  }
  evitarGuion(event: KeyboardEvent) {
    if (event.key === '-') {
      event.preventDefault();
    }
    this.timesError=false;
  }
  changeType(){
    if (this.infoDiscount.type == 'percentage') {
      if ( this.infoDiscount.amount > 100) {
        this.infoDiscount.amount = 100;
      }  
    }

  }
  validInput(event: any) {
    if (event.key === '-') {
      event.preventDefault();
    }
    if (this.infoDiscount.type == 'percentage') {
      const inputValue = event.target.value;
      if (inputValue > 100) {
        this.infoDiscount.amount = 100;
      } else {
        this.infoDiscount.amount = parseInt(inputValue) ;
      }
    }
  }
}
