<div style="padding-bottom: 60.11%; position: relative"
  *ngIf="this.video?.source?.exist">
  <div
    [id]="'kaltura_player_' + this.video?.source?.external_id"
    style="position: absolute; width: 100%; height: 100%"
   
  >
    <app-loading-icon *ngIf="loading"></app-loading-icon>
    <div *ngIf="!video" class="row text-center my-5">
      <h5>Video not found</h5>
    </div>
  </div>


</div>
<div *ngIf="!this.video?.source?.exist" class="d-flex align-items-center justify-content-center">
  <img src="assets/img/courses/video_not_found.png" alt="Imagen centrada" class="img-fluid" style="height: 400px;">
</div>