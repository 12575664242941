<h3 class="h6 mb-1">{{ 'video:edit:modal:tab:categories:title' | translate }}</h3>
<p class="text-muted fs-14">{{ 'video:edit:modal:tab:categories:description' | translate }}</p>
<div class="clearfix category-boxes">
  <div *ngFor="let category of $any(videoEditService.categories)" class="category-box">
    <div class="category-box-title p-2 mb-1">
      <input type="checkbox" [(ngModel)]="category.selected" (ngModelChange)="touched()" id="{{ category.tag_id }}">
      <label class="parent-checkbox ms-2" for="{{ category.tag_id }}">{{ category.name }}</label>
    </div>
    <ul class="category-box-list-items ps-3">
      <li *ngFor="let subcategory of category.subcategories" class=" m-0 mb-1">
        <input [(ngModel)]="subcategory.selected" type="checkbox" (ngModelChange)="touched()" id="{{ subcategory.tag_id }}">
        <label class="ms-2" for="{{ subcategory.tag_id }}">{{ subcategory.name }}</label>
      </li>
    </ul>
  </div>
</div>
