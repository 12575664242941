<div class="card shadow" [ngClass]="{ featured: featured }">
  <span class="badge badge-item-type text-primary px-3">{{
    "playlist:pill:label" | translate
  }}</span>
  <div
    *ngIf="isFromGroup && userCanEdit(playlist)"
    ngbDropdown
    class="btn-toggle text-primary border rounded"
    placement="bottom-right"
  >
    <button type="button" class="btn" id="dropdownBasic1" ngbDropdownToggle>
      <i class="fa fa-ellipsis-h"></i>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="mt-1">
      <button (click)="deleteItem()" class="text-danger" ngbDropdownItem>
        {{ "groups:profile:playlists:delete" | translate }}
      </button>
    </div>
  </div>

  <img
    (click)="goProfile()"
    [ngClass]="{ 'no-publish': playlist?.public == false }"
    [src]="
      playlist?.coverPathUrlMedium ||
      playlist?.thumbnail ||
      playlist?.thumb_url ||
      'assets/img/groups/icons/list.png'
    "
    class="card-img-top"
  />

  <div (click)="goProfile()" class="card-body position-relative">
    <span
      *ngIf="playlist?.status"
      class="badge rounded-pill p-2 px-3 text-white position-absolute fw-normal"
      [ngClass]="backgroundBadge(playlist)"
    >
      <span *ngIf="playlist?.public == true">
        {{ "playlist:library:status:" + playlist?.status | translate }}
      </span>
      <span *ngIf="playlist?.public == false">
        {{ "Unpublished" | translate }}
      </span>
    </span>

    <h3 class="card-title mb-0 mt-2 fw-normal" [ngClass]="{ 'mb-3': featured }">
      {{ playlist?.name }}
    </h3>
    <p class="card-text" *ngIf="!featured">
      <small class="text-muted"
        >{{ "author:by" | translate }} {{ playlist?.user?.display_name }}</small
      >
    </p>
    <p
      class="card-text short-description short-text p-margin"
      *ngIf="!featured"
      [innerHTML]="playlist?.description"
    ></p>

    <div
      class="d-flex justify-content-start align-items-center"
      style="min-height: 21px"
    >
      <small class="me-3 text-muted d-flex">
        <img class="img-play" src="/assets/img/cards/ico-playlist-play.png" />
        <span class="ms-1"
          >{{ playlist?.video_count }}
          {{
            (playlist?.video_count == 1
              ? "playlist:list:count:label:singular"
              : "playlist:list:count:label:plural"
            ) | translate
          }}</span
        >
      </small>

      <small class="me-3 text-muted d-flex" *ngIf="playlist!.video_length">
        <img class="img-play" src="/assets/img/cards/clock.png" />
        <span class="ms-1">{{ playlist?.video_length | duration }}</span>
      </small>
    </div>
  </div>
</div>
