<div (click)="select()" class="thumbnail-item-content clearfix position-relative">
  <div class="pull-left the-thumbnail" [ngStyle]="{'background': 'url(' + getImage() + ')'}">
  </div>
  <div class="thumbnail-item-content-text">
    <div class="the-min-height-trick">
      <div class="row">
        <div class="col-md-10">
          <h2 class="the-item-title">{{ lesson?.name }}</h2>
          <div class="text-muted mb-2 the-item-text">{{ lesson?.chapter?.collections![0].name }}</div>
          <div class="the-item-text" [innerHtml]="lesson?.description! | htmlToPlainText | excerpt:200"></div>
        </div>
        <div class="col-md-2">
          <div *ngIf="lesson?.free_preview" class="m-3 position-absolute badge rounded-pill p-1 px-3 text-white fw-normal"
          style="top: 0; left: 0; background-color: #F7B42B;">Preview</div>
          <div *ngIf="itemSelect == lesson?.id" class="text-center icon-selected"><i class="fas fa-plus-circle"></i></div>
          <div *ngIf="itemSelect != lesson?.id" class="text-center icon-check"><i class="fas fa-plus-circle"></i></div>
        </div>
      </div>
    </div>
  </div>
</div>
