import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  euCountries = [
    {"name": "Austria", "code": "AT", "vatCode": "AT", "tax": 0.20},
    {"name": "Belgium", "code": "BE", "vatCode": "BE", "tax": 0.21},
    {"name": "Bulgaria", "code": "BG", "vatCode": "BG", "tax": 0.20},
    {"name": "Croatia", "code": "HR", "vatCode": "HR", "tax": 0.25},
    {"name": "Cyprus", "code": "CY", "vatCode": "CY", "tax": 0.19},
    {"name": "Czech Republic", "code": "CZ", "vatCode": "CZ", "tax": 0.21},
    {"name": "Denmark", "code": "DK", "vatCode": "DK", "tax": 0.25},
    {"name": "Estonia", "code": "EE", "vatCode": "EE", "tax": 0.20},
    {"name": "Finland", "code": "FI", "vatCode": "FI", "tax": 0.24},
    {"name": "France", "code": "FR", "vatCode": "FR", "tax": 0.20},
    {"name": "Germany", "code": "DE", "vatCode": "DE", "tax": 0.19},
    {"name": "Greece", "code": "GR", "vatCode": "EL", "tax": 0.24},
    {"name": "Hungary", "code": "HU", "vatCode": "HU", "tax": 0.27},
    {"name": "Ireland", "code": "IE", "vatCode": "IE", "tax": 0.23},
    {"name": "Italy", "code": "IT", "vatCode": "IT", "tax": 0.22},
    {"name": "Latvia", "code": "LV", "vatCode": "LV", "tax": 0.21},
    {"name": "Lithuania", "code": "LT", "vatCode": "LT", "tax": 0.21},
    {"name": "Luxembourg", "code": "LU", "vatCode": "LU", "tax": 0.17},
    {"name": "Malta", "code": "MT", "vatCode": "MT", "tax": 0.18},
    {"name": "Netherlands", "code": "NL", "vatCode": "NL", "tax": 0.21},
    {"name": "Poland", "code": "PL", "vatCode": "PL", "tax": 0.23},
    {"name": "Portugal", "code": "PT", "vatCode": "PT", "tax": 0.23},
    {"name": "Romania", "code": "RO", "vatCode": "RO", "tax": 0.19},
    {"name": "Slovakia", "code": "SK", "vatCode": "SK", "tax": 0.20},
    {"name": "Slovenia", "code": "SI", "vatCode": "SI", "tax": 0.22},
    {"name": "Spain", "code": "ES", "vatCode": "ES", "tax": 0.21},
    {"name": "Sweden", "code": "SE", "vatCode": "SE", "tax": 0.25},
    {"name": "United Kingdom", "code": "GB", "vatCode": "GB", "tax": 0.20},
    {"name": "Argentina",      "code": "AR", "vatCode": "AR","tax": 0.21}
  ];

  // Rest of the world countries
  rotwCountries = [
    {"name": "Afghanistan", "code": "AF", "vatCode": ""},
    {"name": "Aland Islands", "code": "AX", "vatCode": ""},
    {"name": "Albania", "code": "AL", "vatCode": "AL"},
    {"name": "Algeria", "code": "DZ", "vatCode": ""},
    {"name": "American Samoa", "code": "AS", "vatCode": ""},
    {"name": "AndorrA", "code": "AD", "vatCode": ""},
    {"name": "Angola", "code": "AO", "vatCode": ""},
    {"name": "Anguilla", "code": "AI", "vatCode": ""},
    {"name": "Antarctica", "code": "AQ", "vatCode": ""},
    {"name": "Antigua and Barbuda", "code": "AG", "vatCode": ""},
    {"name": "Argentina", "code": "AR", "vatCode": "AR"},
    {"name": "Armenia", "code": "AM", "vatCode": ""},
    {"name": "Aruba", "code": "AW", "vatCode": ""},
    {"name": "Australia", "code": "AU", "vatCode": "AU"},
    {"name": "Azerbaijan", "code": "AZ", "vatCode": ""},
    {"name": "Bahamas", "code": "BS", "vatCode": ""},
    {"name": "Bahrain", "code": "BH", "vatCode": ""},
    {"name": "Bangladesh", "code": "BD", "vatCode": ""},
    {"name": "Barbados", "code": "BB", "vatCode": ""},
    {"name": "Belarus", "code": "BY", "vatCode": "BY"},
    {"name": "Belize", "code": "BZ", "vatCode": ""},
    {"name": "Benin", "code": "BJ", "vatCode": ""},
    {"name": "Bermuda", "code": "BM", "vatCode": ""},
    {"name": "Bhutan", "code": "BT", "vatCode": ""},
    {"name": "Bolivia", "code": "BO", "vatCode": "BO"},
    {"name": "Bosnia and Herzegovina", "code": "BA", "vatCode": ""},
    {"name": "Botswana", "code": "BW", "vatCode": ""},
    {"name": "Bouvet Island", "code": "BV", "vatCode": ""},
    {"name": "Brazil", "code": "BR", "vatCode": "BR"},
    {"name": "British Indian Ocean Territory", "code": "IO", "vatCode": ""},
    {"name": "Brunei Darussalam", "code": "BN", "vatCode": ""},
    {"name": "Burkina Faso", "code": "BF", "vatCode": ""},
    {"name": "Burundi", "code": "BI", "vatCode": ""},
    {"name": "Cambodia", "code": "KH", "vatCode": ""},
    {"name": "Cameroon", "code": "CM", "vatCode": ""},
    {"name": "Canada", "code": "CA", "vatCode": "CA"},
    {"name": "Cape Verde", "code": "CV", "vatCode": ""},
    {"name": "Cayman Islands", "code": "KY", "vatCode": ""},
    {"name": "Central African Republic", "code": "CF", "vatCode": ""},
    {"name": "Chad", "code": "TD", "vatCode": ""},
    {"name": "Chile", "code": "CL", "vatCode": "CL"},
    {"name": "China", "code": "CN", "vatCode": ""},
    {"name": "Christmas Island", "code": "CX", "vatCode": ""},
    {"name": "Cocos (Keeling) Islands", "code": "CC", "vatCode": ""},
    {"name": "Colombia", "code": "CO", "vatCode": "CO"},
    {"name": "Comoros", "code": "KM", "vatCode": ""},
    {"name": "Congo", "code": "CG", "vatCode": ""},
    {"name": "Congo, The Democratic Republic of the", "code": "CD", "vatCode": ""},
    {"name": "Cook Islands", "code": "CK", "vatCode": ""},
    {"name": "Costa Rica", "code": "CR", "vatCode": "CR"},
    {"name": "Cote D'Ivoire", "code": "CI", "vatCode": ""},
    {"name": "Cuba", "code": "CU", "vatCode": ""},
    {"name": "Djibouti", "code": "DJ", "vatCode": ""},
    {"name": "Dominica", "code": "DM", "vatCode": ""},
    {"name": "Dominican Republic", "code": "DO", "vatCode": "DO"},
    {"name": "Ecuador", "code": "EC", "vatCode": "EC"},
    {"name": "Egypt", "code": "EG", "vatCode": ""},
    {"name": "El Salvador", "code": "SV", "vatCode": "SV"},
    {"name": "Equatorial Guinea", "code": "GQ", "vatCode": ""},
    {"name": "Eritrea", "code": "ER", "vatCode": ""},
    {"name": "Ethiopia", "code": "ET", "vatCode": ""},
    {"name": "Falkland Islands (Malvinas)", "code": "FK", "vatCode": ""},
    {"name": "Faroe Islands", "code": "FO", "vatCode": ""},
    {"name": "Fiji", "code": "FJ", "vatCode": ""},
    {"name": "French Guiana", "code": "GF", "vatCode": ""},
    {"name": "French Polynesia", "code": "PF", "vatCode": ""},
    {"name": "French Southern Territories", "code": "TF", "vatCode": ""},
    {"name": "Gabon", "code": "GA", "vatCode": ""},
    {"name": "Gambia", "code": "GM", "vatCode": ""},
    {"name": "Georgia", "code": "GE", "vatCode": ""},
    {"name": "Ghana", "code": "GH", "vatCode": ""},
    {"name": "Gibraltar", "code": "GI", "vatCode": ""},
    {"name": "Greenland", "code": "GL", "vatCode": ""},
    {"name": "Grenada", "code": "GD", "vatCode": ""},
    {"name": "Guadeloupe", "code": "GP", "vatCode": ""},
    {"name": "Guam", "code": "GU", "vatCode": ""},
    {"name": "Guatemala", "code": "GT", "vatCode": "GT"},
    {"name": "Guernsey", "code": "GG", "vatCode": ""},
    {"name": "Guinea", "code": "GN", "vatCode": ""},
    {"name": "Guinea-Bissau", "code": "GW", "vatCode": ""},
    {"name": "Guyana", "code": "GY", "vatCode": ""},
    {"name": "Haiti", "code": "HT", "vatCode": ""},
    {"name": "Heard Island and Mcdonald Islands", "code": "HM", "vatCode": ""},
    {"name": "Holy See (Vatican City State)", "code": "VA", "vatCode": ""},
    {"name": "Honduras", "code": "HN", "vatCode": "HN"},
    {"name": "Hong Kong", "code": "HK", "vatCode": ""},
    {"name": "Iceland", "code": "IS", "vatCode": "IS"},
    {"name": "India", "code": "IN", "vatCode": "IN"},
    {"name": "Indonesia", "code": "ID", "vatCode": "ID"},
    {"name": "Iran, Islamic Republic Of", "code": "IR", "vatCode": ""},
    {"name": "Iraq", "code": "IQ", "vatCode": ""},
    {"name": "Isle of Man", "code": "IM", "vatCode": "GB"},
    {"name": "Israel", "code": "IL", "vatCode": "IL"},
    {"name": "Jamaica", "code": "JM", "vatCode": ""},
    {"name": "Japan", "code": "JP", "vatCode": ""},
    {"name": "Jersey", "code": "JE", "vatCode": ""},
    {"name": "Jordan", "code": "JO", "vatCode": ""},
    {"name": "Kazakhstan", "code": "KZ", "vatCode": ""},
    {"name": "Kenya", "code": "KE", "vatCode": ""},
    {"name": "Kiribati", "code": "KI", "vatCode": ""},
    {"name": "Korea, Democratic People's Republic of", "code": "KP", "vatCode": ""},
    {"name": "Korea, Republic of", "code": "KR", "vatCode": ""},
    {"name": "Kuwait", "code": "KW", "vatCode": ""},
    {"name": "Kyrgyzstan", "code": "KG", "vatCode": ""},
    {"name": "Lao People's Democratic Republic", "code": "LA", "vatCode": ""},
    {"name": "Lebanon", "code": "LB", "vatCode": ""},
    {"name": "Lesotho", "code": "LS", "vatCode": ""},
    {"name": "Liberia", "code": "LR", "vatCode": ""},
    {"name": "Libyan Arab Jamahiriya", "code": "LY", "vatCode": ""},
    {"name": "Liechtenstein", "code": "LI", "vatCode": ""},
    {"name": "Macao", "code": "MO", "vatCode": ""},
    {"name": "Macedonia, The Former Yugoslav Republic of", "code": "MK", "vatCode": ""},
    {"name": "Madagascar", "code": "MG", "vatCode": ""},
    {"name": "Malawi", "code": "MW", "vatCode": ""},
    {"name": "Malaysia", "code": "MY", "vatCode": ""},
    {"name": "Maldives", "code": "MV", "vatCode": ""},
    {"name": "Mali", "code": "ML", "vatCode": ""},
    {"name": "Marshall Islands", "code": "MH", "vatCode": ""},
    {"name": "Martinique", "code": "MQ", "vatCode": ""},
    {"name": "Mauritania", "code": "MR", "vatCode": ""},
    {"name": "Mauritius", "code": "MU", "vatCode": ""},
    {"name": "Mayotte", "code": "YT", "vatCode": ""},
    {"name": "Mexico", "code": "MX", "vatCode": "MX"},
    {"name": "Micronesia, Federated States of", "code": "FM", "vatCode": ""},
    {"name": "Moldova, Republic of", "code": "MD", "vatCode": ""},
    {"name": "Monaco", "code": "MC", "vatCode": "FR"},
    {"name": "Mongolia", "code": "MN", "vatCode": ""},
    {"name": "Montserrat", "code": "MS", "vatCode": ""},
    {"name": "Morocco", "code": "MA", "vatCode": ""},
    {"name": "Mozambique", "code": "MZ", "vatCode": ""},
    {"name": "Myanmar", "code": "MM", "vatCode": ""},
    {"name": "Namibia", "code": "NA", "vatCode": ""},
    {"name": "Nauru", "code": "NR", "vatCode": ""},
    {"name": "Nepal", "code": "NP", "vatCode": ""},
    {"name": "Netherlands Antilles", "code": "AN", "vatCode": ""},
    {"name": "New Caledonia", "code": "NC", "vatCode": ""},
    {"name": "New Zealand", "code": "NZ", "vatCode": ""},
    {"name": "Nicaragua", "code": "NI", "vatCode": "NI"},
    {"name": "Niger", "code": "NE", "vatCode": ""},
    {"name": "Nigeria", "code": "NG", "vatCode": ""},
    {"name": "Niue", "code": "NU", "vatCode": ""},
    {"name": "Norfolk Island", "code": "NF", "vatCode": ""},
    {"name": "Northern Mariana Islands", "code": "MP", "vatCode": ""},
    {"name": "Norway", "code": "NO", "vatCode": "NO"},
    {"name": "Oman", "code": "OM", "vatCode": ""},
    {"name": "Pakistan", "code": "PK", "vatCode": ""},
    {"name": "Palau", "code": "PW", "vatCode": ""},
    {"name": "Palestinian Territory", "code": "PS", "vatCode": ""},
    {"name": "Panama", "code": "PA", "vatCode": "PA"},
    {"name": "Papua New Guinea", "code": "PG", "vatCode": ""},
    {"name": "Paraguay", "code": "PY", "vatCode": "PY"},
    {"name": "Peru", "code": "PE", "vatCode": "PE"},
    {"name": "Philippines", "code": "PH", "vatCode": "PH"},
    {"name": "Pitcairn", "code": "PN", "vatCode": ""},
    {"name": "Puerto Rico", "code": "PR", "vatCode": ""},
    {"name": "Qatar", "code": "QA", "vatCode": ""},
    {"name": "RWANDA", "code": "RW", "vatCode": ""},
    {"name": "Reunion", "code": "RE", "vatCode": ""},
    {"name": "Russian Federation", "code": "RU", "vatCode": "RU"},
    {"name": "Saint Helena", "code": "SH", "vatCode": ""},
    {"name": "Saint Kitts and Nevis", "code": "KN", "vatCode": ""},
    {"name": "Saint Lucia", "code": "LC", "vatCode": ""},
    {"name": "Saint Pierre and Miquelon", "code": "PM", "vatCode": ""},
    {"name": "Saint Vincent and the Grenadines", "code": "VC", "vatCode": ""},
    {"name": "Samoa", "code": "WS", "vatCode": ""},
    {"name": "San Marino", "code": "SM", "vatCode": "SM"},
    {"name": "Sao Tome and Principe", "code": "ST", "vatCode": ""},
    {"name": "Saudi Arabia", "code": "SA", "vatCode": ""},
    {"name": "Senegal", "code": "SN", "vatCode": ""},
    {"name": "Serbia and Montenegro", "code": "CS", "vatCode": "RS"},
    {"name": "Seychelles", "code": "SC", "vatCode": ""},
    {"name": "Sierra Leone", "code": "SL", "vatCode": ""},
    {"name": "Singapore", "code": "SG", "vatCode": ""},
    {"name": "Solomon Islands", "code": "SB", "vatCode": ""},
    {"name": "Somalia", "code": "SO", "vatCode": ""},
    {"name": "South Africa", "code": "ZA", "vatCode": ""},
    {"name": "South Georgia and the South Sandwich Islands", "code": "GS", "vatCode": ""},
    {"name": "Sri Lanka", "code": "LK", "vatCode": ""},
    {"name": "Sudan", "code": "SD", "vatCode": ""},
    {"name": "Suriname", "code": "SR", "vatCode": ""},
    {"name": "Svalbard and Jan Mayen", "code": "SJ", "vatCode": ""},
    {"name": "Swaziland", "code": "SZ", "vatCode": ""},
    {"name": "Switzerland", "code": "CH", "vatCode": "CH"},
    {"name": "Syrian Arab Republic", "code": "SY", "vatCode": ""},
    {"name": "Taiwan, Province of China", "code": "TW", "vatCode": ""},
    {"name": "Tajikistan", "code": "TJ", "vatCode": ""},
    {"name": "Tanzania, United Republic of", "code": "TZ", "vatCode": ""},
    {"name": "Thailand", "code": "TH", "vatCode": ""},
    {"name": "Timor-Leste", "code": "TL", "vatCode": ""},
    {"name": "Togo", "code": "TG", "vatCode": ""},
    {"name": "Tokelau", "code": "TK", "vatCode": ""},
    {"name": "Tonga", "code": "TO", "vatCode": ""},
    {"name": "Trinidad and Tobago", "code": "TT", "vatCode": ""},
    {"name": "Tunisia", "code": "TN", "vatCode": ""},
    {"name": "Turkey", "code": "TR", "vatCode": "TR"},
    {"name": "Turkmenistan", "code": "TM", "vatCode": ""},
    {"name": "Turks and Caicos Islands", "code": "TC", "vatCode": ""},
    {"name": "Tuvalu", "code": "TV", "vatCode": ""},
    {"name": "Uganda", "code": "UG", "vatCode": ""},
    {"name": "Ukraine", "code": "UA", "vatCode": "UA"},
    {"name": "United Arab Emirates", "code": "AE", "vatCode": ""},
    {"name": "United States Minor Outlying Islands", "code": "UM", "vatCode": ""},
    {"name": "United States", "code": "US", "vatCode": ""},
    {"name": "Uruguay", "code": "UY", "vatCode": "UY"},
    {"name": "Uzbekistan", "code": "UZ", "vatCode": "UZ"},
    {"name": "Vanuatu", "code": "VU", "vatCode": ""},
    {"name": "Venezuela", "code": "VE", "vatCode": "VE"},
    {"name": "Viet Nam", "code": "VN", "vatCode": ""},
    {"name": "Virgin Islands, British", "code": "VG", "vatCode": ""},
    {"name": "Virgin Islands, U.S.", "code": "VI", "vatCode": ""},
    {"name": "Wallis and Futuna", "code": "WF", "vatCode": ""},
    {"name": "Western Sahara", "code": "EH", "vatCode": ""},
    {"name": "Yemen", "code": "YE", "vatCode": ""},
    {"name": "Zambia", "code": "ZM", "vatCode": ""},
    {"name": "Zimbabwe", "code": "ZW", "vatCode": ""}
  ];

  constructor() { }

  getCountryList (region: string = ''){
    if (region == 'eu'){
      return this.euCountries;
    }
    if (region == 'rotw'){
      return this.rotwCountries;
    }
    return [...this.euCountries, ...this.rotwCountries] ;
  }

  isEuCountry (countryCode: string): boolean {
    var is = false;

    let country = this.euCountries.find( (c) => c.code == countryCode );

    if (country) {
      is = true;
    }

    return is;
  }

  getCountryTax (countryCode: string): number {
    let country = this.euCountries.find( (c) => c.code == countryCode );
    if (country) {
      return country.tax;
    } else {
      return 0.21;
    }
  }

  getDefaultCountryCode(): string {
    return 'NL';
  }
}
