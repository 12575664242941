<div class="modal-close" (click)="closeModal()">
    <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
    <h4 class="modal-title">{{"group:modal:notifications:title" | translate}}</h4>
</div>
<div class="modal-body">
    <form id="notificationSettingsFormId" name="notificationSettingsFormName" novalidate>
        <div *ngIf="showLoading" class="row m-auto">
            <div class="mb-5">
                <app-loading-icon></app-loading-icon>
            </div>
        </div>
        <div *ngIf="!showLoading">
            <div class="form-group mb-3">
                
                <p class="title">{{ "group:modal:notifications:label:1" | translate }}</p>
                <ml-switch [inputValue]="notificationsSettings.notification_user_join" 
                    textAlignClass="ml-switch-text-right"
                    textContent="{{ 'group:modal:notifications:switch:1' | translate }}"
                    (value)="notificationsSettings.notification_user_join = $event"></ml-switch>
            </div>
            <div class="form-group mb-3">
                <p class="title">{{ "group:modal:notifications:label:2" | translate }}</p>
                <ml-switch [inputValue]="notificationsSettings.notification_add_object" 
                    textAlignClass="ml-switch-text-right"
                    textContent='{{ "group:modal:notifications:switch:2" | translate }}'
                    (value)="notificationsSettings.notification_add_object = $event"></ml-switch>
            </div>
            <div class="form-group mb-3">
                <p class="title">{{ "group:modal:notifications:label:3" | translate }}</p>
                <ml-switch [inputValue]="notificationsSettings.notification_comment_activity" 
                    textAlignClass="ml-switch-text-right"
                    textContent='{{ "group:modal:notifications:switch:3" | translate }}'
                    (value)="notificationsSettings.notification_comment_activity = $event"></ml-switch>
            </div>
            <div class="form-group mb-3">
                <p class="title">{{ "group:modal:notifications:label:4" | translate }}</p>
                <ml-switch [inputValue]="notificationsSettings.notification_comment_video" 
                    textAlignClass="ml-switch-text-right"
                    textContent='{{ "group:modal:notifications:switch:4" | translate }}'
                    (value)="notificationsSettings.notification_comment_video = $event"></ml-switch>
            </div>
            <div class="form-group mb-3">
                <p class="title">{{ "group:modal:notifications:label:5" | translate }}</p>
                <ml-switch [inputValue]="notificationsSettings.notification_comment_conversation" 
                    textAlignClass="ml-switch-text-right"
                    textContent='{{ "group:modal:notifications:switch:5" | translate }}'
                    (value)="notificationsSettings.notification_comment_conversation = $event"></ml-switch>
            </div>
            <div class="form-group mb-4">
                <p class="title">{{ "group:modal:notifications:label:6" | translate }}</p>
                <ml-switch [inputValue]="notificationsSettings.notification_comment_file" 
                    textAlignClass="ml-switch-text-right"
                    textContent='{{ "group:modal:notifications:switch:6" | translate }}'
                    (value)="notificationsSettings.notification_comment_file = $event"></ml-switch>
            </div>
            <h6 class="text-center description-footer">{{"group:modal:notifications:desc" | translate}}</h6>
            <hr>
            <div class="footer">
                <button class="btn btn-primary" (click)="saveNotificationSettings()" [disabled]="saveLoading"
                    type="submit">
                    {{ 'group:modal:btn:save' | translate }}
                    <fa-icon *ngIf="saveLoading" class="ms-2" [icon]="faCircleNotch" [spin]="true"></fa-icon>
                </button>
            </div>
        </div>
    </form>
</div>