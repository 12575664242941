import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrganizationsService } from 'src/app/services/organizations.service';
import {InitService} from 'src/app/services/init.service';
import { Organization } from 'src/app/interfaces/organization';
import { ApiService } from 'src/app/services/api.service';
import { Video } from 'src/app/interfaces/video/video';
import { VideoEditService } from '../video-edit.service';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-shared-public-form',
  templateUrl: './shared-public-form.component.html',
  styleUrls: ['./shared-public-form.component.scss']
})
export class SharedPublicFormComponent implements OnInit {
  @Output('touch') touch: EventEmitter<boolean> = new EventEmitter();
  @Input('video') video: Video | undefined;
  @Input('modalref') modalref: NgbModalRef | undefined;

  organizations: Array<Organization> = [];
  currentOrg:any=null;

  constructor(
    private orgsService: OrganizationsService,
    private api: ApiService,
    public videoEditService: VideoEditService,
    private toastr: ToastService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.orgsService.getUserOrganizationsMenu().then( organizations => {
      let currentOrg = this.orgsService.getCurrent();

      if (currentOrg && !currentOrg.visible) {
        this.organizations = JSON.parse(JSON.stringify(organizations.filter(  o => o.id != 1 )));
      } else {
        this.organizations = JSON.parse(JSON.stringify(organizations));
      }

      this.organizations.forEach( org => {
        org.checked = false;
        this.video?.organizations?.forEach( videoOrg => {
          if (org.id == videoOrg.id) {
            org.checked = true;
          }
        })
      });
    });
  }

  touched() {
    this.touch.emit(true);
  }

  save() {
    let promises = [];
    //filtering checked organizations
    let orgsIds = this.organizations.filter( o => o.checked ).map( o => o.id );

    promises.push( this.api.patch('/video/' + this.video?.id + '/organization', {
        organization_ids: orgsIds
    }));

    promises.push( this.api.put('/video/' + this.video?.id + '/source', this.videoEditService.source)
      .then((response) => {
        if (response.result && response.result.origin_id) {
          this.videoEditService.source.origin.id = response.result.origin_id;
        }
      })
      .catch((response) => {
        this.toastr.showError('something went wrong updating clip source');
      }));

    if (this.videoEditService.getGroup()) {
      promises.push( this.api.put('/video/' + this.video?.id + '/context', {
          show_on_platform: this.videoEditService.video!.show_on_platform
        }).then( (res: any): void => {
          if (res.result !== 'success') {
            alert('something went wrong updating show on platform');
          }
        })
      );
    }

    return Promise.all(promises).then( res => {
      this.touch.emit(false);
    })
  }

  delete() {
    if ( confirm( this.translate.instant('video:delete:confirm:message') ) ) {
      this.api.delete("/video/" + this.video?.id)
        .then( (response: any) => {
          if (response.result == 'success') {
            this.toastr.showSuccess("Successfully deleted video");
            this.modalref?.close();

            window.location.reload()
          }
          else {
            this.toastr.showError("Something went wrong deleting video");
          }
        })
        .catch( (response) => {
          this.toastr.showError("Something went wrong deleting video");
        });
    }
  };

  changeShowOnPlatform(val: boolean) {
    this.touch.emit(true);
    this.videoEditService.video!.show_on_platform = val;
  }
}
