import { Component, Input, OnInit } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PlMainComponent } from '../main/plmain.component';

@Component({
  selector: 'app-terms-condition-pl',
  templateUrl: './pl-terms-condition.component.html',
  styleUrls: ['./pl-terms-condition.component.scss'],
})
export class PlTermsConditionComponent implements OnInit {
  faTimes = faTimes;
  @Input() description: string = '';
  @Input() selfModal: NgbModalRef | null = null;
  @Input() title: String = '';
  loadSpin: boolean = false;
  faCircleNorch: any = faCircleNotch;
  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
    this.openModalRegister();
  }
  public openModalRegister() {
    let modalRef = this.modalService.open(PlMainComponent, {
      windowClass: 'login-modal',
    });
    modalRef.componentInstance.state = 'register';
    modalRef.componentInstance.selfModal = modalRef;
  }
}
