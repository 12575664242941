import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Video } from 'src/app/interfaces/video/video';
import { ApiService } from 'src/app/services/api.service';
import { ToastService } from 'src/app/services/toast.service';
import { VideoEditService } from '../video-edit.service';

@Component({
  selector: 'app-category-form',
  templateUrl: './category-form.component.html',
  styleUrls: ['./category-form.component.scss']
})
export class CategoryFormComponent implements OnInit {
  @Output('touch') touch: EventEmitter<boolean> = new EventEmitter();
  @Input('video') video: Video | undefined;

  categories: Array<any> = []

  constructor(
    private api: ApiService,
    public videoEditService: VideoEditService,
    private toastr: ToastService
  ) {
  }

  ngOnInit(): void {
  }

  touched() {
    this.touch.emit(true)
  }

  save() {
    let categoryPromises: Array<any> = [];

    this.videoEditService.categories.forEach( (category: any) => {
      if (category.selected !== category.selectedBeforeUpdate) {
        if (!category.selected) {
          // Delete parent category tag
          categoryPromises.push(this.api.delete("/video/" + this.video?.id + "/analysis/" + category.analysis_record_id).then( (resp: any) => {
            if (resp.result === 'success') {
              category.selectedBeforeUpdate = category.selected;
            }
            return resp;
          }));
        } else if (category.selected) {
          // Post tag
          var apiTag = {
            tag_type : "user",
            tag_id : category.tag_id
          };

          categoryPromises.push(this.api.post("/video/" + this.video?.id + "/analysis/tag", apiTag).then( (resp) => {
            if (resp.result === 'success') {
              // If the API results succes we can append our added tag to the userTagsBeforeChanges variable
              category.analysis_record_id = resp.video.analysis.id;
              category.selectedBeforeUpdate = category.selected;
            }

            return resp;
          }));
        }
      }

      category.subcategories.forEach( (subcategory: any) => {
        if (subcategory.selected !== subcategory.selectedBeforeUpdate) {
          if (!subcategory.selected) {
            // Delete tag
            categoryPromises.push(this.api.delete("/video/" + this.video?.id + "/analysis/" + subcategory.analysis_record_id).then( (resp: any) => {
              if (resp.result === 'success') {
                subcategory.selectedBeforeUpdate = subcategory.selected;
              }
              return resp;
            }));
          } else if (subcategory.selected) {
            // Post tag
            var apiTag = {
              tag_type : "user",
              tag_id : subcategory.tag_id
            };

            categoryPromises.push(this.api.post("/video/" + this.video?.id + "/analysis/tag", apiTag).then( (resp: any) => {
              if (resp.result === 'success') {
                // If the API results succes we can append our added tag to the userTagsBeforeChanges variable
                subcategory.analysis_record_id = resp.video.analysis.id;
                subcategory.selectedBeforeUpdate = subcategory.selected;
              }

              return resp;
            }));
          }
        }
      });
    });

    return Promise.all(categoryPromises).then( (results: any) => {
      if (results.filter( (r: any) => r.result == 'error' ).length > 0) {
        this.toastr.showError('something went wrong updating categories');
      } else if (results.filter( (r: any) => r.result == 'success' ).length == results.length) {
        this.touch.emit(false)
      } else {
        this.toastr.showError('unknown error. something went wrong updating categories');
      }
    }).catch( (err) => {
      this.toastr.showError('unknown error. something went wrong updating categories');
    });
  }
}
