<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title">
    {{ "collection:modal:duplicate:playlist:title" | translate }}
  </h4>
</div>
<div class="modal-body flex-column">
  <div class="row">
    <p class="description mb-3">
      {{ "collection:modal:duplicate:playlist:text:1" | translate }}
    </p>
    <p class="description">
      {{ "collection:modal:duplicate:playlist:text:2" | translate }}
    </p>
  </div>
  <div class="row">
    <label class="form-label mb-3">
      {{ "collection:modal:duplicate:playlist:text:3" | translate }}
    </label>
    <app-loading-icon *ngIf="loading; else content"></app-loading-icon>
    <ng-template #content>
      <div class="col-9 organization-list" *ngFor="let organization of org">
        <label class="fw-bold" [for]="organization.name + 'modal'">
          <input
            [disabled]="organization.visible"
            class="form-check-input"
            type="checkbox"
            [(ngModel)]="organization.checked"
            [name]="organization.name + 'modal'"
            [id]="organization.name + 'modal'"
          />
          {{ organization.name }}
          <span class="text-muted fw-light">
            {{ organization.visible ? " (public)" : "" }}
          </span>
        </label>
      </div>
    </ng-template>
  </div>
</div>
<div class="modal-footer">
  <div class="d-flex justify-content-end">
    <button
      (click)="sendOrg()"
      type="button"
      role="tab"
      class="btn btn-success col-auto"
    >
      <fa-icon
        class="mr-2"
        [icon]="faCircleNorch"
        *ngIf="loading"
        [spin]="true"
      ></fa-icon
      >{{ "collection:modal:duplicate:playlist:button:duplicate" | translate }}
    </button>
  </div>
</div>
