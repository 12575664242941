<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon>
  <span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">
    {{ "title:develop:modal" | translate }}
  </h4>
</div>
<div class="modal-body">
  <div class="row justify-content-start">
    <div class="col-12">
      <p>
        {{ "develop:modal:sub:1" | translate }}
        <span class="fw-bold course-text">
          {{ course?.name }}
        </span>
        {{ "develop:modal:sub:2" | translate }}
      </p>
    </div>
  </div>
  <div *ngIf="loading" class="row justify-content-center">
    <app-loading-icon></app-loading-icon>
  </div>
  <div
    *ngIf="!loading"
    class="flex-column flex-md-row d-flex justify-content-center"
  >
    <div class="col-auto" style="max-width: 360px;">
      <div class="card-border">
        <p class="mx-auto text-center my-2 fw-bold">
          {{ "develop:modal:buy" | translate }}
          {{ priceCourse | currency : "EUR" : "symbol" : "1.0-2" }}
        </p>
        <div class="row justify-content-center">
          <app-course-card
            *ngIf="courseAux"
            [fromCompetencies]="true"
            (sendPrice)="getPrice($event)"
            [course]="courseAux"
            [featured]="false"
          ></app-course-card>
        </div>
      </div>
      <div class="col-12 my-2">
        <button
          type="button"
          style="width: 100%"
          class="btn btn-primary"
          (click)="goToCourse()"
        >
          {{ "develop:modal:learn" | translate }}
        </button>
      </div>
      <div class="col-12">
        <button
          [disabled]="loading"
          type="button"
          style="width: 100%"
          class="btn btn-primary"
          (click)="openCheckout()"
        >
          {{ "develop:modal:checkout" | translate }}
        </button>
      </div>
    </div>

    <div class="col-auto text-center my-sm-auto mx-auto my-3">
      <h5 class="mb-0">{{ "OR" | translate }}</h5>
    </div>

    <div class="col-12 text-center" style="max-width: 360px;">
      <div class="card-border">
        <p class="mx-auto text-center my-2 fw-bold">
          {{ "develop:modal:buy:2" | translate }}
          {{
            compentencyPrice
              | currency
                : "EUR"
                : "symbol"
                : (compentencyPrice % 1 === 0 ? "1.0-2" : "1.2-2")
          }}
        </p>
        <img class="img-graph" src="assets/img/dh-profile/img-graph.png" />
        <div class="col-10 mx-auto">
          <p>
            {{ "develop:modal:focus" | translate }}
          </p>
        </div>
      </div>
      <div class="col-12 my-2">
        <button
          [disabled]="loading"
          (click)="selectParts()"
          style="width: 100%"
          type="button"
          class="btn btn-primary"
        >
          {{ "develop:modal:select" | translate }}
        </button>
      </div>
      <div class="col-12"></div>
    </div>
  </div>
</div>
