import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faCheckCircle, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Organization } from 'src/app/interfaces/organization';
import { AuthService } from 'src/app/services/auth.service';
import { InitService } from 'src/app/services/init.service';
import { ToastService } from 'src/app/services/toast.service';
import { lowerCount, numberCount, specialCount, upperCount } from '../login/login/login.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  @Output('close') close = new EventEmitter();
  @Output('success') success = new EventEmitter();
  @Input() hash: string | undefined;
  @Input() selfModal: NgbModalRef | undefined;

  organization: Organization | null = null;
  resetForm: UntypedFormGroup;

  loading = false;

  faCircleNorch = faCircleNotch;
  faCheckCircle = faCheckCircle;

  constructor(
    private fb: UntypedFormBuilder,
    private toastr: ToastService,
    private translate: TranslateService,
    private initService: InitService,
    private auth: AuthService,
    private router: Router
  ) {
    this.resetForm = this.fb.group({
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          lowerCount(),
          upperCount(),
          numberCount(),
          specialCount(),
        ],
      ]
    });
  }

  ngOnInit(): void {
    this.organization = this.initService.getCurrentOrg();
  }

  closeModal() {
    this.selfModal?.close();
  }

  reset() {
    if (this.resetForm.valid) {
      this.loading = true;
      let password = this.resetForm.get('password')!.value;

      this.auth.resetPassword(this.hash!, password).then( res => {
        this.toastr.showSuccess(this.translate.instant('reset:password:success'));
        this.router.navigateByUrl('/login');
        this.closeModal();
      }).catch( err => {
        if (err.error.message.type == 'EqualPasswords'){
          this.toastr.showError(err.error.message.friendly);
        }else {
          this.toastr.showError(this.translate.instant('reset:password:error'));
        }
      }).finally( () => {
        this.loading = false;
      });
    } else {
      if ( this.resetForm.controls['password'].errors ) {
        for (const i in this.resetForm.controls) {
          this.resetForm.controls[i].markAsTouched();
        }

        if (this.resetForm.controls['password'].hasError('required')) {
          this.toastr.showError(
            this.translate.instant('sign_up:password:required')
          );
          return;
        }
      }
    }
  }
}
