import { Component, Input, OnInit } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { GroupsService } from 'src/app/services/groups.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss']
})
export class NotificationSettingsComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() collection: any = {};

  faTimes = faTimes;
  faCircleNotch = faCircleNotch;
  showLoading = false;
  saveLoading = false;
  groupSettingsStatus = 'unsave';

  notificationsSettings = {
    notification_user_join: false,
    notification_add_object:false,
    notification_comment_activity: false,
    notification_comment_video: false,
    notification_comment_conversation: false,
    notification_comment_file: false,
  }

  constructor(
    private groupsService: GroupsService,
    private toastService:ToastService,
    private translateService:TranslateService,
    ) {
  }

  ngOnInit(): void {
    this.showLoading = true;
    this.groupsService.getNotifyConfig(this.collection.id)
    .then((res)=>{
        // console.log(res);
        this.notificationsSettings = res.data;
        // console.log(this.notificationsSettings);
        this.showLoading=false;
    })
  }
  closeModal(){
    if(this.selfModal) this.selfModal?.close();
    else $("ngb-modal-window").click();
  }

  saveNotificationSettings(){
    this.saveLoading = true;
    this.groupsService.putNotifyConfig(this.collection.id, this.notificationsSettings)
        .then((res)=>{
            this.toastService.showSuccess(this.translateService.instant('my_profile:settings:notifications:conf:success'));      
            this.saveLoading = false;
            this.closeModal();
        });
  }
}
