<div class="main-container" *ngIf="!loading">
  <div class="fixed-layout">
    <app-header
      [hidden]="hideSidebar"
      [ngClass]="{ 'sticky-header': hideClass }"
      [organizations]="organizations"
      (sidebarToggled)="toggleSidebar()"
    ></app-header>
    <app-sidebar
      [organizations]="organizations"
      [state]="sidebarState"
      (toggle)="toggleSidebar()"
    ></app-sidebar>
  </div>
  <app-toasts-container></app-toasts-container>
  <div class="page-content">
    <app-confirm-email
      *ngIf="!showConfirmEmail"
      [email]="email"
    ></app-confirm-email>
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</div>
