<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title">
    {{
      "dashboard:admin:modal:delete:users:title"
        | translate: { number: numbAccounts }
    }}
  </h4>
</div>
<div class="modal-body flex-column">
  <div class="row">
    <p class="description">
      {{ "dashboard:admin:modal:delete:users:text:first" | translate }}
    </p>
  </div>
  <div class="row">
    <p class="description">
      {{ "dashboard:admin:modal:delete:users:text:second" | translate }}
    </p>
  </div>
  <div class="row">
    <div class="col-6">
      <button type="button" role="tab" class="btn btn-primary text-nowrap">
        <fa-icon class="mr-2"></fa-icon>
        {{
          "dashboard:admin:modal:delete:users:change:ownership:button"
            | translate
        }}
      </button>
    </div>
  </div>
  <div class="row mt-3">
    <p class="fw-bold">
      {{
        "dashboard:admin:modal:delete:users:change:ownership:info" | translate
      }}
    </p>
  </div>

  <div class="form-group my-2">
    <label class="modal-label fw-bold" for="clip-title">{{
      "users:remove:account:modal:enter:password:superuser" | translate
    }}</label>
    <input
      type="password"
      [(ngModel)]="password"
      class="input-box w-100 mt-2"
      id="clip-title"
      maxlength="80"
      name="Password"
      placeholder="Write password here"
    />
  </div>
</div>
<div class="row justify-content-end modal-footer">
  <hr class="common-separator my-0" />
  <div class="row justify-content-end text-center">
    <div class="col-2">
      <button
        type="button"
        class="btn btn-outline-primary ms-2 fs-15 text-nowrap"
        (click)="closeModal()"
      >
        {{ "Cancel" | translate }}
      </button>
    </div>
    <div class="col-4">
      <button
        [disabled]="password == '' || loadSpin"
        (click)="deleteMembers()"
        type="button"
        role="tab"
        class="btn btn-danger text-nowrap"
        
      >
        <fa-icon
          class="mr-2"
          [icon]="faCircleNorch"
          *ngIf="loadSpin"
          [spin]="true"
        ></fa-icon>
        {{
          "dashboard:admin:modal:delete:users:confirm"
            | translate: { number: numbAccounts }
        }}
      </button>
    </div>
  </div>
</div>
