import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-enrolled-stats',
  templateUrl: './enrolled-stats.component.html',
  styleUrls: ['./enrolled-stats.component.scss'],
})
export class EnrolledStatsComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() course: any;
  @Output() refresh = new EventEmitter<any>();
  public faTimes = faTimes;
  public loadSpin: boolean = false;
  public faCircleNorch: any = faCircleNotch;
  public users: any;
  public exParticipants: any;
  constructor(
    private organizationService: OrganizationsService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.getParticipants();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  public getParticipants() {
    this.users = this.course.users.filter((item: any) => {
      return item.status == 'accepted';
    });
  }

  public getExParticipants() {
    this.users = this.course.users.filter((item: any) => {
      return item.status != 'accepted';
    });
  }
}
