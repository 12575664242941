import { Injectable } from '@angular/core';
import { User } from '../interfaces/user';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PlaylistsService {
  private groupNamesubject = new BehaviorSubject<string>('');
  user: User | null = null;

  constructor(private api: ApiService, private auth: AuthService) {
    this.auth.user.subscribe((user) => {
      this.user = user;
    });
  }
  setNameGroup(data: string) {
    this.groupNamesubject.next(data);
  }

  getNameGroup(): Observable<string> {
    return this.groupNamesubject.asObservable();
  }
  async getPublic(
    limit: number | null = 50,
    page: number | null = 1,
    order: string | null,
    search: string | null
  ) {
    if (!order) {
      order = 'RELEVANCE';
    }

    try {
      let query = `?type=playlist&owner=&limit=${limit}&page=${page}&search=${
        search || ''
      }&order=${order}&open=both&section=all&public=true`;
      const playlists = await this.api.get('/collection' + query);
      return playlists;
    } catch (error) {
      return error;
    }
  }

  async getEnrolled(
    limit: number | null = 50,
    page: number | null = 1,
    order: string | null,
    search: string | null
  ) {
    order = 'DESC';

    try {
      let query = `?type=playlist&limit=${limit}&page=${page}&search=${
        search || ''
      }&order=${order}&owner=&section=mine&joined=true`;
      const playlists = await this.api.get('/collection/enrolled' + query);
      return playlists;
    } catch (error) {
      return error;
    }
  }

  async getOwn(
    limit: number | null = 50,
    page: number | null = 1,
    order: string | null,
    search: string | null
  ) {
    order = 'DESC';
    try {
      console.log('linea 66', this.user?.username);
      // https://api.move-e-learning.com/collection?type=playlist&owner=gustavofiv&limit=9&page=1&search=&order=RELEVANCE&section=mine
      let query = `?type=playlist&limit=${limit}&page=${page}&search=${
        search || ''
      }&order=RELEVANCE&owner=${this.user?.username}&section=mine`;
      // /user/doliviernu1keetupcom/playlists?username=doliviernu1keetupcom
      const playlists = await this.api.get(
        `/user/${this.user?.username}/playlists?username=${this.user?.username}`
      );
      return playlists;
    } catch (error) {
      return error;
    }
  }

  getProfile(id: string) {
    return this.api.get('/collection/' + id).then((res) => {
      return res;
    });
  }

  async getProfileEntity(
    id: string,
    limit: number | null = 10,
    page: number | null = 1
  ) {
    try {
      const playlists = await this.api.get('/collection/' + id + '/entity');
      return playlists;
    } catch (error) {
      return error;
    }
  }

  async patchUnenrollPlaylist(id: string) {
    try {
      const patch_unenroll = await this.api.patch(
        '/collection/' + id + '/users/unenroll'
      );
      return patch_unenroll;
    } catch (error) {
      return error;
    }
  }

  async patchEnrollPlaylist(id: string, status: any) {
    try {
      if (status == 'accept_invitation') {
        let patch_enroll = await this.api.post(
          '/collection/' + id + '/invite/accept',
          {}
        );
        return patch_enroll;
      } else {
        let patch_enroll = await this.api.patch(
          '/collection/' + id + '/users/enroll'
        );
        return patch_enroll;
      }
    } catch (error) {
      return error;
    }
  }

  ////////////////
  // Edit Playlist
  ///////////////

  async uploadImgplaylist(courseId: number, file: File): Promise<any> {
    let formData = new FormData();
    formData.append('file', file);
    return this.api.put(`/collection/${courseId}`, formData);
  }

  patchEditPlaylist(playlistId: string, dataEdit: {}) {
    return this.api.put(`/collection/${playlistId}`, dataEdit);
  }

  async patchPlaylistOrganization(
    id: string,
    organizations: any,
    check: boolean
  ) {
    let url = '';
    if (check) {
      url = '/collection/' + id + '/share';
    } else {
      url = '/collection/' + id + '/unshare';
    }
    try {
      const resp = await this.api.patch(url, { organizations });
      return resp;
    } catch (error) {
      return error;
    }
  }

  public async deletePlaylist(playlistId: string) {
    try {
      let url = `/collection/${playlistId}`;
      const deleteCourse = await this.api.delete(url);
      return deleteCourse;
    } catch (error) {
      return error;
    }
  }
  public async deleteFromGroup(playlistId: string, groupId: string) {
    try {
      let url = `/collection/${groupId}/entity`;
      const deleteItem = await this.api.delete(url, '', {
        entity_ids: [playlistId],
      });
      return deleteItem;
    } catch (error) {
      return error;
    }
  }
  public async deleteFileFromGroup(fileId: string, groupId: string) {
    try {
      let url = `/file/${fileId}`;
      const deleteItem = await this.api.delete(url);
      return deleteItem;
    } catch (error) {
      return error;
    }
  }

  //////////////////////
  //Edit video playlist
  /////////////////////
  async editVideoPlaylist(videoId: number, dataVideo: any) {
    let entities = [
      {
        name: dataVideo.name,
        description: dataVideo.description,
        description_alt: dataVideo.description_alt,
        id: dataVideo.id,
      },
    ];
    try {
      const resp = await this.api.put(`/collection/${videoId}/entity`, {
        entities,
      });
      return resp;
    } catch (error) {
      return error;
    }
  }
  async updatePosition(videoId: number, dataPosition: any) {
    let entities = dataPosition;
    try {
      const resp = await this.api.put(`/collection/${videoId}/entity`, {
        entities,
      });
      return resp;
    } catch (error) {
      return error;
    }
  }

  public async deleteVideoPlaylist(id: number, entity_id: any) {
    try {
      console.log(id, entity_id);

      let url = `/collection/${id}/entity`;
      const deleteEntity = await this.api.delete(url, '', {
        entity_ids: [entity_id],
      });
      return deleteEntity;
    } catch (error) {
      return error;
    }
  }

  async postVideoEntity(id: number, videoEntityId: any) {
    try {
      let sendEntity: any = { entity_id: videoEntityId, type: 'video' };

      const postVideoEntity = await this.api.postJson(
        `/collection/${id}/entity`,
        {
          entities: videoEntityId,
        }
      );
      return postVideoEntity;
    } catch (error) {
      return error;
    }
  }
  async publishPlaylist(id: string, isPublic: any) {
    try {
      let resp = {
        public: isPublic,
      };
      const publishPlay = await this.api.patch(
        '/collection/' + id + '/publish',
        resp
      );
      return publishPlay;
    } catch (error) {
      return error;
    }
  }

  getPendingRequests(id: number) {
    return this.api
      .get('/collection/' + id + '/users/pending')
      .then((res: any) => {
        return res.data.enrolled_users;
      });
  }

  confirmAccessRequest(playlistId: number, requestId: number) {
    return this.api
      .patch('/collection/' + playlistId + '/enrolled/' + requestId + '/accept')
      .then((res: any) => {
        return res.data.message;
      });
  }

  denyAccessRequest(playlistId: number, requestId: number) {
    return this.api
      .patch('/collection/' + playlistId + '/enrolled/' + requestId + '/deny')
      .then((res: any) => {
        return res.data.message;
      });
  }
  orderPlaylist(playlistId: string | null, entities: any) {
    return this.api
      .put('/collection/' + playlistId + '/entity', {
        entities,
      })
      .then((res: any) => {
        return res.data;
      });
  }
  async getMembers(playlistId: number) {
    try {
      const members = await this.api.get(
        `/collection/${playlistId}/users/accepted`
      );
      return members;
    } catch (error) {
      return error;
    }
  }
  async getMembersInvited(playlistId: number) {
    try {
      const members = await this.api.get(
        `/collection/${playlistId}/users/invited`
      );
      return members;
    } catch (error) {
      return error;
    }
  }

  public async deleteMemberEnrolled(playlistId: number, memberId: number) {
    try {
      let url = `/collection/${playlistId}/enrolled/${memberId}`;
      const memberDeleted = await this.api.delete(url);
      return memberDeleted;
    } catch (error) {
      return error;
    }
  }

  async sendMessage(user: any, message: string, organizatios: any) {
    return this.api
      .patch('/user/' + user + '/organizations', {
        message,
        organizations_id: organizatios,
      })
      .then((res: any) => {
        return res.data.message;
      });
  }
  async getOrg(user: any) {
    try {
      const members = await this.api.get(`/organization/username/${user}`);
      return members;
    } catch (error) {
      return error;
    }
  }
  async sendDuplicate(playlistId: string, organizations: any) {
    try {
      let duplicate = await this.api.postJson(
        '/collection/' + playlistId + '/duplicate',
        { organizations: organizations }
      );
      return duplicate;
    } catch (error) {
      return error;
    }
  }
  async changeOwner(collection: any, userId: any) {
    try {
      let change = await this.api.patch(
        '/collection/' + collection.id + '/users/owner',
        { user_id: userId }
      );
      return change;
    } catch (error) {
      return error;
    }
  }
}
