<div class="container-review p-4 mx-4 mx-md-0">
  <div class="row text-start">
    <h4 class="mb-3 title-review">
      {{ "preview:lesson:title:1" | translate }}
    </h4>
  </div>
  <div
    *ngFor="let course of arrayReviews"
    class="continue-watching row mx-auto"
  >
    <div class="col-md-4 px-0 img-course">
      <div
        (click)="goTopreview(course?.courseId, course?.lessonId, course)"
        *ngIf="course.img_src; else errorimg"
        class="the-image"
        [ngStyle]="{
          'background-image': 'url(' + course.img_src + ')'
        }"
      ></div>
      <ng-template #errorimg>
        <div
          (click)="goToCourse(course?.courseId, course?.lessonId)"
          class="the-image"
          [ngStyle]="{
            'background-image': 'url(assets/img/groups/icons/list.png)'
          }"
        ></div>
      </ng-template>
    </div>
    <div class="col-md-8 px-3 custom-p">
      <div class="row">
        <div class="col-12 title-continue px-md-0">
          <h5 class="name-lesson mb-3">
            <span class="from">
              {{ "preview:lesson:title:2" | translate }}
            </span>
            {{ course?.name }}
          </h5>
          <h5 class="name-course">
            <span class="from">
              {{ "preview:lesson:title:3" | translate }}
            </span>
            {{ course?.courseName }}
          </h5>
        </div>
        <div class="col mt-2 px-0">
          <div class="row justify-content-md-start justify-content-center">
            <!-- <div class="col-auto align-items-center d-flex">
              <p class="text-muted mb-0">{{ "" }}</p>
            </div> -->
            <div class="col-auto mt-4 px-0">
              <button
                type="button"
                class="btn btn-outline-primary ms-2 fs-15 text-nowrap"
                (click)="
                  goTopreview(course?.courseId, course?.lessonId, course)
                "
              >
                {{ "preview:lesson:title:4" | translate }}
              </button>
              <button
                type="button"
                class="btn btn-primary ms-2 fs-15 text-nowrap"
                (click)="goToCourse(course?.courseId, course?.lessonId)"
              >
                {{ "preview:lesson:go:course" | translate }}
              </button>
            </div>
          </div>

          <!-- <div class="row justify-content-between mt-2">
            <div class="col-auto align-items-center d-flex">
              <p class="text-muted mb-0">{{ "" }}</p>
            </div>
            <div class="col-auto align-items-center d-flex">
              <button
              type="button"
              class="btn btn-primary ms-2 fs-15 text-nowrap"
              (click)="goToCourse(course?.courseId, course?.lessonId)"
            >
            {{ "preview:lesson:go:course" | translate }}
            </button>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="separator my-2"></div>
  </div>
</div>
