<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">
    {{ "admin:users:modal:discountEdit" | translate }}
  </h4>
</div>
<div class="modal-body">
  <div class="form-group">
    <label class="modal-label" for="clip-title">{{
      "admin:users:modal:discountProfile:label:2" | translate
    }}</label>
    <input
      [(ngModel)]="infoDiscount.code"
      class="input-box w-100"
      id="clip-title"
      maxlength="80"
      name="name"
      placeholder="{{ '' | translate }}"
      required=""
      type="text"
    />
  </div>

  <div class="form-group d-flex mt-3">
    <div class="col-6">
      <label for="clip-title" class="modal-label">{{
        "admin:users:modal:discountProfile:label:3" | translate
      }}</label>
      <input
        [disabled]="firstCheck == true"
        [(ngModel)]="infoDiscount.total_available"
        class="input-number form-control"
        type="number"
        min="0"
        max="1000"
        placeholder="0"
      />
    </div>
    <div class="col-6 align-check">
      <label>
        <input
          [(ngModel)]="firstCheck"
          [ngModelOptions]="{ standalone: true }"
          (change)="changeInfinite()"
          type="checkbox"
          class="form-check-input offset-1"
          id="infinite"
        />
      </label>
      <p class="mx-2 fw-bold mt-2">
        {{ "admin:users:modal:discountProfile:check:1" | translate }}
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <label for="clip-title" class="d-block mt-3 modal-label">{{
        "admin:users:modal:discountProfile:label:8" | translate
      }}</label>
      <select [(ngModel)]="infoDiscount.type" class="pure-input">
        <option value="percentage" selected="selected">
          {{ "Percentage" | translate }}
        </option>
        <option value="fixed">
          {{ "Fixed" | translate }}
        </option>
      </select>
    </div>
    <div class="col-4">
      <label for="clip-title" class="d-block mt-3 modal-label">{{
        "admin:users:modal:discountProfile:label:4" | translate
      }}</label>
      <input
        [(ngModel)]="infoDiscount.amount"
        class="input-number form-control"
        type="number"
        min="0"
        max="1000"
        placeholder=""
      />
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <label for="clip-title" class="d-block mt-3 modal-label">{{
        "Course" | translate
      }}</label>
      <div *ngIf="courseSelected != ''" class="col-auto">
        <p class="course-item d-flex justify-content-between">
          <span>
            {{ courseSelected?.name | excerpt : 25 }}
          </span>
          <fa-icon
            (click)="unselect()"
            class="icon me-1"
            [icon]="faTimes"
          ></fa-icon>
        </p>
      </div>
    </div>
    <div *ngIf="courseSelected == ''" class="col-8">
      <app-search-courses
        (searchEvent)="getCourse($event)"
      ></app-search-courses>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <label for="clip-title" class="d-block mt-3 modal-label">{{
        "admin:users:modal:discountProfile:label:6" | translate
      }}</label>
      <input
        [(ngModel)]="infoDiscount.valid_from"
        class="input-number form-control"
        type="text"
        placeholder="dd/mm/yyyy"
      />
    </div>
    <div class="col-4">
      <label for="clip-title" class="d-block mt-3 modal-label">{{
        "admin:users:modal:discountProfile:label:7" | translate
      }}</label>
      <input
        [disabled]="secondCheck == true"
        [(ngModel)]="infoDiscount.valid_until"
        class="input-number form-control"
        type="text"
        placeholder="dd/mm/yyyy"
      />
    </div>
    <div class="col-4 align-check-2">
      <label class="">
        <input
          [(ngModel)]="secondCheck"
          [ngModelOptions]="{ standalone: true }"
          (change)="changeExpires()"
          type="checkbox"
          class="form-check-input offset-1"
          id="infinite"
        />
      </label>
      <p class="mx-2 fw-bold mt-2">
        {{ "admin:users:modal:discountProfile:check:2" | translate }}
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <div class="form-group clearfix">
      <button
        (click)="updateDiscount()"
        type="button"
        role="tab"
        class="btn btn-primary float-right ms-2 fs-15"
      >
        <fa-icon
          class="mr-2"
          [icon]="faCircleNorch"
          *ngIf="loadSpin"
          [spin]="true"
        ></fa-icon>
        {{ "admin:users:modal:discountEdit:btn:save" | translate }}
      </button>
    </div>
  </div>
</div>
