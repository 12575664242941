<div class="box-levels">
  <div *ngIf="showTooltip && dhprofiles.length == 0" class="tooltip1">
    <p class="mb-0">
      {{ "Use this button to go to the Profile overview page." | translate }}
    </p>
  </div>
  <img
    *ngIf="addJoy"
    (click)="goBack('')"
    id="elementjoy4"
    joyrideStep="fourthStep"
    [title]="'steponboarding4title' | translate"
    [text]="'steponboarding4' | translate"
    class="logo"
    src="/assets/img/dh-multi-profile/healix-logo.png"
  />
  <img
    *ngIf="!addJoy"
    (click)="goBack('')"
    id="elementjoy4"
    class="logo"
    src="/assets/img/dh-multi-profile/healix-logo.png"
  />
  <img
    (click)="select(levels[6], 7)"
    class="helix helix-1"
    src="/assets/img/dh-multi-profile/helix-1.png"
  />
  <img
    (click)="select(levels[5], 6)"
    class="helix helix-2"
    src="/assets/img/dh-multi-profile/helix-2.png"
  />
  <img
    (click)="select(levels[2], 5)"
    class="helix helix-3"
    src="/assets/img/dh-multi-profile/helix-3.png"
  />

  <img
    (click)="fromLevels ? goBack('4') : select(levels[3], 3)"
    class="helix helix-4"
    [src]="
      levels[3]?.info
        ? '/assets/img/dh-multi-profile/helix-4-on.png'
        : '/assets/img/dh-multi-profile/helix-4.png'
    "
  />
  <img
    (click)="fromLevels ? goBack('3') : select(levels[4], 4)"
    class="helix helix-5"
    [src]="
      levels[4]?.info
        ? '/assets/img/dh-multi-profile/helix-5-on.png'
        : '/assets/img/dh-multi-profile/helix-5.png'
    "
  />

  <img
    (click)="select(levels[1], 2)"
    class="helix helix-6"
    src="/assets/img/dh-multi-profile/helix-6.png"
  />
  <img
    (click)="select(levels[0], 1)"
    class="helix helix-8"
    src="/assets/img/dh-multi-profile/helix-7.png"
  />

  <img class="logo-gray" src="/assets/img/dh-multi-profile/logo-gray.png" />
  <!-- <img class="levels" src="/assets/img/dh-multi-profile/levels-box.png" /> -->
</div>
