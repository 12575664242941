<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title">
    {{ "video:duplicate:modal:title" | translate }}
  </h4>
</div>
<div class="modal-body">
  <p class="text-muted">{{ "video:duplicate:modal:intro" | translate }}</p>
  <div class="form-check">
    <input
      tabindex="-1"
      id="check1"
      type="checkbox"
      class="form-check-input"
      [(ngModel)]="infoDuplicate.details"
      checked="false"
    />
    <label for="check1" class="form-check-label fw-500 fs-14">
      {{ "video:duplicate:data:detail" | translate }}
    </label>
  </div>
  <div class="form-check">
    <input
      disabled
      id="check2"
      type="checkbox"
      class="form-check-input"
      [(ngModel)]="infoDuplicate.analysis"
      checked="false"
    />
    <label for="check2" class="form-check-label fw-500 fs-14">
      {{ "video:duplicate:data:analysis" | translate }}
    </label>
  </div>
</div>
<div class="modal-footer mt-0">
  <div class="row justify-content-end">
    <div class="col-auto px-0">
      <button
        type="button"
        class="btn btn-primary ms-2 fs-15 text-nowrap"
        (click)="duplicate()"
      >
        <fa-icon
          [icon]="faCircleNorch"
          *ngIf="loadSpin"
          [spin]="true"
        ></fa-icon>
        {{ "video:duplicate:button:label" | translate }}
      </button>
    </div>
  </div>
</div>
