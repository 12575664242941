import { animate, style, transition, trigger } from '@angular/animations';
import { Component, TemplateRef } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-toasts-container',
  templateUrl: './toasts-container.component.html',
  host: {'[class.ngb-toasts]': 'true'},
  styleUrls: ['./toasts-container.component.scss'],
  animations: [
    trigger(
      'inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0, 'margin-top': '-50px' }),
            animate(
              '.5s ease-out',
              style({ opacity: 1, 'margin-top': '0' })
            )
          ]
        )
      ]
    )
  ]
})
export class ToastsContainerComponent {
  show = true;
  constructor(
    public toastService: ToastService
  ) {
    this.toastService.toasts = [];
  }

  isTemplate(toast: any) { return toast.textOrTpl instanceof TemplateRef; }
  close() {
    this.show = false;
    setTimeout(() => this.show = true, 3000);
  }
}
