<div class="modal-close"  (click)="closeModal()">
    <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon><span >{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
    <h4 class="modal-title">{{"course:create:modal:title"|translate}}</h4>
</div>
<div class="modal-body">
    <div class="form-group">
        <label class="form-label" for="clip-title">{{"course:edit:name:label"|translate}}</label>
        <input [(ngModel)]="course.name" class="input-box w-100" id="clip-title" maxlength="80" name="name" placeholder="{{'course:edit:name:placeholder'|translate}}" required="" type="text">
    </div>
    <div class="form-group">
      <label class="form-label mt-3">{{ "Subtitle" | translate }}</label>
      <input [(ngModel)]="course.subtitle" class="input-box w-100" maxlength="85" placeholder="{{'Subtitle'|translate}}" required="">
    </div>
    <div class="form-group">
        <label for="clip-title" class="d-block mt-3 form-label">{{"courseCreation:language:title"|translate}}</label>
        <select class="pure-input" [(ngModel)]="course.language">
            <option value="nl" selected="selected">{{"language:options:nl"|translate}}</option>
            <option value="en">{{"language:options:en"|translate}}</option>
        </select>
    </div>
    <hr class="common-separator">
    <div class="form-group">
        <label class="form-label" for="clip-title">{{"course:edit:settings:label"|translate}}</label>
        <label class="fancy-radio-checkbox form-label">
            <input [(ngModel)]="course.open" class="fancy-input" id="open" name="privacyOpen"  type="radio" [value]="true">
            <div class="fancy-graphic"></div>
            {{"course:edit:settings:open"|translate}}
        </label>
        <div class="small-form-text">{{"course:edit:settings:open:description"|translate}}</div>
        <label class="fancy-radio-checkbox form-label">
            <input [(ngModel)]="course.open" class="fancy-input" id="closed" name="privacyOpen"  type="radio" value=false>
            <div class="fancy-graphic"></div>
            {{"course:edit:settings:closed"|translate}}
        </label>
        <div class="small-form-text">{{"course:edit:settings:closed:description"|translate}}</div>
    </div>
</div>
<div class="modal-footer">
    <div class="form-group clearfix">
        <button [disabled]="course.name.length<4" (click)="create()" type="button" role="tab" class="btn btn-primary btn-create float-right ms-2 fs-15">
            <fa-icon class="mr-2" [icon]="faCircleNorch" *ngIf="loadSpin"  [spin]="true"></fa-icon>
            {{"course:edit:save:button"|translate}}</button>
    </div>
</div>

