<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title">
    {{ "course:delete:modal:title" | translate }}
  </h4>
</div>
<div class="modal-body flex-column">
  <div class="row">
    <p class="description">
      {{ "course:delete:modal:description:1a" | translate }}
      <span class="fw-bold">
        {{ course?.name }}
      </span>
      ?
      {{ "course:delete:modal:description:1b" | translate }}
    </p>
    <ul class="ms-4">
      <li>
        <p class="description">
          {{ "course:delete:modal:description:2" | translate }}
        </p>
      </li>
      <li>
        <p class="description">
          {{ "course:delete:modal:description:3" | translate }}
        </p>
      </li>
      <li>
        <p class="description">
          {{ "course:delete:modal:description:4" | translate }}
        </p>
      </li>
    </ul>
    <p class="description">
      {{ "course:delete:modal:description:5" | translate }}
    </p>
  </div>
</div>
<div class="row justify-content-end modal-footer">
  <hr class="common-separator my-1" />
  <div class="row justify-content-end text-end">
    <div class="col-3">
      <button
        (click)="deleteCourse()"
        type="button"
        role="tab"
        class="btn btn-danger text-nowrap"
      >
        <fa-icon
          class="mr-2"
          [icon]="faCircleNorch"
          *ngIf="loadSpin"
          [spin]="true"
        ></fa-icon>
        {{ "course:delete:modal:button:delete" | translate }}
      </button>
    </div>
  </div>
</div>
