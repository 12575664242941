import {
  Component,
  EventEmitter,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Course } from 'src/app/interfaces/course';
import { Subscription } from 'src/app/interfaces/subscription';
import { AuthService } from 'src/app/services/auth.service';
import { CountryService } from 'src/app/services/country.service';
import { CoursesService } from 'src/app/services/courses.service';
import { OrdersService } from 'src/app/services/orders.service';
import { ToastService } from 'src/app/services/toast.service';
import { CourseUtilitiesService } from 'src/app/utilities/course-utilities.service';
import { CheckoutQuestionnaireService } from '../checkout-questionnaire.service';
import { environment } from 'src/environments/environment';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnChanges {
  @Input() selfModal: NgbModalRef | undefined;
  @Input() course: Course | undefined;
  @Input() collections: Array<any> | undefined;
  @Input() initialDays: number | undefined;
  @Input() modalInfo: any;
  @Input() isGift = false;
  @Output('openModal') openModal = new EventEmitter();
  @Input() competencies: any = [];
  @Input() subscription_price: any = 90;
  @Input() totalPrice: any = 90;

  product: any = null;
  tabs = [
    {
      id: 'product',
      touched: false,
      enabled: true,
    },
    {
      id: 'method',
      touched: false,
      enabled: true,
    },
    {
      id: 'confirm',
      touched: false,
      enabled: false,
    },
  ];

  currentTab = 0;

  subscriptions: Array<Subscription> = [];

  orderDetailsDiffer: KeyValueDiffer<any, any> | undefined;
  original_price: number | undefined;
  loadedOrderData = false;

  defaultVat = 0.21;
  currentCountry = 'NL';
  paying = false;
  public language = 'nl';
  public compentencyPrice: any = 24.5;

  constructor(
    public checkout: CheckoutQuestionnaireService,
    private coursesService: CoursesService,
    private courseUtils: CourseUtilitiesService,
    private ordersService: OrdersService,
    private countryService: CountryService,
    private kvDiffers: KeyValueDiffers,
    public auth: AuthService,
    private orderService: OrdersService,
    private toastr: ToastService,
    private translate: TranslateService,
    private lang: LangService,
  ) {
    this.lang.language.subscribe((lang) => {
      this.language = lang || 'en';
      if (this.language == 'en') {
        this.compentencyPrice = environment.en_compentencyPrice;
      } else {
        this.compentencyPrice = environment.nl_compentencyPrice;
      }
    });
    if (this.auth.isAuthenticated()) {
      this.auth.user.subscribe((user: any) => {
        this.currentCountry = user.country_code;
        if (!this.currentCountry) {
          this.currentCountry = this.countryService.getDefaultCountryCode();
        }
      });
    } else {
      for (let i = 0; i < this.tabs.length; i++) {
        this.tabs[i].enabled = false;
      }
      this.tabs.unshift({
        id: 'login',
        touched: false,
        enabled: true,
      });
      this.currentCountry = this.countryService.getDefaultCountryCode();
      console.log(this.currentCountry, 'este es el country');
    }
  }

  ngOnInit(): void {
    console.log(this.collections);
    if (this.competencies.length > 0) {
      this.product = {
        description: 'Focus on a single competency (90 days)',
        id: environment.nl_compentencyCourseId,
        name: 'Focus on a single competency (90 days)',
        slug: 'competency',
        striked_price: this.totalPrice,
        type: 'competency',
        competenciesId: this.competencies,
      };
    } else {
      this.product = this.collections![0];
    }

    this.createCheckout();
    if (this.auth.isAuthenticated()) {
      this.loadOrderData();
    }
  }

  canGoNext(): boolean {
    if (this.product) {
      return true;
    } else {
      return false;
    }
  }

  nextTab() {
    let next = this.currentTab + 1;
    let nextTab = this.tabs[next];
    console.log('checkout', this.checkout);
    if (this.tabs[this.currentTab].id == 'method') {
      if (!this.checkout.validateInvoice(true)) {
        return;
      }
      this.tabs[this.currentTab + 1].enabled = true;
      setTimeout(() => {
        if (nextTab) {
          let node = document.querySelector(
            'button[data-bs-target="#' + nextTab.id + '"]'
          );
          (node as HTMLElement).click();
          this.currentTab = next;
        }
      }, 1);
    } else {
      if (nextTab) {
        let node = document.querySelector(
          'button[data-bs-target="#' + nextTab.id + '"]'
        );
        (node as HTMLElement).click();
        this.currentTab = next;
      }
    }
  }

  prevTab() {
    let prev = this.currentTab - 1;
    let prevTab = this.tabs[prev];
    if (prevTab) {
      let node = document.querySelector(
        'button[data-bs-target="#' + prevTab.id + '"]'
      );
      (node as HTMLElement).click();
      this.currentTab = prev;
    }
  }

  changeTab(num: number): void {
    if (this.tabs[num]?.enabled) {
      this.currentTab = num;
    }
  }

  closeModal() {
    this.checkout.discount = null;
    // this.checkout.subscription!.price = this.original_price!;
    this.checkout.calculateTotalAmount();
    this.selfModal?.close();
  }

  loadOrderData(): void {
    if (this.loadedOrderData) {
      return;
    }
    let params = {
      'filter[user_id]': this.auth.getMe()!.id,
      limit: 1,
    };

    this.orderService.query(params).then((res) => {
      // First order
      let order = res.data.data[0];
      if (order) {
        if (order.payment_method || order.method) {
          this.checkout.order!.method = order.payment_method || order.method;
        }

        let invoice_details = order.invoice_details[0];

        if (invoice_details) {
          this.checkout.order!.invoice_details = {
            firstname: invoice_details.firstname,
            lastname: invoice_details.lastname,
            is_company: invoice_details.is_company,
            country_code: invoice_details.country_code,
            is_eu_country: invoice_details.is_eu_country,
            company_vat: invoice_details.company_vat,
            coc: invoice_details.coc,
            company_name: invoice_details.company_name,
            company_address: invoice_details.company_address,
          };

          if (!this.checkout.order!.invoice_details.country_code) {
            this.checkout.order!.invoice_details.country_code =
              this.countryService.getDefaultCountryCode();
          }
          this.checkout.onCountryCodeChange();

          this.loadedOrderData = true;
        }
      } else {
        //not order complet to get country code and tax values
        // console.log('currentCountry',this.currentCountry)
        this.checkout.order!.invoice_details = {
          firstname: '',
          lastname: '',
          is_company: false,
          country_code: this.currentCountry, //this.countryService.getDefaultCountryCode(),
          is_eu_country: true,
          company_vat: '',
          coc: '',
          company_name: '',
          company_address: '',
        };
        this.checkout.onCountryCodeChange();
      }
    });
  }

  pay() {
    if (this.competencies.length > 0) {
      this.checkout.order!.competencies = this.competencies;
      this.checkout.order!.competencies_subscription_days = 90;
      this.checkout.order!.type = 'competency';
     // this.checkout.order!.entries[0].collection_id = 1860;
      this.checkout.order!.entries[0].subscription_days = 90;
    }else{

      this.checkout.order!.entries[0].collection_id = this.product.id;
    }
    console.log('this.checkout.order', this.checkout.order);
  
    if (!this.checkout.order!.method) {
      this.toastr.showError(
        this.translate.instant('form:error:required', {
          field: this.translate.instant('course:buy:payment:form:label:method'),
        })
      );
      this.changeTab(1);
      return;
    }

    // if (this.isGift && !this.checkout.validateGift(true)) {
    //   this.changeTab( this.tabs.findIndex( t => t.id == 'gift') );
    //   return;
    // }

    if (!this.checkout.validateInvoice(true)) {
      this.changeTab(this.tabs.findIndex((t) => t.id == 'method'));
      return;
    }

    this.paying = true;

    this.checkout.pay()?.finally(() => (this.paying = false));
  }

  getPriceWithDiscount(value: number) {
    if (!this.checkout.discount) return value;
    else if (this.checkout.discount.type === 'percentage')
      return value * (1 - this.checkout.discount.amount / 100);
    return Math.max(0, value - this.checkout.discount.amount);
  }
  getTotalValue() {
    return this.getPriceWithDiscount(
      this.checkout.order!.tax + this.checkout.order!.in_total
    );
  }

  getTaxValue() {
    // return this.getTotalValue()*this.checkout.order!.tax_percentage;
    return this.getTotalValue() - this.getNetValue();
  }
  getNetValue() {
    //return (this.getTotalValue()-this.getTotalValue()*this.checkout.order!.tax_percentage)
    return this.getTotalValue() / (1 + this.checkout.order!.tax_percentage);
  }
  ngOnChanges(changes: SimpleChanges): void {}

  changeProduct(collection: any) {
    this.checkout.course = this.product;
    this.checkout.order!.in_total = this.product.striked_price;
    console.log('this.checkout', this.checkout);
  }
  createCheckout() {
    console.log('change');
    if (this.product) {
      console.log('change', this.product);

      this.checkout.course = this.product;

      this.checkout.order = {
        method: this.ordersService.getPaymentMethods()[0],
        entries: [
          {
            collection_id: this.product.id,
            subscription_days: 0,
          },
        ],
        invoice_details: {
          firstname: '',
          lastname: '',
          is_company: false,
          country_code: this.countryService.getDefaultCountryCode(),
          is_eu_country: false,
          company_vat: '',
          coc: '',
          company_name: '',
          company_address: '',
          is_company_vat_valid: false,
        },
        tax_percentage: 0,
        in_total: this.product.striked_price,
        tax: 0,
      };

      if (this.isGift) {
        this.tabs.splice(this.auth.isAuthenticated() ? 1 : 2, 0, {
          id: 'gift',
          touched: false,
          enabled: false,
        });
        this.checkout.isGift = true;
        this.checkout.order!.message = '';
        this.checkout.order!.email = '';
      } else {
        this.checkout.isGift = false;
      }
    }
  }

  logedIn() {
    this.auth.user.subscribe((user) => {
      this.closeModal();
      this.openModal.emit();
    });
  }
}
