import { Component, Input, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { GroupsService } from 'src/app/services/groups.service';
import { ToastService } from 'src/app/services/toast.service';
import {Router} from "@angular/router"
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-accept-gift-modal',
  templateUrl: './accept-gift-modal.component.html',
  styleUrls: ['./accept-gift-modal.component.scss']
})

export class AcceptGiftModalComponent implements OnInit {

  faTimes = faTimes;
  @Input() selfModal: NgbModalRef | null = null;
  @Input() title:String='';
  @Input() subDays:String='';
  @Input() initTime:String='';
  @Input() endTime:String='';
  loadSpin: boolean = false;
  faCircleNorch: any = faCircleNotch;
  loading: boolean = false;
  

  constructor(
    private groupsService: GroupsService,
    private toastService: ToastService,
    private router: Router,
    private translate: TranslateService,
    public activeModal: NgbActiveModal,
   
  ) {

   }


  ngOnInit(): void {
  }
  closeModal(){
    if(this.selfModal) this.selfModal?.close();
    else $("ngb-modal-window").click();
  }

  acceptInvitation() {
    console.log('test click')
    this.activeModal.close('is ok')
  }

}
