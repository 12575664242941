<div class="d-flex" [ngClass]="{'flex-column': labelPosition == 'top' || labelPosition == 'bottom'}">
    <div *ngIf="labelPosition == 'top'" class="the-progress-number pb-1">
        <ng-container [ngTemplateOutlet]="label"></ng-container>
    </div>

    <ng-container *ngIf="labelPosition == 'left'"[ngTemplateOutlet]="label"></ng-container>

    <div class="progress-bar-container position-relative" [ngClass]="{'ms-2': labelPosition == 'left', 'me-2': labelPosition == 'right'}">
        <div class="progress-bar" [style.width.%]="width"  >
            <div class="the-progress" [style.width.%]="progress" [ngStyle]="{ 'background-color': color }"></div>
        </div>
    </div>

    <ng-container *ngIf="labelPosition == 'right'" [ngTemplateOutlet]="label"></ng-container>

    <div *ngIf="labelPosition == 'bottom'" class="the-progress-number pt-1">
        <ng-container [ngTemplateOutlet]="label"></ng-container>
    </div>
</div>

<ng-template #label>
    <label for="progress">
        {{ progress | number: '1.0-0'}}% <span *ngIf="showLabelText">{{ "progressbar:completed" | translate }}</span>
    </label>
</ng-template>
