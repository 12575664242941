import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { GroupsService } from 'src/app/services/groups.service';
import { ToastService } from 'src/app/services/toast.service';
import { Router } from "@angular/router"
import { TranslateService } from '@ngx-translate/core';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-upload-file-modal',
  templateUrl: './uploadFile-modal.component.html',
  styleUrls: ['./uploadFile-modal.component.scss']
})

export class UploadFileModalComponent implements OnInit {

  faTimes = faTimes;
  @Input() selfModal: NgbModalRef | null = null;
  @Input() title: String = '';
  @Input() group: any;
  faCircleNorch = faCircleNotch;
  loading = false;
  maxSize = '250MB';
  progress = 75;
  file_name='';
  fileSupported:any;
  fileToUp: any //File | null = null;
  file = {
    name: "",
    description: ""
  }
  modulesQuill={};

  boxStyle={};

  constructor(
    private groupsService: GroupsService,
    private toastService: ToastService,
    private router: Router,
    private translate: TranslateService,

  ) { }


  ngOnInit(): void {
    this.boxStyle = {'height': '200px','width': '100%' , 'border': 'none'};
    this.modulesQuill = {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          ['blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],
          [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
        ],
      },
    };
    this.fileSupported=this.groupsService.getFileSoported('all');
    console.log(this.fileSupported);

  }
  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $("ngb-modal-window").click();
  }

  uploadFile(inputValue: any): void {

    console.log(inputValue.target.files);
    this.fileToUp = inputValue.target.files[0];
    this.file_name=this.fileToUp.name?this.fileToUp.name:'';
    this.file.name =   this.file_name.slice(0, -4)
  }


  create() {
    this.loading = true;
    
    this.groupsService.uploadFile(this.fileToUp, this.file.name, this.file.description).then(res => {
      
      this.groupsService.addFileToGroup(res.id,this.group.id).then(resp => {
        this.toastService.showSuccess(this.translate.instant('groups:form:save:settings:label'));
        this.loading = false;
        this.closeModal();
      })
  
   

    }).catch(err => {
      console.log(err);
      this.loading = false;
      this.toastService.showError(this.translate.instant('groups:from:save:settings:error'))

    });

  }



}
