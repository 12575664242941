import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { User } from '../interfaces/user';
import { AuthService } from './auth.service';
import { InitService } from './init.service';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LangService {
  private language$: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);
  private updateCorusesSubject = new Subject<void>();

  activateCourses(lang: any) {
    this.updateCorusesSubject.next(lang);
  }
  get activateCourses$(): Observable<void> {
    return this.updateCorusesSubject.asObservable();
  }
  constructor(
    private auth: AuthService,
    private translateService: TranslateService,
    private initService: InitService,
    private route: ActivatedRoute
  ) {
    this.setDefault();
  }

  // move this to another service
  public setLanguage(language: string) {
    if (!language){language='nl'}
    this.translateService.setDefaultLang(language.toLowerCase());
    if (this.auth.isAuthenticated()) {
      // todo, update user and save user lang
    }
    this.language$.next(language.toLowerCase());
    localStorage.setItem('language', language.toLowerCase());
    this.activateCourses(language.toLowerCase());
  }
  public get language(): Observable<string | null> {
    return this.language$.asObservable();
  }

  public setDefault() {
    let lang: string | null = localStorage.getItem('language');
    let language = lang
      ? lang
      : this.initService.getCurrentOrg()?.language
      ? (this.initService.getCurrentOrg()?.language as string)
      : 'nl';
    this.language$.next(language);
    localStorage.setItem('language', language.toLowerCase());
    this.translateService.setDefaultLang(language);
    this.auth.user.subscribe((user: User | null) => {
      let lang = localStorage.getItem('language') || language;
      this.language$.next(user?.language || lang);
      this.translateService.setDefaultLang(user?.language || lang);
      localStorage.setItem('language', user?.language || lang);
    });
  }
}
