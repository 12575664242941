import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { InitService } from 'src/app/services/init.service';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { OrganizationChooseComponent } from '../../organization-choose/organization-choose.component';

@Component({
  selector: 'app-main-pl',
  templateUrl: './plmain.component.html',
  styleUrls: ['./plmain.component.scss']
})
export class PlMainComponent implements OnInit {
  @Output() logged = new EventEmitter();
  @Input() state: string = 'login';
  @Input() selfModal: NgbModalRef | undefined;
  @Input() message: string | undefined;

  organization: any = null;

  constructor(
    private initService: InitService,
    private router: Router,
    private orgsService: OrganizationsService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.organization = this.initService.getCurrentOrg();
  }

  closeModal() {
    this.selfModal?.close();
  }

  loggedIn() {
   
    console.log('login')
      //this.logged.emit();
     // this.selfModal?.close();
   
    if (this.logged.observers.length) {
      this.selfModal?.close();
      this.logged.emit();
      return
    }

    if ( this.router.url.includes('courses') && (this.router.url == '/' || this.router.url.includes('login')) ) {
      this.orgsService.getUserOrganizationsMenu().then( data => {
        let modalRef = this.modalService.open(OrganizationChooseComponent, { windowClass: 'login-modal', backdrop: 'static', keyboard: false });
        modalRef.componentInstance.selfModal = modalRef;
        this.selfModal?.close();
      });
    } else {
      if (this.router.url.includes('login')) {
        this.router.navigateByUrl('/');
        this.selfModal?.close();
      } else {
        window.location.reload();
      }
    }
  }
}
