import { DeleteReviewComponent } from './../../modals/courses/delete-review/delete-review.component';
import { PermissionsService } from './../../utilities/permissions.service';
import { CoursesService } from 'src/app/services/courses.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CreateReviewModalComponent } from 'src/app/modals/courses/create-review-modal/create-review-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'src/app/services/toast.service';
import { CourseUtilitiesService } from 'src/app/utilities/course-utilities.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss'],
})
export class ReviewsComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;

  @Input('valor') course: any = null;
  @Output() refresh: EventEmitter<any> = new EventEmitter();
  constructor(
    private courseService: CoursesService,
    private ngbModal: NgbModal,
    private translate: TranslateService,
    private toastService: ToastService,
    private courseUtils: CourseUtilitiesService,
    private permissionsService: PermissionsService,
    public auth: AuthService
  ) {}

  ngOnInit(): void {}

  round(number: any) {
    return Math.round(number);
  }
  public deleteReview(reviewId: number, index: number) {
    this.courseService
      .deleteReview(this.course?.id, reviewId)
      .then((resp) => {
        this.closeModal();
        this.toastService.showSuccess(this.translate.instant('Review deleted'));
        this.refresh.emit();
      })
      .catch((err: any) => {
        this.closeModal();
        console.log(err);
        this.toastService.showError(
          this.translate.instant('Error deleting review')
        );
      });
  }

  public deleteReviewModal(reviewId: number, index: number) {
    let modalRef = this.ngbModal.open(DeleteReviewComponent, {
      windowClass: 'default-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.deleteReview.subscribe((resp: any) => {
      this.deleteReview(reviewId, index);
    });
  }
  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  public editReview(reviewId: number, review: any) {
    let modalRef = this.ngbModal.open(CreateReviewModalComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
    });
    modalRef.componentInstance.course = this.course;
    modalRef.componentInstance.reviewId = reviewId;
    modalRef.componentInstance.reviewToEdit = review;
    modalRef.componentInstance.selfModal = modalRef;
    modalRef.componentInstance.getReviews.subscribe((resp: any) => {
      this.refresh.emit();
    });
  }

  public userCanEdit(review: any): boolean {
    if (this.course) {
      return (
        (this.courseUtils.userIsEnrolled(this.course) &&
          this.permissionsService.userIsOwner(review)) ||
        this.auth.hasAnyRole(['moderator', 'super_user'])
      );
    }
    return false;
  }
}
