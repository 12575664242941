<div class="select-users">
  <div class="box-input">
    <input
    #yourElement
      id="selectMembersInpt"
      class="input-box mb-0"
      type="text"
      (keyup)="onSearch()"
      [(ngModel)]="searchTxt"
      placeholder="{{
        placeholder | translate
      }}"
      autofocus
    />
  </div>

  <div class="list-search" *ngIf="courses && courses.length && !loading && searchTxt !== ''">
    <div *ngFor="let item of courses" (click)="onAdd(item)" class="d-flex">
      <img
        src="{{
          item.coverPathUrlSmall ||
            'assets/img/avatars/profile-avatar-purple.svg'
        }}"
      />

      <span style="float: left">{{ item.name }}</span>
    </div>
  </div>
  <div class="list-search" *ngIf="loading">
    <div>Loading..</div>
  </div>
</div>
