import { Component, Input, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-main-modal',
  templateUrl: './main-modal.component.html',
  styleUrls: ['./main-modal.component.scss']
})

export class MainModalComponent implements OnInit {
  
  faTimes = faTimes;
  @Input() selfModal: NgbModalRef | null = null;
  @Input() title:String='';

  loading= false;

  constructor() { }


  ngOnInit(): void { 
  }
  closeModal(){
    if(this.selfModal) this.selfModal?.close();
    else $("ngb-modal-window").click();
  }

}
