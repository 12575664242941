<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1 icon-x" [icon]="faTimes"></fa-icon
  ><span class="icon-x">{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title">
    {{ "video:edit:modal:unpublished:text" | translate }}
  </h4>
</div>
<div class="modal-body flex-column pb-0">
  <div class="row">
    <p class="description">
      {{ "video:edit:modal:unpublished:text1" | translate }}
    </p>
  </div>
  <div class="row mt-1">
    <p class="description">
      {{ "video:edit:modal:unpublished:text2" | translate }}
    </p>
  </div>
  <div class="row mt-1">
    <p class="description">
      {{ "video:edit:modal:unpublished:text3" | translate }}
    </p>
  </div>
  <div class="row mt-1">
    <p class="description">
      {{ "video:edit:modal:unpublished:text4" | translate }}
    </p>
  </div>
</div>
<div class="row justify-content-end modal-footer">
  <hr class="common-separator" />
  <div class="row justify-content-end text-center">
    <div class="col">
      <button
        (click)="closeModalUnpublished()"
        type="button"
        class="btn  btn-outline-danger ms-2 fs-15 text-nowrap"
      >
        {{ "Leave video unpublished" | translate }}
      </button>
    </div>
    <div class="col pe-0">
      <button
        (click)="closeModal()"
        type="button"
        role="tab"
        class="btn btn-primary text-nowrap ms-5"
      >
        <fa-icon
          class="mr-2"
          [icon]="faCircleNorch"
          *ngIf="loadSpin"
          [spin]="true"
        ></fa-icon>
        {{ "Go back and publish" | translate }}
      </button>
    </div>
  </div>
</div>
