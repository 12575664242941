import { Component, Input, OnInit } from '@angular/core';
import { InitService } from 'src/app/services/init.service';

@Component({
  selector: 'app-empty-content',
  templateUrl: './empty-content.component.html',
  styleUrls: ['./empty-content.component.scss']
})
export class EmptyContentComponent implements OnInit {
 
	@Input() primaryText: String='';


  constructor(
    private initService: InitService
  ) {
    
  }

  ngOnInit(): void {
  }

}
