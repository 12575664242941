import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from "@angular/router";
import { ProfileComponent } from './profile.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ProfileComponent
      },
      {
        path: 'delete-account/:id/:user',
        component: DeleteAccountComponent,
      },
    ]
  }
]

@NgModule({
  declarations: [],
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class ProfileRoutingModule { }