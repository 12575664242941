import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { Organization } from 'src/app/interfaces/organization';
import { InitService } from 'src/app/services/init.service';
@Component({
  selector: 'app-go-to-duplicate',
  templateUrl: './go-to-duplicate.component.html',
  styleUrls: ['./go-to-duplicate.component.scss'],
})
export class GoToDuplicateComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() user: any = {};
  @Input() organizations: any = [];
  @Input() playlistId: any = ' ';

  public loading: boolean = false;
  public faCircleNorch: any = faCircleNotch;
  public faTimes = faTimes;
  constructor(private router: Router, private initService: InitService) {}

  ngOnInit(): void {}
  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
  
  switchOrganization(organization: Organization): void {
    const currentOrg = this.initService.getCurrentOrg();
    if (currentOrg && currentOrg.slug != organization.slug) {
      let aDomain = window.location.host.split('.');
      aDomain[0] = organization.slug;
      window.location.href =
        window.location.protocol +
        '//' +
        aDomain.join('.') +
        '/playlist/' +
        this.playlistId;
    } else {
      window.location.reload();
    }
  }
}
