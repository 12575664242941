import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-welcome-user',
  templateUrl: './welcome-user.component.html',
  styleUrls: ['./welcome-user.component.scss'],
})
export class WelcomeUserComponent implements OnInit {
  faTimes = faTimes;
  @Input() description: string = '';
  @Input() selfModal: NgbModalRef | null = null;
  @Input() title: String = '';
  @Output() outputEvent = new EventEmitter<any>();

  loadSpin: boolean = false;
  faCircleNorch: any = faCircleNotch;

  constructor() {}

  ngOnInit(): void {    
  }

  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
}
