import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { Video } from 'src/app/interfaces/video/video';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VideoComponent } from 'src/app/modals/video/video.component';
import { VideoUtilitiesService } from 'src/app/utilities/video-utilities.service';
import { AuthService } from 'src/app/services/auth.service';
import { PermissionsService } from 'src/app/utilities/permissions.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.scss'],
})
export class VideoCardComponent implements OnChanges, OnInit {
  @Input() video: Video | null = null;
  @Input() username: String | null = null;
  @Input() featured: boolean = false;
  @Input() group: number | null = null;
  @Input() clickItem: number | null = null;
  @Input() isFromGroup: boolean = false;
  @Output() refreshDelete = new EventEmitter<any>();
  @Output() refreshDeleteFromGroup = new EventEmitter<any>();

  faPlay = faPlay;
  faStar = faStar;
  faClock = faClock;
  time: any;
  title = '';
  description = '';
  currentLang = 'en';

  constructor(
    private modalService: NgbModal,
    private videoUtils: VideoUtilitiesService,
    private permissionsService: PermissionsService,
    public auth: AuthService,
    private lang: LangService
  ) {}
  ngOnInit(): void {
    this.lang.language.subscribe((lang) => {
      if (this.video) {
        let info = this.videoUtils.getInfoByLang(this.video);
        this.title = info?.name;
        this.description = info?.description;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.video?.id == this.clickItem) {
      this.openVideoModal();
    }
    var tempTime = moment.duration(this.video?.source?.length);
    var mm;
    var hh;
    var ss;

    if (tempTime.minutes() < 10) {
      mm = '0' + tempTime.minutes();
    } else {
      mm = tempTime.minutes();
    }

    if (tempTime.hours() < 10) {
      hh = '0' + tempTime.hours() + ':';
    } else {
      hh = tempTime.hours() + ':';
    }

    if (tempTime.hours() == 0) {
      hh = '';
    }

    if (tempTime.seconds() < 10) {
      ss = '0' + tempTime.seconds();
    } else {
      ss = tempTime.seconds();
    }

    var y = hh + mm + ':' + ss;
    this.time = y;
  }

  openVideoModal() {
    let modalRef = this.modalService.open(VideoComponent, {
      windowClass: 'default-modal video-modal',
      size: 'lg',
    });
    modalRef.componentInstance.videoId = this.video?.id;
    modalRef.componentInstance.group = this.group;
    modalRef.componentInstance.selfModal = modalRef;
    modalRef.componentInstance.refreshDelete.subscribe((data: any) => {
      this.refreshDelete.emit();
    });
  }
  public deleteItem() {
    this.refreshDeleteFromGroup.emit(this.video?.id_item);
  }
  public userCanEdit(item: any) {
    if (item) {
      return (
        this.permissionsService.userIsOwner(item) ||
        this.auth.hasAnyRole(['moderator', 'super_user'])
      );
    }
    return false;
  }
}
