import { OrderDetailComponent } from './../../modals/admin/order/order-detail/order-detail.component';
import { AdminService } from 'src/app/services/admin.service';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-table-admin-orders',
  templateUrl: './table-admin-orders.component.html',
  styleUrls: ['./table-admin-orders.component.scss'],
})
export class TableAdminOrdersComponent implements OnInit {
  @Input() listOrders: any[] = [];
  @Output() refreshTable = new EventEmitter<any>();
  @Output() scrolled = new EventEmitter<null>();

  @ViewChild('container') container!: ElementRef;
  constructor(
    private organizationService: OrganizationsService,
    private toastService: ToastService,
    private ngbModal: NgbModal,
    private router: Router,
    private orderService: AdminService
  ) {}

  ngOnInit(): void {
    console.log('listOrders',this.listOrders)
  }
  @HostListener('window:scroll', ['$event'])
  onScroll(e: any) {
    let bottomPosition =
      this.container.nativeElement.getBoundingClientRect().top +
      this.container.nativeElement.offsetHeight;
    if (bottomPosition < window.innerHeight) {
      this.scrolled.emit();
    }
  }

  public shareSettings() {}

  public openDetail(orderId: any) {
    let modalRef = this.ngbModal.open(OrderDetailComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.orderId = orderId;
  }
}
