import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InitService } from 'src/app/services/init.service';
import { GroupsService } from 'src/app/services/groups.service';
import { Paginator } from 'src/app/interfaces/paginator';


@Component({
  selector: 'app-group-activity-item',
  templateUrl: './group-activity-item.component.html',
  styleUrls: ['./group-activity-item.component.scss']
})
export class GroupActivityItemComponent implements OnInit {
 
	@Input() activity: any;
	@Input() tab: any;
	@Output() refreshConversation = new EventEmitter<null>();

  activities:any;
  loading = false;


  constructor( ) {
  }

  ngOnInit(): void {
    
  }

  getUser(){
      var user = this.activity.user;
      if (this.activity.type_activity === 'remove_user') {
          user = this.activity.entity;
      }
      return user;
  }
  
  public refresh($event : any ){
    this.refreshConversation.emit();
  }

}
