<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title">
    {{ "courses:modal:title:sharesettings" | translate }}
  </h4>
</div>
<div class="modal-body flex-column">
  <div *ngIf="loadingOrganization; else content" class="row justify-content-center">
    <div class="col">
      <app-loading-icon></app-loading-icon>
    </div>
  </div>
  <ng-template #content>
    <div class="row mt-3 p-0">
      <div class="mb-3">
        <label class="form-label mb-3">
          {{
            "course:edit:modal:tab:share:organization:title" | translate
          }} </label
        ><br />
        <label class="form-label mb-3">
          {{ "collection:share:owner:organizations" | translate }}
        </label>

        <div class="row">
          <div
            class="col-md-5 col-xs-12 organization-list"
            *ngFor="let organization of organizations"
          >
            <label class="fw-bold" [for]="organization.name">
              <input
                class="form-check-input"
                type="checkbox"
                [(ngModel)]="organization.checked"
                [name]="organization.name"
                [id]="organization.name"
              />
               {{ organization.name }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="organizationsNotRegister.length > 0" class="row mt-3 p-0">
      <div class="mb-3">
        <label class="form-label mb-3">
          {{ "collection:share:other:organizations" | translate }}
        </label>

        <div class="row">
          <div
            class="col-md-5 col-xs-12 organization-list"
            *ngFor="let organization of organizationsNotRegister"
          >
            <label class="fw-bold" [for]="organization.name">
              <input
                class="form-check-input"
                type="checkbox"
                [(ngModel)]="organization.checked"
                [name]="organization.name"
                [id]="organization.name"
              />
              {{ organization.name }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<div class="modal-footer">
  <div class="d-flex justify-content-end text-center">
    <button
      type="button"
      class="btn btn-outline-primary ms-2 fs-15 text-nowrap"
      (click)="closeModal()"
    >
      {{ "Cancel" | translate }}
    </button>
    <button
      type="button"
      role="tab"
      class="btn btn-primary ms-2"
      (click)="share()"
    >
      <fa-icon
        class="mr-2"
        [icon]="faCircleNorch"
        *ngIf="loadSpin"
        [spin]="true"
      ></fa-icon>
      {{ "courses:modal:title:sharesettings:btn:save" | translate }}
    </button>
  </div>
</div>
