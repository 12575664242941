import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Course } from 'src/app/interfaces/course';


@Component({
  selector: 'app-curriculum-main',
  templateUrl: './curriculum-main.component.html',
  styleUrls: ['./curriculum-main.component.scss']
})
export class CurriculumMain implements OnInit {
  @Input() course: Course|undefined;
  @Input() type: string='curriculum';
public currentCourse: any 
  constructor(
  ) {}

  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.currentCourse = this.course
  }
}
