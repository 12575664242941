import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Context } from 'src/app/interfaces/video/context';
import { Video } from 'src/app/interfaces/video/video';
import { ApiService } from 'src/app/services/api.service';
import { ToastService } from 'src/app/services/toast.service';
import { VideoEditService } from '../video-edit.service';

@Component({
  selector: 'app-dh-form',
  templateUrl: './dh-form.component.html',
  styleUrls: ['./dh-form.component.scss']
})
export class DhFormComponent implements OnInit {
  @Output('touch') touch: EventEmitter<boolean> = new EventEmitter();
  @Input('video') video: Video|undefined;

  contexts: any = [];

  phases = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
  levels = [1, 2, 3, 4, 5, 6, 7, 8];

  apiTags: Array<Array<any>> = [];

  contextsToDelete: Array<Context> = [];

  istouched = {
    dh: false,
    context: false
  }

  constructor(
    private api: ApiService,
    public videoEditService: VideoEditService,
    public toastr: ToastService
  ) { }

  ngOnInit(): void {
    this.updateVideoContexts(this.video?.contexts);
  }

  addContext(): void {
    this.touched()
    this.contexts.push({
      id: undefined,
      special_id: undefined,
      special_extra: {
        startPhase: null,
        endPhase: null
      },
      phase_id: undefined,
      level_id: undefined
    })
  }

  updateVideoContexts(contexts: any): void {
    this.contexts = [];
    // Loop through video context and push it to a scope variable we'll use in our view and below methods
    contexts.forEach( (context: any) => {
      this.contexts.push({
        video_post_context_id: context.id,
        level_id: context.level ? context.level.id : null,
        phase_id: (context.phase ? context.phase.id : null),
        special_id: (context.special ? context.special.id : null ),
        special: (context.special ? context.special.special : null ),
        special_extra: (context.special && context.special.special === 'sequence' ? (context.special_extra ? context.special_extra : { startPhase: null, endPhase: null }) : { startPhase: null, endPhase: null })
      })
    });
  };

  removeContext(i: number): void {
    this.touched()
    if (this.contexts[i].video_post_context_id) {
      this.contextsToDelete.push(this.contexts[i])
    }
    this.contexts.splice(i, 1);
  }

  touched(dh = false) {
    if (dh) {
      this.istouched.dh = true;
    } else {
      this.istouched.context = true;
    }
    this.touch.emit(true);
  }

  getTags(i: number): void {
    this.touched();
    if (this.contexts[i].phase_id && this.contexts[i].level_id) {
      this.api.get('/tag/doublehealix/level/' + (this.contexts[i].level_id! - 1) + '/phase/' + this.contexts[i].phase_id!).then( res => {
        let tags: Array<any> = [];
        res.data.tags.forEach( (tag: any) => {
          tag.selected = false;
          tags.push(tag)
        });

        this.apiTags[i] = tags;
      })
    }
  }

  toggleTag(tag: any) {
    this.touched(true);

    // check if is already selected in the general tag list
    let isAlreadySelected = this.videoEditService.dhtags.filter( (t: any) => t.tag_context_id == tag.tag_context_id).length;

    if (isAlreadySelected) {
      tag.selected = false;

      this.videoEditService.dhtags = this.videoEditService.dhtags.filter( (t: any) => t.tag_context_id != tag.tag_context_id);
    } else {
      tag.selected = true;

      // search in user tags
      let selectTag: any = this.videoEditService.userTags.filter( (t: any) => t.id == tag.tag_id )[0];
      if (selectTag) {
          selectTag.selected = tag.selected;
      }

      this.videoEditService.dhtags.push(tag);
    }
  }

  save() {
    let promises = []

    if (this.istouched.context) {
      let contextUpdatePromises = [];

      let processedContexts = JSON.parse(JSON.stringify(this.contexts))
      processedContexts.forEach( (c: Context) => {
        if (c.special !== 'sequence') {
          c.special_extra = null;
        }
        if (c.level_id) {
          c.level = c.level_id - 1
        }
        c.phase = c.phase_id
      });

      contextUpdatePromises.push(this.api.put('/video/' + this.video?.id+ '/context', {
          contexts : processedContexts,
          double_helix_analysis: this.video?.double_helix_analysis
      }));

      if (this.contextsToDelete.length > 0) {
        contextUpdatePromises.push(this.api.put('/video/' + this.video?.id + '/context', {
          delete: {
            context : this.contextsToDelete
          }
        }));

        this.contextsToDelete.forEach( (deletedContext: Context) => {
          let tagsForDeletedContextToDelete = this.videoEditService.dhtagsBeforeChanges.filter( (t: any) => {
            return t.level_id == deletedContext.level_id && t.phase_id == deletedContext.phase_id
          })

          tagsForDeletedContextToDelete.forEach( (tag: any) => {
            this.videoEditService.dhtags = this.videoEditService.dhtags.filter( (t: any) => {
              return t.analysis_record_id != tag.analysis_record_id
            });
            this.videoEditService.dhtagsBeforeChanges = this.videoEditService.dhtagsBeforeChanges.filter( (t: any) => {
              return t.analysis_record_id != tag.analysis_record_id
            });
            this.videoEditService.userTags = this.videoEditService.userTags.filter( (t: any) => {
              return t.analysis_record_id != tag.analysis_record_id
            });
            this.videoEditService.userTagsBeforeChanges = this.videoEditService.userTagsBeforeChanges.filter( (t: any) => {
              return t.analysis_record_id != tag.analysis_record_id
            });

            contextUpdatePromises.push(this.api.delete("/video/" + this.video?.id + "/analysis/" + tag.analysis_record_id));
          })
        });
      }

      promises.push( Promise.all(contextUpdatePromises).then( (results) => {
        let errors = results.filter( r => r.result == 'error' );
        let successed = results.filter( r => r.result == 'success' );

        if (errors.length) {
          results.forEach( r => {
            this.toastr.showError(r.message.friendly);
          });
        } else if ( successed.length == results.length ) {
          if (successed[successed.length - 1].data.video.contexts) {
            successed[successed.length - 1].data.video.contexts.sort( (a: Context, b: Context) => {
              return a.id! - b.id!;
            })
            this.updateVideoContexts(successed[successed.length - 1].data.video.contexts)
          }
          this.istouched.context = false;
          this.touch.emit(false);
        } else {
          this.toastr.showError("Something went wrong adding Double Healix tags");
        }
        return results;
      }).catch( (err) => {
        this.toastr.showError("Something went wrong adding Double Healix tags");
        return err;
      }));
    }

    if (this.istouched.dh) {
      let tagPromises: Array<any> = [];

      this.videoEditService.dhtags.forEach( (tag: any) => {
        let apiTag = {
          tag_type : "doublehealix",
          tag_id : tag.tag_context_id
        };

        // check if already exist
        let newtag = !this.videoEditService.dhtagsBeforeChanges.filter( (t: any) => t.tag_context_id == tag.tag_context_id ).length;
        if (newtag) {
          tagPromises.push(this.api.post("/video/" + this.video?.id + "/analysis/tag", apiTag).then( (resp) => {
              if (resp.result === 'success') {
                // If the API results succes we can append our added tag to the doublehealixTagsBeforeChanges variable
                tag.analysis_record_id = resp.video.analysis.id;
                tag.id = tag.tag_id;
                tag.tag_type = apiTag.tag_type;

                this.videoEditService.dhtagsBeforeChanges.push(tag);
                this.videoEditService.userTags.push(tag);
                this.videoEditService.userTagsBeforeChanges.push(tag);
              }

              return resp;
            })
          );
        }
      });

      let tagsDHBeforeUpdate = this.videoEditService.dhtagsBeforeChanges.map( t => t.tag_context_id);
      let tagsDHAfterUpdate = this.videoEditService.dhtags.map( t => t.tag_context_id);
      let tagsDHToDelete = tagsDHBeforeUpdate.filter(t => !tagsDHAfterUpdate.includes(t));

      tagsDHToDelete.forEach( (tag_context_id) => {
          let tag = this.videoEditService.dhtagsBeforeChanges.find( t => t.tag_context_id == tag_context_id);

          if (tag.analysis_record_id) {
            tagPromises.push(this.api.delete("/video/" + this.video?.id + "/analysis/" + tag.analysis_record_id).then( (resp: any) => {
                if (resp.result === 'success') {
                  // If the API results succes we can delete our remote tag from the userTagsBeforeChanges variable
                  this.videoEditService.dhtagsBeforeChanges =

                  this.videoEditService.dhtagsBeforeChanges = this.videoEditService.dhtagsBeforeChanges.filter( (t: any) => {
                    return t.analysis_record_id != tag.analysis_record_id
                  });
                  this.videoEditService.userTags = this.videoEditService.userTags.filter( (t: any) => {
                    return t.analysis_record_id != tag.analysis_record_id
                  });
                  this.videoEditService.userTagsBeforeChanges = this.videoEditService.userTagsBeforeChanges.filter( (t: any) => {
                    return t.analysis_record_id != tag.analysis_record_id
                  });
                }

                return resp;
              })
            )
          }
      });

      promises.push( Promise.all(tagPromises).then( (results: any) => {
        if (results.filter( (r: any) => r.esult == 'error' ).length > 0) {
          this.toastr.showError('something went wrong updating clip tags');
        } else if (results.filter( (r: any) => r.result == 'success' ).length === results.length) {
          this.istouched.context = false;
          this.touch.emit(false);
        } else {
          this.toastr.showError('unknown error. something went wrong updating clip tags');
        }
        return results
      }).catch( (err) => {
        this.toastr.showError('unknown error. something went wrong updating clip tags');
        return err;
      }));
    }
    return Promise.all(promises)
  }
}
