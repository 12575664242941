<div class="table-responsive-sm mb-4">
  <table class="table table-hover" #container>
    <thead>
      <tr class="" [ngClass]="{ 'custom-border': true }">
        <th class="text-center" scope="col"></th>
        <th scope="col" class="my-3 align-middle" style="min-width: 280px;">
          <span>
            {{ "organization:settings:courses:table:title:1" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "organization:settings:courses:table:title:2" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "organization:settings:courses:table:title:3" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "organization:settings:courses:table:title:4" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "organization:settings:courses:table:title:6" | translate }}
          </span>
        </th>
        <th class="text-center" scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container>
        <tr
          [ngClass]="{ 'custom-border': true }"
          *ngFor="let course of listCourses; let i = index"
        >
          <td class="justify-content-center">
            <img
              class="img-course p-1"
              [src]="course?.coverPathUrlMedium"
              alt="img"
              onerror="this.src='assets/img/groups/icons/list.png'"
            />
          </td>
          <td class="title-course align-middle">
            {{ course.name | excerpt : 50 }}
          </td>
          <td class="align-middle">
            {{ course?.user?.display_name || "" }}
          </td>
          <td class="align-middle">
            {{(joinPlatforms(course?.organizations)| excerpt : 55 ) || "" }}
          </td>
          <td class="align-middle">
            {{ 0 }}
          </td>
          <td class="align-middle">
            <p class="fw-bold">From €{{ course?.subscription_price_min }}</p>
          </td>
          <td colspan="3" class="align-middle text-center">
            <div
              ngbDropdown
              class="d-inline-block float_right"
              placement="bottom-right"
            >
              <button
                type="button"
                class="btn btn-outline-primary"
                id="dropdownBasic1"
                ngbDropdownToggle
              >
                <i class="fa fa-ellipsis-h"></i>
              </button>
              <div
                ngbDropdownMenu
                aria-labelledby="dropdownBasic1"
                class="mt-1"
              >
                <button (click)="goToCourse(course)" ngbDropdownItem>
                  {{
                    "organization:settings:courses:table:dropdown:item:5"
                      | translate
                  }}
                </button>
                <button (click)="shareSettings(course)" ngbDropdownItem>
                  {{
                    "organization:settings:courses:table:dropdown:item:1"
                      | translate
                  }}
                </button>
                <button (click)="openViewMembers(course)" ngbDropdownItem>
                  {{
                    "organization:settings:courses:table:dropdown:item:2"
                      | translate
                  }}
                </button>
                <button (click)="openPricing(course)" ngbDropdownItem>
                  {{
                    "organization:settings:courses:table:dropdown:item:3"
                      | translate
                  }}
                </button>
                <button
                  class="text-danger"
                  (click)="deleteCourse(course)"
                  ngbDropdownItem
                >
                  {{
                    "organization:settings:courses:table:dropdown:item:4"
                      | translate
                  }}
                </button>
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
