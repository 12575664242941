<h3 class="h6 mb-1">{{ 'video:edit:modal:tab:tags:title' | translate }}</h3>
<p class="fs-14 text-muted">{{ 'video:edit:modal:tab:tags:description' | translate }}</p>

<div class="tags-search-container">
  <i class="fa fa-search text-primary"></i>
  <input class="form-control" [ngModel]="search" (ngModelChange)='changed($event)'
    [placeholder]="'video:edit:modal:search_tags:placeholder' | translate" type="text">
  <i *ngIf="searching" class="fa fa-circle-notch fa-spin text-primary"></i>

  <div class="tags-results" *ngIf="showResults && search.length">
    <a class="searchbar-result searchbar-result-tag d-flex align-items-center p-2" *ngFor="let tag of results" (click)="selectTag(tag)">
      <div class="autocomplete-content">
        <h2 class="h5 text-dark mb-0">
          <span>{{ tag.name }}</span>
          <span class="pull-right title-extra-info" *ngIf="tag.tag && tag.tag.parent_tag && tag.tag.parent_tag.info.length">
              {{ 'video:edit:modal:tab:tags:parent' | translate }}: {{ tag.tag.parent_tag.info[0].name }}
          </span>
        </h2>
        <h3 *ngIf="tag.tag.context.length && currentOrg!.dh_organization" class="h6">
          <span class="sub-subtitle">{{ tag.tag.context.length }} {{ "video:edit:modal:tab:tags:combinations:title" + (tag.tag.context.length == 1 ? ':singular' : '') | translate }}:</span>
          <span *ngFor="let tagContext of tag.tag.context" class="text-muted ms-2">
            <span *ngIf="tagContext.phase.number">{{ "video:edit:modal:tab:tags:combinations:field" | translate }} <strong>{{ tagContext.phase.number }}</strong> - </span>
            <span>{{ "video:edit:modal:tab:tags:combinations:level" | translate }} <strong>{{ tagContext.level.number }}</strong>;</span>
          </span>
        </h3>
        <p *ngIf="tag.description" class="text-muted mb-0">{{ tag.description }}</p>
      </div>
    </a>
    <div class="searchbar-result p-2" *ngIf="!searching">
        <a class="fs-14" (click)="notFoundClick()">
          <i class="fa fa-plus me-2"></i>
          {{ "video:edit:modal:tab:tags:add" | translate }} "{{ search }}" {{ "video:edit:modal:tab:tags:add:as" | translate }}
        </a>
    </div>
  </div>
</div>

<div class="card p-3 mt-3" style="background-color: #f2f6fb;">
  <h4 class="h6 mb-2">{{ 'video:edit:modal:tab:tags:subtitle' | translate }}</h4>
  <p *ngIf="!tags.length" class="text-muted text-center fs-14">{{ 'video:edit:modal:tab:tags:first:text' | translate }}</p>
  <div>
    <ng-container *ngFor="let tag of this.videoEditService.userTags; let i = index">
      <div class="pill selected" *ngIf="tag.selected">
        <span *ngIf="tag.info.description" placement="bottom" container="body" [ngbTooltip]="tag.info.description"><i class="fa fa-info-circle me-1 text-primary" aria-hidden="true"></i></span>
        <span>{{ tag.info.name }}</span>
        <span class="fa fa-times ms-2" (click)="removeTag(tag)"></span>
      </div>
    </ng-container>
  </div>
</div>
