import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from 'src/app/services/admin.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
})
export class PricingComponent implements OnInit {
  @Input() course: any = {};
  @Input() selfModal: NgbModalRef | null = null;
  @Output() refresh = new EventEmitter<any>();
  public organizations: any[] = [];
  public faCircleNorch: any = faCircleNotch;
  public faTimes = faTimes;
  public loadSpin: boolean = false;

  public infoColletion: any = {};
  public organizationsPrices: any = {};

  infoPricing = {
    subscription_days: 0,
    price: null,
    subscription_type: null,
  };
  constructor(
    private adminService: AdminService,
    private toastService: ToastService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.infoColletion = {
      id: this.course?.id,
      open: this.course?.open,
    };

    this.organizations = this.course.organizations;
    this.organizationsPrices.pricing = this.course.subscription_price;
    this.organizationsPrices.prolong_price = this.course.prolong_price;
    this.organizationsPrices.renew_price = this.course.renew_price;

    this.organizations.forEach((organization, i) => {
      if (
        !this.organizations[i].pricing ||
        !this.organizations[i].prolong_price ||
        !this.organizations[i].renew_price
      ) {
        this.organizations[i].pricing = {};
        this.organizations[i].prolong_price = {};
        this.organizations[i].renew_price = {};
      }

      this.organizations[i].pricing = this.parseData(
        this.organizationsPrices.pricing,
        organization,
        null
      );
      this.organizations[i].prolong_price = this.parseData(
        this.organizationsPrices.prolong_price,
        organization,
        1
      );
      this.organizations[i].renew_price = this.parseData(
        this.organizationsPrices.renew_price,
        organization,
        2
      );
    });
  }
  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  public parseData(
    arrayData: any,
    organization: any,
    subscription_type: number | null = null
  ) {
    let days: any = {
      days30: { price: '', subscription_type, subscription_days: 30 },
      days90: { price: '', subscription_type, subscription_days: 90 },
      days180: { price: '', subscription_type, subscription_days: 180 },
      days365: { price: '', subscription_type, subscription_days: 365 },
    };

    arrayData.forEach((element: any, i: number) => {
      if (organization.id == element.organization_id) {
        if (element.subscription_days == 30) {
          arrayData[i].subscription_type = subscription_type;
          days.days30 = element;
        }
        if (element.subscription_days == 90) {
          arrayData[i].subscription_type = subscription_type;
          days.days90 = element;
        }
        if (element.subscription_days == 180) {
          arrayData[i].subscription_type = subscription_type;
          days.days180 = element;
        }
        if (element.subscription_days == 365) {
          arrayData[i].subscription_type = subscription_type;
          days.days365 = element;
        }
      }
    });
    return days;
  }

  public putInfoCourse() {
    this.adminService
      .patchEditCourse(this.course.id, this.infoColletion)
      .then((resp: any) => {
        this.loadSpin = false;
        this.toastService.showSuccess(
          this.translate.instant('courses:pricing:save:success')
        );
        this.refresh.emit();
        this.closeModal();
      })
      .catch((error) => {
        this.toastService.showError(`${error}`);
      });
  }

  public sendInfoPricing() {
    let info: any = [];
    this.loadSpin = true;
    let arrayPromise: any = [];

    this.organizations.forEach((organization: any) => {
      for (const key in organization.pricing) {
        info.push({
          price: organization.pricing[key].price,
          subscription_days: organization.pricing[key].subscription_days,
          subscription_type: organization.pricing[key].subscription_type,
        });
      }
      for (const key in organization.prolong_price) {
        info.push({
          price: organization.prolong_price[key].price,
          subscription_days: organization.prolong_price[key].subscription_days,
          subscription_type: organization.prolong_price[key].subscription_type,
        });
      }
      for (const key in organization.renew_price) {
        info.push({
          price: organization.renew_price[key].price,
          subscription_days: organization.renew_price[key].subscription_days,
          subscription_type: organization.renew_price[key].subscription_type,
        });
      }
      arrayPromise.push(this.returnPromise(organization.id, info));
      info = [];
    });

    Promise.all(arrayPromise).then((resp) => {
      this.putInfoCourse();
    });
  }

  public returnPromise(organizationId: number, data: any): Promise<any> {
    return this.adminService.priceOrganization(
      this.course.id,
      organizationId,
      data
    );
  }
  evitarGuion(event: KeyboardEvent,model:any=null) {
    console.log('model',model)
    const keyCode = event.keyCode;
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
      event.preventDefault();
    }
    if (event.key === '-') {
      event.preventDefault();
    }
    if (model>100){
      console.log('es mas de 100')
    }
    
  }
}
