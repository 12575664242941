<div class="container-fluid background-yellow px-0">
  <header>
    <div class="header-develop">
      <div class="container">
        <div class="row pt-4 align-items-center text-start">
          <p (click)="goBack()" class="back px-0 my-1 pb-0 ms-2">
            <img
              class="back-icon"
              src="/assets/img/dh-landing/back.png"
              alt=""
            />
            {{ "back:button:levels" | translate }}
          </p>
        </div>
        <div class="row pt-2 align-items-center flex-column text-center">
          <h1 class="title col-12 col-md-7 my-3">
            {{ "level3:title" | translate }}
          </h1>
        </div>
        <div class="row my-4 justify-content-center text-center">
          <p class="description col-12 col-md-8 mb-4">
            {{ "level3:subtitle" | translate }}
          </p>
        </div>

        <div class="row justify-content-center gap-5">
          <button class="btn btn-level3 col-auto" (click)="getFormLevel3()">
            <span style="color: #fff">{{
              "take:free:questionaire" | translate
            }}</span>
          </button>
          <!-- <button class="btn btn-level3-gray col-auto">
            <span>Learn more about Double Healix</span>
            <img
              class="right"
              src="/assets/img/dh-landing/levels/level-4/right-gray.png"
              alt=""
            />
          </button> -->
        </div>
      </div>
    </div>
  </header>
  <div class="row justify-content-center px-0">
    <div class="col-11 col-md-9 card-1-level3 my-5">
      <div class="row justify-content-between">
        <div class="col-sm-7 col-12">
          <p class="title-card">
            {{ "level3:card1:title" | translate }}
          </p>
          <p class="description-card">
            {{ "level3:card1:description" | translate }}
          </p>
        </div>
        <div class="col-12 col-sm-auto">
          <img
            class="img-card"
            src="/assets/img/dh-landing/levels/img-card1.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-11 col-md-9 card-2-level3 my-3">
      <div class="row">
        <div class="col-auto">
          <img
            class="img-card"
            [src]=" currentLang == 'en' ?  '/assets/img/dh-landing/levels/img-card2.png' : '/assets/img/dh-landing/levels/img-card2-NL.png'"
            alt=""
          />
        </div>
        <div class="col-md-7 col-12">
          <p class="title-card">
            {{ "level3:card2:title" | translate }}
          </p>
          <p class="description-card">
            {{ "level3:card2:description" | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center mt-5">
    <div class="col-11 col-md-6 card-3-level3 my-3">
      <div class="row">
        <p class="title-card text-center">
          {{ "level3:card3:title" | translate }}
        </p>
        <p class="description-card">
          {{ "level3:card3:description" | translate }}
        </p>
      </div>
      <div class="row justify-content-center mt-3">
        <button (click)="getFormLevel3()" class="btn btn-level3 col-auto">
          <span style="color: #fff">{{
            "Take free questionnaire" | translate
          }}</span>
        </button>
      </div>
    </div>
  </div>
  <div class="container-footer">
    <div class="footer-container">
      <div class="row pt-5 align-items-center flex-column text-center">
        <h2 class="footer-title col-12 col-md-7 my-4">
          {{ "footer:landing" | translate }}
        </h2>
        <p class="footer-landing col-12 col-md-9 mb-5 mt-4">
          {{ "description:footer:landing" | translate }}
        </p>

        <a
          class="btn mt-5 btn-footer col-auto"
          target="__blank"
          href="https://doublehealix.com/het-double-healix-model/"
        >
          {{ "button:learn:model" | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
