<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title">
    {{ course?.name | translate }}
  </h4>
</div>
<div class="modal-body flex-column mx-2">
  <div class="row justify-content-center my-2">
    <div class="col-auto">
      <app-movie-tabs-wrapper [main]="true">
        <li class="nav-item">
          <button
            (click)="getParticipants()"
            class="nav-link active py-3"
            data-bs-toggle="tab"
            type="button"
            role="tab"
          >
            {{ "Participants" | translate }}
          </button>
        </li>
        <li class="nav-item">
          <button
            (click)="getExParticipants()"
            class="nav-link py-3"
            data-bs-toggle="tab"
            type="button"
            role="tab"
          >
            {{ "Ex Participants" | translate }}
          </button>
        </li>
      </app-movie-tabs-wrapper>
    </div>
  </div>
  <div class="users">
    <div class="row mt-4" *ngFor="let user of users">
      <div class="col-8 d-flex">
        <div class="col-2">
          <td class="align-middle">
            <app-user-icon size="medium" [user]="user.user"></app-user-icon>
          </td>
        </div>
        <div class="col ms-3">
          <p class="mb-0">{{ user.user.display_name }}</p>
          <p class="mb-0" style="color: #302e2e">{{ user.user?.email }}</p>
        </div>
      </div>
      <div class="col-3">
        <app-progress-bar
          [progress]="(user?.course_progress > 1 ? 1 : user?.course_progress ) * 100"
          [showLabelText]="false"
          [labelPosition]="'top'"
        ></app-progress-bar>
      </div>
    </div>
  </div>
</div>
