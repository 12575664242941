<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">{{ "groups:create:title" | translate }}</h4>
</div>
<div class="modal-body">
  <div class="form-group">
    <label class="form-label" for="clip-title">{{
      "groups:form:name:label" | translate
    }}</label>
    <input
      [(ngModel)]="group.name"
      class="input-box w-100"
      id="clip-title"
      maxlength="80"
      name="name"
      placeholder="{{ 'groups:form:name:placeholder' | translate }}"
      required=""
      type="text"
    />
  </div>

  <div class="form-group">
    <label class="mt-3 form-label mb-1" for="clip-title">{{
      "group:edit:settings:label" | translate
    }}</label>
    <p class="text-explanation">
      {{ "group:profile:settings:cant_change" | translate }}
    </p>
    <label class="fancy-radio-checkbox form-label">
      <input
        checked
        [(ngModel)]="group.open"
        class="fancy-input"
        id="popen"
        name="privacyOpen"
        type="radio"
        [value]="true"
      />
      <div class="fancy-graphic"></div>
      {{ "groups:form:privacy:settings:open:label" | translate }}
    </label>

    <div class="small-form-text">
      {{ "groups:form:privacy:settings:open:subtitle:label" | translate }}
    </div>
    <label class="fancy-radio-checkbox form-label">
      <input
        [(ngModel)]="group.open"
        class="fancy-input"
        id="pclosed"
        name="privacyClose"
        type="radio"
        [value]="false"
      />
      <div class="fancy-graphic"></div>
      {{ "groups:form:privacy:settings:closed:label" | translate }}
    </label>
    <div class="small-form-text">
      {{ "groups:form:privacy:settings:closed:subtitle:label" | translate }}
    </div>
  </div>

  <hr class="common-separator" />

  <div class="form-group">
    <label class="form-label" for="clip-title">{{
      "groups:form:visibility:settings:label" | translate
    }}</label>
    <label class="fancy-radio-checkbox form-label">
      <input
        [(ngModel)]="group.public"
        class="fancy-input"
        id="open"
        name="visibility"
        type="radio"
        [value]="true"
      />
      <div class="fancy-graphic"></div>
      {{ "groups:form:visibility:settings:visible:label" | translate }}
    </label>
    <div class="small-form-text">
      {{ "groups:form:visibility:settings:visible:subtitle:label" | translate }}
    </div>
    <label class="fancy-radio-checkbox form-label">
      <input
        [(ngModel)]="group.public"
        class="fancy-input"
        id="closed"
        name="visibility"
        type="radio"
      />
      <div class="fancy-graphic"></div>
      {{ "groups:form:visibility:settings:hidden:label" | translate }}
    </label>
    <div class="small-form-text">
      {{ "groups:form:visibility:settings:hidden:subtitle:label" | translate }}
    </div>
  </div>
  <hr class="common-separator" />

  <div class="form-group clearfix">
    <button
      [disabled]="group.name.length < 4"
      (click)="create()"
      type="button"
      role="tab"
      class="btn btn-primary btn-create modal-btn-width float-right ms-2 fs-15"
    >
      <fa-icon
        class="ms-2"
        [icon]="faCircleNorch"
        *ngIf="loading"
        [spin]="true"
      ></fa-icon>
      {{ "groups:form:create:label" | translate }}
    </button>
  </div>
</div>
