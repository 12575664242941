import { CustomConfirmComponent } from './../../custom-confirm/custom-confirm.component';
import { CoursesService } from 'src/app/services/courses.service';
import { Component, Input, OnInit } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { GroupsService } from 'src/app/services/groups.service';
import { ToastService } from 'src/app/services/toast.service';
import * as moment from 'moment';
@Component({
  selector: 'app-invite-members',
  templateUrl: './invite-members.component.html',
  styleUrls: ['./invite-members.component.scss'],
})
export class InviteMembersComponent implements OnInit {
  ///////////
  //Atributes
  ///////////
  faTimes = faTimes;
  @Input() selfModal: NgbModalRef | null = null;
  @Input() course: any;
  @Input() hideButtons: boolean = true;
  @Input()  language: string = 'en';
  public faCircleNorch = faCircleNotch;
  public usersInvited: any = [];
  public actualTab = 'inviteNewUser';
  public loading: boolean = false;
  public selectUsers: any = [];
  public textName: any = '';
  public loadSpin: boolean = false;
  public textMessage: string = '';
  public days: number = 30;
  public custom_value: number = 1;
  

  public users: any = [];
  public membersEnrolled: any = [];
  public exParticipant: any = [];
  public enrolledUser: any = [];
  
  currentDate = moment();
  constructor(
    private groupsService: GroupsService,
    private toastService: ToastService,
    private translate: TranslateService,
    private courseService: CoursesService,
    private ngbModal: NgbModal
  ) {}

  ngOnInit(): void {
    this.getMembersInvited();
    this.getMembersEnrolled();
  }

  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  getTab(tab: string) {
    this.actualTab = tab;
    if (this.actualTab == 'invited') {
      this.getMembersInvited();
    }
  }

  getMembersInvited() {
    this.loading = true;
    this.courseService.getMembersInvited(this.course.id).then((result: any) => {
      this.usersInvited = result.data.enrolled_users;

      this.loading = false;
    });
  }
  public getMembersEnrolled() {
    this.loading = true;
    this.courseService.getMembers(this.course.id).then((resp: any) => {
      this.loading = false;
      this.enrolledUser=this.exParticipant = resp.data.enrolled_users
      this.exParticipant = resp.data.enrolled_users.filter((item: any) => {
        return this.currentDate > moment(item.access_until);
      });
    });
  }

  usersSelected(data: any) {
    this.selectUsers = data;
    // this.textName =  this.selectUsers[this.selectUsers.length-1].display_name;
  }

  sendInvitation() {
    let days=this.days;
    if (this.days==-1){
      if (this.custom_value === null || this.custom_value === undefined || this.custom_value === 0 ||this.custom_value < 1) {
        this.toastService.showError(
          this.translate.instant('Custom value must be a positive integer')
        );
       
      }
      days=this.custom_value 
    }
  
    let allUserInvited: any;
    this.loadSpin = true;
    allUserInvited = this.selectUsers.map((user: any) => {
      return {
        display_name: user.display_name,
        email: user.email,
        message: this.textMessage,
      };
    });

    this.courseService
      .sendInvitations(this.course.id, allUserInvited, days, this.language)
      .then((data: any) => {
        this.selectUsers = [];
        this.toastService.showSuccess(
          this.translate.instant('collection:members:add:members:success')
        );
        this.loadSpin = false;
        this.closeModal();
      })
      .catch((err) => {
        console.log(err);
        this.toastService.showError(
          this.translate.instant('organization:members:send_invitation:error')
        );
      });
  }

  public deleteUserInvited(index: number, memberId: number) {
    let modalRef = this.ngbModal.open(CustomConfirmComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.selfModal = modalRef;
    modalRef.componentInstance.title =
      'collection:members:action:remove:invitation';
    modalRef.componentInstance.description =
      'course:members:modal:delete_invited:text';
    modalRef.componentInstance.buttonText =
      'course:members:modal:delete:button';
    modalRef.componentInstance.refresh.subscribe((data: any) => {
      this.courseService
        .deleteInvitation(this.course.id, memberId)
        .then((resp: any) => {
          if (resp.result == 'success') {
            this.toastService.showSuccess(
              this.translate.instant(
                'collection:members:remove:invitation:success'
              )
            );
            this.getMembersInvited();
          } else {
            this.closeModal();
            this.toastService.showSuccess('Error');
          }
        });
    });
  }
}
