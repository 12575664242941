<div class="container">
  <div class="row pt-5 align-items-center flex-column text-center">
    <h1 class="title-development col-12 col-md-7 my-3">
      {{ "home:title" | translate }}
    </h1>
    <p class="description-development col-12 col-md-7">
      {{ "home:subtitle" | translate }}
    </p>
  </div>
  <div class="row justify-content-center pb-5">
    <div class="col-8 col-md-auto text-center mb-3 mb-md-0">
      <button (click)="openVideoModal()" class="btn btn-secondary">
        <i class="fa fa-play ms-1"> </i>
        {{ "home:video:button" | translate }}
      </button>
    </div>
    <div class="col-8 col-md-auto text-center">
      <a class="btn btn-primary" (click)="gotToReviews()">
        {{ "home:reviews:button" | translate }}
      </a>
    </div>
  </div>
  <div class="row justify-content-center pb-5">
    <div
      *ngFor="let item of infoCardsDevelopment"
      [style.border]="item.border"
      class="card col-md-3 col-11 mx-3 mb-3"
    >
      <div class="card-body text-center">
        <img [src]="item.icon" alt="" class="img-card mb-2" />
        <h5 class="card-title">{{ item.title | translate }}</h5>
        <p class="card-text description-development">
          {{ item.description | translate }}
        </p>
      </div>
    </div>
  </div>
</div>
