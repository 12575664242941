<div class="modal-close"  (click)="closeModal()">
    <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon><span >{{ "btn:close" | translate }}</span>
</div>

<div class="modal-header">
    <h4 class="modal-title">{{"course:add:modal:title:lesson"|translate}}</h4>
</div>

<div class="modal-body">
  <div class="container py-4">
    <app-search-input
      [inputPlaceholder]="'Search lesson'"
      [actualSearch]="this.searchText"
      [loading]="loading"

      [searchText]="'Search'"
      (searchEvent)="search($event)"
      *ngIf="actualTab=='allCourse'"
    ></app-search-input>

    <div *ngIf="loading" class="row max-heigth">
      <div class="row loadingCenter">
          <app-loading-icon ></app-loading-icon>
      </div>
    </div>

    <div *ngIf="!loading" class="row justify-content-center max-heigth list-overflow">
      <div  *ngFor="let lesson of lessons" class="row justify-content-center">
        <div class="col-xl-12 p-0" style="margin-right: 4px;">
          <app-lesson-list-card [lesson]="lesson" [itemSelect]="itemSelect" (selectItem)="selectItem($event)" class="p-0"></app-lesson-list-card>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="form-group clearfix">
    <button (click)="sendData()" [disabled]="itemSelect==''"  type="button" role="tab" class="btn btn-primary btn-create modal-btn-width float-right ms-2 fs-15">
      <fa-icon class="ms-2" [icon]="faCircleNorch" *ngIf="addLoading"  [spin]="true"></fa-icon>
      {{ onLesson ? 'Add lesson' : "course:add:modal:title" | translate}}
    </button>
  </div>
</div>

