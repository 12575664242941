<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">{{ "courses:modal:reviews:title" | translate }}</h4>
</div>
<div class="modal-body">
  <div
    *ngIf="course.coverPathUrlMedium"
    class="the-image col-md-2"
    [ngStyle]="{ 'background-image': 'url(' + course.coverPathUrlMedium + ')' }"
  ></div>

  <div class="description fw-bold">
    {{ "congratulations:title:50" | translate }}
  </div>
  <div class="description">{{ "congratulations:text:50" | translate }}</div>

  <div class="form-group clearfix">
    <button
      (click)="createReview()"
      type="button"
      role="tab"
      class="btn btn-primary btn-create modal-btn-width float-right ms-2 fs-15 mt-4"
    >
      {{ "btn:write_review" | translate }}
    </button>
    <a
      (click)="closeModal()"
      class="btn btn-link float-right mt-4 mb-3 font-weight-Light mx-2 border"
    >
      <span> {{ "btn:return:to:courses" | translate }}</span>
    </a>
  </div>
</div>
