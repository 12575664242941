import { Component, Input, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
declare var kWidget: any;
@Component({
  selector: 'app-how-itworks',
  templateUrl: './how-itworks.component.html',
  styleUrls: ['./how-itworks.component.scss'],
})
export class HowItworksComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() videoId: number | null = null;
  faTimes = faTimes;

  constructor() {}

  ngOnInit(): void {
    let flashvars = {
      'video.volume': 0.1,
      'autoPlay': true
    };

    setTimeout(() => {
      kWidget?.embed({
        targetId: 'kaltura_player',
        wid: '_' + '192',
        uiconf_id: '23456301',
        flashvars: flashvars,
        cache_st: this.videoId,
        entry_id: this.videoId,
        readyCallback: (playerID: any) => {
          let kdp: any = document.getElementById('kaltura_player');
          setTimeout(() => {
            kdp.sendNotification("changeVolume", 0.5);
          }, 1000);
        }
      });
    }, 15);
  }

  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
}
