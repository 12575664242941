import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { CoursesService } from 'src/app/services/courses.service';
import { ToastService } from 'src/app/services/toast.service';
import { CourseUtilitiesService } from 'src/app/utilities/course-utilities.service';
import { MainComponent } from '../../checkout/main/main.component';
import { MainComponent as loginComponent } from 'src/app/modals/login/main/main.component';
import { environment } from 'src/environments/environment';
import { LangService } from 'src/app/services/lang.service';
@Component({
  selector: 'app-enrol-course-modal',
  templateUrl: './enrol-course-modal.component.html',
  styleUrls: ['./enrol-course-modal.component.scss'],
})
export class EnrolCourseModalComponent implements OnInit {
  faTimes = faTimes;
  @Input() description: string = '';
  @Input() selfModal: NgbModalRef | null = null;
  @Input() title: String = '';
  @Input() chapterNumber: String = '';
  @Input() course: any;
  @Input() chapter: any;
  loadSpin: boolean = false;
  faCircleNorch: any = faCircleNotch;
  public idCourseEnv = environment?.nl_compentencyCourseId
  public language = 'nl';
  constructor(
    private coursesService: CoursesService,
    private toastService: ToastService,
    private translateService: TranslateService,
    public auth: AuthService,
    private ngbModal: NgbModal,
    public courseUtils: CourseUtilitiesService,
    private router: Router,
    private lang: LangService,
  ) {
    this.lang.language.subscribe((lang) => {
      this.language = lang || 'en';
      if (this.language == 'en') {
        this.idCourseEnv = environment?.en_compentencyCourseId
      } else {
        this.idCourseEnv = environment?.nl_compentencyCourseId
      }
    });
  }

  ngOnInit(): void {}
  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  public enrollCourse() {
    if (!this.isAuthenticated()) {
      this.openLoginModal();
    } else {
      if (!this.chapter.access) {
        this.checkout();
        return;
      }
      this.loadSpin = true;
      this.coursesService
        .patchEnrollCourse(this.course.id)
        .then((res) => {
          this.loadSpin = false;
          if (res.result == 'success') {
            this.toastService.showSuccess(
              this.translateService.instant('courses:join:open:success')
            );
            this.closeModal();
          }
          if (res.error) {
            this.toastService.showError(res.error.message.friendly);
          }
          this.loadCourse();
        })
        .catch((err) => {
          this.toastService.showError(
            this.translateService.instant('collection:open:enroll:error')
          );
        });
    }
  }

  loadCourse() {
    this.coursesService.loadCourseSummary(this.course.id);
  }

  isAuthenticated() {
    return this.auth.isAuthenticated();
  }
  checkout(modalInfo = null): void {
    let modalRef = this.ngbModal.open(MainComponent, {
      windowClass: 'default-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.course = this.course;
    modalRef.componentInstance.modalInfo = modalInfo;
    modalRef.componentInstance.selfModal = modalRef;
    modalRef.componentInstance.initialDays = 30;
    modalRef.componentInstance.ngOnChanges();
  }
  canBuy(): boolean {
    return this.courseUtils.userCanBuy(this.course!);
  }
  isOpen(): boolean {
    return this.courseUtils.isOpen(this.course);
  }

  openLoginModal() {
    let modalRef = this.ngbModal.open(loginComponent, {
      windowClass: 'login-modal',
    });
    modalRef.componentInstance.state = 'login';
    modalRef.componentInstance.selfModal = modalRef;
  }
  public goTobuyChapters() {
    this.closeModal();
    this.router.navigate(['/dh/level/3'], {
      queryParams: { chapter: this.chapterNumber },
    });
  }
}
