<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title">
    {{ "collection:make:member:modal:title" | translate }}
  </h4>
</div>
<div class="modal-body flex-column">
  <div class="row mb-3">
    <div class="col-auto">
      <app-user-icon [user]="user"></app-user-icon>
    </div>
    <div class="col-auto px-0">
      <h6 class="mt-2">
        {{ user.display_name }}
      </h6>
    </div>
  </div>
  <div class="row">
    <p class="description fw-bold">
      {{ "collection:share:other:organizations:modal:invite:text" | translate }}
    </p>
  </div>
  <div class="row">
    <div
      class="col-auto organization-list"
      *ngFor="let organization of organizations"
    >
      <label class="fw-bold">
        <input
          [disabled]="true"
          class="form-check-input"
          type="checkbox"
          [(ngModel)]="organization.checked"
        />
        {{ organization.name }}
      </label>
    </div>
  </div>
  <div class="form-group mb-3">
    <label for="clip-title" class="d-block mt-3 modal-label">{{
      "collection:share:other:organizations:modal:message:text" | translate
    }}</label>
    <textarea
      cols="30"
      rows="6"
      name="description"
      type="text"
      class="input-box w-100"
      placeholder="{{
        'collection:share:other:organizations:modal:message:default' | translate
      }}"
      [(ngModel)]="message"
      autofocus
    >
    </textarea>
  </div>
</div>
<div class="modal-footer">
  <div class="d-flex justify-content-end">
    <button
      (click)="sendMessage()"
      type="button"
      role="tab"
      class="btn btn-success col-auto"
    >
      <fa-icon
        class="mr-2"
        [icon]="faCircleNorch"
        *ngIf="loading"
        [spin]="true"
      ></fa-icon
      >{{ "Add user to organizatios" | translate }}
    </button>
  </div>
</div>
