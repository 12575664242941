import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Organization } from 'src/app/interfaces/organization';
import { AuthService } from 'src/app/services/auth.service';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { ToastService } from 'src/app/services/toast.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-delete-account-modal',
  templateUrl: './delete-account-modal.component.html',
  styleUrls: ['./delete-account-modal.component.scss'],
})
export class DeleteAccountModalComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() userId: any;
  @Input() username: string | undefined;
  @Input() deleteFromMembers: boolean = false;
  public password: string = '';
  passErr=false;
  public faTimes = faTimes;
  public faCircleNotch = faCircleNotch;
  public loading = false;
  showPassword=false;

  currentOrg: Organization | null = null;
  constructor(
    private usersService: UsersService,
    private toastService: ToastService,
    private ngbModal: NgbModal,
    private auth: AuthService,
    private router: Router,
    private orgsService: OrganizationsService
  ) {}

  ngOnInit(): void {
    this.currentOrg = this.orgsService.getCurrent();
  }

  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  public deleteAccount() {
    this.loading = true;

    this.usersService
      .deleteAccountProfile(this.userId, this.password)
      .then((res: any) => {
        if (res.result == 'success') {
          this.closeModal();
          if (this.deleteFromMembers) {
            this.router.navigate([
              '/organization',
              this.currentOrg?.id,
              'members',
            ]);
          } else {
            this.router.navigate(['/']);
            this.auth.logOut();
          }
        }
        console.log('res',res)
        if (res.status == 422) {
          console.log('res 422')
          this.passErr=true;
          this.toastService.showError(res.error.message.friendly);
        }
        if (res.status == 401) {
          this.toastService.showError(res.error.message.friendly);
        }
        this.loading = false;
      })
      .catch((err) => {
        console.log('error',err)
        this.toastService.showError(err);
        this.loading = false;
      });
  }
  showHidePassword() {
    this.showPassword = !this.showPassword;
  }
}
