import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Organization } from 'src/app/interfaces/organization';
import { AuthService } from 'src/app/services/auth.service';
import { InitService } from 'src/app/services/init.service';
import { OrganizationsService } from 'src/app/services/organizations.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  faBars = faBars;
  @Output() sidebarToggled = new EventEmitter<string>();
  @Input() organizations: Array<Organization> = [];
  @Input() showItems: boolean = true;
  public currentOrganization: Organization | null = null;

  constructor(
    private initService: InitService,
    private auth: AuthService,
    private orgsService: OrganizationsService
  ) {}

  ngOnInit(): void {
    console.log('organizations', this.organizations);
    
    this.currentOrganization = this.initService.getCurrentOrg();
  }
  isAdmin(): boolean {
    return this.auth.hasAnyRole(['moderator', 'super_user']);
  }
  toggleSidebar() {
    this.sidebarToggled.emit();
  }
  switchOrganization(organization: Organization): void {
    const currentOrg = this.initService.getCurrentOrg();
    if (currentOrg && currentOrg.slug != organization.slug) {
      let aDomain = window.location.host.split('.');
      aDomain[0] = organization.slug;
      window.location.href =
        window.location.protocol + '//' + aDomain.join('.');
    } else {
      window.location.reload();
    }
  }
  isAuthenticated(){
    return  this.auth.isAuthenticated();
  }

}
