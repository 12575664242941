import { AdminService } from 'src/app/services/admin.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
})
export class OrderDetailComponent implements OnInit {
  @Input() orderId: any = '';
  @Input() selfModal: NgbModalRef | null = null;
  @Input() closeBoton: boolean = true;

  
  public data: any = {};
  public loading: boolean = false;
  public faCircleNorch: any = faCircleNotch;
  public compentencyPrice: any = 24.5;
  public competencies: any = [];
  language = 'en';
  public faTimes = faTimes;
  constructor(private adminService: AdminService,private lang: LangService) {
    this.lang.language.subscribe((lang) => {
      this.language = lang || 'en';
    });
  }

  ngOnInit(): void {
    this.getDetail();
  }

  getDetail() {
    this.loading = true;
    this.adminService
      .getOrderDetail(this.orderId)
      .then((resp) => {
        this.loading = false;
        this.data = resp.data;
        this.competencies = JSON.parse(this.data.competency_data);
        console.log(' this.competencies ', this.competencies )
        console.log(' this.data ', this.data )
      })
      .catch((err) => {});
  }
  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
}
