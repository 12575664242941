import { CourseDetailComponent } from './../course-detail/course-detail.component';
import { RemoveFromPlatformComponent } from './../remove-from-platform/remove-from-platform.component';
import { EditUserComponent } from './../edit-user/edit-user.component';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/services/admin.service';
import { OrderDetailComponent } from '../../order/order-detail/order-detail.component';
import { DeactivateOneMemberComponent } from 'src/app/modals/organizations/deactivate-one-member/deactivate-one-member.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-users-admin-detail',
  templateUrl: './users-admin-detail.component.html',
  styleUrls: ['./users-admin-detail.component.scss'],
})
export class UsersAdminDetailComponent implements OnInit {
  @Output() refresh = new EventEmitter<any>();
  @Output() openAgain = new EventEmitter<any>();

  @Input() userName: any = '';
  @Input() selfModal: NgbModalRef | null = null;
  public data: any = {};
  public loading: boolean = false;
  public faCircleNorch: any = faCircleNotch;
  public avatar: any = {};
  public faTimes = faTimes;
  public currentTab: string = 'order';
  public listOrders: any = [];
  public compentencyPrice: any = 24.5;
  public platforms: any = [];
  public courses: any = [];
  public coursesList: any = [];
  public userId: any = '';
  public status: string = '';
  constructor(private adminService: AdminService, 
    private translate: TranslateService,
    private ngbModal: NgbModal) {}

  ngOnInit(): void {
    this.getUserDetail();
  }
  public getTab(tab: string) {
    this.currentTab = tab;
    if (this.currentTab == 'order') {
    }
    if (this.currentTab == 'platforms') {
    }
    if (this.currentTab == 'courses') {
    }
  }
  getUserDetail() {
    this.loading = true;
    this.adminService
      .getUserDetail(this.userName)
      .then((resp) => {
        this.data = resp.data;
        this.userId = this.data.id;
        this.platforms = this.data.organizations;
        this.getOrderUser();
        this.status = this.data?.account_deactivated;
        this.avatar = {
          avatar: this.data.avatar,
          avatarPathUrlMedium: this.data.avatarPathUrlMedium,
        };
      })
      .catch((err) => {});
  }
  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
  public getOrderUser() {
    this.adminService.getOrderUser(this.userId).then((resp: any) => {
      this.listOrders = resp.data.data;
      console.log(' this.listOrders', this.listOrders)

      this.listOrders.forEach((order:any) => {
        
        if (order.competency_data){
   
          order.product_name=this.translate.instant('focus:competencies:text')
        } 

        
      });


      this.getCoursesUser();
    });
  }

  public getCoursesUser() {
    this.adminService.getCoursesUser(this.userId).then((resp: any) => {
      this.courses = resp.data.collections;
      this.courses.forEach((course:any) => {
        course.order.forEach((order:any) => {
          if (order.completed_at||order.email||order.gift_user_id){
            let sub_days=order.order_entry[0].subscription_days
            let completedAtDate = new Date(order.completed_at); // Convertir a objeto Date
            if (!order.completed_at){
               completedAtDate = new Date(order.created_at); // Convertir a objeto Date

            }
            console.log('order',order)
            let start_date=order.start_date?order.start_date:order.completed_at
            let invited=false
            if (order.email||order.gift_user_id){
              //quiere decir que fue invitado
              invited=true
            }
            let name=course.name
            if (order.competency_data){
              name='focus:competencies:text';
            } 
            let data={
              'course_id':course.id,
              'competency':order.competency_data?true:false,
              'competencies':JSON.parse(order.competency_data),
              'id': order.id,
              'name':name,
              'start_date':start_date?start_date:order.created_at,
              'end_date': new Date(completedAtDate.setDate(completedAtDate.getDate() + sub_days)),
              'subscription_days': sub_days,
              'invited':invited?order.created_at:null
            }
            this.coursesList.push(data)

              // Filtrar los elementos que contienen 'teamroleprofile:checkout:product' en el campo 'name'
            this.coursesList = this.coursesList
                .filter((course:any) => !course.name.includes('teamroleprofile:checkout:product'))
                .sort((a:any, b:any) => a.id - b.id); // Ordenar por id en orden ascendente



          }
          
        });

        
      });
      console.log('this.coursesList',this.coursesList)
      console.log('this.listOrders',this.listOrders)
      const ordersNotInAlt = this.listOrders.filter((order:any) =>
        !this.coursesList.some((altOrder:any) => altOrder.id === order.id)
      );
      console.log('this.ordersNotInAlt',ordersNotInAlt)
      //ordersNotInAlt son las ordenes que se invitaron y aun no fueron aceptadas
      ordersNotInAlt.forEach((order:any) => {
        let invited=false;
        let sub_days=order.entries[0].subscription_days
        if (order.email||order.gift_user_id){
          //quiere decir que fue invitado
          invited=true
        }
        let data={
          'id': order.id,
          'name':order.collections[0].name,
          'course_id':order.collections[0].id,
          'start_date':null,
          'end_date': null,
          'subscription_days': sub_days,
          'invited':invited?order.created_at:null
        }
        this.coursesList.push(data)
      });
      

      this.loading = false;
    });
  }
  
  public openCourse(orderId: any) {
    const url = `/courses/${orderId}`;
    window.open(url, '_blank'); // Abre la URL en una nueva pestaña

  }
  public openDetailOrder(orderId: any) {
  //  this.closeModal();
    let modalRef = this.ngbModal.open(OrderDetailComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.orderId = orderId;
    modalRef.componentInstance.closeBoton = false;
  }

  public openCourseDetail(item: string) {
    this.closeModal();
    let modalRef = this.ngbModal.open(CourseDetailComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.course = item;
    modalRef.componentInstance.refresh.subscribe((data: any) => {
      this.openAgain.emit();
    });
    modalRef.componentInstance.openOrderDetail.subscribe((data: any) => {
      this.openDetailOrder(data);
    });
  }

  public openEdit(user: any) {
    this.closeModal();
    let modalRef = this.ngbModal.open(EditUserComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.user = user;
    modalRef.componentInstance.refresh.subscribe((data: any) => {
      this.refresh.emit();
    });
    modalRef.componentInstance.refreshEdit.subscribe((data: any) => {
      this.getUserDetail();
    });
  }

  public openRemoveFromPlatform(platform: any) {
    this.closeModal();
    let modalRef = this.ngbModal.open(RemoveFromPlatformComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.user = this.data;
    modalRef.componentInstance.data = platform;
    modalRef.componentInstance.refreshEdit.subscribe((data: any) => {
      this.getUserDetail();
    });
  }

  public deactivateMember(memberId: string, activate: boolean = false) {
    let modalRef = this.ngbModal.open(DeactivateOneMemberComponent, {
      windowClass: 'default-modal',
      size: '600',
      centered: false,
    });
    modalRef.componentInstance.memberId = memberId;
    modalRef.componentInstance.activate = activate;
    modalRef.componentInstance.refresh.subscribe((data: any) => {
      this.getUserDetail();
    });
  }
}
