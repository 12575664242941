<form action="">
  <label for="language" class="form-label fw-bold fs-15">{{ 'video:edit:modal:language:label' | translate }} *</label>
  <nav>
    <div class="nav btn-group d-block" id="nav-tab" role="tablist">
      <button class="btn btn-outline-primary active" id="nav-en-tab" data-bs-toggle="tab" data-bs-target="#nav-language-en" type="button" role="tab" aria-controls="nav-language-en" aria-selected="true">
        {{ "language:options:en" | translate }} <i *ngIf="details.en.name && details.en.description" class="fa fa-check ms-2"></i>
      </button>
      <button class="btn btn-outline-primary" id="nav-nl-tab" data-bs-toggle="tab" data-bs-target="#nav-language-nl" type="button" role="tab" aria-controls="nav-language-nl" aria-selected="false">
        {{ "language:options:nl" | translate }} <i *ngIf="details.nl.name && details.nl.description" class="fa fa-check ms-2"></i>
      </button>
    </div>
  </nav>

  <div class="tab-content mt-3" id="nav-tabContent">
    <div class="tab-pane fade show active" id="nav-language-en" role="tabpanel" aria-labelledby="nav-home-tab">
      <div class="mb-3 col-5">
        <label for="name-en" class="form-label fw-bold fs-15">{{ 'video:edit:modal:clip_title:label' | translate }} *</label>
        <input type="text"  maxlength="55" [(ngModel)]="details.en.name" (ngModelChange)="touched('details')" id="name-en" name="name-en" class="form-control d-block" [placeholder]="'video:edit:modal:clip_title:placeholder' | translate">
      </div>
      <div class="mb-3">
        <label for="description-en" class="form-label fw-bold fs-15 ">{{ 'video:edit:modal:description:label' | translate }} *</label>
        <textarea [(ngModel)]="details.en.description" rows="7" (ngModelChange)="touched('details')" (input)="autoGrowTextAreaEn()" #myTextareaEn id="description-en" name="description-en" [placeholder]="'video:edit:modal:description:placeholder' | translate" class="form-control textAreaItem ms-1"></textarea>
      </div>
    </div>
    <div class="tab-pane fade" id="nav-language-nl" role="tabpanel" aria-labelledby="nav-language-nl-tab"><div class="mb-3">
      <label for="name-nl" class="form-label fw-bold fs-15">{{ 'video:edit:modal:clip_title:label' | translate }} *</label>
      <input type="text"maxlength="55" [(ngModel)]="details.nl.name" (ngModelChange)="touched('details')" id="name-nl" name="name-nl" class="form-control d-block" [placeholder]="'video:edit:modal:clip_title:placeholder' | translate">

    </div>
    <div class="mb-3">
      <label for="description-nl" class="form-label fw-bold fs-15 ">{{ 'video:edit:modal:description:label' | translate }} *</label>
      <textarea [(ngModel)]="details.nl.description" (ngModelChange)="touched('details')" id="description-nl" (input)="autoGrowTextAreaNl()" name="description-nl" #myTextareaNl [placeholder]="'video:edit:modal:description:placeholder' | translate" rows="7" class="form-control ms-1"></textarea>
    </div></div>
  </div>

  <hr [ngClass]="{'my-5': !videoEditService.isModal}">

  <div class="mb-3">
    <label for="language" class="form-label fw-bold fs-15">{{ 'video:edit:modal:source_type:label' | translate }} *</label><br>
    <div class="btn-group" role="group">
      <input type="radio" [(ngModel)]="videoEditService.source.origin_type" (ngModelChange)="removeVideoSourceOrigin()" class="btn-check" name="orgin-type" id="orgin-type_film" value="film" autocomplete="off" checked>
      <label class="btn btn-outline-primary" for="orgin-type_film">{{ "video:edit:modal:source_type:film" | translate }}</label>

      <input type="radio" [(ngModel)]="videoEditService.source.origin_type" (ngModelChange)="removeVideoSourceOrigin()" class="btn-check" name="orgin-type" id="orgin-type_tvshow" value="tvshow" autocomplete="off">
      <label class="btn btn-outline-primary" for="orgin-type_tvshow">{{ "video:edit:modal:source_type:tv_show" | translate }}</label>

      <input type="radio" [(ngModel)]="videoEditService.source.origin_type" (ngModelChange)="removeVideoSourceOrigin()" class="btn-check" name="orgin-type" id="orgin-type_documentary" value="documentary" autocomplete="off">
      <label class="btn btn-outline-primary" for="orgin-type_documentary">{{ "video:edit:modal:source_type:documentary" | translate }}</label>

      <input type="radio" [(ngModel)]="videoEditService.source.origin_type" (ngModelChange)="removeVideoSourceOrigin()" class="btn-check" name="orgin-type" id="orgin-type_dhproduction" value="dhproduction" autocomplete="off">
      <label class="btn btn-outline-primary" for="orgin-type_dhproduction">{{ "video:edit:modal:source_type:dh_production" | translate }}</label>

      <input type="radio" [(ngModel)]="videoEditService.source.origin_type" (ngModelChange)="removeVideoSourceOrigin()" class="btn-check" name="orgin-type" id="orgin-type_homevideo" value="homevideo" autocomplete="off">
      <label class="btn btn-outline-primary" for="orgin-type_homevideo">{{ "video:edit:modal:source_type:home_video" | translate }}</label>

      <input type="radio" [(ngModel)]="videoEditService.source.origin_type" (ngModelChange)="removeVideoSourceOrigin()" class="btn-check" name="orgin-type" id="orgin-type_commercial" value="commercial" autocomplete="off">
      <label class="btn btn-outline-primary" for="orgin-type_commercial">{{ "video:edit:modal:source_type:commercial" | translate }}</label>
    </div>
  </div>

  <div class="mb-3">
    <label class="form-label fw-bold fs-15" for="imdb-search">
      <span *ngIf="videoEditService.source.origin_type == 'film'">{{ "video:edit:modal:source_type:film" | translate }}</span>
      <span *ngIf="videoEditService.source.origin_type == 'tvshow'">{{ "video:edit:modal:source_type:tv_show" | translate }}</span>
      <span *ngIf="videoEditService.source.origin_type == 'documentary'">{{ "video:edit:modal:source_type:documentary" | translate }}</span>

      <span *ngIf="videoEditService.source.origin_type == 'dhproduction'">{{ "video:edit:modal:source_type:dh_production" | translate }}</span>
      <span *ngIf="videoEditService.source.origin_type == 'homevideo'">{{ "video:edit:modal:source_type:home_video" | translate }}</span>
      <span *ngIf="videoEditService.source.origin_type == 'commercial'">{{ "video:edit:modal:source_type:commercial" | translate }}</span>

      <span *ngIf="videoEditService.source.origin_type == 'film' || videoEditService.source.origin_type === 'tvshow' || videoEditService.source.origin_type === 'documentary'">*</span>
    </label>

    <app-imdb-search [type]="videoEditService.source.origin_type" (notFound)="notFound($event)" (selected)="movieSelected($event)" *ngIf="!videoEditService.source.origin.type && ( videoEditService.source.origin_type === 'film' || videoEditService.source.origin_type === 'tvshow' || videoEditService.source.origin_type === 'documentary' ) && !IMDBNotFound; else movieData"></app-imdb-search>

    <app-loading-icon *ngIf="loadingImdb"></app-loading-icon>

    <ng-template #movieData>
      <div class="row" *ngIf="!IMDBNotFound && videoEditService.source.origin.type; else movieForm">
        <div *ngIf="videoEditService.source.origin.poster" class="col-2">
          <img class="w-100" [src]="videoEditService.source.origin.poster" alt="">
        </div>
        <div class="col position-relative">
          <div class="tv-show-film-extra-info-text" ng-class="{ 'has-image': videoEditService.source.origin.poster }">
              <div class="mb-3">
                <input class="form-control" id="film_title" name="film_title" type="text" [placeholder]=" 'video:edit:modal:film:placeholder' | translate " [(ngModel)]="videoEditService.source.origin.title" (ngModelChange)="touched('source')">
              </div>
              <div class="mb-3">
                <textarea class="form-control"  id="film_desc" name="film_desc" [placeholder]=" 'video:edit:modal:film:description:label' | translate " [(ngModel)]="videoEditService.source.origin.plot" (ngModelChange)="touched('source')"></textarea>
              </div>
              <ul class="list-unstyled fs-14" *ngIf="videoEditService.source.origin.director || videoEditService.source.origin.writer || videoEditService.source.origin.actors || videoEditService.source.origin.genre">
                <li *ngIf="videoEditService.source.origin.director">{{ "video:edit:modal:film:director" | translate }}: {{ videoEditService.source.origin.director }}</li>
                <li *ngIf="videoEditService.source.origin.writer">{{ "video:edit:modal:film:writer" | translate }}: {{ videoEditService.source.origin.writer }}</li>
                <li *ngIf="videoEditService.source.origin.actors">{{ "video:edit:modal:film:actors" | translate }}: {{ videoEditService.source.origin.actors }}</li>
                <li *ngIf="videoEditService.source.origin.genre">{{ "video:edit:modal:film:genre" | translate }}: {{ videoEditService.source.origin.genre }}</li>
                <li *ngIf="videoEditService.source.origin.production">{{ "video:edit:modal:film:production" | translate }}: {{ videoEditService.source.origin.production }}</li>
              </ul>
          </div>
          <button class="btn btn-outline-danger btn-sm position-absolute bottom-0 end-0" (click)="removeVideoSourceOrigin()">{{ "video:edit:modal:button:remove" | translate }}</button>
        </div>
      </div>

      <ng-template #movieForm>
        <input class="form-control mb-3" name="24" type="text" [placeholder]="'video:edit:modal:film:placeholder' | translate " [(ngModel)]="videoEditService.source.origin.title" (ngModelChange)="touched('source')"/>
        <textarea class="form-control mb-3" name="23" [placeholder]="'video:edit:modal:film:description:label' | translate" [(ngModel)]="videoEditService.source.origin.plot" (ngModelChange)="touched('source')"></textarea>
        <textarea class="form-control" name="25" [placeholder]="'video:edit:modal:copyright:placeholder' | translate" [(ngModel)]="videoEditService.source.origin.copyright" (ngModelChange)="touched('source')"></textarea>
        <div class="row mt-2" *ngIf="IMDBNotFound">
          <div class="col text-end">
            <button class="btn btn-outline-danger btn-sm" (click)="removeVideoSourceOrigin()">{{ "video:edit:modal:button:remove" | translate }}</button>
          </div>
        </div>
      </ng-template>
    </ng-template>
  </div>

  <div class="mb-3">
    <label for="spoken-lang" class="form-label fw-bold fs-15">{{ 'video:edit:modal:spoken_language:label' | translate }} *</label><br>
    <div class="btn-group" role="group">

      <ng-container *ngFor="let lang of pLanguages">
        <input type="radio" [(ngModel)]="videoEditService.source.spoken_language" (ngModelChange)="touched('source')" class="btn-check" name="spoken-lang" [id]="'spoken-lang_' + lang" [value]="lang" autocomplete="off">
        <label class="btn btn-outline-primary" [for]="'spoken-lang_' + lang">{{ "language:options:" + lang | translate }}</label>
      </ng-container>

      <div class="btn-group" role="group">
        <button class="btn dropdown-toggle" [ngClass]="languages.includes(videoEditService.source.spoken_language) ? 'btn-primary' : 'btn-outline-primary'" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
          <span *ngIf="pLanguages.includes(videoEditService.source.spoken_language)">{{ 'extra_language:more:dropdown' | translate }}</span>
          <span *ngIf="languages.includes(videoEditService.source.spoken_language)">{{ 'language:options:' + videoEditService.source.spoken_language | translate }}</span>
        </button>
        <ul class="dropdown-menu dropdown-menu-movie" aria-labelledby="dropdownMenuButton2">
          <ng-container *ngFor="let lang of languages">
            <li>
              <input type="radio" [(ngModel)]="videoEditService.source.spoken_language" (ngModelChange)="touched('source')" class="btn-check" name="spoken-lang" [id]="'spoken-lang_' + lang" [value]="lang" autocomplete="off">
              <label class="dropdown-item pe-pointer" [ngClass]="{ active: lang == videoEditService.source.spoken_language}" [for]="'spoken-lang_' + lang">{{ "language:options:" + lang | translate }}</label>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>

  <div class="mb-3">
    <label for="subtitle-lang" class="form-label fw-bold fs-15">{{ 'video:edit:modal:subtitle_language:label' | translate }} *</label><br>
    <div class="btn-group" role="group">

      <ng-container *ngFor="let lang of pLanguages">
        <input type="radio" [(ngModel)]="videoEditService.source.subtitle_language" (ngModelChange)="touched('source')" class="btn-check" name="subtitle-lang" [id]="'subtitle-lang_' + lang" [value]="lang" autocomplete="off">
        <label class="btn btn-outline-primary" [for]="'subtitle-lang_' + lang">{{ "language:options:" + lang | translate }}</label>
      </ng-container>

      <div class="btn-group" role="group">
        <button class="btn dropdown-toggle" [ngClass]="languages.includes(videoEditService.source.subtitle_language) ? 'btn-primary' : 'btn-outline-primary'" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
          <span *ngIf="pLanguages.includes(videoEditService.source.subtitle_language)">{{ 'extra_language:more:dropdown' | translate }}</span>
          <span *ngIf="languages.includes(videoEditService.source.subtitle_language)">{{ 'language:options:' + videoEditService.source.subtitle_language | translate }}</span>
        </button>
        <ul class="dropdown-menu dropdown-menu-movie" aria-labelledby="dropdownMenuButton2">
          <ng-container *ngFor="let lang of languages">
            <li>
              <input type="radio" [(ngModel)]="videoEditService.source.subtitle_language" (ngModelChange)="touched('source')" class="btn-check" name="subtitle-lang" [id]="'subtitle-lang_' + lang" [value]="lang" autocomplete="off">
              <label class="dropdown-item pe-pointer" [ngClass]="{ active: lang == videoEditService.source.subtitle_language}" [for]="'subtitle-lang_' + lang">{{ "language:options:" + lang | translate }}</label>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>

  <div>
    <label for="explicit" class="form-label fw-bold fs-15">{{ 'video:edit:modal:contains_explicit_content:label' | translate }} *</label><br>
    <div class="btn-group" role="group">
      <input type="radio" [(ngModel)]="videoEditService.source.explicit_content" (ngModelChange)="touched('source')" class="btn-check" name="explicit" id="explicit_no" [value]="false" autocomplete="off" checked>
      <label class="btn btn-outline-primary" for="explicit_no">{{"video:edit:explicit_content:no" | translate}}</label>

      <input type="radio" [(ngModel)]="videoEditService.source.explicit_content" (ngModelChange)="touched('source')" class="btn-check" name="explicit" id="explicit_yes" [value]="true" autocomplete="off">
      <label class="btn btn-outline-primary" for="explicit_yes">{{"video:edit:explicit_content:yes" | translate}}</label>
    </div>
    <p class="text-muted fs-14 mt-2 mb-0">{{ 'video:edit:modal:contains_explicit_content:explanation' | translate }}</p>
  </div>
</form>
