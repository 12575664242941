<div class="container-courses px-4">
  <div
    *ngFor="let course of courses"
    class="continue-watching row mx-auto my-3"
    (click)="goCourse(course)"
  >
    <div class="col-md-5 px-0 img-course">
      <!-- <img
        [src]="course.collection?.coverPathUrlMedium"
        class="the-image"
        alt=""
        onerror="this.src='assets/img/groups/icons/list.png'"
      /> -->
      <div
        *ngIf="course?.collection?.coverPathUrlMedium; else errorimg"
        class="the-image"
        [ngStyle]="{
          'background-image':
            'url(' + course?.collection?.coverPathUrlMedium + ')'
        }"
      ></div>
      <ng-template #errorimg>
        <div
          class="the-image"
          [ngStyle]="{
            'background-image': 'url(assets/img/groups/icons/list.png)'
          }"
        ></div>
      </ng-template>
      <div class="continue-progress-bar col-12">
        <app-progress-bar
          [progress]="
            courseProgress(course?.course_progress, course?.collection?.chapter)
          "
        ></app-progress-bar>
      </div>
    </div>
    <div class="col-md-7 p-3">
      <div class="row">
        <div class="col-12 mb-4 title-continue">
          <h5>
            {{ "continueWatching:title" | translate }}:
            {{ course?.collection?.name }}
          </h5>
        </div>
        <div class="col mt-2 time">
          <p *ngIf="course.collection.duration" class="my-0 text-muted col">
            <fa-icon [icon]="faClock"></fa-icon>
            <span class="ms-1">{{
              course.collection.duration +
                course?.collection?.qa_duration * 60000 | duration
            }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
