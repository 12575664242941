import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-deactivate-one-member',
  templateUrl: './deactivate-one-member.component.html',
  styleUrls: ['./deactivate-one-member.component.scss'],
})
export class DeactivateOneMemberComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() memberId: any | null = [];
  @Input() activate: boolean = true;
  @Output() refresh = new EventEmitter<any>();
  public faCircleNorch: any = faCircleNotch;
  public title: string = '';
  public textButton: string = '';
  public faTimes = faTimes;
  public loadSpin: boolean = false;
  constructor(
    private organizationService: OrganizationsService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {}
  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
  public deactiveAccount() {
    this.loadSpin = true;
    this.organizationService
      .deactivateOrActivateOneMember(this.memberId, this.activate)
      .then((resp) => {
        if (resp.result == 'success') {
          this.loadSpin = false;
          if (this.activate) {
            this.toastService.showSuccess('The member has been activated');
          } else {
            this.toastService.showSuccess('The member has been deactivated');
          }
          this.closeModal();
          this.refresh.emit(this.activate);
        } else {
          this.toastService.showError('Error');
        }
      })
      .catch((error) => {
        this.toastService.showError(error);
      });
  }
}
