import { Component, Input, OnInit } from '@angular/core';
import { Organization } from 'src/app/interfaces/organization';
import { InitService } from 'src/app/services/init.service';

@Component({
  selector: 'app-organization-logo',
  templateUrl: './organization-logo.component.html',
  styleUrls: ['./organization-logo.component.scss']
})
export class OrganizationLogoComponent implements OnInit {
  @Input() organization: Organization | null | any = null

  constructor(
    private initService: InitService
  ) {
  }
  
  ngOnInit(): void {
    if (!this.organization) {
      this.organization = this.initService.getCurrentOrg();
    }
    
  }

}
