import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GroupsService } from 'src/app/services/groups.service';
import { ToastService } from 'src/app/services/toast.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-create-group-modal',
  templateUrl: './create-modal.component.html',
  styleUrls: ['./create-modal.component.scss'],
})
export class CreateGroupModalComponent implements OnInit {
  faTimes = faTimes;
  @Input() selfModal: NgbModalRef | null = null;
  @Input() title: String = '';
  faCircleNorch = faCircleNotch;
  loading = false;
  group = {
    type: 'group',
    name: '',
    open: true,
    public: true,
    invitation_access: 'owner',
  };
  constructor(
    private groupsService: GroupsService,
    private toastService: ToastService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {}
  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
  create() {
    this.loading = true;
    this.groupsService
      .save(this.group)
      .then((res) => {
        this.toastService.showSuccess(
          this.translate.instant('groups:form:save:settings:label')
        );
        let id = res.data.collection.id;
        this.router.navigate(['/groups', 'edit', id]);

        this.loading = false;
        this.closeModal();
      })
      .catch((err) => {
        if (err.error?.message?.friendly) {
          this.toastService.showError(
            this.translate.instant(err.error?.message?.friendly)
          );
        } else {
          this.toastService.showError(
            this.translate.instant('groups:profile:error')
          );
        }
        this.loading = false;
      });
  }
}
