import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Organization } from 'src/app/interfaces/organization';
import { Video } from 'src/app/interfaces/video/video';
import { ApiService } from 'src/app/services/api.service';
import { InitService } from 'src/app/services/init.service';
import { ToastService } from 'src/app/services/toast.service';
import { VideoEditService } from '../video-edit.service';

@Component({
  selector: 'app-tags-form',
  templateUrl: './tags-form.component.html',
  styleUrls: ['./tags-form.component.scss']
})
export class TagsFormComponent implements OnInit {
  @Output('touch') touch: EventEmitter<boolean> = new EventEmitter();
  @Input('video') video: Video|undefined;

  currentOrg: Organization | null = null;

  search = '';
  searchChanged: Subject<string> = new Subject<string>();
  searching = 0
  showResults = false
  results: Array<any> = []

  tags: Array<any> = [];

  constructor(
    private initService: InitService,
    private api: ApiService,
    public videoEditService: VideoEditService,
    private toastr: ToastService
  ) {
    this.searchChanged.pipe(
      debounceTime(500),
      distinctUntilChanged()
    )
    .subscribe( s => {
      this.search = s;
      this.searching += 1;

      this.api.get('/search/tags?q=' + s).then( (res) => {
        this.searching -= 1;
        this.results = res.data.tags;
      })
      this.showResults = true;
    });
  }

  ngOnInit(): void {
    this.currentOrg = this.initService.getCurrentOrg();
  }

  touched() {
    this.touch.emit(true)
  }

  selectTag(tag: any): void {
    this.results = [];
    this.showResults = false;
    this.search = '';

    let selectedTag = {
      selected: true,
      info: {
          name: tag.name,
          description: tag.description,
          language: tag.language,
          tag_translation_id: tag.id
      },
      context: tag.tag.context,
      parent_tag_id: tag.tag.parent_tag_id,
      id: tag.tag.tag_id,
      verified: tag.tag.verified,
      tag_type: 'user'
    };

    this.videoEditService.userTags.push(selectedTag);

    this.touched()
  }

  changed(text: string) {
    this.searchChanged.next(text);
  }

  notFoundClick(): void {
    let searchCopy = this.search;
    this.search = '';
    let lang: string = this.currentOrg?.language || 'en';
    let tagToAPI: any = {
      info: {}
    };
    tagToAPI.info[lang] = {name: searchCopy};

    this.api.postJSON('/tag', tagToAPI).then( (response) => {
        if(response.result !== 'success') {
          this.toastr.showError("Something went wrong adding your tag");
        } else {
            let id = response.data.tag.id;

            let tag = {
              selected: true,
              id: id,
              verified: false,
              tag_type : 'user',
              info: {
                name: searchCopy,
                language: lang
              }
            };

            let tagAlreadyInUserTags = this.videoEditService.userTags.find( t => t.id == tag.id );
            if (tagAlreadyInUserTags) {
              tagAlreadyInUserTags[0].selected = true;
            } else {
              this.videoEditService.userTags.push(tag);
            }

            this.touched();
        }
    });
  }

  removeTag(tag: any): void {
    let selectedTags = this.videoEditService.userTags.filter( t => t.id == tag.id);
    if (selectedTags.length) {
      selectedTags.forEach( st => {
        st.selected = !st.selected;

        if (st.tag_type === 'doublehealix') {
          this.toggleDHTag(tag);
        }
      });
    }

    this.touched();
  }

  toggleDHTag(tag: any) {
    // check if is already selected in the general tag list
    let isAlreadySelected = this.videoEditService.dhtags.filter( (t: any) => t.tag_context_id == tag.tag_context_id).length;

    if (isAlreadySelected) {
      tag.selected = false;

      this.videoEditService.dhtags = this.videoEditService.dhtags.filter( (t: any) => t.tag_context_id != tag.tag_context_id);
    } else {
      tag.selected = true;

      // search in user tags
      let selectTag: any = this.videoEditService.userTags.filter( (t: any) => t.id == tag.tag_id )[0];
      if (selectTag) {
          selectTag.selected = tag.selected;
      }

      this.videoEditService.dhtags.push(tag);
    }
  }

  save() {
    let tagPromises: Array<any> = [];

    this.videoEditService.userTags.forEach( (tag: any) => {
      if (!tag.selected) {
        return;
      }
      if (tag.tag_type != 'user') {
        return;
      }

      let apiTag = {
        tag_type: 'user',
        tag_id: tag.id
      };

      if (!this.videoEditService.userTagsBeforeChanges.find( t => t.id == tag.id )) {
        tagPromises.push(this.api.post("/video/" + this.video?.id + "/analysis/tag", apiTag).then( (resp) => {
          if (resp.result === 'success') {
            // If the API results succes we can append our added tag to the userTagsBeforeChanges variable
            tag.analysis_record_id = resp.video.analysis.id;
            tag.tag_type = apiTag.tag_type;
            this.videoEditService.userTagsBeforeChanges.push(tag);
          }

          return resp;
        }));
      }
    });

    let tagsBeforeUpdate = this.videoEditService.userTagsBeforeChanges.filter( t => t.tag_type == 'user' ).map( t => t.id);
    let tagsAfterUpdate = this.videoEditService.userTags.filter( t => t.tag_type == 'user' && t.selected ).map( t => t.id);
    let tagsToDelete = tagsBeforeUpdate.filter(t => !tagsAfterUpdate.includes(t));

    tagsToDelete.forEach( tag_id => {
      let tag = this.videoEditService.userTagsBeforeChanges.find( t => t.tag_type == 'user' && t.id == tag_id);

      tagPromises.push(this.api.delete("/video/" + this.video?.id + "/analysis/" + tag.analysis_record_id).then( (resp: any) => {
        if (resp.result === 'success') {
          // If the API results succes we can delete our remote tag from the userTagsBeforeChanges variable
          this.videoEditService.userTags = this.videoEditService.userTags.filter( (t: any) => {
            return t.analysis_record_id != tag.analysis_record_id
          });
          this.videoEditService.userTagsBeforeChanges = this.videoEditService.userTagsBeforeChanges.filter( (t: any) => {
            return t.analysis_record_id != tag.analysis_record_id
          });
          if (tag.tag_type == 'doublehealix') {
            this.videoEditService.dhtags = this.videoEditService.dhtags.filter( (t: any) => {
              return t.analysis_record_id != tag.analysis_record_id
            });
            this.videoEditService.dhtagsBeforeChanges = this.videoEditService.dhtagsBeforeChanges.filter( (t: any) => {
              return t.analysis_record_id != tag.analysis_record_id
            });
          }
        }
        return resp;
      }));
    });

    return Promise.all(tagPromises).then( (results: any) => {
      if (results.filter( (r: any) => r.result == 'error' ).length > 0) {
        this.toastr.showError('something went wrong updating clip tags');
      } else if (results.filter( (r: any) => r.result == 'success' ).length == results.length) {
        this.touch.emit(false)
      } else {
        this.toastr.showError('unknown error. something went wrong updating clip tags');
      }
    }).catch( (err) => {
      this.toastr.showError('unknown error. something went wrong updating clip tags');
    });
  }
}
