import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-delete-members',
  templateUrl: './delete-members.component.html',
  styleUrls: ['./delete-members.component.scss'],
})
export class DeleteMembersComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() members: any | null = {};
  @Input() organizationId: any;
  @Input() numbAccounts: any;
  @Output() refreshDelete = new EventEmitter<any>();
  public password: string = '';

  public faCircleNorch: any = faCircleNotch;

  public faTimes = faTimes;
  public loadSpin: boolean = false;

  constructor(
    private organizationService: OrganizationsService,
    private toastService: ToastService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {}
  ngOnInit(): void {}
  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  public deleteMembers() {
    let arrayKey = [];
    for (let k in this.members) {
      arrayKey.push(+k);
    }
   this.loadSpin = true; 
   this.organizationService
   .deleteMembers(this.organizationId, arrayKey, this.password)
   .then((data: any) => {
     if (data.result == 'success') {
       this.toastService.showSuccess('Users deleted');
       this.loadSpin = false; 
       this.refreshDelete.emit();
          this.closeModal();
        } else {
          this.closeModal();
          this.toastService.showError('Error');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
