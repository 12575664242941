<!-- [routerLink]="['/groups', groups?.id]" -->
<div  class="card shadow" [routerLink]="['/groups', groups?.id]">
    <span class="badge badge-item-type text-primary px-3">{{ 'groups:group' | translate }}</span>

    <img [src]="groupImg" class="card-img-top" width="100%" height="130">


    <div class="card-body position-relative">
        <span class="badge rounded-pill p-2 px-3 text-white position-absolute fw-normal" [ngClass]="groups?.status == 'permission_needed' ? 'bg-primary' : 'bg-success'">
            {{ 'groups:status:' + groups?.status | translate }}
        </span>
        <div class="block-image-group-avatar" [ngStyle]="{'background-image':'  url(' + avatarImg+ ')'}">
        </div>
            

        <h3 class="card-title mb-2 mt-2 fw-normal short-name">{{ groups?.name }}</h3>
        <!-- <p class="card-text">
            <small class="text-muted">{{ "author:by" | translate }} {{ groups?.user?.display_name }}</small>
        </p> -->
        <p class="card-text short-description" [innerHTML]="groups?.description"></p>

        <div class="d-flex justify-content-start align-items-center" style="min-height: 21px;">
            
                <fa-icon [icon]="faPlay" class="text-muted-light"></fa-icon>
                <span class="ms-1">

                    {{ groups?.users_count_accepted }} {{ (groups?.users_count_accepted == 1 ? 'groups:members:count:singular' : 'groups:members:count:plural') | translate }}

                </span>
        
        </div>
    </div>
</div>