import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ToastService } from '../services/toast.service';
import { OrganizationUtilitiesService } from '../utilities/organization-utilities.service';

@Injectable({
  providedIn: 'root'
})
export class NotAuthenticatedGuard implements CanActivate {
  constructor(
    private orgUtils: OrganizationUtilitiesService,
    private router: Router,
    private toastService: ToastService,
    private auth: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if ( this.auth.isAuthenticated() ) {
      this.toastService.showError('Already Authenticated');
      return this.router.navigateByUrl('/');
    }
    return true;
  }
}
