<ul
  class="list-unstyled font-styles"
  [ngClass]="{ 'nav justify-content-center': !inSidebar }"
>
  <li (click)="toggleState()" class="nav-item">
    <a
      *ngIf="isAuthenticated(); else platform"
      [routerLink]="['/']"
      class="nav-link px-md-2"
      [ngClass]="{ active: isActive('/') }"
      >{{ "navbar:discover" | translate }}</a
    >
    <ng-template #platform>
      <a
        class="nav-link px-md-2"
        [href]="currentLang == 'en' ? env + 'en/' : env"
        >{{ "navbar:discover" | translate }}</a
      >
    </ng-template>
  </li>

  <li class="nav-item" *ngIf="orgUtils.isPublic()">
    <a
      [routerLink]="['/courses']"
      class="nav-link px-md-2"
      [ngClass]="{ active: isActive('/courses') }"
      >{{ "navbar:courses" | translate }}</a
    >
  </li>

  <li
    class="nav-item"
    *ngIf="!orgUtils.isPublic()"
    [ngClass]="{ dropdown: !inSidebar }"
  >
    <span
      class="nav-link px-md-2"
      d="navbarDropdown"
      role="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ "navbar:develop" | translate }}
      <i *ngIf="!inSidebar" class="fa fa-angle-down"></i>
    </span>
    <ul
      class="list-unstyled ms-3"
      [ngClass]="{
        'dropdown-menu dropdown-menu-movie dropdown-menu-center dropdown-bubble':
          !inSidebar
      }"
      aria-labelledby="navbarDropdown"
    >
      <li>
        <a
          (click)="toggleState()"
          [routerLink]="['/courses']"
          [ngClass]="{
            'dropdown-item': !inSidebar,
            'nav-link': inSidebar,
            active: isActive('/courses')
          }"
          >{{ "navbar:courses" | translate }}</a
        >
      </li>
      <li
        (click)="toggleState()"
        *ngIf="!orgUtils.isPublic() && isAuthenticated()"
      >
        <a
          [routerLink]="['/playlists']"
          [ngClass]="{
            'dropdown-item': !inSidebar,
            'nav-link': inSidebar,
            active: isActive('/playlists')
          }"
          href="#"
          >{{ "navbar:playlist" | translate }}</a
        >
      </li>
      <li (click)="toggleState()" *ngIf="!orgUtils.isPublic()">
        <a
          [routerLink]="['/video']"
          [ngClass]="{
            'dropdown-item': !inSidebar,
            'nav-link': inSidebar,
            active: isActive('/video')
          }"
          href="#"
          >{{ "navbar:videos" | translate }}</a
        >
      </li>
    </ul>
  </li>

  <li
    (click)="toggleState()"
    class="nav-item"
    *ngIf="!orgUtils.isPublic() && isAuthenticated()"
  >
    <a
      [routerLink]="['/groups']"
      class="nav-link px-md-2"
      [ngClass]="{ active: isActive('/groups') }"
      >{{ "navbar:groups" | translate }}</a
    >
  </li>

  <!-- <li class="nav-item" *ngIf="hasDHProfileAccess && !isDHProfileAdmin()">
    <a
      (click)="toggleState()"
      [routerLink]="['/dh/multi-profile']"
      class="nav-link px-md-2"
      [ngClass]="{ active: isActive('/dh/profile') }"
    >
      {{ "Double Healix Profile" | translate }}
    </a>
  </li> -->
  <li class="nav-item" *ngIf="orgUtils.isPublic()">
    <a
      [href]="
        currentLang == 'en'
          ? env + 'en/learn-from-examples-in-movies'
          : env + 'leren-van-filmvoorbeelden'
      "
      class="nav-link px-md-2"
      [target]="isAuthenticated() ? '_blank' : ''"
      >{{ "navbar:how" | translate }}</a
    >
  </li>
  <li class="nav-item" *ngIf="orgUtils.isPublic()">
    <a
      [href]="
        currentLang == 'en'
          ? env + 'en/for-organizations'
          : env + 'voor-organisaties'
      "
      class="nav-link px-md-2"
      [target]="isAuthenticated() ? '_blank' : ''"
      >{{ "navbar:for:organizations" | translate }}</a
    >
  </li>
  <li
    *ngIf="auth.isAuthenticated()"
    class="nav-item"
    [ngClass]="{ dropdown: !inSidebar }"
  >
    <span
      class="nav-link px-md-2"
      d="navbarDropdown"
      role="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ "navbar:dh_profile" | translate }}
      <i *ngIf="!inSidebar" class="fa fa-angle-down"></i>
    </span>
    <ul
      class="list-unstyled ms-4"
      [ngClass]="{
        'dropdown-menu dropdown-menu-movie dropdown-menu-center dropdown-bubble dropdown-team':
          !inSidebar
      }"
      aria-labelledby="navbarDropdown"
    >
      <li *ngIf="hasDHProfileAccess">
        <a
          (click)="toggleState()"
          [routerLink]="['/dh/multi-profile']"
          [ngClass]="{
            'dropdown-item': !inSidebar,
            'nav-link': inSidebar,
            active: isActive('/dh/multi-profile')
          }"
        >
          {{ "navbar:your:dh_profile:landing" | translate }}
        </a>
      </li>
      <li>
        <a
          (click)="toggleState()"
          [routerLink]="['/dh/landing']"
          [ngClass]="{
            'dropdown-item': !inSidebar,
            'nav-link': inSidebar,
            active: isActive('/dh/landing')
          }"
        >
          {{ "navbar:dh_profile:landing" | translate }}
        </a>
      </li>

      <li *ngIf="isDHProfileAdmin()">
        <a
          (click)="toggleState()"
          [routerLink]="['/dh/team']"
          [ngClass]="{
            'dropdown-item': !inSidebar,
            'nav-link': inSidebar,
            active: isActive('/dh/team')
          }"
          href="#"
        >
          {{ "navbar:user:management" | translate }}
        </a>
      </li>
    </ul>
  </li>
  <!-- <li
  class="nav-item"
  *ngIf="isDHProfileAdmin()"
  [ngClass]="{ dropdown: !inSidebar }"
>
  <span
    class="nav-link px-md-2"
    d="navbarDropdown"
    role="button"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    {{ "navbar:dh_profile" | translate }}
    <i *ngIf="!inSidebar" class="fa fa-angle-down"></i>
  </span>
  <ul
    class="list-unstyled ms-4"
    [ngClass]="{
      'dropdown-menu dropdown-menu-movie dropdown-menu-center dropdown-bubble dropdown-team':
        !inSidebar
    }"
    aria-labelledby="navbarDropdown"
  >
    <li>
      <a
        (click)="toggleState()"
        [routerLink]="['/dh/landing']"
        [ngClass]="{
          'dropdown-item': !inSidebar,
          'nav-link': inSidebar,
          active: isActive('/dh/landing')
        }"
      >
        {{ "navbar:dh_profile:landing" | translate }}
      </a>
    </li>
    <li>
      <a
        (click)="toggleState()"
        [routerLink]="['/dh/multi-profile']"
        [ngClass]="{
          'dropdown-item': !inSidebar,
          'nav-link': inSidebar,
          active: isActive('/dh/multi-profile')
        }"
      >
        {{ "navbar:your:dh_profile:landing" | translate }}
      </a>
    </li>
    <li>
      <a
        (click)="toggleState()"
        [routerLink]="['/dh/team']"
        [ngClass]="{
          'dropdown-item': !inSidebar,
          'nav-link': inSidebar,
          active: isActive('/dh/team')
        }"
        href="#"
      >
        {{ "navbar:user:management" | translate }}
      </a>
    </li>
  </ul>
</li> -->
  <li
    class="nav-item"
    *ngIf="orgUtils.isPublic()"
    [ngClass]="{ dropdown: !inSidebar }"
  >
    <span
      class="nav-link px-md-2"
      d="navbarDropdown"
      role="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ "navbar:about" | translate }}
      <i *ngIf="!inSidebar" class="fa fa-angle-down"></i>
    </span>
    <ul
      class="list-unstyled ms-3"
      [ngClass]="{
        'dropdown-menu dropdown-menu-movie dropdown-menu-center dropdown-bubble':
          !inSidebar
      }"
      aria-labelledby="navbarDropdown"
    >
      <li>
        <a
          [href]="
            currentLang == 'en'
              ? env + 'en/about-the-foundation'
              : env + 'over-de-stichting'
          "
          [target]="isAuthenticated() ? '_blank' : ''"
          [ngClass]="{
            'dropdown-item': !inSidebar,
            'nav-link': inSidebar
          }"
          >{{ "navbar:movie" | translate }}</a
        >
      </li>
      <li>
        <a
          [href]="
            currentLang == 'en'
              ? env + 'en/double-healix'
              : env + 'double-healix'
          "
          [target]="isAuthenticated() ? '_blank' : ''"
          [ngClass]="{
            'dropdown-item': !inSidebar,
            'nav-link': inSidebar
          }"
          >{{ "navbar:about:healix" | translate }}</a
        >
      </li>
      <li>
        <a
          [href]="currentLang == 'en' ? env + 'en/contact' : env + 'contact'"
          [target]="isAuthenticated() ? '_blank' : ''"
          [ngClass]="{
            'dropdown-item': !inSidebar,
            'nav-link': inSidebar
          }"
          >{{ "navbar:about:contact" | translate }}</a
        >
      </li>
    </ul>
  </li>
  <li class="nav-item" *ngIf="orgUtils.isPublic()">
    <a
      [href]="currentLang == 'en' ? env + 'en/videos' : env + 'videos'"
      class="nav-link px-md-2"
      [target]="isAuthenticated() ? '_blank' : ''"
      >{{ "navbar:media" | translate }}</a
    >
  </li>
  <!-- <li
    class="nav-item"
    *ngIf="hasDHProfileAccess && !isDHProfileAdmin()"
    [ngClass]="{ dropdown: !inSidebar }"
  >
    <span
      class="nav-link px-md-2"
      d="navbarDropdown"
      role="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{'Double Healix Profile'|translate}} <i *ngIf="!inSidebar" class="fa fa-angle-down"></i>
    </span>
    <ul
      class="list-unstyled ms-4"
      [ngClass]="{
        'dropdown-menu dropdown-menu-movie dropdown-menu-center dropdown-bubble dropdown-team':
          !inSidebar
      }"
      aria-labelledby="navbarDropdown"
    >
      <li>
        <a
          (click)="toggleState()"
          [routerLink]="['/dh/multi-profile']"
          [ngClass]="{
            'dropdown-item': !inSidebar,
            'nav-link': inSidebar,
            active: isActive('/dh/profile')
          }"
        >
        {{'Your Double Healix Profile'|translate}}
        </a>
      </li>
      <li>
        <a
          (click)="toggleState()"
          [routerLink]="['/dh/landing']"
          [ngClass]="{
            'dropdown-item': !inSidebar,
            'nav-link': inSidebar,
            active: isActive('/dh/landing')
          }"
          href="#"
        >
         {{'About the Double Healix Profile'|translate}}

        </a>
      </li>
    </ul>
  </li> -->
  <!-- <li class="nav-item" *ngIf="!hasDHProfileAccess && orgUtils.isPublic() && isAuthenticated()">
    <a
      (click)="toggleState()"
      [routerLink]="['/dh/landing']"
      class="nav-link px-md-2"
      [ngClass]="{ active: isActive('/dh/landing') }"
    >
      
      {{ "navbar:dh_profile:landing" | translate }}
    </a>
  </li> -->

  <li *ngIf="inSidebar && isAuthenticated()">
    <h6 class="dropdown-header fw-lighter text-muted fw-500 switch-platform">
      <small>{{ "organization:switchplatform" | translate }}</small>
    </h6>
    <div class="choose-orgs-container">
      <a
        class="d-flex ps-2 org-line"
        (click)="switchOrganization(organization)"
        *ngFor="let organization of organizations"
      >
        <app-organization-logo
          [organization]="organization"
        ></app-organization-logo>
        <div
          class="flex-grow-1 d-flex align-items-center mx-2 text-dark fw-600 fs-14"
        >
          {{ organization.name }}
        </div>
      </a>
    </div>
  </li>
</ul>
