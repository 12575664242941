import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-subscription-show',
  templateUrl: './subscription-show.component.html',
  styleUrls: ['./subscription-show.component.scss']
})
export class SubscriptionShow implements OnInit {

  @Input() course:any
  
  constructor() {
  }

  ngOnInit(): void {
    

  }

}
