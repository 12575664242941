<div class="row mx-1 mt-3 justify-content-end">
  <button
    [disabled]="entities.length == 0"
    (click)="changeOrder()"
    class="btn btn-secondary py-1 col-auto"
  >
    {{ hideOnDrag ? "Finish" : ("playlist:change:order" | translate) }}
  </button>
</div>
<div class="row mx-1 mt-3 mb-2">
  <button
    [ngClass]="{ 'hide-element': hideOnDrag }"
    (click)="addVideoModal(-1)"
    class="btn btn-edit-curriculum py-2"
  >
    <fa-icon [icon]="faPlus" class="icon"></fa-icon
    >{{ "organization:video:add:button" | translate }}
  </button>
</div>
<div
  [dndDropzone]="['playlist']"
  (dndDrop)="onDrop($event)"
  dndEffectAllowed="move"
  class="configuration"
>
  <div
    [dndDisableIf]="!hideOnDrag"
    (dndStart)="onDragStart($event, i)"
    (dndEnd)="onDragEnd($event, i)"
    [dndDraggable]="entity"
    dndType="playlist"
    dndEffectAllowed="move"
    *ngFor="let entity of entities; let i = index"
    >
    <!-- [ngStyle]="{ 'opacity': entities[i].hide == true ? '0' : '1' }" -->
    <div
      [ngClass]="{ border: !hideOnDrag }"
      class="mb-1 border-rounded pb-2"
      dndDragImageRef
    >
      <div
        [ngClass]="{ 'hide-element': hideOnDrag }"
        class="row justify-content-between ms-2"
      >
        <div *ngIf="!editing[i]?.edit" class="col-10">
          <h3>{{ entity.name }}</h3>
        </div>
        <input
          *ngIf="editing[i]?.edit"
          class="input-box col-9 ms-4 input-edit mt-2"
          [id]="i"
          maxlength="80"
          name="name"
          [(ngModel)]="editing[i].title"
          [placeholder]="
            entity.name || 'playlist:video:context:title' | translate
          "
          type="text"
        />

        <div
          class="col-2 col-md-auto text-end mr-2 icon-modal me-3 d-flex align-items-center"
        >
          <div ngbDropdown class="d-inline-block">
            <button
              type="button"
              class="btn btn-outline-primary"
              id="dropdownBasic1"
              ngbDropdownToggle
            >
              <i class="fa fa-ellipsis-h"></i>
            </button>
            <div class="mt-1" ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button (click)="editVideo(entity, i)" ngbDropdownItem>
                {{ "playlist:add:text" | translate }}
              </button>
              <button
                (click)="deleteVideo(entity)"
                class="text-danger"
                ngbDropdownItem
              >
                {{ "collection:profile:button:delete" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!editing[i]?.edit" class="row ms-2">
        <div
          [ngClass]="{ 'hide-element': hideOnDrag }"
          [innerHtml]="entity?.description"
        ></div>
      </div>
      <div *ngIf="editing[i]?.edit" class="ms-1 row mt-2">
        <div class="col-11">
          <quill-editor
            id="txtContent"
            name="description"
            [styles]="boxStyle"
            class="input-box w-100 p-0"
            [modules]="modulesQuill"
            [(ngModel)]="editing[i].description"
            [placeholder]="
              entity.description || 'playlist:video:context:intro' | translate
            "
            autofocus
          ></quill-editor>
        </div>
        <div class="row justify-content-start pt-2">
          <div class="col-auto">
            <button (click)="cancel(i)" class="btn btn-outline-primary">
              {{ "Cancel" | translate }}
            </button>
          </div>
          <div class="col-auto">
            <button
              (click)="edit(entity, i)"
              class="btn btn-success text-nowrap"
            >
              {{ "admin:users:modal:edit_courses:btn:save" | translate }}
              <fa-icon
                class="mr-2"
                [icon]="faCircleNorch"
                *ngIf="loadingSpin"
                [spin]="true"
              ></fa-icon>
            </button>
          </div>
        </div>
      </div>
      <div
        (click)="openVideoModal(entity.object.id)"
        [ngClass]="{ small: hideOnDrag }"
        class="thumbnail-item-content clearfix card-video-curriculum"
      >
        <div
          [ngClass]="{ 'small-image': hideOnDrag }"
          class="pull-left the-thumbnail"
          [ngStyle]="{ background: 'url(' + entity?.object?.thumb_url + ')' }"
        >
          <div class="clock-icon">
            <span class="meta--stat">
              <fa-icon
                class="icon"
                [icon]="faClock"
                [styles]="{ color: 'white' }"
              ></fa-icon>
              <i class="fa fa-clock-o"></i>
              {{ entity?.object?.source?.length | timeLength }}
            </span>
          </div>
        </div>
        <div class="thumbnail-item-content-text">
          <div class="the-min-height-trick">
            <div class="row justify-content-between">
              <h2 class="the-item-title col">
                {{getVideoDataInCorrectLang(entity.object)['name'] }}
              </h2>
              <div
                class="col-auto text-right align-items-center d-flex justify-content-center"
              >
                <i *ngIf="hideOnDrag" class="fa fa-bars" aria-hidden="true"></i>
              </div>
            </div>
            <div style="min-height: 17px;">
              <h3
              class="the-item-subtitle"
              *ngIf="entity?.object?.source?.origin?.title"
              >
              {{ "film:from" | translate }}
              <span class="the-source">{{
                entity?.object?.source.origin?.title || ""
              }}</span>
            </h3>
          </div>
            <div class="the-item-text">
              {{
                getVideoDataInCorrectLang(entity.object)['description'] 
                  | htmlToPlainText
                  
              }}
            </div>
            <div class="user-name-with-avatar">
              <app-user-icon class="small-avatar-group" [user]="entity.object?.user"></app-user-icon>
              <div class="the-user-name"><span class="by-whom">{{'author:by' | translate}} </span> <span class="the-author ">{{entity.object?.user?.display_name}}</span></div>
          </div>
          </div>
        </div>
      </div>
      <div *ngIf="!editing[i]?.edit" class="row ms-2">
        <div
          [ngClass]="{ 'hide-element': hideOnDrag }"
          [innerHtml]="entity?.description_alt"
        ></div>
      </div>
      <div class="px-3" *ngIf="editing[i]?.edit">
        <div class="col-11">
          <quill-editor
            id="txtContent"
            name="description_alt"
            [styles]="boxStyle"
            class="input-box w-100 p-0"
            [modules]="modulesQuill"
            [(ngModel)]="editing[i].description_alt"
            [placeholder]="
              entity?.description_alt || 'playlist:video:context:outro'
                | translate
            "
          ></quill-editor>
        </div>
        <div
          [ngClass]="{ 'hide-element': hideOnDrag }"
          class="row justify-content-start pt-2"
        >
          <div class="col-auto">
            <button (click)="cancel(i)" class="btn btn-outline-primary">
              {{ "Cancel" | translate }}
            </button>
          </div>
          <div class="col-auto">
            <button
              (click)="edit(entity, i)"
              class="btn btn-success text-nowrap"
            >
              {{ "admin:users:modal:edit_courses:btn:save" | translate }}
              <fa-icon
                class="mr-2"
                [icon]="faCircleNorch"
                *ngIf="loadingSpin"
                [spin]="true"
              ></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div [ngClass]="{ 'hide-element': hideOnDrag }" class="row my-3 mx-3">
      <button (click)="addVideoModal(i)" class="btn btn-edit-curriculum py-2 mb-3">
        <fa-icon [icon]="faPlus" class="icon"></fa-icon>
        {{ "organization:video:add:button" | translate }}
      </button>
    </div>
  </div>
  <div class="card my-2" dndPlaceholderRef>
    <div class="card-header" style="min-height: 60px"></div>
  </div>
</div>
