import { LeaveVideoComponent } from './../leave-video/leave-video.component';
import { Component,EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxFileDropComponent, NgxFileDropEntry } from 'ngx-file-drop';
import { Video } from 'src/app/interfaces/video/video';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { CollectionService } from 'src/app/services/collection.service';
import { ToastService } from 'src/app/services/toast.service';
import { VideoService } from 'src/app/services/video.service';
import { environment } from 'src/environments/environment';
import { VideoComponent } from '../../video/video.component';
import { AdvancedSettingFormComponent } from '../advanced-setting-form/advanced-setting-form.component';
import { CategoryFormComponent } from '../category-form/category-form.component';
import { DetailsFormComponent } from '../details-form/details-form.component';
import { DhFormComponent } from '../dh-form/dh-form.component';
import { SharedPublicFormComponent } from '../shared-public-form/shared-public-form.component';
import { TagsFormComponent } from '../tags-form/tags-form.component';
import { VideoEditService } from '../video-edit.service';
@Component({
  selector: 'app-video-upload',
  templateUrl: './video-upload.component.html',
  styleUrls: ['./video-upload.component.scss'],
  providers: [VideoEditService]
})
export class VideoUploadComponent implements OnInit {
  @ViewChild('fileDrop') fileDrop: NgxFileDropComponent | undefined;
  @ViewChild('detailsForm') detailsForm: DetailsFormComponent | undefined;
  @ViewChild('DHForm') dhForm: DhFormComponent | undefined;
  @ViewChild('tagsForm') tagsForm: TagsFormComponent | undefined;
  @ViewChild('categoryForm') categoryForm: CategoryFormComponent | undefined;
  @ViewChild('advancedForm') advancedForm: AdvancedSettingFormComponent | undefined;
  @ViewChild('shareForm') shareForm: SharedPublicFormComponent | undefined;


  @Input() selfModal: NgbModalRef | null = null;
  @Input() group: number | null = null;
  @Output() refresh: EventEmitter<any> = new EventEmitter();

  faTimes = faTimes;
  faCircleNorch = faCircleNotch;

  video: Video|undefined;
  public fileName: string = '';
  tabs = [
    {
      id: 'uploader',
      touched: false,
      enabled: true
    },
    {
      id: 'clip-details',
      touched: false,
      enabled: false
    },
    {
      id: 'dh-info',
      touched: false,
      enabled: false
    },
    {
      id: 'tags-info',
      touched: false,
      enabled: false
    },
    {
      id: 'category',
      touched: false,
      enabled: false
    },
    {
      id: 'advanced-setting',
      touched: false,
      enabled: false
    },
    {
      id: 'shared-public',
      touched: false,
      enabled: false
    },
  ]

  currentTab = 0;

  uploading = false;
  uploaded = false;
  uploadingProgress = 0;
  progressInterval: any | null = null;

  saving = false;
  publishing = false;

  acceptFileUpload = "video/mp4v-es,.m4v," +
    "video/mp4,.mp4," +
    "video/x-m4v,.m4v," +
    "video/quicktime,.mov,.qt," +
    "video/x-quicktime,.mov,.qt," +
    "image/mov," +
    "audio/aiff," +
    "audio/x-midi," +
    "audio/x-wav," +
    "video/avi," +
    "video/msvideo,.avi," +
    "video/x-msvideo,.avi," +
    "image/avi," +
    "video/xmpg2," +
    "application/x-troff-msvideo," +
    "audio/avi," +
    "video/x-matroska,.mkv," +
    "video/x-ms-wmv,.wmv";

  constructor(
    private auth: AuthService,
    private toastr: ToastService,
    private translate: TranslateService,
    public editService: VideoEditService,
    private videoService: VideoService,
    private api: ApiService,
    private ngbModal: NgbModal,
    private collectionService: CollectionService
  ) { }



  ngOnInit(): void {
    this.editService.isModal = true;
    this.editService.setGroup(this.group);
  }
 
  testModal(){
    this.toastr.showWarning(this.translate.instant('video:upload:youtube:upload:process:running'));

  }


  openFileSelector($event: MouseEvent){
    this.fileDrop?.openFileSelector($event);
  }
  ////////////////////////
  // For admin permissions
  ////////////////////////
  isAdmin(): boolean {
    return this.auth.hasAnyRole(['moderator', 'super_user']);
  }
  isInvalidFileName(name: string): boolean {
    // Expresión regular para verificar si el nombre de archivo contiene caracteres inválidos
    const regex = /[<>:"\/\\|?*\x00-\x1F%&$#]/;
    return regex.test(name);
  }
   
  fileDropped(files: NgxFileDropEntry[]) {
    files.forEach( dfile => {
      if (dfile.fileEntry.isFile) {
        const fileEntry = dfile.fileEntry as any;

        if (this.isInvalidFileName(dfile.fileEntry.name)){
          this.toastr.showError(this.translate.instant("video:upload:error:file:name"))
          return
        }
        this.fileName = dfile.fileEntry.name;
        console.log('este es el nombre ', this.fileName);
        
        fileEntry.file( (file: File) => {
          // CHECK FILE SIZE
          let unit = 'MB'
          let maxSize = environment.videoMaxSize
          if (this.isAdmin()) {
            unit = 'GB'
            maxSize = environment.videoMaxSizeAdmin;
          }
          if (file.size > maxSize * 1024 * 1024) {
            this.toastr.showError(this.translate.instant("video:upload:info2", {size: maxSize, unity: unit}))
            return
          }

          // CHECK FILE TYPE
          // Use file type to validate
          let fileCheck = null;
          if (file && file.type) {
              fileCheck = file.type;
          }

          // Use extension
          if (file && !fileCheck && file.name) {
              var ext = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2);
              if (ext) {
                  fileCheck = '.' + ext;
              }
          }

          var allowedTypes = this.acceptFileUpload.split(',');

          if (allowedTypes.indexOf(fileCheck!) == -1) {
            this.toastr.showError(this.translate.instant("video:upload:info1"))
            return
          }

          this.startUploadingProgress()
          this.videoService.post().then( videoPost => {
            this.videoService.upload(file, videoPost.id).then( videoData => {
              setTimeout(() => {
                this.stopUploadingProgress()
                this.toastr.showSuccess('Uploading "' + file.name + '" completed')
                this.uploaded = true;
                this.uploading = false;
              }, 5000);
            }).catch( (reason: any) => {
              this.toastr.showError(reason);
            });

            this.editService.setVideoId(videoPost.id);

            this.tabs.forEach((tab, i) => {
              if (i == 0) {
                tab.enabled = false;
              } else {
                tab.enabled = true
              }
            });
            setTimeout(() => {
              this.nextTab();
            }, 400);
          })
        })
      }
      return; //si subis muchos, solo carga el primero
    });
   
  }

  closeModal(){
    if (!this.editService?.video?.published && this.currentTab >= 1) {
      this.notificationModal()
      this.toastr.showInfo(this.translate.instant('You can find the video you was uploading in My Profile / Videos / Unpublished videos'));
    }else {
      if(this.selfModal) this.selfModal?.close();
      else $("ngb-modal-window").trigger('click');
    }
  }
  notificationModal() {
    let modalRef = this.ngbModal.open( LeaveVideoComponent, {
      windowClass: 'default-modal',
      size: 'md',
      centered: false,
      backdrop : 'static',
      keyboard : false
    });
    modalRef.componentInstance.selfModalNotification = modalRef;
    modalRef.componentInstance.closeUpublished.subscribe((resp: any) => {
    
    if(this.selfModal) this.selfModal?.close();
    else $("ngb-modal-window").trigger('click');
    });
  }

  startUploadingProgress() {
    this.uploading = true
    this.uploadingProgress = 1;
    this.progressInterval = setInterval( () => {
      let prog = Math.round(1 * (100 - this.uploadingProgress) ) / 100
      this.uploadingProgress = Math.round(( this.uploadingProgress + prog ) * 100) / 100
    }, 200)
  }

  stopUploadingProgress() {
    this.uploading = false;
    clearInterval(this.progressInterval);
  }

  saveAndNextTab() {
    let all: Array<Promise<any>> = [];
    this.tabs.forEach( (tab, i) => {
      if (i > 0 && tab.touched) {
        switch (tab.id) {
          case 'clip-details':
            if (this.detailsForm) {
              all.push(this.detailsForm.save());
            }
            break;
          case 'dh-info':
            if (this.dhForm) {
              all.push(this.dhForm.save())
            }
            break;
          case 'tags-info':
            if (this.tagsForm) {
              all.push(this.tagsForm.save())
            }
            break;
          case 'category':
            if (this.categoryForm) {
              all.push(this.categoryForm.save())
            }
            break;
          case 'advanced-setting':
            if (this.advancedForm) {
              all.push(this.advancedForm.save())
            }
            break;
          case 'shared-public':
            if (this.shareForm) {
              all.push(this.shareForm.save())
            }
            break;
          default:
            break;
        }
      }
    });

    if (all.length) {
      this.saving = true;
      Promise.all(all).then( res => {
        this.nextTab();
        this.saving = false;
      }).catch( err => {
        if (err.error.message.friendly) {
          this.toastr.showError(err.error.message.friendly)
        } else {
          this.toastr.showError(err.message)
        }
        this.saving = false;
      })
    } else {
      this.nextTab();
    }
  }

  nextTab() {
    let next = this.currentTab + 1;
    if (next == 5 && !this.auth.hasAccess('edit_all_videos')) {
      next = 6
    }
    let nextTab = this.tabs[next];
    if (nextTab) {
      let node = document.querySelector('button[data-bs-target="#' + nextTab.id + '"]');
      (node as HTMLElement).click();
      this.currentTab = next;
    }
  }

  prevTab() {
    let prev = this.currentTab - 1;
    if (prev == 5 && !this.auth.hasAccess('edit_all_videos')) {
      prev = 4
    }
    let prevTab = this.tabs[prev];
    if (prevTab) {
      let node = document.querySelector('button[data-bs-target="#' + prevTab.id + '"]');
      (node as HTMLElement).click();
      this.currentTab = prev
    }
  }

  changeTab(num: number): void {
    if (this.tabs[num]?.enabled) {
      this.currentTab = num
    }
  }

  touched(num: number, val: boolean): void {
    this.tabs[num].touched = val;
  }

  areTouched(): boolean {
    let t = false
    for (let i = 0; i < this.tabs.length; i++) {
      const tab = this.tabs[i];
      if (tab.touched) {
        t = true
      }
    }
    return t;
  }

  publish() {    
    this.publishing = true;
    this.api.patch("/video/" + this.editService.video?.id + "/publish", {
      published: true
    }).then( (response: any) => {
      if (response && response.result == 'success') {
        this.editService.video!.published = !this.editService.video?.published;

        if (response.data.published) {
          if (this.group) {
            this.collectionService
              .setCollection('video', this.editService.video!.id + '', this.group + '')
              .then((res) => {
                this.toastr.showSuccess(
                  this.translate.instant('group:add:video:success')
                );
                this.refresh.emit();

                this.selfModal?.close();
              })
              .catch((err: any) => {
                this.toastr.showError('Error adding video');
              });
          } else {

            this.toastr.showWarning(this.translate.instant('video:upload:youtube:upload:process:running'));


           // this.toastr.showSuccess(this.translate.instant('video:upload:youtube:upload:process:running'));

//            this.toastr.showSuccess("Successfully published this video.");
            this.refresh.emit();
            this.selfModal?.close();

            // open video modal
            let modalRef = this.ngbModal.open(VideoComponent, {
              windowClass: 'default-modal video-modal',
              size: 'lg'
            });
            modalRef.componentInstance.videoId = this.editService.video?.id;
            modalRef.componentInstance.selfModal = modalRef;
          }
        }
      } else {
        let errorMsg = response.message?.friendly! || this.translate.instant('video:publish:error');
        this.toastr.showError(errorMsg);
      }
      this.publishing = false
    })
    .catch( (response: any) => {
      let errorMsg = response.error?.message?.friendly! || this.translate.instant('video:publish:error');
      this.toastr.showError(errorMsg);
      this.publishing = false
    });
  }
}
