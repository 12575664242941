import { Component, Input, OnInit } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from 'src/app/services/admin.service';
import { CoursesService } from 'src/app/services/courses.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-view-members',
  templateUrl: './view-members.component.html',
  styleUrls: ['./view-members.component.scss'],
})
export class ViewMembersComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() course: any | null = null;
  @Input() hideButtons: boolean = true;
  @Input()  language: string = 'en';
  public faCircleNorch: any = faCircleNotch;
  public faTimes = faTimes;
  public loading: boolean = false;
  public loadSpin: boolean = false;

  public actualTab = 'enrolled';
  public usersInvited: any = [];
  public usersAccepted: any = [];
  public selectUsers: any = [];
  public textName: any = '';
  public textMessage: string = '';
  public days: number = 30;
  public custom_value: number = 1;
  public enrolledUser: any = [];
  constructor(
    private adminService: AdminService,
    private toastService: ToastService,
    private translate: TranslateService,
    private courseService: CoursesService
  ) {}

  ngOnInit(): void {
    this.getUsersInvited();
    this.getUsersAccepted();
  }

  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
  getTab(tab: string) {
    this.actualTab = tab;
    if (this.actualTab == 'enrolled') {
      // this.getUsersInvited();
    }
  }
  public getUsersInvited() {
    this.loading = true;
    this.adminService.getUsersInvited(this.course.id).then((resp: any) => {
      this.usersInvited = resp.data.enrolled_users;
      this.loading = false;
    });
  }

  public getUsersAccepted() {
    this.adminService.getUsersAcepted(this.course.id).then((resp: any) => {
      this.usersAccepted = resp.data.enrolled_users;
    });
  }

  usersSelected(data: any) {
    this.selectUsers = data;
    // this.textName =  this.selectUsers[this.selectUsers.length-1].display_name;
  }

  sendInvitation() {
    let days = this.days;
    if (this.days == -1) {
      if (
        this.custom_value === null ||
        this.custom_value === undefined ||
        this.custom_value === 0 ||
        this.custom_value < 1
      ) {
        this.toastService.showError(
          this.translate.instant('Custom value must be a positive integer')
        );
      }
      days = this.custom_value;
    }

    let allUserInvited: any;
    this.loadSpin = true;
    allUserInvited = this.selectUsers.map((user: any) => {
      return {
        display_name: user.display_name,
        email: user.email,
        message: this.textMessage,
      };
    });

    this.courseService
      .sendInvitations(this.course.id, allUserInvited, days, this.language)
      .then((data: any) => {
        this.selectUsers = [];
        this.toastService.showSuccess(
          this.translate.instant('collection:members:add:members:success')
        );
        this.loadSpin = false;
        this.closeModal();
      })
      .catch((err) => {
        console.log(err);
        this.toastService.showError(
          this.translate.instant('organization:members:send_invitation:error')
        );
      });
  }
}
