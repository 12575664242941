import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-accept-invitation',
  templateUrl: './accept-invitation.component.html',
  styleUrls: ['./accept-invitation.component.scss']
})
export class AcceptInvitationComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private os: OrganizationsService,
    private translate: TranslateService,
    private toastr: ToastService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe( (params) => {
      const member = params['member'];
      this.os.addMember(member).then( member => {
        this.toastr.showSuccess(this.translate.instant('organization:members:accept:invitation:success'));

        setTimeout( () => {
          if (member.collection && member.collection.id && member.collection.type) {
            switch (member.collection.type) {
              case 'playlist':
                this.router.navigate(['/playlists', member.collection.id]);
                break;
              case 'group':
                this.router.navigate(['/groups', member.collection.id]);
                break;
              case 'course':
                this.router.navigate(['/courses', member.collection.id]);
                break;
              case 'dh_team':
                this.router.navigate(['dh/profile']);
                break;
              case 'dh_profile':
                this.router.navigate(['dh/profile']);
                break;
            }
          } else {
            this.router.navigate(['/']);
          }
        }, 2000);
      }).catch( err => {
        let message = this.translate.instant(err.error?.message?.friendly) || this.translate.instant('organization:members:accept:invitation:error');
        this.toastr.showError(message);
        this.router.navigate(['/']);
      });
    })
  }
}
