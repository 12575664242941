<div class="modal-close" (click)="closeModal()">
  <i class="fas fa-times me-1"></i><span>{{ "btn:close" | translate }}</span>
</div>

<div class="modal-header">
  <h4 class="modal-title">{{ "course:buy:title" | translate }}</h4>
</div>

<div class="modal-body">
  <div class="text-center">
    <app-movie-tabs-wrapper [main]="true">
      <li
        class="nav-item"
        role="presentation"
        *ngFor="let tab of tabs; let i = index"
      >
        <button
          [id]="i"
          class="nav-link py-3"
          data-bs-toggle="tab"
          [attr.data-bs-target]="'#' + tab.id"
          type="button"
          role="tab"
          (click)="changeTab(i)"
          [ngClass]="{ disabled: !tabs[i].enabled, active: i == 0 }"
        >
          <span *ngIf="tab.id != 'product'">{{
            "checkout:modal:tab:" + tab.id | translate
          }}</span>
          <span *ngIf="tab.id == 'product'">{{
            "teamroleprofile:checkout:product:tab" | translate
          }}</span>
        </button>
      </li>
    </app-movie-tabs-wrapper>
  </div>

  <div class="tab-content mt-2">
    <div
      class="tab-pane fade show p-5"
      id="login"
      role="tabpanel"
      [ngClass]="{ active: tabs[0].id == 'login' }"
    >
      <app-login
        (success)="logedIn()"
        [state]="'register'"
        [messageRegister]="true"
      ></app-login>
    </div>

    <div
      class="tab-pane fade show pt-2"
      *ngIf="auth.isAuthenticated()"
      id="product"
      role="tabpanel"
      [ngClass]="{ active: tabs[0].id == 'product' }"
    >
      <!-- <app-plan-tab [collections]="collections" [initial]="initialDays" (changed)="onSubscriptionChange($event)"></app-plan-tab> -->
      
      <ng-container *ngIf="competencies?.length > 0; else collect">
        <p>{{ "teamroleprofile:checkout:product:tab:text1" | translate }}</p>
        <div>
          <label class="fancy-radio-checkbox d-flex p-3 ps-5 mb-0">
            <input
              class="fancy-input"
              name="subscription_price"
              [value]="totalPrice"
              type="radio"
              [(ngModel)]="totalPrice"
            />
            <div class="fancy-graphic ms-3"></div>

            <div>
              <h6 class="mb-1">
                {{ "focus:competencies:text" | translate }}
              </h6>
              <p class="fw-bold">
                {{ competencies.length }}
                {{ competencies.length==1?("focus:competencies:sub:text" | translate):("focus:competencies:sub:text:s" | translate) }} x
                {{
                  compentencyPrice
                    | currency
                      : "EUR"
                      : "symbol"
                      : (compentencyPrice % 1 === 0 ? "1.0-2" : "1.2-2")
                }}
                =
                {{
                  competencies.length * compentencyPrice
                    | currency
                      : "EUR"
                      : "symbol"
                      : ((competencies.length * compentencyPrice) % 1 === 0
                          ? "1.0-2"
                          : "1.2-2")
                }}
              </p>

              <p class="mb-0 fw-normal">
                <ng-container
                  *ngFor="let competence of competencies; let last = last"
                >
                  {{
                    "dh_profile:competences:level:" + 3 + ":" + competence
                      | translate
                  }}<span *ngIf="!last">, </span>
                </ng-container>
              </p>
            </div>
            <h5 class="text-primary text-end flex-fill align-self-center mb-0">
              {{ totalPrice  | currency
                : "EUR"
                : "symbol"
                : (totalPrice % 1 === 0 ? "1.0-2" : "1.2-2") }}
            </h5>
          </label>
        </div>
      </ng-container>
      <ng-template #collect>
        <p>{{ "teamroleprofile:checkout:product:tab:text" | translate }}</p>
        <div *ngFor="let collection of collections; let i = index">
          <label class="fancy-radio-checkbox d-flex p-3 ps-5 mb-0">
            <input
              class="fancy-input"
              name="subscription_price"
              [value]="collection"
              type="radio"
              [(ngModel)]="product"
              (change)="changeProduct(collection)"
            />
            <div class="fancy-graphic ms-3"></div>
            <div>
              <h5 class="mb-0">{{ collection.name | translate }}</h5>
            </div>
            <h5 class="text-primary text-end flex-fill align-self-center mb-0">
              {{
                collection.striked_price | currency : "EUR" : "symbol" : "1.0-2"
              }}
            </h5>
          </label>
        </div>
      </ng-template>

      <div class="border p-3 mt-3">
        <div class="row">
          <div class="col">
            <h4 class="h5 mb-0">
              {{
                "teamroleprofile:checkout:for:complete:team:header" | translate
              }}
            </h4>
            <p class="ff-karla lh-2 mb-0">
              {{
                "teamroleprofile:checkout:for:complete:team:text" | translate
              }}
            </p>
          </div>
          <div class="col-auto align-self-center">
            <a
              href="mailto:info@doublehealix.com"
              target="_blank"
              class="btn btn-success text-light"
            >
              {{ "course:buy:multiple:btn:contact" | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div
      class="tab-pane fade pt-2"
      *ngIf="auth.isAuthenticated()"
      id="method"
      role="tabpanel"
    >
      <app-method-tab *ngIf="this.checkout.order"></app-method-tab>
    </div>

    <div
      class="tab-pane fade pt-2"
      *ngIf="checkout.course"
      id="confirm"
      role="tabpanel"
    >
      <app-order-preview-tab
        [competencies]="competencies"
      ></app-order-preview-tab>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="container">
    <div class="row justify-content-end">
      <div class="col-6 px-0" *ngIf="currentTab > 0">
        <button class="btn btn-link" (click)="prevTab()">
          <i class="icon fa fa-angle-left"></i>
          {{ "video:edit:modal:prev_step" | translate }}
        </button>
      </div>
      <div *ngIf="auth.isAuthenticated()" class="col-6 text-end px-0">
        <button
          class="btn btn-primary"
          (click)="nextTab()"
          *ngIf="currentTab < tabs.length - 1"
          [disabled]="!canGoNext()"
        >
          {{ "video:edit:modal:next_step" | translate }}
          <i class="icon fa fa-angle-right"></i>
        </button>
        <button
          class="btn btn-primary"
          *ngIf="currentTab == tabs.length - 1"
          [disabled]="!this.checkout.termsAccepted"
          (click)="pay()"
        >
          {{ "course:buy:payment:btn:pay" | translate }}
          {{
            getTotalValue()
              | currency
                : "EUR"
                : "symbol"
                : (getTotalValue() % 1 === 0 ? "1.0-2" : "1.2-2")
          }}
          <!-- {{ "course:buy:payment:btn:pay" | translate }} {{ this.checkout.subscription!.subscription_days == this.checkout.course!.summaryOrders.subscription_day_selected ? (this.checkout.subscription!.price > 0 ? (this.checkout.subscription!.price | currency:'EUR':'symbol':'1.0-2') : (this.checkout.subscription!.price | currency:'EUR':'symbol':'1.0-2')) : this.checkout.subscription!.originalPrice ? (this.checkout.subscription!.originalPrice | currency:'EUR':'symbol':'1.0-2') : (this.checkout.subscription!.price > 0 ? (this.checkout.subscription!.price | currency:'EUR':'symbol':'1.0-2') : (this.checkout.subscription!.price | currency:'EUR':'symbol':'1.0-2')) }} -->
          <span *ngIf="this.checkout.course!.striked_price! > 0"
            >{{ "course:buy:payment:btn:via" | translate }}
            {{
              "course:buy:payment:method:" + this.checkout.order!.method
                | translate
            }}</span
          >
          <i *ngIf="paying" class="fa fa-circle-notch fa-spin ms-2"></i>
        </button>
      </div>
    </div>
  </div>
</div>
