import { Injectable } from '@angular/core';
import { User } from '../interfaces/user';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class CollectionService {
  user: User | null = null

  constructor(
    private api: ApiService,
    private auth: AuthService
  ) {
    this.auth.user.subscribe( user => {
      this.user = user
    })
  }
  

  async getCollectionId(
    id: number,
   
  ) {
    try {
      
      const colletion = await this.api.get('/collection/' + id);
      return colletion;
    } catch (error) {
      return error;
    }
  }
  async getCollection(
    limit: number | null = 50,
    page: number | null = 1,
    type: string | null,
    organization_id: number | null,
    search: string | null,
    open: string ='true',
    excluded_from_group: string | null,
    owner:string | null,
    isPublic:string | null,
   
  ) {
    try {
      let query = `?type=${type}&owner=${owner}&limit=${limit}&page=${page}&search=${search||''}&organization_id=${organization_id}&open=${open}&excluded_from_group=${excluded_from_group || ''}&public=${isPublic || ''}`;
      const colletion = await this.api.get('/collection' + query);
      return colletion;
    } catch (error) {
      return error;
    }
  }

  async getCollectionPlayList(
    limit: number | null = 50,
    page: number | null = 1,
    type: string | null,
    organization_id: number | null,
    search: string | null,
    open: string ='true',
    excluded_from_group: string | null,
    owner:string | null,
    publicD:string | null
  ) {
   // http://localhost:3000/collection?type=playlist&owner=&limit=6&page=1&search=&order=RELEVANCE&open=both&section=all&public=true

    try {
      let query = `?type=${type}&owner=${owner}&limit=${limit}&page=${page}&search=${search||''}&organization_id=${organization_id}&open=${open}&public=${publicD || ''}`;
      const colletion = await this.api.get('/collection' + query);
      return colletion;
    } catch (error) {
      return error;
    }
  }
  async setCollection(
    type: string | null,
    entity_id: string,
    colletion_id: string
  ) {
    let videos=[];
    videos.push({
      entity_id:entity_id,
      type:type
    });

    let entities={entity_id:entity_id,type:type}
    let data={entities:entities}
    try {
      const colletion = await this.api.postJson('/collection/'+colletion_id+'/entity',{ entities: videos });
      return colletion;
    } catch (error) {
      return error;
    }
  }

  async acceptInvitation(collectionId: string) {
    return this.api.post('/collection/' + collectionId + '/invite/accept', null).then( d => {
      return d.data;
    })
  }

  removeUser(collectionId: string|number, collectionUserId: string|number) {
    return this.api.delete('/collection/' + collectionId + '/enrolled/' + collectionUserId).then( (res: any) => {
      //debugger;
      return res.data;
    });
  }

  async changeOwner(userId:string,collectionId: string) {
    return this.api.patch('/collection/' + collectionId + '/users/owner', {'user_id':userId}).then( d => {
      return d.data;
    })
  }

}
