import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faClock } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-continue-course-home',
  templateUrl: './continue-course-home.component.html',
  styleUrls: ['./continue-course-home.component.scss'],
})
export class ContinueCourseHomeComponent implements OnInit {
  @Input() courses: any;
  faClock = faClock;
  constructor(private router: Router) {}

  ngOnInit(): void {
    // this.course.type =
    //   this.course.type == 'course' ? 'Course' : this.course.type;
  }

  courseProgress(course_progress: any = 0, chapters: any): number {
    if (course_progress) {
      return Math.floor(parseFloat(course_progress?.toFixed(3)) * 100);
    } else if (chapters) {
      return 0;
    }
    return 0;
  }

  goCourse(course: any) {
    console.log(course);

    this.router.navigate([
      '/courses',
      course.collection_id,
      'cursus',
      course.collection.name.split(' ').join('-'),
    ]);
  }
}
