


<div *ngFor="let sub of subscriptions_original; let i = index">
  <label class="fancy-radio-checkbox d-flex p-3 ps-5 mb-0">
    <input class="fancy-input" name="subscription_price"
      [value]="sub.subscription_days" type="radio" [(ngModel)]="initial" (ngModelChange)="onSubscriptionChange()">
    <div class="fancy-graphic ms-3"></div>

    <div>
      <h5 class="mb-1">{{ 'course:buy:period:title' | translate:{days: sub.subscription_days} }}</h5>
      <p *ngIf="!isGift" class="mb-0 fw-normal">{{ "course:buy:period:description" | translate:{days: sub.subscription_days} }}</p>
      <p *ngIf="isGift" class="mb-0 fw-normal">{{ "course:gift:period:description" | translate:{days: sub.subscription_days} }}</p>
    </div>


    
    <h5 class="text-primary text-end flex-fill align-self-center mb-0">{{ getPriceWithDiscount(sub.subscription_days == this.checkout.course?.summaryOrders?.subscription_day_selected 
      ? sub.price : (sub.originalPrice?sub.originalPrice:sub.price)) | currency:'EUR':'symbol':'1.0-2'}}</h5>

  </label>
</div>
<p>{{'checkout:prolong:disclaimer' | translate}}</p>
<div class="border p-3 mt-3">
  <div class="row">
    <div class="col">
      <h4 class="h5 mb-0">{{ "course:buy:multiple:title" | translate }}</h4>
      <p class="ff-karla lh-2 mb-0">{{ "course:buy:multiple:description" | translate }}</p>
    </div>
    <div class="col-auto align-self-center">
      <a href="mailto:contact@movielearning.com" target="_blank" class="btn btn-success text-light">
        {{ "course:buy:multiple:btn:contact" | translate }}
      </a>
    </div>
  </div>
</div>
