import { Injectable } from '@angular/core';
import { Video } from '../interfaces/video/video';
import { LangService } from '../services/lang.service';

@Injectable({
  providedIn: 'root'
})
export class VideoUtilitiesService {
  currentLang = 'en';

  constructor(
    private lang: LangService
  ) {
    this.lang.language.subscribe( lang => {
      this.currentLang = lang || 'en';
    });
  }

  getInfoByLang(video: Video) {
    for (let i of video.info) {
      if (i.language == this.currentLang) {
        return i;
      }
    }
    return video.info[0]
  }

  getDhTags(video: Video): Array<any> {
    let dh_tags: Array<any> = [];
    let index_tag = 0;
    
    video.contexts?.forEach(dh_tag => {
      let phases: Array<number> = [];
      if (dh_tag.special && dh_tag.phase) {
        switch (dh_tag.special.special) {
          case 'power_cross':
            phases = [2, 5, 8, 11];
            break;
          case 'mentor_cross':
            phases = [1, 4, 7, 10];
            break;
          case 'substance_cross':
            phases = [3, 6, 9, 12];
            break;
          case 'water_triangle':
            phases = [1, 5, 9];
            break;
          case 'fire_triangle':
            phases = [2, 6, 10];
            break;
          case 'earth_triangle':
            phases = [3, 7, 11];
            break;
          case 'air_triangle':
            phases = [4, 8, 12];
            break;
          case 'opossition':
          case 'polarity':
          case 'paradox':
            let phase = parseInt(dh_tag.phase.phase);
            if (!phase) {
                break;
            }

            if (phase <= 6) {
                phases = [phase, (phase + 6)];
            } else {
                phases = [(phase - 6), phase];
            }
            break;
          case 'sequence':
            //Get seq numb
            if (dh_tag.special_extra && dh_tag.special_extra.startPhase && dh_tag.special_extra.endPhase) {
              for (let index = parseInt(dh_tag.special_extra.startPhase); index <= parseInt(dh_tag.special_extra.endPhase); index++) {
                phases[phases.length] = index;
              }
            }
            break;
          /**
           * Do nothing
           */
          case 'phase':
          case 'state':
          case 'trait':
          /* falls through */
          default:
            phases = [dh_tag.phase.phase];
            break;
        }
      }

      // TODO: Check why we need a special phase selected to show the circles. If we don't select a special or
      // we select a special 'phase', the circle won't be shown.
      if (phases.length > 0) {
        dh_tags[index_tag] = {
          level: dh_tag.level,
          phase: dh_tag.phase,
          special: dh_tag.special,
          phases_selected: phases
        };
        index_tag++;
      }
    });

    return dh_tags;
  }
}
