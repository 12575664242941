import { Component, Input, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { faClock  } from '@fortawesome/free-regular-svg-icons';
import { Lesson } from 'src/app/interfaces/lesson';
import { CourseUtilitiesService } from 'src/app/utilities/course-utilities.service';

@Component({
  selector: 'app-lesson-list-card',
  templateUrl: './lesson-list.component.html',
  styleUrls: ['./lesson-list.component.scss']
})
export class LessonListCardComponent implements OnInit {
	@Input() lesson: Lesson | undefined;
	@Input() itemSelect: any;

  @Output() selectItem = new EventEmitter<number>();

  loading = false;
  title = '';
  description = '';
  language: String = 'en';
  faClock = faClock;

  constructor(
    private cu: CourseUtilitiesService,
  ) {  }

  ngOnInit(): void {}

  select() {
    this.selectItem.emit(this.lesson?.id);
  }

  getImage() {
    return this.cu.getLessonThumbnail(this.lesson!);
  }
}
