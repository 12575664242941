<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-center">
  <h4 class="modal-title">
    {{ "onborading:title" | translate }}
  </h4>
</div>
<div class="modal-body flex-column">
  <div class="row justify-content-center text-start my-3">
    <p>{{ "onborading:desc:1" | translate }}</p>
    <p>{{ "onborading:desc:2" | translate }}</p>
  </div>
  <div class="row my-4">
    <div class="col-6 d-flex align-items-center flex-column">
      <button
        (click)="startOnboarding()"
        type="button"
        class="btn btn-primary mb-2 fs-15 text-nowrap col-auto"
      >
        {{ "onborading:button:1" | translate }}
      </button>
    </div>
    <div class="col-6 d-flex align-items-center text-center flex-column">
      <button
        type="button"
        class="btn btn-primary mb-2 fs-15 col-auto"
        (click)="closeModal()"
      >
        {{ "onborading:button:2" | translate }}
      </button>
    </div>
  </div>
</div>
