<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title">
    {{ "course:progress:reset" | translate }}
  </h4>
</div>
<div class="modal-body">
  <p>{{ "course:progress:reset:message" | translate }}</p>
</div>
<div class="modal-footer">
  <button class="btn btn-link" [disabled]="loading" (click)="closeModal()">{{ 'Cancel' | translate }}</button>
  <button class="btn btn-primary" (click)="resetProgress()" [disabled]="loading">{{ 'course:progress:reset:short' | translate }}<fa-icon class="icon ms-2" *ngIf="loading" [spin]="true" [icon]="faCircleNorch"></fa-icon></button>
</div>
