import { Component, Input, OnInit } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-circle-modal',
  templateUrl: './circle-modal.component.html',
  styleUrls: ['./circle-modal.component.scss'],
})
export class CircleModalComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  public faCircleNorch: any = faCircleNotch;
  @Input() title: String = '';
  @Input() number: String = '';
  @Input() description: String = '';

  public faTimes = faTimes;
  public loadSpin: boolean = false;
  constructor() {}

  ngOnInit(): void {}
  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
}
