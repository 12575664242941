import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { ToastService } from 'src/app/services/toast.service';
import { NgxFileDropComponent, NgxFileDropEntry } from 'ngx-file-drop';
@Component({
  selector: 'app-invite-with-csv',
  templateUrl: './invite-with-csv.component.html',
  styleUrls: ['./invite-with-csv.component.scss'],
})
export class InviteWithCsvComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() organizationId: any;
  @Output() refresh = new EventEmitter<any>();
  public files: NgxFileDropEntry[] = [];
  public faCircleNorch: any = faCircleNotch;
  public loading: boolean = false;
  public selectUsers: any = [];
  public textName: any = '';
  public language: any = 'en';
  public loadSpin: boolean = false;
  public includePending: boolean = false;
  public textMessage: string = '';
  public faTimes = faTimes;
  fileName: string = '';

  public infoUser: any = {
    file: '',
    include_pending: true,
    language: 'en',
    message: '',
  };

  constructor(
    private organizationService: OrganizationsService,
    private toastService: ToastService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.changeOrder();
  }
  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  changeOrder() {
    if (this.language == 'en') {
      this.infoUser.message = `Welcome to MovieLearning!
      MovieLearning is the online video platform to learn about leadership.
      MovieLearning supports all recent versions of Chrome, Safari, Firefox and Edge.
      You can also access MovieLearning on your smartphone.
      If you've got a question feel free to contact us at support@movielearning.com`;
    }
    if (this.language == 'nl') {
      this.infoUser.message = `Welkom op MovieLearning!
      MovieLearning is het video leerplatform om te leren over leiderschap.
      MovieLearning werkt in alle actuele internetbrowsers van Chrome, Safari, Firefox en Edge.
      MovieLearning werkt ook op smartphones.
      Mocht je vragen hebben dan staan we voor je klaar via support@movielearning.com`;
    }
  }

  sendInvitation() {
    this.loadSpin = true;

    this.organizationService
      .inviteCsv(this.organizationId, this.infoUser)
      .then((data) => {
        if (data.result == 'success') {
          this.toastService.showSuccess(
            this.translate.instant('collection:members:add:members:success')
          );
          this.loadSpin = false;
          this.closeModal();
        } else {
          this.closeModal();

          this.toastService.showError(
            this.translate.instant('organization:members:send_invitation:error')
          );
          this.refresh.emit();
        }
      })
      .catch((err) => {
        console.log(err);
        this.toastService.showError(
          this.translate.instant('organization:members:send_invitation:error')
        );
      });
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.infoUser.file = `user;email
    User Name1;user1@email.com
    User Name2;user2@email.com`;

    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          file.text().then( file => {
            this.infoUser.file = file;
          })
          // Here you can access the real file          console.log(droppedFile, file);
          this.fileName = droppedFile.relativePath;
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }
}
