import { Injectable } from '@angular/core';
import { User } from '../interfaces/user';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserUtilitiesService {
  user: User | null = null;

  constructor(private authService: AuthService) {
    this.authService.user.subscribe((userData) => {
      this.user = userData;
    });
  }

  amISuperUser() {
    return (
      this.user?.type == 'User' &&
      this.user?.roles &&
      this.user?.roles?.primary &&
      this.user.roles.primary == 'super_user'
    );
  }

  amIOwner(element: Object, property = 'user' as keyof typeof element) {
    let amIOwner = false;

    if (this.user?.type != 'User' || !element || !property) {
      return amIOwner;
    }

    if (element.hasOwnProperty(property)) {
      let user: User = element[property] as unknown as User;
      amIOwner = user.username == this.user.username;
    }

    return amIOwner;
  }
}
