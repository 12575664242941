<a
  class="d-block d-md-none btn btn-outline-primary btn-viewer-header btn-viewer-header-fixed"
  [ngClass]="{ active: sidebarState != 'collapsed' }"
  (click)="toggleSidebarState()"
>
  <div
    class="hamburger hamburger--slider js-hamburger is-active"
    [ngClass]="{ 'is-active': sidebarState != 'collapsed' }"
  >
    <div class="hamburger-box">
      <div class="hamburger-inner"></div>
    </div>
  </div>
</a>

<div class="d-flex">
  <div class="sidebar-wrapper" [@slideInOut]="sidebarState">
    <div class="sidebar flex-shrink-0">
      <header class="py-1">
        <div class="container-fluid py-2">
          <div class="row justify-content-center">
            <div class="col-2 d-none d-md-flex">
              <button
                (click)="toggleSidebarState()"
                class="btn btn-outline-primary btn-viewer-header"
                aria-label="Toggle navigation"
              >
                <fa-icon [icon]="faTimes" size="lg"></fa-icon>
              </button>
            </div>
            <div class="col-8 col-sm-6 col-md-10 text-center">
              <h3 class="h5 mt-1 mb-0 text-light">{{ course?.name }}</h3>
              <app-progress-bar
                *ngIf="userIsEnrolled()"
                [progress]="course?.course_progress!"
                [showLabelText]="false"
                [labelPosition]="'right'"
              ></app-progress-bar>
            </div>
          </div>
        </div>
      </header>
      <div class="content p-2 px-4 pt-3 mb-0">
        <app-curriculum-main
          *ngIf="course"
          type="course_menu"
          [course]="course"
        ></app-curriculum-main>
      </div>
    </div>
  </div>

  <app-toasts-container></app-toasts-container>

  <div class="page-content d-flex flex-column">
    <div class="header-container">
      <header class="py-1">
        <div class="container-fluid py-2">
          <div class="row">
            <div class="col-3">
              <button
                *ngIf="sidebarState == 'collapsed'"
                (click)="toggleSidebarState()"
                class="btn btn-outline-primary btn-viewer-header d-none d-md-flex"
                aria-label="Toggle navigation"
              >
                MENU
              </button>
            </div>
            <div class="col-6 text-center">
              <h3 class="h5 mt-1 mb-0">{{ course?.name }}</h3>
              <div class="row justify-content-center">
                <div *ngIf="userIsEnrolled()" class="col-10 col-md-6 col-lg-4">
                  <app-progress-bar
                    [progress]="course?.course_progress!"
                    [showLabelText]="false"
                    [labelPosition]="'right'"
                  ></app-progress-bar>
                </div>
              </div>
            </div>
            <div class="col-3 justify-content-end d-flex">
              <ng-container *ngIf="!loading">
                <button
                  *ngIf="!userIsEnrolled()"
                  class="btn btn-primary mx-1"
                  (click)="goToProfile()"
                >
                  {{ "Go to course" | translate }}
                </button>
                <button
                  *ngIf="!userIsEnrolled()"
                  class="btn btn-secondary"
                  (click)="goToHome()"
                >
                  {{ "Back to home" | translate }}
                </button>
                <button
                  *ngIf="userIsEnrolled()"
                  class="btn btn-outline-primary btn-viewer-header"
                  (click)="goToProfile()"
                >
                  <fa-icon [icon]="faTimes" size="lg"></fa-icon>
                </button>
              </ng-container>
            </div>
          </div>
        </div>
      </header>
    </div>

    <div #lessonContent class="content">
      <router-outlet></router-outlet>
    </div>

    <div class="footer-container" *ngIf="userIsEnrolled()">
      <footer class="py-1">
        <div class="container py-2">
          <div class="row justify-content-center">
            <div class="col-md-10">
              <div class="row">
                <div class="col-4 col-md-6">
                  <button
                    class="btn btn-link pr-1 text-nowrap"
                    (click)="goPrev()"
                  >
                    <fa-icon
                      [icon]="faAngleLeft"
                      size="2x"
                      [styles]="{ 'vertical-align': 'middle' }"
                    ></fa-icon>
                    <span class="align-middle ms-2">{{
                      "courseviewer:navigation:previous:lesson" | translate
                    }}</span>
                  </button>
                </div>
                <div class="col-8 col-md-6 text-end">
                  <div
                    *ngIf="
                      completed || (atBottomScroll && isTextLesson);
                      else notgreen
                    "
                    class="btn-group"
                    role="group"
                  >
                    <button
                      class="btn btn-success py-0 button-complete1"
                      (click)="completed ? uncompleteLesson() : goNext()"
                      [ngClass]="{ 'not-completed': !completed }"
                    >
                      <span
                        *ngIf="updating; else check"
                        class="fa fa-spinner fa-lg fa-spin px-1"
                      ></span>
                      <ng-template #check>
                        <span
                          class="circle-empty"
                          *ngIf="!completed"
                          ng-click="goNext()"
                        ></span>

                        <span
                          class="circle-check position-relative"
                          ng-click="goNext()"
                        >
                          <span class="fa fa-check"></span>
                        </span>
                      </ng-template>
                    </button>
                    <button class="btn btn-success pr-1" (click)="goNext()">
                      <span class="align-middle me-2">
                        <span *ngIf="!completed"
                          >{{
                            "courseviewer:navigation:next:complete" | translate
                          }}
                          &</span
                        >
                        {{
                          "courseviewer:navigation:next:lesson" | translate
                        }}</span
                      >
                      <fa-icon
                        [icon]="faAngleRight"
                        size="2x"
                        [styles]="{ 'vertical-align': 'middle' }"
                      ></fa-icon>
                    </button>
                  </div>

                  <ng-template #notgreen>
                    <button class="btn btn-link pr-1" (click)="goNext()">
                      <span
                        *ngIf="updating"
                        class="fa fa-spinner fa-lg fa-spin px-3"
                      ></span>
                      <span class="align-middle me-2">{{
                        "courseviewer:navigation:next:lesson" | translate
                      }}</span>
                      <fa-icon
                        [icon]="faAngleRight"
                        size="2x"
                        [styles]="{ 'vertical-align': 'middle' }"
                      ></fa-icon>
                    </button>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</div>
