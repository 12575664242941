import { Component, Input, OnInit } from '@angular/core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Organization } from 'src/app/interfaces/organization';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { InitService } from 'src/app/services/init.service';
import { User } from 'src/app/interfaces/user';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-organization-choose',
  templateUrl: './organization-choose.component.html',
  styleUrls: ['./organization-choose.component.scss']
})
export class OrganizationChooseComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;

  user: User | null =  null;

  faChevronRight = faChevronRight;

  organizations: Array<Organization> = [];

  constructor(
    private orgsService: OrganizationsService,
    private initService: InitService,
    private authService: AuthService
  ) {
    this.orgsService.getUserOrganizationsMenu().then( (orgs: Array<Organization>) => {
      this.organizations = orgs.sort( (a, b) => { return a.id - b.id });
    });
    this.authService.user.subscribe( (user) => {
      this.user = user;
    })
  }

  ngOnInit(): void {
  }

  switchOrganization(organization: Organization): void {
    const currentOrg = this.initService.getCurrentOrg()
    if (currentOrg && currentOrg.slug != organization.slug) {
      let aUrl = window.location.href.replace('/login', '').split('//');
      let aDomain = aUrl[1].split('.');
      aDomain[0] = organization.slug;
      aUrl[1] = aDomain.join('.');
      window.location.href = aUrl.join('//');
    } else {
      this.selfModal?.close();
      window.location.href = window.location.href.replace('/login', '');
    }
  }

  logout() {
    this.authService.logOut(true);
  }
}
