<label for="gift_email" class="mb-1 fw-bold">{{ "send:as:gift:gift:for:email" | translate }}</label>
<input  id="gift_email"
        class="form-control mb-3"
        type="text"
        [(ngModel)]="this.checkout.order!.email"
        [placeholder]="'send:as:gift:gift:for:email:placeholder' | translate"/>
<label for="gift_email" class="mb-1 fw-bold">{{ "send:as:gift:gift:for:name" | translate }}</label>
        <input  id="gift_email"
                class="form-control mb-3"
                type="text"
                [(ngModel)]="this.checkout.order!.name"
                [placeholder]="'send:as:gift:gift:for:name:placeholder' | translate"/>     
<label for="gift_message" class="mb-1 fw-bold">{{ "send:as:gift:gift:for:message:title" | translate }}</label>
<textarea id="gift_message"
          class="form-control"
          [(ngModel)]="this.checkout.order!.message"
          [placeholder]="'send:as:gift:gift:for:message' | translate">
</textarea>
