import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'src/app/interfaces/subscription';

@Component({
  selector: 'app-subscriptions-cards',
  templateUrl: './subscriptions-cards.component.html',
  styleUrls: ['./subscriptions-cards.component.scss']
})
export class SubscriptionsCardsComponent implements OnInit {
  @Input() subscriptions: Array<Subscription> = [];

  constructor() { }

  ngOnInit(): void {}

}
