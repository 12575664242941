<footer>
  <div class="text-center py-2">
    <img
      src="assets/img/footer/logo_black.png"
      alt="Logo black"
      width="200px"
    />
  </div>
  <div class="border-top text-center py-3">
    <a
      href="https://www.movielearning.com/"
      target="_blank"
      class="btn btn-link mx-1"
      >{{ "navbar:discover" | translate }}</a
    >
    <a
      [href]="currentLang == 'en' ? links.aboutEn : links.aboutNl"
      target="_blank"
      class="btn btn-link mx-1"
      >{{ "footer:menu:item:2" | translate }}</a
    >
    <a
      [href]="currentLang == 'en' ? links.termsEn : links.termsNl"
      target="_blank"
      class="btn btn-link mx-1"
      >{{ "footer:menu:item:3" | translate }}</a
    >
    <a
      [href]="currentLang == 'en' ? links.privacyEn : links.privacyNl"
      target="_blank"
      class="btn btn-link mx-1"
      >{{ "footer:menu:item:4" | translate }}</a
    >
    <a
      [href]="currentLang == 'en' ? links.disclaimerEn : links.disclaimerNl"
      target="_blank"
      class="btn btn-link mx-1"
      >{{ "footer:menu:item:5" | translate }}</a
    >
    <a
      (click)="sendGtm()"
      [href]="currentLang == 'en' ? links.contactEn : links.contactNl"
      target="_blank"
      class="btn btn-link mx-1"
      >{{ "footer:menu:item:7" | translate }}</a
    >
  </div>
</footer>
