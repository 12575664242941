<div class="table-responsive-sm mb-4">
  <table class="table table-hover">
    <thead *ngIf="heading">
      <tr class="" [ngClass]="{ 'custom-border': customBorder }">
        <th *ngIf="checkbox" (change)="checkAllCheckBox($event)">
          <input type="checkbox" />
        </th>
        <th *ngFor="let item of headers" scope="col">
          {{ item.show ? item.name : "" }}
        </th>
        <th class="text-center" *ngIf="actions && isAdmin()" scope="col">{{'Actions' | translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        [ngClass]="{ 'custom-border': customBorder }"
        *ngFor="let member of listMembers; let i = index"
      >
        <td *ngIf="checkbox">
          <input
            type="checkbox"
            [(ngModel)]="listMembers[i].checked"
            name="check"
            value="{{ member.id }}"
          />
        </td>
        <td class="align-middle" *ngFor="let item of headers">
          <app-user-icon
            *ngIf="item.nickname == 'icon'"
            [user]="member.icon"
          ></app-user-icon>
          {{ write(member, item) }}
        </td>
        <td *ngIf="actions && isAdmin()" class="align-middle text-center">
          <img
          *ngIf="member.type != 'manager'"
            href="#"
            (click)="deleteUserInvited(member, i)"
            class="img-trash cursor-pointer"
            src="../../../../../assets/statics/img/trash-can.svg"
            alt="img trash"
          />
        </td>
      </tr>
    </tbody>
  </table>
</div>
