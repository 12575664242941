import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Organization } from 'src/app/interfaces/organization';
import { CoursesService } from 'src/app/services/courses.service';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { ToastService } from 'src/app/services/toast.service';
import { ShareCourseComponent } from '../../courses/share-course/share-course.component';

@Component({
  selector: 'app-share-course-platform',
  templateUrl: './share-course-platform.component.html',
  styleUrls: ['./share-course-platform.component.scss'],
})
export class ShareCoursePlatformComponent implements OnInit {
  @Input() courseId: any = '';
  public course: any = '';
  @Input() user: any = '';
  @Output() refresh: EventEmitter<any> = new EventEmitter();
  @Input() selfModal: NgbModalRef | null = null;

  public organizations: Array<Organization> = [];
  public organizationsNotRegister: Array<Organization> = [];

  public faCircleNorch: any = faCircleNotch;
  public organizationNames = '';
  public faTimes = faTimes;
  public title = '';
  public loadSpin: boolean = false;
  public loadingOrganization: boolean = false;
  constructor(
    private orgsService: OrganizationsService,
    private coursesService: CoursesService,
    private toastService: ToastService,
    private ngbModal: NgbModal
  ) {}

  ngOnInit(): void {
    this.getCourse();
  }

  public closeModal() {
    this.course = null;
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  public getCourse() {
    this.loadingOrganization = true;
    this.coursesService
      .getCourseSummary(parseInt(this.courseId))
      .then((course) => {
        if (course) {
          this.course = course.data;
          this.getOrganization(this.course.user.username);
        }
      });
  }
  public getOrganization(username: string) {
    this.orgsService
      .getOrganizationCourse(username)
      .then((organizations: any) => {
        this.organizations = organizations.data.organizationsRegistered;
        this.organizationsNotRegister =
          organizations.data.organizationsNotRegistered;
        let currentOrg = this.orgsService.getCurrent();
      
        // if (currentOrg && !currentOrg.visible) {
        //   this.organizations = this.organizations.filter((o: any) => o.id != 1);
        // } else {
        //   this.organizations = JSON.parse(JSON.stringify(this.organizations));
        // }
        this.organizations = JSON.parse(JSON.stringify(this.organizations));

        
        //let check if organizations in courses
        this.organizations.forEach((org) => {
          org.checked = false;
          let found = this.course?.organizations?.find(
            (o: Organization) => o.id == org.id
          );
          if (found) {
            org.checked = true;
          }
        });

        if (!Array.isArray(this.organizationsNotRegister)) {
          this.organizationsNotRegister = [];
        }
        this.organizationsNotRegister.forEach((org) => {
          org.checked = false;
          let found = this.course?.organizations?.find(
            (o: Organization) => o.id == org.id
          );
          if (found) {
            org.checked = true;
          }
        });



        this.organizationsNotRegister.sort((a, b) => {
          const nameA = a.name.toUpperCase(); // Convertir a mayúsculas para ordenar de manera insensible a mayúsculas/minúsculas
          const nameB = b.name.toUpperCase();
        
          if (nameA < nameB) {
            return -1;
          }
        
          if (nameA > nameB) {
            return 1;
          }
        
          return 0; // Nombres son iguales
        });

        this.organizations.sort((a, b) => {
          const nameA = a.name.toUpperCase(); // Convertir a mayúsculas para ordenar de manera insensible a mayúsculas/minúsculas
          const nameB = b.name.toUpperCase();
        
          if (nameA < nameB) {
            return -1;
          }
        
          if (nameA > nameB) {
            return 1;
          }
        
          return 0; // Nombres son iguales
        });
        this.loadingOrganization = false;
      });


  }
  

  public sendInfo() {
    this.loadSpin = true;
    let organizations = this.organizations
      .filter((o) => o.checked)
      .map((o) => o.id);

    let organizationsNotRegister = this.organizationsNotRegister
      .filter((o) => o.checked)
      .map((o) => o.id);

    organizationsNotRegister.forEach((element) => {
      organizations.push(element);
    });

    let notOrganizations = this.organizations
      .filter((o) => !o.checked)
      .map((o) => o.id);

    this.coursesService
      .patchCourseOrganization(this.course.id, { organizations })
      .then((resp) => {
        this.loadSpin = false;

        if (resp.result == 'success') {
          this.toastService.showSuccess('Organizations saved');
        } else {
          this.toastService.showError(`Error saving organizations`);
        }
      })
      .catch((error) => {
        this.loadSpin = false;
        this.toastService.showError(`Error saving organizations`);
      });

    if (notOrganizations.length > 0) {
      //solo si hay para enviar.
      this.coursesService
        .patchCourseNotOrganization(this.course.id, { notOrganizations })
        .then((resp) => {
          this.loadSpin = false;

          if (resp.result == 'success') {
            // this.toastService.showSuccess('Organizations saved');
          } else {
            this.toastService.showError(`Error saving organizations`);
          }
        })
        .catch((error) => {
          this.loadSpin = false;
          this.toastService.showError(`Error saving organizations`);
        });
    }
  }

  public share() {
    let organizations = this.organizationsNotRegister.filter((o) => o.checked);
    if (organizations.length > 0) {
      if (this.selfModal) this.selfModal?.close();
      else $('ngb-modal-window').click();
      this.shareCourse(organizations);
    } else {
      this.sendInfo();
    }
  }

  public shareCourse(organizations: any) {
    let modalRef = this.ngbModal.open(ShareCourseComponent, {
      windowClass: 'default-modal',
      size: '800',
      centered: true,
    });
    modalRef.componentInstance.courseId = this.course!.id;
    modalRef.componentInstance.organizations = organizations;
    modalRef.componentInstance.user = this.course.user;

    modalRef.componentInstance.selfModal = modalRef;

    modalRef.componentInstance.refresh.subscribe((data: any) => {
      this.sendInfo();
    });
  }
}
