import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-video',
  templateUrl: './delete-video.component.html',
  styleUrls: ['./delete-video.component.scss']
})
export class DeleteVideoComponent implements OnInit {

  @Input() organizationId: any = '';
  @Input() videoId: any = '';
  @Input() selfModal: NgbModalRef | null = null;
  @Output() refreshDelete = new EventEmitter<any>();

  public faCircleNorch: any = faCircleNotch;

  public faTimes = faTimes;
  public loadSpin: boolean = false;

  constructor() {}

  ngOnInit(): void {}
  public deleteVideoEmit() {
    this.refreshDelete.emit();
    this.closeModal();
  }
  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

}
