<div
  *ngIf="blockedIp"
  class="alert alert-danger bg-danger text-white alert-dismissible fade show pe-3"
  role="alert"
>
  <div class="row">
    <div class="col">
      {{ "login:blocked:error" | translate }}
      {{ $any(timer$ | async)! * 1000 | timeLength }} minutes.
    </div>
  </div>
</div>

<div
  [innerHtml]="'course:login:free_preview' | translate"
  class="mb-4 text-center"
></div>
<div *ngIf="state == 'register'" class="mb-4 text-center">
  <p>
    {{ "sign_up:text" | translate }}
    <span class="link-text" (click)="changeState('login')">
      {{ "sign_up:text:1" | translate }}</span
    >
  </p> 
</div>

<div *ngIf="state == 'login'; else registert" [@inOutAnimation]>
  <h3 class="text-center pb-3 fw-bold h4">{{ "login:title" | translate }}</h3>
  <form [formGroup]="loginForm">
    <div class="mb-3">
      <label for="login-email" class="form-label fw-500 fs-15">{{
        "sign_up:email:label" | translate
      }}</label>

      <input
        type="email"
        class="form-control"
        id="login-email"
        formControlName="email"
        placeholder="{{ 'login:email:placeholder' | translate }}"
      />
    </div>
    <div class="mb-3">
      <label for="login-password" class="form-label fw-500 fs-15">{{
        "login:password:label" | translate
      }}</label>

      <input
        [type]="showPassword ? 'text' : 'password'"
        class="form-control"
        id="login-password"
        formControlName="password"
        placeholder="{{ 'login:password:placeholder' | translate }}"
      />
      <span
        (click)="showHidePassword()"
        style="cursor: pointer"
        class="fa fa-fw field-icon toggle-password"
        [ngClass]="showPassword == true ? ' fa-eye' : ' fa-eye-slash'"
      ></span>
    </div>

    <div class="mb-4 form-check">
      <input
        formControlName="checkLogin"
        type="checkbox"
        class="form-check-input"
        id="exampleCheck1"
      />
      <label class="form-check-label fw-500 fs-14" for="exampleCheck1">{{
        "login:remember:email" | translate
      }}</label>
    </div>
    <div class="d-grid gap-2">
      <button
        class="btn btn-primary fs-14 py-2"
        [class.disabled]="loading || blockedIp"
        (click)="login()"
      >
        Enter <span *ngIf="organization">{{ organization!.name }}</span
        ><fa-icon
          class="ms-2"
          [icon]="faCircleNorch"
          *ngIf="loading"
          [spin]="true"
        ></fa-icon>
      </button>
    </div>
    <!-- <div class="d-grid gap-2 mt-2">
      <a [href]="ssoUrl"
        class="btn btn-outline-primary fs-14 py-2"
        [class.disabled]="loading || blockedIp"
      >
        {{ "Login using HHS" | translate }}
      </a>
    </div> -->
  </form>
</div>
<ng-template #registert>
  <div *ngIf="state == 'register'; else recover" [@inOutAnimation]>
    <div *ngIf="messageRegister" class="row text-center pb-3">
      <p>
        {{ "sign_up:text" | translate }}
        <span class="link-text" (click)="changeState('login')">
          {{ "sign_up:text:1" | translate }}</span
        >
      </p>
    </div>
    <form [formGroup]="registerForm">
      <div class="mb-3">
        <label for="register-username" class="form-label fw-500 fs-15">{{
          "sign_up:name:label" | translate
        }}</label>

        <input
          type="username"
          class="form-control"
          id="register-username"
          formControlName="username"
          placeholder="{{ 'sign_up:username:field:label' | translate }}"
        />
      </div>
      <div class="mb-3">
        <label for="register-email" class="form-label fw-500 fs-15">{{
          "sign_up:email:label" | translate
        }}</label>

        <input
          type="email"
          class="form-control"
          id="register-email"
          formControlName="email"
          placeholder="{{ 'sign_up:email:field:label' | translate }}"
        />
      </div>

      <div class="mb-3">
        <label for="register-password" class="form-label fw-500 fs-15">{{
          "login:password:label" | translate
        }}</label>

        <input
          [type]="showPassword ? 'text' : 'password'"
          class="form-control"
          id="register-password"
          formControlName="password"
          placeholder="{{ 'sign_up:password:field:label' | translate }}"
        />
        <span
          (click)="showHidePassword()"
          style="cursor: pointer"
          class="fa fa-fw field-icon toggle-password"
          [ngClass]="showPassword == true ? ' fa-eye' : ' fa-eye-slash'"
        ></span>
        <div
          *ngIf="registerForm.controls['password'].value"
          class="p-2 px-1 px-md-4 text-muted"
        >
          <div class="check-meter mb-1">
            <fa-icon
              [icon]="faCheckCircle"
              [ngClass]="
                registerForm.controls['password'].hasError('minlength')
                  ? 'text-muted'
                  : 'text-success'
              "
            ></fa-icon>
            {{ "password:requirements:length" | translate }}
          </div>
          <div class="check-meter mb-1">
            <fa-icon
              [icon]="faCheckCircle"
              [ngClass]="
                registerForm.controls['password'].hasError('lowerCount')
                  ? 'text-muted'
                  : 'text-success'
              "
            ></fa-icon>
            {{ "password:requirements:letter:l" | translate }}
          </div>
          <div class="check-meter mb-1">
            <fa-icon
              [icon]="faCheckCircle"
              [ngClass]="
                registerForm.controls['password'].hasError('upperCount')
                  ? 'text-muted'
                  : 'text-success'
              "
            ></fa-icon>
            {{ "password:requirements:letter:u" | translate }}.
          </div>
          <div class="check-meter mb-1">
            <fa-icon
              [icon]="faCheckCircle"
              [ngClass]="
                registerForm.controls['password'].hasError('numberCount')
                  ? 'text-muted'
                  : 'text-success'
              "
            ></fa-icon>
            {{ "password:requirements:number" | translate }}
          </div>
          <div class="check-meter mb-1">
            <fa-icon
              [icon]="faCheckCircle"
              [ngClass]="
                registerForm.controls['password'].hasError('specialCount')
                  ? 'text-muted'
                  : 'text-success'
              "
            ></fa-icon>
            {{ "password:requirements:special" | translate }}
          </div>
        </div>
      </div>

      <div class="mb-3 form-check" *ngIf="currentOrg?.show_terms_conditions">
        <input
          id="check1"
          type="checkbox"
          class="form-check-input"
          formControlName="terms_conditions"
        />
        <label for="check1" class="form-check-label fw-500 fs-14">
          {{ "login:sign_up:terms:1" | translate }}
          <span class="text-primary pe-pointer" (click)="openModalTerms()">
            {{ "login:sign_up:terms:2" | translate }} </span
          ><br />
          {{ "login:sign_up:terms:3" | translate }}
          <span class="text-primary pe-pointer" (click)="openModalPrivacy()">
            {{ "login:sign_up:privacy:2" | translate }}
          </span>
        </label>
      </div>

      <div class="d-grid gap-2">
        <button
          class="btn btn-primary fs-14 py-2"
          href="javascript:void(0);"
          (click)="register()"
          [class.disabled]="loading || blockedIp"
        >
          {{ "sign_up:preview:lesson:label" | translate
          }}<fa-icon
            class="ms-2"
            [icon]="faCircleNorch"
            *ngIf="loading"
            [spin]="true"
          ></fa-icon>
        </button>
      </div>
      <!-- <div class="d-grid gap-2 mt-2">
        <a [href]="ssoUrl"
           class="btn btn-outline-primary fs-14 py-2"
           [class.disabled]="loading || blockedIp"
        >
          {{ "Sign up using HHS" | translate }}
        </a>
      </div> -->
    </form>
  </div>
</ng-template>
<ng-template #recover>
  <div *ngIf="state == 'recover'" [@inOutAnimation]>
    <h3 class="text-center pb-3 fw-bold h4">
      {{ "login:reset_password:label" | translate }}
    </h3>
    <p class="text-center px-3 fs-15 fw-light text-muted font-family-karla">
      {{ "login:reset_password:description" | translate }}
    </p>
    <form [formGroup]="forgotForm">
      <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label fw-500 fs-15">{{
          "sign_up:email:label" | translate
        }}</label>
        <input
          type="email"
          class="form-control"
          formControlName="email"
          placeholder="{{ 'login:reset_password:placeholder' | translate }}"
        />
      </div>
      <div class="d-grid gap-2">
        <button
          type="submit"
          class="btn btn-primary"
          [class.disabled]="loading || forgotForm.controls['email'].errors"
          (click)="forgot()"
        >
          {{ "login:reset_password:button" | translate }}
        </button>
      </div>
    </form>
  </div>
</ng-template>
