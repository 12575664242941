import { Component, Input, OnInit } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CoursesService } from 'src/app/services/courses.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-reset-progress',
  templateUrl: './reset-progress.component.html',
  styleUrls: ['./reset-progress.component.scss']
})
export class ResetProgressComponent implements OnInit {
  faTimes = faTimes;
  @Input() description: string = '';
  @Input() selfModal: NgbModalRef | null = null;
  @Input() course: any;
  loading: boolean = false;
  faCircleNorch: any = faCircleNotch;

  constructor(
    private coursesService: CoursesService,
    private toastr: ToastService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {}

  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  resetProgress() {
    this.loading = true;
    this.coursesService.resetProgress(this.course).then( res => {
      this.toastr.showSuccess(this.translateService.instant('course:progress:reset:confirm'));
      setTimeout( () => {
        window.location.reload();
      }, 1500);
    }).catch( (err) => {
      this.toastr.showError(err);
    });
  }
}
