import { VideoService } from './../../services/video.service';
import { Component, Input, OnInit } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-video-duplicate',
  templateUrl: './video-duplicate.component.html',
  styleUrls: ['./video-duplicate.component.scss'],
})
export class VideoDuplicateComponent implements OnInit {
  faTimes = faTimes;
  @Input() video: any | null = null;
  @Input() selfModal: NgbModalRef | null = null;
  loadSpin: boolean = false;
  public videoId: number = 0;
  faCircleNorch: any = faCircleNotch;
  public infoDuplicate: any = {
    details: false,
    analysis: false,
  };

  constructor(
    private ngbModal: NgbModal,
    private videoService: VideoService,
    private router: Router
  ) {}

  ngOnInit(): void {
    console.log(this.video);
  }
  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
  public duplicate() {
    this.loadSpin = true;
    this.videoService
      .duplicate(this.video.id, this.infoDuplicate)
      .then((resp: any) => {
        this.loadSpin = false;
        this.videoId = resp.data.videopost.id;
        this.closeModal();
        this.goToEdit(this.videoId);
      });
  }
  public goToEdit(id: number) {
    this.router.navigate(['/video', 'edit', id]);
  }
}
