<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title fw-bold">
    {{ "text:ocean:1" | translate }}
  </h4>
</div>
<div class="modal-body flex-column">
  <div class="row">
    <p>{{ "text:ocean:2" | translate }}</p>
    <p>{{ "text:ocean:3" | translate }}</p>
    <p>{{ "text:ocean:4" | translate }}</p>
  </div>
</div>
<div class="row justify-content-end modal-footer">
  <div class="col-auto">
    <button
      type="button"
      role="tab"
      class="btn btn-primary btn-create ms-2 fs-15 text-nowrap"
      (click)="closeModal()"
    >
      <fa-icon
        [icon]="faCircleNorch"
        *ngIf="loadSpin"
        [spin]="true"
      ></fa-icon>
      {{ "Ok" | translate }}
    </button>
  </div>
</div>
