import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CollectionService } from 'src/app/services/collection.service';
import { CoursesService } from 'src/app/services/courses.service';
import { GroupsService } from 'src/app/services/groups.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-collections-redirect',
  templateUrl: './collections-redirect.component.html',
  styleUrls: ['./collections-redirect.component.scss']
})
export class CollectionsRedirectComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cs: CollectionService,
    private gs: GroupsService,
    private toastr: ToastService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.route.url.subscribe( (paths) => {
      let accept = false;
      if (paths[paths.length - 1] && paths[paths.length - 1].path == 'acceptinvitation') {
        accept = true;
      }

      this.route.params.subscribe( (params) => {
        const collection = params['id'];

        let results: Array<Promise<any>> = [];

        results.push(this.gs.getProfile(collection).then( res => {
          return res.data;
        }));

        if (accept) {
          results.push( this.cs.acceptInvitation(collection).then( res => {
            this.toastr.showSuccess(this.translate.instant('groups:members:accept:invitation:success'));
          }).catch( err => {
            const message = this.translate.instant(err.error?.message?.friendly) || this.translate.instant('groups:members:accept:invitation:error');
            this.toastr.showError(message)
          }));
        };

        Promise.all(results).then( data => {
          const collection = data[0].collection;
          switch (collection.type) {
            case 'playlist':
              this.router.navigate(['/playlists', collection.id]);
              break;
            case 'group':
              this.router.navigate(['/groups', collection.id]);
              break;
            case 'course':
              this.router.navigate(['/courses', collection.id], {
                queryParams: { tab: 'about' },
              });
              break;
            default:
              this.router.navigate(['/']);
          }
        });
      });
    });
  }

}
