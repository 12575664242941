import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";
import { BehaviorSubject, EMPTY, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take, tap } from "rxjs/operators";
import { InitService } from "../services/init.service";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { AuthService } from "../services/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshedTokenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private initService: InitService,
    private translate: TranslateService,
    private cookieService: CookieService,
    private auth: AuthService
  ) { }

  //function which will be called for all http calls
  intercept( request: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    if (!request.url.includes('/oauth/token')) {
      request = this.addHeaders(request);
    }

    return next.handle( this.addHeaders(request) ).pipe(
      catchError( (error) => {
        if (error.status == 401) {
          if (!request.url.includes('/oauth/token')) {
            return this.handleRefresh( request, next);
          }
        }
        return throwError(error);
      })
    );
  }

  addHeaders( request: HttpRequest<any> ) {
    let headers = request.headers;

    // Org and lang can be seted on another interceptor
    const currentOrg = this.initService.getCurrentOrg();
    if (currentOrg) {
      headers = headers.set('organization_ids', currentOrg!.id.toString());
    }

    headers = headers.set('language', this.translate.currentLang || this.translate.defaultLang);

    const token = this.cookieService.get('access_token');
    if (token !== null && token != '') {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }

    return request.clone({
      headers: headers
    });
  }

  private handleRefresh(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      return this.auth.refreshToken().pipe(
        switchMap( res => {
          this.isRefreshing = false;
          return next.handle( this.addHeaders(request) );
        }),
        catchError( (error: any) => {
          // debugger;
          this.isRefreshing = false;
          if (error.status == 422) {
            return throwError(error);
          }
          this.auth.logOut();
          return throwError(error);
        })
      )
    }

    return this.refreshedTokenSubject.pipe(
      filter(token => token !== false),
      take(1),
      switchMap( () => next.handle(this.addHeaders(request)) )
    );
  }
}
