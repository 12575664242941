<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title">
    {{ "collection:modal:go:to:duplicate:title" | translate }}
  </h4>
</div>
<div class="modal-body flex-column">
  <div class="row">
    <p class="description mb-3">
      {{ "collection:modal:go:to:duplicate:text:1" | translate }}
    </p>
  </div>
  <div class="row">
    <label class="form-label mb-3">
      {{ "collection:modal:go:to:duplicate:text:2" | translate }}
    </label>
    <div
      class="col-12 org-back ng-star-inserted align-items-center d-flex"
      *ngFor="let organization of organizations"
    >
      <p class="mb-0 fw-bold" (click)="switchOrganization(organization)">
        {{ organization.name }}
      </p>
    </div>
  </div>
</div>
<div class="modal-footer"></div>
