import { DeleteUserComponent } from './../delete-user/delete-user.component';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DeactivateOneMemberComponent } from 'src/app/modals/organizations/deactivate-one-member/deactivate-one-member.component';
import { AdminService } from 'src/app/services/admin.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {
  @Output() refresh = new EventEmitter<any>();
  @Output() refreshEdit = new EventEmitter<any>();
  @Input() selfModal: NgbModalRef | null = null;
  @Input() user: any;
  @Input() avatar: any;
  @Input() organizationId: any;
  info: any = {
    name: '',
    email: '',
    password: '',
    passwordRepeat: null,
  };
  faTimes = faTimes;
  faCircleNorch = faCircleNotch;
  loading = false;
  radio = true;
  constructor(
    private ngbModal: NgbModal,
    private adminService: AdminService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    if (this.user) {
      this.info.name = this.user.display_name;
      this.info.email = this.user.email;
    }
  }
  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  public deactivateUser(activate: boolean = false) {
    this.closeModal();
    let modalRef = this.ngbModal.open(DeactivateOneMemberComponent, {
      windowClass: 'default-modal',
      size: '600',
      centered: false,
    });
    modalRef.componentInstance.memberId = this.user.id;
    modalRef.componentInstance.activate = activate;
    modalRef.componentInstance.refresh.subscribe((data: any) => {
      this.refresh.emit();
    });
  }
  public deleteUser() {
    this.closeModal();
    let modalRef = this.ngbModal.open(DeleteUserComponent, {
      windowClass: 'default-modal',
      size: '600',
      centered: false,
    });
    modalRef.componentInstance.userId = this.user.id;
    modalRef.componentInstance.refresh.subscribe((data: any) => {
      this.refresh.emit();
    });
  }
  public save() {
    if (this.info.password.length < 6) {
      this.toastService.showError('Should not be shorter than 6 characters');
      return;
    }
    if (this.info.password != this.info.passwordRepeat) {
      this.toastService.showError(
        'Repeat password must be equals to Password.'
      );
      return;
    }
    this.loading = true;
    this.adminService
      .saveUserInfo(this.info.name, {
        name: this.info.name,
        email: this.info.email,
        password: this.info.password,
      })
      .then((resp) => {
        if (resp.result == 'success') {
          this.loading = false;
          this.toastService.showSuccess('The member has been activated');
          this.closeModal();
          this.refreshEdit.emit();
        } else {
          this.toastService.showError('Error');
        }
      })
      .catch((error) => {
        this.loading = false;
        this.toastService.showError(error);
      });
  }
}
