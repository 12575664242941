<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title">{{ number }} {{ title || "Empty title" }}</h4>
</div>
<div class="modal-body flex-column">
  <div class="row">
    <p class="description" [innerHtml]="description"></p>
    <p *ngIf="!description" class="description">
      {{ "This field does not have a description yet" | translate }}
    </p>
  </div>
</div>
