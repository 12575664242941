<div class="table-responsive-sm mb-4 py-5">
  <table class="table table-hover">
    <thead>
      <tr class="">
        <th scope="col" class="custom-first-head">
          {{ "dh_teams:table:title" | translate }}
        </th>
        <th scope="col" class="custom-middle-head">
          {{ "Highest role 1" | translate }}
        </th>
        <th scope="col" class="custom-middle-head">
          {{ "Highest role 2" | translate }}
        </th>
        <th scope="col" class="custom-middle-head">
          {{ "Highest role 3" | translate }}
        </th>
        <th scope="col" class="custom-middle-head">
          {{ "Highest role 4" | translate }}
        </th>
        <th scope="col" class="lowest-head">{{ "Lowest role" | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of infoTable; let i = index">
        <td class="d-flex align-items-center justify-content-start">
          <app-user-icon [user]="item?.user"></app-user-icon>
          <span class="ms-3">
            {{ item.user.display_name }}
          </span>
        </td>
        <td class="opacity" *ngFor="let value of item?.high">
          <div class="my-2">
            {{ "dh_profile:competences:" + value.competence_id | translate }}
            {{ value.average | number: "1.0-2" }}
          </div>
        </td>
        <td class="opacity">
          <div class="my-2">
            {{ "dh_profile:competences:" + item.low.competence_id | translate }}
            {{ item.low.average | number: "1.0-2" }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <table class="table table-hover mt-5">
    <thead>
      <tr class="">
        <th scope="col" class="custom-first-head">Roles</th>
        <th scope="col" class="custom-middle-head">
          {{ "Highest role 1" | translate }}
        </th>
        <th scope="col" class="custom-middle-head">
          {{ "Highest role 2" | translate }}
        </th>
        <th scope="col" class="custom-middle-head">
          {{ "Highest role 3" | translate }}
        </th>
        <th scope="col" class="custom-middle-head">
          {{ "Highest role 4" | translate }}
        </th>
        <th scope="col" class="lowest-head">{{ "Lowest role" | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of [].constructor(12); let i = index">
        <td class="d-flex align-items-center">
          {{ "dh_profile:competences:" + (i + 1) | translate }}
        </td>
        <td *ngFor="let item of [].constructor(5); let j = index">
          {{ counts[j] ? (counts[j][i + 1] ? counts[j][i + 1] : "-") : "-" }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
