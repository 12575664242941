import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CoursesService } from 'src/app/services/courses.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-leave-course',
  templateUrl: './leave-course.component.html',
  styleUrls: ['./leave-course.component.scss'],
})
export class LeaveCourseComponent implements OnInit {
  @Input() courseId: any = '';
  @Input() selfModal: NgbModalRef | null = null;
  @Output() refresh: EventEmitter<any> = new EventEmitter();

  public faCircleNorch: any = faCircleNotch;

  public faTimes = faTimes;
  public loadSpin: boolean = false;

  constructor(
    private toastService: ToastService,
    private translateService: TranslateService,
    private coursesService: CoursesService
  ) {}

  ngOnInit(): void {}

  public unenrollCourse() {
    this.loadSpin = true;

    this.coursesService
      .patchUnenrollCourse(this.courseId)
      .then((res) => {
        this.loadSpin = false;
        if (res.result == 'success') {
          this.toastService.showSuccess(
            this.translateService.instant('courses:leave:success')
          );
        }
        this.refresh.emit()
        this.loadCourse();
        this.closeModal();
      })
      .catch((err) => {
        this.toastService.showError(
          this.translateService.instant('collection:open:unenroll:error')
        );
      });
  }

  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  public loadCourse() {
    this.coursesService.loadCourseSummary(this.courseId);
  }
}
