import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoUploadComponent } from './video-upload/video-upload.component';
import { DetailsFormComponent } from './details-form/details-form.component';
import { ImdbSearchComponent } from './imdb-search/imdb-search.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxFileDropModule } from 'ngx-file-drop';
import { TranslateModule } from '@ngx-translate/core';
import {SharedPublicFormComponent} from './shared-public-form/shared-public-form.component'
import {AdvancedSettingFormComponent} from './advanced-setting-form/advanced-setting-form.component'
import {CategoryFormComponent} from './category-form/category-form.component';
import { DhFormComponent } from './dh-form/dh-form.component'
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TagsFormComponent } from './tags-form/tags-form.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { LeaveVideoComponent } from './leave-video/leave-video.component';

@NgModule({
  declarations: [
    VideoUploadComponent,
    DetailsFormComponent,
    ImdbSearchComponent,
    SharedPublicFormComponent,
    AdvancedSettingFormComponent,
    CategoryFormComponent,
    DhFormComponent,
    TagsFormComponent,
    LeaveVideoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    FontAwesomeModule,
    SharedModule,
    NgxFileDropModule,
    SharedModule,
    NgbTooltipModule,
    PipesModule
  ],
  exports: [
    DetailsFormComponent,
    ImdbSearchComponent,
    SharedPublicFormComponent,
    AdvancedSettingFormComponent,
    CategoryFormComponent,
    DhFormComponent,
    TagsFormComponent
  ]
})
export class VideoEditModule { }
