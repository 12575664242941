<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon>
  <span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">
    {{ "dh_profile:competences:" + competence.competence_id | translate }}
  </h4>
</div>
<div class="modal-body p-3">
  <div class="row">
    <div class="col-2">
      <img class="img-chart background" [src]="imgChart" />
    </div>
    <div class="col-10">
      <p class="description">
        {{
          "dh_profile:competences:description:" + competence.competence_id
            | translate
        }}
      </p>
      <div class="row box">
        <div id="chart"></div>
      </div>
    </div>
  </div>
</div>
