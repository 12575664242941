import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import * as moment from 'moment';
import { InvoiceDetails, Order } from 'src/app/interfaces/order';
import { Organization } from 'src/app/interfaces/organization';
import { AuthService } from 'src/app/services/auth.service';
import { InitService } from 'src/app/services/init.service';
import { LangService } from 'src/app/services/lang.service';
import { OrdersService } from 'src/app/services/orders.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss'],
})
export class ResultComponent implements OnInit {
  @Input() selfModal: NgbModalRef | undefined;
  @Input() orderId: number | undefined;
  @Input() success: boolean | undefined;
  @Input() status: string | undefined;
  public competencies: any = [];
  order: Order | any;
  invoiceDetails: InvoiceDetails | undefined;

  loading = true;
  public currentOrganization: Organization | null = null;
  public compentencyPrice: any = 24.5;
  public language = 'nl';

  constructor(
    private ordersService: OrdersService,
    private auth: AuthService,
    private router: Router,
    private gtmService: GoogleTagManagerService,
    private initService: InitService,
    private lang: LangService,
  ) {

    this.lang.language.subscribe((lang) => {
      this.language = lang || 'en';
      if (this.language == 'en') {
        this.compentencyPrice = environment.en_compentencyPrice;
      } else {
        this.compentencyPrice = environment.nl_compentencyPrice;
      }
    });
  }

  ngOnInit(): void {
    this.currentOrganization = this.initService.getCurrentOrg();

    if (this.orderId) {
      this.ordersService.get(this.orderId).then((order) => {
        this.order = order;
        this.competencies = JSON.parse(this.order.competency_data);
        const gtmTag = {
          event: 'purchase',
          ecommerce: {
            currency: 'USD',
            value: this.order.total,
            tax: this.order?.tax,
            shipping: 0,
            affiliation: 'Movielearning',
            transaction_id: this.order?.id,
            payment_type: 'Mollie',
            shipping_tier: '',
            coupon: this.order?.coupon,
            items: [
              {
                item_name: this.order?.collections[0]?.name,
                item_id: this.order?.collections[0]?.id,
                price: this.order.order_price,
                item_brand: '',
                item_category: '',
                item_category2: '',
                item_category3: '',
                item_category4: '',
                item_variant: '',
                quantity: '1',
              },
            ],
          },
        };
        if (this.currentOrganization?.slug == 'courses') {
          console.log('Sending GTM', gtmTag);
          this.gtmService.pushTag(gtmTag);
        }
        if (Array.isArray(order.invoice_details)) {
          this.invoiceDetails = order.invoice_details[0];
        } else {
          this.invoiceDetails = order.invoice_details;
        }

        this.loading = false;
      });
    }
  }

  getSubscriptionEnds() {
    var format = 'MMMM Do YYYY';
    if (this.auth.getMe() && this.auth.getMe()!.language == 'nl') {
      format = 'D MMMM YYYY';
    }
    return moment(this.order!.end_date).format(format);

    return moment()
      .add(this.order?.entries[0].subscription_days, 'days')
      .format(format);
  }

  getSubscriptionStart() {
    var format = 'MMMM Do YYYY';
    if (this.auth.getMe() && this.auth.getMe()!.language == 'nl') {
      format = 'D MMMM YYYY';
    }
    return moment(this.order!.start_date).format(format);
  }

  closeModal() {
    this.selfModal?.close();
  }

  goToCourse() {
    this.router.navigate(['/courses', this.order?.entries[0].collection_id]);
    this.selfModal?.close();
  }

  getOrderDate(): string {
    let format = 'MMMM Do YYYY';
    if (this.language == 'en') {
      format = 'MMMM Do YYYY';
      moment.locale('en');
    } else {
      format = 'D MMMM YYYY';
      moment.locale('nl');
    }
    return moment(this.order?.created_at ).format(format);
  }
}
