import { ToastService } from '../../../services/toast.service';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LangService } from 'src/app/services/lang.service';
import { TranslateService } from '@ngx-translate/core';
///////////
//icons
///////////
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
/////////////
//Interfaces
/////////////
import { TableHeader } from 'src/app/interfaces/headers';
///////////
//Services
///////////
import { GroupsService } from 'src/app/services/groups.service';

import * as Papa from 'papaparse';

@Component({
  selector: 'app-team-invite',
  templateUrl: './team-invite.component.html',
  styleUrls: ['./team-invite.component.scss'],
})
export class TeamInviteComponent implements OnInit, AfterViewInit {
  ///////////
  //Atributes
  ///////////
  faTimes = faTimes;
  @Output() addUser = new EventEmitter<any>();
  @Input() selfModal: NgbModalRef | null = null;
  @Input() team: any;
  @Input() fromDhTeam: boolean = false;
  @Input() usersInTeam: any;
  @Input() actualTab = 'inviteNewUser';
  public faCircleNorch = faCircleNotch;
  public usersInvited: any = [];
  public loading: boolean = false;
  public selectUsers: any = [];
  public textName: any = '';
  public loadSpin: boolean = false;
  public loadingCSV: boolean = false;
  public reloadPage: boolean = false;
  public textMessage: string = '';
  public language='nl';
  public headers: TableHeader[] = [
    { name: 'Icon', show: false, nickname: 'icon', source: 'user', id: 'id' },
    {
      name: 'Name',
      show: true,
      nickname: 'name',
      source: 'user.display_name',
      id: 'id',
    },
    // {
    //   name: 'Email',
    //   show: true,
    //   nickname: 'email',
    //   source: 'user.email',
    //   id: 'id',
    // },
    {
      name: 'Status',
      show: true,
      nickname: 'status',
      source: 'status',
      id: 'id',
    },
  ];
  public users: any = [];
  public membersEnrolled: any = [];
  public membersEnrolledLength: any =0;


  constructor(
    private groupsService: GroupsService,
    private toastService: ToastService,
    private translate: TranslateService,
    private lang: LangService,
    public activeModal: NgbActiveModal
  ) {}
  ngAfterViewInit(): void {
    if (this.actualTab == 'members') {
      let curriculum = document.getElementById('memberTab');
      curriculum?.click();
    }
  }
  ngOnInit(): void {
    this.getUsersInvited();
    this.lang.language.subscribe( lang => {
      this.language = lang || 'en';
     
    })
   

  }

  readFile(file: File) {
    Papa.parse(file, {
      header: true,
      complete: (results, file) => {
        // Aquí puedes hacer algo con los datos procesados
        let count = 0;
        for (let row of results.data as any[]) {
          if (row && row.email && row.user) {
            count++;
            this.selectUsers.push({
              avatar: null,
              avatarPathUrlSmall: null,
              display_name: row.user,
              edit: true,
              email: row.email,
              status: 'pending_by_user',
            });
          }
        }
        if (count == 0) {
          this.toastService.showError(this.translate.instant('Not found data'));
        }
      },
      error: (error, file) => {
        console.error('Error parsing CSV:', error);
        this.toastService.showError(
          this.translate.instant('collection:members:add:csv:error')
        );
      },
    });
  }

  openCSV() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (event: any) => {
      const file = event.target.files[0];
      this.readFile(file);
    };
    input.click();
  }

  closeModal() {
   if (this.reloadPage || (this.membersEnrolled.length<this.membersEnrolledLength)){
      window.location.reload();
   }else{
     if (this.selfModal) this.selfModal?.close('testing ok');
     else $('ngb-modal-window').click();
   }
  }

  getTab(tab: string) {
    this.actualTab = tab;
    if (this.actualTab == 'invited') {
      this.getUsersInvited();
    }
    if (this.actualTab == 'members') {
      this.getMembersEnrolled();
    }
  }

  getUsersInvited() {
    this.loading = true;

    this.groupsService.getUsersInvited(this.team.id).then((result) => {
      let data: any;
      data = result.data.enrolled_users;
      this.usersInvited = this.groupsService.mapUsers(data, this.headers);

      this.loading = false;
    });
  }

  getMembersEnrolled() {
    this.loading = true;

    this.groupsService.getUsersAccepted(this.team.id).then((result) => {
      let data: any;
      data = result.data.enrolled_users;
      this.membersEnrolled = this.groupsService.mapUsers(data, this.headers);
      this.loading = false;
      this.membersEnrolledLength=JSON.parse(JSON.stringify(this.membersEnrolled.length));
    });
  }

  usersSelected(data: any) {
    this.selectUsers = data;
    // this.textName =  this.selectUsers[this.selectUsers.length-1].display_name;
  }

  sendInvitation() {
    let allUserInvited: any;
    this.reloadPage=true;
    //this.loadSpin = true;
    allUserInvited = this.selectUsers.map((user: any) => {
      return {
        display_name: user.display_name,
        email: user.email,
        message: this.textMessage,
      };
    });
    
    //agrego status para que se sepa que esta pendiente de aceptación.
    this.selectUsers.forEach((user: any) => {
      if (!user.id) {
        user.id = null;
      }
      if (this.team?.hypothetical) {
        user.status = this.team.name || 'nombre del team';
      
      }else{

        user.status = 'pending_by_user';
      }
    });
    
    console.log(this.selectUsers);
    let onlyUsers = this.selectUsers.filter((user: any) => user.id);
    
    console.log(onlyUsers);
    this.groupsService
      .sendInvitations(this.team.id, allUserInvited, true, this.language)
      .then((data) => {
        this.addUser.emit(onlyUsers);
        this.selectUsers = [];
        this.toastService.showSuccess(
          this.translate.instant('collection:members:add:members:success')
        );
        this.loadSpin = false;
        this.closeModal();
      })
      .catch((err) => {
        console.log(err);
        this.toastService.showError(
          this.translate.instant('organization:members:send_invitation:error')
        );
      });
  }

  public getUsersFromSubTeam(event: any) {
    this.selectUsers[event.index].id = event.user?.id;
  }
}
