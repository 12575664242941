import { DeleteAccountModalComponent } from './../../../modals/profile/delete-account-modal/delete-account-modal.component';
import { ChangeOwnerModalComponent } from './../../../modals/change-owner/change-owner-modal.component';
import { UsersService } from 'src/app/services/users.service';
import { Component, Input, OnInit } from '@angular/core';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/services/auth.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common'
@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss'],
})
export class DeleteAccountComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  

  /////////////
  // Atributes
  /////////////
  public user: any;
  public username: any;
  public userId: any = '';
  public videos: any;
  public files: any;
  public groups: any;
  public courses: any;
  public playlists: any;
  public loading: boolean = false;
  public mimetypeId = 1;
  public faComment = faComment;
  public deleteFromMembers: boolean = false;
 
  mimeTypes = {
    images: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
    imagesaudio: ['audio/mpeg', 'audio/mp3', 'audio/x-wav', 'audio/wav'],
    documents: [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
      'text/plain',
      'application/vnd.ms-excel',
      'application/vnd.ms-excel.sheet.binary.macroenabled.12',
      'application/vnd.ms-excel.sheet.macroenabled.12',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/x-iwork-keynote-sffkey',
      'application/x-iwork-pages-sffpages',
      'application/x-iwork-numbers-sffnumbers',
      'application/pgp-keys',
      'application/octet-stream',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ],
    pdf: ['application/pdf'],
    files: [
      'application/zip',
      'application/x-rar-compressed',
      'application/x-rar',
      'application/x-download',
    ],
  };

  constructor(
    private userService: UsersService,
    public auth: AuthService,
    private ngbModal: NgbModal,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
    private translate: TranslateService,
  
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.userId = params.get('id');
      this.username = params.get('user');
      this.auth.user.subscribe((user) => {
        if (user?.id != this.userId) {
          this.deleteFromMembers = true;
        } else {
          this.user = user;
        }
      });
      this.getContent();
    });
  }

 
  public getContent() {
    this.loading = true;
    this.userService.getContentProfile(this.userId).then((resp) => {
      this.loading = false;
      this.videos = resp.data.videos;
      this.files = resp.data.files;
      this.playlists = resp.data.playlists;
      this.groups = resp.data.groups;
      this.courses = resp.data.courses;
    });
  }

  getFileImg() {
    return 'assets/img/files/' + this.mimetypeId + '.png';
  }
  cancel(){
    this.location.back();
   // this.router.navigate(['/organization',this.organization,'members'])
  }
  onImgError(event: any){
    event.target.src = 'assets/img/groups/icons/list.png'
   //Do other stuff with the event.target
   }

  public deleteAccount() {
    let modalRef = this.ngbModal.open(DeleteAccountModalComponent, {
      windowClass: 'default-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.userId = this.userId;
    modalRef.componentInstance.username = this.username;
    modalRef.componentInstance.deleteFromMembers = this.deleteFromMembers;
    modalRef.componentInstance.selfModal = modalRef;
  }

  public changeOwner(collection:any,type:any){
    let self=this;
    let modalRef = this.ngbModal.open(ChangeOwnerModalComponent, {
      windowClass: 'default-modal',
      size: '800',
      centered: true,
    });
  
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.collection = collection;
    modalRef.componentInstance.userId = this.userId;
    modalRef.componentInstance.username = this.username;
    modalRef.componentInstance.deleteFromMembers = this.deleteFromMembers;
    modalRef.componentInstance.selfModal = modalRef;
    modalRef.componentInstance.userSelected.subscribe((data: any) => {
    
     if (type=='group'){
      this.groups = this.groups.filter((group:any) => group.id !== collection.id);
     }
     if (type=='playlist'){
      this.playlists = this.playlists.filter((playlist:any) => playlist.id !== collection.id);

     }
     this.toastService.showSuccess(this.translate.instant('dashboard:admin:modal:delete:users:change:ownership:success'));
     
    });
    
  }
}
