<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">
    {{ "admin:users:btn:edit" | translate }}
  </h4>
</div>
<div class="modal-body p-4">
  <div class="row mb-2">
    <div class="col-auto">
      <app-user-icon size="medium" [user]="avatar"></app-user-icon>
    </div>
    <div class="col-9">
      <div class="row">
        <div class="d-flex justify-content-between">
          <p class="mb-0 title-user">{{ user?.display_name }}</p>
        </div>
        <p class="mb-0" style="color: #302e2e">@{{ user?.username }}</p>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="modal-label" for="clip-title">{{
      "organization:members:create_account:user" | translate
    }}</label>
    <input
      [(ngModel)]="info.name"
      class="input-box w-100"
      id="clip-title"
      maxlength="80"
      name="name"
      placeholder="{{ '' | translate }}"
      required=""
      type="text"
    />
    <label class="modal-label" for="clip-title">{{
      "admin:users:info:title:1" | translate
    }}</label>
    <input
      [(ngModel)]="info.email"
      class="input-box w-100"
      id="clip-title"
      maxlength="80"
      name="name"
      placeholder="{{ '' | translate }}"
      required=""
      type="text"
    />
    <div class="row">
      <div class="col-6">
        <label class="modal-label" for="clip-title">{{
          "admin:users:modal:edit_account:label:3" | translate
        }}</label>
        <input
          [(ngModel)]="info.password"
          class="input-box w-100"
          id="clip-title"
          maxlength="80"
          name="name"
          placeholder="{{ '******' | translate }}"
          required=""
          type="text"
        />
      </div>
      <div class="col-6">
        <label class="modal-label" for="clip-title">{{
          "admin:users:modal:edit_account:label:4" | translate
        }}</label>
        <input
          [(ngModel)]="info.passwordRepeat"
          class="input-box w-100"
          id="clip-title"
          maxlength="80"
          name="name"
          placeholder="{{ '******' | translate }}"
          required=""
          type="text"
        />
      </div>
    </div>
  </div>

  <div class="form-group mt-4">
    <label class="modal-label" for="clip-title ">{{
      "admin:users:modal:edit_platforms:btn:deactivate" | translate
    }}</label>
    <p>
      {{ "users:deactivate:account:modal:description:superuser" | translate }}
    </p>
    <button
      (click)="deactivateUser()"
      type="button"
      role="tab"
      class="btn btn-danger col-auto"
    >
      {{ "admin:users:modal:edit_platforms:btn:deactivate" | translate }}
    </button>
    <label class="modal-label my-2 col-12" for="clip-title ">{{
      "admin:users:modal:edit_account:label:5" | translate
    }}</label>
    <button
      (click)="deleteUser()"
      type="button"
      role="tab"
      class="btn btn-danger col-auto"
    >
      {{ "admin:users:modal:edit_account:btn:remove" | translate }}
    </button>
  </div>
  <hr class="common-separator" />
  <div class="form-group">
    <div class="row justify-content-end mx-1">
      <button
        (click)="save()"
        type="button"
        role="tab"
        class="btn btn-primary btn-create modal-btn-width float-right ms-2"
      >
        {{ "Save" | translate }}
        <fa-icon [icon]="faCircleNorch" *ngIf="loading" [spin]="true"></fa-icon>
      </button>
    </div>
  </div>
</div>
