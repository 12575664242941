import { Component, Input, OnInit } from '@angular/core';
import { InitService } from 'src/app/services/init.service';
import { GroupsService } from 'src/app/services/groups.service';
import { Paginator } from 'src/app/interfaces/paginator';


@Component({
  selector: 'app-group-profile-activity',
  templateUrl: './group-profile-activity.component.html',
  styleUrls: ['./group-profile-activity.component.scss']
})
export class GroupPofileActivityComponent implements OnInit {
  paginator: Paginator = {
    pages: 1,
    current_page: 1,
    per_page: 12,
    total: 0
  };
	@Input() groupId: String='';
  activities:any;
  loading = false;


  constructor(
    private initService: InitService,
    private groupsService:GroupsService
  ) {
    
  }

  ngOnInit(): void {
    this.loading = true;
    this.groupsService.getActivity(this.groupId, 30, this.paginator.current_page).then( res => {
      this.paginator = res.paginator;
     // this.activities = [...this.activities, ...res.data.activities];
      this.activities = res.data.activities;
      this.loading = false;
    });
  }

}
