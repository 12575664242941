<div class="row mb-1 justify-content-center">
  <div class="col-md-9 searchInput p-0">
    <input
      #searchInput
      autofocus
      [disabled]="disabled"
      [(ngModel)]="searchTxt"
      (keyup.enter)="search()"
      type="text"
      id="search"
      class="form-control search"
      [placeholder]="inputPlaceholder | translate"
    />
    <i class="fas fa-search"></i>
  </div>
  <div class="col-md-3 btnSearchResponsive">
    <button
      [disabled]="loading"
      (click)="search()"
      class="btn btn-primary w-100 searchButton text-nowrap"
    >
    {{ searchText | translate }}
      <fa-icon
        class="mr-2"
        [icon]="faCircleNorch"
        *ngIf="loading"
        [spin]="true"
      ></fa-icon>
    </button>
  </div>
</div>
