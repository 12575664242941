<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [class]="toast.classname + ' mb-1'"
  [autohide]="true"
  [animation]="true"
  [delay]="toast.delay || 5000"
  (hide)="toastService.remove(toast)"
  [@inAnimation]
>
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
  </ng-template>

  <ng-template #text>{{ toast.textOrTpl }}</ng-template>
  <button
    (click)="toastService.remove(toast)"
    type="button"
    aria-label="Close"
    class="close"
  >
    <span aria-hidden="true"><i class="fas fa-times icon"></i></span>
  </button>
</ngb-toast>
