import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-table-dh-team',
  templateUrl: './table-dh-team.component.html',
  styleUrls: ['./table-dh-team.component.scss'],
})
export class TableDhTeamComponent implements OnInit {
  @Input() dataReport: [] = [];
  public infoTable: any = [];

  counts: Array<any> = [];

  constructor() {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.infoTable = this.dataReport;
    if (this.infoTable?.length) {
      for (let i = 0; i < this.infoTable.length; i++) {
        const data = this.infoTable[i];

        for (let col = 0; col < data.high.length; col++) {
          const val = data.high[col];

          if (!this.counts[col]) {
            this.counts[col] = [];
          }

          if (!this.counts[col][val.competence_id]) {
            this.counts[col][val.competence_id] = 0;
          }

          this.counts[col][val.competence_id]++;
        }

        if (!this.counts[4]) {
          this.counts[4] = [];
        }

        if (!this.counts[4][data.low.competence_id]) {
          this.counts[4][data.low.competence_id] = 0;
        }
        this.counts[4][data.low.competence_id]++;
      }
    }
  }
}
