import { ToastService } from 'src/app/services/toast.service';
import { PlaylistsService } from 'src/app/services/playlists.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-video-playlist',
  templateUrl: './edit-video-playlist.component.html',
  styleUrls: ['./edit-video-playlist.component.scss'],
})
export class EditVideoPlaylistComponent implements OnInit {
  /////////////
  // Atributes
  /////////////
  @Input() videoId: any;
  @Input() videoData: any;
  @Input() selfModal: NgbModalRef | null = null;

  @Output() refresh: EventEmitter<any> = new EventEmitter();

  public faTimes = faTimes;
  public faCircleNotch = faCircleNotch;
  public loading: boolean = false;

  public video = {
    name: '',
    id: 0,
    description: '',
  };

  constructor(
    private playlistService: PlaylistsService,
    private toastService: ToastService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.video.id = this.videoData.id;
    this.video.name = this.videoData.name;
    this.video.description = this.videoData.description;
  }

  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  public edit() {
    this.loading = true;

    this.playlistService
      .editVideoPlaylist(this.videoId, this.video)
      .then((resp) => {
        this.toastService.showSuccess(this.translate.instant('Video edited'));
        this.refresh.emit();
        this.closeModal();
        this.loading = false;
      })
      .catch((err: any) => {
        console.log(err);
        this.toastService.showError(
          this.translate.instant('Error editing video')
        );
        this.closeModal();
      });
  }
}
