<div class="container-fluid background-yellow px-0">
  <header>
    <div class="header-develop">
      <div class="container">
        <div class="row pt-4 align-items-center text-start">
          <p (click)="goBack()" class="back px-0 my-1 pb-0 ms-2">
            <img
              class="back-icon"
              src="/assets/img/dh-landing/back.png"
              alt=""
            />
            {{ "back:button:levels" | translate }}
          </p>
        </div>
        <div class="row pt-2 align-items-center flex-column text-center">
          <h1 class="title col-12 col-md-7 my-3">
            {{ "level4:title" | translate }}
          </h1>
        </div>
        <div class="row my-4 justify-content-center text-center">
          <p class="description col-12 col-md-8 mb-4">
            {{ "level4:subtitle" | translate }}
          </p>
        </div>

        <div class="row justify-content-center gap-5">
          <div *ngIf="loading" class="row p-5">
            <div class="justify-content-center">
              <app-loading-icon></app-loading-icon>
            </div>
          </div>

          <div class="row justify-content-center gap-5">
            <button
              *ngIf="!loading"
              class="btn btn-level4 mx-1 my-1 col-auto"
              [disabled]="loading"
              (click)="getFormLevel4()"
            >
              <span style="color: #fff">{{
                "buy:free:questionaire" | translate
              }}</span>
              <fa-icon
                class="ms-2"
                [icon]="faCircleNorch"
                *ngIf="loading"
                [spin]="true"
              ></fa-icon>
              <span style="color: #fff" *ngIf="!loading">
                {{
                  collection[0].striked_price
                    | currency : "EUR" : "symbol" : "1.0-2"
                }}</span
              >
            </button>

            <button
              *ngFor="let quest of questionnaires"
              class="btn btn-level4 mx-1 my-1 col-auto"
              (click)="acceptAndOpenQuestionnaire(quest.id)"
            >
              <span style="color: #fff">
                {{ "dh:profile:start:questionnaire" | translate }}</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="row justify-content-center px-0">
    <div class="col-11 col-md-9 card-1-level3 my-5">
      <div class="row justify-content-center">
        <div class="col-md-7 col-12">
          <p class="title-card">
            {{ "level4:card1:title" | translate }}
          </p>
          <p class="description-card">
            {{ "level4:card1:description" | translate }}
          </p>
        </div>
        <div class="col-auto">
          <img
            class="img-card"
            src="/assets/img/dh-landing/levels/level-4/img-card1.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-11 col-md-9 card-2-level3 my-3">
      <div class="row">
        <div class="col-auto d-flex align-items-center">
          <img
            class="img-card"
            [src]="
              currentLang == 'en'
                ? '/assets/img/dh-landing/levels/level-4/img-card2.png'
                : '/assets/img/dh-landing/levels/level-4/img-level4-nl.png'
            "
            alt=""
          />
        </div>
        <div class="col-md-7 col-12">
          <p class="title-card">
            {{ "level4:card2:title" | translate }}
          </p>
          <p class="description-card">
            {{ "level4:card2:description" | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center px-0">
    <div class="col-11 col-md-9 card-1-level3 my-5">
      <div class="row justify-content-between align-items-center">
        <div class="col-md-7 col-12">
          <p class="title-card">
            {{ "level4:card3:title" | translate }}
          </p>
          <p class="description-card">
            {{ "level4:card3:description" | translate }}
          </p>
          <p class="description-card">
            {{ "level4:card3:description:p1" | translate }}
          </p>
          <p class="description-card">
            {{ "level4:card3:description:p2" | translate }}
          </p>
          <p class="description-card">
            {{ "level4:card3:description:p3" | translate }}
          </p>
          <p class="description-card">
            {{ "level4:card3:description:p4" | translate }}
          </p>
          <p class="description-card">
            {{ "level4:card3:description:p5" | translate }}
          </p>
        </div>
        <div class="col-auto mx-auto">
          <img
            class="img-card"
            src="/assets/img/dh-landing/levels/level-4/img-card3.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center mt-5">
    <div class="col-11 col-md-6 card-3-level3 my-3">
      <div class="row">
        <p class="title-card text-center">
          {{ "level4:card4:title" | translate }}
        </p>
        <p class="description-card">
          {{ "level4:card4:description" | translate }}
        </p>
        <p class="description-card">
          {{ "level4:card4:description:p1" | translate }}
        </p>
        <p class="description-card">
          {{ "level4:card4:description:p2" | translate }}
        </p>
        <p class="description-card">
          {{ "level4:card4:description:p3" | translate }}
        </p>
      </div>
      <div class="row justify-content-center mt-3">
        <button (click)="getFormLevel4()" class="btn btn-level4 col-auto">
          <span style="color: #fff">{{
            "take:free:questionaire" | translate
          }}</span>
        </button>
      </div>
    </div>
  </div>
  <div class="container-footer">
    <div class="footer-container">
      <div class="row pt-5 align-items-center flex-column text-center">
        <h2 class="footer-title col-12 col-md-7 my-4">
          {{ "footer:landing" | translate }}
        </h2>
        <p class="footer-landing col-12 col-md-9 mb-5 mt-4">
          {{ "description:footer:landing" | translate }}
        </p>
        <a
          class="btn mt-5 btn-footer col-auto"
          target="__blank"
          href="https://doublehealix.com/het-double-healix-model/"
        >
          {{ "button:learn:model" | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
