<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon>
  <span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">
    {{
      (team?.hypothetical ? "invite:member:subteam" : "invite:member:team")
        | translate
    }}
  </h4>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="row tabs-row">
      <div class="col text-center">
        <app-movie-tabs-wrapper [main]="true">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active py-3"
              id="activity-tab"
              (click)="getTab('inviteNewUser')"
              type="button"
              role="tab"
            >
              {{ "Invite Users" | translate }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link py-3"
              id="conversation-tab"
              (click)="getTab('invited')"
              type="button"
              role="tab"
            >
              {{ "Invited" | translate }}
            </button>
          </li>
          <li *ngIf="team.id" class="nav-item" role="presentation">
            <button
              class="nav-link py-3"
              id="memberTab"
              (click)="getTab('members')"
              type="button"
              role="tab"
            >
              {{ "courses:modal:members:title" | translate }}
            </button>
          </li>
        </app-movie-tabs-wrapper>
      </div>
    </div>

    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="all"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <div class="container py-4">
          <div *ngIf="loading" class="row">
            <div class="row loadingCenter">
              <app-loading-icon></app-loading-icon>
            </div>
          </div>

          <div
            *ngIf="!loading"
            class="row justify-content-center list-overflow"
            [ngClass]="{
              'max-heigth': !team?.hypothetical,
              'heigth-subteam': team?.hypothetical
            }"
          >
            <div *ngIf="actualTab == 'inviteNewUser'">
              <div *ngIf="!team?.hypothetical" class="label-container">
                <label class="form-label" for="search"
                  >{{
                    "Enter the email to the users to be invited" | translate
                  }}
                  or
                  <a (click)="openCSV()" class="ng-binding"
                    >upload a CSV of users</a
                  ></label
                >

                <label class="form-label" for="search">
                  <a
                    href="../../../../assets/img/bulk_example.csv"
                    target="_blank"
                    download="bulk_example.csv"
                    class="ng-binding"
                  >
                    {{ "Download CSV template" | translate }}
                  </a>
                </label>
              </div>

              <app-users-invited
                [fromDHSubTeam]="team.hypothetical"
                [usersSelected]="selectUsers"
                (addUserFromSubTeam)="getUsersFromSubTeam($event)"
              ></app-users-invited>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6">
                  <div class="form-group">
                    <app-search-users
                      [internalUsers]="false"
                      [fromDHSubTeam]="team.hypothetical"
                      [maxUsers]="50"
                      [usersSelected]="selectUsers"
                      [usersExcluded]="usersInTeam"
                      [usersExcludedMsg]="'already in team'"
                      id="search"
                      (searchEvent)="usersSelected($event)"
                      type="text"
                      placeholder="{{
                        'play_video:video_gift:enter_name' | translate
                      }}"
                    ></app-search-users>
                  </div>
                </div>
                <div
                  *ngIf="!team.hypothetical"
                  class="col-12 col-sm-12 col-md-6"
                >
                  <div class="form-group">
                    <label class="form-label" for="name"> </label>
                    <input
                      id="name"
                      class="input-box w-100"
                      maxlength="80"
                      name="Name"
                      placeholder="{{ 'Name' | translate }}"
                      required=""
                      type="text"
                      [disabled]="true"
                    />
                  </div>
                </div>
                <div
                  *ngIf="team.hypothetical"
                  class="col-12 col-sm-12 col-md-6"
                >
                  <div ngbDropdown class="d-inline-block mt-1 button-select">
                    <button
                      disabled
                      type="button"
                      class="btn btn-outline-primary"
                      id="dropdownBasic1"
                      ngbDropdownToggle
                    >
                      {{ "Select dhProfile" }}
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1"></div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-2">
                  <select
                    class="form-select"
                    aria-label="Language"
                    [(ngModel)]="language"
                  >
                    <option selected value="en">
                      {{ "English" | translate }}
                    </option>
                    <option value="nl">
                      {{ "Dutch" | translate }}
                    </option>
                  </select>
                </div>
              </div>
              <div *ngIf="!team.hypothetical" class="row mt-5">
                <div class="form-group">
                  <label class="form-label" for="textarea">{{
                    "Write a message for the invitation" | translate
                  }}</label>
                  <textarea
                    [(ngModel)]="textMessage"
                    class="form-control"
                    id="textarea"
                    rows="3"
                  ></textarea>
                </div>
              </div>
            </div>

            <div *ngIf="actualTab == 'invited'">
              <app-empty-content
                *ngIf="!usersInvited?.length && !loading"
                [primaryText]="'collection:members:invited:empty' | translate"
              ></app-empty-content>
              <app-members-list
                *ngIf="usersInvited.length"
                [members]="usersInvited"
                [groupId]="team.id"
                [headers]="headers"
                [checkbox]="false"
                [actions]="true"
                [customBorder]="true"
              ></app-members-list>
            </div>
            <div *ngIf="actualTab == 'members'">
              <app-empty-content
                *ngIf="!membersEnrolled?.length && !loading"
                [primaryText]="'Empty' | translate"
              ></app-empty-content>
              <app-members-list
                *ngIf="membersEnrolled.length"
                [members]="membersEnrolled"
                [groupId]="team.id"
                [headers]="headers"
                [checkbox]="false"
                [actions]="true"
                [customBorder]="true"
              ></app-members-list>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group clearfix" *ngIf="actualTab == 'inviteNewUser'">
      <button
        (click)="sendInvitation()"
        [disabled]="!selectUsers.length"
        type="button"
        role="tab"
        class="btn btn-primary btn-create modal-btn-width float-right ms-2 fs-15 text-nowrap"
      >
        <fa-icon
          class="mr-2"
          [icon]="faCircleNorch"
          *ngIf="loadSpin"
          [spin]="true"
        ></fa-icon>
        {{
          team?.hypothetical
            ? "Add profile(s)"
            : ("Send Invitations" | translate)
        }}
      </button>
    </div>
  </div>
</div>
