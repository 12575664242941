<input #input type="text" class="form-control" [ngModel]="search" (ngModelChange)='changed($event)'
      [placeholder]="'video:edit:modal:source_type:film:placeholder' | translate"/>
<i *ngIf="searching" class="fa fa-circle-notch fa-spin text-primary"></i>
<div class="imdb-results" *ngIf="showResults && search.length">
    <a class="searchbar-result searchbar-result-movie d-flex align-items-center p-2" *ngFor="let movie of results" (click)="selectMovie(movie)">
        <div class="icon me-2" ng-if="movie.poster" [style.background-image]="'url(' + movie.poster + ')'"></div>
        <h2 class="h6 mb-0 text-dark">{{ movie.title }} ({{ movie.year }})</h2>
    </a>
    <div class="searchbar-result p-2" *ngIf="!searching && results.length > 0">
        <a class="fs-14" (click)="notFoundClick()">
          {{ 'video:edit:modal:add:new:1' | translate }} '{{ search }}' {{ 'video:edit:modal:add:new:2' | translate }}
        </a>
    </div>
    <div class="searchbar-result p-2" *ngIf="!searching && results.length <= 0">
        <h2 class="h6">
          {{ "video:edit:modal:empty:1" | translate }} <span *ngIf="type === 'movie'">{{ "video:edit:modal:add:as:movie" | translate }}</span>
          <span *ngIf="type === 'series'">{{ "video:edit:modal:add:as:tv_show" | translate }}</span> "{{ search }}"
        </h2>
        <a class="fs-14" (click)="notFoundClick()">
          {{ 'video:edit:modal:add:new:1' | translate }} '{{ search }}' {{ 'video:edit:modal:add:new:2' | translate }}
        </a>
    </div>
</div>
