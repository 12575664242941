<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">
    {{ title | translate }}
  </h4>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-6">
      <label for="clip-title" class="d-block mt-3 modal-label">{{
        "admin:users:modal:discountProfile:label:2" | translate
      }}</label>
      <input
        [disabled]="quantity <= 0"
        [(ngModel)]="infoDiscount.code"
        class="input-number form-control"
        id="clip-title"
        maxlength="80"
        name="name"
        (keypress)="evitarGuion($event)"
        placeholder="{{
          'admin:users:modal:discountProfile:label:2:placeholder' | translate
        }}"
        required=""
        type="text"
      />
    </div>
    <!-- <div class="col-4">
      <label for="clip-title" class="d-block mt-3 modal-label">{{
        "Quantity of codes" | translate
      }}</label>
      <input
        [(ngModel)]="quantity"
        class="input-number form-control"
        (change)="reset()"
        type="number"
        min="1"
        max="1000"
        placeholder="1"
      />
    </div> -->
    <div class="col-4 d-flex align-items-end">
      <button
        *ngIf="quantity > 1"
        [disabled]="infoDiscount.code == ''"
        (click)="generateCodes()"
        type="button"
        class="btn btn-primary fs-15 col-auto size-btn"
      >
        {{ "Generate" | translate }}
      </button>
    </div>
  </div>

  <div class="row" *ngIf="quantity > 1">
    <div class="col-12">
      <label for="clip-title" class="d-block mt-3 modal-label">{{
        "Codes that will be generated" | translate
      }}</label>
      <p>
        {{ codes }}
      </p>
    </div>
  </div>
  <div class="form-group d-flex mt-3">
    <div class="col-6">
      <label for="clip-title" class="modal-label">{{
        "admin:users:modal:discountProfile:label:3" | translate
      }}</label>
      <input
        [disabled]="firstCheck == true"
        [(ngModel)]="infoDiscount.total_available"
        class="input-number form-control"
        type="number"
        min="0"
        max="1000"
        placeholder="0"
        (keypress)="evitarGuion($event)"
      />
      <span *ngIf="timesError" class="modal-label" style="color: red">{{
        "Invalid data"
      }}</span>
    </div>
    <div class="col-6 align-check">
      <label>
        <input
          [(ngModel)]="firstCheck"
          [ngModelOptions]="{ standalone: true }"
          (change)="changeInfinite()"
          type="checkbox"
          class="form-check-input offset-1"
          id="infinite"
        />
      </label>
      <p class="mx-2 fw-bold mt-2">
        {{ "admin:users:modal:discountProfile:check:1" | translate }}
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <label for="clip-title" class="d-block mt-3 modal-label">{{
        "admin:users:modal:discountProfile:label:8" | translate
      }}</label>
      <select
        [(ngModel)]="infoDiscount.type"
        class="pure-input"
        (ngModelChange)="changeType()"
      >
        <option value="percentage" selected="selected">
          {{ "Percentage" | translate }}
        </option>
        <option value="fixed">
          {{ "Fixed" | translate }}
        </option>
      </select>
    </div>
    <div class="col-4">
      <label for="clip-title" class="d-block mt-3 modal-label">{{
        "admin:users:modal:discountProfile:label:4" | translate
      }}</label>
      <input
        [(ngModel)]="infoDiscount.amount"
        class="input-number form-control"
        type="number"
        min="0"
        max="100"
        placeholder=""
        (keypress)="evitarGuion($event)"
        (keyup)="validInput($event)"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <label for="clip-title" class="d-block mt-3 modal-label">{{
        "Course" | translate
      }}</label>
      <div *ngIf="courseSelected != ''" class="col-auto">
        <p class="input-box set-color d-flex justify-content-between">
          <span>
            {{ courseSelected?.name | excerpt : 25 }}
          </span>
          <fa-icon
            (click)="unselect()"
            class="icon me-1"
            [icon]="faTimes"
          ></fa-icon>
        </p>
      </div>
    </div>
    <div *ngIf="courseSelected == ''" class="col-12">
      <app-search-courses
        (searchEvent)="getCourse($event)"
        [placeholder]="'courses:text:search:placeholder' | translate"
      ></app-search-courses>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <label for="clip-title" class="d-block mt-3 modal-label">{{
        "admin:users:modal:discountProfile:label:6" | translate
      }}</label>
      <input
        [(ngModel)]="infoDiscount.valid_from"
        class="input-number form-control"
        type="date"
        placeholder="DD-MM-YYYY"
      />
    </div>
    <div class="col-4">
      <label for="clip-title" class="d-block mt-3 modal-label">{{
        "admin:users:modal:discountProfile:label:7" | translate
      }}</label>
      <input
        [disabled]="secondCheck == true"
        [(ngModel)]="infoDiscount.valid_until"
        class="input-number form-control"
        type="date"
        placeholder="DD-MM-YYYY"
        [min]="infoDiscount.valid_from"
      />
    </div>
    <div class="col-4 align-check-2">
      <label class="">
        <input
          [(ngModel)]="secondCheck"
          [ngModelOptions]="{ standalone: true }"
          (change)="changeExpires()"
          type="checkbox"
          class="form-check-input offset-1"
          id="infinite"
        />
      </label>
      <p class="mx-2 fw-bold mt-2">
        {{ "admin:users:modal:discountProfile:check:2" | translate }}
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <div class="form-group clearfix">
      <button
        [disabled]="
          infoDiscount.code == '' ||
          courseSelected == '' ||
          infoDiscount.valid_from == '' ||
          infoDiscount.valid_from == 'Invalid date' ||
          !infoDiscount.valid_from ||
          infoDiscount.type == '' ||
          infoDiscount.amount <= 0 ||
          infoDiscount.amount == null
        "
        (click)="updateDiscount()"
        type="button"
        role="tab"
        class="btn btn-primary float-right ms-2 fs-15"
      >
        <fa-icon
          class="mr-2"
          [icon]="faCircleNorch"
          *ngIf="loadSpin"
          [spin]="true"
        ></fa-icon>
        {{ btnAccept | translate }}
      </button>
    </div>
  </div>
</div>
