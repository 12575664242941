<div class="confirm-email py-3">
  <div class="container d-md-flex align-items-center">
    <div class="col-md-6 col-12 mx-2">
      <p class="text-white fw-bold my-auto">
        {{ "confirm:email:text" | translate : { email: auxEmail } }}
      </p>
    </div>
    <div class="col-md-2 mx-2 text-md-end col-12 my-md-0 my-2">
      <a (click)="resendEmail()" class="btn btn-primary">{{
        "confirm:email:resend:email" | translate
      }}</a>
    </div>
    <div class="col-md-3 mx-2 col-12 my-md-0 my-2">
      <a
        [routerLink]="['/profile']"
        [queryParams]="{ section: 'settings' }"
        class="btn btn-primary"
        >{{ "confirm:email:change:email" | translate }}</a
      >
    </div>
  </div>
</div>
