import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
})
export class CreateAccountComponent implements OnInit {
  faTimes = faTimes;
  @Input() selfModal: NgbModalRef | null = null;
  @Input() title: String = '';
  @Input() organizationId: any;
  @Output() refresh = new EventEmitter<any>();
  public passwordRepeat: string = '';
  loadSpin: boolean = false;
  faCircleNorch: any = faCircleNotch;
  infoUser = {
    name: '',
    email: '',
    password: '',
    type: 'user',
  };

  constructor(
    private organizationService: OrganizationsService,
    private toastService: ToastService,
    private translate: TranslateService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {}

  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
  public create() {
    if (this.passwordRepeat != this.infoUser.password) {
      this.toastService.showError(
        this.translate.instant(
          'organization:members:edit_account:password_repeat:error'
        )
      );
      return;
    }
    this.loadSpin = true;
    this.organizationService
      .createAccount(this.organizationId, this.infoUser)
      .then((resp) => {
        if (resp.result == 'success') {
          this.toastService.showSuccess(
            this.translate.instant('Member created')
          );
          this.loadSpin = false;
          this.refresh.emit();
          this.closeModal();
        } else {
          this.loadSpin = false;
          this.toastService.showError(
            this.translate.instant(resp.errors.message)
          );
          this.closeModal();
        }
      })
      .catch((err) => {
        this.loadSpin = false;
        this.toastService.showError(
          this.translate.instant('error:create:member')
        );
      });
  }
  isAdmin(): boolean {
    return this.auth.hasAnyRole(['super_user']);
  }
}
