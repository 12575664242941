import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { JoyrideService } from 'ngx-joyride';
import { MainComponent } from 'src/app/modals/login/main/main.component';
import { AuthService } from 'src/app/services/auth.service';
import { DhprofileService } from 'src/app/services/dhprofile.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-level3',
  templateUrl: './level3.component.html',
  styleUrls: ['./level3.component.scss'],
})
export class Level3Component implements OnInit, AfterViewInit {
  public currentLang: string | null = 'en';
  public titleDes: string = 'Testing title';
  public textDesc: string =
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum';
  constructor(
    private router: Router,
    private dhProfileService: DhprofileService,
    private readonly joyrideService: JoyrideService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private auth: AuthService,
    private modalService: NgbModal,
    private lang: LangService,
    private route: ActivatedRoute
  ) {
    this.lang.language.subscribe((lang) => {
      this.currentLang = lang || 'en';
    });

    this.route.url.subscribe(() => {
      if (this.router.url === '/doublehealixprofile/competencies') {
        this.lang.setLanguage('en')
        this.currentLang = 'en';
     }
      if (this.router.url === '/doublehealixprofiel/competenties') {
        this.lang.setLanguage('nl')
        this.currentLang = 'nl';
     }
    });
  
  }
  checkCurrentRoute() {
    // Verifica si la URL actual es /dh/level-3 o /dh/level-4
    if (this.router.url === '/dh/level-3') {
      let language : any = '';
      language = localStorage.getItem('language') || this.currentLang;
      // Redirige a la ruta deseada
      if ( language == 'nl') {
        this.router.navigate(['/doublehealixprofiel/competenties']);
        this.lang.setLanguage('nl');
      }else{
        this.router.navigate(['/doublehealixprofile/competencies']);
        this.lang.setLanguage('en');
      }
    }
  }
  ngAfterViewInit(): void {
    this.checkCurrentRoute();
  }

  ngOnInit(): void {
   
  }
  goBack() {
    this.router.navigate(['/dh/landing']);
  }
  onNext() {
    // Do something
  }
  async getFormLevel3() {
    if (!this.auth.isAuthenticated()) {
      this.login();
    }
    else {

      let data = {
        type: 'quest_level_3',
      user_id: 2222,
    };
    console.log('test', data.type);

    await this.dhProfileService
      .createQuestionnaire(data)
      .then((resp) => {
        console.log(resp);
        this.router.navigate(['/dh', 'questionnaire', resp.id]);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }

  login(): void {
    let modalRef = this.modalService.open(MainComponent, { windowClass: 'login-modal' });
    modalRef.componentInstance.state = 'register';
    modalRef.componentInstance.selfModal = modalRef;
    modalRef.componentInstance.showMessage = true;

  }
}
