<div (click)="select()" class="thumbnail-item-content clearfix">
  <div class="row">
    <div class="col-md-3 col-sm-3">
      <div
        class="pull-left the-thumbnail"
        [ngStyle]="{ background: 'url(' + video?.thumb_url + ')' }"
      ></div>
      <div class="clock-icon">
        <fa-icon
          class="icon"
          [icon]="faClock"
          [styles]="{ color: 'white' }"
        ></fa-icon>
        <i class="fa fa-clock-o"></i>
        {{ this.video?.source?.length || 0 | timeLength }}
      </div>
    </div>
    <div class="col-md-8 col-sm-8">
      <div class="row justify-content-between">
        <h3 class="card-title mb-0 col-auto" [ngClass]="{ 'mb-3': featured }">
          {{ title }}
        </h3>
        <p
          style="position: relative; left: 65px"
          class="col-auto appearances"
          *ngIf="video.appearances"
        >
          {{
            (video.appearances == 1 ? "Added:times:video" : "Added:times:videos")
              | translate : { appearances: video.appearances }
          }}
        </p>
      </div>
      <p class="card-text mb-0 card-from" *ngIf="!featured">
        <small *ngIf="video?.source?.origin" class="text-muted">{{
          "courseviewer:lesson:from" | translate
        }}</small>
        <small class="text-muted font-purple">
          {{ video?.source?.origin?.title }}</small
        >
      </p>
      <p
        class="card-text short-description"
        *ngIf="!featured"
        [innerHTML]="(description | slice : 0 : 150) + '...'"
      ></p>

      <div class="d-flex justify-content-start align-items-center">
        <small *ngIf="video?.ratings?.starsAvg" class="me-3 text-muted">
          <fa-icon [icon]="faStar" [styles]="{ color: '#eeee00' }"></fa-icon>
          <span class="ms-1">{{ video?.ratings.starsAvg }}/5 |</span>
        </small>
        <small *ngIf="video?.user?.display_name" class="me-3 text-muted d-flex">
          <span
            ><app-user-icon
              [class]="'small-avatar-group'"
              [user]="video.user"
            ></app-user-icon
          ></span>
          <span class="ms-1">{{'author:by' | translate}} {{ video?.user?.display_name }}</span>
        </small>
      </div>
    </div>

    <div class="col-md-1 col-sm-1">
      <div *ngIf="video.selected" class="text-center icon-selected">
        <i class="fas fa-plus-circle"></i>
      </div>
      <div *ngIf="!video.selected" class="text-center icon-check">
        <i class="fas fa-plus-circle"></i>
      </div>
    </div>
  </div>
</div>
