import { Component, Input, OnInit } from '@angular/core';
import { InitService } from 'src/app/services/init.service';
import { GroupsService } from 'src/app/services/groups.service';
import { LangService } from 'src/app/services/lang.service';
import { faClock, faPlayCircle  } from '@fortawesome/free-regular-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-group-activity-item-playlist',
  templateUrl: './group-activity-item-playlist.component.html',
  styleUrls: ['./group-activity-item-playlist.component.scss']
})
export class GroupActivityItemPlayListComponent implements OnInit {
 
	@Input() activities: any;

	comments: any;
  activity:any;
  loading = false;
  title = '';
  description = '';
  language: String = 'en';
  faClock = faClock;
  faPlayCircle=faPlayCircle;
  imageUrl=''

  constructor(private router: Router) {  }

  getDescription(){
    this.description=this.activity.description;
    if (this.description&&this.description.length>300){
           this.description=this.description.slice(0,300) + '...';
    }
  }
  ngOnInit(): void {
    this.activity=this.activities.entity;
    this.comments=this.activities.comment;
    this.imageUrl=this.activity.thumbnail?this.activity.thumbnail:'assets/img/groups/icons/list.png'
    this.getDescription();
   

    
  }
 
  goToPlaylist(playlistId: any) {
    // this.router.navigate(['/playlists', playlistId]);
    window.open(this.router.createUrlTree(['/playlists', playlistId]).toString(), '_blank');
  }
}
