<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title">
    {{ "courses:status:leave" | translate }}
  </h4>
</div>
<div class="modal-body flex-column">
  <div class="row">
    <p class="description">{{ "courses:leave:modal:text" | translate }}</p>
  </div>
</div>
<div class="modal-footer">
  <div class="d-flex justify-content-end text-center">
    <button
      type="button"
      class="btn btn-outline-primary ms-2 fs-15 text-nowrap"
      (click)="closeModal()"
    >
      {{ "Cancel" | translate }}
    </button>
    <button
      type="button"
      role="tab"
      class="btn btn-primary ms-2"
      (click)="unenrollCourse()"
    >
      <fa-icon
        class="mr-2"
        [icon]="faCircleNorch"
        *ngIf="loadSpin"
        [spin]="true"
      ></fa-icon>
      {{ "collection:button:leave" | translate }}
    </button>
  </div>
</div>
