<div class="block" [ngClass]="{ 'thumbnail-item-content': type == 'activity' }">
  <div class="row file" (click)="leaveCommentaryModal(file.id)">
    <!-- <div class="file-list-icon pull-left ng-isolate-scope" style="background-image: url('assets/img/files/3.png')"
        file="file"></div> -->
    <div class="col-md-1">
      <img class="user-avatar rounded-list-icon" [src]="getFileImg()" />
    </div>
    <div class="col-md-11">
      <div class="title">{{ file.title }}</div>
      <div class="the-creator">
        <span
          class="by-whom ng-binding"
          style="color: #999999; font-weight: 500; font-size: 14px"
          >{{'author:by' | translate}}
        </span>
        <span
          class="the-author ng-binding"
          style="color: #a251bd; font-weight: 500; font-size: 14px"
          >{{ file.user.display_name }}</span
        >
        <span style="color: #999999; font-weight: 500; font-size: 14px">
          ·
        </span>
        <span
          class="the-time"
          style="color: #999999; font-weight: 500; font-size: 14px"
          >{{ file.created_at | amTimeAgo }}</span
        >
      </div>
      <div class="description p-margin" [innerHtml]="file.description"></div>
      <div class="comment-count" *ngIf="type != 'activity'">
        <fa-icon class="commentIcon" [icon]="faComment"></fa-icon>
        <span
          class="fa fa-comment-o"
          style="color: #cdcdcd; font-size: 18px"
        ></span>
        <span class="comment-ammount">{{
          "groups:profile:files:responses:" +
            (file.count_comments == 1 ? "singular" : "plural")
            | translate : { count: file.count_comments }
        }}</span>
      </div>
    </div>

    <div class="file-download-name-size mt-1" *ngIf="type == 'activity'">
      <img
        class="user-avatar rounded-list-icon file-list-icon"
        [src]="getFileImg()"
      />

      <div class="d-block file-download-title">
        <h4 class="item-title">{{ file.originalName }}</h4>
        <p>{{ file.sizeFriendly }}</p>
      </div>

      <div class="file-item-controls">
        <a href="{{ file.pathUrl }}" class="button button-download">{{
          "files:download:button" | translate
        }}</a>
      </div>
      <!-- ngIf: file.mimetype == 'audio/mpeg' || file.mimetype == 'audio/mp3' || file.mimetype == 'audio/x-wav' || file.mimetype == 'audio/wav' -->
    </div>
  </div>
  <div
    *ngIf="isFromGroup && userCanEdit(file)"
    ngbDropdown
    class="btn-toggle text-primary border rounded"
    placement="bottom-right"
  >
    <button type="button" class="btn" id="dropdownBasic1" ngbDropdownToggle>
      <i class="fa fa-ellipsis-h"></i>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="mt-1">
      <button (click)="deleteItem()" class="text-danger" ngbDropdownItem>
        {{ "groups:profile:videos:delete:modal:button" | translate }}
      </button>
    </div>
  </div>
</div>
<div class="comments" *ngIf="type == 'activity' && tap != 'activity'">
  <app-comments-container
    type="activity_group"
    [activity]="activities"
    placeholder="{{ 'comments:placeholder' | translate }}"
    [title]="false"
  ></app-comments-container>
</div>
