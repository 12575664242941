import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Params} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {ToastService} from "../../services/toast.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-surf',
  templateUrl: './surf.component.html',
  styleUrls: ['./surf.component.scss']
})
export class SurfComponent implements OnInit {
  @Output('success') success = new EventEmitter();
  loading = false;
  public errors='';
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastService: ToastService,
    private translate: TranslateService,
  ) {
  }
  //http://courses.movielearning.test:4200/auth/surf?email=dagtmp1312@student.hhs.nl&name=H.%20Schouten&ssoId=1d453c12e4dad5bba66eb5dd1b031cdc0fed0fc6&shouldRegister=0&accessToken=1b8337f8169db8d0f2e49da3bdcdc119a07f0d7f&hash=df32f8b91226917a52a40153d6bc9b23
  ngOnInit(): void {
 //   console.log('init')
    const queryParams = this.route.snapshot.queryParams;
  //  console.log('queryParams',queryParams)
    
    if (queryParams && queryParams['email'] && queryParams['name'] && queryParams['ssoId']) {
      console.log('queryParams', queryParams);
      console.log('shouldRegister', queryParams['shouldRegister'] === '1')
      this.loading = true;
      if (queryParams['shouldRegister'] === '1') {
    //    console.log('registering...');
        this.registerSsoUser(queryParams);
      } else {
    //    console.log('loggin!!...');

        this.loginSsoUser(queryParams);
      }
    //  console.log('fini init!!...');

    }
  }

  private loginSsoUser(queryParams: Params) {
    this.authService.logInWithSso(queryParams['accessToken'], queryParams['refreshToken'], null)
      .then((res) => {
        console.log('login success', res);
        if (typeof res == 'undefined'){
          console.log('Res no definido')
        }
        this.loading = false;
      
        this.success.emit();
      })
      .catch((err) => {
        // if (err.error!.message!.type == 'TooManyAttempts') {
        //   this.blockIp();
        // }
        
        if (err.error.message?.friendly) {
          this.errors=err.error.message.friendly.message;
          if (err.error.message.friendly.message) {
            this.toastService.showError(
              this.translate.instant(err.error.message.friendly.message)
            );
          } else {
            this.errors=err.error.message.friendly;

            this.toastService.showError(
              this.translate.instant(err.error.message.friendly)
            );
          }
        } else {
          this.errors='Login error code=3';
          this.toastService.showError(
            this.translate.instant('login:member:error')
          );
        }
        this.loading = false;
      });
  }

  private registerSsoUser(queryParams: Params) {
    this.authService.register(queryParams['email'], queryParams['name'], queryParams['ssoId'], queryParams['hash'])
      .then((res) => {
        console.log('register success', res);
        this.authService
          .logIn(queryParams['email'], queryParams['ssoId'], true)
          .then((res) => {
            this.loading = false;
            this.success.emit();
          })
          .catch((err) => {
            if (err.error.message?.friendly) {
              this.errors=err.error.message.friendly;
              this.toastService.showError(err.error.message.friendly);
            } else {
              this.toastService.showError('Login error');
              this.errors='Login error code=1'
            }
            this.loading = false;
          });
      })
      .catch((err) => {
        
        if (err.error.message?.friendly) {
          this.errors=err.error.message.friendly;
          this.toastService.showError(err.error.message.friendly);
        } else {
          this.errors='Login error code=2'
          this.toastService.showError(
            this.translate.instant('sign_up:error')

          );
        }
        this.loading = false;
      });
  }
}
