import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { InvoiceDetails, Order } from 'src/app/interfaces/order';
import { AuthService } from 'src/app/services/auth.service';
import { OrdersService } from 'src/app/services/orders.service';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {
  @Input() selfModal: NgbModalRef | undefined;
  @Input() orderId: number | undefined;
  @Input() questionnaireId: number | undefined;
  @Input() success: boolean | undefined;
  @Input() status: string | undefined;

  order: Order | undefined;
  invoiceDetails: InvoiceDetails | undefined;

  loading = true;

  constructor(
    private ordersService: OrdersService,
    private auth: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    if (this.orderId) {
      this.ordersService.get(this.orderId).then( order => {
        this.order = order;

        if (Array.isArray(order.invoice_details)) {
          this.invoiceDetails = order.invoice_details[0];
        } else {
          this.invoiceDetails = order.invoice_details
        }
        
        this.loading = false;
      } );
    }
  }


  getSubscriptionEnds() {
    var format = 'MMMM Do YYYY';
    if (this.auth.getMe() && this.auth.getMe()!.language == 'nl') {
        format = 'D MMMM YYYY';
    }

    return moment().add(this.order?.entries[0].subscription_days, 'days').format(format);
  };

  getSubscriptionStart() {
    var format = 'MMMM Do YYYY';
    if (this.auth.getMe() && this.auth.getMe()!.language == 'nl') {
        format = 'D MMMM YYYY';
    }
    return moment(this.order!.created_at).format(format);
  };

  closeModal(){
    this.selfModal?.close();
  }

  goToQuest() {
    this.router.navigate(['/dh', 'questionnaire', this.questionnaireId]);
    this.selfModal?.close();

    
  }
}
