import { map } from 'rxjs/operators';
import { ToastService } from 'src/app/services/toast.service';
import { PlaylistsService } from 'src/app/services/playlists.service';
import { EditVideoPlaylistComponent } from './../../modals/playlist/edit-video-playlist/edit-video-playlist.component';
import { VideoUtilitiesService } from 'src/app/utilities/video-utilities.service';
import { AddVideoComponent } from './../../modals/group/add-video/add-video.component';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  faCircleNotch,
  faClock,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DeleteVideoComponent } from 'src/app/modals/playlist/delete-video/delete-video.component';
import { DndDropEvent } from 'ngx-drag-drop';
import { AuthService } from 'src/app/services/auth.service';
import { Observable } from 'rxjs';
import { fromEvent } from 'rxjs';
import { VideoComponent } from 'src/app/modals/video/video.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-curriculum-draggable',
  templateUrl: './curriculum-draggable.component.html',
  styleUrls: ['./curriculum-draggable.component.scss'],
})
export class CurriculumDraggableComponent implements OnInit {
  @Input() activities: any;
  @Input() activity: any;
  @Input() type: string = '';
  @Input() entities?: any;
  @Input() playlistId?: any = '';
  @Input() playlist?: any;
  @Output() refreshList: EventEmitter<any> = new EventEmitter();
  @Output() hideMenu: EventEmitter<any> = new EventEmitter();
  public editing: any = [];
  faPlus = faPlus;
  faCircleNorch: any = faCircleNotch;
  loading = false;
  loadingSpin = false;
  title = '';
  description = '';
  language: String = 'en';
  faClock = faClock;
  public positions: any = [];
  //--------For quill---------//
  public lastDateEdition: string = '';
  public modulesQuill = {};
  public boxStyle = {};
  public hideOnDrag: boolean = false;
  ////////////////
  //Drag and drop
  ///////////////
  positionYMouse: any = 0;
  draggable = {
    // note that data is handled with JSON.stringify/JSON.parse
    // only set simple data or POJO's as methods will be lost
    data: 'myDragData',
    effectAllowed: 'all',
    disable: false,
    handle: false,
  };
  constructor(
    private playlistService: PlaylistsService,
    private ngbModal: NgbModal,
    private toastService: ToastService,
    private translate: TranslateService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private videoUtils: VideoUtilitiesService
  ) {
    this.boxStyle = { height: '100px', width: '100%', border: 'none' };

    this.modulesQuill = {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          ['blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],
          [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
        ],
      },
    };
  }
  getVideoDataInCorrectLang(video: any) {
    let info = this.videoUtils.getInfoByLang(video);
    return info;
  }
  ngOnInit(): void {
    // if (this.entities) {
    //   for (let i = 0; i < this.entities.length; i++) {
    //     this.editing.push({
    //       edit: false,
    //       title: this.entities[i].name || '',
    //       description: this.entities[i].description || '',
    //       description_alt: this.entities[i].description_alt || '',
    //       id: this.entities[i].id || '',
    //     });
    //   }
    // }
    if (this.route.snapshot.queryParamMap.get('video_id')) {
      this.openVideoModal(this.route.snapshot.queryParamMap.get('video_id'));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.entities.forEach((elem: any) => {
      elem.hide = false;
    });
    if (this.entities) {
      this.editing = [];
      for (let i = 0; i < this.entities.length; i++) {
        this.editing.push({
          edit: false,
          title: this.entities[i].name || '',
          description: this.entities[i].description || '',
          description_alt: this.entities[i].description_alt || '',
          id: this.entities[i].id || '',
        });
      }
    }
  }
  public getTitle() {
    this.title = this.activity?.info[0]?.name; //by default.
    var info = this.activity?.info;
    if (!info) {
      this.title = '';
      return;
    }

    for (let i of info) {
      if (i.language == this.language) {
        this.description = i.description;
        this.title = i.name;
      }
    }
    if (!this.title) {
      this.title = '';
    }
    if (!this.description) {
      this.description = '';
    }
  }

  public addVideoModal(index: number) {
    console.log('entities', this.entities);

    let modalRef = this.ngbModal.open(AddVideoComponent, {
      windowClass: 'default-modal video-modal',
      size: '1000',
      centered: true,
    });
    modalRef.componentInstance.title = 'Add a video';
    modalRef.componentInstance.playlist = this.playlist;
    modalRef.componentInstance.group = this.playlist;
    modalRef.componentInstance.entities = this.entities;
    modalRef.componentInstance.addOnPlaylist = true;
    modalRef.componentInstance.refresh.subscribe((data: any) => {
      // let entity = {
      //   id: data[0].id,
      // };
      const entity = data.map((item: any) => ({
        id: item.id,
      }));

      this.positions = this.entities.map((item: any) => {
        return { ...item };
      });
      this.positions.splice(index + 1, 0, ...entity);
    });
    modalRef.componentInstance.close.subscribe((data: any) => {
      this.updatePosition();
    });
  }

  public updatePosition() {
    let position;
    position = this.positions.map((item: any, index: number) => {
      return { id: item.id, position: index + 1 };
    });
    this.playlistService
      .updatePosition(this.playlist.id, position)
      .then((resp) => {
        this.refreshList.emit();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public editVideo(entity: any, index: number) {
    this.editing[index].edit = true;

    // let modalRef = this.ngbModal.open(EditVideoPlaylistComponent, {
    //   windowClass: 'default-modal video-modal',
    //   size: '600',
    //   centered: true,
    // });
    // modalRef.componentInstance.videoData = entity;
    // modalRef.componentInstance.videoId = entity.collection_id;
    // modalRef.componentInstance.refresh.subscribe((data: any) => {
    //   this.refreshList.emit();
    // });
  }

  public deleteVideo(entity: any) {
    let modalRef = this.ngbModal.open(DeleteVideoComponent, {
      windowClass: 'default-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.refreshDelete.subscribe((data: any) => {
      this.playlistService
        .deleteVideoPlaylist(entity.collection_id, entity.id)
        .then((resp: any) => {
          if (resp.result == 'success') {
            this.toastService.showSuccess(
              this.translate.instant('video:removed:playlist')
            );
            this.refreshList.emit();
          } else {
            this.toastService.showError(
              this.translate.instant('video:removed:playlist')
            );
          }
        })
        .catch((err: any) => {
          console.log(err);
          this.toastService.showError('Error deleting video');
        });
    });
  }

  public edit(videoData: any, index: number) {
    this.loadingSpin = true;
    let infoVideo = {
      id: 0,
      name: '',
      description: '',
      description_alt: '',
    };
    infoVideo.id = videoData.id;
    infoVideo.name = this.editing[index].title;
    infoVideo.description = this.editing[index].description;
    infoVideo.description_alt = this.editing[index].description_alt;

    this.playlistService
      .editVideoPlaylist(videoData.collection_id, infoVideo)
      .then((resp) => {
        if (resp.result == 'success') {
          this.toastService.showSuccess(this.translate.instant('Video edited'));
          this.loadingSpin = false;
          this.entities[index].name = this.editing[index].title;
          this.entities[index].description = this.editing[index].description;
          this.entities[index].description_alt =
            this.editing[index].description_alt;
          this.cancel(index);
        } else {
          this.toastService.showError('Error editing video');
        }
      })
      .catch((err: any) => {
        console.log(err);
        this.toastService.showError(
          this.translate.instant('Error editing video')
        );
      });
  }
  public cancel(index: number) {
    this.editing[index].edit = false;
  }

  ////////////////
  //drag and drop
  ///////////////
  public onDrop(event: DndDropEvent) {
    let currentElement = event.data;
    let to: any = event.index;

    if (typeof to === 'undefined') {
      to = this.entities.length;
    }
    let from = this.entities.findIndex(
      (elem: any) => elem.id == currentElement.id
    );

    if (to != from + 1) {
      if (to > from + 1) {
        to -= 1;
      }

      let el = this.entities.splice(from, 1)[0];
      this.entities.splice(to!, 0, el);
    }
    this.orderChapters();
  }
  orderChapters() {
    let params: Array<any> = [];

    this.entities.forEach((c: any, i: number) => {
      params.push({
        id: c.id,
        position: i + 1,
      });
    });

    this.playlistService
      .orderPlaylist(this.playlist.id, params)
      .then((data) => {
        this.toastService.showSuccess('Playlists order saved successfully.');
      })
      .catch((err) => {
        this.toastService.showError('Error editing playlist');
      });
  }
  onDragStart(event: any, i: number) {
    this.entities[i].hide = true;
    this.auth.emitChange(true);
  }

  onDragEnd(event: any, i: number) {
    this.entities[i].hide = false;
    this.auth.emitChange(false);
  }
  public changeOrder() {
    this.hideOnDrag = !this.hideOnDrag;
  }
  async dragMove(eventObj: any) {
    // scrolls screen when dragging an element up or down
    // fromEvent(document.body, 'mousemove').subscribe((e:any) => {
    // this.positionYMouse = e.pageY
    //   // console.log('se ejecuta el moove y esta es la position ', this.positionYMouse)
    // });
  }

  openVideoModal(id: any) {
    let modalRef = this.ngbModal.open(VideoComponent, {
      windowClass: 'default-modal video-modal',
      size: 'lg',
    });
    modalRef.componentInstance.videoId = id;
    modalRef.componentInstance.selfModal = modalRef;
    modalRef.componentInstance.playlistIdEdit = this.playlistId;
  }
}
