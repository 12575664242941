import { GroupsService } from 'src/app/services/groups.service';
import { Component, Input, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-search-users',
  templateUrl: './search-users.component.html',
  styleUrls: ['./search-users.component.scss'],
})
export class SearchUsersComponent implements OnInit {
  @Input() placeholder: string = '';
  @Input() internalUsers: boolean = true;
  @Input() maxUsers = 30;
  @Input() type: string = '';
  @Input() fromDHSubTeam: boolean = false;
  @Input() group: any;
  @Input() filterByOrganization: boolean = true;
  @Output() searchEvent = new EventEmitter<any>();
  @Input() usersSelected: Array<any> = [];
  @Input() usersExcluded: Array<any> = [];
  @Input() usersExcludedMsg: string = '';

  searchTxt = '';
  usersSearch: any;
  ifIsValidToInvite: boolean = false;

  usersList: Array<any> = [];
  timer: any;
  loadingUsers = false;
  delaySearch = false;

  constructor(
    private usersService: UsersService,
    private groupService: GroupsService,
    public auth: AuthService
  ) {}

  reset() {
    this.searchTxt = '';
    this.usersSearch = [];
    this.ifIsValidToInvite = false;
    this.usersSelected = [];
    this.usersList = [];
  }
  ngOnInit(): void {
    // this.loadingUsers=true;
    // this.usersService.searchUsers(true,'test').then(res=>{
    //   this.usersList=res.data;
    //   console.log('this.usersList');
    //   console.log(this.usersList);
    //   //this.usersSelected=this.usersList;
    //   this.usersSearch=this.usersList;
    //   this.loadingUsers=false;
    // })
  }
  onSearch() {
    this.delaySearch = false;
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.callSearch();
    }, 1500);
  }
  callSearch() {
    this.loadingUsers = true;
    if (this.group) {
      this.groupService
        .searchMembersOfGroup(this.group.id, this.searchTxt)
        .then((resp) => {
          this.ifIsValidToInvite = true;
          let list = resp.data.enrolled_users.map((data: any) => data.user);
          //delete item of list that is in userList
          for (var i = list.length - 1; i >= 0; i--) {
            for (var j = 0; j < this.usersSelected.length; j++) {
              if (list[i] && list[i].id === this.usersSelected[j].id) {
                list.splice(i, 1);
              }
            }
          }

          this.usersList = list;
          this.usersSearch = this.usersList;
          if (this.usersSearch && this.usersSearch.length == 0) {
            var re = /\S+@\S+\.\S+/;
            if (re.test(this.searchTxt) == true) {
              this.ifIsValidToInvite = true;
            } else {
              this.ifIsValidToInvite = false;
            }
          }
          this.loadingUsers = false;
          this.delaySearch = true;
        });
    } else {
      this.usersService
        .searchUsers(this.filterByOrganization, this.searchTxt)
        .then((res) => {
          let list = res.data;
          this.ifIsValidToInvite = true;
          //delete item of list that is in userList
          for (var i = list.length - 1; i >= 0; i--) {
            for (var j = 0; j < this.usersSelected.length; j++) {
              if (list[i] && list[i].id === this.usersSelected[j].id) {
                list.splice(i, 1);
              }
            }
          }

          //delete item of list that is in emailsexcluded
          //si hay usersExcludedMsg entonces no lo borro, pero lo marco para que no sea permitdo agregarlo
          if (this.usersExcludedMsg && this.usersExcluded) {
            for (var i = list.length - 1; i >= 0; i--) {
              for (var j = 0; j < this.usersExcluded.length; j++) {
                if (list[i] && list[i].id === this.usersExcluded[j].user.id) {
                  list[i].excluded = true;
                }
              }
            }
          }
          if (!this.usersExcludedMsg && this.usersExcluded) {
            for (var i = list.length - 1; i >= 0; i--) {
              for (var j = 0; j < this.usersExcluded.length; j++) {
                if (list[i] && list[i].id === this.usersExcluded[j].user.id) {
                  list.splice(i, 1);
                }
              }
            }
          }

          this.usersList = list;
          this.usersSearch = this.usersList;
          if (this.usersSearch && this.usersSearch.length == 0) {
            var re = /\S+@\S+\.\S+/;
            if (re.test(this.searchTxt) == true) {
              this.ifIsValidToInvite = true;
            } else {
              this.ifIsValidToInvite = false;
            }
          }
          this.loadingUsers = false;
          if (
            this.searchTxt &&
            this.usersSearch.length == 0 &&
            this.ifIsValidToInvite
          ) {
            this.onInvite(this.searchTxt);
          }
          this.delaySearch = true;
        });
    }
  }
  isAdmin(): boolean {
    return this.auth.hasAnyRole(['moderator', 'super_user']);
  }
  onAdd(user: any) {
    console.log('tester', user);
    if (
      (user.dh_profiles?.length == 0 && this.fromDHSubTeam) ||
      user.excluded
    ) {
      return;
    }
    this.usersSelected.push(user);
    this.searchTxt = '';
    this.usersSearch = [];
    this.ifIsValidToInvite = false;
    this.delaySearch = false;

    this.searchEvent.emit(this.usersSelected);
  }

  onRemove(index: any) {
    this.usersSelected.splice(index, 1);
    this.searchEvent.emit(this.usersSelected);
  }

  onInvite(searchTxt: string) {
    if (this.isAdmin()) {
      if (this.fromDHSubTeam) {
        return;
      }
      console.log('test ok', searchTxt);
      let user = {
        avatarPathUrlSmall: null,
        avatar: null,
        display_name: searchTxt,
        email: searchTxt,
        edit: true,
      };
      this.usersSelected.push(user);
      this.searchTxt = '';
      this.ifIsValidToInvite = false;
      this.delaySearch = false;
      this.searchEvent.emit(this.usersSelected);
    }
  }
}
