<div class="scrubBar"
    tabindex="0"
    role="slider"
    aria-label="scrub bar"
    aria-level="polite"
    aria-valuemin="0"
    aria-valuemax="100"
    [attr.aria-valuenow]="getPercentage()"
    [attr.aria-valuetext]="getPercentage()">

    
    
    <microclip-scrub-bar-current-time [startTime]="startTime" [endTime]="endTime"></microclip-scrub-bar-current-time>
    <microclip-scrub-bar-buffering-time [startTime]="startTime" [endTime]="endTime"></microclip-scrub-bar-buffering-time>

    <span class="bracket bracket-left microclip-bracket microclip-bracket-start" [ngStyle]="bracketLeftStyle">
        <!-- <span class="ml-static-tooltip purple-background" ng-if="!disableChangeRange" ng-show="showTooltipStart">
            <span class="the-text">{{ "courses:add_lesson:microclip:tooltip:start" | translate }}</span>
        </span> -->
    </span>
    <span class="bracket bracket-right microclip-bracket microclip-bracket-end" [ngStyle]="bracketRightStyle">
        <!-- <span class="ml-static-tooltip purple-background" ng-if="!disableChangeRange" ng-show="showTooltipEnd">
            <span class="the-text">{{ "courses:add_lesson:microclip:tooltip:end" | translate }}</span>
        </span> -->
    </span>
</div>