import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlLoginComponent } from './login/pllogin.component';
import { PlMainComponent } from './main/plmain.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { PlTermsConditionComponent } from './terms-condition/pl-terms-condition.component';



@NgModule({
  declarations: [
    PlLoginComponent,
    PlMainComponent,
    PlTermsConditionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    PipesModule
  ],
  exports: [
    PlLoginComponent
  ]
})
export class PlLoginModule { }
