<div class="table-responsive">
  <table class="table mb-0 mt-1" *ngIf="usersSelected">
    <tbody>
      <tr *ngFor="let item of usersSelected; let i = index">
        <td class="align-middle">
          <app-user-icon [user]="item"></app-user-icon>
        </td>
        <td class="align-middle fw-bolder" style="width: 390px;">{{ item.email }}</td>
        <td class="align-middle">
          <input
            class="input-box w-100"
            [disabled]="!item.edit"
            type="text"
            [(ngModel)]="item.display_name"
          />
        </td>
        <td class="align-middle">
          <img
            (click)="deleteUserInvited(i)"
            class="img-trash"
            src="../../../../../assets/statics/img/trash-can.svg"
            alt="img trash"
          />
        </td>
      </tr>
    </tbody>
  </table>
</div>
