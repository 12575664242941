import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-users-selected',
  templateUrl: './users-selected.component.html',
  styleUrls: ['./users-selected.component.scss'],
})
export class UsersSelectedComponent implements OnInit {
  @Input() usersSelected: any[] = [];
  public textEdit: boolean = false;
  constructor() {}

  ngOnInit(): void {
  }

  deleteUserInvited(index: number) {
    this.usersSelected.splice(index, 1);
  }
}
