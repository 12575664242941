<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon>
  <span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">

  <h4 class="modal-title">{{title|translate}}</h4>

</div>
<div class="modal-body">
  <div *ngIf="loading" class="row max-heigth">
    <div class="row loadingCenter">
      <app-loading-icon></app-loading-icon>
    </div>
  </div>

  <div
    *ngIf="!loading"
    class="row justify-content-center max-heigth list-overflow"
  >
    <label class="modal-label" for="search">{{
      "dh_profile:invite" | translate
    }}</label>
    <app-users-invited [usersSelected]="selectUsers"></app-users-invited>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6">
        <div class="form-group">
          <app-search-users
            [internalUsers]="false"
            id="search"
            (searchEvent)="usersSelected($event)"
            type="text"
            placeholder="{{ 'play_video:video_gift:enter_name' | translate }}"
          ></app-search-users>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6">
        <div class="form-group">
          <label class="modal-label" for="name"> </label>
          <input
            id="name"
            class="input-box w-100"
            maxlength="80"
            name="Name"
            placeholder="{{ 'Name' | translate }}"
            required=""
            type="text"
            [disabled]="true"
          />
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-2">
        <select
          class="form-select"
          aria-label="Language"
          [(ngModel)]="language"
        >
          <option selected value="en">
            {{ "English" | translate }}
          </option>
          <option value="nl">
            {{ "Dutch" | translate }}
          </option>
        </select>
      </div>
    </div>
    <div class="row mt-5">
      <div class="form-group">
        <label class="modal-label" for="textarea">{{
          "dh_teams:profile:invite:message" | translate
        }}</label>
        <textarea
          [(ngModel)]="textMessage"
          class="form-control"
          id="textarea"
          rows="3"
        ></textarea>
      </div>
    </div>
    <div class="row justify-content-end my-3 px-4">
      <button
        (click)="sendInvitation()"
        [disabled]="!selectUsers.length"
        type="button"
        role="tab"
        class="btn btn-primary col-auto"
      >
        <fa-icon
          class="mr-2"
          [icon]="faCircleNorch"
          *ngIf="loadSpin"
          [spin]="true"
        ></fa-icon>
        {{ "dh_teams:send:invitation:label" | translate }}
      </button>
    </div>
  </div>
</div>
