import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from 'src/app/services/admin.service';
import { InitService } from 'src/app/services/init.service';
import { ToastService } from 'src/app/services/toast.service';
import * as moment from 'moment';
@Component({
  selector: 'app-discount-detail',
  templateUrl: './discount-detail.component.html',
  styleUrls: ['./discount-detail.component.scss'],
})
export class DiscountDetailComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() discount: any = {};
  faTimes = faTimes;
  date1: any = '';
  date2: any = '';
  loadSpin: boolean = false;
  faCircleNorch: any = faCircleNotch;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.date1 = this.discount?.valid_from
      ? moment(this.discount?.valid_from).format('DD/MM/YYYY')
      : '';

    this.date2 = this.discount?.valid_until
      ? moment(this.discount?.valid_until).format('DD/MM/YYYY')
      : '';
  }
  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
  goToCourse(courseId: any) {
    this.closeModal();
    this.router.navigate(['/courses', courseId]);
  }
}
