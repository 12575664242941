<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title">
    {{ "users:delete:modal:title" | translate }}
  </h4>
</div>
<div class="modal-body flex-column">
  <div class="row">
    <p class="description">
      {{ "users:delete:modal:description" | translate }}
    </p>
  </div>
</div>
<div class="row justify-content-end modal-footer">
  <div class="row justify-content-end text-center margin-30">
    <div class="col-2">
      <button
        type="button"
        class="btn btn-outline-primary ms-2 fs-15 text-nowrap"
        (click)="closeModal()"
        [disabled]="loadSpin"
      >
        {{ "Cancel" | translate }}
      </button>
    </div>
    <div class="col-auto">
      <button
        (click)="deleteUser()"
        type="button"
        role="tab"
        class="btn btn-danger text-nowrap"
        [disabled]="loadSpin"
      >
        <fa-icon
          class="mr-2"
          [icon]="faCircleNorch"
          *ngIf="loadSpin"
          [spin]="true"
        ></fa-icon>
        {{
          "organization:members:delete:invitation:confirm:accepted" | translate
        }}
      </button>
    </div>
  </div>
</div>
