<fa-icon [icon]="faStar" [size]="size" [styles]="{'color': '#f8e71c'}" *ngIf="!showStars; else elseb"></fa-icon>
<ng-template #elseb>
    <fa-stack *ngFor="let star of stars" [size]="size">
        <fa-icon [icon]="faStar" stackItemSize="1x" [styles]="{'color': bgColor}"></fa-icon>
        <fa-icon [icon]="faHalfStar" stackItemSize="1x" [styles]="{'color': '#f8e71c'}" *ngIf="star >= 3 && star <= 7"></fa-icon>
        <fa-icon [icon]="faStar" stackItemSize="1x" [styles]="{'color': '#f8e71c'}" *ngIf="star >= 8"></fa-icon>
    </fa-stack>
</ng-template>
<ng-container *ngIf="showNumber">
    <span [ngStyle]="{'color': bgColor}" class="ms-2">{{ rating }}</span>
</ng-container>
