import { ToastService } from './../../../services/toast.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
///////////
//icons
///////////
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
/////////////
//Interfaces
/////////////
import { TableHeader } from 'src/app/interfaces/headers';
///////////
//Services
///////////
import { GroupsService } from 'src/app/services/groups.service';

@Component({
  selector: 'app-invite-new-members',
  templateUrl: './invite-new-members.component.html',
  styleUrls: ['./invite-new-members.component.scss'],
})
export class InviteNewMembersComponent implements OnInit {
  ///////////
  //Atributes
  ///////////
  faTimes = faTimes;
  @Input() selfModal: NgbModalRef | null = null;
  @Input() group: any;
  public faCircleNorch = faCircleNotch;
  public usersInvited: any = [];
  public actualTab = 'inviteNewUser';
  public loading: boolean = false;
  public selectUsers: any = [];
  public textName: any = '';
  public loadSpin: boolean = false;
  public textMessage: string = '';
  public headers: TableHeader[] = [
    { name: 'Icon', show: false, nickname: 'icon', source: 'user', id: 'id' },
    {
      name: 'Name',
      show: true,
      nickname: 'name',
      source: 'user.display_name',
      id: 'id',
    },
    // {
    //   name: 'Email',
    //   show: true,
    //   nickname: 'email',
    //   source: 'user.email',
    //   id: 'id',
    // },
    {
      name: 'Status',
      show: true,
      nickname: 'status',
      source: 'status',
      id: 'id',
    },
  ];
  public users: any = [];

  constructor(
    private groupsService: GroupsService,
    private toastService: ToastService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.getUsersInvited();

  }

  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  getTab(tab: string) {
    this.actualTab = tab;
    if (this.actualTab == 'invited') {
      this.getUsersInvited();
    }
  }

  getUsersInvited() {
    this.loading = true;

    this.groupsService.getUsersInvited(this.group.id).then((result) => {
      let data: any;
      data = result.data.enrolled_users;
      this.usersInvited = this.groupsService.mapUsers(data, this.headers);

      this.loading = false;
    });
  }

  usersSelected(data: any) {
    this.selectUsers = data;
    // this.textName =  this.selectUsers[this.selectUsers.length-1].display_name;
  }

  sendInvitation() {
    let allUserInvited: any;
    this.loadSpin = true;
    allUserInvited = this.selectUsers.map((user: any) => {
      return {
        display_name: user.display_name,
        email: user.email,
        message: this.textMessage,
      };
    });

    this.groupsService
      .sendInvitations(this.group.id, allUserInvited)
      .then((data) => {
        this.selectUsers = [];
        this.toastService.showSuccess(
          this.translate.instant('collection:members:add:members:success')
        );
        this.loadSpin = false;
        this.closeModal();
      })
      .catch((err) => {
        console.log(err);
        this.toastService.showError(
          this.translate.instant('organization:members:send_invitation:error')
        );
      });
  }
}
