<div class="profile-settings-page">
  <!-- Profile Info Account -->
  <div class="profile-info group">
    <h2>{{ "my_profile:settings:profile:title" | translate }}</h2>
    <div class="form-group">
      <label class="form-label">{{
        "my_profile:settings:profile:picture" | translate
      }}</label>
      <div
        id="avatar"
        class="avatar"
        [style]="{
          'background-image':
            'url(' +
            (showImage || 'assets/img/avatars/profile-avatar-purple.svg') +
            ')'
        }"
      ></div>

      <input
        #fileInput
        style="display: none"
        type="file"
        (change)="uploadImage($event)"
        accept="image/png,image/jpeg,image/bmp,image/jpg"
      />

      <div class="buttons">
        <button
          class="btn btn-primary py-1"
          (click)="fileInput.click()"
          [disabled]="loadingProfile"
        >
          {{ "my_profile:settings:profile:button:change" | translate }}
        </button>
        <button
          class="btn btn-link text-danger font-weight-Light"
          *ngIf="user.avatarPathUrlSmall"
          (click)="onRemoveAvatar()"
        >
          {{ "my_profile:settings:profile:button:delete" | translate }}
        </button>
      </div>
    </div>

    <form [formGroup]="fgProfile" (ngSubmit)="onSaveProfile()">
      <div class="form-group">
        <label class="form-label">{{
          "my_profile:settings:profile:name" | translate
        }}</label>
        <input
          formControlName="display_name"
          type="text"
          [(ngModel)]="profileData.display_name"
          class="form-control my-input"
        />
      </div>
      <div
        *ngIf="fgProfile.controls.display_name.errors?.required"
        class="error-message"
      >
        {{ "my_profile:settings:profile:name:mandatory" | translate }}
      </div>

      <div class="form-group d-block">
        <label class="form-label">{{
          "my_profile:settings:profile:bio" | translate
        }}</label>
        <textarea
          formControlName="bio"
          type="text"
          [(ngModel)]="profileData.bio"
          class="form-control my-input"
        ></textarea>
      </div>

      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="fgProfile.invalid || loadingProfile"
      >
        {{ "my_profile:settings:button:save" | translate }}
        <fa-icon
          *ngIf="loadingProfile"
          class="ms-2"
          [icon]="faCircleNotch"
          [spin]="true"
        ></fa-icon>
      </button>
    </form>
  </div>

  <!-- Account Info -->
  <div class="account-info group" id="emailGroup">
    <h2>{{ "my_profile:settings:account:title" | translate }}</h2>

    <form [formGroup]="fgEmail" (ngSubmit)="onChangeEmail()">
      <div class="form-group">
        <label class="form-label">{{
          "my_profile:settings:account:email" | translate
        }}</label>
        <input
          formControlName="newEmail"
          [(ngModel)]="emailData.newEmail"
          class="form-control my-input"
          type="email"
          placeholder="{{
            'my_profile:settings:placeholder:email' | translate
          }}"
        />
      </div>
      <div
        *ngIf="fgEmail.controls.newEmail.errors?.required"
        class="error-message"
      >
        {{ "my_profile:settings:profile:email:mandatory" | translate }}
      </div>
      <div
        *ngIf="fgEmail.controls.newEmail.errors?.email"
        class="error-message"
      >
        {{ "my_profile:settings:account:email:invalid" | translate }}
      </div>

      <div
        class="success-message"
        *ngIf="emailData.oldEmail == emailData.newEmail && user.email_confirmed"
      >
        <i class="fa fa-check"></i>
        {{ "my_profile:settings:account:email:confirmed" | translate }}
      </div>

      <div
        class="error-message"
        *ngIf="
          emailData.oldEmail == emailData.newEmail && !user.email_confirmed
        "
      >
        <fa-icon [icon]="faExclamationTriangle"> </fa-icon>
        {{ "my_profile:settings:account:email:not_confirmed" | translate }}
      </div>

      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="fgEmail.invalid || loadingENotify"
      >
        {{ "my_profile:settings:button:save" | translate }}
        <fa-icon
          *ngIf="loadingENotify"
          class="ms-2"
          [icon]="faCircleNotch"
          [spin]="true"
        ></fa-icon>
      </button>
    </form>

    <form
      #fInfoAccount="ngForm"
      (ngSubmit)="onSaveInfoAccount(fInfoAccount.value)"
    >
      <div class="form-group-50 left">
        <label class="form-label">{{
          "my_profile:settings:account:language" | translate
        }}</label>
        <select
          name="language"
          class="my-input py-2"
          [(ngModel)]="accountData.language"
          required
        >
          <option value="nl">{{ "navigation:language:nl" | translate }}</option>
          <option value="en">{{ "navigation:language:en" | translate }}</option>
        </select>
      </div>

      <div class="form-group-50 right">
        <label class="form-label">{{
          "my_profile:settings:account:country" | translate
        }}</label>
        <select
          name="country_code"
          class="my-input py-2"
          [(ngModel)]="accountData.country_code"
        >
          <option *ngFor="let country of countries" [value]="country.code">
            {{ country.name }}
          </option>
        </select>
      </div>

      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="fInfoAccount.invalid || loadingInfoAccount"
      >
        {{ "my_profile:settings:button:save" | translate }}
        <fa-icon
          *ngIf="loadingInfoAccount"
          class="ms-2"
          [icon]="faCircleNotch"
          [spin]="true"
        ></fa-icon>
      </button>
    </form>
    <!-- Notify configuration -->
    <form #fNotify="ngForm" (ngSubmit)="onSaveNotificationsConf(fNotify)">
      <h2>{{ "my_profile:settings:notifications:title" | translate }}</h2>
      <p class="mb-4">
        {{ "my_profile:settings:notifications:text:pre" | translate }}
      </p>

      <h3>{{ "my_profile:settings:notifications:label" | translate }}</h3>
      <div class="form-group my-2">
        <ml-switch
          name="uploaded"
          [inputValue]="notificationsData.uploaded"
          textAlignClass="ml-switch-text-right"
          textContent="{{
            'my_profile:settings:notifications:label:uploaded' | translate
          }}"
          (value)="setUploadVideo($event)"
        >
        </ml-switch>
      </div>
      <br />
      <div class="form-group">
        <ml-switch
          name="commented"
          [inputValue]="notificationsData.commented"
          textAlignClass="ml-switch-text-right"
          textContent="{{
            'my_profile:settings:notifications:label:commented' | translate
          }}"
          (value)="setCommentVideo($event)"
        >
        </ml-switch>
      </div>

      <p class="mt-3">
        {{ "my_profile:settings:notifications:text:post" | translate }}
      </p>

      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="fNotify.invalid || loadingNotifyConf"
      >
        {{ "my_profile:settings:button:save" | translate }}
        <fa-icon
          *ngIf="loadingNotifyConf"
          class="ms-2"
          [icon]="faCircleNotch"
          [spin]="true"
        ></fa-icon>
      </button>
    </form>
  </div>
  <!-- Password group -->
  <div class="password group" id="passwordGroup">
    <h2>{{ "my_profile:settings:password:title" | translate }}</h2>

    <form #fPass="ngForm" (ngSubmit)="onChangePassword(fPass)">
      <div class="form-group">
        <label class="form-label">{{
          "my_profile:settings:password:current" | translate
        }}</label>
        <input
          class="form-control my-input py-2"
          name="currentPassword"
          (keyup)="checkPassword(passwordData.current_password, 'currentPass')"
          [(ngModel)]="passwordData.current_password"
          [type]="currentPasswordType"
          minlength="10"
          maxlength="15"
          placeholder="{{
            'my_profile:settings:password:current:placeholder' | translate
          }}"
          required
        />
        <fa-icon
          (click)="rePasswordShow('currentPassword')"
          [icon]="currentPasswordShow"
          class="togglePassword"
        >
        </fa-icon>
      </div>
      <div
        class="error-message"
        *ngIf="
          !currentPass &&
          newPass &&
          passwordData.new_password == passwordData.repeat_new_password
        "
      >
        <fa-icon [icon]="faExclamationTriangle"></fa-icon>
        {{ "my_profile:settings:password:error:required" | translate }}
      </div>
      <div
        class="error-message"
        *ngIf="!currentPass && passwordData.current_password.length > 0"
      >
        {{ "my_profile:settings:password:error:invalid" | translate }}
      </div>
      <div class="form-group">
        <label class="form-label">{{
          "my_profile:settings:password:new" | translate
        }}</label>
        <input
          class="form-control my-input py-2"
          name="newPassword"
          (keyup)="checkPassword(passwordData.new_password, 'newPass')"
          [(ngModel)]="passwordData.new_password"
          [type]="newPasswordType"
          minlength="10"
          maxlength="15"
          placeholder="{{
            'my_profile:settings:password:new:placeholder' | translate
          }}"
          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%ˆ&*()!@ ...]).{10,15}$"
          required
        />
        <fa-icon
          (click)="rePasswordShow('newPassword')"
          [icon]="newPasswordShow"
          class="togglePassword"
        >
        </fa-icon>
      </div>
      <div
        *ngIf="passwordData.new_password.length > 0"
        class="text-muted fw-500"
      >
        <span><small>The password must contain:</small></span>
        <ul style="list-style: none; padding-left: 5px" Class="mb-0 mx-0">
          <li>
            <small>
              <i
                class="fa fa-check-circle"
                [ngStyle]="{ color: !lengthy ? '#c6c7c8' : '#5cb85c' }"
              ></i>
              <span class="mx-1">{{
                "password:requirements:length" | translate
              }}</span>
            </small>
          </li>
          <li>
            <small>
              <i
                class="fa fa-check-circle"
                [ngStyle]="{ color: !lowerCase ? '#c6c7c8' : '#5cb85c' }"
              ></i>
              <span class="mx-1"
                >small{{ "password:requirements:letter:l" | translate }}</span
              >
            </small>
          </li>
          <small>
            <i
              class="fa fa-check-circle"
              [ngStyle]="{ color: !uperCase ? '#c6c7c8' : '#5cb85c' }"
            ></i>
            <span class="mx-1"
              >{{ "password:requirements:letter:u" | translate }}.</span
            >
          </small>
          <li>
            <small>
              <i
                class="fa fa-check-circle"
                [ngStyle]="{ color: !num ? '#c6c7c8' : '#5cb85c' }"
              ></i>
              <span class="mx-1">
                {{ "password:requirements:number" | translate }}.</span
              >
            </small>
          </li>
          <li>
            <small>
              <i
                class="fa fa-check-circle"
                [ngStyle]="{ color: !special ? '#c6c7c8' : '#5cb85c' }"
              ></i>
              <span class="mx-1"
                >{{ "password:requirements:special" | translate }}al character
                (#$%ˆ&*()!@ ...)</span
              >
            </small>
          </li>
          <li>
            <small>
              <i
                class="fa fa-check-circle"
                [ngStyle]="{ color: !newPass ? '#c6c7c8' : '#5cb85c' }"
              ></i>
              <span class="mx-1">{{
                "password:requirements:different" | translate
              }}</span>
            </small>
          </li>
        </ul>
      </div>
      <div class="form-group">
        <label class="form-label">{{
          "my_profile:settings:password:retype" | translate
        }}</label>
        <input
          class="form-control my-input"
          name="retypePassword"
          [(ngModel)]="passwordData.repeat_new_password"
          placeholder="{{
            'my_profile:settings:password:retype:placeholder' | translate
          }}"
          [type]="retypePasswordType"
          minlength="10"
          maxlength="15"
          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%ˆ&*()!@ ...]).{10,15}$"
          required
        />
        <fa-icon
          (click)="rePasswordShow('retypePassword')"
          [icon]="retypePasswordShow"
          class="togglePassword"
        >
        </fa-icon>
      </div>
      <div class="form-group">
        <div
          class="error-message"
          *ngIf="
            passwordData.new_password.length > 0 &&
            passwordData.new_password != passwordData.repeat_new_password
          "
        >
          {{ "my_profile:settings:password:error:not_match" | translate }}
        </div>
        <div
          class="success-message"
          *ngIf="
            passwordData.new_password.length > 0 &&
            passwordData.new_password == passwordData.repeat_new_password &&
            passwordData.new_password != passwordData.current_password
          "
        >
          {{ "my_profile:settings:password:match" | translate }}
        </div>
        <div
          class="error-message"
          *ngIf="
            passwordData.new_password.length > 0 &&
            passwordData.new_password == passwordData.current_password
          "
        >
          {{ "my_profile:settings:password:error:match" | translate }}
        </div>
      </div>
      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="
          fPass.invalid ||
          passwordData.new_password != passwordData.repeat_new_password ||
          passwordData.new_password == passwordData.current_password ||
          !newPass ||
          !currentPass ||
          loadingPassword
        "
      >
        {{ "my_profile:settings:password:button" | translate }}
        <fa-icon
          *ngIf="loadingPassword"
          class="ms-2"
          [icon]="faCircleNotch"
          [spin]="true"
        ></fa-icon>
      </button>
    </form>
  </div>
  <!-- Act/Des Account -->
  <div class="deactivate-account group">
    <h2>{{ "my_profile:settings:deactivate:title" | translate }}</h2>
    <p>
      {{ "my_profile:settings:deactivate:text" | translate }}
    </p>
    <a
      class="btn btn-danger mt-3"
      (click)="onDeactivateAccount()"
      *ngIf="!user.account_deactivated"
    >
      {{ "my_profile:settings:deactivate:button" | translate }}
    </a>
    <a
      class="btn btn-danger mt-3"
      (click)="onActivateAccount()"
      *ngIf="user.account_deactivated"
    >
      {{ "my_profile:settings:activate:button" | translate }}
    </a>
  </div>

  <div class="delete-account group mt-4">
    <h2>{{ "my_profile:settings:delete:title" | translate }}</h2>
    <p>
      {{ "my_profile:settings:delete:text" | translate }}
    </p>
    <button
      class="btn btn-danger mt-3"
      (click)="onDeleteAccount()"
      ng-if="!user.account_to_be_deleted_created_at"
    >
      {{ "my_profile:settings:delete:button" | translate }}
    </button>
  </div>
</div>
