import { Component, Input,Output,EventEmitter, OnInit } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Organization } from 'src/app/interfaces/organization';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { ToastService } from 'src/app/services/toast.service';

import { CollectionService } from 'src/app/services/collection.service';


@Component({
  selector: 'app-change-owner-modal',
  templateUrl: './change-owner-modal.component.html',
  styleUrls: ['./change-owner-modal.component.scss'],
})
export class ChangeOwnerModalComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() collection: any;
  @Input() type: any;
  @Input() username: string | undefined;
  @Input() deleteFromMembers: boolean = false;

  @Output() userSelected = new EventEmitter<any>();
  public password: string = '';
  passErr=false;
  public faTimes = faTimes;
  public faCircleNotch = faCircleNotch;
  public loading = false;
  showPassword=false;
  userSelect: any;

  currentOrg: Organization | null = null;
  constructor(
    private orgsService: OrganizationsService,    
    private collectionService: CollectionService,
    private toastService: ToastService,

  ) {}

  ngOnInit(): void {
    this.currentOrg = this.orgsService.getCurrent();
  }

  usersSelected(user: any){
    this.userSelect=user;

    //this.closeModal
    // $('ngb-modal-window').click();
    // this.userSelected.emit(this.userSelect);

  }
  changeOwner(){
  
    this.loading = true;


    this.collectionService
    .changeOwner(this.userSelect[0].id,this.collection.id)
    .then((res) => {
     
    
      this.loading = false;
      this.userSelected.emit(this.userSelect);
      $('ngb-modal-window').click();
    })
    .catch((error) => {
      this.loading = false;
      this.toastService.showError(`${error.error.message.friendly}`);
    });

    console.log(this.userSelect[0])
  }
  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

 
}
