<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon>
  <span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">{{ "Members" | translate }}</h4>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="row tabs-row">
      <div class="col text-center">
        <app-movie-tabs-wrapper [main]="true">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active py-3"
              id="activity-tab"
              (click)="getTab('enrolled')"
              type="button"
              role="tab"
            >
              {{ "courses:status:enrolled" | translate }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link py-3"
              id="conversation-tab"
              (click)="getTab('invited')"
              type="button"
              role="tab"
            >
              {{ "Invited" | translate }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link py-3"
              (click)="getTab('inviteNewUser')"
              type="button"
              role="tab"
            >
              {{ "collection:members:tab:add:members" | translate }}
            </button>
          </li>
        </app-movie-tabs-wrapper>
      </div>
    </div>
    <div *ngIf="loading; else content" class="justify-content-center">
      <app-loading-icon></app-loading-icon>
    </div>
    <ng-template #content>
      <div class="row mt-3">
        <table class="table table-hover" *ngIf="actualTab == 'enrolled'">
          <thead>
            <tr [ngClass]="{ 'custom-border': true }">
              <th class="text-center" scope="col"></th>
              <th scope="col" class="my-3 align-middle">
                <span>
                  {{ "User" | translate }}
                </span>
              </th>
              <th scope="col" class="my-3 align-middle">
                <span>
                  {{ "courses:modal:members:table:title:2" | translate }}
                </span>
              </th>
              <th scope="col" class="my-3 align-middle">
                <span>
                  {{ "courses:modal:members:table:title:3" | translate }}
                </span>
              </th>
              <th scope="col" class="my-3 align-middle">
                <span>
                  {{ "courses:modal:members:table:title:4" | translate }}
                </span>
              </th>

              <th class="text-center" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              [ngClass]="{ 'custom-border': true }"
              *ngFor="let member of usersAccepted; let i = index"
            >
              <td class="justify-content-center align-middle">
                <app-user-icon [user]="member.user"></app-user-icon>
              </td>
              <td class="title-course align-middle">
                <p class="mb-0 fw-bold">
                  {{ member.user.display_name | excerpt : 50 }}
                </p>
                <p class="text-muted mb-0 email">
                  {{ member.user.email | excerpt : 25 }}
                </p>
              </td>
              <td class="align-middle">
                {{ course?.created_at | date : "longDate" }}
              </td>
              <td class="align-middle">
                {{ "-" }}
              </td>
              <td class="align-middle">
                {{ "30 days" }}
              </td>
              <td
                *ngIf="member.type == 'manager'"
                class="align-middle text-muted"
              >
                {{ "(owner)" }}
              </td>
              <!-- <td colspan="3" class="align-middle text-center">
                      <div
                        ngbDropdown
                        class="d-inline-block float_right"
                        placement="bottom-right"
                      >
                        <button
                          type="button"
                          class="btn btn-outline-primary"
                          id="dropdownBasic1"
                          ngbDropdownToggle
                        >
                          <i class="fa fa-ellipsis-h"></i>
                        </button>
                        <div
                          ngbDropdownMenu
                          aria-labelledby="dropdownBasic1"
                          class="mt-1"
                        >
                          <button
                            (click)="goToCourse(course?.id)"
                            ngbDropdownItem
                          >
                            {{
                              "organization:settings:courses:table:dropdown:item:5"
                                | translate
                            }}
                          </button>
                          <button (click)="shareSettings()" ngbDropdownItem>
                            {{
                              "organization:settings:courses:table:dropdown:item:1"
                                | translate
                            }}
                          </button>
                          <button
                            (click)="openViewMembers(course)"
                            ngbDropdownItem
                          >
                            {{
                              "organization:settings:courses:table:dropdown:item:2"
                                | translate
                            }}
                          </button>
                          <button (click)="openPricing(course)" ngbDropdownItem>
                            {{
                              "organization:settings:courses:table:dropdown:item:3"
                                | translate
                            }}
                          </button>
                          <button
                            class="text-danger"
                            (click)="deleteCourse(course)"
                            ngbDropdownItem
                          >
                            {{
                              "organization:settings:courses:table:dropdown:item:4"
                                | translate
                            }}
                          </button>
                        </div>
                      </div>
                    </td> -->
            </tr>
          </tbody>
        </table>
        <table class="table table-hover" *ngIf="actualTab == 'invited'">
          <thead>
            <tr [ngClass]="{ 'custom-border': true }">
              <th scope="col" class="my-3 align-middle">
                <span>
                  {{ "User" | translate }}
                </span>
              </th>

              <th class="text-center" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              [ngClass]="{ 'custom-border': true }"
              *ngFor="let member of usersInvited; let i = index"
            >
              <td class="justify-content-center">
                <app-user-icon [user]="member.user"></app-user-icon>
              </td>
              <td class="title-course align-middle">
                <p class="mb-0 fw-bold"></p>
                {{ member.user.display_name | excerpt : 50 }}
                <p class="email mb-0">{{ member.user.email | excerpt : 50 }}</p>
              </td>
              <td class="align-middle"></td>
              <td class="align-middle"></td>
              <td class="align-middle"></td>
              <!-- <td colspan="3" class="align-middle text-center">
                      <div
                        ngbDropdown
                        class="d-inline-block float_right"
                        placement="bottom-right"
                      >
                        <button
                          type="button"
                          class="btn btn-outline-primary"
                          id="dropdownBasic1"
                          ngbDropdownToggle
                        >
                          <i class="fa fa-ellipsis-h"></i>
                        </button>
                        <div
                          ngbDropdownMenu
                          aria-labelledby="dropdownBasic1"
                          class="mt-1"
                        >
                          <button
                            (click)="goToCourse(course?.id)"
                            ngbDropdownItem
                          >
                            {{
                              "organization:settings:courses:table:dropdown:item:5"
                                | translate
                            }}
                          </button>
                          <button (click)="shareSettings()" ngbDropdownItem>
                            {{
                              "organization:settings:courses:table:dropdown:item:1"
                                | translate
                            }}
                          </button>
                          <button
                            (click)="openViewMembers(course)"
                            ngbDropdownItem
                          >
                            {{
                              "organization:settings:courses:table:dropdown:item:2"
                                | translate
                            }}
                          </button>
                          <button (click)="openPricing(course)" ngbDropdownItem>
                            {{
                              "organization:settings:courses:table:dropdown:item:3"
                                | translate
                            }}
                          </button>
                          <button
                            class="text-danger"
                            (click)="deleteCourse(course)"
                            ngbDropdownItem
                          >
                            {{
                              "organization:settings:courses:table:dropdown:item:4"
                                | translate
                            }}
                          </button>
                        </div>
                      </div>
                    </td> -->
            </tr>
          </tbody>
        </table>
        <div class="px-0" *ngIf="actualTab == 'inviteNewUser'">
          <label class="form-label" for="search">{{
            "course:modal:invite:title" | translate
          }}</label>
          <app-users-invited [usersSelected]="selectUsers"></app-users-invited>
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6">
              <div class="form-group">
                <app-search-users
                  [internalUsers]="false"
                  [usersExcluded]="enrolledUser"
                  id="search"
                  (searchEvent)="usersSelected($event)"
                  type="text"
                  placeholder="{{
                    'play_video:video_gift:enter_name' | translate
                  }}"
                ></app-search-users>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 ps-0">
              <div class="form-group">
                <label class="form-label" for="name"> </label>
                <input
                  id="name"
                  class="input-box w-100"
                  maxlength="80"
                  name="Name"
                  placeholder="{{ 'Name' | translate }}"
                  required=""
                  type="text"
                  [disabled]="true"
                />
              </div>
            </div>
          </div>
          <div *ngIf="hideButtons" class="form-group d-flex">
            <div class="col-6">
              <label for="clip-title" class="d-block mt-3 modal-label">{{
                "courseCreation:language:title" | translate
              }}</label>
              <select class="pure-input" [(ngModel)]="language">
                <option value="nl" selected="selected">
                  {{ "language:options:nl" | translate }}
                </option>
                <option value="en">
                  {{ "language:options:en" | translate }}
                </option>
              </select>
            </div>
            <div class="col-6">
              <label for="clip-title" class="d-block mt-3 modal-label">{{
                "collection:members:add:members:subscription:length" | translate
              }}</label>
              <select class="pure-input" [(ngModel)]="days">
                <option value="30" selected="selected">
                  {{ "30 days" | translate }}
                </option>
                <option value="90" selected="selected">
                  {{ "90 days" | translate }}
                </option>
                <option value="180" selected="selected">
                  {{ "180 days" | translate }}
                </option>
                <option value="360" selected="selected">
                  {{ "360 days" | translate }}
                </option>
                <option value="-1" selected="selected">
                  {{ "Custom" | translate }}
                </option>
              </select>
              <input
                id="name"
                class="input-box custom-value"
                maxlength="50"
                name="custom_value"
                [(ngModel)]="custom_value"
                placeholder="{{ 'Custom value' | translate }}"
                required=""
                type="number"
                *ngIf="days == -1"
              />
            </div>
          </div>
          <div class="row mt-3">
            <div class="form-group">
              <label class="form-label" for="textarea">{{
                "Write a message for the invitation" | translate
              }}</label>
              <textarea
                [(ngModel)]="textMessage"
                class="form-control"
                id="textarea"
                rows="3"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <app-empty-content
            class="w-100"
            *ngIf="!usersAccepted?.length && actualTab == 'enrolled'"
            [primaryText]="'No users' | translate"
          ></app-empty-content>
          <app-empty-content
            class="w-100"
            *ngIf="!usersInvited?.length && actualTab == 'invited'"
            [primaryText]="'collection:members:invited:empty' | translate"
          ></app-empty-content>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="modal-footer p-0" *ngIf="actualTab == 'inviteNewUser'">
    <div class="d-flex justify-content-end text-center mt-3">
      <button
        (click)="sendInvitation()"
        [disabled]="!selectUsers.length"
        type="button"
        role="tab"
        class="btn btn-primary fs-15 text-nowrap mx-0"
      >
        <fa-icon
          class="mr-2"
          [icon]="faCircleNorch"
          *ngIf="loadSpin"
          [spin]="true"
        ></fa-icon>
        {{ "Send Invitations" | translate }}
      </button>
    </div>
  </div>
</div>
