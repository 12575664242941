<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<!-- <app-loading-icon *ngIf="loading"></app-loading-icon> -->
<ng-container>
  <div style="padding-bottom: 60.11%; position: relative">
    <div
      [id]="'kaltura_player'"
      style="position: absolute; width: 100%; height: 100% !important;"
    ></div>
  </div>
</ng-container>
