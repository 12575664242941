<div class="maintenance-wrapper p-3 pe-4">
  <div class="row mb-md-3 mb-5">
    <div class="col-2 text-center col-md-1">
      <img
       
        src="/assets/img/home/maintenance-icon.png"
        alt=""
        class="img-question mb-2"
      />
    </div>
    <div class="col">
      <h5>{{ title }}</h5>
      <p class="questionaire-desc col-8 m-0">
        {{ msg }}
      </p>
    </div>
    <img (click)="hide()" src="/assets/img/home/x.png" alt="" class="img-x" />
  </div>
  <div class="row justify-content-end text-end">
    <div
      ngbDropdown
      container="body"
      class="dropdown col-auto px-0 m-2"
      (click)="$event.stopPropagation()"
    >
      <a
        class="btn btn-outline btn-w"
        role="button"
        id="profileOptions"
        aria-expanded="false"
        ngbDropdownToggle
      >
        {{ "btn:ignore" | translate }}
        <img
          src="/assets/img/home/arrow-down-quest.png"
          alt=""
          class="img-arrow"
        />
      </a>

      <ul
        ngbDropdownMenu
        aria-labelledby="profileOptions"
        class="dropdown-menu-movie dropdown-menu-center dropdown-bubble"
      >
        <button disabled ngbDropdownItem class="fw-bold text-secondary">
          {{ "dh_teams:remove:from:team:confirmation" | translate }}
        </button>
        <hr class="my-0 mx-2" />
        <button ngbDropdownItem (click)="hide()" class="fw-bold text-secondary">
          {{ "option:no" | translate }}
        </button>
        <button
          ngbDropdownItem
          (click)="hideDefinitelyNotification()"
          class="fw-bold text-danger"
        >
          {{ "option:yes:ignore" | translate }}
        </button>
      </ul>
    </div>
    <button
      (click)="acceptAndOpenQuestionnaire()"
      class="btn btn-success col-auto m-2 btn-w"
    >
      {{ "beta:message:accept" | translate }}
    </button>
  </div>
</div>
