<div class="modal-close" *ngIf="type != 'profile'" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<app-loading-icon *ngIf="loading"></app-loading-icon>
<ng-container *ngIf="!loading">
  <div style="padding-bottom: 60.11%; position: relative"
  *ngIf="this.video?.source?.exist">
    <div
     
      [id]="'kaltura_player_' + video?.source?.external_id"
      style="position: absolute; width: 100%; height: 100%"
    >
      <!-- <app-loading-icon></app-loading-icon> -->
      <div class="row m-auto">
        <div class="spinner">
          <app-loading-icon></app-loading-icon>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!this.video?.source?.exist&&!loading" class="d-flex align-items-center justify-content-center"
  style="padding-bottom: 20.11%;padding-top: 20.11%;position: relative">
    <img src="assets/img/courses/video_not_found.png" alt="Imagen centrada" class="img-fluid" style="height: 400px;">
  </div>
</ng-container>
