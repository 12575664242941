
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'src/app/services/toast.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-cancel-email',
  templateUrl: './cancel-email.component.html',
  styleUrls: ['./cancel-email.component.scss']
})
export class CancelEmailComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private us: UsersService,
    private translate: TranslateService,
    private toastr: ToastService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe( (params) => {
      const token = params['token'];
      this.us.cancelEmail(token).then( (data) => {
      
        setTimeout(() => {
          this.toastr.showSuccess( this.translate.instant('cancel:email:success') );

        },200)
      }).catch( err => {
        const message = err.error?.message?.friendly || this.translate.instant('confirm:email:error');
        setTimeout(() => {
          this.toastr.showError(message);
        },200)
      }).finally( () => {
        this.router.navigate(['/']);
      })
    })
  }

}
