import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { faTimes, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-edit-discount',
  templateUrl: './edit-discount.component.html',
  styleUrls: ['./edit-discount.component.scss'],
})
export class EditDiscountComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() discount: any = {};
  @Output() refresh = new EventEmitter<any>();
  courseSelected: any = '';
  faTimes = faTimes;
  date1: any = '';
  date2: any = '';
  id: any = '';
  infoDiscount: any = {};
  loadSpin: boolean = false;
  firstCheck: boolean = false;
  secondCheck: boolean = false;
  faCircleNorch: any = faCircleNotch;

  constructor(private router: Router, private adminService: AdminService) {}

  ngOnInit(): void {
    this.date1 = this.discount?.valid_from
      ? moment(this.discount?.valid_from).format('YYYY-MM-DD')
      : '';

    this.date2 = this.discount?.valid_until
      ? moment(this.discount?.valid_until).format('YYYY-MM-DD')
      : '';

    this.id = this.discount.id;
    this.firstCheck = this.discount.total_available == null ? true : false;
    this.secondCheck = this.discount.valid_until == null ? true : false;

    this.infoDiscount = {
      amount: this.discount.amount,
      code: this.discount.code,
      collection_id: this.discount.collection_id,
      total_available: this.discount.total_available,
      type: this.discount.type,
      valid_from: this.date1,
      valid_until: this.date2,
    };
  }
  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  goToCourse(courseId: any) {
    this.closeModal();
    this.router.navigate(['/courses', courseId]);
  }

  public updateDiscount() {
    this.loadSpin = true;
    if (this.firstCheck) {
      this.infoDiscount.total_available = null;
    }
    if (this.secondCheck) {
      this.infoDiscount.valid_until = null;
    }
    this.adminService
      .updateDiscount(this.infoDiscount, this.id)
      .then((result) => {
        this.loadSpin = false;
        this.closeModal();
        this.refresh.emit();
      })
      .catch((err) => {
        this.closeModal();
        this.refresh.emit();
      });
  }

  public changeInfinite() {
    !this.firstCheck;
  }
  public changeExpires() {
    !this.secondCheck;
  }
  public unselect() {
    this.courseSelected = '';
  }
  public getCourse(course: any) {
    this.courseSelected = course;
  }
}
