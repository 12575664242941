import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-edit-member',
  templateUrl: './edit-member.component.html',
  styleUrls: ['./edit-member.component.scss'],
})
export class EditMemberComponent implements OnInit, OnChanges {
  @Output() refresh = new EventEmitter<any>();
  @Input() selfModal: NgbModalRef | null = null;
  @Input() member: any;
  @Input() organizationId: any;

  info: any = {
    name: '',
    email: '',
    password: '',
    passwordRepeat: null,
  };
  faTimes = faTimes;
  faCircleNorch = faCircleNotch;
  loading = false;
  radio = true;

  constructor(
    private organizationService: OrganizationsService,
    private toastService: ToastService,
    private translate: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {}
  ngOnInit(): void {
    console.log(this.member);
    if (this.member) {
      this.info.name = this.member.user.name;
      this.info.email = this.member.user.email;
    }
  }

  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
  public editMember() {
    this.loading = true;
    let data: any = {};
    data = {
      email: this.info.email,
      name: this.info.name,
    };

    if (
      this.info.password != this.info.passwordRepeat &&
      this.info.password != '' &&
      this.info.passwordRepeat != ''
    ) {
      this.toastService.showError(
        this.translate.instant('Password and password repeat do not match')
      );
      return;
    }
    if (this.info.password != '') {
      data['password'] = this.info.password;
    }

    this.organizationService
      .editMember(this.organizationId, this.member.id, data)
      .then((resp) => {
        this.loading = false;
        if (resp.result == 'success') {
          this.toastService.showSuccess(
            this.translate.instant('Member edited')
          );
          this.refresh.emit();
        } else {
          console.log(resp);

          this.toastService.showError(resp?.error.message?.friendly);
        }
        this.closeModal();
      })
      .catch((err: any) => {
        console.log(err);
        this.loading = false;
        this.closeModal();
        this.toastService.showError(
          this.translate.instant('Error editing review')
        );
      });
  }
}
