import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { Playlist } from 'src/app/interfaces/playlist';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { PermissionsService } from 'src/app/utilities/permissions.service';

@Component({
  selector: 'app-playlist-card',
  templateUrl: './playlist-card.component.html',
  styleUrls: ['./playlist-card.component.scss'],
})
export class PlaylistCardComponent implements OnInit {
  @Input() playlist: Playlist | any = null;
  @Input() featured: boolean = false;
  @Input() isFromGroup: boolean = false;
  @Output() refreshDelete = new EventEmitter<null>();

  public showUnpublished: boolean = false;
  id: number = 0;

  faPlay = faPlay;

  constructor(
    private router: Router,
    private permissionsService: PermissionsService,
    public auth: AuthService
  ) {}

  ngOnInit(): void {
    this.id = this.playlist?.id || 0;
  }
  goProfile() {
    this.router.navigate(['/playlists/' + this.playlist?.id]);
  }
  backgroundBadge(playlist: any) {
    if (playlist?.status == 'permission_needed' && playlist?.public == true) {
      return 'bg-primary';
    } else {
      if (playlist.public == false) {
        return 'bg-danger';
      }
    }
    return 'bg-success';
  }
  public deleteItem() {
    this.refreshDelete.emit(this.playlist?.id_item);
  }
  public userCanEdit(item: any) {
    if (item) {
      return (
        this.permissionsService.userIsOwner(item) ||
        this.auth.hasAnyRole(['moderator', 'super_user'])
      );
    }
    return false;
  }
}
