import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { InitService } from 'src/app/services/init.service';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { OrganizationChooseComponent } from '../../organization-choose/organization-choose.component';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  @Output() logged = new EventEmitter();
  @Input() state: string = 'login';
  @Input() openModalAgain: boolean = true;
  @Input() info: any = {};
  @Input() selfModal: NgbModalRef | undefined;
  @Input() message: string | undefined;
  public showMessage: boolean = false;

  organization: any = null;

  constructor(
    private initService: InitService,
    private router: Router,
    private orgsService: OrganizationsService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private lang: LangService
  ) {}

  ngOnInit(): void {
    this.organization = this.initService.getCurrentOrg();
  }

  closeModal() {
    if (this.openModalAgain) {
      
      this.selfModal?.close();
    }
  }

  changeLanguage(language: string): void {}
  loggedIn() {
    if (this.logged.observers.length) {
      this.selfModal?.close();
      this.logged.emit();
      return;
    }

    if (
      this.router.url.includes('courses') &&
      (this.router.url == '/' || this.router.url.includes('login'))
    ) {
      this.orgsService.getUserOrganizationsMenu().then((data) => {
        let modalRef = this.modalService.open(OrganizationChooseComponent, {
          windowClass: 'login-modal',
          backdrop: 'static',
          keyboard: false,
        });
        modalRef.componentInstance.selfModal = modalRef;
        this.selfModal?.close();
      });
    } else {
      if (this.router.url.includes('login')) {
        this.router.navigateByUrl('/');
        this.selfModal?.close();
      } else {
        window.location.reload();
      }
    }
  }
}
