<header class="">
  <div class="header-wrapper container-fluid bg-light-blue pt-5">
    <div class="container text-center pb-3">
      <div class="row justify-content-center">
        <div
          class="user-profile-image"
          [ngStyle]="{ 'background-image': '  url(' + avatarImg + ')' }"
        ></div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-10">
          <h2 class="mb-4 fw-bold user-profile-name">{{ display_name }}</h2>
        </div>
      </div>
    </div>
    <div class="inclined-bottom bg-white"></div>
  </div>
</header>
<div class="container-fluid">
  <div class="row tabs-row">
    <div class="col text-center">
      <app-movie-tabs-wrapper [main]="true">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link py-3"
            [ngClass]="{ active: listFilter == 'courses' }"
            id="courses-tab"
            (click)="getCourses()"
            type="button"
            role="tab"
          >
            {{ "user:profile:tab:courses" | translate }}
          </button>
        </li>
        <li
          class="nav-item"
          role="presentation"
          *ngIf="auth.isAuthenticated() && !orgUtils.isPublic()"
        >
          <button
            class="nav-link py-3"
            [ngClass]="{ active: listFilter == 'playlists' }"
            id="playlists-tab"
            (click)="getPlayList()"
            type="button"
            role="tab"
          >
            {{ "user:profile:tab:playlists" | translate }}
          </button>
        </li>

        <li
          class="nav-item"
          role="presentation"
          *ngIf="auth.isAuthenticated() && !orgUtils.isPublic()"
        >
          <button
            class="nav-link py-3"
            [ngClass]="{ active: listFilter == 'groups' }"
            id="groups-tab"
            (click)="getGroups()"
            type="button"
            role="tab"
          >
            {{ "user:profile:tab:groups" | translate }}
          </button>
        </li>
        <li
          class="nav-item"
          role="presentation"
          *ngIf="auth.isAuthenticated() && !orgUtils.isPublic()"
        >
          <button
            class="nav-link py-3"
            [ngClass]="{ active: listFilter == 'videos' }"
            id="videos-tab"
            (click)="getVideo()"
            type="button"
            role="tab"
          >
            {{ "user:profile:tab:videos" | translate }}
          </button>
        </li>
        <li class="nav-item" role="presentation" *ngIf="auth.isAuthenticated()">
          <button
            class="nav-link py-3"
            [ngClass]="{ active: listFilter == 'settings' }"
            id="settings-tab"
            (click)="getSetting()"
            type="button"
            role="tab"
          >
            {{ "user:profile:tab:settings" | translate }}
          </button>
        </li>
        <li class="nav-item" role="presentation" *ngIf="auth.isAuthenticated()">
          <button
            class="nav-link py-3"
            [ngClass]="{ active: listFilter == 'orders' }"
            id="settings-tab"
            (click)="getOrders()"
            type="button"
            role="tab"
          >
            {{ "admin:users:info:tab:1" | translate }}
          </button>
        </li>
      </app-movie-tabs-wrapper>
    </div>
  </div>

  <div class="tab-content" id="myTabContent">
    <div
      class="tab-pane fade show active"
      id="all"
      role="tabpanel"
      aria-labelledby="profile-tab"
    >
      <div class="container pt-3 pb-4">
        <!-- for courses -->
        <div *ngIf="listFilter == 'settings'" class="">
          <h2 class="title-user-profile">Settings</h2>
          <app-profile-settings [valor]="user"></app-profile-settings>
        </div>
        <!-- for courses -->
        <div *ngIf="listFilter == 'courses'">
          <h2 *ngIf="coursesEnrolled?.length" class="title-user-profile">
            {{ "courses:list:joined" | translate }}
          </h2>
          <app-grid
            *ngIf="coursesEnrolled?.length"
            [items]="coursesEnrolled"
            [cols]="3"
            [colsMd]="2"
          >
          </app-grid>

          <!-- Explore button -->
          <div
            [routerLink]="['/courses']"
            *ngIf="!loading"
            class="row profile-add-block explore-course-library purple"
          >
            <div class="oval col-xs-12 col-sm-12 col-lg-12 col-md-12"></div>
            <div
              class="the-text col-xs-12 col-sm-12 col-lg-12 col-md-12 padding-left-x24-11"
            >
              <h3 class="the-title">
                {{ "user:profile:explore:library" | translate }}
              </h3>
              <p
                *ngIf="
                  coursesEnrolled.length === 0 && coursesCompleted.length === 0
                "
                class="the-p"
              >
                {{ "user:profile:explore:library:text:empty" | translate }}
              </p>
              <p
                *ngIf="
                  coursesEnrolled.length > 0 || coursesCompleted.length > 0
                "
                class="the-p"
              >
                {{ "user:profile:explore:library:text:full" | translate }}
              </p>
            </div>
          </div>
          <!-- End explore button -->

          <h2
            *ngIf="coursesCompleted?.length"
            class="title-user-profile mt-5 mb-4"
          >
            {{ "user:profile:following:courses" | translate }}
          </h2>
          <app-grid
            *ngIf="coursesCompleted?.length"
            [items]="coursesCompleted"
            [cols]="3"
            [colsMd]="2"
          ></app-grid>
          <h2 *ngIf="coursesOwn?.length" class="title-user-profile mt-5 mb-4">
            {{ "user:profile:own:courses" | translate }}
          </h2>
          <app-grid
            *ngIf="coursesOwn?.length"
            [items]="coursesOwn"
            [cols]="3"
            [colsMd]="2"
          >
          </app-grid>
        </div>

        <!-- for playlsit -->
        <div *ngIf="listFilter == 'playlists'">
          <h2 *ngIf="myPlayList?.length" class="title-user-profile">
            {{ "playlist:list:my" | translate }}
          </h2>
          <app-grid
            *ngIf="myPlayList?.length"
            [items]="myPlayList"
            [cols]="3"
            [colsMd]="2"
          ></app-grid>

          <!-- Create button -->
          <div *ngIf="!currentOrganization?.visible" class="clearfix">
            <div
              (click)="createPlayList()"
              class="profile-add-block profile-add white col-xs-12 col-sm-12 col-lg-12 col-md-12"
            >
              <div class="oval col-xs-12 col-sm-12 col-lg-12 col-md-12"></div>
              <div
                class="the-text col-xs-12 col-sm-12 col-lg-12 col-md-12 padding-left-x24-11"
              >
                <h3 class="the-title">
                  {{ "user:profile:playlists:new" | translate }}
                  <!--span class="fa fa-chevron-right"></span-->
                </h3>
                <p class="the-p">
                  {{ "user:profile:playlists:new:text" | translate }}
                </p>
              </div>
            </div>
          </div>
          <!--End create button -->

          <h2 *ngIf="playListEnrolled?.length" class="title-user-profile mt-5 mb-4">
            {{ "playlist:list:enrolled" | translate }}
          </h2>
          <app-grid
            *ngIf="playListEnrolled?.length"
            [items]="playListEnrolled"
            [cols]="3"
            [colsMd]="2"
          >
          </app-grid>

          <!-- Explore button -->
          <div
            [routerLink]="['/playlists']"
            *ngIf="!currentOrganization?.visible"
            class="row profile-add-block explore-playlist-library purple"
          >
            <div class="oval col-xs-12 col-sm-12 col-lg-12 col-md-12"></div>
            <div
              class="the-text col-xs-12 col-sm-12 col-lg-12 col-md-12 padding-left-x24-11"
            >
              <h3 class="the-title">
                {{ "user:profile:playlists:explore:library" | translate }}
              </h3>
              <p class="the-p">
                {{ "user:profile:playlists:explore:library:text" | translate }}
              </p>
            </div>
          </div>
          <!-- End explore button -->
        </div>

        <!-- for groups -->
        <div *ngIf="listFilter == 'groups'">
          <h2 *ngIf="myGroups?.length" class="title-user-profile mt-5 mb-4">
            {{ "user:profile:my:groups:manage" | translate }}
          </h2>
          <app-grid
            *ngIf="myGroups?.length"
            [items]="myGroups"
            [cols]="4"
            [colsMd]="2"
          ></app-grid>

          <div class="clearfix">
            <!-- Add group -->
            <div
              (click)="createGroup()"
              class="profile-add-block profile-add white col-xs-12 col-sm-12 col-lg-12 col-md-12"
              *ngIf="!loading"
            >
              <div class="oval col-xs-12 col-sm-12 col-lg-12 col-md-12"></div>
              <div
                class="the-text col-xs-12 col-sm-12 col-lg-12 col-md-12 padding-left-x24-11"
              >
                <h3 class="the-title">
                  {{ "user:profile:groups:new" | translate }}
                  <!--span class="fa fa-chevron-right"></span-->
                </h3>
                <p class="the-p">
                  {{ "user:profile:groups:new:text" | translate }}
                </p>
              </div>
            </div>
          </div>

          <h2 *ngIf="groupsEnrolled?.length" class="title-user-profile">
            {{ "groups:list:joined" | translate }}
          </h2>
          <app-grid
            *ngIf="groupsEnrolled?.length"
            [items]="groupsEnrolled"
            [cols]="4"
            [colsMd]="2"
          >
          </app-grid>
          <!-- Explore button -->
          <div
            [routerLink]="['/groups']"
            *ngIf="!loading"
            class="row profile-add-block explore-groups purple"
          >
            <div class="oval col-xs-12 col-sm-12 col-lg-12 col-md-12"></div>
            <div
              class="the-text col-xs-12 col-sm-12 col-lg-12 col-md-12 padding-left-x24-11"
            >
              <h3 class="the-title">
                {{ "user:profile:groups:explore:library" | translate }}
              </h3>
              <p class="the-p">
                {{ "user:profile:groups:explore:library:text" | translate }}
              </p>
            </div>
          </div>
          <!-- End explore button -->
        </div>
        <!-- for videos -->
        <div *ngIf="listFilter == 'videos'">
          <h2 *ngIf="videosPublished?.length" class="title-user-profile">
            {{ "user:profile:published:videos" | translate }}
          </h2>

          <app-grid
            (refreshDelete)="refreshDelete()"
            type="video"
            *ngIf="videosPublished?.length"
            [items]="videosPublished"
            [cols]="4"
            [colsMd]="2"
          ></app-grid>
          <div class="clearfix">
            <!-- Add Video -->
            <div
              (click)="uploadVideo()"
              class="profile-add-block profile-add white col-xs-12 col-sm-12 col-lg-12 col-md-12"
              *ngIf="!loading"
            >
              <div class="oval col-xs-12 col-sm-12 col-lg-12 col-md-12"></div>
              <div
                class="the-text col-xs-12 col-sm-12 col-lg-12 col-md-12 padding-left-x24-11"
              >
                <h3 class="the-title">
                  {{ "user:profile:upload:video" | translate }}
                  <!--span class="fa fa-chevron-right"></span-->
                </h3>
                <p class="the-p">
                  {{ "user:profile:upload:video:description" | translate }}
                </p>
              </div>
            </div>
          </div>
          <h2
            *ngIf="videosUnprocessed?.length"
            class="title-user-profile mt-5 mb-4"
          >
            {{ "user:profile:unprocessed:videos" | translate }}
          </h2>

          <app-grid
            (refreshDelete)="refreshDelete()"
            type="video"
            *ngIf="videosUnprocessed?.length"
            [items]="videosUnprocessed"
            [cols]="4"
            [colsMd]="2"
          ></app-grid>
          <h2
            *ngIf="favoritesVideos?.length"
            class="title-user-profile mt-5 mb-4"
          >
            {{ "videos:section:nav:favorites" | translate }}
          </h2>

          <app-grid
            (refreshDelete)="refreshDelete()"
            type="video"
            *ngIf="favoritesVideos?.length"
            [items]="favoritesVideos"
            [cols]="4"
            [colsMd]="2"
          ></app-grid>
          <h2
            *ngIf="videosUnpublished?.length"
            class="title-user-profile mt-5 mb-4"
          >
            {{ "user:profile:unpublished:videos" | translate }}
          </h2>

          <app-grid
            (refreshDelete)="refreshDelete()"
            type="video"
            *ngIf="videosUnpublished?.length"
            [items]="videosUnpublished"
            [cols]="4"
            [colsMd]="2"
          ></app-grid>
        </div>
        <!-- for Orders -->
        <div *ngIf="listFilter == 'orders'">
          <div *ngIf="loading; else table" class="row p-5">
            <div class="justify-content-center">
              <!-- <app-loading-icon></app-loading-icon> -->
            </div>
          </div>
          <ng-template #table>
            <app-table-profile-orders
              class="my-4"
              [listOrders]="listOrders"
              [user]="user"
              (refreshTable)="getList()"              >
            </app-table-profile-orders>
            <!-- (scrolled)="loadMore()" -->
          </ng-template>
          <app-empty-content
            class="py-5"
            [primaryText]="'You have no ordered anything on MovieLearning yet.' | translate"
            *ngIf="!listOrders?.length && !loading"
          ></app-empty-content>
          <!-- <div *ngIf="loadingMore" class="row p-5">
            <div class="justify-content-center">
              <app-loading-icon></app-loading-icon>
            </div>
          </div> -->
        </div>
        <app-loading-icon
          [class.grid-loading]="!videosPublished?.length"
          *ngIf="loading"
        ></app-loading-icon>
      </div>
    </div>
  </div>
</div>
