import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from 'src/app/modals/delete-modal/delete-modal.component';
import { PlaylistsService } from 'src/app/services/playlists.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  @Output() scrolled = new EventEmitter<null>();
  @Output() refreshConversation = new EventEmitter<null>();
  @ViewChild('container') container!: ElementRef;
  @Input() items: Array<any> = [];
  @Input() playlistId: any = '';
  @Input() language = 'en';
  @Input() type = '';
  @Input() course: any;
  @Input() members: any;
  @Input() tab: string = '';
  @Input() isFromGroup: boolean = false;
  @Input() group: any = null; // for lists inside a group
  @Output() refreshDelete = new EventEmitter<null>();
  @Input() fromPlaylistProfile: boolean = false;
  colSize = 4;
  colSizeMd = 6;

  constructor(
    private toastService: ToastService,
    private ngbModal: NgbModal,
    private playlistService: PlaylistsService
  ) {}

  ngOnInit(): void {}

  @HostListener('window:scroll', ['$event'])
  onScroll(e: any) {
    if (!this.container) {
      return;
    }
    let bottomPosition =
      this.container.nativeElement.getBoundingClientRect().top +
      this.container.nativeElement.offsetHeight;
    if (bottomPosition < window.innerHeight) {
      this.scrolled.emit();
    }
  }

  refreshListConversation(event: any) {
    this.refreshConversation.emit();
  }

  public deleteItem($event: any, type: string) {
    let textTitle: string = 'groups:profile:file:delete:modal:title';
    let textMessaje: string = 'files:delete:confirm';

    let modalRef = this.ngbModal.open(DeleteModalComponent, {
      windowClass: 'default-modal',
      size: '600',
      centered: false,
    });
    modalRef.componentInstance.title = textTitle;
    modalRef.componentInstance.message = textMessaje;
    modalRef.componentInstance.response.subscribe((data: any) => {
      if ((data = 'yes')) {
        this.playlistService
          .deleteFileFromGroup($event, this.group)
          .then((resp: any) => {
            if (resp.result == 'success') {
              this.toastService.showSuccess('Successfully deleted');
              this.refreshDelete.emit($event);
            } else {
              this.toastService.showError('Something went wrong deleting');
            }
          })
          .catch((resp: any) => {
            this.toastService.showError('Something went wrong deleting');
          });
      } else {
        console.log('no');
      }
    });
  }
}
