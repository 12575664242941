import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { LangService } from '../services/lang.service';
@Pipe({
  name: 'timeAgo',
})
export class timeAgo implements PipeTransform {
  public language = 'en';
  constructor(private lang: LangService) {
    this.lang.language.subscribe((lang: any) => {
      this.language = lang || 'en';
    });
  }

  transform(value: string | undefined): string {
    const duration = moment.duration(moment().diff(moment(value)));
    const minutes = duration.asMinutes();

    let min = this.language == 'en' ? 'minute' : 'minuut';
    let hoursTranslate = this.language == 'en' ? 'hour' : 'uur';
    let daysTranslate = this.language == 'en' ? 'day' : 'dagen';

    if (minutes < 1) {
      return this.language == 'en'
        ? 'a few seconds ago'
        : 'een paar seconden geleden';
    } else if (minutes < 60) {
      return `${Math.floor(minutes)} ${min}${minutes > 1 ? 's' : ''}`;
    } else {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = Math.floor(minutes % 60);
      if (hours < 24) {
        return `${hours} ${hoursTranslate}${
          hours > 1 ? 's' : ''
        } and ${remainingMinutes} ${min}${remainingMinutes > 1 ? 's' : ''}`;
      } else {
        const days = Math.floor(hours / 24);
        const remainingHours = Math.floor(hours % 24);
        return `${days} ${daysTranslate}${
          days > 1 ? 's' : ''
        }, ${remainingHours} ${hoursTranslate}${
          remainingHours > 1 ? 's' : ''
        }, and ${remainingMinutes} ${min}${remainingMinutes > 1 ? 's' : ''}`;
      }
    }
  }
}
