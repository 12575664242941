import { CircleModalComponent } from './../../modals/courses/circle-modal/circle-modal.component';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-circle',
  templateUrl: './circle.component.html',
  styleUrls: ['./circle.component.scss'],
})
export class CircleComponent implements OnInit {
  @Output() sendName: EventEmitter<any> = new EventEmitter();

  @Input() dataCircles: any = {};
  public dataCircleParse: any = [];
  public generalInfo: string = '';
  public titleCircle: any = '';
  public level: any = '';

  constructor(private ngbModal: NgbModal, private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.dataCircleParse = JSON.parse(this.dataCircles?.json_data);

    this.titleCircle = this.dataCircleParse.title;
    this.send(this.titleCircle);
    this.generalInfo = this.dataCircleParse.general_info;
    this.level = this.dataCircleParse.level;
  }

  ngOnInit(): void {
    this.getSize();
  }
  getSize() {
    document.querySelectorAll('.ciclegraph').forEach((ciclegraph) => {
      let circles: any = ciclegraph.querySelectorAll('.circle');
      let angle = 360 - 90,
        dangle = 360 / circles.length;
      for (let i = 0; i < circles.length; ++i) {
        let circle = circles[i];
        angle += dangle;
        circle.style.transform = `rotate(${angle}deg) translate(${
          ciclegraph.clientWidth / 2
        }px) rotate(-${angle}deg)`;
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.getSize();
  }

  public openModalCircle(infoPoint: any, number: string = '') {
    if (infoPoint.fieldTitle == '') {
      return;
    }
    let modalRef = this.ngbModal.open(CircleModalComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.title = infoPoint.fieldTitle;
    modalRef.componentInstance.description = infoPoint.fieldInfo;
    modalRef.componentInstance.number = number;
  }

  public getLevel() {
    if (this.level == 0) {
      return 'ciclegraph-level-0';
    }
    if (this.level == 1) {
      return 'ciclegraph-level-1';
    }
    if (this.level == 2) {
      return 'ciclegraph-level-2';
    }
    if (this.level == 3) {
      return 'ciclegraph-level-3';
    }
    if (this.level == 4) {
      return 'ciclegraph-level-4';
    }
    if (this.level == 5) {
      return 'ciclegraph-level-5';
    }
    if (this.level == 6) {
      return 'ciclegraph-level-6';
    }
    if (this.level == 7) {
      return 'ciclegraph-level-7';
    }
    return 'ciclegraph-level-0';
  }

  public getColorLevel() {
    if (this.level == 0) {
      return '#8e8e8e';
    }
    if (this.level == 1) {
      return '#e2001a';
    }
    if (this.level == 2) {
      return '#f58220';
    }
    if (this.level == 3) {
      return '#ffcc00';
    }
    if (this.level == 4) {
      return '#41a62a';
    }
    if (this.level == 5) {
      return '#009ee0';
    }
    if (this.level == 6) {
      return '#004b93';
    }
    if (this.level == 7) {
      return '#5b1f78';
    }
    return '#8e8e8e';
  }
  send(name: string) {
    this.sendName.emit(name);
  }
}
