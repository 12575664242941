import { ToastService } from './../../services/toast.service';
import { GroupsService } from 'src/app/services/groups.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { Comment } from 'src/app/interfaces/comment';
import { UserUtilitiesService } from 'src/app/utilities/user-utilities.service';
import { PermissionsService } from 'src/app/utilities/permissions.service';
import { AuthService } from 'src/app/services/auth.service';
import * as moment from 'moment';
import { LangService } from 'src/app/services/lang.service';
import { VideoService } from 'src/app/services/video.service';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit {
  @Input() comment: Comment | null = null;
  @Input() type: string = '';
  @Input() videoId: any = '';
  @Output() refresh: EventEmitter<any> = new EventEmitter();
  @Output() refreshEdit: EventEmitter<any> = new EventEmitter();
  public editingCommentary: boolean = false;
  public loadingSpin: boolean = false;
  public commentaryEdited: any = '';
  faElipsis = faEllipsisH;
  public faCircleNorch: any = faCircleNotch;
  public language = 'en';
  constructor(
    public auth: AuthService,
    private userUtils: UserUtilitiesService,
    private groupService: GroupsService,
    private toastService: ToastService,
    private permissionsService: PermissionsService,
    private lang: LangService,
    private videoService: VideoService
  ) {
    this.lang.language.subscribe((lang: any) => {
      this.language = lang || 'en';
      moment.locale(this.language);
    });
  }

  ngOnInit(): void {
    this.commentaryEdited = this.comment?.comment;
  }

  public userCanEdit() {
    return (
      this.permissionsService.userIsOwner(this.comment) ||
      this.auth.hasAnyRole(['moderator', 'super_user'])
    );
  }

  public cancel() {
    this.editingCommentary = false;
  }
  public openEdit() {
    this.editingCommentary = true;
  }
  public editCommentary(comment: any) {
    console.log('tipo', this.comment);

    this.loadingSpin = true;
    if (
      this.type == 'commentaryConversation' ||
      this.type == 'activity_group'
    ) {
      this.groupService
        .editCommentaryConversation(
          comment.conversation_id || comment.activity_id,
          comment.id,
          this.commentaryEdited
        )
        .then((resp) => {
          this.loadingSpin = false;
          this.cancel();
          this.refreshEdit.emit(resp.data);
          this.toastService.showSuccess('Commentary edited');
        })
        .catch((err: any) => {
          this.loadingSpin = false;
          console.log(err);
          this.toastService.showError('Error editing commentary');
        });
    }

    if (this.type == 'file') {
      this.groupService
        .editCommentaryConversationFile(
          comment.file_id,
          comment.id,
          this.commentaryEdited
        )
        .then((resp) => {
          this.cancel();
          this.refreshEdit.emit(resp.data);
          this.loadingSpin = false;

          this.toastService.showSuccess('Commentary edited');
        })
        .catch((err: any) => {
          this.loadingSpin = false;
          console.log(err);
          this.toastService.showError('Error editing commentary');
        });
    }
    if (this.type == 'video') {
      this.videoService
        .editCommentaryConversation(
          this.videoId,
          comment.id,
          this.commentaryEdited
        )
        .then((resp) => {
          this.cancel();
          this.refreshEdit.emit(resp.data);
          this.loadingSpin = false;

          this.toastService.showSuccess('Commentary edited');
        })
        .catch((err: any) => {
          this.loadingSpin = false;
          console.log(err);
          this.toastService.showError('Error editing commentary');
        });
    }
  }

  public deleteCommentaryConversation(comment: any) {
    if (confirm('Are you sure you want delete the commentary?')) {
      this.loadingSpin = true;

      if (this.type == 'commentaryConversation') {
        this.groupService
          .deleteCommentaryConversation(comment.conversation_id, comment.id)
          .then((resp) => {
            this.refresh.emit();
            this.loadingSpin = false;

            this.toastService.showSuccess('Commentary Deleted');
          })
          .catch((err: any) => {
            this.loadingSpin = false;

            console.log(err);
            this.toastService.showError('Error deleting commentary');
          });
      }
      if (this.type == 'file') {
        this.groupService
          .deleteCommentaryConversationFile(comment.file_id, comment.id)
          .then((resp) => {
            this.loadingSpin = false;
            this.refresh.emit();
            this.toastService.showSuccess('Commentary Deleted');
          })
          .catch((err: any) => {
            this.loadingSpin = false;

            console.log(err);
            this.toastService.showError('Error deleting commentary');
          });
      }
    }
  }
}
