import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({
  name: 'timeLength'
})
export class TimePipe implements PipeTransform {

  transform(duration: number): unknown {
    if (!duration) {
      return '';
    }

    var tempTime = moment.duration(duration);
    var mm;
    var hh;
    var ss;
    if (tempTime.minutes()<10){
      mm='0'+tempTime.minutes();
    }
    else{
      mm=tempTime.minutes();

    }
    if (tempTime.hours()<10){
      hh='0'+tempTime.hours()+':';
    }
    else{
      hh=tempTime.hours()+':';

    }
    if (tempTime.hours()==0){
      hh='';
    }
    if (tempTime.seconds()<10){
      ss='0'+tempTime.seconds();
    }
    else{
      ss=tempTime.seconds();

    }

    var y = hh +mm+':'+ss;
    return y;
  }

}
