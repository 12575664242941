import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileSettingsComponent } from './profile-settings/profile-settings.component';
import { ProfileRoutingModule } from './profile-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { DeleteAccountComponent } from './delete-account/delete-account.component';


@NgModule({
  declarations: [
    ProfileComponent,
    ProfileSettingsComponent,
    DeleteAccountComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ProfileRoutingModule,
    SharedModule,
    TranslateModule,
    ReactiveFormsModule,
  ],
})
export class ProfileModule { }
