import { Component, OnInit } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Organization } from 'src/app/interfaces/organization';
import { InitService } from 'src/app/services/init.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public currentLang: string = 'en';
  public links: any = {
    aboutEn: 'https://www.movielearning.com/en/movielearning-foundation',
    aboutNl: 'https://www.movielearning.com/nl/stichting-movielearning',
    termsEn: 'https://www.movielearning.com/en/terms-service',
    termsNl: 'https://www.movielearning.com/nl/algemene-voorwaarden',
    privacyEn: 'https://www.movielearning.com/en/privacy-policy',
    privacyNl: 'https://www.movielearning.com/nl/privacy-verklaring',
    disclaimerEn: 'https://www.movielearning.com/en/disclaimer',
    disclaimerNl: 'https://www.movielearning.com/nl/disclaimer',
    contactEn: 'https://www.movielearning.com/en/contact',
    contactNl: 'https://www.movielearning.com/nl/contact',
  };
  public currentOrganization: Organization | null = null;

  constructor(
    private lang: LangService,
    private gtmService: GoogleTagManagerService,
    private initService: InitService
  ) {
    this.lang.language.subscribe((lang) => {
      this.currentLang = lang || 'en';
    });
  }

  ngOnInit(): void {
    this.currentOrganization = this.initService.getCurrentOrg();
  }
  sendGtm() {
    if (this.currentOrganization?.slug == 'courses') {
      const gtmTag = {
        event: 'contact_us',
      };
      console.log('Sending GTM', gtmTag);
      this.gtmService.pushTag(gtmTag);
    }
  }
}
