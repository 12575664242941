import { Component, OnInit } from '@angular/core';
import { CheckoutService } from '../checkout.service';

@Component({
  selector: 'app-gift-tab',
  templateUrl: './gift-tab.component.html',
  styleUrls: ['./gift-tab.component.scss']
})
export class GiftTabComponent implements OnInit {

  constructor(
    public checkout: CheckoutService,
  ) { }

  ngOnInit(): void {
  }

}
