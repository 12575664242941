import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { GroupsService } from 'src/app/services/groups.service';
import { ToastService } from 'src/app/services/toast.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Course } from 'src/app/interfaces/course';
import { CreateReviewModalComponent } from '../create-review-modal/create-review-modal.component';
@Component({
  selector: 'app-course-review-modal',
  templateUrl: './course-review-modal.component.html',
  styleUrls: ['./course-review-modal.component.scss'],
})
export class CourseReviewModalComponent implements OnInit {
  faTimes = faTimes;
  @Input() selfModal: NgbModalRef | null = null;
  @Input() title: String = '';
  @Input() course: Course | any;
  loading = false;

  constructor(
    private groupsService: GroupsService,
    private toastService: ToastService,
    private router: Router,
    private translate: TranslateService,
    private ngbModal: NgbModal
  ) {}

  ngOnInit(): void {}

  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  createReview() {
    this.closeModal();
    let modalRef = this.ngbModal.open(CreateReviewModalComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
    });
    modalRef.componentInstance.videoId = this.course;
    modalRef.componentInstance.course = this.course;
    modalRef.componentInstance.selfModal = modalRef;
  }
}
