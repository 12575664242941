<vg-player *ngIf="flavor" (onPlayerReady)="playerReady($event)">
    <vg-overlay-play></vg-overlay-play>
    <vg-buffering></vg-buffering>


    <vg-controls>
        <vg-play-pause></vg-play-pause>

        <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

        <microclip-scrub-bar [startTime]="microclip?.start_time" [endTime]="microclip?.end_time"></microclip-scrub-bar>

        <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>

        <vg-track-selector></vg-track-selector>
        <vg-mute></vg-mute>
        <vg-volume></vg-volume>

        <vg-fullscreen></vg-fullscreen>
    </vg-controls>

    <video [vgMedia]="$any(media)" #media id="singleVideo" preload="auto" crossorigin>
        <source [src]="flavor.src" type="video/mp4">
    </video>
</vg-player>