<div
  id="activity-{{ activity.id }}"
  class="activity-list-item block margin-bottom"
  ng-class="{ focus: focus }"
>
  <div class="activity-list-item-header clearfix">
    <app-user-icon
      class="pull-left"
      container-class="avatar"
      [user]="getUser()"
    ></app-user-icon>
    <div class="activity-list-item-header-text">
      <h3>
        <span class="user-name">{{
          getUser().display_name || activity.user.display_name
        }}</span>
        <span class="activity-type">
          {{
            "groups:profile:activity:" + activity.type_activity | translate
          }}</span
        >
      </h3>
      <span
        class="time"
        am-time-ago="activity.created_at"
        style="color: #999999; font-weight: 500; font-size: 14px"
        >{{ activity.created_at | amTimeAgo }}</span
      >
    </div>
  </div>

  <app-group-activity-item-comment
    (refreshConversation)="refresh($event)"
    [tab]="tab"
    [activities]="activity"
    *ngIf="
      activity.type_activity == 'comment_conversation' ||
      activity.type_activity == 'comment_video' ||
      activity.type_activity == 'comment_file'
    "
  ></app-group-activity-item-comment>
  <!-- <app-group-activity-item-comment [activities]="activity" *ngIf="activity.type_activity=='add_conversation'"></app-group-activity-item-comment> -->
  <!-- <app-video-card [video]="activity.entity" *ngIf="activity.type_activity=='add_video'"></app-video-card> -->
  <app-group-activity-item-video
    type="activity"
    [activities]="activity"
    *ngIf="activity.type_activity == 'add_video'"
  ></app-group-activity-item-video>
  <app-group-activity-item-course
    [activities]="activity"
    *ngIf="activity.type_activity == 'add_course'"
  ></app-group-activity-item-course>
  <app-group-activity-item-playlist
    [activities]="activity"
    *ngIf="activity.type_activity == 'add_playlist'"
  ></app-group-activity-item-playlist>
  <app-file-card
    [tap]="tab"
    type="activity"
    [activities]="activity"
    *ngIf="activity.type_activity == 'upload_file'"
  ></app-file-card>
</div>

<!-- <div class="activity-list col-xs-12 col-md-8 col-lg-8 col-sm-12 no-padding-765" style="order: 1;">
    <empty-content
           block-style="true"
           class="activity-profile-empty-content"
           ng-show="showEmptyContent"
           primary-text="'groups:profile:tab:activity:empty' | translate"
           secondary-text="'groups:profile:tab:activity:empty:subtitle' | translate"
       ></empty-content>

   <activity-item
       ng-repeat="activity in activities"
       activity="activity"
       group="group"
       focus="activityIdFocus == activity.id"
   ></activity-item>
</div> -->
