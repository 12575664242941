import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-new-privacy',
  templateUrl: './new-privacy.component.html',
  styleUrls: ['./new-privacy.component.scss']
})
export class NewPrivacyComponent implements OnInit {

  faTimes = faTimes;
  @Input() description: string = '';
  @Input() selfModal: NgbModalRef | null = null;
  @Input() title: String = '';
  @Output() outputEvent = new EventEmitter<any>();
  loadSpin: boolean = false;
  faCircleNorch: any = faCircleNotch;
  currentLang: string = 'en';
  constructor(
    private lang: LangService
  ) {
    this.lang.language.subscribe( lang => {
      this.currentLang = lang || 'en';
    });
  }

  ngOnInit(): void {}

  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
}
