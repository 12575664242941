<div class="modal-close" (click)="cancelModal()">
  <i class="fas fa-times icon me-1"></i>
  <span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">Create new team profile</h4>
</div>
<div class="modal-body">
  <form [formGroup]="form">


    <div *ngIf="!teamAlreadyCreated">
      <div class="card">
        <div class="card-header">
          Add existing members
        </div>
        <div class="card-body" formGroupName="users">
          <div class="row p-2" *ngFor="let member of teamUsers">
            <div class="col-auto">
              <app-user-icon [user]="member.user"></app-user-icon>
            </div>
            <div class="col pt-2">{{ member.user.display_name }}<span *ngIf="member.user.dh_profiles.length > 0"
                class="badge bg-success ms-2">{{ member.user.dh_profiles.length }} profiles</span></div>
            <div class="col-auto pt-2">
              <input type="checkbox" [formControlName]="member.id" />
            </div>
          </div>
        </div>
      </div>
      <div class="form-group mb-3">
        <label for="message" class="mb-2 form-label">Add message to include in your invite</label>
        <textarea class="form-control" id="message" formControlName="message"></textarea>
      </div>
    </div>
    <div *ngIf="teamAlreadyCreated" class="form-group m-5 text-center">
      <div> It is not possible to create a team profile because there is another one for this date.
      </div>
    </div>



  </form>
</div>
<div class="row justify-content-end modal-footer">
  <div class="row justify-content-end text-center">
    <div class="col-auto">
      <button type="button" class="btn btn-outline-secondary fs-15 text-nowrap" (click)="cancelModal()">
        {{ "Cancel" | translate }}
      </button>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-primary text-nowrap" (click)="createProfile()"
        [ngClass]="{disabled: creating||teamAlreadyCreated || !hasUserSelected() }">
        {{ "create" | translate }}<i *ngIf="creating" class="fa fa-circle-notch fa-spin ms-2"></i>
      </button>
    </div>
  </div>
</div>