import { GoBackPlaylistComponent } from './../../modals/playlist/go-back-playlist/go-back-playlist.component';
import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Video } from 'src/app/interfaces/video/video';
import { AuthService } from 'src/app/services/auth.service';
import { LangService } from 'src/app/services/lang.service';
import { TranslateService } from '@ngx-translate/core';
import { PlaylistsService } from 'src/app/services/playlists.service';
import { ToastService } from 'src/app/services/toast.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { DeleteModalComponent } from 'src/app/modals/delete-modal/delete-modal.component';
import { PermissionsService } from 'src/app/utilities/permissions.service';

@Component({
  selector: 'app-sub-header-info',
  templateUrl: './sub-header-info.component.html',
  styleUrls: ['./sub-header-info.component.scss'],
})
export class SubHeaderInfoComponent implements OnInit, OnChanges {
  ///////////////////////////
  /// Atributes for subHeader
  /////////////////////////
  @Input() type: string = '';
  @Input() editTitle: string | undefined;
  @Input() allData: any;
  @Input() id: number = 0;
  @Output() closeUpublished: EventEmitter<any> = new EventEmitter();
  @Output() closeSubheader: EventEmitter<any> = new EventEmitter();
  @Output() openChangeOwner = new EventEmitter<string>();
  @Input() showButton: boolean = true;
  @Input() notSave: any = undefined;

  public update: string = '';

  constructor(
    public auth: AuthService,
    private router: Router,
    private lang: LangService,
    private playlistService: PlaylistsService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private ngbModal: NgbModal,
    private route: ActivatedRoute,
    private permissionsService: PermissionsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('allData')) {
      if (this.type == 'video') {
        this.updateVideoInfo();
      }
    }
  }

  ngOnInit(): void {
    this.initSubHeader();
  }

  public userCanChangeOwner() {
    return (
      this.permissionsService.userIsOwner(this.allData) ||
      this.auth.hasAnyRole(['moderator', 'super_user'])
    );
  }

  public deletePlaylist() {
    let modalRef = this.ngbModal.open(DeleteModalComponent, {
      windowClass: 'default-modal',
      size: '600',
      centered: false,
    });
    modalRef.componentInstance.title = 'playlist:edit:delete';
    modalRef.componentInstance.message = 'playlist:edit:delete:subtext';
    modalRef.componentInstance.response.subscribe((data: any) => {
      if ((data = 'yes')) {
        console.log('yes');
        this.playlistService

          .deletePlaylist(this.id.toString())
          .then((resp: any) => {
            if (resp.result == 'success') {
              this.toastService.showSuccess('Successfully deleted playlist');
              this.router.navigate(['/playlists']);
            } else {
              this.toastService.showError(
                'Something went wrong deleting playlist'
              );
            }
          })
          .catch((resp: any) => {
            this.toastService.showError(
              'Something went wrong deleting playlist'
            );
          });
      } else {
        console.log('no');
      }
    });
  }

  public initSubHeader() {
    if (this.allData) {
      if (this.type == 'video') {
        this.lang.language.subscribe((lang) => {
          if (lang) {
            let info = (this.allData as Video).info.find(
              (i) => i.language == lang
            );
            this.editTitle = info?.name;
          }
        });
        this.update = this.allData.updated_at;
      } else {
        this.editTitle = this.allData.name;
        this.update = this.allData.updated_at;
      }
    }
  }

  updateVideoInfo() {
    if (this.type == 'video') {
      this.lang.language.pipe(first()).subscribe((lang) => {
        if (lang) {
          let info = (this.allData as Video).info.find(
            (i) => i.language == lang
          );
          this.editTitle = info?.name;
        }
      });
      this.update = this.allData.updated_at;
    }
  }

  public goToEdit() {
    if (this.type === 'course') {
      this.router.navigate(['/courses', 'edit', this.id]);
    }
    if (this.type === 'playlist') {
      this.router.navigate(['/playlists', 'edit', this.id]);
    }
  }
  public goBack() {
    if (this.type === 'course') {
      this.router.navigate(['/courses', this.id]);
    }
    if (this.type === 'playlist') {
      if (this.allData.published_at != null) {
        this.router.navigate(['/playlists', this.id]);
      } else {
        this.router.navigate(['/playlists', this.id]);
      }
    }
    if (this.type === 'group') {
      this.router.navigate(['/groups', this.id]);
    }
    if (this.type === 'video') {
      if (this.route.snapshot.queryParamMap.get('playlist_id')) {
        this.router.navigate(
          ['/playlists', this.route.snapshot.queryParamMap.get('playlist_id')],
          {
            queryParams: {
              video_id: this.id,
            },
          }
        );
      } else {
        let editId = this.route.snapshot.queryParamMap.get('playlistEdit_id');
        if (editId) {
          this.router.navigate(['/playlists', 'edit', editId], {
            queryParams: {
              video_id: this.id,
            },
          });
        } else {
          this.router.navigate(['/video', this.id]);
        }
      }
    }
  }

  goToRequests() {
    this.router.navigate(['/playlists', 'edit', this.id], {
      queryParams: {
        tab: 'requests',
      },
    });
  }

  // public goBackPlaylist() {
  //   let modalRef = this.ngbModal.open(GoBackPlaylistComponent, {
  //     windowClass: 'default-modal',
  //     size: '600',
  //     centered: false,
  //   });
  //   modalRef.componentInstance.fromSubheader = true;
  //   modalRef.componentInstance.closeUpublished.subscribe((data: any) => {
  //     // this.closeUpublished.emit();
  //     this.closeSubheader.emit();
  //   });
  // }
  public changeOwner() {
    this.openChangeOwner.emit();
  }
}
