import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PlaylistsService } from 'src/app/services/playlists.service';
import { ToastService } from 'src/app/services/toast.service';
 
@Component({
  selector: 'app-request-members',
  templateUrl: './request-members.component.html',
  styleUrls: ['./request-members.component.scss'],
})
export class RequestMembersComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() playlistId: any;
  @Output() refresh = new EventEmitter<any>();
  public faTimes = faTimes;

  pendingRequests: Array<any> = [];
  loadingRequests = true;
  loadingConfirmRequest = false;
  loadingDenyRequest = false;
  constructor(
    private playlistService: PlaylistsService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.getPendingRequests();
  }
  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();  
  }
  public getPendingRequests() {
    this.loadingRequests = true;
    this.playlistService
      .getPendingRequests(this.playlistId)
      .then((pr: Array<any>) => {
        this.pendingRequests = pr;
        this.loadingRequests = false;
        for (let i = 0; i < this.pendingRequests.length; i++) {
          this.pendingRequests[i].isLoading = false;
        }
      });
  }
  public confirmRequest(id: number, index: number) {
    this.loadingConfirmRequest = true;
    this.pendingRequests[index].isLoading = true;

    this.playlistService
      .confirmAccessRequest(this.playlistId, id)
      .then((message: string) => {
        this.loadingConfirmRequest = false;

        this.toastService.showSuccess(message);
        //this.pendingRequests[index].isLoading = false;
        this.pendingRequests.splice(
          this.pendingRequests.findIndex((r) => r.id == id),
          1
        );

        this.refresh.emit();
      });
  }

  public denyRequest(id: number, index: number) {
    this.loadingConfirmRequest = true;
    this.pendingRequests[index].isLoading = true;

    this.playlistService
      .denyAccessRequest(this.playlistId, id)
      .then((message: string) => {
        this.loadingConfirmRequest = false;

        this.toastService.showSuccess(message);
        this.pendingRequests.splice(
          this.pendingRequests.findIndex((r) => r.id == id),
          1
        );
        this.pendingRequests[index].isLoading = false;
      });
  }
}
