import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MainComponent } from 'src/app/modals/login/main/main.component';
import { LangService } from 'src/app/services/lang.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(
    private ms: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastService,
    private lang: LangService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      const errorMsg = params['errormsg'];
      console.log(errorMsg);
      if (errorMsg == 'InvitationAlreadyUsed') {
        this.toastr.showError(
          'The invitation was already used, please login to access'
        );
      }
      const currentUrl = this.router.url;
      this.openLoginModal(currentUrl.includes('signup') ? 'register' : 'login');
    });

    // this.activatedRoute.paramMap.subscribe((params:any) => {
    //   console.log(params['lang'])

    // });
    this.activatedRoute.params.subscribe(({ lang }) => {
      console.log(lang);
      setTimeout(() => {
        if (lang) {
          if (lang == 'nl') {
            this.lang.setLanguage('nl');
          }
          if (lang == 'en') {
            this.lang.setLanguage('en');
          }
        }
      }, 150);
    });
  }

  openLoginModal(type = 'login') {
    let modalRef = this.ms.open(MainComponent, {
      windowClass: 'login-modal',
      backdrop: 'static',
      keyboard: false,
      backdropClass: 'modal-backdrop-transparent',
      centered: true,
    });
    modalRef.componentInstance.state = type;
    modalRef.componentInstance.openModalAgain = false;
    modalRef.componentInstance.selfModal = modalRef;
    if (localStorage.getItem('remember_url')) {
      modalRef.componentInstance.logged.subscribe(() => {
        const url = localStorage.getItem('remember_url');
        if (url) {
          localStorage.removeItem('remember_url');
          this.router.navigateByUrl(url);
        }
      });
    }
  }
}
