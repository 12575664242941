<div class="table-responsive-sm mb-4">
  <table class="table table-hover" #container>
    <thead>
      <tr class="" [ngClass]="{ 'custom-border': true }">
        <th (change)="checkAllCheckBox($event)">
          <input
            *ngIf="hideCheck"
            class="m-3 form-check-input"
            type="checkbox"
          />
        </th>
        <th *ngFor="let item of headers" scope="col" class="my-3 align-middle">
          <span
            (click)="orderByColumn(item.name)"
            [ngClass]="{
              order:
                item.name == 'Name' ||
                item.name == 'Created' ||
                item.name == 'Last Played'
            }"
          >
            {{ item.show ? (item.name | translate) : "" }}
          </span>
        </th>
        <th class="text-center" scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="type == 'videos'">
        <tr
          [ngClass]="{ 'custom-border': true }"
          *ngFor="let item of listVideos; let i = index"
        >
          <td class="checkbox">
            <input
              class="m-3 form-check-input"
              type="checkbox"
              [(ngModel)]="listVideos[i].checked"
              name="check"
              value="{{ item.id }}"
            />
          </td>
          <td class="align-middle">
            <img
              [src]="item.source?.thumbnail_url"
              alt=""
              sizes=""
              srcset=""
              height="45"
              width="60"
              alt="img"
              onerror="this.src='assets/img/groups/icons/list.png'"
            />
          </td>
          <td class="align-middle">{{ item.info[0]?.name }}</td>
          <td class="align-middle">
            {{ item.user?.display_name }}
          </td>
          <td class="align-middle date">
            {{ item?.created_at | date : "shortDate" }}
          </td>
          <td class="align-middle date">
            {{ item?.last_view || "" | date : "shortDate" }}
          </td>
          <td
            class="align-middle"
            [ngClass]="{
              published: item.published == true,
              'text-danger': item.published == false
            }"
          >
            {{ item.published ? "Published" : "Unpublished" }}
          </td>
          <td colspan="3" class="align-middle text-center p-3">
            <div
              ngbDropdown
              class="d-inline-block float_right"
              placement="bottom-right"
            >
              <button
                type="button"
                class="btn btn-outline-primary"
                id="dropdownBasic1"
                ngbDropdownToggle
              >
                <i class="fa fa-ellipsis-h"></i>
              </button>
              <div
                ngbDropdownMenu
                aria-labelledby="dropdownBasic1"
                class="mt-1"
              >
                <button
                  [routerLink]="['/video/edit', item?.id]"
                  ngbDropdownItem
                >
                  Edit
                </button>
                <button
                  (click)="deleteVideModal(item?.id, i)"
                  class="text-danger"
                  ngbDropdownItem
                >
                  Delete
                </button>
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="type == 'members'">
        <tr
          [ngClass]="{ 'custom-border': true }"
          *ngFor="let member of listMembers; let i = index"
        >
          <td>
            <input
              *ngIf="hideCheck"
              type="checkbox"
              [(ngModel)]="listMembers[i].checked"
              name="check"
              (change)="selectMember(member)"
              value="{{ member.id }}"
              class="m-3 form-check-input"
            />
          </td>
          <td class="align-middle">
            <app-user-icon size="medium" [user]="member.user"></app-user-icon>
          </td>
          <td class="align-middle">
            {{ member.user?.name || member.name }}
          </td>
          <td class="align-middle">
            {{ member.user?.email || member.email }}
          </td>
          <td *ngIf="headers[3].nickname == 'created_at'" class="align-middle">
            {{ member?.created_at | date : "dd LLL YYYY" }}
          </td>
          <td class="align-middle user-status">
            {{ member?.type_custom | titlecase }}
          </td>
          <td
            *ngIf="member?.user; else status"
            class="align-middle user-status"
          >
            {{ member?.user?.account_deactivated ? "Inactive" : "Active" }}
          </td>
          <ng-template #status>
            <td class="align-middle user-status">
              {{ member.status | titlecase  }}
            </td>
          </ng-template>
          <td colspan="3" class="align-middle text-center">
            <div
              ngbDropdown
              class="d-inline-block float_right"
              placement="bottom-right"
            >
              <button
                type="button"
                class="btn btn-outline-primary"
                id="dropdownBasic1"
                [disabled]="!hasRole('super_user') && (member?.type_custom == 'Administrator'  || member?.type_custom == 'moderator' )"
                ngbDropdownToggle
              >
             
                <i class="fa fa-ellipsis-h"></i>
                <!-- <fa-icon
                *ngIf="saveLoading"
                class="ms-2"
                [icon]="faCircleNotch"
                [spin]="true"
              ></fa-icon> -->
              </button>
              <div
                ngbDropdownMenu
                aria-labelledby="dropdownBasic1"
                class="mt-1"
              >
                <ng-container *ngIf="currentTab == 'allMembers'">
                  <ng-container *ngIf="hasRoles(['super_user'])">
                    <ng-container
                      *ngIf="member?.type != 'moderator'; else moderator"
                    >
                      <button
                        (click)="changeRole(member?.user_id, i, 'moderator')"
                        ngbDropdownItem
                      >
                        Make Moderator
                      </button>
                    </ng-container>
                    <ng-template #moderator>
                      <button
                        (click)="changeRole(member?.user_id, i, 'user')"
                        ngbDropdownItem
                      >
                        Remove moderator rights
                      </button>
                    </ng-template>

                    <button
                      (click)="changeRole(member?.user_id, i, 'team_trainer')"
                      ngbDropdownItem
                    >
                      Make Trainer
                    </button>
                  </ng-container>

                  <button
                    (click)="resetPassword(member?.user?.email)"
                    ngbDropdownItem
                  >
                    Reset password
                  </button>
                  <button (click)="openEdit(member)" ngbDropdownItem>
                    {{ "admin:users:btn:edit" | translate }}
                  </button>
                  <button
                    (click)="removeFromPlatform(member?.user_id)"
                    class="text-danger"
                    ngbDropdownItem
                  >
                    {{
                      "organization:members:action:remove_from_organization"
                        | translate
                    }}
                  </button>

                  <button
                    (click)="
                      deactivateMember(
                        member?.user_id,
                        i,
                        member?.user?.account_deactivated
                      )
                    "
                    class="text-danger"
                    ngbDropdownItem
                  >
                    {{
                      member?.user?.account_deactivated
                        ? "Activate"
                        : "Deactivate"
                    }}
                  </button>
                  <button
                    (click)="
                      goToDelete(member?.user_id, member?.user?.username)
                    "
                    class="text-danger"
                    ngbDropdownItem
                  >
                    Delete account
                  </button>
                </ng-container>
                <ng-container
                  *ngIf="
                    currentTab == 'pendingInvitation' ||
                    currentTab == 'failedInvitation'
                  "
                >
                  <button
                    ngbDropdownItem
                    (click)="goToResendInvitation(member)"
                  >
                    {{
                      "organization:members:resend:invitation:menu" | translate
                    }}
                  </button>
                  <button
                    class="text-danger"
                    ngbDropdownItem
                    (click)="goToDeleteInvitation(member)"
                  >
                    {{
                      "organization:members:delete:invitation:menu" | translate
                    }}
                  </button>
                </ng-container>
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="type == 'courses'">
        <tr
          [ngClass]="{ 'custom-border': true }"
          *ngFor="let member of listCourses; let i = index"
        >
          <td>
            <input
              *ngIf="hideCheck"
              type="checkbox"
              [(ngModel)]="listCourses[i].checked"
              name="check"
              (change)="selectMember(member)"
              value="{{ member.id }}"
              class="m-3 form-check-input"
            />
          </td>
          <td class="justify-content-center">
            <img
              class="img-course"
              [src]="member?.coverPathUrlMedium"
              alt="img"
              onerror="this.src='assets/img/groups/icons/list.png'"
            />
          </td>
          <td class="title-course">
            {{ member.name }}
            <div class="short-description">
              {{ member?.short_description }}
            </div>
          </td>
          <td class="align-middle">
            {{ member?.users_count_accepted }}
            <div (click)="openModalView(member)" class="view">
              {{ "View" | translate }}
            </div>
          </td>
          <td class="align-middle">
            <fa-icon
              class="icon me-1"
              [icon]="faStar"
              [styles]="{ color: 'yellow' }"
              size="1x"
            ></fa-icon>
            <span *ngIf="member.rating">
              {{ member?.rating | number : "1.0-2" }}
            </span>
            <span *ngIf="!member.rating">
              {{ 0 }}
            </span>
          </td>
          <td class="align-middle">
            {{ member?.reviews_count || 0 }}
          </td>
          <td class="align-middle">
            <app-progress-bar
              [progress]="member?.summaryEnrolled?.progress_average * 100"
              [showLabelText]="false"
              [labelPosition]="'top'"
            ></app-progress-bar>
          </td>
          <td class="align-middle">
            <app-progress-bar
              [progress]="member?.summaryEnrolled?.completed_average * 100"
              [showLabelText]="false"
              [labelPosition]="'top'"
            ></app-progress-bar>
          </td>
          <td colspan="3" class="align-middle text-center">
            <div
              ngbDropdown
              class="d-inline-block float_right"
              placement="bottom-right"
            >
              <button
                type="button"
                class="btn btn-outline-primary"
                id="dropdownBasic1"
                ngbDropdownToggle
              >
                <i class="fa fa-ellipsis-h"></i>
                <!-- <fa-icon
              *ngIf="saveLoading"
              class="ms-2"
              [icon]="faCircleNotch"
              [spin]="true"
            ></fa-icon> -->
              </button>
              <div
                ngbDropdownMenu
                aria-labelledby="dropdownBasic1"
                class="mt-1"
              >
                <button (click)="goToCourse(member)" ngbDropdownItem>
                  Go to course
                </button>
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
