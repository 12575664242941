import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-search-courses',
  templateUrl: './search-courses.component.html',
  styleUrls: ['./search-courses.component.scss'],
})
export class SearchCoursesComponent implements OnInit {
  @Input() placeholder: string = 'admin:users:modal:discountProfile:label:5:placeholder';
  @Input() type: string = '';
  @Input() filterByOrganization: boolean = true;
  @Output() searchEvent = new EventEmitter<any>();
  @Input() usersSelected: Array<any> = [];

  searchTxt = '';
  courses: any;
  ifIsValidToInvite: boolean = false;

  usersList: Array<any> = [];
  timer: any;
  loading = false;
  delaySearch = false;
  constructor(
    private adminService: AdminService,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {}
  onSearch() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.callSearch();
    }, 1000);
  }

  public callSearch() {
    this.loading = true;
    this.adminService.searchCoursesOnlyPublic(this.searchTxt).then((resp) => {
      this.courses = resp.data.collections;
      this.loading = false;
    });
  }
  onAdd(user: any) {
    this.searchTxt = '';
    this.courses = [];
    this.searchEvent.emit(user);
  }

  onRemove(index: any) {
    this.usersSelected.splice(index, 1);
    this.searchEvent.emit(this.usersSelected);
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.courses = [];
    }
  }
}
