import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/services/admin.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-remove-from-platform',
  templateUrl: './remove-from-platform.component.html',
  styleUrls: ['./remove-from-platform.component.scss'],
})
export class RemoveFromPlatformComponent implements OnInit {
  @Output() refresh = new EventEmitter<any>();
  @Output() refreshEdit = new EventEmitter<any>();
  @Input() selfModal: NgbModalRef | null = null;
  @Input() user: any;
  @Input() data: any;
  @Input() avatar: any;
  public faCircleNorch: any = faCircleNotch;
  public faTimes = faTimes;
  public loading = false;
  constructor(
    private adminService: AdminService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    console.log('esta es la data ', this.data);
  }
  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  public remove() {
    this.loading = true;
    this.adminService
      .removeFromPlatform(this.data.id, this.user.id)
      .then((resp: any) => {
        if (resp.result == 'success') {
          this.loading = false;
          this.toastService.showSuccess('The user has been removed');
          this.closeModal();
          this.refreshEdit.emit();
        } else {
          this.toastService.showError('Error');
        }
      })
      .catch((error) => {
        this.loading = false;
        this.toastService.showError(error);
      });
  }
}
