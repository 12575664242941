import { Component, Input, OnInit } from '@angular/core';
import { Output, EventEmitter,  ElementRef,
  ViewChild, } from '@angular/core';
import { faClock  } from '@fortawesome/free-regular-svg-icons';
import { CourseUtilitiesService } from 'src/app/utilities/course-utilities.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationsService } from 'src/app/services/organizations.service';

@Component({
  selector: 'app-review-list-card',
  templateUrl: './review-list-card.component.html',
  styleUrls: ['./review-list-card.component.scss']
})
export class ReviewListCardComponent implements OnInit {
	@Input() review: any | undefined;
	@Input() itemSelect: any;

  @Output() selectItem = new EventEmitter<number>();
  @Output() deleteReview = new EventEmitter<number>();

  loading = false;
  title = '';
  description = '';
  language: String = 'en';
  faClock = faClock;
  @ViewChild('removecontent') removecontent: ElementRef | undefined;
  constructor(
    private cu: CourseUtilitiesService,
    private os: OrganizationsService,
    private ms: NgbModal,
  ) {  }

  ngOnInit(): void {}

  select() {
    if (this.review.adding){return} ///si ya esta agregado, entonces no dejo seleccionar
   
    this.selectItem.emit(this.review?.id);
    
  }
 

  deleteItem( ) {
    // Open content delete confirm modal
    let modal = this.ms.open(this.removecontent);
    modal.result.then(() => {
      console.log('aca va el remove',this.review);
      this.review.adding=false;
      this.deleteReview.emit(this.review.id);
      // let params = {
      //   id: section.id,
      //   content_id: this.content.id,
      // };

      // this.os.deleteHomepageContent(params).then((res) => {
      //   this.toastr.showSuccess('Content deleted');
      //   this.deleted.emit();
      // });
    });
  }
getContentName() {
    var name = '';

    

    return name;
  }
  getImage() {
    return this.cu.getLessonThumbnail(this.review!);
  }
}
