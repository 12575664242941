import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';

import {
  faBuilding,
  faPlus,
  faSearch,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Organization } from 'src/app/interfaces/organization';
import { User } from 'src/app/interfaces/user';
import { AuthService } from 'src/app/services/auth.service';
import { InitService } from 'src/app/services/init.service';
import { LangService } from 'src/app/services/lang.service';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { OrganizationUtilitiesService } from 'src/app/utilities/organization-utilities.service';
import { environment } from 'src/environments/environment';
import { CreateCourseModalComponent } from 'src/app/modals/courses/create-modal/create-modal.component';
import { CreatePlayListModalComponent } from 'src/app/modals/playlist/create-modal/create-modal.component';
import { CreateGroupModalComponent } from 'src/app/modals/group/create-modal/create-modal.component';
import { VideoUploadComponent } from 'src/app/modals/video-edit/video-upload/video-upload.component';
import { MainComponent } from 'src/app/modals/login/main/main.component';
import { VideoService } from 'src/app/services/video.service';
import { OnboardingModalComponent } from 'src/app/modals/dh/onboarding-modal/onboarding-modal.component';
import { DhprofileService } from 'src/app/services/dhprofile.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';
import { CustomConfirmComponent } from 'src/app/modals/custom-confirm/custom-confirm.component';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  @Input() showItems: boolean = true;

  faSearch = faSearch;
  faBuilding = faBuilding;
  faPlus = faPlus;
  faUser = faUser;
  envs = environment;
  showInterrogant: boolean = false;
  user: any = null;

  language = 'en';

  organizations: Array<Organization> = [];
  currentOrg: Organization | null = null;

  constructor(
    private modalService: NgbModal,
    private authService: AuthService,
    private dhprofileService: DhprofileService,
    private lang: LangService,
    private orgsService: OrganizationsService,
    private initService: InitService,
    public orgUtils: OrganizationUtilitiesService,
    private ngbModal: NgbModal,
    private videoService: VideoService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private toastService: ToastService,
    private translateService: TranslateService,
    private usersService: UsersService
  ) {
    this.authService.user.subscribe((userData) => {
      this.user = userData;

      this.orgsService.getUserOrganizationsMenu().then((organizations) => {
        this.organizations = organizations;
      });

      this.currentOrg = this.orgsService.getCurrent();
    });
    this.lang.language.subscribe((lang) => {
      this.language = lang || 'en';
    });
    this.router.events.subscribe((event) => {
      const currentPath = this.location.path();
      if (currentPath.includes('multi-profile')) {
        this.showInterrogant = true;
      } else {
        this.showInterrogant = false;
      }
    });
  }

  ngOnInit(): void {
    const currentPath = this.location.path();
    if (currentPath.includes('multi-profile')) {
      this.showInterrogant = true;
    } else {
      this.showInterrogant = false;
    }
  }

  changeLanguage(language: string): void {
    this.lang.setLanguage(language);
    this.language = language;
    if (this.isAuthenticated()) {
      this.onSaveProfile(language);
    }
  }

  createCourse() {
    let modalRef = this.ngbModal.open(CreateCourseModalComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
      centered: true,
    });
  }

  createPlayList() {
    let modalRef = this.ngbModal.open(CreatePlayListModalComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
      centered: true,
    });
  }

  createGroup() {
    let modalRef = this.ngbModal.open(CreateGroupModalComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
      centered: true,
    });
  }

  uploadVideo() {
    let modalRef = this.ngbModal.open(VideoUploadComponent, {
      windowClass: 'default-modal',
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.selfModal = modalRef;
    modalRef.componentInstance.refresh.subscribe((data: any) => {
      this.videoService.sendData();
    });
  }

  login(): void {
    let modalRef = this.modalService.open(MainComponent, {
      windowClass: 'login-modal',
    });
    modalRef.componentInstance.state = 'login';
    modalRef.componentInstance.selfModal = modalRef;
  }

  register(): void {
    let modalRef = this.modalService.open(MainComponent, {
      windowClass: 'login-modal',
    });
    modalRef.componentInstance.state = 'register';
    modalRef.componentInstance.selfModal = modalRef;
  }

  logout(): void {
    this.authService.logOutWithRedirect();
  }

  isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  isOrganizationAdmin(): boolean {
    return this.authService.hasAnyRole(['moderator', 'super_user']);
  }

  isSuperUser(): boolean {
    return this.authService.hasRole('super_user');
  }

  switchOrganization(organization: Organization): void {
    const currentOrg = this.initService.getCurrentOrg();
    if (currentOrg && currentOrg.slug != organization.slug) {
      let aDomain = window.location.host.split('.');
      aDomain[0] = organization.slug;
      window.location.href =
        window.location.protocol + '//' + aDomain.join('.');
    } else {
      window.location.reload();
    }
  }
  public openTour() {
    let modalRef = this.modalService.open(OnboardingModalComponent, {
      windowClass: 'default-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.start.subscribe((data: any) => {
      this.dhprofileService.setData('true');
    });
  }
  public modalConfirm() {
    let modalRef = this.ngbModal.open(CustomConfirmComponent, {
      windowClass: 'default-modal video-modal',
      size: '300',
      centered: true,
    });
    modalRef.componentInstance.selfModal = modalRef;
    modalRef.componentInstance.showCancel = false;
    modalRef.componentInstance.title = 'Language confirm';
    modalRef.componentInstance.description = 'profile:edit:success';
    modalRef.componentInstance.buttonText = 'Confirm';
    modalRef.componentInstance.refresh.subscribe((data: any) => {});
  }
  onSaveProfile(language = '') {
    this.usersService
      .patchInfoAccount(this.user.username, {
        country_code: null,
        language: this.language.toLowerCase(),
      })
      .then((res: any) => {
        if (language) {
          this.toastService.showSuccess(
            this.translateService.instant('profile:change:language:success')
          );
        } else {
          this.toastService.showSuccess(
            this.translateService.instant('profile:edit:success')
          );
        }
        console.log(res);
      })
      .catch((err) => {
        this.toastService.showError(err);
      });
  }
}
