import { Component, ElementRef, HostBinding, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { VgControlsHiddenService, VgApiService, VgStates } from '@videogular/ngx-videogular/core';

@Component({
  selector: 'microclip-scrub-bar',
  templateUrl: './microclip-scrub-bar.component.html',
  styleUrls: ['./microclip-scrub-bar.component.scss']
})
export class MicroclipScrubBarComponent implements OnInit, OnDestroy {
  @Input() startTime: number | undefined;
  @Input() endTime: number | undefined;

  bracketLeftStyle: any;
  bracketRightStyle: any; 

  @HostBinding('class.hide') hideScrubBar = false;

  @Input() vgFor: string | undefined;
  @Input() vgSlider = true;



  elem: HTMLElement;
  target: any;
  isSeeking = false;
  wasPlaying = false;

  subscriptions: Subscription[] = [];

  constructor(
    ref: ElementRef,
    public API: VgApiService,
    vgControlsHiddenState: VgControlsHiddenService
  ) {
    this.elem = ref.nativeElement;
    this.subscriptions.push(
      vgControlsHiddenState.isHidden.subscribe((hide) =>
        this.onHideScrubBar(hide)
      )
    );
  }

  ngOnInit() {
    if (this.API.isPlayerReady) {
      this.onPlayerReady();
    } else {
      this.subscriptions.push(
        this.API.playerReadyEvent.subscribe(() => this.onPlayerReady())
      );
    }
  }

  onPlayerReady() {
    this.target = this.API.getMediaById(this.vgFor);
    this.API.seekTime(this.startTime! / 1000);
    
    this.target.subscriptions.canPlay.subscribe(() => this.relocateBrackets())
  }

  protected seekStart() {
    if (this.target.canPlay) {
      this.isSeeking = true;
      if (this.target.state === VgStates.VG_PLAYING) {
        this.wasPlaying = true;
      }
      this.target.pause();
    }
  }

  protected seekMove(offset: number) {
    if (this.isSeeking) {
      const percentage = Math.max( Math.min((offset * 100) / this.elem.scrollWidth, 99.9), 0 );
      this.target.time.current = (percentage * this.target.time.total) / 100;
      this.target.seekTime(percentage, true);
    }
  }

  protected seekEnd(offset: number) {
    this.isSeeking = false;
    if (this.target.canPlay) {
      const percentage = Math.max(
        Math.min((offset * 100) / this.elem.scrollWidth, 99.9),
        0
      );
      this.target.seekTime(percentage, true);
      if (this.wasPlaying) {
        this.wasPlaying = false;
        this.target.play();
      }
    }
  }

  protected touchEnd() {
    this.isSeeking = false;
    if (this.wasPlaying) {
      this.wasPlaying = false;
      this.target.play();
    }
  }

  protected getTouchOffset(event: any) {
    let offsetLeft = 0;
    let element: any = event.target;
    while (element) {
      offsetLeft += element.offsetLeft;
      element = element.offsetParent;
    }
    return event.touches[0].pageX - offsetLeft;
  }

  @HostListener('mousedown', ['$event'])
  onMouseDownScrubBar($event: any) {
    if (this.target) {
      if (!this.target.isLive) {
        let offset = $event.offsetX;
        if ((<HTMLElement>$event.target).classList.contains('bracket')) {
          offset += (<HTMLElement>$event.target).offsetLeft;
        }
        let time = offset * this.target.duration / this.elem.clientWidth;
        if (time >= this.startTime! / 1000 && time <= this.endTime! / 1000) {
          if (!this.vgSlider) {
            this.seekEnd(offset);
          } else {
            this.seekStart();
          }
        }
      }
    }
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMoveScrubBar($event: any) {
    if ((<HTMLElement>$event.target).classList.contains('bracket') || (<HTMLElement>$event.target).classList.contains('scrubBar')) {
      if (this.target) {
        if (!this.target.isLive && this.vgSlider && this.isSeeking) {
          let offset = $event.offsetX;
          if ((<HTMLElement>$event.target).classList.contains('bracket')) {
            offset += (<HTMLElement>$event.target).offsetLeft;
          }
          let time = offset * this.target.duration / this.elem.clientWidth;
          if (time >= this.startTime! / 1000 && time <= this.endTime! / 1000) {
            this.seekMove(offset);
          }
        }
      }
    }
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUpScrubBar($event: any) {
    if (this.target) {
      if (!this.target.isLive && this.vgSlider && this.isSeeking) {
        let offset = $event.offsetX;
        if ((<HTMLElement>$event.target).classList.contains('bracket')) {
          offset += (<HTMLElement>$event.target).offsetLeft;
        }
        let time = offset * this.target.duration / this.elem.clientWidth;
        if (time >= this.startTime! / 1000 && time <= this.endTime! / 1000) {
          this.seekEnd(offset);
        }
      }
    }
  }

  @HostListener('touchstart', ['$event'])
  onTouchStartScrubBar($event: any) {
    if (this.target) {
      if (!this.target.isLive) {
        if (!this.vgSlider) {
          this.seekEnd(this.getTouchOffset($event));
        } else {
          this.seekStart();
        }
      }
    }
  }

  @HostListener('document:touchmove', ['$event'])
  onTouchMoveScrubBar($event: any) {
    if (this.target) {
      if (!this.target.isLive && this.vgSlider && this.isSeeking) {
        this.seekMove(this.getTouchOffset($event));
      }
    }
  }
  // @ts-ignore
  @HostListener('document:touchcancel', ['$event'])
  onTouchCancelScrubBar( _$event: any ) {
    if (this.target) {
      if (!this.target.isLive && this.vgSlider && this.isSeeking) {
        this.touchEnd();
      }
    }
  }
  // @ts-ignore
  @HostListener('document:touchend', ['$event'])
  onTouchEndScrubBar( _$event: any ) {
    if (this.target) {
      if (!this.target.isLive && this.vgSlider && this.isSeeking) {
        this.touchEnd();
      }
    }
  }

  @HostListener('keydown', ['$event'])
  arrowAdjustVolume(event: KeyboardEvent) {
    if (this.target) {
      if (event.keyCode === 38 || event.keyCode === 39) {
        event.preventDefault();
        this.target.seekTime((this.target.time.current + 5000) / 1000, false);
      } else if (event.keyCode === 37 || event.keyCode === 40) {
        event.preventDefault();
        this.target.seekTime((this.target.time.current - 5000) / 1000, false);
      }
    }
  }

  getPercentage() {
    return this.target
      ? Math.round((this.target.time.current * 100) / this.target.time.total) + '%'
      : '0%';
  }

  onHideScrubBar(hide: boolean) {
    this.hideScrubBar = hide;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  relocateBrackets() {
    if (this.API.duration) {
      let totalTimeAux = this.API.duration;
      let startTime = this.startTime! / 1000;
      let endTime = this.endTime! / 1000;

      // Bracket left
      let left = (startTime! * 100) / totalTimeAux;
      this.bracketLeftStyle = {
        left: left + '%'
      };

      // Bracket right
      let right = ((totalTimeAux - endTime!) * 100) / totalTimeAux;
      if (right >= 100) {
        right = 0;
      }
      this.bracketRightStyle = {
        right: (right - .5) + '%'
      };
    }
  }
}
