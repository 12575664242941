<div class="container-fluid hide-header">
  <div class="container py-2">
    <div class="row sub-header justify-content-between">
      <div class="col-5">
        <h5 *ngIf="editTitle" class="text-wrap text-md-nowrap h6 my-2">
           {{ ('Edit ' + type)|translate }}: {{ editTitle || " loading..." }}
        </h5>
      </div>
      <div class="col-7">
        <div class="row d-flex align-items-center justify-content-end">
          <div class="col-auto col-sm-auto" *ngIf="!showButton">
            <button
              (click)="goBack()"
              class="btn btn-primary text-nowrap button-edit"
            >
              {{'Back to '+type |translate}}
            </button>
          </div>
          <div
            class="col-6 col-sm-auto"
            *ngIf="notSave === true && !showButton"
          >
            <div class="text-danger fw-bold text-center text-nowrap">
              {{ "not:saved" | translate }}
            </div>
          </div>
          <div
            class="col-auto col-sm-auto"
            *ngIf="notSave === false && !showButton"
          >
            <div class="text-success fw-bold text-center text-nowrap">
              {{ "saved" | translate }}
            </div>
          </div>

          <div class="col-auto" *ngIf="showButton && type == 'playlist'">
            <div class="dropdown">
              <button
                type="button"
                class="btn btn-outline-primary btn-sm"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ "play_video:more:dropdown" | translate }}
                <i class="fa fa-angle-down ms-1"></i>
              </button>
              <ul
                class="dropdown-menu dropdown-menu-movie dropdown-menu-center dropdown-bubble"
              >
                <li>
                  <a class="dropdown-item" (click)="goToEdit()">
                    {{
                      (type == "playlist"
                        ? "playlist:edit:mode"
                        : type == "course"
                        ? ("admin:course:btn:edit"|translate)
                        : type == "group"
                        ? "Edit group"
                        : type == "video"
                        ? "Edit video"
                        : ""
                      ) | translate
                    }}</a
                  >
                  <a *ngIf="userCanChangeOwner()" class="dropdown-item" (click)="changeOwner()">
                    {{ "collection:profile:button:ownership" | translate }}</a
                  >
                </li>

                <li *ngIf="type == 'playlist'">
                  <a
                    (click)="deletePlaylist()"
                    class="dropdown-item text-danger"
                    >{{ "playlist:edit:delete" | translate }}</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <div
            class="col-auto col-sm-auto"
            *ngIf="showButton && type != 'playlist'"
          >
            <button
              (click)="goToEdit()"
              class="btn btn-primary text-nowrap button-edit"
            >
              {{
                (type == "playlist"
                  ? "playlist:edit:mode"
                  : type == "course"
                  ? ("admin:course:btn:edit"|translate)
                  : type == "group"
                  ? "Edit group"
                  : type == "video"
                  ? "Edit video"
                  : ""
                ) | translate
              }}
            </button>
          </div>
          <div
            class="col-auto col-sm-auto"
            *ngIf="showButton && type == 'playlist'"
          >
            <button
              (click)="goToRequests()"
              class="btn btn-outline-success text-nowrap button-edit"
            >
              {{ "view:request:saccesses" | translate }}
            </button>
          </div>
          <div class="col-auto col-sm-auto">
            <div
              *ngIf="update"
              class="text-explanation text-nowrap text-center my-2"
            >
              {{ "last:update" | translate }}
              <span class="fw-bold">{{
                (update | date) || " loading..."
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
