import { ViewMembersComponent } from './../../modals/admin/course/view-members/view-members.component';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteCourseComponent } from 'src/app/modals/admin/course/delete-course/delete-course.component';
import { PricingComponent } from 'src/app/modals/admin/course/pricing/pricing.component';
import { ShareCoursePlatformComponent } from 'src/app/modals/admin/share-course-platform/share-course-platform.component';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-table-admin-course',
  templateUrl: './table-admin-course.component.html',
  styleUrls: ['./table-admin-course.component.scss'],
})
export class TableAdminCourseComponent implements OnInit {
  @Output() scrolled = new EventEmitter<null>();
  @Input() listCourses: any[] = [];
  @Output() refreshTable = new EventEmitter<any>();

  @ViewChild('container') container!: ElementRef;
  constructor(
    private organizationService: OrganizationsService,
    private toastService: ToastService,
    private ngbModal: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {}
  @HostListener('window:scroll', ['$event'])
  onScroll(e: any) {
    let bottomPosition =
      this.container.nativeElement.getBoundingClientRect().top +
      this.container.nativeElement.offsetHeight;
    if (bottomPosition < window.innerHeight) {
      this.scrolled.emit();
    }
  }

  public goToCourse(course: any) {
    this.router.navigate([
      '/courses',
      course.id,
      'cursus',
      course.name.split(' ').join('-'),
    ]);
  }
  public shareSettings(course: any) {
    let modalRef = this.ngbModal.open(ShareCoursePlatformComponent, {
      windowClass: 'default-modal',
      size: '800',
      centered: true,
    });
    modalRef.componentInstance.courseId = course.id;
    modalRef.componentInstance.user = course.user;
    modalRef.componentInstance.selfModal = modalRef;
    modalRef.componentInstance.refresh.subscribe((data: any) => {
      this.refreshTable.emit();
    });
  }

  public deleteCourse(course: any) {
    let modalRef = this.ngbModal.open(DeleteCourseComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.course = course;
    modalRef.componentInstance.refresh.subscribe((data: any) => {
      this.refreshTable.emit();
    });
  }

  public openPricing(course: any) {
    let modalRef = this.ngbModal.open(PricingComponent, {
      windowClass: 'default-modal video-modal',
      size: '900',
      centered: true,
    });
    modalRef.componentInstance.course = course;
    modalRef.componentInstance.refresh.subscribe((data: any) => {
      this.refreshTable.emit();
    });
  }

  public openViewMembers(course: any) {
    let modalRef = this.ngbModal.open(ViewMembersComponent, {
      windowClass: 'default-modal video-modal',
      size: '700',
      centered: true,
    });
    modalRef.componentInstance.course = course;
  }
  
  public joinPlatforms(array: any) {
    return array.map((objeto: any) => objeto.name).join(', ');
  }
}
