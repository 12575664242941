<div class="modal-close" (click)="closeModal()">
  <i class="fas fa-times me-1"></i><span>{{ "btn:close" | translate }}</span>
</div>

<div class="modal-header">
  <h4 class="modal-title">
    {{ "course:buy:" + status + ":title" | translate }}
  </h4>
</div>

<div class="modal-body">
  <div *ngIf="loading; else content" class="row p-5">
    <div class="justify-content-center">
      <app-loading-icon></app-loading-icon>
    </div>
  </div>

  <ng-template #content>
    <div class="course-image rounded mb-3">
      <img
        class="w-100"
        [src]="
          order!.collections![0].coverPathUrlMedium ||
          order!.collections![0].thumbnail
        "
        alt=""
      />
    </div>
    <div class="row text-center py-2">
      <p>
        {{ "course:buy:" + status + ":description" | translate }}
        <strong *ngIf="status == 'paid'">{{ order!.user!.email }}</strong
        ><span *ngIf="status == 'paid'">.</span>
        <span *ngIf="status == 'cancelled'"
          ><strong>contact@movielearning.com</strong>.</span
        >
      </p>
    </div>

    <div *ngIf="status == 'paid'">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-4">
              <span class="item-title">{{
                "course:buy:paymentInfoList:item:1" | translate
              }}</span>
            </div>
            <div class="col-8">
              <strong>{{
                "course:buy:payment:method:" +
                  (order!.payment_method || order!.method) | translate
              }}</strong>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4">
              <span class="item-title">{{
                "course:buy:paymentInfoList:item:2" | translate
              }}</span>
            </div>
            <div class="col-8">
              <strong>{{ order!.payments![0].id }}</strong>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4">
              <span class="item-title">{{
                "course:buy:paymentInfoList:item:3" | translate
              }}</span>
            </div>
            <div class="col-8">
              <strong>{{ order!.id }}</strong>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4">
              <span class="item-title">{{
                "course:buy:paymentInfoList:item:4" | translate
              }}</span>
            </div>
            <div class="col-8">
              <strong
                >{{(getOrderDate()) | titlecase }} CET
                timezone</strong
              >
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4">
              <span class="item-title">{{
                "course:buy:paymentInfoList:item:5" | translate
              }}</span>
            </div>
            <div class="col-8">
              <strong>{{
                invoiceDetails!.firstname + " " + invoiceDetails!.lastname
              }}</strong>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4">
              <span class="item-title">{{
                "course:buy:paymentInfoList:item:6" | translate
              }}</span>
            </div>
            <div class="col-8">
              <strong>{{
                (invoiceDetails!.is_company
                  ? "course:buy:paymentInfoList:item:6:business"
                  : "course:buy:paymentInfoList:item:6:user"
                ) | translate
              }}</strong>
            </div>
          </div>

          <div class="row mt-2" *ngIf="invoiceDetails!.country_code">
            <div class="col-4">
              <span class="item-title">{{
                "course:buy:paymentInfoList:item:11" | translate
              }}</span>
            </div>
            <div class="col-8">
              <strong>{{ invoiceDetails!.country_code }}</strong>
            </div>
          </div>

          <div class="row mt-2" *ngIf="invoiceDetails!.is_company">
            <div class="col-4">
              <span class="item-title">{{
                "course:buy:paymentInfoList:item:7" | translate
              }}</span>
            </div>
            <div class="col-8">
              <strong>{{ invoiceDetails!.company_name }}</strong>
            </div>
          </div>

          <div class="row mt-2" *ngIf="invoiceDetails!.is_company">
            <div class="col-4">
              <span class="item-title">{{
                "course:buy:paymentInfoList:item:8" | translate
              }}</span>
            </div>
            <div class="col-8">
              <strong>{{ invoiceDetails!.company_address }}</strong>
            </div>
          </div>

          <div class="row mt-2" *ngIf="invoiceDetails!.is_company">
            <div class="col-4">
              <span class="item-title">{{
                "course:buy:paymentInfoList:item:9" | translate
              }}</span>
            </div>
            <div class="col-8">
              <strong>{{ invoiceDetails!.company_vat }}</strong>
            </div>
          </div>

          <div class="row mt-2" *ngIf="invoiceDetails!.is_company">
            <div class="col-4">
              <span class="item-title">{{
                "course:buy:paymentInfoList:item:10" | translate
              }}</span>
            </div>
            <div class="col-8">
              <strong>{{ invoiceDetails!.coc }}</strong>
            </div>
          </div>
        </div>
      </div>

      <h3 class="h5 mt-4">
        {{ "course:buy:payment:order:title" | translate }}
      </h3>

      <div class="card">
        <div class="card-body">
          <div class="row justify-content-end mt-2">
            <div class="col-3">
              <img
                class="img-course"
                [src]="
                  competencies?.length > 0
                    ? 'assets/img/dh-profile/img-card3.png'
                    : 'assets/img/dh-profile/img-card3.png'
                "
              />
            </div>
            <div class="col col-md-9">
              <ng-container *ngIf="competencies?.length > 0; else entries">
                <div class="row">
                  <div class="col-auto">
                    <h6>{{ "focus:competencies:text" | translate }}</h6>
                    <p class="fw-bold">
                      {{ competencies.length }}
                      {{
                        (competencies.length > 1
                          ? "focus:competencies:sub:text:s"
                          : "focus:competencies:sub:text"
                        ) | translate
                      }}
                      x
                      {{
                        compentencyPrice
                          | currency
                            : "EUR"
                            : "symbol"
                            : (compentencyPrice % 1 === 0 ? "1.0-2" : "1.2-2")
                      }}
                      =
                      {{
                        competencies.length * compentencyPrice
                          | currency
                            : "EUR"
                            : "symbol"
                            : ((competencies.length * compentencyPrice) % 1 ===
                              0
                                ? "1.0-2"
                                : "1.2-2")
                      }}
                    </p>
                  </div>

                  <!-- <div class="col text-end">
                    <h6>{{ order!.total_taxed! | currency:'EUR':'symbol':'1.0-2' }}</h6>
                  </div> -->
                </div>

                <p class="mb-0 fw-normal">
                  <ng-container
                    *ngFor="let competence of competencies; let last = last"
                  >
                    {{
                      "dh_profile:competences:level:" + 3 + ":" + competence
                        | translate
                    }}<span *ngIf="!last">, </span>
                  </ng-container>
                </p>
              </ng-container>
              <ng-template #entries>
                <div class="row">
                  <div class="col-auto">
                    <h6>{{ order!.product_name! }}</h6>
                  </div>

                  <div class="col text-end">
                    <h6>
                      {{
                        order!.total_taxed!
                          | currency : "EUR" : "symbol" : "1.0-2"
                      }}
                    </h6>
                  </div>
                </div>
                <p class="fs-14">
                  {{
                    "movielearning:admin:orders:profile:details:access"
                      | translate
                        : { days: order!.entries[0]!.subscription_days }
                  }}
                  -
                  {{
                    "movielearning:admin:orders:profile:details:ends"
                      | translate
                        : {
                            start_date: getSubscriptionStart(),
                            end_date: getSubscriptionEnds()
                          }
                  }}
                </p>
              </ng-template>
            </div>
          </div>
          <hr />
          <div class="row justify-content-end mt-2">
            <div class="col col-md-6">
              <div class="row mb-2" *ngIf="order!.coupon!">
                <div class="col fs-14">
                  {{ "discount:choose:coupon:applied" | translate }}
                </div>
                <div class="col text-end fw-bold">
                  {{ order!.coupon!.code }}
                </div>
              </div>
              <div class="row mb-2" *ngIf="order!.discount! > 0">
                <div class="col fs-14">
                  {{ "discount:choose:coupon:discount" | translate }}
                </div>
                <div class="col text-end fw-bold">
                  {{
                    order!.order_price! - order!.total_taxed!
                      | currency : "EUR" : "symbol" : "1.0-2"
                  }}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col fs-14">
                  {{
                    "movielearning:admin:orders:profile:details:list:1"
                      | translate
                  }}
                </div>
                <div class="col text-end fw-bold">
                  {{ order!.total | currency : "EUR" : "symbol" : "1.0-2" }}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col fs-14">
                  {{
                    "movielearning:admin:orders:profile:details:list:3"
                      | translate : { tax: order!.order_tax! * 100 }
                  }}
                </div>
                <div class="col text-end fw-bold">
                  {{ order!.tax | currency : "EUR" : "symbol" : "1.0-2" }}
                </div>
              </div>
              <div class="row">
                <div class="col fs-14">
                  {{
                    "movielearning:admin:orders:profile:details:list:4"
                      | translate
                  }}
                </div>
                <div class="col text-end fw-bold">
                  {{
                    order!.total_taxed! | currency : "EUR" : "symbol" : "1.0-2"
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<div class="modal-footer">
  <div class="container">
    <div class="row justify-content-end">
      <div class="col-6 text-end">
        <button
          *ngIf="order?.gift_user_id != null || order?.email != null"
          class="btn btn-primary"
          (click)="closeModal()"
        >
          {{ "course:close:btn" | translate }}
        </button>
        <!-- <button *ngIf="!(order?.gift_user_id!=null||order?.email!=null)" class="btn btn-primary" (click)="goToCourse()">{{ 'course:start:btn' | translate }}</button> -->
      </div>
    </div>
  </div>
</div>
