import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-delete-invitation',
  templateUrl: './delete-invitation.component.html',
  styleUrls: ['./delete-invitation.component.scss'],
})
export class DeleteInvitationComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Output() refresh = new EventEmitter<any>();
  @Input() organizationId:number=0;
  @Input() member:any;

  public faCircleNorch: any = faCircleNotch;

  public faTimes = faTimes;
  public loadSpin: boolean = false;

  constructor(
    private organizationService: OrganizationsService,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {}

  public deleteInvitation() {
    this.loadSpin=true;
    this.organizationService
      .deleteInvitation(this.organizationId,this.member.origin,this.member.origin_id)
      .then((resp) => {
        if (resp.result == 'success') {
          this.toastService.showSuccess(
            'The invitation was successfully removed.'
          );
          this.loadSpin=false;
          this.refresh.emit();
           this.closeModal();
          
        } else {
          this.loadSpin=false;
          this.toastService.showError('Error');
        }
      })
      .catch((error) => {
        this.loadSpin=false;
        this.toastService.showError(error);
      });


   
  }

  public closeModal() {
    if (this.loadSpin) return;
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
}
