import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ApplicationRef, ChangeDetectorRef, Component, ElementRef, HostListener, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { faAngleLeft, faAngleRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Course } from 'src/app/interfaces/course';
import { CourseViewerService } from 'src/app/services/course-viewer.service';
import { Lesson } from 'src/app/interfaces/lesson';
import { Chapter } from 'src/app/interfaces/chapter';
import { Paragraph } from 'src/app/interfaces/paragraph';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseReviewModalComponent } from 'src/app/modals/courses/review-modal/course-review-modal.component'
import { CourseUtilitiesService } from 'src/app/utilities/course-utilities.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Organization } from 'src/app/interfaces/organization';
import { InitService } from 'src/app/services/init.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('collapsed', style({
        'width': '0px'
      })),
      state('out', style({
        'width': '400px'
      })),
      state('outMobile', style({
        'width': '100%'
      })),
      transition('collapsed => out', animate('400ms ease-in-out')),
      transition('collapsed => outMobile', animate('400ms ease-in-out')),
      transition('out => collapsed', animate('400ms ease-in-out')),
      transition('outMobile => collapsed', animate('400ms ease-in-out'))
    ]),
  ]
})
export class MainComponent implements OnInit, AfterViewInit {
  @ViewChild('lessonContent') lessonContent: ElementRef|undefined;

  sidebarState = 'collapsed';
  showGreenButton = false;
  public courseId = ''
  faTimes = faTimes;
  faAngleLeft = faAngleLeft;
  faAngleRight = faAngleRight;

  atBottomScroll = false;

  updating = false;

  course: Course | undefined;
  current: Lesson | Chapter | Paragraph | undefined;

  completed = false;
  isTextLesson = false;
  loading = false;

  public currentOrganization: Organization | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private courseViewer: CourseViewerService,
    private ref: ChangeDetectorRef,
    private ngbModal:NgbModal,
    private courseUtils: CourseUtilitiesService,
    private gtmService: GoogleTagManagerService,
    private initService: InitService,
  ) { }

  ngOnInit(): void {
    this.courseViewer.action$.subscribe(() => {
      this.toggleSidebarState(); // Llama a la función cuando se emite el evento
    });
    this.loading = true
    this.currentOrganization = this.initService.getCurrentOrg();
    
    this.route.params.subscribe( ({course_id}) => {
      if (course_id) {
        this.courseId = course_id;
        this.courseViewer.setCourseId(course_id);
        this.courseViewer.getCourseObservable()?.subscribe( (course: Course|null) => {
          if (course) {
            this.loading = false
            this.course = course;
            console.log(this.course);
            if (this.currentOrganization?.slug == 'courses') {
              
              // const gtmTag = {
              //   event: 'watch_preview',
              //   preview : {
              //     course: this.course
              //   }
              // };
              // console.log('Sending GTM', gtmTag);
  
              // this.gtmService.pushTag(gtmTag);
            }
          }
        });
      }
    });

    this.courseViewer.getCurrent().subscribe( lesson => {
      this.current = lesson;
      this.completed = lesson ? (<Lesson>lesson).completed : false;
      this.isTextLesson = this.courseViewer.getCurrentType() == 'text';
      // if (!this.current!.access) {
      //   this.goToProfile();
      // }
    })

    this.courseViewer.getUpdtingObservable().subscribe( updating => {
      this.updating = updating;
      this.ref.detectChanges();
    } )
  }

  ngAfterViewInit(): void {
    this.lessonContent?.nativeElement.setAttribute('scrollTop', 0);
    this.lessonContent?.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
  }

  onScroll(): void {
    let el = this.lessonContent?.nativeElement
    let distanceFromBotton = el.scrollHeight - el.offsetHeight - el.scrollTop;

    if ( this.courseViewer.getEntityType(this.current!) == 'lesson' ) {
      if (distanceFromBotton < 200) {
        this.atBottomScroll = true;
      } else {
        this.atBottomScroll = false;
      }
    }
  }

  toggleSidebarState(): void {
    let isMobile = matchMedia("(max-width: 767px)").matches;
    this.sidebarState = this.sidebarState == 'collapsed' ? ( isMobile ? 'outMobile' : 'out' ) : 'collapsed';
  }

  @HostListener('window:resize')
  onResize() {
    let isMobile = matchMedia("(max-width: 767px)").matches;
    if ( isMobile ) {
      if ( !this.sidebarState.includes('Mobile') ) {
        // this.sidebarState += 'Mobile';
      
      }
    } else {
      this.sidebarState = this.sidebarState.replace('Mobile', '');
    }
  }

  goNext(): void {
    if (this.isTextLesson && !this.completed) {

      this.completeLesson().then( res => {
        this.courseViewer.goNext();
        this.scrollTopContainer();
      })
    } else {
      this.courseViewer.goNext();
      this.scrollTopContainer();
    }
  }

  goPrev(): void {
    this.courseViewer.goPrev();
    this.scrollTopContainer();
  }

  scrollTopContainer(): void {
    let el = this.lessonContent;
    setTimeout(() => {
      if (el) {
        el.nativeElement.scrollTop = 0;
      }
    }, 1);
  }

  async completeLesson(): Promise<Lesson> {
    return this.courseViewer.completeLesson(<Lesson>this.current).then()
  }

  async uncompleteLesson() {
    return this.courseViewer.uncompleteLesson(<Lesson>this.current).then()
  }

  reviewModalsShow() {
    let modalRef = this.ngbModal.open(CourseReviewModalComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
      centered: true
    });
    modalRef.componentInstance.course = this.course;
  }

  userIsEnrolled(): boolean {
    if (this.course) {
      return this.courseUtils.userIsEnrolled(this.course);
    }
    return false;
  }

  public goToProfile(){
  
    location.href ='/courses/'+this.courseId
    //this.router.navigate(['/courses',this.courseId],extras)
    // setTimeout(() => {
    //   window.location.reload();
    // }, 300);
  }
  public goToHome(){
    this.router.navigate(['/'])
  }
}
