import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ApiV1Service } from './api.v1.service';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private api: ApiService, private apiV1: ApiV1Service) {}

  async getPlatforms() {
    try {
      let query = `/organization?paranoid=false`;
      const courses = await this.api.get(query);
      return courses;
    } catch (error) {
      return error;
    }
  }

  async createPlatform(params: any): Promise<any> {
    return this.api.postJson('/organization', params);
  }

  public disablePlatform(platformId: any) {
    return this.api
      .patch(`/organization/${platformId}/disable`)
      .then((res: any) => {
        return res.data;
      });
  }

  public enablePlatform(platformId: any) {
    return this.api
      .patch(`/organization/${platformId}/enable`)
      .then((res: any) => {
        return res.data;
      });
  }

  ///////////
  //Courses
  /////////
  async getCourses(
    search: string | null,
    organization_id: Array<any> | null,
    limit: number | null = 10,
    page: number | null = 1,
    searchAvailable = true
  ) {
    try {
      let query = '';
      if (searchAvailable) {
        query = `?type=course&owner=&limit=${limit}&page=${page}&search=${
          search || ''
        }&order=desc&all_available=true&public=both${
          organization_id?.length ? '&organization_id[]=' + organization_id : ''
        }`;
      } else {
        query = `?type=course&owner=&limit=${limit}&page=${page}&search=${
          search || ''
        }&order=desc&public=both${
          organization_id?.length ? '&organization_id[]=' + organization_id : ''
        }`;
      }

      const searchVideos = await this.api.get('/collection' + query);
      return searchVideos;
    } catch (error) {
      return error;
    }
  }

  public async deleteCourse(courseId: number) {
    try {
      let url = `/collection/${courseId}`;
      const deleteCourse = await this.api.delete(url);
      return deleteCourse;
    } catch (error) {
      return error;
    }
  }
  async patchEditCourse(id: number, dataEdit: {}) {
    try {
      const patchCourse = await this.api.put(`/collection/${id}`, dataEdit);
      return patchCourse;
    } catch (error) {
      return error;
    }
  }

  async priceOrganization(courseId: number, organizationId: number, data: {}) {
    try {
      let query = `?organization_id=${organizationId}`;
      const patchCourse = await this.api.put(
        `/collection/${courseId}/price${query}`,
        data
      );
      return patchCourse;
    } catch (error) {
      return error;
    }
  }
  async getUsersAcepted(courseId: number) {
    try {
      let query = `/collection/${courseId}/users/accepted`;
      const members = await this.api.get(query);
      return members;
    } catch (error) {
      return error;
    }
  }
  async getUsersInvited(courseId: number) {
    try {
      let query = `/collection/${courseId}/users/invited`;
      const members = await this.api.get(query);
      return members;
    } catch (error) {
      return error;
    }
  }

  ///////////
  //Orders
  /////////
  // order?end_date=2023-02-03&limit=10&page=1&search=&start_date=2023-01-02

  async getOrders(
    search: string | null,
    organization_id: Array<any> | null,
    limit: number | null = 10,
    page: number | null = 1,
    endDate: any = '',
    startDate: any = ''
  ) {
    try {
      let query = `?end_date=${endDate}&limit=${limit}&page=${page}&search=${
        search || ''
      }&start_date=${startDate}`;

      const order = await this.api.get('/order' + query);
      return order;
    } catch (error) {
      return error;
    }
  }
  async getOrdersProfile(limit: number | null = 10, page: number | null = 1) {
    try {
      const order = await this.api.get('/admin/orders');
      return order;
    } catch (error) {
      return error;
    }
  }
  async getOrderDetail(idDetail: string | null) {
    try {
      const order = await this.api.get(`/order/${idDetail}?id=${idDetail}`);
      return order;
    } catch (error) {
      return error;
    }
  }

  async setMaintenanceData(data: any) {
    //return true;  //se comenta linea ya que la funcionalidad aun no esta completa y entonces genera un error al responder como unautenticated y eso hace que el usuario se desloge
    return await this.apiV1.post(`/maintenance`, data);
  }

  async getMaintenanceData() {
    try {
      const maintenance = await this.apiV1.get(`/maintenance`);
      return maintenance;
    } catch (error) {
      return error;
    }
  }
  async hasMaintenance() {
    try {
      const maintenance = await this.apiV1.get(`/has_maintenance`);
      return maintenance;
    } catch (error) {
      return error;
    }
  }
  async setTrackPreviewLesson(data: any) {
    //return true;  //se comenta linea ya que la funcionalidad aun no esta completa y entonces genera un error al responder como unautenticated y eso hace que el usuario se desloge
    return await this.apiV1.post(`/track_course_preview`, data);
  }

  async setTrackContacted(id_track: any, value: boolean) {
    let data = {
      contacted: value ? 1 : 0,
      id_track: id_track,
    };
    return await this.apiV1.post(`/set_track_contacted`, data);
  }

  async getCoursePreviewProspectsList(
    search: string | null,
    organization_id: number | null = 0,
    limit: number | null = 10,
    page: number | null = 1
  ) {
    try {
      let query = `?limit=${limit}&page=${page}&organization=${organization_id}&order_name=date&order_type=desc&track_name=preview_course&search=${
        search || ''
      }`;

      const users = await this.apiV1.get(`/track` + query);
      return users;
    } catch (error) {
      return error;
    }
  }
  ////////////
  ///Users
  ///////////
  async getUsers(
    search: string | null,
    limit: number | null = 10,
    page: number | null = 1
  ) {
    try {
      let query = `?&limit=${limit}&page=${page}&paranoid=false&search=${
        search || ''
      }`;
      const users = await this.api.get(`/user/list` + query);
      return users;
    } catch (error) {
      return error;
    }
  }

  async getUserDetail(name: string | null) {
    try {
      const user = await this.api.get(
        `/user/${name}?paranoid=false&username=${name}`
      );
      return user;
    } catch (error) {
      return error;
    }
  }

  async getCourseDetail(courseId: string | null) {
    try {
      const user = await this.api.get(`/order/${courseId}?id=${courseId}`);
      return user;
    } catch (error) {
      return error;
    }
  }
  async getOrderUser(id: string | null) {
    try {
      const user = await this.api.get(
        `/order?filter%5Buser_id%5D=${id}&limit=999999999`
      );
      return user;
    } catch (error) {
      return error;
    }
  }
  async getCoursesUser(id: string | null) {
    try {
      const user = await this.api.get(
        `/collection/enrolled?public=both&open=both&type=course&owner=&limit=999999999&page=1&search=&order=DESC&joined=true&section=joined&user_id=${id}&all_available=true`
      );
      return user;
    } catch (error) {
      return error;
    }
  }
  async deleteUser(userId: any) {
    try {
      let url = `/user/${userId}/remove`;
      const accountDeleted = await this.api.patch(url, {});
      return accountDeleted;
    } catch (error) {
      return error;
    }
  }
  async saveUserInfo(userName: any, info: any) {
    try {
      let url = `/user/${userName}`;
      const edit = await this.api.put(url, info);
      return edit;
    } catch (error) {
      return error;
    }
  }
  async removeFromPlatform(oganizationId: any, userId: any) {
    try {
      let url = `/organization/${oganizationId}/member/${userId}`;
      const user = await this.api.delete(url);
      return user;
    } catch (error) {
      return error;
    }
  }

  /////////////
  // Discounts
  ///////////
  async deleteDiscounts(
    id: number | null,
  ) {
    try {
      if (!id) {return}
      const searchVideos = await this.api.delete('/coupons/'+id);
      return searchVideos;
    } catch (error) {
      return error;
    }
  }

  async getDiscounts(
    search: string | null,
    organization_id: Array<any> | null,
    limit: number | null = 10,
    page: number | null = 1,
    courseId = ''
  ) {
    try {
      // coupons?limit=12&page=2&search=&order=UPDATED_AT,DESC&organization_ids=
      let query = `?limit=${limit}&page=${page}&search=${
        search || ''
      }&order=UPDATED_AT,DESC&organization_id[]=${organization_id}&course_ids=${courseId}`;

      const searchVideos = await this.api.get('/coupons' + query);
      return searchVideos;
    } catch (error) {
      return error;
    }
  }
  async searchCourses(search: string | null, searchAvailable = true) {
    try {
      let query = '';
      if (searchAvailable) {
        query = `?type=course&owner=&limit=10&page=1&search=${search}&order=desc&all_available=true&public=both&organization_id[]=`;
      } else {
        query = `?type=course&owner=&limit=10&page=1&search=${search}&order=desc&&public=both&organization_id[]=`;
      }

      const searchCourses = await this.api.get('/collection' + query);
      return searchCourses;
    } catch (error) {
      return error;
    }
  }
  async searchCoursesOnlyPublic(search: string | null, searchAvailable = true) {
    try {
      let query = '';
      if (searchAvailable) {
        query = `?type=course&owner=&limit=10&page=1&search=${search}&order=desc&public=both&organization_id[]=1`;
      } else {
        query = `?type=course&owner=&limit=10&page=1&search=${search}&order=desc&&public=both&organization_id[]=1`;
      }

      const searchCourses = await this.api.get('/collection' + query);
      return searchCourses;
    } catch (error) {
      return error;
    }
  }

  async updateDiscount(discount: any, id: any) {
    return await this.api.patch(`/coupons/${id}`, discount);
  }
  async createDiscount(discount: any) {
    return await this.api.postJson(`/coupons`, discount);
  }
}
