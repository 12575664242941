import { Component, OnInit , Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Organization } from 'src/app/interfaces/organization';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('collapsed', style({
        'margin-left': '-100%',
        display: 'none'
      })),
      state('out', style({
        'margin-left': '0px'
      })),
      transition('collapsed => out', [
        style({ display: 'block' }),
        animate('400ms ease-in-out'),
      ]),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
    trigger('darken', [
      state('collapsed', style({
        background: 'rgba(0,0,0,0)',
        display: 'none'
      })),
      state('out', style({
        background: 'rgba(0,0,0,.3)',
      })),
      transition('collapsed => out', [
        style({ display: 'block' }),
        animate('400ms ease-in-out'),
      ]),
      transition('out => collapsed', animate('400ms ease-in-out'))
    ]),
  ]
})
export class SidebarComponent implements OnInit {
  @Input() state:string = 'collapsed';
  @Output() toggle = new EventEmitter<string>();
  @Input() organizations: Array<Organization> = [];


  constructor() { }

  ngOnInit(): void {
  }
  toggleState(): void {
    this.toggle.emit();
  }
}
