import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-go-back-playlist',
  templateUrl: './go-back-playlist.component.html',
  styleUrls: ['./go-back-playlist.component.scss'],
})
export class GoBackPlaylistComponent implements OnInit {
  @Input() selfModalNotification: NgbModalRef | null = null;
  @Input() fromSubheader: boolean = false;
  @Output() closeUpublished: EventEmitter<any> = new EventEmitter();
  @Output() fromSubHeader: EventEmitter<any> = new EventEmitter();
  public faCircleNorch: any = faCircleNotch;

  public faTimes = faTimes;
  public loadSpin: boolean = false;

  constructor(private router: Router) {}

  ngOnInit(): void {}
  public closeModal() {
    if (this.selfModalNotification) this.selfModalNotification?.close();
    else $('ngb-modal-window').click();
  }

  public closeModaPublished() {
    this.closeUpublished.emit(false);
    if (this.selfModalNotification) this.selfModalNotification?.close();
    else $('ngb-modal-window').click();
  }

  public closeModaUnpublished() {
    if (this.fromSubheader) {
      console.log('se ejecuta');
      // this.router.navigate(['/playlists']);
    } else {
      this.closeUpublished.emit(true);
      if (this.selfModalNotification) this.selfModalNotification?.close();
      else $('ngb-modal-window').click();
    }
  }
}
