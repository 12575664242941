import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-review-stars',
  templateUrl: './review-stars.component.html',
  styleUrls: ['./review-stars.component.scss'],
})
export class ReviewStarsComponent implements OnInit {
  @Input() rating: number | null = null;
  @Input() size: SizeProp = 'lg';
  @Input() showNumber: boolean = true;
  @Input() showStars: boolean = true;
  @Input() bgColor: string = '#d8d8d8';

  stars = [0, 0, 0, 0, 0];

  faStar = faStar;
  faHalfStar = faStarHalf;

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    this.initStarts();
  }
  ngOnInit(): void {
    this.initStarts();
  }

  public initStarts() {
    // multiplyed by 10 to avoid js inprecision on floats
    let rating = this.rating! * 10;
    for (let i = 0; i < this.stars.length; i++) {
      if (rating && rating > 0) {
        let aux = rating - 10;
        if (rating > 10) {
          this.stars[i] = rating - aux;
        } else {
          this.stars[i] = rating;
        }
        rating -= 10;
      } else {
        this.stars[i] = 0;
      }
    }
  }
}
