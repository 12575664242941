<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title fw-bold">
    {{ "privacy:title" | translate }}
  </h4>
</div>
<div class="modal-body flex-column">
  <div class="row">
    <p>{{ "privacy:text:1" | translate }}</p>
    <p>{{ "privacy:text:2" | translate }}</p>
    <p>{{ "privacy:text:3" | translate }}</p>
  </div>
  <div *ngIf="currentLang == 'nl'" class="row justify-content-center">
    <div class="col-auto mb-2">
      <button
        type="button"
        role="tab"
        class="btn btn-primary btn-create ms-2 fs-15 text-nowrap"
        (click)="closeModal()"
      >
        {{ "privacy:close" | translate }}
      </button>
    </div>
  </div>
  <div *ngIf="currentLang == 'nl'" class="row">
    <p>{{ "privacy:text:1:en" | translate }}</p>
    <p>{{ "privacy:text:2:en" | translate }}</p>
    <p>{{ "privacy:text:3:en" | translate }}</p>
  </div>
  <div class="row justify-content-center ">
    <div class="col-auto">
      <button
        type="button"
        role="tab"
        class="btn btn-primary btn-create ms-2 fs-15 text-nowrap"
        (click)="closeModal()"
      >
        {{  currentLang == 'nl' ? "Close this message"  : 'privacy:close' | translate }}
      </button>
    </div>
  </div>
</div>
