import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main/main.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { PlanTabComponent } from './plan-tab/plan-tab.component';
import { FormsModule } from '@angular/forms';
import { MethodTabComponent } from './method-tab/method-tab.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OrderPreviewTabComponent } from './order-preview-tab/order-preview-tab.component';
import { ResultComponent } from './result/result.component';
import { LoginModule } from '../login/login.module';
 

@NgModule({
  declarations: [
    MainComponent,
    PlanTabComponent,
    MethodTabComponent,
    OrderPreviewTabComponent,
    ResultComponent,
   
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    SharedModule,
    LoginModule,
    NgbModule
  ],
  exports: [
    PlanTabComponent
  ]
})
export class CheckoutQuestionnaireModule { }
