<div class="modal-close"  (click)="closeModal()">
    <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon><span >{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
    <h4 class="modal-title">{{"playlist:add:modal:title"|translate}}</h4>
</div>
<div class="modal-body">
    <div class="container-fluid">

        <div class="row tabs-row">
            <div class="col text-center">
                <app-movie-tabs-wrapper [main]="true">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active py-3" id="activity-tab" (click)="getTab('allPlayList')" type="button"
                            role="tab">{{ 'playlist:libray:all' | translate }}</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link py-3" id="conversation-tab" (click)="getTab('myPlayList')" type="button"
                            role="tab">{{ 'playlist:libray:my' | translate }}</button>
                    </li>
                    

                </app-movie-tabs-wrapper>
            </div>
        </div>
        </div>
        

        <div  class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="profile-tab">
                <div class="container py-4">
                   
                    <app-search-input
                        [inputPlaceholder]="'Search courses'"
                        [actualSearch]="this.searchText"
                        [loading]="loading"

                        [searchText]="'Search'"
                        (searchEvent)="searchAll($event)"
                        *ngIf="actualTab=='allPlayList'"
                     ></app-search-input>
                   
                     <app-search-input
                        [inputPlaceholder]="'Search courses'"
                        [actualSearch]="this.searchTextOwn"
                        [loading]="loading"
    
                        [searchText]="'Search'"
                        (searchEvent)="searchOwn($event)"
                        *ngIf="actualTab=='myPlayList'"
                      ></app-search-input>

                     <div *ngIf="loading" class="row max-heigth">
                        <div class="row loadingCenter">
                            <app-loading-icon ></app-loading-icon>
                        </div>

                       
                    </div>
               
                    <div *ngIf="!loading" class="row justify-content-center max-heigth list-overflow">
                       
        
                        <div  *ngIf="actualTab=='allPlayList'">
                           
                                <div  *ngFor="let playlist of playlists" class="row justify-content-center">
                                    <div class="col-xl-12 p-0">
                                            <app-course-list-card (selectItem)="selectItem($event)" [itemSelect]="itemSelect" [course]="playlist" ></app-course-list-card>
                                    </div>
                                </div>
                       
                        </div>


                        <div *ngIf="actualTab=='myPlayList'">
                            <div  *ngFor="let playlistOwn of playlistsOwn" class="row justify-content-center">
                                <div class="col-xl-12 p-0">
                                        <app-course-list-card (selectItem)="selectItem($event)" [itemSelect]="itemSelect" [course]="playlistOwn" ></app-course-list-card>
                                </div>
                         
                            </div>
                            <div class="text-empty-content" *ngIf="playlistsOwn.length==0">
                                {{'playlist:list:empty'|translate}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    <div class="form-group clearfix">
        <button (click)="sendData()" [disabled]="itemSelect==''"  type="button" role="tab" class="btn btn-primary btn-create modal-btn-width float-right ms-2 fs-15">
            <fa-icon class="ms-2" [icon]="faCircleNorch" *ngIf="addLoading"  [spin]="true"></fa-icon>
            {{"playlist:add:modal:title"|translate}}
        </button>
    </div>
</div>

