import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  @Input() progress = 0;
  @Input() width = 100;
  @Input() color = '#1ebe55';
  @Input() labelPosition: 'top'|'left'|'right'|'bottom'|null = null;
  @Input() showLabelText: boolean = true

  constructor() { }

  ngOnInit(): void {
  }

}
