import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toasts: any[] = [];

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.unshift({ textOrTpl, ...options });
  }

  showError(message: string) {
    this.show(message, { classname: 'bg-danger text-white' });
  }
  showSuccess(message: string) {
    this.show(message, { classname: 'bg-success text-white' });
  }
  showInfo(message: string) {
    this.show(message, { classname: 'bg-primary text-white' });
  }
  showWarning(message: string) {
    this.show(message, { classname: 'bg-warning text-white', delay:15000 });
  }

  remove(toast: any) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }
}
