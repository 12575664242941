<div class="container px-5 pt-4 pb-3">
    <h3 class="text-center mb-2 fw-bold h4">{{ 'welcome:back' | translate }}, {{user?.display_name}}</h3>
    <p class="text-center px-3 fw-light font-family-karla">{{ 'welcome:back:sub' | translate }}</p>
    <div class="choose-orgs-container">
        <a class="d-flex my-2" (click)="switchOrganization(organization)" *ngFor="let organization of organizations">
            <app-organization-logo [organization]="organization"></app-organization-logo>
            <div class="flex-grow-1 d-flex align-items-center mx-3 text-dark fw-500">{{ organization.name }}</div>
            <fa-icon [icon]="faChevronRight" class="d-flex align-items-center p-3"></fa-icon>
        </a>
    </div>
    <div class="row justify-content-center mt-1">
        <div class="col-auto"><a class="btn btn-link fw-500" (click)="logout()">{{ 'navbar:logout' | translate }}</a></div>
    </div>
</div>