import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-zoom-image',
  templateUrl: './zoom-image.component.html',
  styleUrls: ['./zoom-image.component.scss'],
})
export class ZoomImageComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() image: any = null;
  @Output() deleteReview = new EventEmitter<string>();
  @ViewChild('miImagen') miImagen!: ElementRef;
  screenWidth!: number;
  AP: number = 500;
  AI: number = 0;
  public faCircleNorch: any = faCircleNotch;

  public faTimes = faTimes;
  public loadSpin: boolean = false;
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    // Obtener el ancho de la pantalla
    this.screenWidth = window.innerWidth;

    // Calcular AP
    this.AP = Math.floor(this.screenWidth * 0.9);
    // Obtener el ancho de la imagen
    const image = new Image();
    image.src = this.image;

    // image.onload = () => {
    //   this.AI = image.width;
    //   // Comprobar si la imagen es mayor o igual que AP
    //   if (this.AI >= this.AP) {
    //     // Redimensionar la imagen al tamaño de AP
    //     const ratio = this.AP / this.AI;
    //     const canvas = document.createElement('canvas');
    //     canvas.width = this.AP;
    //     canvas.height = image.height * ratio;
    //     const ctx = canvas.getContext('2d');

    //     if (ctx) {
    //       // Verificar si ctx no es nulo
    //       ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    //       const dataURL = canvas.toDataURL();
    //       this.renderer.setAttribute(
    //         this.miImagen.nativeElement,
    //         'src',
    //         dataURL
    //       ); // Usando Renderer2 para establecer src
    //     }
    //   }
    // };
  }

  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
}
