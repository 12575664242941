import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

@Component({
  selector: 'app-questionaire-notification',
  templateUrl: './questionaire-notification.component.html',
  styleUrls: ['./questionaire-notification.component.scss'],
})
export class QuestionaireNotificationComponent implements OnInit {
  @Output() showNotification: EventEmitter<any> = new EventEmitter();
  @Output() openSurvey: EventEmitter<any> = new EventEmitter();
  @Output() hideDefinitely: EventEmitter<any> = new EventEmitter();
  @Input() quest:any=[];
  title:string='quest:invitation:teamroles:text'
  team_name:string=''
  constructor() {}

  ngOnInit(): void {
    console.log('Questionaries',this.quest)
    if (this.quest&&this.quest.level==3){
      this.title='quest:invitation:competencies:text'
    }
    
    if (this.quest&&this.quest.level==4){
      this.title='quest:invitation:teamroles:text'
    }

    if (this.quest&&this.quest.dh_team){ //es un team quest
      this.title='quest:invitation:dhteam:text'
      this.team_name=this.quest.dh_team.name
      console.log(' this.team_name', this.team_name)
    }
    
  }

  hide() {
    this.showNotification.emit();
  }

  acceptAndOpenQuestionnaire() {
    this.openSurvey.emit();
  }
  
  hideDefinitelyNotification() {
    this.hideDefinitely.emit();
  }
}
