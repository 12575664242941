import { Component, Input, OnInit } from '@angular/core';
import { InitService } from 'src/app/services/init.service';
import { GroupsService } from 'src/app/services/groups.service';
// import { Video } from 'src/app/interfaces/video/video';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VideoComponent } from 'src/app/modals/video/video.component';
import { VideoUtilitiesService } from 'src/app/utilities/video-utilities.service';
// import { Paginator } from 'src/app/interfaces/paginator';
// import * as moment from 'moment';
import { LangService } from 'src/app/services/lang.service';
import { faClock  } from '@fortawesome/free-regular-svg-icons';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-group-activity-item-video',
  templateUrl: './group-activity-item-video.component.html',
  styleUrls: ['./group-activity-item-video.component.scss']
})
export class GroupActivityItemVideoComponent implements OnInit {
 
	@Input() activities: any;
  @Input() activity: any;
  @Input() type: string='';
  @Input() playlistId: any = '';
  @Input() fromPlaylistProfile: boolean = false;

	comments: any;
 
  loading = false;
  title = '';
  description = '';
  language: String = 'en';
  faClock = faClock;

  constructor(
    private lang: LangService,
    private initService: InitService,
    private groupsService:GroupsService,
    private modalService: NgbModal,
    private videoUtils: VideoUtilitiesService,
    private route: ActivatedRoute
  ) {
    this.lang.language.subscribe( lang => {
      this.language = lang || 'en';
      this.getTitle();

    });
  }
  ngOnInit(): void {
    if (this.route.snapshot.queryParamMap.get('video_id')) {
      if (this.activity.id == this.route.snapshot.queryParamMap.get('video_id')) {
        this.openVideoModal(this.route.snapshot.queryParamMap.get('video_id'))
      }
    }

    if (this.activities){
      this.activity=this.activities.entity;
      this.comments=this.activities.comment;
    }   
    this.getTitle();
  }

  openVideoModal(id:any) {
    let modalRef = this.modalService.open(VideoComponent, {
      windowClass: 'default-modal video-modal',
      size: 'lg'
    });
    modalRef.componentInstance.videoId = id;
    modalRef.componentInstance.playlistId = this.playlistId;
    modalRef.componentInstance.fromPlaylistProfile = this.fromPlaylistProfile;
    modalRef.componentInstance.selfModal = modalRef;
  }

  getTitle(){
    this.title = this.activity?.info[0]?.name; //by default.
    var info = this.activity?.info;
    if (!info) {
      this.title='';
      return;
    }

    for (let i of info) {
      if (i.language==this.language){
        this.description=i.description;
        this.title=i.name;
      }
    }
    if (!this.title) { this.title = '' }
    if (!this.description) { this.description = '' }

  }

}