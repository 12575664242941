<div class="sidebar-wrapper d-flex d-lg-none">
  <div class="sidebar-bg" (click)="toggleState()" [@darken]="state"></div>
  <div
    class="sidebar flex-shrink-0 p-3 bg-white"
    style="width: 280px; z-index: 10"
    [@slideInOut]="state"
  >
    <app-nav
      [organizations]="organizations"
      (toggle)="toggleState()"
      [inSidebar]="true"
    ></app-nav>
  </div>
</div>
