import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AddUserComponent } from '../add-user/add-user.component';
import { DuplicateComponent } from '../duplicate/duplicate.component';

@Component({
  selector: 'app-make-member-modal',
  templateUrl: './make-member-modal.component.html',
  styleUrls: ['./make-member-modal.component.scss'],
})
export class MakeMemberModalComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() organizations: any = [];
  @Input() user: any = {};
  @Output() refresh = new EventEmitter<any>();
  @Input() playlistId: any = ' ';

  public faCircleNorch: any = faCircleNotch;
  public faTimes = faTimes;
  public names: any = '';
  constructor(private ngbModal: NgbModal) {}

  ngOnInit(): void {
    let names = this.organizations.map((o: any) => o.name);
    this.names = names.join(', ');
  }
  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
  public addUser() {
    console.log('aca llega el user', this.user);
    let modalRef = this.ngbModal.open(AddUserComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.organizations = this.organizations;
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.refresh.subscribe((data: any) => {
      this.refresh.emit();
    });
  }
  public duplicate() {
    this.refresh.emit();

    let modalRef = this.ngbModal.open(DuplicateComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.organizations = this.organizations;
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.playlistId = this.playlistId;
  }
}
