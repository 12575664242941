 import { Component, Input, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-search-owner',
  templateUrl: './search-owner.component.html',
  styleUrls: ['./search-owner.component.scss'],
})
export class SearchOwnerComponent implements OnInit {
  @Input() placeholder: string = '';
  @Input() internalUsers: boolean = true;
  @Input() maxUsers = 1;
  @Input() type: string = '';
  @Input() collection: any;
  @Input() filterByOrganization: boolean = true;
  @Output() searchEvent = new EventEmitter<any>();
  @Input() usersSelected: Array<any> = [];

  searchTxt = '';
  usersSearch: any;
  ifIsValidToInvite: boolean = false;
  usersList: Array<any> = [];
  timer: any;
  loadingUsers = true;
  delaySearch = false;
  constructor(
    private usersService: UsersService,
  ) { }

  reset() {
    this.searchTxt = '';
    this.usersSearch = [];
    this.ifIsValidToInvite = false;
    this.usersSelected = [];
    this.usersList = [];
  }

  ngOnInit(): void {
    this.searchUsers();
    
  }
  newSearch(){
    if (  this.loadingUsers ) return;
    if (this.usersSelected&&this.usersSelected.length==0){
      this.callSearch();
    }
  }
  onSearch() {
    this.loadingUsers = true;
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.callSearch();
    }, 800);
  }

  searchUsers() {

    this.loadingUsers = true;
    if (this.type == 'playlist' || this.type == 'group') {
      this.usersService
        .getAccepted(this.searchTxt, this.collection.id)
        .then((resp) => {
          this.ifIsValidToInvite = true;
          let list = resp?.data?.enrolled_users.map((data: any) => data.user);
          //delete item of list that is in userList
          for (var i = list.length - 1; i >= 0; i--) {
            for (var j = 0; j < this.usersSelected.length; j++) {
              if (list[i] && list[i].id === this.usersSelected[j].id) {
                list.splice(i, 1);
              }
            }
          }


          this.usersList = list;
          this.usersSearch = this.usersList;
          this.loadingUsers = false;

        });
    } else {
      this.usersService
        .searchUsers(true, this.searchTxt)
        .then((resp) => {

          this.usersList = resp.data
          this.usersSearch = this.usersList;

          this.loadingUsers = false;
        })
    }
  }
  callSearch() {
    
    this.loadingUsers = true;

    this.usersSearch = this.usersList.filter(user => {
      const searchTermLower = this.searchTxt.toLowerCase();
      return user.display_name.toLowerCase().includes(searchTermLower) || user.email.toLowerCase().includes(searchTermLower);
    });
    this.loadingUsers = false;
  }

  onAdd(user: any) {
    console.log(user);
    this.usersSelected = [user];
    this.searchTxt = '';
    this.usersSearch = [];
    this.ifIsValidToInvite = false;
    this.searchEvent.emit(this.usersSelected);
  }

   
   
}
