import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/services/admin.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.scss'],
})
export class CourseDetailComponent implements OnInit {
  @Output() refresh = new EventEmitter<any>();
  @Output() openOrderDetail = new EventEmitter<any>();
  @Input() selfModal: NgbModalRef | null = null;
  @Input() course: any;
  public data: any;
  @Input() avatar: any;
  public faCircleNorch: any = faCircleNotch;
  public faTimes = faTimes;
  public loading = false;
  constructor(
    private adminService: AdminService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.getInfo();
  }

  closeModal() {
    this.refresh.emit();
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  public getInfo() {
    console.log('esta es la data ', this.course);

    this.loading = true;
    this.adminService
      .getCourseDetail(this.course.id)
      .then((result) => {
        this.loading = false;
        this.data = result.data;
      })
      .catch((err) => {});
  }

  public openViewOrder(data: any) {
    this.openOrderDetail.emit(data);
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
}
