<div class="table-responsive">
  <table class="table" *ngIf="usersSelected">
    <tbody>
      <tr *ngFor="let user of usersSelected; let i = index">
        <td class="align-middle">
          <app-user-icon class="col-auto" [user]="user"></app-user-icon>
        </td>
        <td class="align-middle fw-bolder" style="width: 407px">
          {{ user.email }}
        </td>
        <td class="align-middle">
          <input
            id="selectMembersInput"
            *ngIf="user?.dh_profiles?.length == 0 || !fromDHSubTeam"
            class="input-box w-100"
            [disabled]="!user.edit"
            type="text"
            [(ngModel)]="user.display_name"
            auto-focus
            autofocus
          />
          <div
            *ngIf="user?.dh_profiles?.length > 0 && fromDHSubTeam"
            ngbDropdown
            class="d-inline-block mt-2 button-select"
          >
            <button
              type="button"
              class="btn btn-outline-primary"
              id="dropdownBasic1"
              ngbDropdownToggle
            >
              {{ user?.select || "Select dhProfile" }}
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button *ngFor="let user of user?.dh_profiles" ngbDropdownItem>
                <div style="display: flex" (click)="selectUserInvited(user, i)">
                  <img
                    class="icon-user"
                    src="{{
                      user.avatarPathUrlSmall ||
                        user.avatar ||
                        'assets/img/avatars/profile-avatar-purple.svg'
                    }}"
                  />
                  <span class="d-flex flex-column" style="float: left"
                    >{{ user?.dh_team?.name || user.id }}
                    <small class="fw-lighter text-muted">{{
                      user?.created_at | date : "dd LLL YYYY"
                    }}</small>
                  </span>
                </div>
              </button>
            </div>
            <div>
              <img
              (click)="deleteUserInvited(i)"
              class="img-trash"
              src="../../../../../assets/statics/img/trash-can.svg"
              alt="img trash"
            />
            </div>
          </div>
        </td>
        
        
        <td class="align-middle">
          <img
            (click)="deleteUserInvited(i)"
            class="img-trash"
            src="../../../../../assets/statics/img/trash-can.svg"
            alt="img trash"
          />
        </td>



      </tr>
    </tbody>
  </table>
</div>
