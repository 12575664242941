import { DigitalOceanMessageComponent } from './../../modals/digital-ocean-message/digital-ocean-message.component';
import { WelcomeUserComponent } from './../../modals/welcome-user/welcome-user.component';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DashboardSection } from 'src/app/interfaces/dashboard-section';
import { Organization } from 'src/app/interfaces/organization';
import { DashboardService } from 'src/app/services/dashboard.service';
import { InitService } from 'src/app/services/init.service';
import { LangService } from 'src/app/services/lang.service';
import { CoursesService } from 'src/app/services/courses.service';
import { AuthService } from 'src/app/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DhprofileService } from 'src/app/services/dhprofile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Questionnaire } from 'src/app/interfaces/questionnaire';
import { AdminService } from 'src/app/services/admin.service';
import { NewPrivacyComponent } from 'src/app/modals/new-privacy/new-privacy.component';
import { MainComponent } from '../course-viewer/main/main.component';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { UsersService } from 'src/app/services/users.service';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  sections: Array<DashboardSection> = [];
  featured: DashboardSection | null | undefined;
  currentLang: string = 'en';
  org: Organization | any = null;
  user: any;
  questionnaireId: any = '';
  lastactivecourse: any = [];
  arrayReviews: any[] = [];
  loading = true;
  showWidgetMantenance = false;
  showNotificationMantenance = true;
  showNotification = false;
  showWidget = false;
  maintenance: any;
  questionnaires: Array<Questionnaire> = [];
  lastPosition: number = 0;

  questionnaireSettings: any = {
    displayedwidget: false,
    displayedNotification: false,
    hideDefinitely: false,
    questionnaire: {},
  };

  //guardamos la configuracion para mostrar o no el mensaje de mantenimiento.
  maintenanceSettings: any = {
    displayedwidget: false,
    displayedNotification: true,
    hideDefinitely: false,
  };

  constructor(
    private adminService: AdminService,
    private dashboardServ: DashboardService,
    private lang: LangService,
    private initService: InitService,
    private coursesService: CoursesService,
    private authService: AuthService,
    private ngbModal: NgbModal,
    private route: ActivatedRoute,
    private dhprofileService: DhprofileService,
    private router: Router,
    private usersService: UsersService,
    private os: OrganizationsService,
    private toastr: ToastService,
    private translate: TranslateService
  ) {
    this.lang.language.subscribe((lang) => {
      this.currentLang = lang || 'en';
    });

    this.org = this.initService.getCurrentOrg();
    this.authService.user.subscribe((user: any) => {
      this.getNewSections();
      if (user) {
        this.user = user;

        this.coursesService.getLastActiveCourse(user.id).then((res) => {
          this.lastactivecourse = res?.data;
        });
        this.coursesService.getPreviewLesson().then((res) => {
          this.arrayReviews = res.data[0]?.contents.map((item: any) => {
            //necesito obtener la imagen
            let img_src = '';
            if (item.content.type == 'video') {
              img_src = item.content?.video_post
                ? item.content.video_post.thumb_url
                : '';
            }
            if (img_src == '') {
              //un intento mas
              item.content.lesson_blocks.forEach((data: any) => {
                if (data.type == 'video') {
                  img_src = data.video.thumb_url;
                  return;
                }
                if (data.type == 'file' && img_src == '') {
                  //comparo con img_src para quedarme con la primera
                  img_src = data.file?.pathUrlMedium;
                }
                if (data.type == 'text' && img_src == '') {
                  img_src = '';
                }
              });
            }

            return {
              name: item.content.name,
              courseName: item?.content?.chapter?.collections[0]?.name || '',
              type: item.content.type,
              lessonId: item.content_id,
              courseId:
                item.content?.chapter?.collections[0]?.id ||
                item.content.content_id,
              img: item.content.lesson_blocks.find(
                (img: any) => img.file && img.file.mimetype == 'image/jpeg'
              ),
              img_src: img_src,
            };
          });
        });
      }
    });
  }

  private modalIsOpen: boolean = false;
  ngAfterViewInit(): void {
    if (this.isAuthenticated()) {
      if (!this.user.accepted_privacy_policy) {
        // this.modalNewPrivacy();
      }
    }
    let displayedMessage = localStorage.getItem('displayedMessage') || false;
    if (
      !displayedMessage &&
      this.org!.show_start_message &&
      this.isAuthenticated()
    ) {
      localStorage.setItem(
        'displayedMessage',
        JSON.stringify(this.org!.show_start_message)
      );
      this.modalWelcome();
    } else {
      const today = new Date();
      const cutoffDate = new Date('August 15, 2023');

      if (today < cutoffDate) {
        let displayedMessageOcean =
          localStorage.getItem('OceanMessage') || false;
        if (!displayedMessageOcean) {
          localStorage.setItem('OceanMessage', JSON.stringify(true));
          // this.modalOcean();
        }
      }
    }
  }

  ngOnInit(): void {
    if (this.isAuthenticated()) {
      this.route.params.subscribe((params) => {
        this.lang.setDefault(); // cambia el idioma dependiendo de la organizacion
        const member = params['member'];
        if (member) {
          //si hay un acetacion de invitacion
          this.setMemberToUser(member);
        }
        let id = params['user_id'];
        //voy a traer los cuestionarios pendientes del usuario
        this.dhprofileService.getQuestionnairesList(id).then((list: any) => {
          //quiero que se muestren todos los cuestionarios
          // if (this.isNormalUser()) {
          //   this.questionnaires = list.filter((item: any) => {
          //     return item?.dh_team?.hypothetical == false;
          //   });
          // } else {
          //   this.questionnaires = list;
          // }

          // por eso comento lo de arriba
          this.questionnaires = list;

          //console.log('this.questionnaires', this.questionnaires)
          this.lastPosition = list.length - 1;
          this.questionnaireId = list[this.lastPosition]?.id;
          this.questionnaireSettings.questionnaire =
            this.questionnaires[this.lastPosition];
          //console.log('this.questionnaireSettings', this.questionnaireSettings)

          if (
            JSON.parse(localStorage.getItem('questionnaireSettings')!)
              ?.questionnaire
          ) {
            let currentQuest = JSON.parse(
              localStorage.getItem('questionnaireSettings')!
            ).questionnaire;

            let lastQuest = list[this.lastPosition];
            if (lastQuest && currentQuest.id != lastQuest.id) {
              this.showNotification = true;
              this.questionnaireSettings.displayedNotification = true;
              localStorage.setItem(
                'questionnaireSettings',
                JSON.stringify(this.questionnaireSettings)
              );
            }
            // console.log('if localstorage',   this.showNotification)
          }

          if (
            JSON.parse(localStorage.getItem('questionnaireSettings')!)
              ?.hideDefinitely
          ) {
            this.showNotification = false;
            this.showWidget = false;
          } else {
            if (
              JSON.parse(localStorage.getItem('questionnaireSettings')!)
                ?.displayedwidget
            ) {
              this.showWidget = true;
            } else {
              if (
                JSON.parse(localStorage.getItem('questionnaireSettings')!)
                  ?.displayedNotification &&
                this.questionnaires.length > 0
              ) {
                this.showNotification = true;
              }
            }
            if (
              !JSON.parse(localStorage.getItem('questionnaireSettings')!)
                ?.displayedNotification &&
              !JSON.parse(localStorage.getItem('questionnaireSettings')!)
                ?.displayedwidget &&
              this.questionnaires.length > 0
            ) {
              this.showNotification = true;
              if (this.questionnaires.length > 0) {
                this.questionnaireSettings.displayedNotification = true;
                localStorage.setItem(
                  'questionnaireSettings',
                  JSON.stringify(this.questionnaireSettings)
                );
              }
            }
          }
        });

        //controlo si hay mantenimiento programado

        //      console.log('if fin',   this.showNotification)

        this.getMantenaceMsg();
      });
    } else {
      this.route.params.subscribe((params) => {
        // this.lang.setDefault();
      });
    }
  }

  setMemberToUser(member: any) {
    this.os
      .addMember(member)
      .then((member) => {
        this.toastr.showSuccess(
          this.translate.instant(
            'organization:members:accept:invitation:success'
          )
        );
        console.log('testing');
        setTimeout(() => {
          if (
            member.collection &&
            member.collection.id &&
            member.collection.type
          ) {
            switch (member.collection.type) {
              case 'playlist':
                this.router.navigate(['/playlists', member.collection.id]);
                break;
              case 'group':
                this.router.navigate(['/groups', member.collection.id]);
                break;
              case 'course':
                this.router.navigate(['/courses', member.collection.id]);
                break;
              case 'dh_team':
                this.router.navigate(['dh/profile']);
                break;
              case 'dh_profile':
                this.router.navigate(['dh/profile']);
                break;
            }
          }
          console.log('testing2');
        }, 2000);
      })
      .catch((err) => {
        let message =
          err.error?.message?.friendly ||
          this.translate.instant(
            'organization:members:accept:invitation:error'
          );
        this.toastr.showError(message);
        this.router.navigate(['/']);
      });
  }

  getMantenaceMsg() {
    this.adminService.hasMaintenance().then((resp: any) => {
      if (resp.data) {
        //voy a controlar si esta guardad la configuracion de mostrar o no el msg
        let date = resp.data.init_date ? resp.data.init_date : '';
        let storeData = JSON.parse(
          localStorage.getItem('maintenanceSettings' + date)!
        );
        console.log('storeData', storeData);

        if (storeData && storeData.hideDefinitely) {
          this.showNotificationMantenance = false;
          this.showWidgetMantenance = false;
        } else {
          if (storeData && storeData.displayedwidget) {
            this.showNotificationMantenance = false;
            this.showWidgetMantenance = true;
          } else {
            this.showNotificationMantenance = true;
            this.showWidgetMantenance = false;
          }
        }

        this.maintenance = resp.data;
        const dateT = new Date(this.maintenance.init_date);
        console.log('dateT', dateT);
      }
      //console.log(resp.data[0]);
    });
  }
  ///////////////
  //Questionnaire
  ///////////////
  showMaintenance() {
    this.showWidgetMantenance = false;
    this.showNotificationMantenance = true;
  }
  showQuestionnaire() {
    this.showWidget = false;
    this.showNotification = true;

    this.questionnaireSettings.displayedwidget = false;
    this.questionnaireSettings.displayedNotification = true;
    localStorage.setItem(
      'questionnaireSettings',
      JSON.stringify(this.questionnaireSettings)
    );
  }

  public hideDefinitelyNotificationMaintenance() {
    this.showNotificationMantenance = false; //oculto show meesage y widget
    this.showWidgetMantenance = false;

    this.maintenanceSettings.displayedwidget = false;
    this.maintenanceSettings.displayedNotification = false;
    this.maintenanceSettings.hideDefinitely = true;
    console.log('guardando data');
    localStorage.setItem(
      'maintenanceSettings' + this.maintenance.init_date,
      JSON.stringify(this.maintenanceSettings)
    );
  }

  public hideDefinitelyNotification() {
    this.showWidget = false;
    this.showNotification = false;

    this.questionnaireSettings.displayedwidget = false;
    this.questionnaireSettings.displayedNotification = false;
    this.questionnaireSettings.hideDefinitely = true;
    localStorage.setItem(
      'questionnaireSettings',
      JSON.stringify(this.questionnaireSettings)
    );
  }

  public getInfoNotification() {
    this.showWidget = true;
    this.showNotification = false;
    console.log('test');
    this.questionnaireSettings.displayedwidget = true;
    this.questionnaireSettings.displayedNotification = false;
    localStorage.setItem(
      'questionnaireSettings',
      JSON.stringify(this.questionnaireSettings)
    );
  }
  public acceptAndOpenQuestionnaire() {
    this.router.navigate(['/dh', 'questionnaire', this.questionnaireId]);
  }
  public acceptMaintenance() {
    this.showWidgetMantenance = true;
    this.showNotificationMantenance = false;

    this.maintenanceSettings.displayedwidget = true;
    this.maintenanceSettings.displayedNotification = false;
    this.maintenanceSettings.hideDefinitely = false;
    console.log('guardando data');
    localStorage.setItem(
      'maintenanceSettings' + this.maintenance.init_date,
      JSON.stringify(this.maintenanceSettings)
    );
  }

  ////////////
  ////////////
  ////////////

  public getNewSections() {
    this.dashboardServ.getNewSections().then((data) => {
      this.featured = data.shift();
      //filter coruse by lang
      if (this.featured?.contents) {
        this.featured.contents = this.featured.contents.filter((data) => {
          if (!data || !data.content) {
            return false;
          }
          if (data.type =='playlist') {
            return true;
          }

          // Verificar el idioma directamente en content
          if (data.content.language === this.currentLang) {
            return true;
          }

          // Verificar el idioma en info si existe
          if (
            Array.isArray(data.content.info) &&
            data.content.info.length > 0
          ) {
            return data.content.info[0].language === 'nl' || data.content.info[0].language === 'en' ;
          }

          return false;
        });
      }

      this.sections = data;
      this.loading = false;
    });
  }
  public organizationWelcomeText(): string {
    if (this.currentLang == 'en') {
      return (
        this.org!.welcome_text_en ||
        'Welcome to ' + this.org!.name + ' Movielearning'
      );
    }
    return (
      this.org!.welcome_text_nl ||
      'Welkom bij ' + this.org!.name + ' Movielearning'
    );
  }

  modalWelcome() {
    let welcomeText =
      this.currentLang == 'en'
        ? this.org!.welcome_text_en
        : this.org!.welcome_text_nl;

    let modalRef = this.ngbModal.open(WelcomeUserComponent, {
      windowClass: 'default-modal',
      size: '700',
      centered: true,
    });
    modalRef.componentInstance.description = this.org!.start_message;
    modalRef.componentInstance.title = welcomeText;
    modalRef.componentInstance.outputEvent.subscribe((value: any) => {});
    modalRef.result
      .then(() => {
        this.modalIsOpen = false;
      })
      .catch(() => {
        const today = new Date();
        const cutoffDate = new Date('August 15, 2023');

        if (today < cutoffDate) {
          let displayedMessageOcean =
            localStorage.getItem('OceanMessage') || false;
          if (!displayedMessageOcean) {
            localStorage.setItem('OceanMessage', JSON.stringify(true));
            // this.modalOcean();
          }
        }
      });
  }
  modalNewPrivacy() {
    // let modalRef = this.ngbModal.open(NewPrivacyComponent, {
    //   windowClass: 'default-modal',
    //   size: '700',
    //   centered: true,
    // });
    // modalRef.componentInstance.outputEvent.subscribe((value: any) => {});
    // modalRef.result.then(() => {}).catch(() => {});
    // this.usersService.setAcceptedPrivacyPolicy(this.user.username);
  }

  modalOcean() {
    // localStorage.setItem('OceanMessage', JSON.stringify(true));
    // let modalRef = this.ngbModal.open(DigitalOceanMessageComponent, {
    //   windowClass: 'default-modal',
    //   size: '700',
    //   centered: true,
    // });
    // modalRef.componentInstance.selfModal = modalRef;
    // modalRef.componentInstance.outputEvent.subscribe((value: any) => {});
  }

  isAuthenticated() {
    return this.authService.isAuthenticated();
  }

  scrollTo(className: string = 'reviews-container'): void {
    const elementList = document.querySelectorAll('.' + className);
    const element = elementList[0] as HTMLElement;
    element.scrollIntoView({ behavior: 'smooth' });
  }
  public isNormalUser() {
    return this.authService.hasAnyRole(['user', 'normal']);
  }
}
