<div class="row justify-content-center">
    <div *ngFor="let subscription of subscriptions" class="col-auto">
        <div class="card">
            <div class="card-body d-flex flex-column align-items-center fs-14">
                <div *ngIf="subscription.discount" class="badge rounded-pill bg-danger">{{ 'discount' | translate }}</div>
                
                <p class="mb-2" *ngIf="(subscription.subscription_days / 30).toFixed(0) != '12'">
                    {{ 'courses:profile:view:subscription:months:' + (( subscription.subscription_days / 30).toFixed(0) == '1' ? 'singular' : 'plural') | translate }}
                    {{ "access" | translate }}
                </p>
                <p class="mb-2" *ngIf="(subscription.subscription_days / 30).toFixed(0) == '12'">
                    {{ "course:subscription:months:12" | translate }} {{ "access" | translate }}
                </p>

                <h1 class="mb-1">
                    <span class="striked-text" style="opacity: 0.6; margin-right: 5px;" *ngIf="subscription.discount">
                        {{ subscription.originalPrice | currency:'EUR':'symbol':'4.2-2' }}
                    </span>
                    {{ subscription.price | currency:'EUR':'symbol':'.0' }}
                </h1>

                <p class="text-muted">{{ 'vat:included' | translate }}</p>

                <p class="text-center">{{ 'course:subscription:access:days' | translate:{ days: subscription.subscription_days } }}</p>

                <a class="btn btn-primary p-2 px-4">
                    <ng-container *ngIf="!subscription.subscription_type; else btnProlong">
                        {{ 'course:subscription:buy:button' | translate }}
                    </ng-container>
                    <ng-template #btnProlong>
                        <span *ngIf="(subscription.subscription_days / 30).toFixed(0) != '12'">
                            {{ "amplify:add" | translate }} {{ (subscription.subscription_days / 30).toFixed(0) }}
                            {{ 'courses:profile:view:subscription:months:' + ((subscription.subscription_days / 30).toFixed(0) == '1' ? 'singular' : 'plural') | translate }} {{ "access" | translate }}
                        </span>
    
                        <span *ngIf="(subscription.subscription_days / 30).toFixed(0) == '12'">
                            {{ "amplify:add" | translate }}
                            {{ "course:subscription:months:12" | translate }}
                        </span>
                    </ng-template>
                </a>
            </div>
        </div>
    </div>
</div>
