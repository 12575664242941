<div *ngIf="course" class="icons">
    <div class="d-flex float-left mb-2">
        <fa-icon [icon]="faGlobe" class="me-2"></fa-icon>
        <span *ngIf="course.language == 'en'"><b>{{"courses:profile:about:options:language" | translate}}</b><br>{{ "language:options:en" | translate }}</span>
        <span *ngIf="course.language == 'nl'"><b>{{"courses:profile:about:options:language" | translate}}</b><br>{{ "language:options:nl" | translate }}</span>
    </div>
    <div class="d-flex float-left mb-2">
        <fa-icon [icon]="faClock" class="me-2"></fa-icon> 
        <span><b>{{ "courses:profile:about:options:time" | translate }}</b><br>{{getEstimatedLessonsTime()}}</span>      
    </div>
    <div *ngIf="!hFlag">
        <div class="d-flex float-left mb-2">
            <fa-icon [icon]="faPlay" class="me-2"></fa-icon> 
            <span><b>{{"courses:profile:about:options:video" | translate}}</b><br>{{getEstimatedTimeVideoLessons()}}</span>      
        </div>
        <div class="d-flex float-left mb-2">
            <fa-icon [icon]="faText" class="me-2"></fa-icon> 
            <span><b>{{"courses:profile:about:options:reading" | translate}}</b><br>{{getEstimatedTimeTextLessons()}}</span>      
        </div>

        <div *ngIf="getEstimatedTimeQA()!='0 min'" class="d-flex float-left mb-2">
            <fa-icon [icon]="faClock" class="me-2"></fa-icon> 
            <span><b>{{"courses:profile:about:options:questions" | translate}}</b><br>{{getEstimatedTimeQA()}}</span>      
        </div>
    </div>
</div>