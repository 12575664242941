<div class="table-responsive-sm mb-4">
  <table class="table table-hover mt-5" #container>
    <thead>
      <tr class="" [ngClass]="{ 'custom-border': true }">
        <th class="text-center" scope="col"></th>
        <th scope="col" class="my-3 align-right">
          <span>
            {{ "organization:settings:users:table:title:1" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-right">
          <span>
            {{ "Email address" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-right">
          <span>
            {{ "Course" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "Description" | translate }}
          </span>
        </th>
        
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "Contacted" | translate }}
          </span>
        </th>
        
      </tr>
    </thead>
    <tbody>
      <ng-container>
        <tr
          [ngClass]="{ 'custom-border': true }"
          *ngFor="let data of listProspects; let i = index"
        >
        <td class="justify-content-center align-middle">
          <app-user-icon size="medium" [user]="data?.user"></app-user-icon>
        </td>
        <td class="title-course align-middle">
          {{ data?.user.username | excerpt : 50 }}
        </td>
          <td class="justify-content-center align-middle">
            {{data?.email}}
          </td>
      
          <td class="align-middle">
            {{ data?.collection?.name}}
          </td>
          <td class="align-middle" style="white-space: pre-line;" >
            <span [innerHtml]="data?.description"></span>
           
          </td>

          
          <td class="align-middle text-center">
            <input [(ngModel)]="data.contacted" (click)="sendContacted(data.id,!data.contacted)" type="checkbox" id="is_discounted" class="form-check-input offset-1 ng-pristine ng-valid ng-touched" >
          </td>
          
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
