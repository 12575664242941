import { TermsConditionComponent } from './../terms-condition/terms-condition.component';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { trigger, style, animate, transition } from '@angular/animations';
import { ToastService } from 'src/app/services/toast.service';
import { AuthService } from 'src/app/services/auth.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  faCheckCircle,
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Organization } from 'src/app/interfaces/organization';
import { timer } from 'rxjs';
import { finalize, scan, takeWhile } from 'rxjs/operators';
import { OrganizationUtilitiesService } from 'src/app/utilities/organization-utilities.service';
import { LangService } from 'src/app/services/lang.service';
import { InitService } from 'src/app/services/init.service';
import { environment } from 'src/environments/environment';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [style({ display: 'none' })]),
    ]),
  ],
})
export class LoginComponent implements OnInit, AfterViewInit {
  @Output('close') close = new EventEmitter();
  @Output('success') success = new EventEmitter();
  @Input() state: string = 'login';
  @Input() info: any = {};
  @Input() messageRegister: boolean = false;
  @Input() organization: Organization | undefined;
  @Input() reloadregister = false;
  @Input() message: string | undefined;
  @Input() showMessage: boolean = false;
  @Input() openNormalLogin: boolean = false;

  showPassword: boolean = false;
  faCircleNorch = faCircleNotch;
  faCheckCircle = faCheckCircle;

  loginForm: UntypedFormGroup;
  registerForm: UntypedFormGroup;
  forgotForm: UntypedFormGroup;

  loading: boolean = false;

  currentLang = 'en';
  currentOrg: Organization | undefined;

  blockedIp = false;
  blockedIpTime = 3 * 60;
  timer$: any;
  checkLogin: boolean = false;
  ssoUrl = environment.apiUrl.concat('/auth/sso');
  ///////////
  //Captcha
  ///////////
  public mainCaptcha: string = '';
  public loginAttempts: number = 0;
  public code: any = [
    {
      catpcha: '',
    },
  ];
  public isCollapsed = true;
  public loginStatus: string = 'load';

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private toastService: ToastService,
    private translate: TranslateService,
    private ngbModal: NgbModal,
    private orgUtils: OrganizationUtilitiesService,
    private lang: LangService,
    private init: InitService,
    private gtmService: GoogleTagManagerService,
    private route: ActivatedRoute
  ) {
    this.currentOrg = this.init.getCurrentOrg()!;

    this.loginForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ],
      ],
      password: ['', Validators.required],
      captcha: [''],
      checkLogin: [false, Validators.required],
    });

    this.registerForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ],
      ],
      username: ['', [Validators.required]],
      language: [this.currentLang, []],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          lowerCount(),
          upperCount(),
          numberCount(),
          specialCount(),
        ],
      ],
    });

    if (this.currentOrg.show_terms_conditions) {
      this.registerForm.addControl(
        'terms_conditions',
        this.fb.control(false, [Validators.requiredTrue])
      );
    }

    if (this.currentOrg.show_security_policy) {
      // this.registerForm.addControl(
      //   'security_policy',
      //   this.fb.control(false, [Validators.requiredTrue])
      // );
    }

    this.forgotForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ],
      ],
    });
  }
  ngAfterViewInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.lang.language.subscribe((lang) => {
        this.currentLang = lang || 'en';
      });
    });
  }

  ngOnInit(): void {
    if (this.info) {
      this.registerForm.controls['email'].setValue(this.info.email);
      this.registerForm.controls['username'].setValue(this.info.username);
      this.registerForm.controls['password'].setValue(this.info.password || '');
      this.registerForm.controls['terms_conditions'].setValue(
        this.info.terms_conditions
      );
      this.registerForm.controls['security_policy']?.setValue(
        this.info.security_policy
      );
    }
    if (JSON.parse(localStorage.getItem('email')!)) {
      this.loginForm.controls.email.setValue(
        JSON.parse(localStorage.getItem('email')!)
      );
    }
  }
  showHidePassword() {
    this.showPassword = !this.showPassword;
  }

  changeState(state: string): void {
    this.state = state;
  }

  login() {
    if (this.loginForm.valid) {
      if (this.loginForm.get('checkLogin')!.value) {
        localStorage.setItem(
          'email',
          JSON.stringify(this.loginForm.get('email')!.value)
        );
      }
      this.loading = true;
      this.authService
        .logIn(
          this.loginForm.get('email')!.value,
          this.loginForm.get('password')!.value
        )
        .then((res) => {
          // this.toastService.showSuccess('Loged in');
          this.loading = false;

          this.success.emit();
          const gtmTag = {
            event: 'signed_up',
            data: {
              user: this.loginForm.get('email')!.value,
            },
          };
          if (this.currentOrg?.slug == 'courses') {
            console.log('Sending GTM', gtmTag);
            this.gtmService.pushTag(gtmTag);
          }
        })
        .catch((err) => {
          this.loginAttempts += 1;
          console.log('Login error!', this.loginAttempts, err);
          if (this.loginAttempts >= 3) {
            this.code.captcha = '';
            this.loginForm.controls.captcha.setValue('');
            this.loginStatus = 'captcha';
            this.captcha();
          }
          if (err.error!.message!.type == 'TooManyAttempts') {
            // this.toastService.showError(
            //   this.translate.instant("block:ip")
            // );
            this.blockIp();
          }
          if (err.error.message?.friendly) {
            if (err.error.message.friendly.message) {
              this.toastService.showError(
                this.translate.instant(err.error.message.friendly.message)
              );
            } else {
              this.toastService.showError(
                this.translate.instant(err.error.message.friendly)
              );
            }
          } else {
            this.toastService.showError(
              this.translate.instant('login:member:error')
            );
          }
          this.loading = false;
        });
    } else {
      if (this.loginForm.controls['email'].hasError('required')) {
        this.toastService.showError(
          this.translate.instant('login:email:required')
        );
        return;
      }

      if (this.loginForm.controls['email'].hasError('pattern')) {
        this.toastService.showError(
          this.translate.instant('login:email:email')
        );
        return;
      }

      if (this.loginForm.controls['password'].hasError('required')) {
        this.toastService.showError(
          this.translate.instant('login:password:required')
        );
        return;
      }

      return;
    }
  }

  register() {
    if (this.registerForm.valid) {
      this.loading = true;
      let email = this.registerForm.get('email')!.value;
      let password = this.registerForm.get('password')!.value;
      this.authService
        .register(
          email,
          this.registerForm.get('username')!.value,
          password,
          '',
          this.currentLang
        )
        .then((res) => {
          // this.toastService.showSuccess(
          //   this.translate.instant('register:success:confirm:email', {
          //     email: email,
          //   })
          // );
          const gtmTag = {
            event: 'account_registration',
          };
          if (this.currentOrg?.slug == 'courses') {
            console.log('Sending GTM', gtmTag);
            this.gtmService.pushTag(gtmTag);
          }
          this.authService
            .logIn(email, password, this.reloadregister)
            .then((res) => {
              this.success.emit();
              this.loading = false;
            })
            .catch((err) => {
              if (err.error.message?.friendly) {
                this.toastService.showError(err.error.message.friendly);
              } else {
                this.toastService.showError('Login error');
              }
              this.loading = false;
            });
        })
        .catch((err) => {
          if (err.error.message?.friendly) {
            this.toastService.showError(err.error.message.friendly);
          } else {
            this.toastService.showError(
              this.translate.instant('sign_up:error')
            );
          }
          this.loading = false;
        });
    } else {
      if (
        this.registerForm.controls['email'].errors ||
        this.registerForm.controls['username'].errors ||
        this.registerForm.controls['password'].errors
      ) {
        for (const i in this.registerForm.controls) {
          this.registerForm.controls[i].markAsTouched();
        }

        if (this.registerForm.controls['email'].hasError('required')) {
          this.toastService.showError(
            this.translate.instant('sign_up:email:required')
          );
          return;
        }

        if (this.registerForm.controls['email'].hasError('pattern')) {
          this.toastService.showError(
            this.translate.instant('sign_up:email:email')
          );
          return;
        }

        if (this.registerForm.controls['username'].hasError('required')) {
          this.toastService.showError(
            this.translate.instant('sign_up:username:required')
          );
          return;
        }

        if (this.registerForm.controls['password'].hasError('required')) {
          this.toastService.showError(
            this.translate.instant('sign_up:password:required')
          );
          return;
        }
      } else if (
        this.registerForm.controls['terms_conditions'].hasError('required')
      ) {
        this.toastService.showError(
          this.translate.instant('sign_up:terms:required')
        );
      } else if (
        this.registerForm.controls['security_policy'].hasError('required')
      ) {
        this.toastService.showError(
          this.translate.instant('sign_up:privacy:required')
        );
      }
    }
  }

  forgot() {
    if (this.forgotForm.valid) {
      this.loading = true;

      this.authService
        .forgot(this.forgotForm.controls['email'].value)
        .then((res) => {
          this.toastService.showSuccess(
            this.translate.instant('login:reset_password:success')
          );
          this.loading = false;
          this.close.emit();
        })
        .catch((err) => {
          if (err.error.message?.friendly) {
            this.toastService.showError(err.error.message.friendly);
          } else {
            this.toastService.showError(
              this.translate.instant('login:reset_password:error')
            );
          }
          this.loading = false;
        });
    } else {
      if (this.forgotForm.controls['email'].hasError('required')) {
        this.toastService.showError(
          this.translate.instant('login:reset_password:email:required')
        );
        return;
      }
      if (this.forgotForm.controls['email'].hasError('pattern')) {
        this.toastService.showError(
          this.translate.instant('login:reset_password:email:email')
        );
        return;
      }
      return;
    }
  }

  blockIp() {
    this.timer$ = timer(0, 1000).pipe(
      scan((acc) => --acc, this.blockedIpTime),
      takeWhile((x) => x >= 0),
      finalize(() => {
        this.blockedIp = false;
      })
    );
    this.blockedIp = true;
  }

  openNormalTerms() {
    let modalRef = this.ngbModal.open(TermsConditionComponent, {
      windowClass: 'default-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.title = this.translate.instant(
      'login:modal:terms:title'
    );
    modalRef.componentInstance.normal = this.openNormalLogin;

    let text = this.currentOrg?.show_terms_conditions.texts.en;
    if (this.currentLang == 'nl') {
      text = this.currentOrg?.show_terms_conditions.texts.nl;
    }

    modalRef.componentInstance.description =
      typeof text == 'object' ? text.long_description : text;
  }
  public openModalTerms() {
    this.close.emit();

    let modalRef = this.ngbModal.open(TermsConditionComponent, {
      windowClass: 'default-modal2',
      size: '600',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.selfModal = modalRef;
    modalRef.componentInstance.normal = this.openNormalLogin;
    modalRef.componentInstance.close.subscribe((data: any) => {
      // if (this.registerForm.controls['terms_conditions'].value) {
      //   setTimeout(() => {
      //     let node = document.getElementById('check1');
      //     (node as HTMLElement).click();
      //   }, 500);
      // }
      // if (this.registerForm.controls['security_policy'].value) {
      //   setTimeout(() => {
      //     let node = document.getElementById('check2');
      //     (node as HTMLElement).click();
      //   }, 500);
      // }
    });
    modalRef.componentInstance.info = {
      email: this.registerForm.get('email')!.value,
      username: this.registerForm.get('username')!.value,
      password: this.registerForm.get('password')!.value,
      terms_conditions: this.registerForm.get('terms_conditions')?.value,
      security_policy: this.registerForm.get('security_policy')?.value,
    };
    modalRef.componentInstance.title = this.translate.instant(
      'login:modal:terms:title'
    );

    let text = this.currentOrg?.show_terms_conditions.texts.en;
    if (this.currentLang == 'nl') {
      text = this.currentOrg?.show_terms_conditions.texts.nl;
    }

    modalRef.componentInstance.description =
      typeof text == 'object' ? text.long_description : text;
  }

  public openModalPrivacy() {
    this.close.emit();
    let modalRef = this.ngbModal.open(TermsConditionComponent, {
      windowClass: 'default-modal2',
      size: '600',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.selfModal = modalRef;
    modalRef.componentInstance.normal = true;
    modalRef.componentInstance.close.subscribe((data: any) => {
      if (this.registerForm.controls['terms_conditions'].value) {
        // setTimeout(() => {
        //   let node = document.getElementById('check1');
        //   (node as HTMLElement).click();
        // }, 500);
      }
      if (this.registerForm.controls['security_policy'].value) {
        // setTimeout(() => {
        //   let node = document.getElementById('check2');
        //   (node as HTMLElement).click();
        // }, 500);
      }
    });
    modalRef.componentInstance.info = {
      email: this.registerForm.get('email')!.value,
      username: this.registerForm.get('username')!.value,
      password: this.registerForm.get('password')!.value,
      terms_conditions: this.registerForm.get('terms_conditions')?.value,
      security_policy: this.registerForm.get('security_policy')?.value,
    };
    modalRef.componentInstance.title = this.translate.instant(
      'login:modal:privacy:title'
    );

    let text = this.currentOrg?.show_security_policy.texts.en;
    if (this.currentLang == 'nl') {
      text = this.currentOrg?.show_security_policy.texts.nl;
    }

    modalRef.componentInstance.description =
      typeof text == 'object' ? text.long_description : text;
  }

  publicOrg() {
    return this.orgUtils.isPublic();
  }

  captcha() {
    let alpha = new Array(
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
      'a',
      'b',
      'c',
      'd',
      'e',
      'f',
      'g',
      'h',
      'i',
      'j',
      'k',
      'l',
      'm',
      'n',
      'o',
      'p',
      'q',
      'r',
      's',
      't',
      'u',
      'v',
      'w',
      'x',
      'y',
      'z',
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9'
    );
    let i;
    let code = '';
    for (i = 0; i < 6; i++) {
      code = code + alpha[Math.floor(Math.random() * alpha.length)] + ' ';
    }
    //    var code = a + ' ' + b + ' ' + ' ' + c + ' ' + d + ' ' + e + ' ' + f + ' ' + g;
    this.mainCaptcha = code;
  }
  removeSpaces(text: string) {
    return text.split(' ').join('');
  }

  validCaptcha() {
    var string1 = this.removeSpaces(this.mainCaptcha);
    var string2 = this.removeSpaces(this.loginForm.get('captcha')!.value);

    console.log(this.loginStatus, string1, string2);
    if (string1 == string2) {
      this.loginStatus = 'load';
    } else {
      this.loginStatus = 'login';
    }
  }
}

export function lowerCount(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let countLowerCases =
      control?.value?.length - control?.value?.replace(/[a-z]/g, '').length;

    return +((countLowerCases / 1) * 100).toFixed(2) >= 100
      ? null
      : { lowerCount: true };
  };
}

export function upperCount(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let countUpperCases =
      control.value.length - control.value.replace(/[A-Z]/g, '').length;

    return +((countUpperCases / 1) * 100).toFixed(2) >= 100
      ? null
      : { upperCount: true };
  };
}

export function numberCount(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let countUpperCases =
      control.value.length - control.value.replace(/[0-9]/g, '').length;

    return +((countUpperCases / 1) * 100).toFixed(2) >= 100
      ? null
      : { numberCount: true };
  };
}

export function specialCount(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let countUpperCases =
      control.value.length -
      control.value.replace(/[!@#$%^&*()_+\-=\[\]{} ;':"\\|,.<>\/?]/gi, '')
        .length;

    return +((countUpperCases / 1) * 100).toFixed(2) >= 100
      ? null
      : { specialCount: true };
  };
}
