<div (click)="select()" class="thumbnail-item-content clearfix">
    <div class="pull-left the-thumbnail" [ngStyle]="{'background' : 'url('+course?.coverPathUrlMedium+')'}">
    </div>
    <div class="thumbnail-item-content-text">
        <div class="the-min-height-trick">
            <div class="row">
                <div class="col-md-10">
                    <h2 class="the-item-title">{{course?.name}}</h2>
                    <div class="the-item-text" [innerHtml]="course?.description |htmlToPlainText|excerpt:200"></div>
                </div>
                <div class="col-md-2">
                    <div *ngIf="itemSelect==course.id" class="text-center icon-selected"><i class="fas fa-plus-circle"></i></div>
                    <div *ngIf="itemSelect!=course.id" class="text-center icon-check"><i class="fas fa-plus-circle"></i></div>

                </div>
            </div>
            
        </div>
    </div>
    <div>
    </div>
   
</div>
