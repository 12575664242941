<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">
    {{ "admin:users:modal:discountShow" | translate }}
  </h4>
</div>
<div class="modal-body">
  <div class="form-group">
    <label class="modal-label" for="clip-title">{{
      "admin:users:modal:discountProfile:label:2" | translate
    }}</label>
    <input
      [(ngModel)]="discount.code"
      disabled
      class="input-box w-100"
      id="clip-title"
      maxlength="80"
      name="name"
      placeholder="{{ '' | translate }}"
      required=""
      type="text"
    />
  </div>

  <div class="form-group d-flex mt-3">
    <div class="col-6">
      <label for="clip-title" class="modal-label">{{
        "admin:users:modal:discountProfile:label:3" | translate
      }}</label>
      <input
        [(ngModel)]="discount.total_available"
        class="input-number form-control"
        type="number"
        min="0"
        max="1000"
        placeholder=""
        disabled
      />
    </div>
    <div class="col-6 align-check">
      <label class="">
        <input
          disabled
          [(ngModel)]="!discount.total_available"
          [ngModelOptions]="{ standalone: true }"
          type="checkbox"
          class="form-check-input offset-1"
          id="infinite"
        />
      </label>
      <p class="mx-2 fw-bold mt-2">
        {{ "admin:users:modal:discountProfile:check:1" | translate }}
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <label for="clip-title" class="d-block mt-3 modal-label">{{
        "admin:users:modal:discountProfile:label:8" | translate
      }}</label>
      <select [(ngModel)]="discount.type" disabled class="pure-input">
        <option value="percentage" selected="selected">
          {{ discount.type | translate }}
        </option>
        <option value="fixed">
          {{ discount.type | translate }}
        </option>
      </select>
    </div>
    <div class="col-4">
      <label for="clip-title" class="d-block mt-3 modal-label">{{
        "admin:users:modal:discountProfile:label:4" | translate
      }}</label>
      <input
        [(ngModel)]="discount.amount"
        class="input-number form-control"
        type="number"
        min="0"
        max="1000"
        placeholder=""
        disabled
      />
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <label for="clip-title" class="d-block mt-3 modal-label">{{
        "Course" | translate
      }}</label>
      <p (click)="goToCourse(discount?.collection.id)" class="course-item">
        {{ discount?.collection.name }}
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <label for="clip-title" class="d-block mt-3 modal-label">{{
        "admin:users:modal:discountProfile:label:6" | translate
      }}</label>
      <input
        [(ngModel)]="date1"
        class="input-number form-control"
        type="text"
        placeholder="dd/mm/yyyy"
        disabled
      />
    </div>
    <div class="col-4">
      <label for="clip-title" class="d-block mt-3 modal-label">{{
        "admin:users:modal:discountProfile:label:7" | translate
      }}</label>
      <input
        [(ngModel)]="date2"
        class="input-number form-control"
        type="text"
        placeholder="dd/mm/yyyy"
        disabled
      />
    </div>
    <div class="col-4 align-check-2">
      <label class="">
        <input
          disabled
          [(ngModel)]="!discount.valid_until"
          [ngModelOptions]="{ standalone: true }"
          type="checkbox"
          class="form-check-input offset-1"
          id="infinite"
        />
      </label>
      <p class="mx-2 fw-bold mt-2">
        {{ "admin:users:modal:discountProfile:check:2" | translate }}
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <div class="form-group clearfix">
      <button
        (click)="closeModal()"
        type="button"
        role="tab"
        class="btn btn-primary btn-create float-right ms-2 fs-15"
      >
        {{ "course:close:btn" | translate }}
      </button>
    </div>
  </div>
</div>
