import { UsersAdminDetailComponent } from './../../modals/admin/users/users-admin-detail/users-admin-detail.component';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { ToastService } from 'src/app/services/toast.service';
import { AdminService } from 'src/app/services/admin.service';


@Component({
  selector: 'app-table-admin-course-prospects',
  templateUrl: './table-admin-course-prospects.component.html',
  styleUrls: ['./table-admin-course-prospects.component.scss'],
})
export class TableAdminCourseProspectsComponent implements OnInit {
  @Output() scrolled = new EventEmitter<null>();
  @Input() listProspects: any[] = [];
  @Output() refreshTable = new EventEmitter<any>();

  @ViewChild('container') container!: ElementRef;
  constructor(

    private toastService: ToastService,
    private ngbModal: NgbModal,
    private adminService: AdminService,
  ) {}


  sendContacted(track_id:any,value:any){
    this.adminService.setTrackContacted(track_id,value).then(resp=>{
      this.toastService.showSuccess('Record updated successfully');
    })
  }


  ngOnInit(): void {
    console.log('test',this.listProspects)
    console.log(this.listProspects)
  }
  @HostListener('window:scroll', ['$event'])
  onScroll(e: any) {
    let bottomPosition =
      this.container.nativeElement.getBoundingClientRect().top +
      this.container.nativeElement.offsetHeight;
    if (bottomPosition < window.innerHeight) {
      this.scrolled.emit();
    }
  }
  public openDetail(userName: any) {
    let modalRef = this.ngbModal.open(UsersAdminDetailComponent, {
      windowClass: 'default-modal video-modal',
      size: '900',
      centered: true,
    });
    modalRef.componentInstance.userName = userName;
    modalRef.componentInstance.refresh.subscribe((data: any) => {
      this.refreshTable.emit();
    });
  }
}
