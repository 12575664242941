import { Groups } from './../interfaces/groups';
import { Injectable } from '@angular/core';
import { Course } from '../interfaces/course';
import { AuthService } from '../services/auth.service';
import { InitService } from '../services/init.service';
import { User } from '../interfaces/user';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(private initService: InitService, private auth: AuthService) {}

  public userIsEnrolled(data: Course | Groups): boolean {
    return data.status === 'enrolled';
  }

  //////////////////////////////////////////////////
  // Permision for courses reviews and conversations
  /////////////////////////////////////////////////

  public userIsOwner(data: any, user: User | undefined = undefined): any {
    if (!user) {
      user = this.auth.getMe()!;
    }
    return user && data && data.user && data.user.username === user.username;
  }
}
