<div class="modal-close" (click)="closeModal()">
    <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
    ><span>{{ "btn:close" | translate }}</span>
  </div>
  <div class="modal-header justify-content-start">
    <h4 class="modal-title">
      {{ "courses:review:modal:delete:title" | translate }}
    </h4>
  </div>
  <div class="modal-body flex-column">
    <div class="row">
      <p class="description">{{ "courses:review:modal:text" | translate }}</p>
    </div>
  </div>
  <div class="row justify-content-end modal-footer">
    <hr class="common-separator" />
    <div class="row justify-content-end text-center">
      <div class="col-2">
        <button
          type="button"
          class="btn btn-outline-primary ms-2 fs-15 text-nowrap"
          (click)="closeModal()"
        >
          {{ "Cancel" | translate }}
        </button>
      </div>
      <div class="col-2">
        <button
          type="button"
          role="tab"
          class="btn btn-primary text-nowrap"
          (click)="deleteReviewConfirm()"
        >
          <fa-icon
            class="mr-2"
            [icon]="faCircleNorch"
            *ngIf="loadSpin"
            [spin]="true"
          ></fa-icon>
          {{ "Delete" | translate }}
        </button>
      </div>
    </div>
  </div>
  