import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InitService } from 'src/app/services/init.service';
import { GroupsService } from 'src/app/services/groups.service';
import { Paginator } from 'src/app/interfaces/paginator';


@Component({
  selector: 'app-group-activity-item-comment',
  templateUrl: './group-activity-item-comment.component.html',
  styleUrls: ['./group-activity-item-comment.component.scss']
})
export class GroupActivityItemCommentComponent implements OnInit {
	@Output() refreshConversation = new EventEmitter<null>();
 
	@Input() activities: any;
	@Input() type: string = '';
	@Input() tab: string = '';
	comments: any;
	activity: any;
  loading = false;
  constructor() {
   
  }

  ngOnInit(): void {    
    this.activity=this.activities.entity;
    this.comments=this.activities.comment;
    
    // this.comments=this.activity.comments;
  }
 
  public refresh($event: any = 0){
    this.refreshConversation.emit($event);
  }
  public countComments (){
    return  this.activities?.entity?.count_comments || this.comments.length
  }

}
