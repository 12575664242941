import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faTimes, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-digital-ocean-message',
  templateUrl: './digital-ocean-message.component.html',
  styleUrls: ['./digital-ocean-message.component.scss'],
})
export class DigitalOceanMessageComponent implements OnInit {
  faTimes = faTimes;
  @Input() description: string = '';
  @Input() selfModal: NgbModalRef | null = null;
  @Input() title: String = '';
  @Output() outputEvent = new EventEmitter<any>();
  loadSpin: boolean = false;
  faCircleNorch: any = faCircleNotch;
  constructor() {}

  ngOnInit(): void {}

  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
}
