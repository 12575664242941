import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CourseUtilitiesService } from 'src/app/utilities/course-utilities.service'
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { Lesson } from 'src/app/interfaces/lesson';
import { Course } from 'src/app/interfaces/course';
import { EnrolCourseModalComponent } from 'src/app/modals/courses/enrol-course-modal/enrol-course-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseViewerService } from 'src/app/services/course-viewer.service';
import { MainComponent } from 'src/app/modals/login/main/main.component';
import { TranslateService } from '@ngx-translate/core';
import { LessonUtilitiesService } from 'src/app/utilities/lesson-utilities.service';
import { Chapter } from 'src/app/interfaces/chapter';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-viewer-lessons',
  templateUrl: './viewer-lessons.component.html',
  styleUrls: ['./viewer-lessons.component.scss']
})
export class ViewerLessons implements OnInit, OnDestroy {
  @Input() lesson: Lesson|undefined;
  @Input() chapter: Chapter|undefined;
  @Input() course: Course|undefined;

  user: any;
  sub: any | undefined;

  constructor(
    private courseUtilitiesService: CourseUtilitiesService,
    private auth: AuthService,
    private router: Router,
    private ngbModal: NgbModal,
    private courseViewer: CourseViewerService,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private lessonUtils: LessonUtilitiesService,
  ) { 

  }

  // Llama a triggerFunction cuando lo necesites
  someOtherMethod() {
    this.courseViewer.triggerFunction(); // Esto activará myFunction en MyComponent
  }
  ngOnInit(): void {
    this.getImage();
    this.user = this.auth.getMe();
    
      // Establecer la función de callback
      this.courseViewer.setCallback(() => this.enrolCourseModal());

    this.sub = this.courseViewer.getCurrent().subscribe( (current) => {
      
      let type = this.courseViewer.getCurrentType();
      if (this.courseViewer.getCurrentType() == 'text') {
        if (current!.id == this.lesson!.id) {
          this.lesson = <Lesson>current;
          this.cd.detectChanges();
        }
      }
    });
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  getImage(){
    return this.lessonUtils.getBackgroundImage(this.lesson!)
  }

  completed() {
    return this.courseUtilitiesService.isLessonCompleted(<Lesson>this.lesson);
  }

  hasBackgroundImage(lesson:any){
    return true
  }

  goToLesson() {
    if ((this.userIsEnrolled() && this.chapter?.access) || this.lesson?.free_preview) {
      if (this.auth.isAuthenticated() ) {
        this.router.navigate([
          'course-viewer',
          this.course?.id,
          this.lesson?.type == 'video' ? ( this.lesson?.video_post_id ? 'video-lesson' : 'microclip-lesson'): 'text-lesson',
          this.lesson?.id
        ]);
      } else {
        let modalRef = this.ngbModal.open( MainComponent, {
          windowClass: 'login-modal',
          keyboard: false,
          backdropClass: 'modal-backdrop-transparent'
        });
        modalRef.componentInstance.state = 'login';
        modalRef.componentInstance.selfModal = modalRef;
        modalRef.componentInstance.message = this.translate.instant('course:login:free_preview');
        modalRef.componentInstance.logged.subscribe( () => {
          this.router.navigate([
            'course-viewer',
            this.course?.id,
            this.lesson?.type == 'video' ? ( this.lesson?.video_post_id ? 'video-lesson' : 'microclip-lesson'): 'text-lesson',
            this.lesson?.id
          ]);
        });
      }
    }else{
      this.enrolCourseModal();
    }
  }

  userIsEnrolled(): boolean {
    if (this.course) {

      return this.courseUtilitiesService.userIsEnrolled(this.course);
    }
    return false;
  }

  enrolCourseModal() {
    let modalRef = this.ngbModal.open(EnrolCourseModalComponent, {
      windowClass: 'default-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.course = this.course;
    modalRef.componentInstance.chapter = this.chapter;

  }
  

}
