<div class="container">
    <div class="continue-watching row" (click)="goCourse()">
        <div class="the-image col-md-2" [ngStyle]="{'background-image': 'url(' + (course?.collection.coverPathUrlMedium || 'assets/img/groups/icons/list.png') + ')'}" >
            <div class="item-type ng-binding">{{"courses:course"|translate}}</div>
        </div>
        <div class="col-md-7">
            <div class="row">
                <div *ngIf="courseProgress()<100">
                    <div class="col-auto continue-watching-contents">
                        <h2 class="ng-binding progress-bar-text">{{ "continueWatching:title" | translate }}: {{ course.collection.name }}</h2>
                        <div class="continue-progress-bar col-12 col-md-7 col-lg-5">
                            <app-progress-bar [progress]="courseProgress()" [labelPosition]="'top'"></app-progress-bar> 
                        </div>
                    </div>
                </div>
                <div class="col-md-12 continue-watching-contents" *ngIf="courseProgress()>=100">
                    <h2 class="progress-bar-text">{{ "congratulations:title" | translate }}!<br><small>{{ "continueWatching:completed:title" | translate }}: {{ course.collection.name }}.</small></h2>
                    <div *ngIf="course.users[0].access_until"  class="progress-bar-text">
                        <small class="mt-3">{{ "continueWatching:completed:text" | translate }} {{course.users[0].access_until | amDateFormat:'D MMMM YYYY'}}</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3 flex-center mb-3 mb-md-0">
           <button  (click)="goCourse()" class="btn btn-sm button--confirm-light continue-learning-button"  *ngIf="courseProgress()<100" >{{ "continueWatching:button:continue" | translate }}</button>
           <button (click)="goCourse()" class="btn btn-sm  button--confirm-light continue-learning-button" *ngIf="courseProgress()>=100">{{ "continueWatching:completed:button" | translate }}</button>
        </div>
    </div>
</div>