<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">{{ "files:create:title" | translate }}</h4>
</div>

<div class="modal-body">
  <div class="form-group">
    <label class="mt-3" for="clip-title">{{
      "files:form:file:label" | translate : { maxSize: maxSize }
    }}</label>
    <p class="text-explanation">
      {{ "files:form:file:supported" | translate }}
    </p>
    <div class="row">
      <input
        #fileInput
        style="display: none"
        type="file"
        (change)="uploadFile($event)"
        [accept]="fileSupported"
      />

      <div class="buttons">
        <button class="btn btn-primary py-1" (click)="fileInput.click()">
          {{ "files:form:file:select" | translate }}
        </button>
        <label class="modal-title" style="margin-left: 5px">{{
          file_name | excerpt : 40
        }}</label>
      </div>
    </div>
  </div>
  <div class="form-group mt-3">
    <input
      [(ngModel)]="file.name"
      class="input-box w-100"
      id="clip-title"
      maxlength="255"
      name="name"
      placeholder="{{
        'Name' | translate 
      }}"
      required=""
      type="text"
    />
  </div>

  <div class="form-group">
    <p></p>
    <label for="clip-title">{{
      "files:form:description:label" | translate
    }}</label>
    <quill-editor
      id="txtContent"
      name="description"
      [styles]="boxStyle"
      class="input-box w-100 p-0 quill-box"
      [(ngModel)]="file.description"
      [modules]="modulesQuill"
      placeholder="{{ 'files:form:description:placeholder' | translate }}"
      autofocus
    ></quill-editor>
  </div>

  <div class="form-group clearfix">
    <label class="text-explanation">{{
      "upload:viruses:scan:info" | translate
    }}</label>

    <button
      [disabled]="file_name.length < 1"
      (click)="create()"
      type="button"
      role="tab"
      class="btn btn-primary btn-create modal-btn-width float-right ms-2 fs-15"
    >
      <fa-icon
        class="ms-2"
        [icon]="faCircleNorch"
        *ngIf="loading"
        [spin]="true"
      ></fa-icon>
      {{ "files:form:create:label" | translate }}
    </button>
  </div>
</div>
