<div>
    <div class="row d-flex align-items-center curriculum-chapter-header">
        <div class="col fs-14 py-1 fw-bold" role="button" (click)="isCollapsed = !isCollapsed"
            [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBlock">
            {{ chapterIndex != 0 ? chapterIndex + '.' + (paragraph.position + 1) : '' }} {{paragraph.name}}
            <span *ngIf="paragraph.lessons.length > 0" [ngClass]="isCollapsed ? 'fa-chevron-down' : 'fa-chevron-up'"
                class="fa viewer-sidebar-chevron collapsed-toggle"></span>
        </div>
        <div class="col-auto the-right-elements">{{ paragraph.lessons ? paragraph.lessons.length : '0' }} lessons</div>
    </div>

    <div id="collapseBlock" [ngbCollapse]="isCollapsed" class="row">
        <app-viewer-lessons *ngFor="let lesson of paragraph.lessons" [lesson]="lesson" [chapter]="chapter" [course]="currentCourse" class="p-0"></app-viewer-lessons>
    </div>
</div>
