<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title">
    {{ "collection:make:member:modal:title" | translate }}
  </h4>
</div>
<div class="modal-body flex-column">
  <div class="row">
    <p class="description">{{ "courses:share:other:organizations:modal:text2" | translate:{userName: user.username,organizationNames:organizationNames} }}</p>
  </div>
  <div class="row">
    <p class="description">{{ "collection:share:other:organizations:modal:invite:text" | translate }}</p>
  </div>

  <div class="row">
    <div
      class="col-md-12 col-xs-12 "
      *ngFor="let organization of organizations"
    >
      <label class="fw-bold my-0" [for]="organization.name">
       
        > {{ organization.name }}
      </label>
    </div>
  </div>


  <div class="row">
    <div  class="col-md-12 col-xs-12 ">
      <label class="description" >{{ 'collection:share:other:organizations:modal:message:text'|translate }}
      </label>
      <textarea
        class="input-box w-100"
        id="clip-title"
        maxlength="80"
        name="name"
        placeholder=""
        required=""
        type="text"
        [(ngModel)]="title"
    ></textarea>
    </div>
  </div>


  
</div>
<div class="modal-footer">
  <div class="d-flex justify-content-end text-center">
    <button
      type="button"
      class="btn btn-outline-primary ms-2 fs-15 text-nowrap"
      (click)="closeModal()"
    >
      {{ "collection:share:other:organizations:modal:add:user:cancel" | translate }}
    </button>
    <button
      type="button"
      role="tab"
      class="btn btn-primary ms-2"
      (click)="shareCourse()"
    >
      <fa-icon
        class="mr-2"
        [icon]="faCircleNorch"
        *ngIf="loadSpin"
        [spin]="true"
      ></fa-icon>
      {{ "collection:share:other:organizations:modal:add:user" | translate }}
    </button>
  </div>
</div>
