import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-widget-maintenance-notification',
  templateUrl: './widget-maintenance-notification.component.html',
  styleUrls: ['./widget-maintenance-notification.component.scss'],
})
export class WidgetMaintenancetNotificationComponent implements OnInit {
  @Output() showNotification: EventEmitter<any> = new EventEmitter();
  @Output() showMaintenance: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  showMainNotification() {
    this.showMaintenance.emit();
  }

}
