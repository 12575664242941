import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { InitService } from './init.service';
import { async, BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { User } from '../interfaces/user';
import { HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Organization } from '../interfaces/organization';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ToastService } from './toast.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { Video } from '../interfaces/video/video';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private user$: BehaviorSubject<User | null> =
    new BehaviorSubject<User | null>(null);
  public _user: User | null = null;

  loginUrl = '/oauth/token';
  refreshUrl = '/oauth/refresh-token';
  registerUrl = '/user/auth/local';
  forgotUrl = '/user/password_reset/request';

  organization: Organization | null = null;
  domain: string = '';

  private httpNoInterceptors: HttpClient | undefined;
  // Observable string sources
  private emitChangeSource = new Subject<any>();
  // Observable string streams
  changeEmitted$ = this.emitChangeSource.asObservable();
  constructor(
    private apiService: ApiService,
    private initService: InitService,
    private cookieService: CookieService,
    private http: HttpClient,
    private toastr: ToastService,
    private httpBackend: HttpBackend,
    private translate: TranslateService,
    private router: Router
  ) {
    this.httpNoInterceptors = new HttpClient(this.httpBackend);

    this.user$.subscribe((user) => {
      this._user = user;
    });

    if (this.isAuthenticated()) {
      let userJSON = localStorage.getItem('user');
      if (userJSON) {
        let user = JSON.parse(userJSON);
        if (user.token != this.cookieService.get('access_token')) {
          this.loadMe();
        } else {
          delete user.token;
          this.user$.next(user);
        }
      } else {
        this.loadMe();
      }
    }

    this.organization = initService.getCurrentOrg();

    this.domain = window.location.href
      .split('//')[1]
      .split('/')[0]
      .split(':')[0];
    let a = this.domain.split('.');
    a[0] = '';
    this.domain = a.join('.');
  }

  // Service message commands
  emitChange(change: any) {
    this.emitChangeSource.next(change);
  }
  async logIn(email: string, password: string, reload: boolean = false) {
    const loginRequest: any = {
      grant_type: 'password',
      client_id: environment.auth.clientId,
      client_secret: environment.auth.clientSecret,
      username: btoa(email.toLowerCase()),
      password: btoa(password),
      currentOrganization: this.initService.getCurrentOrg()?.id.toString(),
    };

    try {
      const response: {
        access_token: string;
        refresh_token: string;
        data: any;
      } = await this.apiService.post(this.loginUrl, loginRequest);

      this.cookieService.set(
        'access_token',
        response.access_token,
        undefined,
        '/',
        this.domain
      );
      this.cookieService.set(
        'refresh_token',
        response.refresh_token,
        undefined,
        '/',
        this.domain
      );

      let loaded = await this.loadMe().then((user) => {
        if (reload) {
          window.location.reload();
        }
      });

      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  register(
    email: string,
    username: string,
    password: string,
    hash: string = '',
    lang = ''
  ) {
    let org = this.initService.getCurrentOrg();
    let browserLang = navigator.language.includes('en')
      ? 'en'
      : navigator.language.includes('nl')
      ? 'nl'
      : null;
    const registerRequest: any = {
      email: email,
      display_name: username,
      password: password,
      currentOrganization: org?.id.toString(),
      hash: hash,
      language: lang
        ? lang
        : browserLang
        ? browserLang
        : org?.language
        ? org.language
        : 'en',
    };

    return new Promise((resolve, reject) => {
      this.apiService
        .post(this.registerUrl, registerRequest)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  registerInvited(
    email: string,
    username: string,
    password: string,
    hash: string
  ) {
    let org = this.initService.getCurrentOrg();
    let browserLang = navigator.language.includes('en')
      ? 'en'
      : navigator.language.includes('nl')
      ? 'nl'
      : null;

    const registerRequest: any = {
      email: btoa(email),
      display_name: username,
      password: btoa(password),
      currentOrganization: org?.id.toString(),
      language: browserLang ? browserLang : org?.language ? org.language : 'en',
      invitation_token: hash,
      grant_type: environment.auth.grantType,
      client_id: environment.auth.clientId,
      client_secret: environment.auth.clientSecret,
    };

    return new Promise((resolve, reject) => {
      this.apiService
        .postJson('/user/auth/invitation', registerRequest)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async logInWithSso(access_token: string, refresh_token: string, data: any) {
    this.cookieService.set(
      'access_token',
      access_token,
      undefined,
      '/',
      this.domain
    );
    this.cookieService.set(
      'refresh_token',
      refresh_token,
      undefined,
      '/',
      this.domain
    );

    this.loadMe().then((user) => {
      window.location.reload();
    });
  }
  refreshToken() {
    const refreshRequest = new URLSearchParams({
      grant_type: 'refresh_token',
      client_id: environment.auth.clientId,
      client_secret: environment.auth.clientSecret,
      refresh_token: this.cookieService.get('refresh_token'),
    });

    let headers: any = {};
    headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
    const options = {
      headers: headers,
    };

    return this.http
      .post(environment.apiUrl.concat(this.loginUrl), refreshRequest, options)
      .pipe(
        map((res: any) => {
          this.cookieService.set(
            'access_token',
            res.access_token,
            undefined,
            '/',
            this.domain
          );
          this.cookieService.set(
            'refresh_token',
            res.refresh_token,
            undefined,
            '/',
            this.domain
          );
          return res;
        }),
        catchError((e) => {
          this.toastr.showError(this.translate.instant('auth:login:required'));
          const state = this.router.routerState.snapshot;
          localStorage.setItem('remember_url', state.url);
          setTimeout(() => {
            this.logOut();
            this.router.navigate(['/login']).then(() => {
              this.toastr.showError(
                this.translate.instant('auth:login:required')
              );
              window.location.reload();
            });
          }, 3000);
          return throwError(e);
        })
      );
  }

  forgot(email: string) {
    const forgotRequest: any = {
      email: email,
    };

    return new Promise((resolve, reject) => {
      this.apiService
        .post(this.forgotUrl, forgotRequest)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public get user(): Observable<User | null> {
    return this.user$.asObservable();
  }

  public isAuthenticated(): boolean {
    const token = this.cookieService.get('access_token');
    return token ? true : false;
  }

  logOut(reload: boolean = false) {
    this.cookieService.delete('access_token', '/', this.domain);
    this.cookieService.delete('refresh_token', '/', this.domain);
    localStorage.removeItem('user');

    this.user$.next(null);
    if (reload) {
      window.location.reload();
    }
  }

  logOutWithRedirect() {
    this.cookieService.delete('access_token', '/', this.domain);
    this.cookieService.delete('refresh_token', '/', this.domain);
    localStorage.removeItem('user');

    this.user$.next(null);
    window.location.href = '/';
  }

  // this is done before anything and can't use the interceptor
  // then we added the bearer in the httpbackend
  async loadMe(): Promise<User> {
    return new Promise((resolve, reject) => {
      const token = this.cookieService.get('access_token');

      let headers: any = {
        Authorization: `Bearer ${token}`,
      };

      const org = this.initService.getCurrentOrg()?.id;
      if (org) {
        headers = new HttpHeaders({
          Authorization: 'Bearer ' + token,
          Organization_ids: `${org}`,
        });

        // headers  = {
        //   'Authorization': `Bearer ${token}`,
        //   'Organization_ids' : org
        // }
        //headers.organization_ids = org
      }
      this.httpNoInterceptors!.get(environment.apiUrl.concat('/user'), {
        headers: headers,
      }).subscribe((response: any) => {
        if (this._user != response.data.user) {
          let user = response.data.user;
          this.user$.next(user);
          this._user = user;
          user.token = this.cookieService.get('access_token');
          localStorage.setItem('user', JSON.stringify(user));
          // Can't use cockies because of the size (4k limit)
          // this.cookieService.set( 'user', JSON.stringify(user), undefined, undefined, this.domain );
          resolve(user);
        }
      });
    });
  }

  getMe(): User | null {
    return this._user;
  }

  hasRole(role: string) {
    return this.hasPrimaryRole(role) || this.hasOrganizationRole(role);
  }

  hasAnyRole(roles: Array<string>): boolean {
    let hasRole = false;
    roles.forEach((role) => {
      if (this.hasRole(role)) {
        hasRole = true;
      }
    });
    return hasRole;
  }

  hasPrimaryRole(role: string): boolean {
    return this.isAuthenticated() && this._user?.roles.primary == role;
  }

  hasOrganizationRole(role: string): boolean {
    //console.log('this._user?.roles',this._user)
    return this.isAuthenticated() && this._user?.roles.organization == role;
  }

  isAdmin(): boolean {
   // console.log('this._user?.roles', this._user);
    // es para ver aca hay un draam con los datos que viene por ejemplo si un usuario es moderador, viene como primary super_admin y en la organizacion como moderador
    if (
      this._user?.roles.organization == 'moderator' &&
      this.isAuthenticated()
    ) {
      return false;
    }
    return this.isAuthenticated() && this.hasPrimaryRole('super_user');
  }

  hasAccess(feature: string, user: User | null = null) {
    let hasAccess = false;

    if (!user) {
      user = this._user;
    }
    if (!user) {
      return hasAccess;
    }

    var features = user.access.features.filter((f: any) => f.field == feature);
    if (features.length) {
      hasAccess = true;
    }

    return hasAccess;
  }

  isOwner(entity: Video) {
    return this._user?.username == entity.user?.username;
  }

  resetPassword(hash: string, pass: string) {
    return new Promise((resolve, reject) => {
      this.apiService
        .postJson('/user/password_reset/complete', {
          password: pass,
          token: hash,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
