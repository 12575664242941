<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title">
    {{ "playlist:modal:change:ownership:title" | translate }}
  </h4>
</div>
<div class="modal-body flex-column">
  <div class="row">
    <p class="description fw-bold mb-0">
      {{ "playlist:modal:change:ownership:subtitle" | translate }}
    </p>
  </div>

  <div class="form-group mb-3">
    <label for="clip-title" class="d-block modal-label">{{
      "playlist:modal:change:ownership:description" | translate
    }}</label>
    <app-search-owner
      [collection]="collection"
      id="search"
      (searchEvent)="usersSelected($event)"
      [type]="'playlist'"
      placeholder="{{ 'search:by:email' | translate }}"
    ></app-search-owner>
  </div>
</div>
<div class="modal-footer">
  <div class="d-flex justify-content-end">
    <button
      type="button"
      (click)="closeModal()"
      role="tab"
      class="btn btn-dange col-auto"
    >
      {{ "Cancel" | translate }}
    </button>
    <button
      (click)="change()"
      type="button"
      role="tab"
      class="btn btn-primary col-auto"
    >
      <fa-icon
        class="mr-2"
        [icon]="faCircleNorch"
        *ngIf="loading"
        [spin]="true"
      ></fa-icon
      >{{ "playlist:modal:change:ownership:button" | translate }}
    </button>
  </div>
</div>
