<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-body">
  <img #miImagen [src]="image" />
</div>
<!-- <div class="row justify-content-end modal-footer">
  <div class="row justify-content-end text-center">
    <button
      [autofocus]="false"
      type="button"
      class="btn btn-outline-primary col-auto"
      (click)="closeModal()"
    >
      {{ "btn:close" | translate }}
    </button>
  </div>
</div> -->
