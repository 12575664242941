<div class="table-responsive-sm mb-4">
  <table class="table table-hover" #container>
    <thead>
      <tr class="" [ngClass]="{ 'custom-border': true }">
        <th class="text-center" scope="col"></th>
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "organization:settings:users:table:title:1" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "Email" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "organization:settings:users:table:title:3" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "organization:settings:users:table:title:4" | translate }}
          </span>
        </th>
        <th scope="col" class="my-3 align-middle">
          <span>
            {{ "organization:settings:users:table:title:5" | translate }}
          </span>
        </th>
        <th class="text-center" scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container>
        <tr
          [ngClass]="{ 'custom-border': true }"
          *ngFor="let user of listUsers; let i = index"
        >
          <td class="justify-content-center">
            <app-user-icon size="medium" [user]="user?.user"></app-user-icon>
          </td>
          <td class="title-course align-middle">
            {{ user?.username | excerpt : 50 }}
          </td>
          <td class="align-middle">
            {{ user?.invited_by?.email || "-" }}
          </td>
          <td class="align-middle">
            {{ mapOrg(user?.organizations) | excerpt : 200}}
          </td>

          <td class="align-middle text-center">
            <p class="fw-bold mb-0">{{ user?.products }}</p>
          </td>
          <td class="align-middle text-center">
            <p class="fw-bold mb-0">€{{ user?.spent }}</p>
          </td>
          <td colspan="3" class="align-middle text-center">
            <div
              ngbDropdown
              class="d-inline-block float_right"
              placement="bottom-right"
            >
              <button
                type="button"
                class="btn btn-outline-primary"
                id="dropdownBasic1"
                ngbDropdownToggle
              >
                <i class="fa fa-ellipsis-h"></i>
              </button>
              <div
                ngbDropdownMenu
                aria-labelledby="dropdownBasic1"
                class="mt-1"
              >
                <button (click)="openDetail(user?.username)" ngbDropdownItem>
                  {{
                    "organization:settings:orders:table:dropdown:item:1"
                      | translate
                  }}
                </button>
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
