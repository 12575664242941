import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { JoyrideService } from 'ngx-joyride';

@Component({
  selector: 'app-healix',
  templateUrl: './healix.component.html',
  styleUrls: ['./healix.component.scss'],
})
export class HealixComponent implements OnInit {
  @Input() levels: any;
  @Input() showTooltip: any;
  @Input() dhprofiles: any;
  @Input() addJoy: any = true;
  @Input() initTour: any = false;
  @Input() fromLevels: boolean = false;
  @Output() selectLevel = new EventEmitter<any>();
  constructor(private joyrideService: JoyrideService, private router: Router) {}

  ngOnInit(): void {
    this.levels;
    if (this.initTour) {
      this.joyrideService?.startTour().subscribe(
        (step) => {
          /*Do something*/
          console.log(step);
          if (step.name == 'fourthStep') {
            const element: any = document.getElementById('elementjoy4');
            setTimeout(() => {
              window.scrollTo({
                top: element.offsetTop,
                behavior: 'smooth',
              });
            }, 50);
          }
        },
        (error) => {
          /*handle error*/
        },
        () => {}
      );
    }
  }

  public select(info: any, position: number) {
    this.selectLevel.emit({ info, position });
  }
  goBack(level: string) {
    if (this.fromLevels) {
      this.router.navigate(['/dh', 'multi-profile']);
    } else {
      this.router.navigate(['/dh', 'multi-profile'], {
        queryParams: { level: level },
      });
    }
  }
}
