import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseUrl: string;

  constructor(
    private httpClient: HttpClient,
  ) {
    this.baseUrl = environment.apiUrl;
  }

  public get(url: string, params: any | null = null): Promise<any> {
    const options = {
      params: params ? params : undefined
    };
    return this.httpClient.get(this.baseUrl.concat(url), options).toPromise();
  }

  public postJSON(url: string, data: any): Promise<any> {
    let headers: any = {};
    headers['Content-Type'] = 'application/json';

    const options = {
      headers: headers,
    };

    return this.httpClient
      .post(this.baseUrl.concat(url), data, options)
      .toPromise();
  }

  public postJson(url: string, data: any): Promise<any> {
    return this.postJSON(url, data);
  }

  public post(url: string, data: any): Promise<any> {
    let headers: any = {};
    headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
    const options = {
      headers: headers,
    };

    data = new URLSearchParams(data);

    return this.httpClient
      .post(this.baseUrl.concat(url), data, options)
      .toPromise();
  }

  public postFile(url: string, data: any): Promise<any> {
    let headers: any = {};
    const options = {
      headers: headers,
    };

    return this.httpClient
      .post(this.baseUrl.concat(url), data, options)
      .toPromise();
  }

  public put(url: string, data: any = {}): Promise<any> {
    const options = {
      headers: {},
    };
    return this.httpClient
      .put(this.baseUrl.concat(url), data, options)
      .toPromise();
  }

  public patch(url: string, data: any = {}): Promise<any> {
    const options = {
      headers: {},
    };
    return this.httpClient
      .patch(this.baseUrl.concat(url), data, options)
      .toPromise();
  }

  public delete(url: string, data = '', body = {}): Promise<object> {
    const options = {
      headers: {},
      body
    };
    return this.httpClient
      .delete(this.baseUrl.concat(url).concat(data), options)
      .toPromise();
  }

    public deleteJSON(url: string, data: any): Promise<any> {
      let headers: any = {};
      headers['Content-Type'] = 'application/json';

      const options = {
        headers: headers,
        body: data
      };
;
      return this.httpClient
        .delete(this.baseUrl.concat(url), options)
        .toPromise();
    }
}
