<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">{{ "Edit video of playlist" | translate }}</h4>
</div>
<div class="modal-body heigth m-1">
  <form>
    <div class="form-group mb-3">
      <label for="clip-title" class="d-block mt-3 modal-label">{{
        "Name of the video" | translate
      }}</label>
      <input
        name="title"
        type="text"
        class="input-box w-100"
        placeholder="{{ 'Title' | translate }}"
        [(ngModel)]="video.name"
        autofocus
        required
      />

      <label for="clip-title" class="d-block mt-3 modal-label">{{
        "Description of the video" | translate
      }}</label>
      <textarea
        cols="30"
        rows="8"
        name="description"
        type="text"
        class="input-box w-100"
        placeholder="{{
          'Introduce this video within the context of this playlists'
            | translate
        }}"
        [(ngModel)]="video.description"
        autofocus
        required
      >
      </textarea>
    </div>
    <div class="mb-1 clearfix">
      <button
        (click)="edit()"
        [disabled]="
          (video.name && video.name.length <= 0) ||
          (video.description && video.description.length <= 0)
        "
        type="button"
        role="tab"
        class="btn btn-success btn-create modal-btn-width float-right ms-2 fs-15"
      >
        <fa-icon
          *ngIf="loading"
          class="ms-2"
          [icon]="faCircleNotch"
          [spin]="true"
        ></fa-icon>
        {{'organization:settings:btn:save' | translate}}
      </button>
    </div>
  </form>
</div>
