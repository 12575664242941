  <!-- <p class="activity-item-generic-content activity-item-comment-from">
    {{ activities?.entity?.count_comments  }} {{'comments' | translate}}
  </p> -->
<div (click)="openVideoModal(activity.id)" class="thumbnail-item-content clearfix">
    <div class="pull-left the-thumbnail" [ngStyle]="{'background' : 'url('+activity?.thumb_url+')'}">
        <div class="clock-icon">
            <span class="meta--stat">
                <fa-icon class="icon" [icon]="faClock" [styles]="{ 'color': 'white'}"></fa-icon>
                <i class="fa fa-clock-o"></i> {{this.activity?.source?.length | timeLength}}
            </span>
        </div>
    </div>
    <div class="thumbnail-item-content-text">
        <div class="the-min-height-trick">
            <h2 class="the-item-title">{{title}}</h2>
            <div style="min-height: 17px;">
                <h3 class="the-item-subtitle" *ngIf="activity?.source?.origin?.title">
                    {{ "film:from" | translate }}
                    <span class="the-source">{{ activity?.source?.origin?.title }}</span>
                </h3>
            </div>
            <div class="the-item-text p-margin">{{description|htmlToPlainText}}</div>

            
        </div>
    </div>
    <div class="user-name-with-avatar">
        <app-user-icon class="small-avatar-group" [user]="activity?.user"></app-user-icon>
        <div class="the-user-name"><span class="by-whom">{{'author:by' | translate}} </span> <span class="the-author ">{{activity?.user?.display_name}}</span></div>
    </div>
 

</div>
<div class="comments" *ngIf="type=='activity'">
    <!-- <app-comments-container type="activity_group" [activity]="activities" placeholder="{{ 'comments:placeholder' | translate }}" [title]=false ></app-comments-container> -->
</div>