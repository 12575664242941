import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { PlaylistsService } from 'src/app/services/playlists.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() organizations: any = [];
  @Input() user: any = {};
  @Output() refresh = new EventEmitter<any>();

  public loading: boolean = false;
  public faCircleNorch: any = faCircleNotch;
  public faTimes = faTimes;
  public message: string =
    'I would like to invite you to this learning platform.';
  constructor(
    private playlistService: PlaylistsService,
    private toastService: ToastService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {}
  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  public sendMessage() {
    this.loading = true;
    let organizatios = this.organizations
      .filter((o: any) => o.checked)
      .map((o: any) => o.id);

    this.playlistService
      .sendMessage(this.user, this.message, organizatios)
      .then((result) => {
        this.loading = false;
        this.toastService.showSuccess(
          this.translate.instant(
            'collection:share:success'
          )
        );
        this.refresh.emit();
        this.closeModal();
      })
      .catch((err: any) => {
        this.loading = false;
        this.closeModal();
        console.log(err);
      });
  }
}
