import { Component, Input, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { VideoService } from 'src/app/services/video.service';
import { environment } from 'src/environments/environment';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

declare var kWidget: any, $: any;

@Component({
  selector: 'app-simple-video',
  templateUrl: './simple-video.component.html',
  styleUrls: ['./simple-video.component.scss'],
})
export class SimpleVideoComponent implements OnInit {
  faTimes = faTimes;

  @Input() selfModal: NgbModalRef | null = null;
  @Input() videoId: number | null = null;
  @Input() type: string = '';

  loading = true;
  video: any;

  constructor(private videoService: VideoService) {}

  ngOnInit(): void {
    if (this.videoId) {
      this.videoService.getById(this.videoId).then((response) => {
        this.video = response.data.video;
        // console.log(this.video);

        let flashvars = {
          'video.volume': 0.1,
        };

        // if (this.video?.source?.enable_watermark) {
        //   flashvars = {
        //     'video.volume': 0.1,
        //     // "watermark": {
        //     //   "plugin": true,
        //     //   "img": "assets/img/logos/mllogo-md.png",
        //     //   "href": "",
        //     //   "cssClass": this.video.source.watermark_position ? this.video.source.watermark_position.replace(/-([a-z])/g, function (g: any) { return g[1].toUpperCase(); }) : "topRight"
        //     // }
        //   };
        // }

        this.loading = false;

        setTimeout(() => {
          kWidget?.embed({
            targetId: 'kaltura_player_' + this.video?.source?.external_id,
            wid: '_' + environment.vixyPId,
            uiconf_id: environment.vixyPlayerId,
            flashvars: flashvars,
            cache_st: this.video?.source?.external_id,
            entry_id: this.video?.source?.external_id,
            readyCallback: (playerID: any) => {
              let kdp: any = document.getElementById(playerID);
              setTimeout(() => {
                kdp.sendNotification("changeVolume", 0.5);
              }, 1000);
            }
          });
        }, 15);
      });
    }
  }

  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
}
