<div (click)="select()" class="thumbnail-item-content clearfix position-relative">
  <div class="row">
    <div class="col-md-3">
      <div class="mx-2">
          <app-user-icon
         
          container-class="avatar"
          [user]="review?.user"
          size="medium"
        ></app-user-icon>
      </div>
      <div class="mx-2">
        <div class="user-info col px-1">
          <h5>
            <span class="user-name">{{ review?.user?.username }}</span>
          </h5>
          <p class="user-description">{{ review?.job_title }}</p>
        </div>

      </div>

     
    </div>
    <div class="col-md-9">
    
        <div class="the-min-height-trick">
          <div class="row">
            <div class="col-md-10">
              <div class="row">
                <div class="col-md-7">
                  <h2 class="the-item-title">{{ review?.title }} </h2>
                </div>
                <div class="col-md-5">
                  <app-review-stars  [rating]="review?.review"></app-review-stars>
                </div>
               

              </div>
           
              <div class="the-item-text" [innerHtml]="review?.description |htmlToPlainText|excerpt:200"></div>
            </div>
            <div class="col-md-2">
              <div *ngIf="review.adding" class="text-center text-added">Review already selected</div>
              
              <div *ngIf="review.adding" class="text-center icon-deleted" (click)="deleteItem()">
                <img  src="../../../../../assets/statics/img/trash-can.svg" alt="img trash" class="img-trash"></div>
              <div *ngIf="!review.adding&&itemSelect==review.id" class="text-center icon-selected"><i class="fas fa-plus-circle"></i></div>
              <div *ngIf="!review.adding&&itemSelect!=review.id" class="text-center icon-check"><i class="fas fa-plus-circle"></i></div>

          </div>
            
          </div>
        </div>
      

    </div>
  </div>

  <ng-template #removecontent let-modal>
    <div class="modal-close" (click)="modal.dismiss()">
      <i class="fas fa-times me-2"></i><span>{{ "btn:close" | translate }}</span>
    </div>
    <div class="modal-header">
      <h4 class="modal-title">
        {{ "dashboard:admin:modal:content:delete:item:title" | translate }}
      </h4>
    </div>
    <div class="modal-body">
      <p>{{ 'dashboard:admin:modal:content:delete:item:text' | translate:{content: getContentName()} }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">
        {{ "questionnaire:delete:modal:button:cancel" | translate }}
      </button>
      <button class="btn btn-primary" (click)="modal.close()">
        {{ "dashboard:admin:modal:delete:item:btn:remove" | translate }}
      </button>
    </div>
  </ng-template>

</div>
