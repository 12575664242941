<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">{{ "navbar:create_organization" | translate }}</h4>
</div>
<div class="modal-body">
  <div class="form-group">
    <label class="form-label" for="clip-title">{{
      "organization:edit:name:label" | translate
    }}</label>
    <input
      [(ngModel)]="platform.name"
      class="input-box w-100"
      id="clip-title"
      maxlength="80"
      name="name"
      placeholder="{{ 'Add name' | translate }}"
      required=""
      type="text"
    />
  </div>
  <div class="form-group">
    <label class="form-label mt-3">{{ "Description" | translate }}</label>
    <textarea
      [(ngModel)]="platform.description"
      class="input-box w-100"
      maxlength="250"
      placeholder="{{ 'Add Description' | translate }}"
      rows="4"
    >
    </textarea>
  </div>
  <div class="form-group">
    <label for="clip-title" class="d-block mt-3 form-label">{{
      "courseCreation:language:title" | translate
    }}</label>
    <select class="pure-input" [(ngModel)]="platform.language">
      <option value="nl" selected="selected">
        {{ "language:options:nl" | translate }}
      </option>
      <option value="en">{{ "language:options:en" | translate }}</option>
    </select>
  </div>
  <hr class="common-separator" />
  <div class="form-group">
    <label class="form-label" for="clip-title">{{
      "Platform type" | translate
    }}</label>
    <label class="fancy-radio-checkbox form-label">
      <input
        [(ngModel)]="platform.visible"
        class="fancy-input"
        id="open"
        name="privacyOpen"
        type="radio"
        [value]="true"
      />
      <div class="fancy-graphic"></div>
      {{ "Open platform" | translate }}
    </label>
    <div class="small-form-text">
      {{ "Public platform" | translate }}
    </div>
    <label class="fancy-radio-checkbox form-label">
      <input
        [(ngModel)]="platform.visible"
        class="fancy-input"
        id="closed"
        name="privacyOpen"
        type="radio"
        [value]="false"
      />
      <div class="fancy-graphic"></div>
      {{ "organization:edit:public:no" | translate }}
    </label>
    <div class="small-form-text">
      {{ "Access only after invitation" | translate }}
    </div>
    <label class="form-label col-12" for="clip-title">{{
      "organization:form:dh_organization:label" | translate
    }}</label>
    <ml-switch
      [inputValue]="platform.dh_organization"
      textAlignClass="ml-switch-text-right"
      textContent="{{ '' | translate }}"
      (value)="platform.dh_organization = $event"
    ></ml-switch>
  </div>
</div>
<div class="modal-footer">
  <div class="form-group clearfix">
    <button
      [disabled]="platform.name.length < 4 || loadSpin"
      (click)="createPlatform()"
      type="button"
      role="tab"
      class="btn btn-primary btn-create float-right ms-2 fs-15"
    >
      <fa-icon
        class="mr-2"
        [icon]="faCircleNorch"
        *ngIf="loadSpin"
        [spin]="true"
      ></fa-icon>
      {{ "platform:create:button" | translate }}
    </button>
  </div>
</div>
