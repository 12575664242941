import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { CollectionService } from 'src/app/services/collection.service';
import { AuthService } from 'src/app/services/auth.service';
import { InitService } from 'src/app/services/init.service';
import { Course } from 'src/app/interfaces/course';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Lesson } from 'src/app/interfaces/lesson';

@Component({
  selector: 'add-review',
  templateUrl: './add-review.component.html',
  styleUrls: ['./add-review.component.scss']
})

export class AddReviewComponent implements OnInit {
  @Output() success: EventEmitter<any> = new EventEmitter();
  @Output() deleteReview: EventEmitter<any> = new EventEmitter();
  @Input() onLesson:boolean = false;
  @Input() idsToFilter:Array<any> = [];
  faTimes = faTimes;
  @Input() selfModal: NgbModalRef | null = null;
  @Input() title:String='';
  reviews: Array<any> = [];
  faCircleNorch = faCircleNotch;
  loading= true;
  addLoading=false;
  actualTab='allCourse';
  username: any;
  itemSelect='';
  searchText=''
  searchTextOwn=''

  constructor(
    public auth: AuthService,
    public ds: DashboardService,
  ) { }

  ngOnInit(): void {
    this.getReviews();
    console.log('idsToFilter-->',this.idsToFilter);
  }

  getReviews() {
    let seft=this
    this.ds.getReviews(this.searchText,100).then( (res) => {
      console.log(seft.idsToFilter);
      console.log('RESP',res);
      res.forEach((review:any) => {
        review.adding = seft.idsToFilter.includes(review.id);
      });

      this.reviews  = res;
     // this.reviews  = res.filter((item:any) => !seft.idsToFilter.includes(item.id)&& item.user!=null);
      
      //console.log('this.reviews',this.reviews);

     // this.reviews = res;
      this.loading = false;
    });
  }

  closeModal() {
    if(this.selfModal) this.selfModal?.close();
    else $("ngb-modal-window").click();
  }

  selectItem( value: number ) {
    this.itemSelect = value + '';

  }

  deletedItem( value: number){
    let review = this.reviews.find( (l: any) => l.id == +value );
    //console.log('review to delete',review);
    this.deleteReview.emit(review);

  }

  sendData(){
    this.addLoading = true;
    this.success.emit(this.findSelected());
    this.closeModal()
  }

  search(text:string){
    this.searchText = text;
    this.getReviews();
  }

  findSelected() {
    let reviews = this.reviews.find( (l: any) => l.id == +this.itemSelect );
    return reviews;
  }
}
