<div class="row justify-content-center" style="margin-top: -25px;">
  <div class="col-auto">
      <app-organization-logo [organization]="organization"></app-organization-logo>
  </div>
</div>
<div class="container px-5 py-3">
  <h3 class="text-center fw-bold h4">
    {{ "reset_password:label" | translate }}
  </h3>
  <form [formGroup]="resetForm">
    <div class="mb-3">
      <label for="password" class="form-label fw-500 fs-15">{{
        "new_password:label" | translate
      }}</label>

      <input
        type="password"
        class="form-control"
        id="password"
        formControlName="password"
        placeholder="{{ 'my_profile:settings:password:new:placeholder' | translate }}"
      />

      <div
        *ngIf="resetForm.controls['password'].value"
        class="p-2 px-1 px-md-4 text-muted"
      >
        <div class="check-meter mb-1">
          <fa-icon
            [icon]="faCheckCircle"
            [ngClass]="
              resetForm.controls['password'].hasError('minlength')
                ? 'text-muted'
                : 'text-success'
            "
          ></fa-icon>
          {{ "password:requirements:length" | translate }}
        </div>
        <div class="check-meter mb-1">
          <fa-icon
            [icon]="faCheckCircle"
            [ngClass]="
              resetForm.controls['password'].hasError('lowerCount')
                ? 'text-muted'
                : 'text-success'
            "
          ></fa-icon>
          {{ "password:requirements:letter:l" | translate }}
        </div>
        <div class="check-meter mb-1">
          <fa-icon
            [icon]="faCheckCircle"
            [ngClass]="
              resetForm.controls['password'].hasError('upperCount')
                ? 'text-muted'
                : 'text-success'
            "
          ></fa-icon>
          {{ "password:requirements:letter:u" | translate }}.
        </div>
        <div class="check-meter mb-1">
          <fa-icon
            [icon]="faCheckCircle"
            [ngClass]="
              resetForm.controls['password'].hasError('numberCount')
                ? 'text-muted'
                : 'text-success'
            "
          ></fa-icon>
          {{ "password:requirements:number" | translate }}
        </div>
        <div class="check-meter mb-1">
          <fa-icon
            [icon]="faCheckCircle"
            [ngClass]="
              resetForm.controls['password'].hasError('specialCount')
                ? 'text-muted'
                : 'text-success'
            "
          ></fa-icon>
          {{ "password:requirements:special" | translate }}
        </div>
      </div>
    </div>
    <div class="d-grid gap-2">
      <button
        class="btn btn-primary fs-14 py-2"
        href="javascript:void(0);"
        (click)="reset()"
        [class.disabled]="loading || !resetForm.valid"
      >
        {{ "Save" | translate }}<fa-icon
          class="ms-2"
          [icon]="faCircleNorch"
          *ngIf="loading"
          [spin]="true"
        ></fa-icon>
      </button>
    </div>
  </form>
</div>
