import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Chapter } from 'src/app/interfaces/chapter';
import { Course } from 'src/app/interfaces/course';

@Component({
  selector: 'app-viewer-paragraph',
  templateUrl: './viewer-paragraph.component.html',
  styleUrls: ['./viewer-paragraph.component.scss'],
})
export class ViewerParagraph implements OnInit {
  @Input() paragraph: any;
  @Input() chapterIndex: any;
  @Input() paragraphs: any;
  @Input() course: Course | undefined;
  @Input() chapter: Chapter|undefined;

  currentCourse: any;
  public isCollapsed = true;
  paragraphIndex = -1;

  constructor() {}

  ngOnInit(): void {
    this.paragraphs.forEach((paragraph: any, i: number) => {
      if (paragraph.id == this.paragraphs.id) {
        this.paragraphIndex = i;
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.currentCourse = this.course;
  }
}
