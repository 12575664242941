<div class="card shadow" [ngClass]="{ featured: featured }">
  <div class="card-img-top position-relative">
    <img
      (click)="openVideoModal()"
      [src]="video?.thumb_url || 'assets/img/groups/icons/list.png'"
      onerror="this.src='assets/img/groups/icons/list.png'"
    />

    <span class="badge badge-item-type text-primary px-3" *ngIf="featured">{{
      "discover:pill:label" | translate
    }}</span>
    <div
    *ngIf="isFromGroup && userCanEdit(video)"
      ngbDropdown
      class="btn-toggle text-primary border rounded"
      placement="bottom-right"
    >
      <button type="button" class="btn" id="dropdownBasic1" ngbDropdownToggle>
        <i class="fa fa-ellipsis-h"></i>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="mt-1">
        <button (click)="deleteItem()" class="text-danger" ngbDropdownItem>
          {{ "groups:profile:video:delete:button:text" | translate }}
        </button>
      </div>
    </div>
    <div class="video-play-button"
    (click)="openVideoModal()">
      <fa-icon
      
        class="play-icon"
        [icon]="faPlay"
        [styles]="{ color: 'white' }"
      ></fa-icon>
    </div>

    <div class="clock-icon">
      <span class="meta--stat">
        <fa-icon
          class="icon"
          [icon]="faClock"
          [styles]="{ color: 'white' }"
        ></fa-icon>
        <i class="fa fa-clock-o"></i>
        {{ this.video?.source?.length || 0 | timeLength }}
      </span>
    </div>
  </div>

  <div (click)="openVideoModal()" class="card-body position-relative">
    <span
      *ngIf="[0, 1, 3, 4, 5].includes(video?.source?.status!); else unpublished"
      class="badge rounded-pill p-2 px-3 bg-danger text-white position-absolute fw-normal"
    >
      Unprocessed
    </span>
    <ng-template #unpublished>
      <span
        *ngIf="!video?.published"
        class="badge rounded-pill p-2 px-3 bg-danger text-white position-absolute fw-normal"
      >
        Unpublished
      </span>
    </ng-template>

    <h3
      *ngIf="title"
      class="card-title mb-0 mt-2 fw-normal"
      [ngClass]="{ 'mb-3': featured }"
    >
      {{ title.length > 24 ? (title | slice : 0 : 50) + "..." : title }}
    </h3>
    <p class="card-text" *ngIf="!featured">
      <small *ngIf="video?.source?.origin" class="text-muted"
        >{{ "courseviewer:lesson:from" | translate }}
        {{ video?.source?.origin?.title }}</small
      >
    </p>
    <p
      class="card-text short-description"
      *ngIf="!featured"
      [innerHTML]="description"
    ></p>
    <div class="d-flex justify-content-start align-items-center">
      <!-- <small *ngIf="video?.ratings?.starsAvg" class="me-3 text-muted">
        <fa-icon [icon]="faStar" [styles]="{ color: '#eeee00' }"></fa-icon>
        <span class="ms-1">{{ video?.ratings.starsAvg }}/5 |</span>
      </small> -->
      <small *ngIf="video?.user?.display_name" class="me-3 text-muted">
        <span class="ms-1">{{'author:by' | translate}} {{ video?.user?.display_name }}</span>
      </small>
    </div>
  </div>
</div>
