import { AdminService } from './../../services/admin.service';
import { DisabledOrDeleteComponent } from './../../modals/admin/disabled-or-delete/disabled-or-delete.component';
import { ConfirmationDeleteComponent } from './../../modals/organizations/confirmationDelete/confirmation-delete/confirmation-delete.component';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InitService } from 'src/app/services/init.service';
import { Organization } from 'src/app/interfaces/organization';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-admin-platform-card',
  templateUrl: './admin-platform-card.component.html',
  styleUrls: ['./admin-platform-card.component.scss'],
})
export class AdminPlatformCardComponent implements OnInit, OnChanges {
  @Input() platform: any = null;
  avatarImg: String = '';
  platformImg: undefined | string = '';
  faPlay = faUser;
  public status: string = '';
  constructor(
    private router: Router,
    private ngbModal: NgbModal,
    private adminService: AdminService,
    private initService: InitService,
    private toastService: ToastService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.platformImg = 'assets/img/groups/icons/list.png';
    if (this.platform?.logo_file?.coverPathUrlMedium) {
      this.platformImg = this.platform?.coverPathUrlMedium;
    }
    this.getStatus(this.platform);
  }

  public disabledPlatform(platform: any) {
    if (this.status == 'Public' || this.status == 'Closed') {
      let modalRef = this.ngbModal.open(DisabledOrDeleteComponent, {
        windowClass: 'default-modal video-modal',
        size: '600',
        centered: true,
      });
      modalRef.componentInstance.platform = platform;
      modalRef.componentInstance.refresh.subscribe((id: any) => {
        this.adminService
          .disablePlatform(platform.id)
          .then((resp: any) => {
            this.toastService.showSuccess(
              this.translate.instant('organizations:disable:success')
            );
            this.platform = resp;
            this.getStatus(resp);
            // this.platform.deleted_at = resp.deleted_at;
          })
          .catch((err) => {
            this.toastService.showError(
              this.translate.instant('organizations:enable:error')
            );
          });
      });
    } else {
      this.adminService
        .enablePlatform(platform.id)
        .then((resp: any) => {
          this.toastService.showSuccess(
            this.translate.instant('organizations:enable:success')
          );
          this.platform = resp;
          this.getStatus(resp);
          // this.platform.visible = resp.visible;
          // this.platform.deleted_at = resp.deleted_at;
        })
        .catch((err) => {
          this.toastService.showError(
            this.translate.instant('organizations:enable:error')
          );
        });
    }
  }
  switchOrganization(organization: Organization): void {
    const currentOrg = this.initService.getCurrentOrg();
    if (currentOrg && currentOrg.slug != organization.slug) {
      let aDomain = window.location.host.split('.');
      aDomain[0] = organization.slug;
      window.location.href =
        window.location.protocol + '//' + aDomain.join('.');
    } else {
      window.location.reload();
    }
  }

  public getStatus(platform: any) {
    if (platform.deleted_at != null) {
      this.status = 'Disabled';
    } else {
      if (platform.visible) {
        this.status = 'Public';
      } else {
        this.status = 'Closed';
      }
    }
  }
}
