<div class="lesson-item" (click)="goToLesson()" role="button">
    <div class="thumbnail-lesson-item" [style.background-image]='"url(" + (getImage() || "assets/img/groups/icons/list.png") +")!important"'>
        <!-- <span class="lesson-icon chapter-lesson-inner-icon-right"
            [ngClass]="{
                'chapter-lesson-inner-icon-text' : lesson?.type == 'text',
                'chapter-lesson-inner-icon-video': lesson?.video_post_id!,
                'chapter-lesson-inner-icon-microclip': lesson?.microclip_id,
                'chapter-lesson-inner-icon-questions': (lesson?.icon == 'questions') || (lesson?.icon == 'question_videos') || (lesson?.icon == 'question_microclips')
            }"
        ></span>
        <span class="lesson-icon chapter-lesson-inner-icon-left"
            [ngClass]="{
                'chapter-lesson-inner-icon-microclip': (lesson?.type == 'text' && (lesson?.icon == 'microclips' || lesson?.icon == 'question_microclips')),
                'chapter-lesson-inner-icon-video': (lesson?.type == 'text' && (lesson?.icon == 'videos' || lesson?.icon == 'question_videos'))
            }"
        ></span> -->
    </div>

    <h3 *ngIf="lesson?.name" class="the-text m-3 me-2 fw-bold">{{ lesson?.name }}</h3>

    <div class="the-right-elements d-flex" style="z-index: 9;">
      <div class="pull-right status-button paid mt-1 me-2"
          *ngIf="lesson?.free_preview"
          style="background-color: #F7B42B;">Preview</div>
        <span id="time" class="the-time" *ngIf="!(completed() || lesson!.completed) || !user">{{ (lesson?.length! ? lesson?.length! : 0) | timeLength }}</span>
        <span class="fa fa-check-circle" *ngIf="(completed() || lesson!.completed) && user"></span>
    </div>
</div>


