import { Course } from './../../../interfaces/course';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CoursesService } from 'src/app/services/courses.service';
import { environment } from 'src/environments/environment';
import { MainComponent } from 'src/app/modals/checkout/main/main.component';
import { LangService } from 'src/app/services/lang.service';
@Component({
  selector: 'app-develop-competencies',
  templateUrl: './develop-competencies.component.html',
  styleUrls: ['./develop-competencies.component.scss'],
})
export class DevelopCompetenciesComponent implements OnInit {
  @Output() startLearning = new EventEmitter<any>();
  @Input() selfModal: NgbModalRef | null = null;
  @Input() course: any = {};
  faTimes = faTimes;
  public courseId: string = '';
  public loading: boolean = false;
  public priceCourse: number = 0;
  public courseAux: any = {};
  public language = 'nl';
  public compentencyPrice: any = 24.5;
  public selectedCompentences: any = [];
  constructor(
    private coursesService: CoursesService,
    private ngbModal: NgbModal,
    private lang: LangService
  ) {
    this.lang.language.subscribe((lang) => {
      this.language = lang || 'en';
      if (this.language == 'en') {
        this.courseId = environment.en_compentencyCourseId;
        this.compentencyPrice = environment.en_compentencyPrice;
      } else {
        this.courseId = environment.nl_compentencyCourseId;
        this.compentencyPrice = environment.nl_compentencyPrice;
      }
    });
  }
  ngOnInit(): void {
    this.courseAux = { ...this.course };
    this.courseAux.status='';
    console.log('este es el curso desde el modal', this.course);
  }

  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
  openCheckout() {
    this.closeModal();
    console.log('esto es en cursos ', this.course);

    let modalRef = this.ngbModal.open(MainComponent, {
      windowClass: 'default-modal',
      size: '600',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.course = this.course;
    modalRef.componentInstance.selfModal = modalRef;
    modalRef.componentInstance.initialDays = 30;
    modalRef.componentInstance.ngOnChanges();
  }
  public getCourse() {
    this.loading = true;
    this.coursesService.getObservableSummary(parseInt(this.courseId)).subscribe(
      (course) => {
        if (course) {
          this.course = course;
          this.loading = false;

          let chapterWithAccess: any = [];
          chapterWithAccess = this.course.chapters.filter((item: any) => {
            return item.access == true;
          });

          this.selectedCompentences = [
            // uso el set para no duplicar elementos
            ...new Set(
              this.getCompetenceByChapter(
                chapterWithAccess,
                this.course.competency_access
              )
            ),
          ];
        } else {
          this.closeModal();
        }
      },
      (error) => {
        this.closeModal();

        this.loading = false;
      }
    );
  }

  public goToCourse() {
    // Abre el enlace en una nueva pestaña
    window.open(`/courses/${this.courseId}`, '_blank');
  }
  public selectParts() {
    this.closeModal();
    this.startLearning.emit({
      competencies: true,
      course: this.course,
    });
  }
  public getPrice(price: any) {
    this.priceCourse = price;
  }

  getCompetenceByChapter(chapterWithAccess: any, competency_access: any) {
    return chapterWithAccess
      .filter((chapter: any) => {
        return competency_access.some((access: any) => {
          return access.chapter.includes(chapter.id);
        });
      })
      .map((chapter: any) => {
        return competency_access.find((access: any) => {
          return access.chapter.includes(chapter.id);
        }).competence;
      });
  }
}
