<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h5 class="modal-title"> 
    {{ "organization:members:send_invitation:title" | translate }}
  </h5>
</div>
<div class="modal-body">
  <div class="container">
    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="all"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <div class="container py-4">
          <div *ngIf="loading" class="row max-heigth">
            <div class="row loadingCenter">
              <app-loading-icon></app-loading-icon>
            </div>
          </div>

          <div
            *ngIf="!loading"
            class="row justify-content-center max-heigth list-overflow"
          >
            <div>
              <div class="col-12">
                <app-users-invited
                  [usersSelected]="selectUsers"
                ></app-users-invited>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6">
                  <div class="form-group">
                    <app-search-users
                      [internalUsers]="false"
                      [filterByOrganization]="false"
                      id="search"
                      (searchEvent)="usersSelected($event)"
                      type="text"
                      placeholder="{{ 'search:by:email' | translate }}"
                    ></app-search-users>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                  <div class="form-group">
                    <label class="form-label" for="name"> </label>
                    <input
                      id="name"
                      class="input-box w-100"
                      maxlength="80"
                      name="Name"
                      placeholder="{{ 'Name' | translate }}"
                      required=""
                      type="text"
                      [disabled]="true"
                    />
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-2">
                  <select
                    class="form-select"
                    aria-label="Language"
                    (change)="changeOrder()"
                    [(ngModel)]="language"
                  >
                    <option selected value="en">
                      {{ "English" | translate }}
                    </option>
                    <option value="nl">
                      {{ "Dutch" | translate }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row mt-3">
                <div class="form-group">
                  <label class="form-label" for="textarea">{{
                    "collection:members:add:members:message:label" | translate
                  }}</label>
                  <textarea
                    [(ngModel)]="textMessage"
                    class="form-control"
                    id="textarea"
                    rows="5"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group clearfix">
      <button
        (click)="sendInvitation()"
        [disabled]="!selectUsers.length || loadSpin"
        type="button"
        role="tab"
        class="btn btn-primary btn-create modal-btn-width float-right ms-2 fs-15 text-nowrap"
      >
        <fa-icon
          class="mr-2"
          [icon]="faCircleNorch"
          *ngIf="loadSpin"
          [spin]="true"
        ></fa-icon>
        {{ "Send" | translate }}
      </button>
    </div>
  </div>
</div>
