<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title">
    {{ title | translate }}
  </h4>
</div>
<div class="modal-body flex-column">
  <div class="row">
    <p class="description">
      {{ description | translate }}
    </p>
  </div>
</div>
<div class="row justify-content-end p-3">
  <!-- <hr class="common-separator my-0" /> -->
  <div class="row justify-content-end text-center">
    <div class="col-auto">
      <button
        *ngIf="showCancel"
        type="button"
        class="btn btn-outline-primary ms-2 fs-15 text-nowrap"
        (click)="closeModal()"
      >
        {{ "Cancel" | translate }}
      </button>
    </div>
    <div class="col-auto">
      <button
        (click)="disabledPlatform()"
        type="button"
        role="tab"
        class="btn btn-primary text-nowrap"
      >
        <fa-icon
          class="mr-2"
          [icon]="faCircleNorch"
          *ngIf="loadSpin"
          [spin]="true"
        ></fa-icon>
        {{ buttonText | translate }}
      </button>
    </div>
  </div>
</div>
