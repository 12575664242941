import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-widget-notification',
  templateUrl: './widget-notification.component.html',
  styleUrls: ['./widget-notification.component.scss'],
})
export class WidgetNotificationComponent implements OnInit {
  @Output() showNotification: EventEmitter<any> = new EventEmitter();
  @Output() showQuestionaire: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  showQuestionaireNotification() {
    this.showQuestionaire.emit();
  }

}
