<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span style="color: #000;">{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title fw-bold">
    {{ title }}
  </h4>
</div>
<div class="modal-body flex-column">
  <div [innerHtml]="description" class="pre-wrap"></div>
</div>

