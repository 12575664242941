import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss'],
})
export class ConfirmEmailComponent implements OnInit {
  @Input() email: string = '';
  auxEmail: string = ' ';
  constructor(
    private usersService: UsersService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    this.auxEmail = this.email;
  }
  public resendEmail() {
    this.usersService.resendEmailConfirm(this.auxEmail).then((resp) => {
      if (resp.result == 'success') {
        this.toastService.showSuccess(
          `A new email has been sent to ${this.auxEmail}`
        );
      } else {
        this.toastService.showError('error sending email');
      }
    });
  }
}
