<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">{{ "users:delete:modal:title" | translate }}</h4>
</div>
<div class="modal-body">
  <div class="col-12">
    <p>{{ "users:delete:modal:description" | translate }}</p>
  </div>
  <div class="form-group my-2">
    <label class="modal-label" for="clip-title">{{
      "users:remove:account:modal:enter:text:1" | translate 
    }}</label>
    <input
      [type]="showPassword ? 'text' : 'password'"
      [(ngModel)]="password"
      class="input-box w-100"
      id="clip-title"
      maxlength="80"
      name="password"
      (keyup)="passErr=false"
      
    />
    <span
        (click)="showHidePassword()"
        style="cursor: pointer"
        class="fa fa-fw field-icon toggle-password"
        [ngClass]="showPassword == true ? ' fa-eye' : ' fa-eye-slash'"
      ></span>
    <span class="text-danger" *ngIf="passErr">{{'Invalid grant: user credentials are invalid'|translate}}</span>
  </div>
  <div class="d-flex justify-content-end">
    <a class="btn btn-link font-weight-Light mx-2" (click)="closeModal()">
      <span>{{
        "users:deactivate:account:modal:description:button:cancel" | translate
      }}</span>
    </a>
    <button
      class="btn btn-danger"
      (click)="deleteAccount()"
      [disabled]="loading||password.length<1"
    >
      <span>{{ "users:delete:modal:description:button" | translate }}</span>

      <fa-icon
        *ngIf="loading"
        class="ms-2"
        [icon]="faCircleNotch"
        [spin]="true"
      ></fa-icon>
    </button>
  </div>
</div>
