import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InitService } from 'src/app/services/init.service';
import { LangService } from 'src/app/services/lang.service';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { LeaveCommentaryModalComponent } from 'src/app/modals/group/leave-commentary-modal/leave-commentary-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionsService } from 'src/app/utilities/permissions.service';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-file-card',
  templateUrl: './file-card.component.html',
  styleUrls: ['./file-card.component.scss']
})
export class FileCardComponent implements OnInit {

  @Input() file: any;
  @Input() tap: any;
  @Input() activities: any;
  @Input() type ='';
  @Output() refreshDelete = new EventEmitter<null>();
  @Input() isFromGroup: boolean = false;
public copyFile: any = {}
  comments: any;
  userLanguage: string | null = '';
  faComment = faComment;
  mimetypeId=1;
  mimeTypes = {
    images: [
      'image/png',
      'image/jpg',
      'image/jpeg',
      'image/gif'
    ],
    imagesaudio: [
      'audio/mpeg',
      'audio/mp3',
      'audio/x-wav',
      'audio/wav'
    ],
    documents: [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
      'text/plain',
      'application/vnd.ms-excel',
      'application/vnd.ms-excel.sheet.binary.macroenabled.12',
      'application/vnd.ms-excel.sheet.macroenabled.12',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/x-iwork-keynote-sffkey',
      'application/x-iwork-pages-sffpages',
      'application/x-iwork-numbers-sffnumbers',
      'application/pgp-keys',
      'application/octet-stream',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    ],
    pdf: [
      'application/pdf'
    ],
    files: [
      'application/zip',
      'application/x-rar-compressed',
      'application/x-rar',
      'application/x-download',
    ]
  }

  constructor(
    private initService: InitService,
    private lang: LangService,
    private ngbModal: NgbModal,
    private permissionsService: PermissionsService,
    public auth: AuthService,

  ) {
    this.lang.language.subscribe(lang => {
      this.userLanguage = lang || 'en';
    });
  }
  
  getMimeTypeImg(){
    if (this.mimeTypes.files.indexOf(this.file.mimetype)>= 0){
      this.mimetypeId=1
     }  
    if (this.mimeTypes.pdf.indexOf(this.file.mimetype)>= 0){
      this.mimetypeId=2
     }
    if (this.mimeTypes.images.indexOf(this.file.mimetype)>= 0){
      this.mimetypeId=3
     }
    if (this.mimeTypes.documents.indexOf(this.file.mimetype)>= 0){
     this.mimetypeId=4
    }
    if (this.mimeTypes.imagesaudio.indexOf(this.file.mimetype)>= 0){
      this.mimetypeId=5
     }
  }

  ngOnInit(): void {
    this.copyFile = this.file;
    if (this.type!='activity'){
      this.file=this.file.object;
    }
    if (this.type=='activity'){
      this.file=this.activities.entity;
      this.comments=this.activities.comment;

    }
    this.getMimeTypeImg();
  }

  getFileImg() {
    return 'assets/img/files/'+this.mimetypeId+'.png';
  }

  public leaveCommentaryModal(conversationId: string) {
 
      let modalRef = this.ngbModal.open(LeaveCommentaryModalComponent, {
        windowClass: 'default-modal video-modal',
        size: '800',
        centered: true,
      });
      modalRef.componentInstance.conversationId = conversationId;
      modalRef.componentInstance.typeConversation = 'file';
      modalRef.componentInstance.countComment.subscribe(($event: any) => {
        this.file.count_comments  = $event;
      });
    
  }
  public deleteItem(){
    this.refreshDelete.emit(this.copyFile?.entity_id)
  }

  public userCanEdit(item: any) {
    if (item) {
      return (
        this.permissionsService.userIsOwner(item) ||
        this.auth.hasAnyRole(['moderator', 'super_user'])
      );
    }
    return false;
  }
}
