import { Component, ElementRef, Inject } from '@angular/core';
import { NgbToastConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from './services/auth.service';

import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { LangService } from './services/lang.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from './services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor (
    ngbToastConfig: NgbToastConfig,
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private toastr: ToastService,
    private lang: LangService,
    private translate: TranslateService,
    private router: Router
  ) {
    ngbToastConfig.animation = true;

    var s = this.document.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://platform.vixyvideo.com/p/' + environment.vixyPId + '/sp/' + environment.vixyPId + '00/embedIframeJs/uiconf_id/' + environment.vixyPlayerId + '/partner_id/' + environment.vixyPId;
    this.elementRef.nativeElement.appendChild(s);
    if (this.route.snapshot.queryParamMap.get('confirm_email')) {
      //  console.log('email confirmed')
        this.toastr.showSuccess('the email has been confirmed')
    }
    this.route.queryParams.subscribe( (params: any) => {
      setTimeout(() => {
        if (params.organizationError) {
          this.toastr.showError(this.translate.instant('organization:error'));
          // Remove query params
          this.router.navigate([], {
            queryParams: {
              'organizationError': null,
            },
            queryParamsHandling: 'merge'
          })
        }
      }, 100);
    });
  }
}
