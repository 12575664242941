import { GroupsService } from 'src/app/services/groups.service';
import { Member } from './../../interfaces/member';
import { Component, Input, OnInit } from '@angular/core';
import { TableHeader } from 'src/app/interfaces/headers';
import { ToastService } from 'src/app/services/toast.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomConfirmComponent } from 'src/app/modals/custom-confirm/custom-confirm.component';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-members-list',
  templateUrl: './members-list.component.html',
  styleUrls: ['./members-list.component.scss'],
})
export class MembersListComponent implements OnInit {
  @Input() members!: Member;
  @Input() checkbox: boolean = false;
  @Input() actions: boolean = false;
  @Input() heading: boolean = true;
  @Input() customBorder: boolean = false;
  @Input() groupId: string = '';
  @Input() group: any = {};

  @Input() classes: {} = {};
  @Input() headers: TableHeader[] = [];
  public listMembers: any = [];
  constructor(
    private groupService: GroupsService,
    private toastService: ToastService,
    private ngbModal: NgbModal,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.listMembers = this.members;
    console.log( this.listMembers);
    
  }

  public checkAllCheckBox(event: any) {
    this.listMembers.forEach(
      (x: { checked: any }) => (x.checked = event.target.checked)
    );
  }
  public write(member: any, item: any) {
    if (item.nickname != 'icon') {
      if (member['type']  == 'manager') {
        return member[item?.nickname] + ' (Owner)'
      }
      if (member[item.nickname] == 'pending_by_user') {
        return 'Invited';
      } else {
        if (member[item.nickname] == 'accepted') {
          return 'Member';
        } else {
          return member[item.nickname];
        }
      }
    }
    return null;
  }

  public deleteUserInvited(user: any, index: number) {
    let modalRef = this.ngbModal.open(CustomConfirmComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.selfModal = modalRef;
    modalRef.componentInstance.title =
      'course:members:modal:delete_member:title';
    modalRef.componentInstance.description =
      'course:members:modal:delete_member:text';
    modalRef.componentInstance.buttonText =
      'course:members:modal:delete:button';
    modalRef.componentInstance.refresh.subscribe((data: any) => {
      this.groupService
        .deleteUserIvited(this.groupId, user.id)
        .then((resp: any) => {
          if (resp.result == 'success') {
            this.toastService.showSuccess('Deleted member');
            this.listMembers.splice(index, 1);
          } else {
            this.toastService.showError('Error deleting member');
          }
        });
    });
  }
  isAdmin(): boolean {
    return (this.auth.hasAnyRole(['super_user'] ) || this.auth.isOwner(this.group) );
  }
}
