import { EditDiscountComponent } from './../../modals/admin/discount/edit-discount/edit-discount.component';
import { CreateDiscountComponent } from './../../modals/admin/discount/create-discount/create-discount.component';
import { DiscountDetailComponent } from './../../modals/admin/discount/discount-detail/discount-detail.component';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-table-admin-discounts',
  templateUrl: './table-admin-discounts.component.html',
  styleUrls: ['./table-admin-discounts.component.scss'],
})
export class TableAdminDiscountsComponent implements OnInit {
  @Output() scrolled = new EventEmitter<null>();
  @Input() listDiscounts: any[] = [];
  @Output() refreshTable = new EventEmitter<any>();
  @Output() deleteEvent = new EventEmitter<any>();

  @ViewChild('container') container!: ElementRef;
  constructor(private ngbModal: NgbModal) {}
  deleteDiscount:any;
  ngOnInit(): void {}
  @HostListener('window:scroll', ['$event'])
  onScroll(e: any) {
    let bottomPosition =
      this.container.nativeElement.getBoundingClientRect().top +
      this.container.nativeElement.offsetHeight;
    if (bottomPosition < window.innerHeight) {
      this.scrolled.emit();
    }
  }

  public openDetailsDiscount(discount: any) {
    let modalRef = this.ngbModal.open(DiscountDetailComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.discount = discount;
    modalRef.componentInstance.refresh.subscribe((data: any) => {
      this.refreshTable.emit();
       
    });
  }
  public openDeleteDiscount(discount: any,content:any) {

    this.deleteDiscount=discount
    let modal = this.ngbModal.open(content, {
      windowClass: 'default-modal modal-delete' 
    });
    modal.result.then((val) => {
      console.log('borrar')
     // this.deleteProfile(dh_profile);

      this.deleteEvent.emit(discount);
     
    });
  }

  
  public openEditDiscount(discount: any) {
    let modalRef = this.ngbModal.open(CreateDiscountComponent, {
      windowClass: 'default-modal video-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.discount = discount;
    modalRef.componentInstance.refresh.subscribe((data: any) => {
      this.refreshTable.emit();
    });
  }
}
