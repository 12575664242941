<div class="questionarire-wrapper p-3">
  <div (click)="showQuestionaireNotification()" class="row mb-3">
    <div class="text-center">
      <img
        src="/assets/img/home/question.png"
        alt=""
        class="img-question mb-2"
      />
    </div>
    <!-- <img (click)="hide()" src="/assets/img/home/x.png" alt="" class="img-x" /> -->
  </div>
</div>
