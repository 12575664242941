import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { ToastService } from '../services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor (
    private router: Router,
    private toastService: ToastService,
    private auth: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if ( !this.auth.isAuthenticated() ) {
      this.toastService.showError('Authentication needed');
      localStorage.setItem('remember_url', state.url);
      this.router.navigateByUrl('/login');
      return false;
    }

    let roles: Array<string> = route.data.requiredRoles;
    if ( this.auth.hasAnyRole(roles) ) {
      return true;
    }

    this.toastService.showError('Permission needed');
    this.router.navigateByUrl('/');
    return false;
  }
}
