import { Component, Input, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { GroupsService } from 'src/app/services/groups.service';
import { ToastService } from 'src/app/services/toast.service';
import {Router} from "@angular/router"
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-course-modal',
  templateUrl: './create-modal.component.html',
  styleUrls: ['./create-modal.component.scss']
})

export class CreateCourseModalComponent implements OnInit {

  faTimes = faTimes;
  @Input() selfModal: NgbModalRef | null = null;
  @Input() title:String='';
  loadSpin: boolean = false;
  faCircleNorch: any = faCircleNotch;

  public modulesQuill = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],
        [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      ],
    },
  };

  loading: boolean = false;
  course = {
    type: "course",
    name: "",
    subtitle: '',
    open: true,
    language: "nl"
  };

  constructor(
    private groupsService: GroupsService,
    private toastService: ToastService,
    private router: Router,
    private translate: TranslateService,
  ) {

   }


  ngOnInit(): void {
  }
  closeModal(){
    if(this.selfModal) this.selfModal?.close();
    else $("ngb-modal-window").click();
  }
  create(){
    this.loadSpin = true;
    this.loading=true;
    this.groupsService.save(this.course).then( res => {
      this.toastService.showSuccess(this.translate.instant('courses:create:success'));
      let id = res.data.collection.id;
      this.router.navigate(['/courses', 'edit', id])
      this.loading = false;
      this.loadSpin = false;

      this.closeModal();
    }).catch( err => {
      console.log(err);
      this.loading=false;
      this.loadSpin = false;
      this.toastService.showError(this.translate.instant('courses:create:error'));
    });
  }
}
