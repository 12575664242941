import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { Questionnaire } from 'src/app/interfaces/questionnaire';
import { AuthService } from 'src/app/services/auth.service';
import { DhprofileService } from 'src/app/services/dhprofile.service';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { UsersService } from 'src/app/services/users.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  sidebarState = 'collapsed';
  showConfirmEmail: boolean = true;
  email: string = '';
  organizations: any = [];
  hideSidebar: boolean = false;
  hideClass: boolean = false;
  loading: boolean = true;
  public currentLang: string = 'en';
  constructor(
    private usersService: UsersService,
    private auth: AuthService,
    private httpClient: HttpClient,
    private orgsService: OrganizationsService,
    private router: Router,
    private lang: LangService,

  ) {
    this.lang.language.subscribe((lang) => {
      this.currentLang = lang || 'en';
    });
    const hostName = window.location.hostname;
    if (hostName.startsWith('courses.')) { //lo que hacemos aca es redirigir a la landing si no esta logeado. solo para courses
      if (!this.isAuthenticated() && this.router.url === '/') {
        if (this.currentLang == 'nl') {
          window.location.href = environment.URL_PLATFORM;
        }else{
          window.location.href = environment.URL_PLATFORM + 'en';

        }
      }else{
        this.loading=false;
      }
    }else{
      this.loading=false
    }
    auth.changeEmitted$.subscribe((resp) => {
      this.hideClass = resp;
      setTimeout(() => {
        this.hideSidebar = resp;
        this.loading=false
      }, 1000);
    });
  }

  ngOnInit(): void {
    this.getInfoProfile();
    // this.orgsService.getUserOrganizationsMenu().then((organizations) => {
    //   // this.organizations = organizations;
    // });
  }

  isAuthenticated(){
    return  this.auth.isAuthenticated();
  }
  toggleSidebar() {
    this.sidebarState = this.sidebarState === 'out' ? 'collapsed' : 'out';
  }
  public getInfoProfile() {
    let self=this;
    if (this.auth.isAuthenticated()) {
      this.usersService.getProfile().then((user: any) => {
        this.showConfirmEmail = user?.email_confirmed!;
        this.email = user.email!;
        this.organizations = user.organizations.sort((a:any, b:any) => a.name.localeCompare(b.name));        
       //if pending invation redirect to accept invitation. 
        let org=self.orgsService.getCurrent();
        //debugger
        if (!org?.status||org?.status=='pending'){
          const subdomain = window.location.href.split('/')[2].split('.')[0];
          let apiUrl = environment.apiUrl;
          this.httpClient.get( apiUrl + '/organization/slug/' + subdomain ).subscribe((orgData:any)=>{
            if (orgData.data?.status=='pending'&&user){
              console.log('testing acceptinvitation reload. main.components 79 conmment line ')
             // window.location.href = '/acceptinvitation/'+orgData.data?.slug+'/'+orgData.data?.id+'/member/'+user.id
  
            }
           });
        }

        // }else{
        //   if (org?.status=='pending'&&user){
        //     window.location.href = '/acceptinvitation/'+org?.slug+'/'+org?.id+'/member/'+user.id

        //   }
        // }
        //       // console.log('this.currentOrg',this.currentOrg)
          
      });
    }
  }
}
