import { Component, Input, OnInit } from '@angular/core';
import { InitService } from 'src/app/services/init.service';
import { LangService } from 'src/app/services/lang.service';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { CourseUtilitiesService } from 'src/app/utilities/course-utilities.service';
import { Lesson } from 'src/app/interfaces/lesson';
import { Chapter } from 'src/app/interfaces/chapter';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EnrolCourseModalComponent } from 'src/app/modals/courses/enrol-course-modal/enrol-course-modal.component';

@Component({
  selector: 'app-continue-course',
  templateUrl: './continue-course.component.html',
  styleUrls: ['./continue-course.component.scss'],
})
export class ContinueCourseComponent implements OnInit {
  @Input() course: any;
  @Input() chapterIndex: number = 0;

  lesson: Lesson | null = null;
  chapter: any;
  image: string = '';
  constructor(
    private courseUtilitiesService: CourseUtilitiesService,
    private router: Router,
    private ngbModal: NgbModal
  ) {}

  ngOnInit(): void {
    this.lesson = this.courseUtilitiesService.getCurrentLesson(this.course);

    this.course?.chapters.forEach((chapter: Chapter, i: number) => {
      if (chapter.id == this.chapter?.id) {
        this.chapterIndex = i;
      }
    });
    if (this.lesson) {
      this.chapter = this.course.chapters.filter((chapter: Chapter) => {
        return chapter.id == this.lesson?.chapter_id;
      });

      if (this.chapter) {
        this.chapter = this.chapter[0];
      }

      this.image = this.courseUtilitiesService.getCurrentThumbnail(this.course)
        ? this.courseUtilitiesService.getCurrentThumbnail(this.course)
        : '../../../assets/img/groups/icons/list.png';

      this.course.type =
        this.course.type == 'course' ? 'Course' : this.course.type;
    }
  }

  isFirstLesson(): boolean {
    let first: Lesson = this.courseUtilitiesService.getFirstLesson(this.course);
    if (first) {
      return first.id == this.lesson?.id;
    }
    return true;
  }

  courseProgress(): number {
    if (this.course.course_progress) {
      return Math.floor(
        parseFloat(this.course.course_progress.toFixed(3)) * 100
      );
    } else if (this.course.chapters) {
      return 0;
    }
    return 0;
  }
  userIsEnrolled(): boolean {
    if (this.course) {
      return this.courseUtilitiesService.userIsEnrolled(this.course!);
    }
    return false;
  }

  goToChapter(courId: any, chapterId: any) {
    if (this.userIsEnrolled() && this.chapter.access) {
      this.router.navigate(['course-viewer', courId, 'chapter', chapterId], {
        queryParams: { number: this.chapterIndex || 0 },
      });
    } else {
      this.enrolCourseModal();
    }
  }

  enrolCourseModal() {
    let modalRef = this.ngbModal.open(EnrolCourseModalComponent, {
      windowClass: 'default-modal',
      size: '600',
      centered: true,
    });
    modalRef.componentInstance.course = this.course;
    modalRef.componentInstance.chapter = this.chapter;
  }
}
