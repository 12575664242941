import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Microclip } from 'src/app/interfaces/microclip';
import { VgApiService } from '@videogular/ngx-videogular/core';

@Component({
  selector: 'app-microclip-player',
  templateUrl: './microclip-player.component.html',
  styleUrls: ['./microclip-player.component.scss'],
})
export class MicroclipPlayerComponent implements OnInit, OnChanges {
  @Output() watched: EventEmitter<Microclip> = new EventEmitter();
  @Input() microclip: any | undefined;
  API: VgApiService | undefined;

  flavor: any;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.microclip?.currentValue) {
      if (this.microclip?.video_post?.source?.flavors?.flavor) {
        this.flavor = JSON.parse(
          this.microclip?.video_post?.source?.flavors?.flavor!
        );
      } else {
        this.flavor = this.microclip?.videopost?.source?.flavors;
      }
    }
  }

  playerReady(api: VgApiService) {
    this.API = api;

    let target = this.API.getDefaultMedia();

    let emmited = false;

    if (target) {
      target.subscriptions.timeUpdate.subscribe((data) => {
        if (this.API?.currentTime >= this.microclip?.end_time! / 1000) {
          this.API?.pause();
          if (!emmited) {
            this.watched.emit(this.microclip);
            emmited = true;
          }
        }
      });
      target.subscriptions.play.subscribe((data) => {
        if (this.API?.currentTime >= this.microclip?.end_time! / 1000) {
          this.API?.seekTime(this.microclip?.start_time! / 1000);
          emmited = false;
        }
      });
    }
  }

  ngOnInit(): void {}
}
