<div class="card shadow">
  <div
    ngbDropdown
    class="btn-toggle text-primary border rounded"
    placement="bottom-right"
  >
    <button type="button" class="btn" id="dropdownBasic1" ngbDropdownToggle>
      <i class="fa fa-ellipsis-h"></i>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="mt-1">
      <button
        *ngIf="status == 'Public' || status == 'Closed'"
        ngbDropdownItem
        (click)="disabledPlatform(platform)"
      >
        {{ "Disabled this platform" | translate }}
      </button>
      <button
        *ngIf="status == 'Disabled'"
        ngbDropdownItem
        (click)="disabledPlatform(platform)"
      >
        {{ "Enable" | translate }}
      </button>
    </div>
  </div>

  <img
    (click)="switchOrganization(platform)"
    [src]="platformImg"
    class="card-img-top"
    width="100%"
    height="130"
  />

  <div
    (click)="switchOrganization(platform)"
    class="card-body position-relative"
  >
    <span
      class="badge rounded-pill p-2 px-3 text-white position-absolute fw-normal"
      [ngClass]="
        status == 'Public' || status == 'Closed' ? 'bg-success' : 'bg-secondary'
      "
    >
      {{ status }}
    </span>

    <h3 class="card-title mb-2 mt-2 fw-normal short-name">
      {{ platform?.name }}
    </h3>

    <p
      class="card-text short-description p-margin"
      [innerHTML]="platform?.description"
    ></p>

    <div
      class="d-flex justify-content-start align-items-center"
      style="min-height: 21px"
    >
      <fa-icon [icon]="faPlay" class="text-muted-light"></fa-icon>
      <span class="ms-1">
        {{ platform?.users_quantity }}
        {{ "Users" | translate }}
      </span>
    </div>
  </div>
</div>
