import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CoursesService } from 'src/app/services/courses.service';
import { UsersService } from 'src/app/services/users.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-share-course',
  templateUrl: './share-course.component.html',
  styleUrls: ['./share-course.component.scss'],
})
export class ShareCourseComponent implements OnInit {
  @Input() courseId: any = '';
  @Input() organizations: any = '';
  @Input() user: any = '';
  
  @Input() selfModal: NgbModalRef | null = null;
  @Output() refresh: EventEmitter<any> = new EventEmitter();

  public faCircleNorch: any = faCircleNotch;
  public organizationNames='';
  public faTimes = faTimes;
  public title = '';
  public loadSpin: boolean = false;

  constructor(
    private toastService: ToastService,
    private translateService: TranslateService,
    private coursesService: CoursesService,
    private usersService: UsersService
    
  ) {}

  ngOnInit(): void {
    console.log('organizations modal',this.organizations)
    this.title=this.translateService.instant('collection:share:other:organizations:modal:message:default')
    this.organizations.forEach((element:any) => {
      this.organizationNames+=element.name+',';
      
    });
 
    this.organizationNames = this.organizationNames.substring(0, this.organizationNames.length - 1);
  }

  public shareCourse() {
    this.loadSpin = true;
   

    let organizationsID = this.organizations
    .filter((o:any) => o.checked)
    .map((o:any) => o.id);
 
  

    this.usersService
      .inviteToOrganizations(this.user.username,organizationsID,this.title)
      .then((res) => {
        this.loadSpin = false;
        this.refresh.emit()
        this.closeModal();
      })
      .catch((err) => {
        this.toastService.showError(
          this.translateService.instant('collection:open:unenroll:error')
        );
      });
  }

  public closeModal() {

    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  public loadCourse() {
    this.coursesService.loadCourseSummary(this.courseId);
  }
}
