<div>
  <p class="activity-item-generic-content activity-item-comment-from">
    {{ countComments() }}
    {{
      countComments() == 1
        ? ("comment:group" | translate)
        : ("comments:group" | translate)
    }}
  </p>
  <app-conversation-card
    *ngIf="activity"
    containerClass="comments"
    [conversation]="activity"
    [showIcon]="false"
    [tab]="tab"
    (refreshConversations)="refresh($event)"
  ></app-conversation-card>

  <div class="comments" *ngIf="tab != 'activity'">
    <app-comments-container
      *ngIf="type == 'commentaryConversation'"
      [type]="'commentaryConversation'"
      [activity]="activities"
      (countComment)="refresh($event)"
      placeholder="{{ 'comments:placeholder' | translate }}"
      [title]="false"
    ></app-comments-container>
    <app-comments-container
      *ngIf="type === 'file'"
      [type]="'file'"
      [activity]="activities"
      (countComment)="refresh($event)"
      placeholder="{{ 'comments:placeholder' | translate }}"
      [title]="false"
    ></app-comments-container>

    <app-comments-container
      *ngIf="type != 'commentaryConversation' && type != 'file'"
      type="activity_group"
      [activity]="activities"
      placeholder="{{ 'comments:placeholder' | translate }}"
      [title]="false"
    ></app-comments-container>
  </div>
</div>
