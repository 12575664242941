import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { CheckoutQuestionnaireService } from '../checkout-questionnaire.service';
import * as moment from 'moment';
import { DiscountsService } from 'src/app/services/discounts.service';
import { ToastService } from 'src/app/services/toast.service';
import { CountryService } from 'src/app/services/country.service';
import { InvoiceDetails } from 'src/app/interfaces/order';
import { LangService } from 'src/app/services/lang.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-order-preview-tab',
  templateUrl: './order-preview-tab.component.html',
  styleUrls: ['./order-preview-tab.component.scss']
})
export class OrderPreviewTabComponent implements OnInit {
  @Input() competencies: any = [];

  code = '';
  applyingCode = false;
  codeError: string | undefined;
  public currentCountry: string = ''
  showConditions = false;

  original_price: number | undefined;
  public language = 'nl';
  public compentencyPrice: any = 24.5;

  constructor(
    public checkout: CheckoutQuestionnaireService,
    private translate: TranslateService,
    private auth: AuthService,
    private discountService: DiscountsService,
    private toastr: ToastService,
    private countriesService: CountryService,
    private lang: LangService,
  ) {

    this.lang.language.subscribe((lang) => {
      this.language = lang || 'en';
      if (this.language == 'en') {
        this.compentencyPrice = environment.en_compentencyPrice;
      } else {
        this.compentencyPrice = environment.nl_compentencyPrice;
      }
    });
   }

  ngOnInit(): void {
    if (this.auth.isAuthenticated()) {
      
      this.auth.user.subscribe((user:any) => {
        this.currentCountry = user.country_code;
      });
    }else{
      this.currentCountry = this.countriesService.getDefaultCountryCode()

    }
  }

  applyCode () {
    this.codeError = undefined;
    if (this.code == '') {
        this.codeError = this.translate.instant('discount:course:coupon:expired');
        return;
    }

    this.applyingCode = true;

    this.discountService.getByCode(this.code)
      .then( (response: any) => {
        if (moment(response.data.valid_until).isBefore()) {
          this.codeError = this.translate.instant('discount:course:coupon:expired');
          return;
        }
        if (moment(response.data.valid_from).isAfter()) {
          this.codeError = this.translate.instant('discount:course:coupon:unavailable');
          return;
        }

        if (response.data.collection_id !== this.checkout.course!.id) {
          this.codeError = this.translate.instant('discount:course:coupon:wrong_course');
          return;
        }

        if (response.total_available > 0 && response.data.remaining_uses < 1) {
          this.codeError = this.translate.instant('discount:course:coupon:expired');
          return;
        }

        this.checkout.discount = response.data;
        this.original_price = this.checkout.subscription?.price;
        this.checkout.subscription!.price = this.getPriceWithDiscount(this.checkout.subscription!.price);
        this.checkout.calculateTotalAmount();
        this.toastr.showSuccess(this.translate.instant('discount:course:coupon:success'));
      })
      .catch( (error: any) => {
        this.codeError = this.translate.instant('discount:course:coupon:expired');
        this.checkout.subscription!.price = this.getPriceWithDiscount(this.checkout.subscription!.price);
      })
      .finally( () => {
        this.applyingCode = false;
      });
  };

  removeCode() {
    this.code = '';
    this.checkout.discount = null;
    this.checkout.subscription!.price = this.original_price!;
    this.checkout.calculateTotalAmount();
    this.codeError = undefined;
  }

  getSubscriptionEnds() {
    var format = 'MMMM Do YYYY';
    if (this.auth.getMe() && this.auth.getMe()!.language == 'nl') {
        format = 'D MMMM YYYY';
    }

    return moment().add(this.checkout.subscription!.subscription_days, 'days').format(format);
  };

  getSubscriptionStart() {
    var format = 'MMMM Do YYYY';
    if (this.auth.getMe() && this.auth.getMe()!.language == 'nl') {
        format = 'D MMMM YYYY';
    }
    return moment(this.checkout.order!.created_at).format(format);
  };

  toggleConditions() {
    this.showConditions = true;
  }

  getTotalDiscount(){
    return (this.checkout.order!.tax + this.checkout.order!.in_total)-this.getPriceWithDiscount(this.checkout.order!.tax + this.checkout.order!.in_total)
  }
  getTotalValue(){
    return this.getPriceWithDiscount(this.checkout.order!.tax + this.checkout.order!.in_total)
  }

  getTaxValue(){
   // return this.getTotalValue()*this.checkout.order!.tax_percentage;
   
   return this.getTotalValue() - this.getNetValue() ;
  }
  getTaxBycountry(){
    let value=this.countriesService.getCountryTax((this.checkout!.order!.invoice_details as InvoiceDetails).country_code);
    return value;
    
  }

  getNetValue(){
    let tax =  this.checkout.order!.tax_percentage ? this.checkout.order!.tax_percentage : this.getTaxBycountry() 
    //return (this.getTotalValue()-this.getTotalValue()*this.checkout.order!.tax_percentage)
    return (this.getTotalValue()/(1+tax))
  }
  
  getPriceWithDiscount(value: number) {
    if (!this.checkout.discount)
      return value;
    else if (this.checkout.discount.type === 'percentage')
      return value * (1 - this.checkout.discount.amount / 100);
    return Math.max(0, value - this.checkout.discount.amount);
  }
}
