import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'src/app/interfaces/subscription';
import { CheckoutQuestionnaireService } from '../checkout-questionnaire.service';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { DiscountsService } from 'src/app/services/discounts.service';
import * as moment from 'moment';


@Component({
  selector: 'app-plan-tab',
  templateUrl: './plan-tab.component.html',
  styleUrls: ['./plan-tab.component.scss']
})
export class PlanTabComponent implements OnInit, OnChanges {
  @Input('subscriptions') subscriptions: Array<Subscription> | undefined;
  @Input('initial') initial: number | undefined;
  @Input('isGift') isGift: boolean = false;
  @Output('changed') changed: EventEmitter<Subscription> = new EventEmitter();
  code = '';
  applyingCode = false;
  subscriptions_original:any;
  codeError: string | undefined;
  original_price: number | undefined;
  constructor(
    public checkout: CheckoutQuestionnaireService,
    private toastr: ToastService,
    private translate: TranslateService,
    private discountService: DiscountsService,


    ) {         
 
}

  ngOnInit(): void {
    this.subscriptions_original=JSON.parse(JSON.stringify(this.subscriptions)) 
   
  }
  applyCode () {
    this.codeError = undefined;
    if (this.code == '') {
        this.codeError = this.translate.instant('discount:course:coupon:expired');
        return;
    }

    this.applyingCode = true;
    this.checkout.calculateTotalAmount();
    this.discountService.getByCode(this.code)
      .then( (response: any) => {
        if (moment(response.data.valid_until).isBefore()) {
          this.codeError = this.translate.instant('discount:course:coupon:expired');
          return;
        }
        if (moment(response.data.valid_from).isAfter()) {
          this.codeError = this.translate.instant('discount:course:coupon:unavailable');
          return;
        }

        if (response.data.collection_id !== this.checkout.course!.id) {
          this.codeError = this.translate.instant('discount:course:coupon:wrong_course');
          return;
        }

        if (response.total_available > 0 && response.data.remaining_uses < 1) {
          this.codeError = this.translate.instant('discount:course:coupon:expired');
          return;
        }
        

        this.checkout.discount = response.data;
       // this.original_price = this.checkout.subscription?.price;
     //   console.log('mi checko',this.checkout)
      //  console.log('mi susc',this.checkout.subscription)
        
        
        
        // let sub = this.subscriptions?.find( (s) => s.subscription_days == this.initial)
        // let aux=(sub?.subscription_days == this.checkout.course?.summaryOrders?.subscription_day_selected ? sub?.price : (sub?.originalPrice?sub.originalPrice:sub?.price))
        // this.checkout.order!.in_total=aux?aux-this.checkout.order!.tax:0;

        // this.checkout.order!.in_total=this.checkout.subscription!.price;
        // let aux=this.checkout.subscription!.originalPrice
        // if (!aux){
        //   this.checkout.subscription!.originalPrice=this.checkout.subscription!.price;
        //   aux=this.checkout.subscription!.originalPrice
        // }
        // this.checkout.subscription!.price = this.getPriceWithDiscount(aux);
        this.checkout.calculateTotalAmount();
        // console.log('mi susc2',this.checkout.subscription)
        // console.log(this.checkout)
        this.toastr.showSuccess(this.translate.instant('discount:course:coupon:success'));
      })
      .catch( (error: any) => {
        this.codeError = this.translate.instant('discount:course:coupon:expired');
        this.checkout.subscription!.price = this.getPriceWithDiscount(this.checkout.subscription!.price);
      })
      .finally( () => {
        this.applyingCode = false;
      });
  };

  removeCode() {
    
   
    this.code = '';
    this.checkout.discount = null;
 //   this.checkout.subscription!.price = this.original_price!;
    this.checkout.calculateTotalAmount();
    this.codeError = undefined;
  }

  getPriceWithDiscount(value: number) {
    if (!this.checkout.discount)
      return value;
    else if (this.checkout.discount.type === 'percentage')
      return value * (1 - this.checkout.discount.amount / 100);
    return Math.max(0, value - this.checkout.discount.amount);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.onSubscriptionChange();
  }

  onSubscriptionChange(): void {

    //this.checkout.calculateTotalAmount();
    let sub = this.subscriptions?.find( (s) => s.subscription_days == this.initial)
    if (!sub) {
      sub = this.subscriptions?.find( s => !!s.subscription_days )
      this.initial = sub?.subscription_days;
      this.original_price =sub?.price;
     
    }
    //console.log('sub',sub);
    //this.checkout.calculateTotalAmount();
    let aux=(sub?.subscription_days == this.checkout.course?.summaryOrders?.subscription_day_selected ? sub?.price : (sub?.originalPrice?sub.originalPrice:sub?.price))
    this.checkout.order!.in_total=aux?aux-this.checkout.order!.tax:0;
   // console.log('this.checkout.',this.checkout.order)
    this.changed.emit(sub);
  }
}
