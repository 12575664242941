<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">
    {{ "organization:members:create_account:title" | translate }}
  </h4>
</div>
<div class="modal-body">
  <div class="form-group">
    <label class="form-label" for="clip-title">{{
      "organization:members:create_account:user" | translate
    }}</label>
    <input
      [(ngModel)]="infoUser.name"
      class="input-box w-100"
      id="clip-title"
      maxlength="80"
      name="name"
      placeholder="{{ 'Username' | translate }}"
      type="text"
    />
  </div>
  <div class="form-group">
    <label class="form-label mt-3">{{
      "sign_up:email:label" | translate
    }}</label>
    <input
      email="true"
      [(ngModel)]="infoUser.email"
      class="input-box w-100"
      maxlength="80"
      placeholder="example@gmail.com"
    />
  </div>
  <div class="form-group">
    <label class="form-label mt-3">{{ "Password" | translate }}</label>
    <input
      [(ngModel)]="infoUser.password"
      class="input-box w-100"
      maxlength="30"
    />
  </div>
  <div class="form-group">
    <label class="form-label mt-3">{{
      "organization:members:create_account:password_repeat" | translate
    }}</label>
    <input
      [(ngModel)]="passwordRepeat"
      class="input-box w-100"
      maxlength="30"
    />
  </div>

  <hr class="common-separator" />
  <div class="form-group">
    <label class="form-label" for="clip-title">{{
      "organization:members:create_account:user_settings:label" | translate
    }}</label>
    <div class="small-form-text px-0">
      {{
        "organization:members:create_account:user_settings:subtitle:label"
          | translate
      }}
    </div>
    <label class="fancy-radio-checkbox form-label">
      <input
        [(ngModel)]="infoUser.type"
        class="fancy-input"
        id="open"
        name="typeRole"
        type="radio"
        value="user"
      />
      <div class="fancy-graphic"></div>
      {{ "Normal User" | translate }}
    </label>
    <label class="fancy-radio-checkbox form-label">
      <input
        [(ngModel)]="infoUser.type"
        class="fancy-input"
        id="closed"
        name="typeRole"
        type="radio"
        value="moderator"
      />
      <div class="fancy-graphic"></div>
      {{ "Moderator" | translate }}
    </label>
    <label *ngIf="isAdmin()" class="fancy-radio-checkbox form-label">
      <input
        [(ngModel)]="infoUser.type"
        class="fancy-input"
        id="closed"
        name="typeRole"
        type="radio"
        value="super_admin"
      />
      <div class="fancy-graphic"></div>
      {{ "Super admin" | translate }}
    </label>
  </div>
</div>
<div class="modal-footer">
  <div class="form-group clearfix">
    <button
      [disabled]="infoUser.name == '' || loadSpin"
      (click)="create()"
      type="button"
      role="tab"
      class="btn btn-primary btn-create float-right ms-2 fs-15"
    >
      <fa-icon
        class="mr-2"
        [icon]="faCircleNorch"
        *ngIf="loadSpin"
        [spin]="true"
      ></fa-icon>
      {{ "Create" | translate }}
    </button>
  </div>
</div>
