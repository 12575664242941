import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as Highcharts from 'highcharts';
import { DhProfile } from 'src/app/interfaces/dh-profile';
@Component({
  selector: 'app-chart-modal',
  templateUrl: './chart-modal.component.html',
  styleUrls: ['./chart-modal.component.scss'],
})
export class ChartModalComponent implements OnInit, OnChanges {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() numbChart: number = 1;
  @Input() competence: any = {};
  @Input() members: any = {};
  @Input() hypothetical: boolean = false;
  @Input() idTeam: any = '';
  @Input() teamAverage: any = '1';
  @Input() selectedUsers: Array<any> = [];
  @Input() chart: any = [];
  average: any = 1;

  faTimes = faTimes;
  public loadSpin: boolean = false;

  public faCircleNorch = faCircleNotch;
  public imgChart = '../../../../assets/img/dh-chart';
  colors = [
    '#F44336',
    '#673AB7',
    '#009588',
    '#8BC34A',
    '#AA01FF',
    '#D80173',
    '#FF9802',
    '#334190',
    '#01BCD4',
    '#795548',
    '#9E9E9E',
    '#FFC108',
    '#3F687C',
    '#023851',
    '#D33C0D',
    '#D1DB6D',
    '#9C26B0',
    '#FFEB3B',
    '#2096F3',
    '#0e45e4',
    '#d30ee4',
    '#4bf2eb',
    '#ccf24b',
    '#f2aa4b',
    '#b5ee82',
    '#7a0bf0',
    '#c11e9c',
  ];
  ////////////
  // Chart
  ///////////
  Highcharts: typeof Highcharts = Highcharts;

  chartOptions: any = {};
  constructor() {}

  ngOnInit(): void {
    this.average = this.teamAverage;
    this.imgChart = `${this.imgChart}/chart-${this.numbChart}.png`;
    this.initChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.average = this.teamAverage;
    this.imgChart = `${this.imgChart}/chart-${this.numbChart}.png`;
  }

  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  getAColor(): string {
    let color = this.colors[(Math.random() * this.colors.length) | 0];
    this.colors = this.colors.filter((c) => c != color);
    return color;
  }
  initChart() {
    let usersMapped: any = [];
    let names: any = [];
    let usersFilters;
    this.members.map((item: any, index: number) => {
      if (this.hypothetical) {
        usersMapped.push({
          name: item?.display_name || '',
          y:
            Math.round(
              item.dh_profiles[item?.dh_profiles.length - 1].spider_web[this.numbChart - 1].average * 100
            ) / 100,
          color: this.getSerieColor(item),
          id: item.id,
        });
      } else {
        usersMapped.push({
          name: item?.display_name || '',
          y:
            Math.round(
              item.dh_profiles.find(
                (user: any) => user?.dh_team_id == this.idTeam
              )?.spider_web[this.numbChart - 1].average * 100
            ) / 100,
          color: this.getSerieColor(item),
          id: item.id,
        });
      }
    });

    usersMapped.sort(function (a: any, b: any) {
      return b.y - a.y;
    });

    usersMapped.unshift({
      name: 'Team Average',
      y: Number(this.average),
      color: '#1cb451',
    });

    usersFilters = usersMapped.filter((item: any) => item.color != 'black');
    usersFilters.forEach((item: any) => {
      names.push([this.transformName(item?.name, 20)]);
    });
    this.chartOptions = {
      chart: {
        type: 'column',
        width: usersFilters.length <= 3 ? 750 : usersFilters.length * 220,
        height: 300,
        backgroundColor: 'transparent',
        spacingBottom: 0,
      },
      colors: ['rgba(234, 234, 234, 0.77)'],
      title: {
        text: '',
      },
      subtitle: {
        text: '',
      },

      legend: {
        enabled: false,
      },
      xAxis: {
        categories: names,
      },
      yAxis: {
        title: {
          text: '',
        },

        gridLineWidth: 1,
        minorGridLineWidth: 1,
        min: 0,
        max: 5,
        tickInterval: 1,
        plotLines: [
          {
            color: 'black',
            width: 1.5,
            value: this.average,
            zIndex: 9,
            dashStyle: 'Solid',
          },
        ],
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
          pointWidth: 120,
          minPointLength: 5,
          maxPointLength: 50,
          pointPadding: 0,
          groupPadding: 0.09,
          pointRange: 0,
          borderRadius: 5,
        },
      },
      series: [
        {
          name: '',
          maxPointWidth: 400,
          borderRadius: 5,
          type: 'column',

          data: usersFilters, //dynamic data,
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };
    this.Highcharts.chart('chart', this.chartOptions);
  }
  getSerieColor(profile: any): string {
    if (this.selectedUsers.includes(profile.id)) {
      let serie = this.chart.ref?.series.find(
        (s: any) => s.options.id == profile.id.toString()
      );
      return serie?.options.color?.toString() || 'black';
    } else {
      return 'black';
    }
  }
  transformName(input: any, limit: any) {
    input = input.slice(0, limit);
    input += '...';
    return input;
  }
}
