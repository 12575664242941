import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Video } from 'src/app/interfaces/video/video';
import { ApiService } from 'src/app/services/api.service';
import { ToastService } from 'src/app/services/toast.service';
import { VideoEditService } from '../video-edit.service';

@Component({
  selector: 'app-advanced-setting-form',
  templateUrl: './advanced-setting-form.component.html',
  styleUrls: ['./advanced-setting-form.component.scss']
})
export class AdvancedSettingFormComponent implements OnInit {
  @Output('touch') touch: EventEmitter<boolean> = new EventEmitter();
  @Input('video') video: Video|any;

  constructor(
    private api: ApiService,
    private toastr: ToastService,
    public videoEditService: VideoEditService
  ) { }

  ngOnInit(): void {
  }

  touched() {
    this.touch.emit(true);
  }

  save() {
    return this.api.put('/video/' + this.video?.id + '/source', this.videoEditService.source)
      .then((response) => {
        if (response.result && response.result.origin_id) {
          this.videoEditService.source.origin.id = response.result.origin_id;
        }
        this.touch.emit(false);
      })
      .catch((response) => {
        this.toastr.showError('something went wrong updating clip source');
      });
  }
}
