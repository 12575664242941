import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { CollectionService } from 'src/app/services/collection.service';
import { AuthService } from 'src/app/services/auth.service';
import { InitService } from 'src/app/services/init.service';
import { Course } from 'src/app/interfaces/course';
import { Paginator } from 'src/app/interfaces/paginator';

@Component({
  selector: 'app-add-course-group-modal',
  templateUrl: './add-course.component.html',
  styleUrls: ['./add-course.component.scss'],
})
export class AddCourseComponent implements OnInit {
  @Output() success: EventEmitter<any> = new EventEmitter();

  faTimes = faTimes;
  @Input() selfModal: NgbModalRef | null = null;
  @Input() title: String = '';
  @Input() group: any;
  courses: any;
  coursesOwn: any;
  faCircleNorch = faCircleNotch;
  loading = true;
  addLoading = false;
  actualTab = 'allCourse';
  username: any;
  itemSelect = '';
  searchText = '';
  searchTextOwn = '';
  public paginator: Paginator = {
    pages: 1,
    current_page: 1,
    per_page: 10,
    total: 0,
  };
  public loadingMore: boolean = false;
  constructor(
    private collectionService: CollectionService,
    public auth: AuthService,
    private init: InitService
  ) {
    this.auth.user.subscribe((user) => {
      this.username = user?.username;
    });
  }

  ngOnInit(): void {
    this.getCourseAll();
    this.getCourseOwn();
  }

  getCourseAll(isLoadMore: boolean = false) {
    if (isLoadMore) {
      this.loadingMore = true;
    } else {
      this.loading = true;
    }
    let id_organization =
        this.group?.organizations[0].id || this.init.getCurrentOrg()?.id,
      isOpen = 'true',
      excluded = this.group?.id || undefined,
      owner = '';

    this.collectionService
      .getCollection(
        this.paginator.per_page,
        this.paginator.current_page,
        'course',
        id_organization,
        this.searchText,
        isOpen,
        excluded,
        owner,
        null
      )
      .then((res) => {
        if (isLoadMore) {
          this.loadingMore = false;
        } else {
          this.loading = false;
          this.courses = [];
        }
        let copy = res?.data?.collections.map((data: any) => data);
        this.paginator = res.paginator;
        this.courses = [...this.courses, ...copy];
        this.loading = false;
      });
  }

  getCourseOwn() {
    let id_organization =
        this.group?.organizations[0].id || this.init.getCurrentOrg()?.id,
      isOpen = 'true',
      excluded = this.group?.id || undefined;

    this.collectionService
      .getCollection(
        20,
        1,
        'course',
        id_organization,
        this.searchTextOwn,
        isOpen,
        excluded,
        this.username,
        null
      )
      .then((res) => {
        this.coursesOwn = res.data.collections;
      });
  }

  getTab(tab: string) {
    this.actualTab = tab;
  }

  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  selectItem(value: string) {
    this.itemSelect = value;
  }

  sendData() {
    this.addLoading = true;
    if (this.group) {
      this.collectionService
        .setCollection('course', this.itemSelect, this.group.id)
        .then((res) => {
          this.addLoading = false;
          this.closeModal();
        });
    } else {
      this.success.emit(this.findSelectedCourse());
      this.closeModal();
    }
  }

  searchAll(text: string) {
    this.searchText = text;
    this.getCourseAll();
  }

  searchOwn(text: string) {
    this.searchTextOwn = text;
    this.getCourseOwn();
  }

  findSelectedCourse() {
    let course = this.courses.find((c: Course) => c.id == +this.itemSelect);
    if (!course) {
      course = this.coursesOwn.find((c: Course) => c.id == +this.itemSelect);
    }
    return course;
  }
  public nextPage() {
    if (this.paginator.current_page < this.paginator.pages) {
      this.paginator.current_page++;
      this.getCourseAll(true);
    }
  }
}
