import { AdminService } from 'src/app/services/admin.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-course',
  templateUrl: './delete-course.component.html',
  styleUrls: ['./delete-course.component.scss'],
})
export class DeleteCourseComponent implements OnInit {
  @Input() course: any = {};
  @Input() selfModal: NgbModalRef | null = null;
  @Output() refresh = new EventEmitter<any>();

  public faCircleNorch: any = faCircleNotch;

  public faTimes = faTimes;
  public loadSpin: boolean = false;

  constructor(private adminService: AdminService) {}

  ngOnInit(): void {}
  public deleteCourse() {
    this.loadSpin = true;
    this.adminService.deleteCourse(this.course?.id).then((resp: any) => {
      this.loadSpin = false;
      this.refresh.emit(this.course.id);
      this.closeModal();
    });
  }
  public closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }
}
