<h3 class="h6">{{ 'video:edit:modal:tab:share:title' | translate }}</h3>
<p class="text-muted fs-14">{{ 'video:edit:modal:tab:share:description' | translate }}</p>

<div class="mb-3">
  <h4 class="h6 fw-bold fs-14 mb-3">{{ 'video:edit:modal:tab:share:organization:title' | translate }}</h4>

  <div class="row">
    <div class="col-md-5 col-xs-12 organization-list" *ngFor="let organization of organizations">
      <label [for]="organization.name">
        <input type="checkbox" [(ngModel)]="organization.checked" (ngModelChange)="touched()" [name]="organization.name" [id]="organization.name"> {{organization.name}}
      </label>
    </div>
  </div>
</div>

<hr [ngClass]="{'my-5': !videoEditService.isModal}">

<div class="">
  <h4 class="h6 fw-bold fs-14 mb-3">
    {{ 'video:edit:modal:tab:course:uses:title' | translate }}
  </h4>
</div>

<ng-container *ngIf="videoEditService.getGroup()">
  <hr [ngClass]="{'my-5': !videoEditService.isModal}">

  <div class="">
    <h4 class="h6 fw-bold fs-14 mb-3">
      {{ 'video:edit:modal:tab:advanced_settings:options' | translate }}
    </h4>

    <p class="text-muted fs-14 mt-2 mb-2">{{ 'video:edit:modal:tab:advanced_settings:show_on_platforms' | translate }}</p>
    <ml-switch [inputValue]="videoEditService.video?.show_on_platform!"
      (value)="changeShowOnPlatform($event)"></ml-switch>
  </div>
</ng-container>

<hr [ngClass]="{'my-5': !videoEditService.isModal}">

<div class="">
  <h4 class="h6 fw-bold fs-14">{{ 'video:edit:modal:add_watermark:title' | translate }}</h4>
  <div>
    <div class="title mt-2 mr-2" style="margin-right: 10px;display: inline;">{{ 'video:edit:modal:tab:advanced_settings:watermark:position' | translate }}</div>
    <div class="btn-group" role="group">
      <input type="radio" class="btn-check" name="watermark_position" [(ngModel)]="videoEditService.source.watermark_position" (ngModelChange)="touched()" id="orgin-top-left" value="top-left" autocomplete="off">
      <label class="btn btn-outline-primary" for="orgin-top-left">
        {{ "video:edit:modal:tab:advanced_settings:watermark:position:top:left" | translate }}
      </label>

      <input type="radio" class="btn-check" name="watermark_position" [(ngModel)]="videoEditService.source.watermark_position" (ngModelChange)="touched()" id="orgin-top-right" value="top-right" autocomplete="off">
      <label class="btn btn-outline-primary" for="orgin-top-right">
        {{ "video:edit:modal:tab:advanced_settings:watermark:position:top:right" | translate }}
      </label>

      <input type="radio" class="btn-check" name="watermark_position" [(ngModel)]="videoEditService.source.watermark_position" (ngModelChange)="touched()" id="orgin-buttom-left" value="buttom-left" autocomplete="off">
      <label class="btn btn-outline-primary" for="orgin-buttom-left">
        {{ "video:edit:modal:tab:advanced_settings:watermark:position:bottom:left" | translate }}
      </label>

      <input type="radio" class="btn-check" name="watermark_position" [(ngModel)]="videoEditService.source.watermark_position" (ngModelChange)="touched()" id="orgin-buttom-right" value="buttom-right" autocomplete="off">
      <label class="btn btn-outline-primary" for="orgin-buttom-right">
        {{ "video:edit:modal:tab:advanced_settings:watermark:position:bottom:right" | translate }}
      </label>
    </div>
  </div>
</div>

<hr [ngClass]="{'my-5': !videoEditService.isModal}">

<div class="">
  <h4 class="h6 fw-bold fs-14">{{ 'video:edit:modal:tab:share:organization:remove:title' | translate }}</h4>
  <p class="text-muted fs-14 mb-0">{{ 'video:edit:modal:tab:share:organization:remove:description' | translate }}</p>
  <div>
    <div class="btn btn-danger mt-3" (click)="delete()">{{ 'video:edit:modal:delete_video:button' | translate }}</div>
  </div>
</div>
