<h3 class="h5">{{ "course:buy:payment:invoice:title" | translate }}</h3>
<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label class="fs-14 fw-500">{{ "course:buy:payment:form:label:1" | translate }}</label>
          <input type="text" class="form-control" [placeholder]="'course:buy:payment:form:label:1:placeholder' | translate"
            [(ngModel)]="asInvoiceDetails(this.checkout.order?.invoice_details).firstname"/>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label class="fs-14 fw-500">{{ "course:buy:payment:form:label:2" | translate }}</label>
          <input type="text" class="form-control" [placeholder]="'course:buy:payment:form:label:2:placeholder' | translate"
            [(ngModel)]="asInvoiceDetails(this.checkout.order?.invoice_details).lastname"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label class="fs-14 fw-500">{{ "course:buy:payment:form:label:4" | translate }}</label>
          <select name="country_code" class="form-select"
                  [(ngModel)]="asInvoiceDetails(this.checkout.order!.invoice_details).country_code" (ngModelChange)="this.checkout.onCountryCodeChange()">
            <option *ngFor="let country of countries" [value]="country.code">
              {{ country.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <hr>
    <div class="form-group">
      <ml-switch [inputValue]="asInvoiceDetails(this.checkout.order!.invoice_details).is_company"
                [textContent]="'course:buy:payment:form:label:3' | translate"
                [textAlignClass]="'ml-switch-text-right'"
                (value)="checkout.changeIsCompany($event)">
      </ml-switch>
    </div>
    <div [ngbCollapse]="!asInvoiceDetails(this.checkout.order!.invoice_details).is_company">
      <p class="pt-3">
        {{ "course:buy:payment:form:label:3:description" | translate }}
        <a href="mailto:contact@movielearning.com"  target="_blank">{{ "course:buy:payment:form:label:3:description:contact" | translate }}</a>
        {{ "course:buy:payment:form:label:3:description:end" | translate }}
      </p>

      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label class="fs-14 fw-500">
              {{ (asInvoiceDetails(this.checkout.order!.invoice_details).is_eu_country ? "course:buy:payment:form:label:5" : "course:buy:payment:form:label:5:notEu") | translate }}
            </label>
            <div class="input-group">
              <input type="text" [(ngModel)]="asInvoiceDetails(this.checkout.order!.invoice_details).company_vat"
                    [disabled]="this.checkout.validateVatShowLoading" (ngModelChange)="this.checkout.invalidateCompanyVat()"
                    (blur)="this.checkout.validateVat()" class="form-control"/>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
              <label class="fs-14 fw-500">{{ "course:buy:payment:form:label:6" | translate }}</label>
              <input type="text" class="full-width" [(ngModel)]="asInvoiceDetails(this.checkout.order!.invoice_details).coc" class="form-control"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="fs-14 fw-500">{{ "course:buy:payment:form:label:7" | translate }}</label>
            <input type="text" class="full-width input-blue-bg" [(ngModel)]="asInvoiceDetails(this.checkout.order!.invoice_details).company_name" class="form-control"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <label class="fs-14 fw-500">{{ "course:buy:payment:form:label:8" | translate }}</label>
            <input type="text" class="full-width input-blue-bg" [(ngModel)]="asInvoiceDetails(this.checkout.order!.invoice_details).company_address" class="form-control"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mt-4">
  <h3 class="h5">{{ "course:buy:payment:title" | translate }}</h3>
  <div *ngFor="let pm of paymentMethods; let i = index">
    <label class="fancy-radio-checkbox d-flex p-3 ps-5 mb-0 justify-content-between align-items-center">
      <input type="radio" class="fancy-input" name="method" [value]="pm" [(ngModel)]="this.checkout.order!.method">
      <div class="fancy-graphic ms-3"></div>

      <h5 class="mb-0">{{ "course:buy:payment:method:" + pm | translate }}</h5>

      <img *ngIf="pm == 'ideal'" src="assets/img/courses/ico-ideal.png" alt="">
      <img *ngIf="pm == 'creditcard'" src="assets/img/courses/ico-credit-cards.png" alt="">
      <div class="method-icon" [ngClass]="'icon-' + (i + 1)"></div>
    </label>

    <div *ngIf="pm == 'creditcard'" [ngbCollapse]="this.checkout.order!.method != pm">
      <p>{{ "course:buy:payment:method:" + pm + ":info" | translate }}</p>
    </div>
  </div>
</div>
