import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'htmlToPlainText'
})
export class htmlToPlainTextPipe implements PipeTransform {

  transform(input: String): String {
    if (input){
      return input.replace(/<[^>]+>/gm, '');
    }
    return input;
  }

}