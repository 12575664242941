import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faClock, faCommentAlt } from '@fortawesome/free-regular-svg-icons';

import { CourseUtilitiesService } from 'src/app/utilities/course-utilities.service';
import { LangService } from 'src/app/services/lang.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { PermissionsService } from 'src/app/utilities/permissions.service';

@Component({
  selector: 'app-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss'],
})
export class CourseCardComponent implements OnInit {
  @Input() course: any = null;
  @Input() fromCompetencies: boolean = false;
  @Input() featured: boolean = false;
  @Input() isFromGroup: boolean = false;
  @Output() refreshDelete = new EventEmitter<null>();
  @Output() sendPrice: EventEmitter<any> = new EventEmitter<null>();

  faStar = faStar;
  faClock = faClock;
  faComment = faCommentAlt;

  userLanguage: string | null = '';

  constructor(
    private courseUtils: CourseUtilitiesService,
    private lang: LangService,
    private router: Router,
    private permissionsService: PermissionsService,
    public auth: AuthService
  ) {
    this.lang.language.subscribe((lang) => {
      this.userLanguage = lang || 'en';
    });
  }

  ngOnInit(): void {}

  isOpen(): boolean {
    return this.courseUtils.isOpen(this.course);
  }

  hasPrices(): boolean {
    return this.courseUtils.hasPrices(this.course);
  }

  getMinSubscriptionPrice() {
    this.sendPrice.emit(this.courseUtils.getMinSubscriptionPrice(this.course));

    return this.courseUtils.getMinSubscriptionPrice(this.course);
  }

  hasRenewPrices(): boolean {
    return (
      this.courseUtils.hasRenewPrices(this.course) &&
      this.courseUtils.getMinRenewPrice(this.course) != 0
    );
  }

  getMinRenewPrice(): number | null {
    if (this.courseUtils.hasRenewPrices(this.course)) {
      var renewPriceMin = this.courseUtils.getMinRenewPrice(this.course);
      this.sendPrice.emit(renewPriceMin);
      return renewPriceMin;
    }
    return null;
  }

  userIsEnrolled(): boolean {
    return this.courseUtils.userIsEnrolled(this.course);
  }

  userIsInvited(): boolean {
    return this.courseUtils.userIsInvited(this.course);
  }

  userWasEnrolled(): boolean {
    return this.courseUtils.userWasEnrolled(this.course);
  }

  userSubscriptionExpired(): boolean {
    return this.courseUtils.userSubscriptionExpired(this.course);
  }

  userProgress(): number {
    if (this.course.course_progress) {
      return Math.floor(
        parseFloat(this.course.course_progress.toFixed(3)) * 100
      );
    } else if (this.course.chapters) {
      return this.courseUtils.userProgress(this.course);
    }
    return 0;
  }

  isCompleted(): boolean {
    return this.userProgress() >= 100;
  }
  public goToCourse(course: any) {
    if (this.fromCompetencies){ return}
    this.router.navigate(
      ['/courses', course.id + '-' + this.cleanString(course.name)],
      {
        queryParams: {
          tab: 'About',
        },
        queryParamsHandling: 'merge',
      }
    );
  }
  public deleteItem() {
    this.refreshDelete.emit(this.course?.id_item);
  }

  public userCanEdit(item: any) {
    if (item) {
      return (
        this.permissionsService.userIsOwner(item) ||
        this.auth.hasAnyRole(['moderator', 'super_user'])
      );
    }
    return false;
  }

  cleanString(input: string): string {
    // Convertir a minúsculas
    let cleanedString = input.toLowerCase();

    // Reemplazar espacios y guiones por guiones
    cleanedString = cleanedString.replace(/[\s-]+/g, '-');

    // Eliminar caracteres especiales y acentos
    cleanedString = this.removeAccents(cleanedString).replace(
      /[^a-z0-9-]/g,
      ''
    );

    return cleanedString;
  }

  removeAccents(input: string): string {
    let string = input.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return string;
  }
}
