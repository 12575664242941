<div class="modal-close"  (click)="closeModal()">
    <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
</div>
<div class="modal-body">
    <p>Modal body text goes here.</p>
    <ng-container>
   
    </ng-container>
</div>

