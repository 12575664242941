import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-deactivate-accept',
  templateUrl: './deactivate-accept.component.html',
  styleUrls: ['./deactivate-accept.component.scss']
})
export class DeactivateAcceptComponent implements OnDestroy {

  @Input() selfModal: NgbModalRef | null = null;
  @Input() user: any = {};

  constructor(
    private auth: AuthService,
    private router :Router) { }

  ngOnDestroy(){
    this.auth.logOut();
    this.router.navigate(['/']);
  }

  goodbye(){
    if(this.selfModal) this.selfModal?.close();
    else $("ngb-modal-window").click();
  }

}
