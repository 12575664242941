import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';

import { MicroclipPlayerComponent } from './microclip-player/microclip-player.component';
import { MicroclipScrubBarComponent } from './microclip-scrub-bar/microclip-scrub-bar.component';
import { MicroclipScrubBarCurrentTimeComponent } from './microclip-scrub-bar-current-time/microclip-scrub-bar-current-time.component';
import { MicroclipScrubBarBufferingTimeComponent } from './microclip-scrub-bar-buffering-time/microclip-scrub-bar-buffering-time.component';

@NgModule({
  declarations: [
    MicroclipPlayerComponent,
    MicroclipScrubBarComponent,
    MicroclipScrubBarCurrentTimeComponent,
    MicroclipScrubBarBufferingTimeComponent
  ],
  imports: [
    CommonModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule
  ],
  exports: [
    MicroclipPlayerComponent
  ]
})
export class MicroclipModule { }
