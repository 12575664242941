<div class="container">
    <div class="card bg-white p-2 mb-3" *ngIf="chapter?.spacer">
        <h3 class="h5 p-2 m-0">
            {{ chapter?.spacer.name }}
        </h3>
    </div>

    <div class="card bg-white mb-3">
        <div class="card-header bg-white">
            <div class="row justify-content-between">
                <div [ngClass]="auth.isAuthenticated() && chapterProgress != 0 ? 'col-auto' : 'col' ">
                    <h3 class="h6 py-2 m-0">
                        {{ chapterIndex != 0 ? chapterIndex + '. ' : '' }} {{ chapter?.name }} 
                    </h3>
                </div>
                <div *ngIf="auth.isAuthenticated() && chapterProgress != 0" class="col-3">
                    <div class="d-flex bar-progress">
                        <span style="width: 100%; margin-right: 8px;margin-top: 11px;">
                            <app-progress-bar [progress]="chapterProgress" [showLabelText]="false" [labelPosition]="'left'"></app-progress-bar>
                        </span>

                        <span class="fa fa-check-circle mt-1" *ngIf="chapterProgress == 100"></span>
                    </div>
                </div>
                <div *ngIf="chapter?.access && chapter?.end_date" class="col-auto">
                    <h6 class="mb-0 text-success py-2">
                        {{ (chapter?.access?'Enrolled until': '' ) | translate }} {{chapter?.end_date  | amLocale: language | amDateFormat:'MMMM D YYYY'}}
                    </h6>
                </div>
            </div>
        </div>

        <div class="card-body p-0">
            <div *ngIf="type != 'course_menu' && chapter?.description!.length" class="m-0 row hidefromviewer">
                <div class="col">
                    <p class="description-text mb-0 fs-14">
                        <span *ngIf="!showMore"> {{ chapter?.description! | excerpt: 425 }}</span>
                        <span *ngIf="showMore">{{ chapter?.description }}</span>
                        <span class="show-more ms-2" *ngIf="chapter?.description!.length > 425" (click)="showMore=!showMore">{{ ( showMore ? 'courses:btn:show_less' : 'courses:btn:show_more' ) | translate}}</span>
                    </p>
                </div>
            </div>

            <div class="row d-flex align-items-center py-2 m-0 chapter-buttons">
                <span class="col show-more pe-0 fs-14" (click)="isCollapsed = !isCollapsed"
                    [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBlock">
                    {{(isCollapsed?'courses:viewer:sidebar:show:details':'courses:viewer:sidebar:hide:details') | translate}}
                    <span [ngClass]="isCollapsed ? 'fa-chevron-down' : 'fa-chevron-up'" class="fa viewer-sidebar-chevron fa-chevron-down collapsed-toggle"></span>
                </span>

                <div class="col-auto">
                    <button class="btn btn-primary pull-right start-chapter" (click)="goToChapter()">
                        <span >{{ (chapterProgress < 100 && chapterProgress > 0 ? 'courses:viewer:sidebar:continue:chapter' : 'courses:viewer:sidebar:start:chapter') | translate }}</span>
                    </button>
                </div>
            </div>

            <div id="collapseBlock" [ngbCollapse]="isCollapsed">
                <div class="container">
                    <div *ngIf="type=='course_menu'" class="row viewer-sidebar-chapter-text hidefromviewer">
                        <p class="fs-14 p-0 px-3 mt-3">{{ chapter?.description}}</p>
                    </div>
                </div>

                <app-viewer-lessons *ngFor="let lesson of chapter?.lessons" [lesson]="lesson" [chapter]="chapter" [course]="courseAux" class="p-0"></app-viewer-lessons>

                <div class="card-body p-0">
                    <div *ngFor="let paragraph of chapter?.paragraphs" class="paragraph-container">
                        <app-viewer-paragraph [paragraph]="paragraph"
                        [chapter]="chapter"
                            [paragraphs]="chapter?.paragraphs" [course]="courseAux"
                            [chapterIndex]="chapterIndex"></app-viewer-paragraph>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>
