import { Injectable } from '@angular/core';
import { Order } from '../interfaces/order';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(
    private api: ApiService
  ) { }

  public getPaymentMethods(): Array<string> {
    return [
      'ideal',
      'creditcard',
      // 'paypal'
    ];
  }

  public get(id: number): Promise<Order> {
    return this.api.get('/order/' + id).then( (res) => {
      if (res.result == 'success') {
        return res.data
      }
      return res;
    });
  }

  public query(params: any | null): Promise<any> {
    // Params
    if (!params) {
      params = {};
    }

    return this.api.get('/order', params);
  }

  public validateVat (params: {country_code: string | null, vat_number?: string | null} | null) {
    if (!params) {
      params = {
        country_code: null,
        vat_number: null
      }
    }

    return this.api.get('/payment/check_vat', params);
  }

  public save(order: Order): Promise<Order> {
    return this.api.postJSON('/order', order).then( (res) => {
      return res.data;
    });
  }

  public pay(order: Order): Promise<any> {
    return this.api.post('/order/' + order.id + '/pay', {});
  }

  public applyCoupon(order: Order, couponCode: string, params:any=null): Promise<any> {
    return this.api.post('/order/' + order.id + '/coupon', {
      code: couponCode,
      in_total: params?params.in_total:null,
      in_tax: params?params.tax:null,
      
      discount_net: params?params.discount_net:null,
      discount_tax: params?params.discount_tax:null
    });
  }
}
