import { Component, Input,OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TeamService } from 'src/app/services/team.service';
import { ToastService } from 'src/app/services/toast.service';
 
@Component({
  selector: 'app-team-create-profile',
  templateUrl: './team-create-profile.component.html',
  styleUrls: ['./team-create-profile.component.scss']
})
export class TeamCreateProfileComponent implements OnInit {
  @Input() selfModal: NgbModalRef | null = null;
  @Input() team: any;
 

  teamUsers:any=[];
  teamAlreadyCreated:boolean=false;
  form: UntypedFormGroup = this.fb.group({
    title: ['', Validators.required],
    end: ['', Validators.required],
    start: ['', Validators.required],
    message: ['', Validators.minLength(3)],
    users: this.fb.group({})
  });

  creating = false;

  constructor(
    private fb: UntypedFormBuilder,
    private ts: TeamService,
    private toastr: ToastService
  ) { }

  ngOnInit(): void {
   // console.log('this.team',this.team)

    //voy a controlar si ya hay un team creado el mismo dia.
    const today = new Date();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    const year = today.getFullYear();
    const title = `${day}/${month}/${year}`;

   

    // si hay tabs o sea team profile. Solo muestro los usuarios del ultimo team prfile
    let users_id=[];
    if (this.team.tabs&&this.team.tabs.length>0){
      this.team.tabs.forEach((element:any) => {
        if (element.title==title){
         this.teamAlreadyCreated=true
       
        }
        
      });

      let specificIds=this.team.tabs[this.team.tabs.length-1].users_id
      this.teamUsers = this.team.users.filter((user:any) => specificIds.includes(user.user_id));

    }else{
     // this.teamUsers = this.team.users;
      this.teamUsers = this.team.users.filter((user:any) => user.type=='student');
      console.log('User not tab',this.teamUsers)
    }

    this.form.get
    let usercbs = <UntypedFormGroup>this.form.get('users');
    //this.team.users.forEach( (u: any) => {
      this.teamUsers.forEach( (u: any) => {
      usercbs.addControl( u.id, this.fb.control(false));
    });
  }
  cancelModal(){
  
    this.closeModal('cancel')
  }
  closeModal(status='success') {
    if (this.selfModal) this.selfModal?.close(status);
    else $('ngb-modal-window').click();
  }

  hasUserSelected(){
    let raw = this.form.getRawValue();
    for (const key in raw.users) {
      if (raw.users[key]) {
        return true;
      }
    }
    return false;

  }
  createProfile() {
    this.creating = true;
    let raw = this.form.getRawValue();
    let members: Array<any> = [];
    for (const key in raw.users) {
      if (raw.users[key]) {
        members.push(key);
      }
    }
    const randomNumber = Math.floor(Math.random() * 10000000000000);
    const today = new Date();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    const year = today.getFullYear();
    const formattedStartDate = `${month}/${day}/${year}`;
    const title = `${day}/${month}/${year}`;

    const today1 = new Date();
    today1.setFullYear(today1.getFullYear() + 100);
    const month1 = (today1.getMonth() + 1).toString().padStart(2, '0');
    const day1 = today1.getDate().toString().padStart(2, '0');
    const year1 = today1.getFullYear();
    const formattedEndDate = `${month1}/${day1}/${year1}`;

    let tab = {
      id:'1044-'+randomNumber,
      dates: {
        start: formattedStartDate,
        end: formattedEndDate
      },
      message: raw.message,
      title: title,
      users: members,
      users_id: this.team.users.filter( (u: any) => {
        let inc = members.includes(u.id + '')
        return inc;
      }).map( (u: any) => {
        return u.user.id
      })
    };
    console.log(this.team)
    
 
    this.ts.createProfile(this.team, tab).then( (res: any) => {
      this.selfModal?.close('accept');
    }).catch( (err:any) => {
      this.toastr.showError(err);
    }).finally( () => {
      this.creating = false;
    });
  }
}
