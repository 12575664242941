<div class="modal-close" (click)="closeModal()">
  <i class="fas fa-times icon me-1"></i>
  <span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">Team Settings</h4>
</div>
<div class="modal-body">
  <div class="form-group mb-3">
    <label for="title" class="mb-2">Name of the team</label>
    <input
      type="text"
      class="form-control"
      id="title"
      [placeholder]="teamName"
      [(ngModel)]="teamName"
    />
  </div>
</div>
<div class="row justify-content-end modal-footer">
  <div class="row justify-content-end text-center">
    <div class="col-auto">
      <button
        type="button"
        class="btn btn-outline-secondary fs-15 text-nowrap"
        (click)="closeModal()"
      >
        {{ "Cancel" | translate }}
      </button>
    </div>
    <div class="col-auto">
      <button
        (click)="editTeamSettings()"
        type="button"
        class="btn btn-primary text-nowrap"
        [ngClass]="{ disabled: loading }"
      >
        {{ "admin:users:modal:edit_account:btn:save" | translate
        }}<i *ngIf="loading" class="fa fa-circle-notch fa-spin ms-2"></i>
      </button>
    </div>
  </div>
</div>
