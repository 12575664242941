<div class="modal-close" (click)="closeModal()">
  <i class="fas fa-times icon me-1"></i>
  <span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">{{ "dh_profile:introduction:title" | translate }}</h4>
</div>
<div class="modal-body">
  <div class="col-12">
    <img src="assets/img/dh-profile/intro.png" alt="img" class="img-intro" />
  </div>
  <div class="row">
    <div class="col">
      <p #miDiv *ngIf="!showLongText" class="mb-0" [innerHTML]="text"></p>
      <span class="show" *ngIf="!showLongText" (click)="showMore()"
        >...{{ "show more" | translate }}</span
      >
      <div *ngIf="showLongText" class="texto-container">
        <p class="mb-0" [innerHTML]="textoCompleto"></p>
      </div>
    </div>
  </div>
</div>
<div class="row justify-content-center modal-footer">
  <div class="col-auto">
    <button
      (click)="closeModal()"
      type="button"
      class="btn btn-primary text-nowrap"
    >
      {{ "dh_profile:introduction:btn" | translate }}
    </button>
  </div>
</div>
