//<ml-switch (change)="click1($event)" [textAlignClass]="'ml-switch-text-right'" [inputValue]="inputValue" [textContent]="'pepe el grillo'"></ml-switch>

import { Component, Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ml-switch',
  templateUrl: './ml-switch.component.html',
  styleUrls: ['./ml-switch.component.scss']
})
export class MlSwithComponent {
  @Input() inputValue: boolean = false;
  @Input() textAlignClass: any;
  @Input() textContent: any;
  @Input() small: any = false;
  @Output('value') change:EventEmitter<boolean> = new EventEmitter<boolean>();

  onChanged() {
    this.change.emit(!this.inputValue);
  }
}
