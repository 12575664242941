import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { left } from '@popperjs/core';

@Component({
  selector: 'microclip-scrub-bar-current-time',
  templateUrl: './microclip-scrub-bar-current-time.component.html',
  styleUrls: ['./microclip-scrub-bar-current-time.component.scss']
})
export class MicroclipScrubBarCurrentTimeComponent implements OnInit, OnDestroy {
  @Input() startTime: number | undefined;
  @Input() endTime: number | undefined;

  style: {
    left: string,
    width: string,
  } = {left: '0%', width: '0%'};

  @Input() vgFor: string|undefined;
  @Input() vgSlider = false;

  elem: HTMLElement;
  target: any;

  subscriptions: Subscription[] = [];

  constructor(ref: ElementRef, public API: VgApiService) {
    this.elem = ref.nativeElement;
  }

  ngOnInit() {
    if (this.API.isPlayerReady) {
      this.onPlayerReady();
    } else {
      this.subscriptions.push(
        this.API.playerReadyEvent.subscribe(() => this.onPlayerReady())
      );
    }
  }

  onPlayerReady() {
    this.target = this.API.getMediaById(this.vgFor);

    this.target.subscriptions.canPlay.subscribe(() => this.recalculatePosition() );
    this.target.subscriptions.timeUpdate.subscribe( () => this.calculateWidht() );
  }

  recalculatePosition() {
    if (this.API.duration) {
      let totalTimeAux = this.API.duration;
      let startTime = this.startTime! / 1000;

      this.style.left = (startTime! * 100) / totalTimeAux + '%';
    }
  }

  calculateWidht() {
    let currentPercentage = Math.round(((this.target.time.current - this.startTime!) * 100) / this.target.time.total);
    this.style.width = currentPercentage + '%';
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}