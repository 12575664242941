import { Component, Inject, Input, OnInit } from '@angular/core';
import { User } from 'src/app/interfaces/user';

@Component({
  selector: 'app-user-icon',
  templateUrl: './user-icon.component.html',
  styleUrls: ['./user-icon.component.scss']
})
export class UserIconComponent implements OnInit {
  @Input() user: User | null = null;
  @Input() class: string = '';
  @Input() size: string = '';
  @Input() default: string = '';
  @Input() background: string = '#ffff';

  src = '';

  constructor() { }

  ngOnInit(): void {
    if (!this.default) {
      this.default = 'assets/img/avatars/profile-avatar-purple.svg';
    }

    switch (this.size) {
      case 'medium':
        this.class += ' icon-medium';
        this.src = this.user && this.user.avatarPathUrlMedium ? this.user.avatarPathUrlMedium : '';
        break;
      default:
        this.src = this.user && this.user.avatarPathUrlSmall ? this.user.avatarPathUrlSmall : '';
        break;
    }

    if (!this.src) {
      this.src = this.default;
    }
  }
}