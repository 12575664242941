import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-continue-watching',
  templateUrl: './continue-watching.component.html',
  styleUrls: ['./continue-watching.component.scss'],
})
export class ContinueWatchingComponent implements OnInit {
  @Input() course: any;

  public errorImg = 'assets/img/groups/icons/list.png';
  constructor(private router: Router) {}

  ngOnInit(): void {

    //this.course.type = this.course.collection.type;
    console.log('course',this.course)



  }

  courseProgress(): number {
    if (this.course.course_progress) {
      return Math.floor(
        parseFloat(this.course.course_progress.toFixed(3)) * 100
      );
    } else if (this.course.chapters) {
      return 0;
    }
    return 0;
  }

  goCourse() {
    this.router.navigate([
      '/courses',
      this.course.collection.id,
      'cursus',
      this.course.collection.name.split(' ').join('-'),
    ]);
  }
}
