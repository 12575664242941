import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Chapter } from '../interfaces/chapter';
import { Course } from '../interfaces/course';
import { Lesson } from '../interfaces/lesson';
import { Paragraph } from '../interfaces/paragraph';
import { Subscription } from '../interfaces/subscription';
import { User } from '../interfaces/user';
import { AuthService } from '../services/auth.service';
import { InitService } from '../services/init.service';

@Injectable({
  providedIn: 'root',
})
export class CourseUtilitiesService {
  constructor(private initService: InitService, private auth: AuthService) {}

  isOpen(course: Course | undefined): boolean {
    return course!.open;
  }

  userIsEnrolled(course: Course): boolean {
    return course.status === 'enrolled';
  }

  userWasEnrolled(course: Course): boolean {
    return course.status === 'renew_subscription';
  }

  userSubscriptionExpired(course: Course): boolean {
    return (
      moment(new Date(course.finish_subscription)).isBefore(moment()) == true
    );
  }

  userIsOwner(course: Course | null, user: User | undefined = undefined) {
    if (!user) {
      user = this.auth.getMe()!;
    }
    return (
      user && course && course.user && course.user.username === user.username
    );
  }

  userCanEdit(course: Course, user: User | undefined = undefined) {
    if (!user) {
      user = this.auth.getMe()!;
    }

    if (
      this.auth.hasAccess('edit_all_collections') ||
      this.userIsOwner(course, user)
    ) {
      return true;
    }

    return false;
  }

  userIsInvited(course: Course): boolean {
    return (
      course.status === 'accept_invitation' || course.status === 'accept_gift'
    );
  }

  userCompleted(course: Course): boolean {
    let completed = this.userAmountCompletedLessons(course);
    let amount = this.getAmountLessons(course);

    return completed == amount;
  }

  userProgress(course: Course): number {
    let progress = 0;
    let completed = this.userAmountCompletedLessons(course);
    let amount = this.getAmountLessons(course);

    if (amount) {
      var courseprogress = parseFloat((completed / amount).toFixed(3)) * 100;
      progress = Math.floor(courseprogress);
    }

    return progress;
  }

  userCanLeave(course: Course): boolean {
    return this.userIsEnrolled(course) && !this.userIsOwner(course);
  }

  userCanBuy(course: Course): boolean {
    return !(
      !this.hasPrices(course) ||
      this.userCanLeave(course) ||
      this.userIsInvited(course) ||
      this.userCanEdit(course)
    );
  }

  getAllLessons(course: Course): Array<Lesson> {
    let lessons: Array<Lesson> = [];
    if (course) {
       // Ordenar capítulos por collection_position
      course.chapters.sort((a, b) => {
        const positionA = a.Collection_Entity?.collection_position || 0;
        const positionB = b.Collection_Entity?.collection_position || 0;
        return positionA - positionB;
      });
      
      course.chapters.forEach((chapter) => {
        lessons = [...new Set([...lessons, ...chapter.lessons])];

        chapter.paragraphs.forEach((paragraph) => {
          lessons = [...new Set([...lessons, ...paragraph.lessons])];
        });
      });
    }

    return lessons;
  }

  getAllPreviewLessons(course: Course): Array<Lesson> {
    const lessons = this.getAllLessons(course);
    return lessons.filter( l => l.free_preview);
  }

  getLesson(course: Course, lesson_id: number): Lesson | undefined {
    return this.getAllLessons(course).find((l: Lesson) => l.id == lesson_id);
  }

  getChapter(course: Course, chapter_id: number): Chapter | undefined {
    return course.chapters.find((c: Chapter) => c.id == chapter_id);
  }

  getParagraph(course: Course, paragrap_id: number): Paragraph | undefined {
    for (const c of course.chapters) {
      let paragraph = c.paragraphs.find((p: Paragraph) => p.id == paragrap_id);
      if (paragraph) {
        return paragraph;
      }
    }
    return undefined;
  }

  userAmountCompletedLessons(course: Course) {
    let completed = 0;
    let lessons = this.getAllLessons(course);

    lessons.forEach((lesson) => {
      completed += lesson.completed ? 1 : 0;
    });

    return completed;
  }

  getAmountLessons(course: Course): number {
    var amount = 0;

    if (course) {
      course.chapters.forEach((chapter) => {
        amount += chapter.lessons.length;

        chapter.paragraphs.forEach((paragraph) => {
          amount += paragraph.lessons.length;
        });
      });
    }

    return amount;
  }

  hasPrices(course: any): boolean {
    let organizationId = this.initService.getCurrentOrg()?.id;
    if (!course.subscription_price) {
      return false;
    }
    let subscriptionPrice = course.subscription_price.filter((v: any) => {
      return v.organization_id == organizationId;
    });

    return !!subscriptionPrice.length;
  }

  getMinSubscriptionPrice(course: any): any {
    let price = 0;
    let organizationId = this.initService.getCurrentOrg()?.id;

     
    if (!course.subscription_price) {
      course.subscription_price = [];
    }


    let subscriptionPrice = course.subscription_price.filter((v: any) => {
      return v.organization_id == organizationId;
    });

    if (subscriptionPrice.length) {
      let minPrice = null;

      for (let i = 0; i < subscriptionPrice.length; i++) {
        minPrice =
          !minPrice || subscriptionPrice[i].price < minPrice.price
            ? subscriptionPrice[i]
            : minPrice;
      }

      if (minPrice) {
        price = minPrice.price;
      }
    }

    return price;
  }

  hasRenewPrices(course: Course): boolean {
    if (
      !course.pricing ||
      !course.pricing.renew ||
      course.pricing.renew.length == 0
    ) {
      return false;
    }

    const organizationId = this.initService.getCurrentOrg()?.id;

    let renewPrice = course.pricing.renew.filter((p: any) => {
      return p.organization_id == organizationId && p.subscription_type == 2;
    });

    return renewPrice && renewPrice.length > 0;
  }
  getOriginalPrice(course: any,subscription_days:number): number {
    const organizationId = this.initService.getCurrentOrg()?.id;
    let subscriptionPrice = course.pricings.subscription.filter((p: any) => {
      return p.organization_id == organizationId;
    });
    let price=0
    subscriptionPrice.forEach((sub:any) => {
      if (sub.subscription_days==subscription_days){
        price=sub.price;
      }
    });

    return price
  }
  getMinRenewPrice(course: any): number {
    let price = 0;
 
    const organizationId = this.initService.getCurrentOrg()?.id;
    let subscriptionPrice = course.pricings?.renew.filter((p: any) => {
      return p.organization_id == organizationId;
    });


    let suscription_day=course.summaryOrders?.subscription_day_selected;

    subscriptionPrice?.forEach((sub:any) => {
      if (sub.subscription_days!=suscription_day){
       sub.price=this.getOriginalPrice(course,sub.subscription_days)
  
      }
    });


    if (subscriptionPrice?.length) {
      let minPrice = subscriptionPrice.reduce((prev:any, curr:any) =>
        prev.price < curr.price ? prev : curr
      );

      if (minPrice) {
        price = minPrice.price;
      }
    }

    return price;
  }

  getCurrentLesson(course: Course) {
    var lesson = null;
    var lessons = this.getAllLessons(course);
    var amount = this.getAmountLessons(course);

    //sort by id
    lessons = lessons.sort(function (a: any, b: any) {
      if (a.position > b.position) {
        return 1;
      }
      if (a.position < b.position) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    let index = 0;
    lessons.forEach((lesson: any, i) => {
      if (lesson.lesson_users.length != 0) {
        index = i;
      }
    });

    if (index != 0) {
      if (index + 1 < amount) {
        lesson = lessons[index + 1];
      } else {
        index = lessons.findIndex((lesson: any) => {
          return lesson.lesson_users.length == 0;
        });
        if (index != -1) {
          lesson = lessons[index];
        }
      }
    } else {
      lesson = this.getFirstLesson(course);
    }

    return lesson;
  }

  orderLessons(lessons: any) {
    let aux = lessons.sort(function (a: any, b: any) {
      if (a.position > b.position) {
        return 1;
      }
      if (a.position < b.position) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return aux;
  }

  orderParagraph(paragraph: any) {
    let aux = paragraph.sort(function (a: any, b: any) {
      if (a.position > b.position) {
        return 1;
      }
      if (a.position < b.position) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return aux;
  }

  getFirstLesson(course: Course) {
    var lessons = this.getAllLessons(course);

    //sort by id
    lessons = lessons.sort(function (a: any, b: any) {
      if (a.id > b.id) {
        return 1;
      }
      if (a.id < b.id) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });

    return lessons[0];
  }

  getLessonThumbnail(lesson: Lesson): string {
    if (lesson.type == 'video') {
      if (lesson.microclip_id) {
        return lesson.microclip?.video_post?.thumb_url || '';
      }
      return lesson.video_post?.thumb_url || '';
    }

    if (lesson.type == 'text') {
      let img = '';

      if (lesson && lesson.lesson_blocks) {
        let l_block = this.orderLessons(lesson.lesson_blocks);
        l_block.forEach((block: any) => {
          if (block.video && block.video.thumb_url && img == '') {
            img = block.video.thumb_url;
          }
        });
      }

      if (img == '') return 'assets/img/courses/thumb-text-lesson.png';

      return img;
    }
    return '';
  }

  getCurrentThumbnail(course: Course) {
    var lesson: any = this.getCurrentLesson(course);

    if (lesson.type == 'video') {
      return lesson.video_post.thumb_url;
    }
    if (lesson.type == 'text') {
      if (lesson && lesson.lesson_blocks && lesson.lesson_blocks[0] != null) {
        return lesson.lesson_blocks[0].video
          ? lesson.lesson_blocks[0].video.thumb_url
          : lesson.lesson_blocks[0].file
          ? lesson.lesson_blocks[0].file.pathUrl
          : '';
      }
    }
    return '';
  }

  isLessonCompleted(lesson: Lesson) {
    if ((lesson.lesson_users?.length && lesson.lesson_users[0]?.completed) || lesson.completed) {
      return true;
    }
    return false;
  }

  getAllLessonsChapter(chapter: any): Array<Lesson> {
    let lessons: Array<Lesson> = [];
    if (chapter) {
      lessons = [...new Set([...lessons, ...chapter.lessons])];
      chapter.paragraphs.forEach((paragraph: any) => {
        lessons = [...new Set([...lessons, ...paragraph.lessons])];
      });
    }
    return lessons;
  }

  getAmountChapterLessons(chapter: any): number {
    var amount = 0;
    if (chapter) {
      amount += chapter.lessons.length;
      chapter.paragraphs.forEach((paragraph: any) => {
        amount += paragraph.lessons.length;
      });
    }
    return amount;
  }

  chapterProgress(chapter: any) {
    let completed = 0;
    // let totalLessons=chapter.lessons.length?chapter.lessons.length:1;
    let totalLessons = this.getAmountChapterLessons(chapter);
    if (!totalLessons) totalLessons = 1;
    let lessons = this.getAllLessonsChapter(chapter);

    lessons.forEach((lesson: any) => {
      completed += this.isLessonCompleted(lesson) ? 1 : 0;
    });
    var courseprogress =
      parseFloat((completed / totalLessons).toFixed(3)) * 100;
    return Math.floor(courseprogress);
  }

  courseProgress(course: Course): number {
    if (course.course_progress) {
      return Math.floor(parseFloat(course.course_progress.toFixed(3)) * 100);
    } else if (course.chapters) {
      return 0;
    }
    return 0;
  }

  sort(course: Course): void {
    course.chapters.sort((a: Chapter, b: Chapter) => {
      if (
        a.Collection_Entity.collection_position >
        b.Collection_Entity.collection_position
      ) {
        return 1;
      } else if (
        a.Collection_Entity.collection_position <
        b.Collection_Entity.collection_position
      ) {
        return -1;
      }
      return 0;
    });

    course.chapters.forEach((chapter: Chapter) => {
      // sort lessons of chapter
      chapter.lessons.sort((a: Lesson, b: Lesson) =>
        a.position > b.position ? 1 : a.position < b.position ? -1 : 0
      );

      // sort lesson-blocks
      chapter.lessons.forEach((lesson: Lesson) => {
        lesson.lesson_blocks.sort((a, b) =>
          a.position > b.position ? 1 : a.position < b.position ? -1 : 0
        );
      });

      // sort paragraphs
      chapter.paragraphs.sort((a: Paragraph, b: Paragraph) =>
        a.position > b.position ? 1 : a.position < b.position ? -1 : 0
      );

      // sort lessons of pragraphs
      chapter.paragraphs.forEach((paragraph: Paragraph) => {
        paragraph.lessons.sort((a: Lesson, b: Lesson) =>
          a.position > b.position ? 1 : a.position < b.position ? -1 : 0
        );
        // sort lesson-blocks

        chapter.lessons.forEach((lesson: Lesson) => {
          lesson.lesson_blocks.sort((a, b) =>
            a.position > b.position ? 1 : a.position < b.position ? -1 : 0
          );
        });
      });
    });
  }

  calculateProgress(course: Course): void {
    let lessons = this.getAllLessons(course);

    let completed = 0;
    lessons.forEach((lesson) => (completed += lesson.completed ? 1 : 0));

    course.course_progress = Math.floor((100 / lessons.length) * completed);
  }

  getSubscriptionOptions(course: Course | any ): Array<Subscription> {
    let subscriptions: Array<Subscription> = [];
    console.log('testing')
    if (this.userIsEnrolled(course)) {
      if (course.pricings?.prolong) {
        course.pricings?.prolong.forEach((sub: Subscription) => {
          if (sub.price > 0) {
            subscriptions.push(sub);
          }
        });
      }else{
        course.prolong_price?.forEach((sub: Subscription) => {
          if (sub.price > 0) {
            subscriptions.push(sub);
          }
        });
      }
    
    } else if (this.userWasEnrolled(course)) {
      let takeRenew = course?.pricings?.renew || course?.renew_price
      takeRenew.forEach((sub: Subscription) => {
        if (sub.price > 0) {
          subscriptions.push(sub);
        }
      });
      
    } else {
      let takeRenew = course?.pricings?.subscription || course?.subscription_price
      takeRenew.forEach((sub: Subscription) => {
        if (sub.price > 0) {
          subscriptions.push(sub);
        }
      });
    }

    subscriptions.sort((a, b) => {
      return a.subscription_days > b.subscription_days ? 1 : -1;
    });
   
    return subscriptions;
  }
}
