<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1 icon-x" [icon]="faTimes"></fa-icon
  ><span class="icon-x">{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header justify-content-start">
  <h4 class="modal-title">
    {{ "modal:leave_playlist:title" | translate }}
  </h4>
</div>
<div class="modal-body">
  <div class="row">
    <p class="description">
      {{ "modal:leave_playlist:message" | translate }}
    </p>
  </div>
</div>
<div class="row justify-content-end modal-footer">
  <hr class="common-separator" />
  <div class="row justify-content-end text-center">
    <div class="col-auto">
      <button
        (click)="closeModaUnpublished()"
        type="button"
        class="btn btn-outline-danger ms-2 fs-15 text-nowrap"
      >
        {{ "modal:leave_playlist:leave" | translate }}
      </button>
    </div>
    <div class="col-auto">
      <button
        (click)="closeModaPublished()"
        type="button"
        role="tab"
        class="btn btn-primary text-nowrap"
      >
        <fa-icon
          class="mr-2"
          [icon]="faCircleNorch"
          *ngIf="loadSpin"
          [spin]="true"
        ></fa-icon>
        {{ "modal:leave_playlist:publish" | translate }}
      </button>
    </div>
  </div>
</div>
